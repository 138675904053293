import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 90%;
  max-width: 40rem;
  height: auto;
  max-height: 90vh;
  padding: 2rem 2rem 2rem 2rem;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(90vh - 4rem);
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  margin: 0 auto;
  background: -webkit-linear-gradient(#cccccc, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const SubheadingsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;

  & > p:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  text-align: left;
  /* text-transform: uppercase; */
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #ffffff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const InputLabel = styled('p')`
  display: block;
  width: 100%;
  margin-top: 1.3rem;
  text-align: left;
  /* text-transform: uppercase; */
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 300;
  color: #ffffff;
`;

const PriceInputContainer = styled('div')`
  background-color: #090b10;
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid #ffffff77;
  border-radius: 0.3rem;
`;

const PriceInput = styled('input')`
  background-color: transparent;
  display: inline-block;
  width: 60%;
  padding: 0.7rem 1rem;
  vertical-align: middle;
  border: none;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  outline: none;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff;

  &:active {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 35%;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

const PriceCurrency = styled('div')`
  background-color: #314255;
  display: inline-block;
  width: 40%;
  vertical-align: middle;
  padding: 0.7rem 0.5rem;
  border-left: 1px solid #ffffff77;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  word-break: break-word;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffffff;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 500px) {
    width: 65%;
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

const SummaryContainer = styled('div')`
  background-color: #314255;
  display: block;
  width: 100%;
  padding: 1.2rem;
  margin-top: 1.5rem;
  /* border-left: 1px solid #ffffff77; */
  border-radius: 0.3rem;
`;

const SummaryTitle = styled('p')`
  display: block;
  width: 100%;
  text-align: left;
  /* text-transform: uppercase; */
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 700;
  color: #ffffff;
`;

const SummaryRows = styled('div')`
  display: block;
  margin-top: 1.5rem;

  & > div:not(:first-of-type) {
    margin-top: 0.8rem;
  }
`;

const SummaryRow = styled('div')`
  display: block;
  width: 100%;
`;

const SummaryLabel = styled('div')`
  display: inline-block;
  width: calc(50% - 0.5rem);
  margin-right: 0.5rem;
  text-align: left;
  /* text-transform: uppercase; */
  vertical-align: top;
  white-space: pre-line;
  word-break: break-word;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #ffffff;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */

  & > span {
    display: inline-block;
    vertical-align: middle;
  }

  & > span:nth-of-type(1) {
    margin-right: 0.5rem;
  }

  & .material-icons-outlined {
    font-size: 1.1rem;
    cursor: pointer;
  }

  /* & .MuiTooltip-popper {
    z-index: 9999999 !important;
  } */
`;

const SummaryValue = styled('div')`
  display: inline-block;
  width: 50%;
  /* margin-top: 0.3rem; */
  text-align: right;
  vertical-align: top;
  white-space: pre-line;
  word-break: break-word;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
  /* color: #ffffff; */
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const HR = styled('p')`
  display: block;
  width: 100%;
  margin: 1.2rem 0;
  border-bottom: 1px solid #ffffff77;
`;

// const ProgressContainer = styled('div')
//   display: block;
//   margin-top: 1.5rem;
//   text-align: center;

//   & span {
//     color: ${({ theme }) => theme.custom.colors.Yellow};
//   }
// `;

const ReviewWarningContainer = styled('div')`
  background-color: #314255;
  display: block;
  width: 100%;
  padding: 0.8rem 1.2rem;
  margin-top: 1rem;
  border-radius: 0.3rem;
  position: relative;

  & span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translate(0, -50%);
  }

  & .material-icons-outlined {
    font-size: 1.4rem;
    color: #ffffffaa;
  }
`;

const ReviewWarning = styled('div')`
  display: block;
  margin-left: 2.5rem;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #ffffffaa;
`;

const ConfirmationIconContainer = styled('div')`
  display: block;
  text-align: center;

  & .material-icons-outlined {
    display: inline-block;
    font-size: 3rem;
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const ConfirmationTitle = styled('div')`
  display: block;
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const ConfirmationSubtitle = styled('div')`
  display: block;
  margin-top: 1rem;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #ffffff;
`;

const ButtonsContainer = styled('div')`
  display: block;
  margin-top: 2rem;
  text-align: center;
`;

export default {
  Root,
  ContentContainer,
  Heading,
  SubheadingsContainer,
  Subheading,
  InputLabel,
  PriceInputContainer,
  PriceInput,
  PriceCurrency,
  SummaryContainer,
  SummaryTitle,
  SummaryRows,
  SummaryRow,
  SummaryLabel,
  SummaryValue,
  HR,
  ReviewWarningContainer,
  ReviewWarning,
  ConfirmationIconContainer,
  ConfirmationTitle,
  ConfirmationSubtitle,
  // ProgressContainer,
  ButtonsContainer,
};
