import { styled } from '@mui/material';

interface RankProps {
  variantStyles: {
    size: string;
    fontSize: string;
    letterSpacing: string;
  };
}

const Root = styled('div')<RankProps>`
  background-color: #000000;
  display: flex;
  width: ${({ variantStyles }) => variantStyles.size};
  height: ${({ variantStyles }) => variantStyles.size};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #555555;
  font-family: 'LHU Numerals';
  font-size: ${({ variantStyles }) => variantStyles.fontSize};
  font-weight: 400;
  line-height: ${({ variantStyles }) => variantStyles.fontSize};
  letter-spacing: ${({ variantStyles }) => variantStyles.letterSpacing};

  & > span {
    display: inline-block;
    margin-left: ${({ variantStyles }) => variantStyles.letterSpacing};
  }
`;

export default {
  Root,
};
