import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import Styled from 'src/components/pages/nft/components/BackButton.styles';
import { nameSpace } from 'src/modules/i18n';

const BackButton: FC = () => {
  const { t } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();

  return (
    <Styled.Root>
      <div onClick={() => navigateInternal(-1, null)}>
        <span className="material-icons-outlined">arrow_back</span>
        <span id="back-button">{t('common.term.back')}</span>
      </div>
    </Styled.Root>
  );
};

export default BackButton;
