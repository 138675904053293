import { request } from 'src/api/request';
import { config as lhuConfig } from 'src/config/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBlog = (_options: RequestOptions) => (_dispatch: any) => {
  const config = {
    method: 'get',
    url: lhuConfig.base_url.blog,
  };

  return request(config, { isPublic: true });
};
