import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
`;

const CollectionTitle = styled('p')`
  display: block;
  width: 100%;
  font-family: 'Kevlar Underwear';
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};

  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }

  @media (max-height: 500px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

const NoResults = styled('span')`
  display: inline-block;
  text-align: center;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default {
  Root,
  CollectionTitle,
  NoResults,
};
