import { styled } from '@mui/material';

const Root = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 28rem;
  padding-top: 5rem;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1000px) {
    padding-top: 4.5rem;
  }

  @media (max-width: 768px) {
    height: 28rem;
  }
`;

const HeroBottomBorder = styled('div')`
  background-color: #554309;
  display: block;
  width: 100%;
  height: 4px;
  position: absolute;
  bottom: 0;
`;

const Overlay = styled('div')`
  display: block;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  word-wrap: break-word;
  text-align: center;
  position: absolute;
  top: calc(50% + 2rem);
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CopyContainer = styled('div')`
  display: block;
  width: 100%;
  padding: 0 2rem;

  @media (max-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  max-width: 65rem;
  margin: 0 auto 0 auto;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 3.4rem;
  font-weight: 700;
  color: ${({ color }) => color || '#ffffff'};

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1000px) {
    max-width: 30rem;
  }

  @media (max-width: 768px) {
    max-width: none;
    margin: 0 auto;
    text-align: center;
    font-size: 2.6rem;
    line-height: 2.8rem;
  }

  @media (max-width: 500px) {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
`;

const Date = styled('p')`
  display: block;
  width: 100%;
  max-width: 35rem;
  margin: 1.5rem auto 0 auto;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1000px) {
    max-width: 26rem;
  }

  @media (max-width: 768px) {
    max-width: 33rem;
    margin: 1.5rem auto 0 auto;
    text-align: center;
  }

  @media (max-width: 500px) {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
`;

export default {
  Root,
  HeroBottomBorder,
  Overlay,
  CopyContainer,
  Title,
  Date,
};
