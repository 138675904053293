import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #ffffffcc;
`;

const ResultsContainer = styled('div')`
  display: block;
  margin: 1rem 0 0 0;
`;

const ItemContainer = styled('div')`
  display: block;
  min-width: 12rem;
  font-size: 0;
  box-sizing: border-box;
`;

const ItemImg = styled('img')`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  vertical-align: middle;
  border-radius: 0.5rem;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const ItemDetailsContainer = styled('div')`
  display: inline-block;
  width: calc(100% - 3.5rem);
  vertical-align: top;
  font-size: 0;
`;

const Primary = styled('p')`
  display: block;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffffcc;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const Secondary = styled('p')`
  display: block;
  width: 100%;
  margin-top: 0.3rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: #ffffff77;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

interface TypeProps {
  color: string;
}

const Type = styled('p')<TypeProps>`
  display: block;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  /* color: ${({ color }) => color}; */
  color: #ffffff77;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

interface StatusProps {
  color: string;
}

const Status = styled('p')<StatusProps>`
  background-color: ${({ color }) => color};
  display: inline-block;
  padding: 0.2rem 0.5rem 0.3rem 0.5rem;
  border-radius: 0.2rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: #ffffffcc;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

const Date = styled('p')`
  display: block;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff77;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`;

const Action = styled('span')`
  display: inline-block;
  min-width: 10rem;
  padding: 0.2rem 0.4rem 0.1rem 0.4rem;
  vertical-align: middle;
  border: 1px solid #ffffff77;
  border-radius: 0.2rem;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
  color: #fff;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};

    & .material-icons {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }

    & > span:nth-of-type(2) {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin-right: 0.3rem;
    margin-bottom: 0.1rem;
    vertical-align: middle;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-width: 500px) {
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.8rem;
      line-height: 1rem;
    }
  }
`;

const NoAction = styled('span')`
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff77;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`;

export default {
  Root,
  Title,
  ResultsContainer,
  ItemContainer,
  ItemImg,
  ItemDetailsContainer,
  Primary,
  Secondary,
  Type,
  Status,
  Date,
  Action,
  NoAction,
};
