import { styled } from '@mui/material';

interface RootProps {
  color: string;
  size: number;
}

const Root = styled('div')<RootProps>`
  background-color: ${({ color }) => color};
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  vertical-align: middle;
  transform: rotate(45deg);
`;

export default {
  Root,
};
