import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';

type Resolution = 'small' | 'large' | 'full';
interface Icon {
  title: string;
  width: string;
  height: string;
  path: string;
}
interface Icons {
  small: Icon;
  large: Icon;
  full: Icon;
}

export const usePentarcShardsIcon = (resolution: Resolution) => {
  const { t } = useTranslation(nameSpace.common);
  const icons: Icons = t('common.currency.pentarc.shards.icons', {
    returnObjects: true,
  });
  const icon: Icon = icons[resolution];

  return icon;
};
