import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import ButtonLogin from 'src/components/input/ButtonLogin';
import ButtonHex from 'src/components/input/ButtonHex';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/pages/getting-started/components/SectionHero.styles';

type Hero = {
  path: string;
};

const SectionHero: FC = () => {
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const navigateInternal = useAppNavigateInternal();
  const { t: tGettingStarted } = useTranslation(nameSpace.gettingStarted);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const xsMobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const hero: Hero = tGettingStarted('getting.started.hero.asset', { returnObjects: true });

  const handleOnGoTo = (path: string, gtmEvent?: () => void) => {
    navigateInternal(path, { pooleData: { fromContext: 'getting_started' }, gtmEvent: gtmEvent || null });
  };

  return (
    <Styled.Root style={{ backgroundImage: `url(/assets/${mobileView ? 'mobile_' : ''}${hero.path})` }}>
      <Styled.Overlay className={'inview animated fadeIn'}>
        <Styled.CopyContainer>
          <Styled.Heading
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tGettingStarted('getting.started.hero.title')) }}
          />
          <Styled.Subheading
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tGettingStarted('getting.started.hero.subtitle')) }}
          />
          <Styled.Body
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tGettingStarted('getting.started.hero.body')) }}
          />

          <Styled.ButtonsContainer>
            {!authenticated && (
              <ButtonLogin
                variant="white"
                height={xsMobileView ? 54 : 61}
                width={xsMobileView ? 200 : 185}
                useMobileIcon={false}
                textStyle={{ textTransform: 'uppercase', fontSize: xsMobileView ? '.7rem' : '.8rem' }}
                style={
                  !xsMobileView
                    ? { display: 'inline-block', marginRight: '1rem' }
                    : { display: 'block', margin: '0 auto' }
                }
              />
            )}

            <ButtonHex
              hoverEffect
              variant="gold"
              height={xsMobileView ? 54 : 61}
              width={xsMobileView ? 200 : 185}
              title={tCommon('common.nav.faqs')}
              textStyle={{ textTransform: 'uppercase', fontSize: xsMobileView ? '.7rem' : '.8rem' }}
              style={!xsMobileView ? { display: 'inline-block' } : { display: 'block', margin: '1rem auto 0 auto' }}
              onClick={() => handleOnGoTo('/home#faqs')}
            />
          </Styled.ButtonsContainer>
        </Styled.CopyContainer>
      </Styled.Overlay>
    </Styled.Root>
  );
};

export default SectionHero;
