import { FC, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import useProgressiveWebAppContext from 'src/hooks/useProgressiveWebAppContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import { useGameClientConfig } from 'src/hooks/useGameClientConfig';
import { useGameClientResize } from 'src/hooks/useGameClientResize';
import { getGameColors } from 'src/tools/getGameColors';
import MultipleGameInstancesModal from 'src/components/modals/MultipleGameInstancesModal';
import SaveToPhoneModal from 'src/components/modals/SaveToPhoneModal';
import GameClient from 'src/components/game/GameClient';
import ExitTheatreModeButton from 'src/components/game/ExitTheatreModeButton';
import GameAccessCountdown from 'src/components/game/GameAccessCountdown';
import ButtonDiscordJoin from 'src/components/input/ButtonDiscordJoin';
import ButtonLogin from 'src/components/input/ButtonLogin';
import ButtonPrimary from 'src/components/input/ButtonPrimary';
import Styled from 'src/components/game/Game.styles';

interface Background {
  path: string;
}

const Game: FC = () => {
  const { showSaveToPhoneModal } = useProgressiveWebAppContext();
  const {
    dimensions,
    iFrameBlobObjectURL,
    showGamePage,
    gameAvailable,
    gameClientColor,
    theatreMode,
    setTheatreMode,
    fullscreen,
    gameConfigError,
    gameConfigErrorMessage,
    isGameClientDisabledByDebug,
    isGameClientDisabledByMultipleInstances,
    setIsGameClientDisabledByMultipleInstances,
  } = useGameClientContext();
  const [inView, setInView] = useState(false);
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tPlay } = useTranslation(nameSpace.play);
  const navigateExternal = useAppNavigateExternal();
  const gameAccessAllowed = currentProfile?.features?.gameAccess?.allowed;
  const backgrounds: Background[] = tPlay('play.backgrounds', { returnObjects: true });
  const randomColors = getGameColors(gameClientColor);
  const randomBackground = backgrounds[gameClientColor];

  // Listen for changes to the game client config
  useGameClientConfig();

  // Listen for window resize to resize canvas
  useGameClientResize();

  // Listen for escape key press to exit fullscreen
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (theatreMode) setTheatreMode(false);
      }
    };

    document.addEventListener('keydown', handleKeydown, false);

    return () => {
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [theatreMode, setTheatreMode]);

  return (
    <Styled.Root
      colors={randomColors}
      show={showGamePage}
      theatreMode={theatreMode}
      style={{ backgroundImage: `url(/assets/${randomBackground.path})` }}
    >
      <MultipleGameInstancesModal />
      <SaveToPhoneModal />

      <Styled.UnderHeaderContainer className="under-header-container" theatreMode={theatreMode}>
        <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
          <Styled.GameGradientContainer
            dimensions={dimensions}
            className={`game-gradient-container inview animated ${inView ? 'fadeIn' : ''}`}
            colors={randomColors}
            theatreMode={theatreMode}
          >
            {theatreMode && <ExitTheatreModeButton />}

            {!authenticated || !gameAccessAllowed || gameConfigError ? (
              <Styled.GameContainer className="game-container" dimensions={dimensions}>
                <GameAccessCountdown colors={randomColors} />

                {!authenticated && (
                  <Styled.NoGameContainer>
                    <Styled.NoGameTitle
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(tPlay('play.blocked.unauthorized.title')),
                      }}
                    />
                    <Styled.NoGameSubtitle>{tPlay('play.blocked.unauthorized.subtitle.1')}</Styled.NoGameSubtitle>

                    <ButtonLogin
                      useMobileIcon={false}
                      width={170}
                      height={45}
                      style={{ display: 'inline-block', marginTop: '1.5rem' }}
                    />
                  </Styled.NoGameContainer>
                )}

                {authenticated && !gameAccessAllowed && (
                  <Styled.NoGameContainer>
                    <Styled.NoGameTitle
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(tPlay('play.blocked.user.access.title')),
                      }}
                    />
                    <Styled.NoGameSubtitle>
                      {gameConfigErrorMessage === ''
                        ? tPlay('play.blocked.user.access.subtitle.1')
                        : gameConfigErrorMessage}
                    </Styled.NoGameSubtitle>

                    <ButtonDiscordJoin
                      variant={'white'}
                      height={45}
                      cornerOffset={13}
                      onClick={() => {
                        navigateExternal(tCommon('common.social.discord'), {
                          newWindow: true,
                          pooleData: { pageName: 'Discord', fromContext: 'game' },
                        });
                      }}
                      style={{ margin: '1.5rem auto 0 auto' }}
                      textStyle={{ fontSize: '12px' }}
                    />
                  </Styled.NoGameContainer>
                )}

                {authenticated && gameAccessAllowed && gameConfigError && (
                  <Styled.NoGameContainer>
                    <Styled.NoGameTitle
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(tPlay('play.blocked.error.title')),
                      }}
                    />
                    <Styled.NoGameSubtitle>
                      {gameConfigErrorMessage === '' ? tPlay('play.blocked.error.subtitle.1') : gameConfigErrorMessage}
                    </Styled.NoGameSubtitle>
                  </Styled.NoGameContainer>
                )}
              </Styled.GameContainer>
            ) : (
              <Styled.GameContainer className="game-container" theatreMode={theatreMode} dimensions={dimensions}>
                {!theatreMode && !fullscreen && <GameAccessCountdown colors={randomColors} />}

                {iFrameBlobObjectURL &&
                  !showSaveToPhoneModal &&
                  !isGameClientDisabledByDebug &&
                  !isGameClientDisabledByMultipleInstances && <GameClient />}

                {isGameClientDisabledByDebug && (
                  <Styled.NoGameOverlayContainer>
                    <Styled.NoGameContainer>
                      <Styled.NoGameTitle>Game Client Disabled (Debug Widget)</Styled.NoGameTitle>
                      <Styled.NoGameSubtitle>
                        The game client has been disabled through the debug widget. Re-enable the game client to gain
                        access.
                      </Styled.NoGameSubtitle>
                    </Styled.NoGameContainer>
                  </Styled.NoGameOverlayContainer>
                )}

                {isGameClientDisabledByMultipleInstances && (
                  <Styled.NoGameOverlayContainer>
                    <Styled.NoGameContainer>
                      <Styled.NoGameTitle>{tPlay('play.blocked.multiple.instances.title')}</Styled.NoGameTitle>
                      <Styled.NoGameSubtitle>
                        {tPlay('play.blocked.multiple.instances.subtitle.1')}
                      </Styled.NoGameSubtitle>

                      <ButtonPrimary
                        className="game-client-play-btn"
                        hideBackgroundImg
                        enablePulseAnimation
                        title={tCommon('common.play.now')}
                        width={'140px'}
                        height={'44px'}
                        textStyle={{ fontSize: '1rem', fontWeight: '400' }}
                        style={{ marginTop: '1.5rem' }}
                        onClick={() => setIsGameClientDisabledByMultipleInstances(false)}
                      />
                    </Styled.NoGameContainer>
                  </Styled.NoGameOverlayContainer>
                )}

                {!gameAvailable && (
                  <Styled.NoGameOverlayContainer>
                    <Styled.NoGameContainer>
                      <Styled.NoGameTitle
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(tPlay('play.blocked.availability.title')),
                        }}
                      />
                      <Styled.NoGameSubtitle>{tPlay('play.blocked.availability.subtitle.1')}</Styled.NoGameSubtitle>

                      <ButtonDiscordJoin
                        variant={'white'}
                        height={45}
                        cornerOffset={13}
                        onClick={() => {
                          navigateExternal(tCommon('common.social.discord'), {
                            newWindow: true,
                            pooleData: { pageName: 'Discord', fromContext: 'game' },
                          });
                        }}
                        style={{ margin: '1.5rem auto 0 auto' }}
                        textStyle={{ fontSize: '12px' }}
                      />
                    </Styled.NoGameContainer>
                  </Styled.NoGameOverlayContainer>
                )}
              </Styled.GameContainer>
            )}
          </Styled.GameGradientContainer>
        </Waypoint>
      </Styled.UnderHeaderContainer>
    </Styled.Root>
  );
};

export default Game;
