import { FC, ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Header from 'src/components/header/Header';
import LegalNoticeBanner from 'src/components/banners/LegalNoticeBanner';
import LegalNoticeModal from 'src/components/modals/LegalNoticeModal';
import Game from 'src/components/game/Game';
// import CookiePrivacy from 'src/components/cookies/CookiePrivacy';
import Footer from 'src/components/footer/Footer';
import Snackbar from 'src/components/feedback/Snackbar';
import DebugWidget from 'src/components/debug-widget/DebugWidget';
import WebMetrics from 'src/components/web-metrics/WebMetrics';
// import Spine from 'src/components/spine/Spine';
import Styled from 'src/components/layout/BasePage.styles';
import { useLocation } from 'react-router-dom';
// import FloatingDiscordWidget from 'src/components/discord/FloatingDiscordWidget';

interface BasePageProps {
  children: ReactNode;
  header?: boolean;
  game?: boolean;
  // cookiesPopup?: boolean;
  footer?: boolean;
}

const BasePage: FC<BasePageProps> = ({ children, header = true, game = true, footer = true }) => {
  const location = useLocation();
  const isAuthPage = location.pathname.indexOf('auth') !== -1;
  // const isHomePage = location.pathname.indexOf('home') !== -1;

  return (
    <Styled.Root>
      <AnimatePresence>
        <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {header && !isAuthPage && <Header />}

          <LegalNoticeBanner />
          <LegalNoticeModal />

          {children}
          {game && <Game />}
          {footer && !isAuthPage && <Footer />}

          <Snackbar />
          <DebugWidget />
          <WebMetrics />
          {/* {cookiesPopup && <CookiePrivacy />} */}
          {/* {isHomePage && (
            <Spine
              skeletonSrc="/assets/spine/Yuna.json"
              atlasSrc="/assets/spine/Yuna.atlas"
              textureSrc="/assets/spine/Yuna.png"
              animationName="Idle"
            />
          )} */}
        </motion.span>
      </AnimatePresence>
    </Styled.Root>
  );
};

export default BasePage;
