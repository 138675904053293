import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getCurrentProfileCollection } from 'src/api/profile/get-current-profile-collection';
import { getProfileCollection } from 'src/api/profile/get-profile-collection';
import { clearCollectionItems, setCollectionItems } from 'src/redux/slices/collection-items/collection-items';
import { logMain } from 'src/modules/logger/logger';

interface UseLoadCollectionProps {
  redirectOnError?: boolean;
  fromContext: string;
}

export const useLoadCollection = ({ redirectOnError = false, fromContext }: UseLoadCollectionProps) => {
  const { playerId = '' } = useParams();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation(nameSpace.common);
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const navigateInternal = useAppNavigateInternal();
  const dispatch = useAppDispatch();

  // Load Page Data
  useEffect(() => {
    logMain.debug('Loading collection');

    setLoading(true);

    // Load Collections
    dispatch(isCurrentProfile ? getCurrentProfileCollection() : getProfileCollection({ urlParams: { playerId } }))
      .then(data => {
        if (data && data.items && data.items.length > 0) {
          dispatch(setCollectionItems(data.items));
        } else if (data && data.items && data.items.length === 0) {
          dispatch(clearCollectionItems());
        }

        setLoading(false);
      })
      .catch(() => {
        dispatch(clearCollectionItems());

        if (redirectOnError) {
          navigateInternal('/page-not-found', { pooleData: { fromContext } });
        }

        setError(t('common.error.message.1'));
        setLoading(false);
      });
  }, [dispatch, fromContext, isCurrentProfile, navigateInternal, playerId, redirectOnError, t]);

  return { loading, error };
};
