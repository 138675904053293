import { FC } from 'react';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import Styled from 'src/components/custom-scroll/ScrollButton.styles';

interface ScrollButtonProps {
  disabled: boolean;
  materialIconId: 'arrow_upward' | 'arrow_downward';
  buttonWidth: number;
  buttonHeight: number;
  position: 'top' | 'bottom';
  onClick: () => void;
}

const ScrollButton: FC<ScrollButtonProps> = ({
  disabled,
  materialIconId,
  buttonWidth,
  buttonHeight,
  position,
  onClick,
}) => {
  return (
    <Styled.Root disabled={disabled} buttonWidth={buttonWidth} buttonHeight={buttonHeight} position={position}>
      <PrimaryModalBackground disableLineTexture />

      <Styled.Button disabled={disabled} onClick={onClick}>
        <span className={'material-icons'}>{materialIconId}</span>
      </Styled.Button>
    </Styled.Root>
  );
};

export default ScrollButton;
