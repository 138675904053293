import { FC } from 'react';
import useWebMetricsContext from 'src/hooks/useWebMetricsContext';
import Styled from 'src/components/web-metrics/WebMetrics.styles';
import { config } from 'src/config/config';

const WebMetrics: FC = () => {
  const { enableWebMetrics, memoryUsed, memoryTotal, memoryLimit, snapshots, setSnapshots, clearSnapshots } =
    useWebMetricsContext();

  const handleTakeSnapshot = () => {
    setSnapshots([...snapshots, { memoryUsed, memoryTotal, memoryLimit }]);
  };

  if (!enableWebMetrics || config.is_prod) return null;

  return (
    <Styled.Root>
      <Styled.Title>Web Metrics</Styled.Title>

      <Styled.Row>
        <Styled.Label>Heap Memory Used:</Styled.Label>
        <Styled.Value>{memoryUsed ? `${memoryUsed}MB` : 'N/A'}</Styled.Value>
      </Styled.Row>

      <Styled.Description>
        Represents the amount of memory currently being used by JavaScript objects, including V8 internal objects and
        the call stack. <b>This is the actual memory in use</b>.
      </Styled.Description>

      <Styled.Row>
        <Styled.Label>Heap Memory Total:</Styled.Label>
        <Styled.Value>{memoryTotal ? `${memoryTotal}MB` : 'N/A'}</Styled.Value>
      </Styled.Row>

      <Styled.Description>
        Indicates the current size of the JavaScript heap. This includes not just the memory used by objects but also
        the free space and memory fragmentation.
      </Styled.Description>

      <Styled.Row>
        <Styled.Label>Heap Memory Limit:</Styled.Label>
        <Styled.Value>{memoryLimit ? `${memoryLimit}MB` : 'N/A'}</Styled.Value>
      </Styled.Row>

      <Styled.Description>
        Represents the maximum size to which the JavaScript heap can grow. This is the limit beyond which the browser
        will not allow the JavaScript heap to grow, potentially leading to webpage failures if the limit is reached.
      </Styled.Description>

      {memoryUsed && memoryTotal && memoryLimit && (
        <>
          {snapshots.map((snapshot, i) => {
            const { memoryUsed: snapMemoryUsed, memoryTotal: snapMemoryTotal } = snapshot;

            return (
              <Styled.Row key={`snapshot-${i}`}>
                <Styled.Value>
                  {`${i + 1}) ${snapMemoryUsed}MB`} <span>of</span> {`${snapMemoryTotal}MB`}{' '}
                  <span>{`(${(
                    (parseInt(snapMemoryUsed, 10) / parseInt(snapMemoryTotal, 10)) *
                    100
                  ).toFixed()}%)`}</span>
                </Styled.Value>
              </Styled.Row>
            );
          })}

          <Styled.Button onClick={handleTakeSnapshot}>Take Snapshot</Styled.Button>
          <Styled.Button onClick={clearSnapshots}>Clear Snapshots</Styled.Button>
        </>
      )}
    </Styled.Root>
  );
};

export default WebMetrics;
