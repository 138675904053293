import { FC, ReactNode, useState, useMemo, useEffect, useRef } from 'react';
import AlertOverrideContext, {
  initialContext,
  IAlertOverrideContext,
} from 'src/context/alert-override/AlertOverride.context';
import { logMain } from 'src/modules/logger/logger';

interface AlertOverrideProviderProps {
  children: ReactNode;
}

const AlertOverrideProvider: FC<AlertOverrideProviderProps> = ({ children }) => {
  const windowAlertRef = useRef(window.alert);
  const [enableAlertOverride, setEnableAlertOverride] = useState<IAlertOverrideContext['enableAlertOverride']>(
    initialContext.enableAlertOverride,
  );
  const [alertOverrideMessage, setAlertOverrideMessage] = useState<IAlertOverrideContext['alertOverrideMessage']>(
    initialContext.alertOverrideMessage,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAlertOverrideMessage = (message: any) => {
    if (message instanceof Error) {
      setAlertOverrideMessage(message.message);
    } else {
      setAlertOverrideMessage(message);
    }

    logMain.info(message);
  };

  useEffect(() => {
    if (enableAlertOverride) {
      window.alert = handleAlertOverrideMessage;
    } else {
      setAlertOverrideMessage(initialContext.alertOverrideMessage);

      window.alert = windowAlertRef.current;
    }
  }, [enableAlertOverride]);

  const value = useMemo(
    () => ({
      enableAlertOverride,
      setEnableAlertOverride,
      alertOverrideMessage,
      setAlertOverrideMessage,
    }),
    [enableAlertOverride, setEnableAlertOverride, alertOverrideMessage, setAlertOverrideMessage],
  );

  return <AlertOverrideContext.Provider value={value}>{children}</AlertOverrideContext.Provider>;
};

export default AlertOverrideProvider;
