import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  background-color: black;
  display: block;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: ${customThemeOptions.custom.zIndex.legalNoticeBanner};

  & .MuiAlert-icon {
    padding-top: 9px;
  }

  @media (max-width: 1000px) {
    top: 4.5rem;
  }
`;

const BannerTextContainer = styled('div')`
  display: block;

  & > div > button {
    display: none;
  }

  @media (max-width: 1200px) {
    & > div > button {
      display: inline-block;
    }
  }
`;

const BannerText = styled('div')`
  display: inline-block;
  vertical-align: middle;
  font-family: 'Barlow';
  font-size: 1rem;
  font-weight: 400;
`;

const ActionsContainer = styled('div')`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 1.2rem;
  right: 3rem;

  @media (max-width: 1200px) {
    & > button {
      display: none;
    }
  }
`;

const CloseButton = styled('div')`
  display: block;
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 0.2rem;
  position: absolute;
  top: 1.1rem;
  right: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #ffffff1c;
  }

  & .material-icons {
    font-size: 1.4rem;
    color: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default {
  Root,
  BannerTextContainer,
  BannerText,
  ActionsContainer,
  CloseButton,
};
