/* eslint-disable no-plusplus */
import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { LocalStorage } from 'src/local-storage/local-storage';
import LegalNoticeContext, { initialContext, ILegalNoticeContext } from 'src/context/legal-notice/LegalNotice.context';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { applyLegalAction } from 'src/api/legal/apply-legal-action';
import { config } from 'src/config/config';
import { logMain } from 'src/modules/logger/logger';

interface LegalNoticeProviderProviderProps {
  children: ReactNode;
}

const LegalNoticeProvider: FC<LegalNoticeProviderProviderProps> = ({ children }) => {
  const changeNoticeEnabled = config.legal.change_notice_enabled;
  const changeNoticeVariant = config.legal.change_notice_variant;
  const legalNoticeAcknowledged = LocalStorage.get('legal_notice_acknowledged') === 'true';
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const [showLegalNoticeBanner, setShowLegalNoticeBanner] = useState<ILegalNoticeContext['showLegalNoticeBanner']>(
    initialContext.showLegalNoticeBanner,
  );
  const [legalNoticeBannerVariant, setLegalNoticeBannerVariant] = useState<
    ILegalNoticeContext['legalNoticeBannerVariant']
  >(initialContext.legalNoticeBannerVariant);
  const [showLegalNoticePopup, setShowLegalNoticePopup] = useState<ILegalNoticeContext['showLegalNoticePopup']>(
    initialContext.showLegalNoticePopup,
  );
  const [legalNoticePopupVariant, setLegalNoticePopupVariant] = useState<
    ILegalNoticeContext['legalNoticePopupVariant']
  >(initialContext.legalNoticePopupVariant);
  const [submitLegalNoticeView, setSubmitLegalNoticeView] = useState<ILegalNoticeContext['submitLegalNoticeView']>(
    initialContext.submitLegalNoticeView,
  );
  const [submitLegalNoticeAgree, setSubmitLegalNoticeAgree] = useState<ILegalNoticeContext['submitLegalNoticeAgree']>(
    initialContext.submitLegalNoticeAgree,
  );
  const [legalSubmissionError, setLegalSubmissionError] = useState<ILegalNoticeContext['legalSubmissionError']>(
    initialContext.legalSubmissionError,
  );
  const { t } = useTranslation(nameSpace.common);
  const dispatch = useAppDispatch();

  // Depending on auth and legal states, show or hide banners/popups
  useEffect(() => {
    if (authenticated) {
      const legalStates = currentProfile?.features?.legal?.states ? currentProfile?.features?.legal?.states : [];
      // let tView = false; // Terms need veiwing
      // let pView = false; // Privacy needs viewing
      let tAgree = false; // Terms need agreeing
      let pAgree = false; // Privacy needs agreeing

      // Loop through legal states and check if user needs to view or agree to any legal documents
      for (let i = 0; i < legalStates.length; i++) {
        const legalState = legalStates[i];

        // if (legalState.requiredAction === 'view') {
        //   if (legalState.document === 'terms_of_service') {
        //     tView = true;
        //   } else if (legalState.document === 'privacy_policy') {
        //     pView = true;
        //   }
        // }

        if (legalState.requiredAction === 'agree') {
          if (legalState.document === 'terms_of_service') {
            tAgree = true;
          } else if (legalState.document === 'privacy_policy') {
            pAgree = true;
          }
        }
      }

      // Set if banner needs to be shown and what variant
      // if (tView && pView) {
      //   setLegalNoticeBannerVariant('both');
      //   setShowLegalNoticeBanner(true);
      // } else if (tView) {
      //   setLegalNoticeBannerVariant('terms');
      //   setShowLegalNoticeBanner(true);
      // } else if (pView) {
      //   setLegalNoticeBannerVariant('privacy');
      //   setShowLegalNoticeBanner(true);
      // }

      // Set if popup needs to be shown and what variant
      if (tAgree && pAgree) {
        setLegalNoticePopupVariant('both');
        setShowLegalNoticePopup(true);
      } else if (tAgree) {
        setLegalNoticePopupVariant('terms');
        setShowLegalNoticePopup(true);
      } else if (pAgree) {
        setLegalNoticePopupVariant('privacy');
        setShowLegalNoticePopup(true);
      }
    }

    // If user is logged out, reset & hide the legal notice popup
    if (!authenticated) {
      setLegalNoticePopupVariant(initialContext.legalNoticePopupVariant);
      setShowLegalNoticePopup(false);
    }

    // if (!authenticated) {
    if (changeNoticeEnabled) {
      if (!legalNoticeAcknowledged) {
        // If user has not acknowledged the legal notice, show the legal notice banner
        switch (changeNoticeVariant) {
          case 'both':
            setLegalNoticeBannerVariant('both');
            setShowLegalNoticeBanner(true);
            break;
          case 'terms':
            setLegalNoticeBannerVariant('terms');
            setShowLegalNoticeBanner(true);
            break;
          case 'privacy':
            setLegalNoticeBannerVariant('privacy');
            setShowLegalNoticeBanner(true);
            break;
          default:
            setLegalNoticeBannerVariant('both');
            setShowLegalNoticeBanner(false);
            break;
        }
      } else {
        // If user has already acknowledged the legal notice, reset & hide the legal notice banner
        setLegalNoticeBannerVariant(initialContext.legalNoticeBannerVariant);
        setShowLegalNoticeBanner(false);
      }
    } else {
      // If change notice is disabled, reset & hide the legal notice banner
      setLegalNoticeBannerVariant(initialContext.legalNoticeBannerVariant);
      setShowLegalNoticeBanner(false);
    }
    // }
  }, [authenticated, currentProfile, legalNoticeAcknowledged, changeNoticeEnabled, changeNoticeVariant]);

  // Submit request to acknowledge viewing legal notice
  useEffect(() => {
    if (submitLegalNoticeView) {
      setSubmitLegalNoticeView(false);

      // if (authenticated) {
      //   const legalStates = currentProfile?.features?.legal?.states ? currentProfile?.features?.legal?.states : [];
      //   const filteredLegalStates = legalStates.filter(state => state.requiredAction === 'view');
      //   const mappedLegalStates = filteredLegalStates.map(state => ({
      //     action: state.requiredAction,
      //     document: state.document,
      //     version: state.version,
      //   }));

      //   if (mappedLegalStates.length > 0) {
      //     dispatch(
      //       applyLegalAction({
      //         data: { states: mappedLegalStates },
      //       }),
      //     )
      //       .then(() => {
      //         // Reset & hide the legal notice banner
      //         setLegalNoticeBannerVariant(initialContext.legalNoticeBannerVariant);
      //         setShowLegalNoticeBanner(false);
      //         setLegalSubmissionError(initialContext.legalSubmissionError);
      //       })
      //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
      //       .catch((e: any) => {
      //         logMain.debug(e);
      //         setLegalSubmissionError(t('common.error.message.1'));
      //       });
      //   } else {
      //     // Reset & hide the legal notice banner
      //     setLegalNoticeBannerVariant(initialContext.legalNoticeBannerVariant);
      //     setShowLegalNoticeBanner(false);
      //     setLegalSubmissionError(initialContext.legalSubmissionError);
      //   }
      // } else {
      LocalStorage.set('legal_notice_acknowledged', 'true');
      // }
    }
  }, [authenticated, currentProfile?.features?.legal?.states, dispatch, submitLegalNoticeView, t]);

  // Submit request to acknowledge agreeing to legal changes
  useEffect(() => {
    if (submitLegalNoticeAgree) {
      setSubmitLegalNoticeAgree(false);

      const legalStates = currentProfile?.features?.legal?.states ? currentProfile?.features?.legal?.states : [];
      const filteredLegalStates = legalStates.filter(state => state.requiredAction === 'agree');
      const legalActions = filteredLegalStates.map(state => ({
        action: state.requiredAction,
        document: state.document,
        version: state.version,
      }));

      if (legalActions.length > 0) {
        dispatch(
          applyLegalAction({
            data: { actions: legalActions },
          }),
        )
          .then(() => {
            // Reset & hide the legal notice banner
            setLegalNoticePopupVariant(initialContext.legalNoticePopupVariant);
            setShowLegalNoticePopup(false);
            setLegalSubmissionError(initialContext.legalSubmissionError);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((e: any) => {
            logMain.debug(e);
            setLegalSubmissionError(t('common.error.message.1'));
          });
      } else {
        // Reset & hide the legal notice banner
        setLegalNoticePopupVariant(initialContext.legalNoticePopupVariant);
        setShowLegalNoticePopup(false);
        setLegalSubmissionError(initialContext.legalSubmissionError);
      }
    }
  }, [authenticated, currentProfile?.features?.legal?.states, dispatch, submitLegalNoticeAgree, t]);

  const value = useMemo(
    () => ({
      showLegalNoticeBanner,
      setShowLegalNoticeBanner,
      legalNoticeBannerVariant,
      setLegalNoticeBannerVariant,
      showLegalNoticePopup,
      setShowLegalNoticePopup,
      legalNoticePopupVariant,
      setLegalNoticePopupVariant,
      submitLegalNoticeView,
      setSubmitLegalNoticeView,
      submitLegalNoticeAgree,
      setSubmitLegalNoticeAgree,
      legalSubmissionError,
      setLegalSubmissionError,
    }),
    [
      showLegalNoticeBanner,
      setShowLegalNoticeBanner,
      legalNoticeBannerVariant,
      setLegalNoticeBannerVariant,
      showLegalNoticePopup,
      setShowLegalNoticePopup,
      legalNoticePopupVariant,
      setLegalNoticePopupVariant,
      submitLegalNoticeView,
      setSubmitLegalNoticeView,
      submitLegalNoticeAgree,
      setSubmitLegalNoticeAgree,
      legalSubmissionError,
      setLegalSubmissionError,
    ],
  );

  return <LegalNoticeContext.Provider value={value}>{children}</LegalNoticeContext.Provider>;
};

export default LegalNoticeProvider;
