/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
// import useViewportContext from 'src/hooks/useViewportContext';
import useTutorialContext from 'src/hooks/useTutorialContext';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getRarityNameFromEnum } from 'src/tools/getRarityNameFromEnum';
import { getRarityColor } from 'src/tools/getRarityColor';
import { formatPrice } from 'src/tools/formatPrice';
import BaseModal from 'src/components/modals/BaseModal';
import PackOpeningModal from 'src/components/modals/PackOpeningModal';
import ListNFTModal from 'src/components/modals/ListNFTModal';
import DelistNFTModal from 'src/components/modals/DelistNFTModal';
import SecondaryPurchaseModal from 'src/components/modals/SecondaryPurchaseModal';
import TutorialCursor from 'src/components/tutorial-cursor/TutorialCursor';
import ATCIcon from 'src/components/icons/ATCIcon';
import StarLevel from 'src/components/data-display/StarLevel';
import { useMediaQuery, useTheme } from '@mui/material';
import { config } from 'src/config/config';
import Styled from 'src/components/collectibles/NFTPreviewMetadata.styles';
// import { logMain } from 'src/modules/logger/logger';

interface GetMetadataHoverabilityProps {
  isCurrentProfile: boolean;
  listed: boolean;
  isPack: boolean;
  marketplaceEnabled: boolean;
}

const getMetadataHoverability = ({
  isCurrentProfile,
  listed,
  isPack,
  marketplaceEnabled,
}: GetMetadataHoverabilityProps) => {
  if (!marketplaceEnabled) {
    if (isCurrentProfile && isPack) return true;
  } else {
    if (isCurrentProfile) return true;
    if (!isCurrentProfile && listed) return true;
  }

  return false;
};

interface RarityIcon {
  name: string;
  desc: string;
  title: string;
  width: string;
  height: string;
  path: string;
}

interface RarityIcons {
  starter: RarityIcon;
  promo: RarityIcon;
  common: RarityIcon;
  uncommon: RarityIcon;
  rare: RarityIcon;
  ultraRare: RarityIcon;
  epic: RarityIcon;
  legendary: RarityIcon;
}

interface NFTPreviewProps {
  nft: Listing | CollectionItem;
  // position: number;
  firstStarterPack: boolean;
  style?: React.CSSProperties;
}

const NFTPreviewMetadata: FC<NFTPreviewProps> = ({ nft, firstStarterPack, style = {} }) => {
  // const bgRef = useRef<HTMLDivElement>(null);
  // const { viewportWidth, viewportHeight } = useViewportContext();
  const { tutorialState } = useTutorialContext();
  const { enableGameOverlay, navigateGameOverlay } = useGameOverlayContext();
  const [packOpeningConfirmation, setPackOpeningConfirmation] = useState(false);
  // const [bgSize, setBgSize] = useState<number>(0);
  const [packOpeningModal, setPackOpeningModal] = useState(false);
  const [listingModalOpen, setListingModalOpen] = useState(false);
  const [delistingModalOpen, setDelistingModalOpen] = useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const currentProfile = useAppSelector(state => state.currentProfile.currentProfile);
  const navigateInternal = useAppNavigateInternal();
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down(900));

  // Parse NFT data
  const isPack = nft?.type === 'pack';
  const packOpenVideoUrl = nft?.packOpenVideoUrl || '';
  const packLoopVideoUrl = nft?.packLoopVideoUrl || '';
  const packTier = nft?.packTier;
  const packOpenable = !!nft?.packOpenable;
  const listed = !!nft?.listed;
  const listable = !!nft?.listable;
  const listingStatus = nft?.listingStatus;
  const price = formatPrice(nft?.price, { decimals: 0, hideSymbol: true });
  const priceUSD = formatPrice((nft?.price || 0) * config.app_variables.lc_conversion);
  const rarityName: string | undefined = getRarityNameFromEnum(nft?.heroRarity || 0);
  const rarityIcons: RarityIcons = t(`common.rarity.icons`, { returnObjects: true });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rarityIcon: RarityIcon = rarityIcons[rarityName as keyof RarityIcons] as any;
  const rarityColor = getRarityColor(rarityName);
  const isCurrentProfile = currentProfile.id === nft?.playerId;
  const marketplaceEnabled = currentProfile?.features?.marketplace?.enabled;
  const tutorialEnabled =
    enableGameOverlay && tutorialState.starterOpenPack && packTier === 'starter' && firstStarterPack;
  // Determine if NFT has actions available (Allow height increase on hover)
  const hoverable = getMetadataHoverability({
    isCurrentProfile,
    listed,
    isPack,
    marketplaceEnabled,
  });

  // logMain.debug({ position, firstStarterPack });

  const handleGoToNFTDetails = () => {
    if (enableGameOverlay) {
      navigateGameOverlay({
        page: 'nft',
        params: {
          seriesId: nft?.seriesId,
          serialNumber: nft?.serialNumber.toString(),
          seriesName: nft?.seriesName,
        },
      });
    } else {
      navigateInternal(`/nft/${nft?.seriesId}/${nft?.serialNumber}`, { pooleData: { fromContext: 'nft_preview' } });
    }
  };

  // NFT Pack Opening Functions
  const handleOpenPackOpenConfirmation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.OpenPackStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_preview',
      nonfungibleId: nft?.id || '',
      packTier: nft?.packTier || '',
    });

    setPackOpeningConfirmation(true);
  };

  const handleSubmitPackOpenConfirmation = () => {
    setPackOpeningConfirmation(false);
    setPackOpeningModal(true);
  };

  const handleClosePackOpening = () => {
    setPackOpeningConfirmation(false);
    setPackOpeningModal(false);
  };

  // NFT Listing Functions
  const handleOpenListingModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.CreateListingStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_preview',
      nonfungibleId: nft?.id || '',
    });

    setListingModalOpen(true);
  };

  const handleCloseListingModal = async () => {
    await setListingModalOpen(false);
  };

  // NFT Delisting Functions
  const handleOpenDelistingModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.RemoveListingStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_preview',
      nonfungibleId: nft?.id || '',
      listingId: nft?.listingId || '',
      priceLC: nft?.price || 0,
      priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    setDelistingModalOpen(true);
  };

  const handleCloseDelistingModal = async () => {
    await setDelistingModalOpen(false);
  };

  // NFT Remove Listing Functions
  const handleOpenPurchaseModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.SecondaryPurchaseStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_preview',
      nonfungibleId: nft?.id || '',
      listingId: nft?.listingId || '',
      priceLC: (nft?.price || 0).toString(),
      priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    setPurchaseModalOpen(true);
  };

  const handleClosePurchaseModal = async () => {
    await setPurchaseModalOpen(false);
  };

  // useEffect(() => {
  //     if (bgRef?.current) {
  //       const { clientWidth = 0, clientHeight = 0 } = bgRef.current;

  //       setBgSize(Math.max(clientWidth, clientHeight));
  //     }
  // }, [viewportWidth, viewportHeight]);

  return (
    <>
      <ListNFTModal nonfungibleId={nft?.id || ''} open={listingModalOpen} onClose={handleCloseListingModal} />

      <DelistNFTModal nft={nft} open={delistingModalOpen} onClose={handleCloseDelistingModal} />

      <SecondaryPurchaseModal nft={nft} open={purchaseModalOpen} onClose={handleClosePurchaseModal} />

      {isPack && packTier && (
        <>
          <BaseModal
            open={packOpeningConfirmation}
            onClose={handleClosePackOpening}
            heading={t('common.popup.confirmation.pack.heading')}
            subheading={t('common.popup.confirmation.pack.subheading')}
            secondaryButton={t('common.term.no')}
            secondaryOnClick={handleClosePackOpening}
            primaryButton={t('common.term.yes')}
            primaryOnClick={handleSubmitPackOpenConfirmation}
          />

          <PackOpeningModal
            id={nft?.id}
            tier={packTier}
            open={packOpeningModal}
            setOpen={setPackOpeningModal}
            videoOpenUrl={packOpenVideoUrl}
            videoLoopUrl={packLoopVideoUrl}
          />
        </>
      )}

      <Styled.Root id="nft-preview-metadata" onClick={handleGoToNFTDetails} style={style}>
        <Styled.MainContainer
          // ref={bgRef}
          isPack={isPack}
          mobileBreakpoint={mobileBreakpoint}
          hoverable={hoverable}
          tutorialEnabled={tutorialEnabled}
        >
          <Styled.Name isPack={isPack}>{nft?.seriesName || '- -'}</Styled.Name>

          {!isPack && rarityIcon && (
            <Styled.RarityIcon
              src={`/assets/${rarityIcon.path}`}
              alt={rarityIcon.title}
              title={rarityIcon.title}
              width={rarityIcon.width}
              height={rarityIcon.height}
            />
          )}

          {!isPack && !rarityIcon && (
            <Styled.RarityIcon
              src={`/assets/${rarityIcons.starter.path}`}
              alt={rarityIcons.starter.title}
              title={rarityIcons.starter.title}
              width={rarityIcons.starter.width}
              height={rarityIcons.starter.height}
            />
          )}

          <Styled.MetaDetailsContainer isPack={isPack}>
            {nft?.serialNumber && nft?.maxSerialNumber ? (
              <Styled.SerialNumber isPack={isPack}>
                {`#${nft?.serialNumber} ${t('common.term.of')} ${nft?.maxSerialNumber}`}
              </Styled.SerialNumber>
            ) : (
              <Styled.SerialNumber isPack={isPack}>{`1 ${t('common.term.of')} 1`}</Styled.SerialNumber>
            )}

            {!isPack && nft?.heroRarity !== null && rarityName && (
              <Styled.RarityName rarityColor={rarityColor}>{rarityIcon.name}</Styled.RarityName>
            )}
          </Styled.MetaDetailsContainer>

          {!isPack && <StarLevel level={nft?.heroStarLevel || 0} maxLevel={nft?.heroMaxStarLevel || 0} />}

          {isCurrentProfile ? (
            <Styled.ActionsContainer>
              {isPack &&
                (tutorialEnabled ? (
                  <TutorialCursor
                    enableTutorial={tutorialState.starterOpenPack}
                    style={{
                      flexGrow: '1',
                    }}
                  >
                    <Styled.PrimaryButton disabled={!packOpenable} onClick={handleOpenPackOpenConfirmation}>
                      <span className="material-icons-outlined">style</span>
                      {/* <span>{t('common.pack.open')}</span> */}
                      <span>{t('common.term.open')}</span>
                    </Styled.PrimaryButton>
                  </TutorialCursor>
                ) : (
                  <Styled.PrimaryButton
                    disabled={
                      !packOpenable ||
                      (enableGameOverlay &&
                        tutorialState.starterOpenPack &&
                        (packTier !== 'starter' || (packTier === 'starter' && !firstStarterPack)))
                    }
                    onClick={handleOpenPackOpenConfirmation}
                  >
                    <span className="material-icons-outlined">style</span>
                    {/* <span>{t('common.pack.open')}</span> */}
                    <span>{t('common.term.open')}</span>
                  </Styled.PrimaryButton>
                ))}

              {marketplaceEnabled &&
                (listed ? (
                  <Styled.PrimaryButton
                    disabled={!marketplaceEnabled || !nft?.listingId}
                    onClick={handleOpenDelistingModal}
                  >
                    <span className="material-icons-outlined">highlight_off</span>

                    {nft?.listingId ? (
                      isPack ? (
                        <span>{t('common.term.delist')}</span>
                      ) : (
                        <span>{t('common.term.remove.listing')}</span>
                      )
                    ) : isPack ? (
                      <span>{t('common.term.pending')}</span>
                    ) : (
                      <span>{t('common.term.listing.pending')}</span>
                    )}
                  </Styled.PrimaryButton>
                ) : (
                  <Styled.PrimaryButton disabled={!marketplaceEnabled || !listable} onClick={handleOpenListingModal}>
                    <span className="material-icons-outlined">sell</span>

                    {isPack ? <span>{t('common.term.list')}</span> : <span>{t('common.term.list.for.sale')}</span>}
                  </Styled.PrimaryButton>
                ))}
            </Styled.ActionsContainer>
          ) : (
            <Styled.ActionsContainer>
              {marketplaceEnabled && listed && (
                <Styled.PrimaryButton
                  disabled={!marketplaceEnabled || listingStatus === 'pending'}
                  onClick={handleOpenPurchaseModal}
                >
                  {listingStatus === 'pending' ? (
                    <>
                      <span className="material-icons-outlined">restore</span>
                      <span>{t('common.term.purchase.pending')}</span>
                    </>
                  ) : (
                    <>
                      <span className="material-icons-outlined">shopping_cart</span>
                      <span>{t('common.term.purchase')}</span>
                    </>
                  )}
                </Styled.PrimaryButton>
              )}
            </Styled.ActionsContainer>
          )}
        </Styled.MainContainer>

        {nft?.price && (
          <Styled.PriceContainer>
            <Styled.PricePrimary>
              <ATCIcon size={14} style={{ margin: '0 .3rem 2px 0' }} />
              {`${price} ${t('common.project.currency.shorthand')}`}
            </Styled.PricePrimary>

            <Styled.PriceSecondary>{`${priceUSD} ${t('common.currency.usd')}`}</Styled.PriceSecondary>
          </Styled.PriceContainer>
        )}
      </Styled.Root>
    </>
  );
};

export default NFTPreviewMetadata;
