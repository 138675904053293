import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useQuery } from 'src/hooks/useQuery';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
// import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { GTMEvents } from 'src/modules/gtm/events';
import Styled from 'src/components/header/MenuLeftDesktop.styles';

const MenuLeftDesktop: FC = () => {
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t } = useTranslation(nameSpace.common);
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const navigateInternal = useAppNavigateInternal();
  // const navigateExternal = useAppNavigateExternal();
  const leaderboardsEnabled = currentProfile?.features?.leaderboards?.enabled;

  const handleOnGoTo = (path: string, gtmEvent?: () => void) => {
    navigateInternal(path, { pooleData: { fromContext: 'header_navigation' }, gtmEvent: gtmEvent || null });
  };

  const checkLinkIsActive = ({ path = '', hash = '' }: { path: string; hash?: string }): boolean => {
    if (hash) {
      if (location.pathname.toLowerCase().indexOf(path) !== -1) {
        if (location.hash.toLowerCase().indexOf(hash) !== -1) {
          return true;
        }
      }
    } else if (location.pathname.toLowerCase().indexOf(path) !== -1) {
      if (!location.hash) {
        return true;
      }
    }

    return false;
  };

  return (
    <Styled.Root>
      <Styled.Link
        active={checkLinkIsActive({ path: 'home' })}
        onClick={() => handleOnGoTo(trim === '1' ? '/home?trim=1' : '/home')}
      >
        <Styled.LinkIndicator />
        <Styled.LinkTitle>{t('common.nav.home')}</Styled.LinkTitle>
      </Styled.Link>

      <Styled.Link
        active={checkLinkIsActive({ path: 'event' })}
        onClick={() => handleOnGoTo(trim === '1' ? '/events?trim=1' : '/events')}
      >
        <Styled.LinkIndicator />
        <Styled.LinkTitle>{t('common.nav.events')}</Styled.LinkTitle>
      </Styled.Link>

      {authenticated && (
        <Styled.Link active={checkLinkIsActive({ path: 'marketplace' })} onClick={() => handleOnGoTo('/marketplace')}>
          <Styled.LinkIndicator />
          <Styled.LinkTitle>{t('common.nav.marketplace')}</Styled.LinkTitle>
        </Styled.Link>
      )}

      {authenticated && leaderboardsEnabled && (
        <Styled.Link active={checkLinkIsActive({ path: 'leaderboards' })} onClick={() => handleOnGoTo('/leaderboards')}>
          <Styled.LinkIndicator />
          <Styled.LinkTitle>{t('common.nav.leaderboards')}</Styled.LinkTitle>
        </Styled.Link>
      )}

      {!authenticated && (
        <Styled.Link
          active={checkLinkIsActive({ path: 'getting-started' })}
          onClick={() => handleOnGoTo('/getting-started')}
        >
          <Styled.LinkIndicator />
          <Styled.LinkTitle>{t('common.nav.getting.started')}</Styled.LinkTitle>
        </Styled.Link>
      )}

      {!authenticated && (
        <Styled.Link
          active={checkLinkIsActive({ path: 'home', hash: 'roadmap' })}
          onClick={() => {
            GTMEvents.clickBtnRoadmap({ eventContext: 'nav-bar-desktop' });
            handleOnGoTo(trim === '1' ? '/home?trim=1#roadmap' : '/home#roadmap');
          }}
        >
          <Styled.LinkIndicator />
          <Styled.LinkTitle>{t('common.nav.roadmap')}</Styled.LinkTitle>
        </Styled.Link>
      )}

      {/* <Styled.Link
        active={false}
        onClick={() => {
          navigateExternal(t('common.nav.whitepaper.link'), {
            newWindow: true,
            pooleData: { pageName: 'Whitepaper', fromContext: 'header_navigation' },
            gtmEvent: () =>
              GTMEvents.clickBtnWhitepaper({ eventContext: 'header_navigation_desktop', eventIsOutboundLink: true }),
          });
        }}
      >
        <Styled.LinkIndicator />
        <Styled.LinkTitle>{t('common.nav.whitepaper')}</Styled.LinkTitle>
      </Styled.Link> */}

      <Styled.Link active={checkLinkIsActive({ path: 'blog' })} onClick={() => handleOnGoTo('/blog')}>
        <Styled.LinkIndicator />
        <Styled.LinkTitle>{t('common.nav.blog')}</Styled.LinkTitle>
      </Styled.Link>

      <Styled.Link
        active={checkLinkIsActive({ path: 'home', hash: 'faqs' })}
        onClick={() => {
          handleOnGoTo(trim === '1' ? '/home?trim=1#faqs' : '/home#faqs', () =>
            GTMEvents.clickBtnFAQs({ eventContext: 'nav-bar-left-desktop' }),
          );
        }}
      >
        <Styled.LinkIndicator />
        <Styled.LinkTitle>{t('common.nav.faqs')}</Styled.LinkTitle>
      </Styled.Link>
    </Styled.Root>
  );
};

export default MenuLeftDesktop;
