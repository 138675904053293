import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import ButtonHex from 'src/components/input/ButtonHex';
import { useMediaQuery } from '@mui/material';
import Styled from 'src/components/feedback/PageError.styles';

interface PageErrorProps {
  style?: React.CSSProperties;
}

const PageError: FC<PageErrorProps> = ({ style = {} }) => {
  const { t } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();
  const bpMaxHeight500 = useMediaQuery('(max-height: 500px)');

  return (
    <Styled.Root style={style}>
      <Styled.ContentContainer>
        <Styled.Heading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('common.page.error.title')) }} />
        <Styled.Subheading
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('common.page.error.description')) }}
        />

        <ButtonHex
          hoverEffect
          variant="gold"
          width={150}
          height={bpMaxHeight500 ? 44 : 50}
          title={t('common.page.error.action')}
          style={{ display: 'block', margin: '1.5rem auto 0 auto' }}
          textStyle={{ fontSize: bpMaxHeight500 ? '11px' : '13px' }}
          onClick={() => navigateInternal(`/home`, { pooleData: { fromContext: 'page_error' } })}
        />
      </Styled.ContentContainer>
    </Styled.Root>
  );
};

export default PageError;
