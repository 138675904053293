/* eslint-disable @typescript-eslint/no-explicit-any */

'use client';

import { FC, ReactNode } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import Styled from 'src/components/error-boundary/ErrorBoundary.styles';
import { logMain } from 'src/modules/logger/logger';
import ButtonPrimary from 'src/components/input/ButtonPrimary';

interface FallbackRenderProps {
  error: Error;
  resetErrorBoundary: () => void;
}

// FALLBACK COMPONENT: To display when an error occurs
// Call resetErrorBoundary() to reset the error boundary and retry the render.
const Fallback: FC<FallbackRenderProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation(nameSpace.common);

  logMain.error('[ERROR_BOUNDARY]: Fallback rendering...', error);

  return (
    <Styled.Root>
      <Styled.ContentContainer>
        <Styled.Heading
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('common.page.error.boundary.title')) }}
        />
        <Styled.Subheading
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('common.page.error.boundary.description')) }}
        />

        <ButtonPrimary
          type="button"
          title={t('common.term.reload')}
          width={'148px'}
          height={'50px'}
          hideBackgroundImg
          enablePulseAnimation
          fontSize={17}
          textStyle={{ paddingTop: '1px' }}
          style={{
            display: 'block',
            verticalAlign: 'middle',
            margin: '2rem auto 0 auto',
          }}
          onClick={() => resetErrorBoundary()}
        />
      </Styled.ContentContainer>
    </Styled.Root>
  );
};

interface CustomErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: FC<CustomErrorBoundaryProps> = ({ children }) => {
  const onReset = (details: any) => {
    logMain.debug('%c[ERROR_BOUNDARY]: Resetting error boundary.', 'color: orange;', details);
  };

  const onError = (error: Error, info: { componentStack: string }) => {
    logMain.error('[ERROR_BOUNDARY]: Error caught by error boundary.', error, info);
  };

  return (
    <ReactErrorBoundary FallbackComponent={Fallback} onReset={onReset} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
