import { FC, ReactNode } from 'react';
import Styled from './TriangleOutlineContainer.styles';

export interface TriangleOutlineContainerProps {
  children: ReactNode;
  color?: string;
  triangleSize?: number;
  borderWidth?: number;
  triangleScale?: number;
  style?: React.CSSProperties;
}

const TriangleOutlineContainer: FC<TriangleOutlineContainerProps> = ({
  children = null,
  color = '#FEC300',
  triangleSize = 24,
  borderWidth = 2,
  triangleScale = 1.2,
  style = {},
}) => {
  return (
    <Styled.Root triangleSize={triangleSize} style={{ ...style }}>
      <Styled.Box color={color} triangleSize={triangleSize} borderWidth={borderWidth}>
        <Styled.Triangle color={color} triangleSize={triangleSize} direction={1} triangleScale={triangleScale} />
        <Styled.Triangle color={color} triangleSize={triangleSize} direction={-1} triangleScale={triangleScale} />

        {children}
      </Styled.Box>
    </Styled.Root>
  );
};

export default TriangleOutlineContainer;
