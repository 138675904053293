import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { Waypoint } from 'react-waypoint';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getNonfungibleBySeriesAndSerialNumber } from 'src/redux/slices/nonfungibles/nonfungibles';
import { getNFTImage } from 'src/tools/getNFTImage';
import { Backdrop, Modal } from '@mui/material';
import Styled from 'src/components/pages/nft/components/NFTPreview.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface NFTPreviewProps {
  seriesId: string;
  serialNumber: string;
}

const NFTPreview: FC<NFTPreviewProps> = ({ seriesId, serialNumber }) => {
  const [mediaKey, setMediaKey] = useState<string>('animationUrl');
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [inView1, setInView1] = useState<boolean>(false);
  const nonfungible = useAppSelector(state =>
    getNonfungibleBySeriesAndSerialNumber(state, seriesId, parseInt(serialNumber, 10)),
  );
  const { t } = useTranslation(nameSpace.common);
  const { animationUrl = '', image = '' } = nonfungible?.metadata || {};

  const handleClose = () => {
    setFullscreen(false);
  };

  if (!nonfungible) return null;

  return (
    <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated ${inView1 ? 'fadeIn' : ''}`}>
        <Styled.PrimaryAssetContainer>
          {mediaKey === 'animationUrl' && (
            <Styled.PrimaryVideo width="1080" height={'1920'} controls={false} loop autoPlay muted playsInline>
              <source src={animationUrl} type="video/mp4" />
              {t('common.video.support')}
            </Styled.PrimaryVideo>
          )}

          {mediaKey === 'image' && (
            <Styled.PrimaryImg
              src={getNFTImage(`${image}`)}
              width={'100'}
              height={'100'}
              loading="lazy"
              alt={t('common.collectible.alt')}
            />
          )}

          <Styled.FullscreenButton onClick={() => setFullscreen(!fullscreen)}>
            <span className="material-icons-outlined">fullscreen</span>
          </Styled.FullscreenButton>

          {fullscreen && (
            <Modal
              aria-labelledby="svg-dialog"
              aria-describedby="svg-dialog-desc"
              open={fullscreen}
              onClose={handleClose}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              // disableScrollLock
              closeAfterTransition
              sx={{
                zIndex: `${customThemeOptions.custom.zIndex.modals.nftPreview}`,
                padding: '3rem',
                boxSizing: 'border-box',
              }}
            >
              <>
                {mediaKey === 'animationUrl' && (
                  <Styled.PrimaryVideo
                    width="1080"
                    height={'1920'}
                    controls={false}
                    loop
                    autoPlay
                    muted
                    playsInline
                    style={{ borderRadius: '0.3rem' }}
                  >
                    <source src={animationUrl} type="video/mp4" />
                    {t('common.video.support')}
                  </Styled.PrimaryVideo>
                )}

                {mediaKey === 'image' && (
                  <Styled.PrimaryImg
                    src={getNFTImage(`${image}`)}
                    width={'100'}
                    height={'100'}
                    loading="lazy"
                    alt={t('common.collectible.alt')}
                    style={{ borderRadius: '0.3rem' }}
                  />
                )}

                <Styled.CloseButton onClick={handleClose}>
                  <span className="material-icons">close</span>
                </Styled.CloseButton>
              </>
            </Modal>
          )}
        </Styled.PrimaryAssetContainer>

        <Styled.SecondaryAssetsContainer>
          <Styled.SecondaryAssetContainer
            active={mediaKey === 'animationUrl'}
            onClick={() => setMediaKey('animationUrl')}
          >
            <Styled.SecondaryImg
              src={getNFTImage(`${image}`)}
              width={'100'}
              height={'100'}
              loading="lazy"
              alt={t('common.collectible.alt')}
            />

            <Styled.SecondaryVideoOverlay active={mediaKey === 'animationUrl'}>
              <span className="material-icons-outlined">play_circle_filled</span>
            </Styled.SecondaryVideoOverlay>
          </Styled.SecondaryAssetContainer>

          <Styled.SecondaryAssetContainer active={mediaKey === 'image'} onClick={() => setMediaKey('image')}>
            <Styled.SecondaryImg
              src={getNFTImage(`${image}`)}
              width={'100'}
              height={'100'}
              loading="lazy"
              alt={t('common.collectible.alt')}
            />
          </Styled.SecondaryAssetContainer>
        </Styled.SecondaryAssetsContainer>
      </Styled.Root>
    </Waypoint>
  );
};

export default NFTPreview;
