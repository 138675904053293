import { FC } from 'react';
import Styled from 'src/components/data-display/leaderboard/Rank.styles';
// import { logMain } from 'src/modules/logger/logger';

const getStyles = (rank: number, variant: Variant) => {
  const digits = rank.toString().length;
  const size = variant === 'default' ? '3.2rem' : '2.5rem';

  switch (digits) {
    case 1:
      return {
        size,
        fontSize: variant === 'condensed' ? '1.8rem' : '2.2rem',
        letterSpacing: variant === 'condensed' ? '-15px' : '-15px',
      };
    case 2:
      return {
        size,
        fontSize: variant === 'condensed' ? '1.5rem' : '1.8rem',
        letterSpacing: variant === 'condensed' ? '-12px' : '-13px',
      };
    case 3:
      return {
        size,
        fontSize: variant === 'condensed' ? '1.2rem' : '1.5rem',
        letterSpacing: variant === 'condensed' ? '-9px' : '-11px',
      };
    case 4:
      return {
        size,
        fontSize: variant === 'condensed' ? '1rem' : '1.3rem',
        letterSpacing: variant === 'condensed' ? '-7px' : '-11px',
      };
    case 5:
      return {
        size,
        fontSize: variant === 'condensed' ? '0.8rem' : '1rem',
        letterSpacing: variant === 'condensed' ? '-6px' : '-8px',
      };
    case 6:
      return {
        size,
        fontSize: variant === 'condensed' ? '0.7rem' : '0.8rem',
        letterSpacing: variant === 'condensed' ? '-5px' : '-6px',
      };
    default:
      return {
        size,
        fontSize: variant === 'condensed' ? '0.7rem' : '0.8rem',
        letterSpacing: variant === 'condensed' ? '-5px' : '-6px',
      };
  }
};

type Variant = 'condensed' | 'default';

interface RankProps {
  rank: number;
  variant?: Variant;
  style?: React.CSSProperties;
}

const Rank: FC<RankProps> = ({ rank, variant = 'default', style = {} }) => {
  return (
    <Styled.Root variantStyles={getStyles(rank, variant)} style={style}>
      <span>{rank}</span>
    </Styled.Root>
  );
};

export default Rank;
