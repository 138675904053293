import { styled } from '@mui/material';

interface RootProps {
  triangleSize: number;
}

const Root = styled('div')<RootProps>`
  display: block;
  padding: ${({ triangleSize }) => `0 ${triangleSize}px`};
  box-sizing: border-box;
  position: relative;
`;

interface BoxProps {
  color: string;
  triangleSize: number;
  borderWidth: number;
}

const Box = styled('div')<BoxProps>`
  display: block;
  border: ${({ color, borderWidth }) => `${borderWidth}px solid ${color}`};
  box-sizing: border-box;
  position: relative;
`;

interface TriangleProps {
  color: string;
  triangleSize: number;
  direction: number;
  triangleScale: number;
}

const Triangle = styled('div')<TriangleProps>`
  display: block;
  width: 0;
  height: 0;
  border: ${({ triangleSize }) => `${triangleSize}px solid #00000000`};
  border-left: none;
  border-right: ${({ color, triangleSize }) => `${triangleSize}px solid ${color}`};
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: ${({ direction }) => (direction > 0 ? 'auto' : '100%')};
  right: ${({ direction }) => (direction > 0 ? '100%' : 'auto')};
  transform: ${({ direction, triangleScale }) => `translate(0, -50%) scale(${direction}, ${1 * triangleScale})`};
`;

export default { Root, Box, Triangle };
