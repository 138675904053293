import { useContext } from 'react';
import DebugWidgetContext from 'src/context/debug-widget/DebugWidget.context';

const useDebugWidgetContext = () => {
  const debugWidgetContext = useContext(DebugWidgetContext);

  return debugWidgetContext;
};

export default useDebugWidgetContext;
