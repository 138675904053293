import { useContext } from 'react';
import GameClientContext from 'src/context/game-client/GameClient.context';

const useGameClientContext = () => {
  const gameClientContext = useContext(GameClientContext);

  return gameClientContext;
};

export default useGameClientContext;
