import { FC } from 'react';
import { useSwiper } from 'swiper/react';
import { ReactComponent as PrevIcon } from 'src/components/icons/PrevIcon.svg';
import { ReactComponent as NextIcon } from 'src/components/icons/NextIcon.svg';
import Styled from 'src/components/primary-marketplace/components/SwiperNavigation.styles';

interface SwiperNavigationProps {
  swiperIndex: number;
  numSlides: number;
}

const SwiperNavigation: FC<SwiperNavigationProps> = ({ swiperIndex, numSlides }) => {
  const swiper = useSwiper();

  if (numSlides <= 1) return null;

  return (
    <Styled.Root>
      <PrevIcon className={`${swiperIndex === 0 ? 'swiper-nav-disabled' : ''}`} onClick={() => swiper.slidePrev()} />

      <Styled.SlidePosition>{`${swiperIndex + 1} / ${numSlides}`}</Styled.SlidePosition>

      <NextIcon
        className={`${swiperIndex === numSlides - 1 ? 'swiper-nav-disabled' : ''}`}
        onClick={() => swiper.slideNext()}
      />
    </Styled.Root>
  );
};

export default SwiperNavigation;
