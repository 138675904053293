import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import Select from 'src/components/pages/leaderboards/components/Select';
import Styled from 'src/components/pages/leaderboards/components/SelectBar.styles';

const SelectBar: FC = () => {
  const [inView, setInView] = useState(false);
  const { t } = useTranslation(nameSpace.leaderboards);

  return (
    <Styled.Root>
      <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
        <Styled.CenteredContainer className={`inview animated delay-0-5s ${inView ? 'fadeIn' : ''}`}>
          <Styled.Title>{`${t('leaderboards.section.title')}`}</Styled.Title>

          <Select />
        </Styled.CenteredContainer>
      </Waypoint>
    </Styled.Root>
  );
};

export default SelectBar;
