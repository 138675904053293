import { styled } from '@mui/material';

interface RootProps {
  linked: boolean;
}

const Root = styled('div')<RootProps>`
  background-color: ${({ linked }) => (linked ? '#55ff5555' : '#ff555555')};
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  filter: brightness(0.8);
`;

interface IndicatorProps {
  linked: boolean;
}

const Indicator = styled('div')<IndicatorProps>`
  background-color: ${({ linked }) => (linked ? '#55ff55' : '#ff5555')};
  display: inline-block;
  width: 5px;
  height: 5px;
  vertical-align: middle;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(0.8);
`;

export default {
  Root,
  Indicator,
};
