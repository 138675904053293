import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import Styled from 'src/components/pages/getting-started/components/Banner.styles';

const Banner: FC = () => {
  const { t } = useTranslation(nameSpace.gettingStarted);

  return <Styled.Root>{t('getting.started.banner')}</Styled.Root>;
};

export default Banner;
