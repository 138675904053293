import { CSSProperties } from 'react';
import { styled } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';
import { IMediaProps } from './Media';

const Root = styled('div')<{
  active: number;
  height: CSSProperties['height'];
  width: CSSProperties['width'];
  borderradius: IMediaProps['borderRadius'];
  minwidth?: CSSProperties['minWidth'];
  minheight?: CSSProperties['minHeight'];
  maxwidth?: CSSProperties['maxWidth'];
  maxheight?: CSSProperties['maxHeight'];
  border?: CSSProperties['border'];
  aspectratio: CSSProperties['aspectRatio'];
}>`
  max-width: ${({ maxwidth }) => maxwidth};
  min-width: ${({ minwidth }) => minwidth};
  max-height: ${({ maxheight }) => maxheight};
  min-height: ${({ minheight }) => minheight};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  border-radius: ${({ borderradius }) => borderradius};
  border: ${({ active, theme, border = `1px solid ${theme.custom.colors.White}` }) => (active ? border : 'initial')};
  aspect-ratio: ${({ aspectratio }) => aspectratio};
`;

const Placeholder = styled('div')<{ borderradius: IMediaProps['borderRadius'] }>`
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.custom.colors.BgAccentWeak};
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  border-radius: ${({ borderradius }) => borderradius};
  box-sizing: border-box;
`;

const Icon = styled(BaseIcon)`
  color: ${({ theme }) => theme.custom.colors.IconOnAccentWeak};
  position: absolute;
`;

export default { Image, Root, Icon, Placeholder };
