/* eslint-disable no-nested-ternary */
import { styled } from '@mui/material';

interface RootProps {
  tabWidth: number;
  tabMinWidth: number;
  fullWidth: boolean;
  align: 'left' | 'center' | 'right';
}

const Root = styled('div')<RootProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  ${({ align }) => align === 'left' && 'justify-content: flex-start;'};
  ${({ align }) => align === 'center' && 'justify-content: center;'};
  ${({ align }) => align === 'right' && 'justify-content: flex-end;'};

  &::-webkit-scrollbar {
    display: none;
  }

  & > div {
    flex: 0 1 auto;
    width: ${({ fullWidth, tabWidth }) => (fullWidth ? `100%` : tabWidth ? `${tabWidth}px` : 'auto')};
    min-width: ${({ tabWidth, tabMinWidth }) => (tabWidth || !tabMinWidth ? 'auto' : `${tabMinWidth}px`)};
  }

  & > div:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

interface TabProps {
  selected: boolean;
}

const Tab = styled('div')<TabProps>`
  display: inline-block;
  word-break: break-word;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: ${({ selected }) => (selected ? '#ffffff' : '#ffffff99')};
  cursor: pointer;
  transition: color 0.4s ease-in-out;

  /* &:hover {
    color: #fff;
  } */

  & > div {
    width: ${({ selected }) => (selected ? '100%' : '0')};
  }
`;

interface TabIndicatorProps {
  indicatorColor: string;
}

const TabIndicator = styled('div')<TabIndicatorProps>`
  background-color: ${({ indicatorColor }) => indicatorColor};
  display: block;
  height: 3px;
  margin-top: 1rem;
`;

export default {
  Root,
  Tab,
  TabIndicator,
};
