/* eslint-disable @typescript-eslint/no-explicit-any */
import { getLeaderboardConfig } from 'src/api/leaderboards/get-leaderboard-config';
import { getLeaderboardStats } from 'src/api/leaderboards/get-leaderboard-stats';
import { clearLeaderboards, setLeaderboards } from 'src/redux/slices/leaderboards/leaderboards';
import type { AppDispatch } from 'src/redux/store';
import { logMain } from 'src/modules/logger/logger';

const loadLeaderboards = async (dispatch: AppDispatch) => {
  try {
    await dispatch(clearLeaderboards());

    const leaderboardConfig = await dispatch(getLeaderboardConfig());
    const leaderboards = leaderboardConfig?.leaderboards || [];

    // Get leaderboard stats and save leaderboards if they exist
    if (leaderboards.length > 0) {
      const leaderboardIds = leaderboards.map((leaderboard: any) => leaderboard.configId).join(',');
      const leaderboardStats = await dispatch(
        getLeaderboardStats({ queryParams: { leaderboard_ids: leaderboardIds } }),
      );
      const leaderboardsForRedux: any[] = leaderboards.map((leaderboard: any) => {
        const stats =
          leaderboardStats?.stats.find(
            (leaderboardStat: any) => leaderboardStat.leaderboardId === leaderboard.configId,
          ) || {};

        return {
          ...leaderboard,
          ...stats,
          closeTimestampMs: null,
          pagination: {
            nextPageToken: null,
            pageCount: 1,
            pageNumber: 0,
            prevPageToken: null,
          },
          totalPlayers: null,
        };
      });

      dispatch(setLeaderboards(leaderboardsForRedux));
    }
  } catch (e: any) {
    logMain.debug(e);
  }
};

export default loadLeaderboards;
