import { FC } from 'react';
import DOMPurify from 'dompurify';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/modals/BaseModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  heading?: string | Node;
  subheading?: string | Node;
  primaryButton?: string;
  primaryOnClick?: () => void;
  secondaryButton?: string;
  secondaryOnClick?: () => void;
  showProgressBar?: boolean;
}

const BaseModal: FC<BaseModalProps> = ({
  open,
  onClose,
  heading,
  subheading,
  primaryButton,
  primaryOnClick,
  secondaryButton,
  secondaryOnClick,
  showProgressBar = false,
}) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.primary}` }}
    >
      <Fade in={open}>
        <Styled.Root>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            {heading && (
              <Styled.Heading
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(heading),
                }}
              />
            )}

            {subheading && (
              <Styled.SubheadingsContainer>
                <Styled.Subheading
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(subheading),
                  }}
                />
              </Styled.SubheadingsContainer>
            )}

            {showProgressBar && (
              <Styled.ProgressContainer>
                <CircularProgress color="inherit" size={'1.5rem'} />
              </Styled.ProgressContainer>
            )}

            {(primaryButton || secondaryButton) && (
              <Styled.ButtonsContainer>
                {secondaryButton && (
                  <ButtonHex
                    hoverEffect
                    variant={primaryButton ? 'white' : 'gold'}
                    width={mobileView ? 175 : 150}
                    height={45}
                    title={secondaryButton}
                    style={{
                      display: mobileView ? 'block' : 'inline-block',
                      margin: mobileView ? '0 auto 0 auto' : '0',
                    }}
                    onClick={secondaryOnClick || onClose}
                  />
                )}

                {primaryButton && (
                  <ButtonHex
                    hoverEffect
                    variant="gold"
                    width={mobileView ? 175 : 150}
                    height={45}
                    title={primaryButton}
                    style={{
                      display: mobileView ? 'block' : 'inline-block',
                      margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                    }}
                    onClick={primaryOnClick || onClose}
                  />
                )}
              </Styled.ButtonsContainer>
            )}
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default BaseModal;
