import { FC, useState } from 'react';
import Styled from 'src/components/input/CheckboxDiamond.styles';

type Variant = 'white' | 'bronze' | 'silver' | 'gold';
interface CheckboxDiamondProps {
  defaultValue?: boolean;
  clickable?: boolean;
  size?: number;
  variant?: Variant;
  onChange?: (checked: boolean) => void;
  style?: React.CSSProperties;
}

const variantColors = {
  white: '#ffffff',
  bronze: '#b26725',
  silver: '#acacac',
  gold: '#f8c53e',
};

const CheckboxDiamond: FC<CheckboxDiamondProps> = ({
  defaultValue = false,
  clickable = true,
  size = 14,
  variant = 'gold',
  onChange,
  style = {},
}) => {
  const [checked, setChecked] = useState(defaultValue);
  const color = variantColors[variant];

  const handleOnChange = () => {
    const newChecked = !checked;

    setChecked(newChecked);
    onChange && onChange(newChecked);
  };

  return (
    <Styled.Root
      color={color}
      size={size}
      checked={checked}
      clickable={clickable}
      style={style || {}}
      onClick={clickable ? handleOnChange : () => null}
    >
      <span id="checked" className="material-icons-outlined">
        done
      </span>
      <span id="unchecked" className="material-icons-outlined">
        close
      </span>
    </Styled.Root>
  );
};

export default CheckboxDiamond;
