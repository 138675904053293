import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useLeaderboardsContext from 'src/hooks/useLeaderboardsContext';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/leaderboards/components/SectionHero.styles';

type Hero = {
  path: string;
};

const SectionHero: FC = () => {
  const { selectedCategory } = useLeaderboardsContext();
  const { t } = useTranslation(nameSpace.leaderboards);
  const hero: Hero = t('leaderboards.hero.asset', { returnObjects: true });

  return (
    <Styled.Root style={{ backgroundImage: `url(/assets/${hero.path})` }}>
      <Styled.HeroBottomBorder />

      <Styled.Overlay className={'inview animated fadeIn'}>
        <Styled.CopyContainer>
          <Styled.Title>{t('leaderboards.hero.title')}</Styled.Title>

          <ButtonHex
            variant="gold"
            hoverEffect={false}
            width={200}
            height={50}
            fontSize={14}
            enableDropShadow
            title={selectedCategory || 'No Results'}
            textStyle={{ paddingTop: '1px' }}
            style={{ display: 'block', margin: '1.5rem auto 0 auto' }}
            onClick={() => null}
          />
        </Styled.CopyContainer>
      </Styled.Overlay>
    </Styled.Root>
  );
};

export default SectionHero;
