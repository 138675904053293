import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyLeaderboards } from 'src/redux/slices/leaderboards/helpers/copy-leaderboards';
import nestedSort from 'src/tools/nestedSort';

// Define the initial state using that type
const initialState: LeaderboardsState = {
  leaderboards: [],
};

const leaderboardsSlice = createSlice({
  name: 'leaderboards',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLeaderboards: {
      reducer(state, action: PayloadAction<LeaderboardsPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.leaderboards = copyLeaderboards(action.payload.leaderboards);
      },
      prepare(leaderboards: Leaderboard[]) {
        return {
          payload: {
            leaderboards,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    addLeaderboards: {
      reducer(state, action: PayloadAction<LeaderboardsPayload>) {
        const existingItems = state.leaderboards;
        const newItems = action.payload.leaderboards || [];
        const allItems = [...existingItems, ...newItems];

        // eslint-disable-next-line no-param-reassign
        state.leaderboards = copyLeaderboards(allItems);
      },
      prepare(leaderboards: Leaderboard[]) {
        return {
          payload: {
            leaderboards,
          },
        };
      },
    },
    clearLeaderboards: state => {
      // eslint-disable-next-line no-param-reassign
      state.leaderboards = initialState.leaderboards;
    },
  },
});

export const { setLeaderboards, addLeaderboards, clearLeaderboards } = leaderboardsSlice.actions;

// Other Selectors
export const getLeaderboardById = (state: RootState, id: string): Leaderboard | null => {
  return state.leaderboards.leaderboards.find((item: Leaderboard) => item.configId === id) || null;
};
export const getSortedLeaderboardsByCategory = (state: RootState, category: string): Leaderboard[] => {
  const filteredLeaderboards = state.leaderboards.leaderboards.filter(
    (leaderboard: Leaderboard) => leaderboard?.category?.displayName === category,
  );
  return nestedSort(filteredLeaderboards, -1, 'totalPoints');
};
export const getSortedLeaderboardCategories = (state: RootState): string[] => {
  const sortedLeaderboards = nestedSort(state.leaderboards.leaderboards, 1, 'category.displayOrder');
  const categories: string[] = [];

  sortedLeaderboards.forEach(leaderboard => {
    const categoryName = leaderboard?.category?.displayName || null;

    if (categoryName === null) return;
    if (categories.indexOf(leaderboard?.category.displayName) === -1) {
      categories.push(leaderboard.category.displayName);
    }
  });

  return categories;
};

export default leaderboardsSlice.reducer;
