import { FC } from 'react';
import Styled from 'src/components/data-display/leaderboard/Player.styles';
// import { logMain } from 'src/modules/logger/logger';

const getStyles = (variant: Variant) => {
  switch (variant) {
    case 'condensed':
      return {
        fontSize: '1.2rem',
      };
    case 'default':
    default:
      return {
        fontSize: '1.8rem',
      };
  }
};

type Variant = 'condensed' | 'default';

interface PlayerProps {
  player: {
    id: string;
    name: string;
    imageUrl?: string | null;
  };
  variant?: Variant;
  style?: React.CSSProperties;
}

const Player: FC<PlayerProps> = ({ player, variant = 'default', style = {} }) => {
  const { name } = player;

  return (
    <Styled.Root variantStyles={getStyles(variant)} style={style}>
      {name}
    </Styled.Root>
  );
};

export default Player;
