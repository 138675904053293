import axios from 'axios';
import { customAxiosPrivateRequest } from 'src/modules/axios/interceptors/custom-axios-private-request';
import { customAxiosResponse } from 'src/modules/axios/interceptors/custom-axios-response';
import { config } from 'src/config/config';

const BASE_URL = config.base_url.lhu;
const CONTENT_TYPE_POST = 'application/json';
const CLIENT_VERSION = config.version;
const BROWSER_SESSION_ID = config.uuid;
const INTERCEPTORS = {
  customAxiosPrivateRequest,
  customAxiosResponse,
};

// Header defaults
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.post['Content-Type'] = CONTENT_TYPE_POST;
axios.defaults.headers.common['Client-Version'] = CLIENT_VERSION;
axios.defaults.headers.common['Browser-Session-ID'] = BROWSER_SESSION_ID;

// Custom Axios Instances
const CustomAxiosPublic = axios.create();
const CustomAxiosPrivate = axios.create();

// Interceptors
CustomAxiosPublic.interceptors.response.use(INTERCEPTORS.customAxiosResponse, e => Promise.reject(e));
CustomAxiosPrivate.interceptors.request.use(INTERCEPTORS.customAxiosPrivateRequest, e => Promise.reject(e));
CustomAxiosPrivate.interceptors.response.use(INTERCEPTORS.customAxiosResponse, e => Promise.reject(e));
export { axios, CustomAxiosPublic, CustomAxiosPrivate };
