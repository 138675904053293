import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import Menu from '@mui/material/Menu';
import Styled from 'src/components/pages/profile/components/ActionsBar.styles';

interface ActionsBarProps {
  className?: string;
}

const ActionsBar: FC<ActionsBarProps> = ({ className = '' }) => {
  const { playerId = '' } = useParams();
  const { t } = useTranslation(nameSpace.common);
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const shareMenuOpen = Boolean(shareAnchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setShareAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setShareAnchorEl(null);
  };

  const handleCopyURL = () => {
    const url = location.href;
    navigator.clipboard.writeText(!playerId ? `${url}/${currentProfile?.username}` : url);

    handleClose();
  };

  return (
    <Styled.Root className={className}>
      <Styled.DropdownsButtons>
        <span
          className="material-icons"
          id="share-menu-button"
          aria-controls={shareMenuOpen ? 'share-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={shareMenuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          share
        </span>

        <Menu
          id="share-menu"
          anchorEl={shareAnchorEl}
          open={shareMenuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'share-menu-button',
          }}
        >
          <Styled.MenuItem onClick={handleCopyURL}>
            <span className="material-icons">content_copy</span>
            <Styled.MenuItemText>{t('common.menu.button.copy.link')}</Styled.MenuItemText>
          </Styled.MenuItem>
        </Menu>
      </Styled.DropdownsButtons>
    </Styled.Root>
  );
};

export default ActionsBar;
