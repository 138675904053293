import { FC, useRef, useState } from 'react';
import Styled from 'src/components/navigation/Tabs.styles';

interface TabsProps {
  tabs: string[];
  setTab: (value: number) => void;
  defaultValue?: number;
  indicatorColor?: string;
  tabWidth?: number;
  tabMinWidth?: number;
  fullWidth?: boolean;
  align?: 'left' | 'center' | 'right';
  tabStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const Tabs: FC<TabsProps> = ({
  tabs,
  setTab,
  defaultValue = 0,
  indicatorColor = '#68ddfd',
  tabWidth = 0,
  tabMinWidth = 0,
  fullWidth = false,
  align = 'left',
  tabStyle = {},
  style = {},
}) => {
  const initialValue = useRef(defaultValue);
  const parentRef = useRef(null);
  const [value, setValue] = useState(initialValue.current);

  const handleSelectTab = (value: number) => {
    setTab(value);
    setValue(value);
  };

  return (
    <Styled.Root
      ref={parentRef}
      tabWidth={tabWidth}
      tabMinWidth={tabMinWidth}
      fullWidth={fullWidth}
      align={align}
      style={style}
    >
      {tabs.map((tab, i) => {
        return (
          <Styled.Tab key={tab} selected={value === i} style={tabStyle} onClick={() => handleSelectTab(i)}>
            {tab}

            <Styled.TabIndicator
              indicatorColor={indicatorColor}
              style={{ transition: value === i ? 'width 0.4s ease-in-out' : 'none' }}
            />
          </Styled.Tab>
        );
      })}
    </Styled.Root>
  );
};

export default Tabs;
