import { RARITIES } from 'src/constants/rarities';

export const getRarityNameFromEnum = (value: number | null | undefined): string | undefined => {
  const acceptedValues = Object.keys(RARITIES).map(key => parseInt(key, 10));
  const tmpValue = typeof value === 'number' ? value : -1;
  const isValidValue = typeof tmpValue === 'number' && !isNaN(tmpValue);

  if (!isValidValue || acceptedValues.indexOf(tmpValue) === -1) return '';

  return RARITIES[tmpValue];
};
