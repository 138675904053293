import { FC, ReactNode, useState, useMemo } from 'react';
import CursorContext, { initialContext, ICursorContext } from 'src/context/cursor/Cursor.context';
import Styled from 'src/context/cursor/Cursor.styles';

interface CursorProviderProps {
  children: ReactNode;
}

const CursorProvider: FC<CursorProviderProps> = ({ children }) => {
  const [cursorType, setCursorType] = useState<ICursorContext['cursorType']>(initialContext.cursorType);

  const value = useMemo(
    () => ({
      cursorType,
      setCursorType,
    }),
    [cursorType, setCursorType],
  );

  return (
    <CursorContext.Provider value={value}>
      <Styled.Root className={`cursor-${cursorType}`}>{children}</Styled.Root>
    </CursorContext.Provider>
  );
};

export default CursorProvider;
