import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import usePendingPurchasesContext from 'src/hooks/usePendingPurchasesContext';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useLoadNonfungible } from 'src/hooks/useLoadNonfungible';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getNonfungibleBySeriesAndSerialNumber } from 'src/redux/slices/nonfungibles/nonfungibles';
import PageError from 'src/components/feedback/PageError';
import Alert from 'src/components/feedback/Alert';
import NFTPreview from 'src/components/pages/nft/components/NFTPreview';
import Details from 'src/components/pages/nft/components/NFTDetails';
import Styled from 'src/components/nft-details/NFTDetails.styles';

interface NFTDetailsProps {
  disablePageError?: boolean;
  style?: React.CSSProperties;
}

const NFTDetails: FC<NFTDetailsProps> = ({ disablePageError, style = {} }) => {
  const { concurrentPurchaseLimit } = usePendingPurchasesContext();
  const { gameOverlayLocation } = useGameOverlayContext();
  const { seriesId = '', serialNumber = '' } = (gameOverlayLocation.params as Record<string, string>) || {};
  const { loading, error } = useLoadNonfungible({
    seriesId,
    serialNumber,
    redirectOnError: false,
    fromContext: 'game_overlay_collection',
  });
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const pendingPurchases = useAppSelector(({ pendingPurchases }) => pendingPurchases.pendingPurchases);
  const nonfungible = useAppSelector(state =>
    getNonfungibleBySeriesAndSerialNumber(state, seriesId, parseInt(serialNumber, 10)),
  );
  const navigateInternal = useAppNavigateInternal();
  const { t } = useTranslation(nameSpace.common);
  const isCurrentProfile = currentProfile.id === nonfungible?.playerId;
  const listed = nonfungible?.listed;

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;
  // If data loaded incorrectly return page error
  if (!disablePageError && (error || !nonfungible))
    return (
      <PageError
        style={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', width: 'auto', height: 'auto' }}
      />
    );

  return (
    <Styled.Root style={style}>
      {!isCurrentProfile &&
        listed &&
        pendingPurchases.length > 0 &&
        (pendingPurchases.length === concurrentPurchaseLimit ? (
          <Alert
            severity={'error'}
            msg={t('common.message.purchase.pending.max')}
            action={t('common.nav.profile')}
            onAction={() =>
              navigateInternal('/profile?default_tab=3', {
                pooleData: { fromContext: 'nft_details' },
              })
            }
            style={{ marginTop: '1.5rem' }}
          />
        ) : (
          <Alert
            severity={'warning'}
            msg={t('common.message.purchase.pending')}
            action={t('common.nav.profile')}
            onAction={() =>
              navigateInternal('/profile?default_tab=3', {
                pooleData: { fromContext: 'nft_details' },
              })
            }
            style={{ marginTop: '1.5rem' }}
          />
        ))}

      <NFTPreview seriesId={seriesId} serialNumber={serialNumber} />
      <Details seriesId={seriesId} serialNumber={serialNumber} />
    </Styled.Root>
  );
};

export default NFTDetails;
