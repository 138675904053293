import { useAppSelector } from 'src/hooks/useAppSelector';
import { tutorialStateExists } from 'src/tools/tutorialStateExists';

type TutorialHeroName = 'Kit' | 'Yuna';

const useTutorialHeroName = (): { name: TutorialHeroName } => {
  const tutorialStates = useAppSelector(({ tutorialStates }) => tutorialStates.tutorialStates);
  const starterPackMint = tutorialStateExists(tutorialStates, 'starter_1_mint');
  const starterPackOpen = tutorialStateExists(tutorialStates, 'starter_2_open');
  const heroQuestExplain = tutorialStateExists(tutorialStates, 'heroQuest_02_explain');
  const heroQuestMid = tutorialStateExists(tutorialStates, 'heroQuest_04_mid');
  const heroQuestClaim = tutorialStateExists(tutorialStates, 'heroQuest_06_claim');
  const showKit = starterPackMint || starterPackOpen;
  const showYuna = heroQuestExplain || heroQuestMid || heroQuestClaim;

  if (showKit) return { name: 'Kit' };
  if (showYuna) return { name: 'Yuna' };

  return { name: 'Kit' };
};

export default useTutorialHeroName;
