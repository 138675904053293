import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useQuery } from 'src/hooks/useQuery';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { useMediaQuery, useTheme } from '@mui/material';
import LogoSrc from 'src/assets/logo/lhu_logo.webp';
import MenuLeftDesktop from 'src/components/header/MenuLeftDesktop';
import MenuLeftMobile from 'src/components/header/MenuLeftMobile';
import MenuRight from 'src/components/header/MenuRight';
import { config } from 'src/config/config';
import Styled from 'src/components/header/NavBar.styles';

const NavBar: FC = () => {
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const mobileView = useMediaQuery(theme.breakpoints.down(config.ui_variables.mobile_breakpoints.header));
  const xsView = useMediaQuery(theme.breakpoints.down(350));
  const { hash } = useLocation();
  const navigateInternal = useAppNavigateInternal();

  useEffect(() => {
    if (hash) {
      setTimeout(function () {
        const targetElement = document.getElementById(hash.substring(1));
        targetElement?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [hash]);

  const handleOnGoTo = (path: string, gtmEvent?: () => void) => {
    navigateInternal(path, { pooleData: { fromContext: 'header_navigation' }, gtmEvent: gtmEvent || null });
  };

  return (
    <Styled.Root>
      {!xsView && (
        <Styled.Logo
          src={LogoSrc}
          width="406"
          height="213"
          loading="lazy"
          alt={t('common.project.name')}
          onClick={() => handleOnGoTo(trim === '1' ? '/home?trim=1' : '/home')}
        />
      )}

      {!mobileView && <MenuLeftDesktop />}
      {mobileView && <MenuLeftMobile />}

      <MenuRight />
    </Styled.Root>
  );
};

export default NavBar;
