import { FC } from 'react';
import DOMPurify from 'dompurify';
import placeholderImage from 'src/assets/placeholders/generic_placeholder_blue.webp';
import StarLevel from 'src/components/data-display/StarLevel';
import Styled from 'src/components/pages/nft/components/Skills.styles';

interface SkillsProps {
  emptyText: string;
  skills: Skill[];
  maxStarLevel?: number;
  style?: React.CSSProperties;
}

const Skills: FC<SkillsProps> = ({ emptyText, skills, maxStarLevel = 0, style = {} }) => {
  if (!skills || skills?.length === 0) return <Styled.EmptyText>{emptyText}</Styled.EmptyText>;

  const basicAttacks = skills?.filter(skill => skill.type === 'basic_attack') || [];
  const skillAttacks = skills?.filter(skill => skill.type === 'skill') || [];
  const ultimates = skills?.filter(skill => skill.type === 'ultimate') || [];

  return (
    <Styled.Root style={style}>
      {(!skills || skills?.length === 0) && <Styled.EmptyText>{emptyText}</Styled.EmptyText>}
      <Styled.SkillRow>
        <Styled.SkillCategory>{'Basic Attack'}</Styled.SkillCategory>

        {basicAttacks.map((skill, index) => (
          <Styled.SkillContainer key={`skill-basic-${index}`}>
            <Styled.SkillIcon
              loading="lazy"
              src={skill.imageUrl || placeholderImage}
              width={'100'}
              height={'100'}
              alt={skill.name}
              title={skill.name}
            />

            <Styled.SkillTextContainer>
              <Styled.SkillLabel>
                <div>{skill.name.toLowerCase()}</div>
                <StarLevel level={skill?.starLevel || 0} maxLevel={maxStarLevel} starSize={16} />
              </Styled.SkillLabel>
              <Styled.SkillValue dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(skill.description) }} />
            </Styled.SkillTextContainer>
          </Styled.SkillContainer>
        ))}
      </Styled.SkillRow>

      <Styled.SkillRow>
        <Styled.SkillCategory>{'Skills'}</Styled.SkillCategory>

        {skillAttacks.map((skill, index) => (
          <Styled.SkillContainer key={`skill-skill-${index}`}>
            <Styled.SkillIcon
              src={skill.imageUrl || placeholderImage}
              width={'100'}
              height={'100'}
              alt={skill.name}
              title={skill.name}
            />

            <Styled.SkillTextContainer>
              <Styled.SkillLabel>
                <div>{skill.name.toLowerCase()}</div>
                <StarLevel level={skill?.starLevel || 0} maxLevel={maxStarLevel} starSize={16} />
              </Styled.SkillLabel>

              <Styled.SkillValue dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(skill.description) }} />
            </Styled.SkillTextContainer>
          </Styled.SkillContainer>
        ))}
      </Styled.SkillRow>

      <Styled.SkillRow>
        <Styled.SkillCategory>{'Ultimate'}</Styled.SkillCategory>

        {ultimates.map((skill, index) => (
          <Styled.SkillContainer key={`skill-ultimate-${index}`}>
            <Styled.SkillIcon
              src={skill.imageUrl || placeholderImage}
              width={'100'}
              height={'100'}
              alt={skill.name}
              title={skill.name}
            />

            <Styled.SkillTextContainer>
              <Styled.SkillLabel>
                <div>{skill.name.toLowerCase()}</div>
                <StarLevel level={skill?.starLevel || 0} maxLevel={maxStarLevel} starSize={16} />
              </Styled.SkillLabel>
              <Styled.SkillValue dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(skill.description) }} />
            </Styled.SkillTextContainer>
          </Styled.SkillContainer>
        ))}
      </Styled.SkillRow>
    </Styled.Root>
  );
};

export default Skills;
