import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

interface ButtonProps {
  disabled?: boolean;
}

const Button = styled('div')<ButtonProps>`
  background-color: transparent;
  display: block;
  width: 100%;
  height: calc(2.7rem - 2px);
  border-radius: 0.2rem;
  border: 1px solid #ffffff77;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ disabled }) => (disabled ? '#ffffff77' : '#ffffff')};
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & > span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > span > span {
    display: inline-block;
    vertical-align: middle;
  }

  & .material-icons,
  & .material-icons-outlined {
    margin-top: 2px;
    margin-right: 0.3rem;
    font-size: 0.9rem;
    color: ${({ disabled }) => (disabled ? '#ffffff77' : '#ffffff')};
  }

  &:hover {
    & .material-icons,
    & .material-icons-outlined {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }

    background-color: #ffffff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }
`;

const BrandsContainer = styled('div')`
  display: block;
  width: 100%;
  height: 2.5rem;
  position: relative;
`;

const ForteContainer = styled('div')`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  & > .material-icons {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.3rem;
    color: #999;
  }

  & > img {
    display: inline-block;
    margin-top: 0.5rem;
    vertical-align: middle;
    font-size: 1.3rem;
    color: #999;
  }
`;

const ForteBrandingLabel = styled('span')`
  display: inline-block;
  margin: 0 0.6rem 0 0.6rem;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #999;
`;

export default {
  Root,
  Button,
  BrandsContainer,
  ForteContainer,
  ForteBrandingLabel,
};
