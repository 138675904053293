import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useTutorialContext from 'src/hooks/useTutorialContext';
import useTutorialHeroName from 'src/hooks/useTutorialHeroName';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { transitionTutorial } from 'src/api/tutorial/transition-tutorial';
import Dialog from 'src/components/tutorial-overlay/Dialog';
import { useMediaQuery } from '@mui/material';
import Styled from 'src/components/tutorial-overlay/TutorialOverlay.styles';

const getDialogHeroRightCoord = (
  bpMaxWidth700: boolean,
  bpMaxHeight600: boolean,
  bpMaxHeight500: boolean,
  bpMaxHeight400: boolean,
  extraRightSpace: boolean,
) => {
  if (bpMaxHeight400) {
    return extraRightSpace ? '-2rem' : '-4rem';
  }

  if (bpMaxHeight500) {
    return extraRightSpace ? '-4rem' : '-6rem';
  }

  if (bpMaxHeight600) {
    return extraRightSpace ? '-6rem' : '-8rem';
  }

  if (bpMaxWidth700) {
    return extraRightSpace ? '-10rem' : '-12rem';
  }

  return extraRightSpace ? '-8rem' : '-8rem';
};

interface TutorialOverlayProps {
  style?: React.CSSProperties;
}

const TutorialOverlay: FC<TutorialOverlayProps> = ({ style = {} }) => {
  const { progressTutorial, tutorialProgress, tutorialState } = useTutorialContext();
  const dispatch = useAppDispatch();
  const { name } = useTutorialHeroName();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tPlay } = useTranslation(nameSpace.play);
  const bpMaxWidth700 = useMediaQuery('(max-width:700px)');
  const bpMaxHeight600 = useMediaQuery('(max-height:600px)');
  const bpMaxHeight500 = useMediaQuery('(max-height:500px)');
  const bpMaxHeight400 = useMediaQuery('(max-height:400px)');
  const dialogs: string[] = tPlay('play.tutorial.dialogs', { returnObjects: true }) || [];

  const handleProgressTutorial = () => {
    // Call API to transition to next tutorial state at end of current tutorial state dialog sequence.
    if (tutorialState.heroQuestExplain && tutorialProgress === 6) {
      dispatch(transitionTutorial({ data: { fromState: 'heroQuest_02_explain' } }));
    } else if (tutorialState.heroQuestMid && tutorialProgress === 7) {
      dispatch(transitionTutorial({ data: { fromState: 'heroQuest_04_mid' } }));
    }

    progressTutorial();
  };

  if (!tutorialState.visible) return null;

  return (
    <Styled.Root
      onClick={handleProgressTutorial}
      style={style}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0, transition: { type: 'tween', duration: 0.3, delay: 0 } }}
      // transition={{ duration: 0.5, delay: 1.6 }}
    >
      {name === 'Kit' && (
        <Styled.Video
          width="720"
          height="720"
          preload="auto"
          loop
          autoPlay
          muted
          playsInline
          right={getDialogHeroRightCoord(bpMaxWidth700, bpMaxHeight600, bpMaxHeight500, bpMaxHeight400, false)}
          // initial={{ right: '-100rem' }}
          // animate={{
          //   right: getDialogHeroRightCoord(bpMaxWidth700, bpMaxHeight600, bpMaxHeight500, bpMaxHeight400, false),
          // }}
          // exit={{ right: '-100rem', transition: { type: 'Tween', duration: 0.8, delay: 0 } }}
          // transition={{ type: 'spring', bounce: 1, damping: 15, stiffness: 100, duration: 0.8, delay: 1.6 }}
        >
          {/* <source src={tPlay('play.tutorial.video.kit')} type="video/mp4" /> */}
          <source src={'/assets/kit.mov'} type="video/mp4" />
          <source src={'/assets/kit.webm'} type="video/webm" />
          {tCommon('common.video.support') as ReactNode}
        </Styled.Video>
      )}

      {name === 'Yuna' && (
        <Styled.Video
          width="720"
          height="720"
          preload="auto"
          loop
          autoPlay
          muted
          playsInline
          right={getDialogHeroRightCoord(bpMaxWidth700, bpMaxHeight600, bpMaxHeight500, bpMaxHeight400, true)}
          // initial={{ right: '-100rem' }}
          // animate={{
          //   right: getDialogHeroRightCoord(bpMaxWidth700, bpMaxHeight600, bpMaxHeight500, bpMaxHeight400, true),
          // }}
          // exit={{ right: '-100rem', transition: { type: 'Tween', duration: 0.8, delay: 0 } }}
          // transition={{ type: 'spring', bounce: 1, damping: 15, stiffness: 100, duration: 0.8, delay: 1.6 }}
        >
          {/* <source src={tPlay('play.tutorial.video.yuna')} type="video/mp4" /> */}
          <source src={'/assets/yuna_kick.mov'} type="video/mp4" />
          <source src={'/assets/yuna_kick.webm'} type="video/webm" />
          {tCommon('common.video.support') as ReactNode}
        </Styled.Video>
      )}

      {dialogs.map((dialog, i) => {
        if (tutorialProgress === i) {
          return <Dialog key={`dialog-step-${i}`} dialog={dialog} />;
        }

        return null;
      })}
    </Styled.Root>
  );
};

export default TutorialOverlay;
