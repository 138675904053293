import { useContext } from 'react';
import MarketplaceContext from 'src/context/marketplace/Marketplace.context';

const useMarketplaceContext = () => {
  const marketplaceContext = useContext(MarketplaceContext);

  return marketplaceContext;
};

export default useMarketplaceContext;
