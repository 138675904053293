import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyPendingPurchases } from 'src/redux/slices/pending-purchases/helpers/copy-pending-purchases';

// Define the initial state using that type
const initialState: PendingPurchasesState = {
  pendingPurchases: [],
};

const pendingPurchasesSlice = createSlice({
  name: 'pendingPurchases',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPendingPurchases: {
      reducer(state, action: PayloadAction<PendingPurchasesPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.pendingPurchases = copyPendingPurchases(action.payload.pendingPurchases);
      },
      prepare(pendingPurchases: PendingPurchase[]) {
        return {
          payload: {
            pendingPurchases,
          },
        };
      },
    },
    clearPendingPurchases: state => {
      // eslint-disable-next-line no-param-reassign
      state.pendingPurchases = initialState.pendingPurchases;
    },
  },
});

export const { setPendingPurchases, clearPendingPurchases } = pendingPurchasesSlice.actions;

// Other Selectors
export const getPendingPurchasesArr = (state: RootState): PendingPurchase[] => state.pendingPurchases.pendingPurchases;

export default pendingPurchasesSlice.reducer;
