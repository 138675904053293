import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getLeaderboardItemsByLeaderboardId } from 'src/redux/slices/leaderboard-items/leaderboard-items';
import { getLeaderboardById } from 'src/redux/slices/leaderboards/leaderboards';
import loadLeaderboard from 'src/components/pages/leaderboard/utils/load-leaderboard';
import Metadata from 'src/components/pages/leaderboard/components/Metadata';
import PlayerLeaderboardTable from 'src/components/data-display/leaderboard/PlayerLeaderboardTable';
import ButtonHex from 'src/components/input/ButtonHex';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/pages/leaderboard/components/SectionDetails.styles';

interface BG {
  path: string;
}

interface SectionDetailsProps {
  resultsFound: boolean;
  setResultsFound: (resultsFound: boolean) => void;
}

const SectionDetails: FC<SectionDetailsProps> = ({ resultsFound, setResultsFound }) => {
  const { leaderboardId = '' } = useParams();
  const leaderboard = useAppSelector(state => getLeaderboardById(state, leaderboardId));
  const leaderboardItems = useAppSelector(state => getLeaderboardItemsByLeaderboardId(state, leaderboardId));
  const [inView, setInView] = useState(false);
  const dispatch = useAppDispatch();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tLeaderboard } = useTranslation(nameSpace.leaderboard);
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down(651));
  const bgBottom: BG = tLeaderboard('leaderboard.details.bkg.bottom', { returnObjects: true });
  const pageNumber = leaderboard?.pagination?.pageNumber || 0;

  const handleLoadMore = () => {
    loadLeaderboard(dispatch, leaderboardId, setResultsFound, pageNumber + 1);
  };

  return (
    <Styled.Root>
      <Styled.BackgroundImage style={{ backgroundImage: `url(/assets/${bgBottom.path})` }}>
        <Styled.BackgroundImageGradient />
      </Styled.BackgroundImage>

      <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
        <Styled.CenteredContainer className={`inview animated delay-1s ${inView ? 'fadeInUp' : ''}`}>
          <Metadata />
          <PlayerLeaderboardTable
            variant={'condensed'}
            data={leaderboardItems}
            style={{ marginTop: mobileBreakpoint ? '2rem' : '0.8rem' }}
          />

          {leaderboardItems.length > 0 && resultsFound && (
            <ButtonHex
              variant="white"
              width={200}
              height={50}
              fontSize={12}
              enableDropShadow
              hoverEffect
              title={tCommon('common.term.load.more')}
              textStyle={{ paddingTop: '1px' }}
              style={{ display: 'block', margin: '4rem auto 0 auto' }}
              onClick={() => handleLoadMore()}
            />
          )}
        </Styled.CenteredContainer>
      </Waypoint>
    </Styled.Root>
  );
};

export default SectionDetails;
