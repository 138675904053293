import { styled } from '@mui/material';

interface RootProps {
  hideBorder: boolean;
}

const Root = styled('div')<RootProps>`
  display: block;
  border: ${({ hideBorder }) => (hideBorder ? 'none' : '1px solid #ffffff22')};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* opacity: 0.95; */

  & > div {
    top: ${({ hideBorder }) => (hideBorder ? '0' : '8px')};
    right: ${({ hideBorder }) => (hideBorder ? '0' : '8px')};
    bottom: ${({ hideBorder }) => (hideBorder ? '0' : '8px')};
    left: ${({ hideBorder }) => (hideBorder ? '0' : '8px')};
  }
`;

const Background = styled('div')`
  display: block;
  border: 70px solid;
  border-image: url('/assets/nine_slice_decorative_primary.webp') round;
  border-image-slice: 140 fill;
  position: absolute;
  /* opacity: 0.95; */
`;

const Gradient = styled('div')`
  background: linear-gradient(0deg, #4a3867 35%, #1e1913 85%);
  display: block;
  position: absolute;
  opacity: 0.4;
`;

export default { Root, Background, Gradient };
