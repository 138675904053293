import { FC, useEffect, useRef, useState } from 'react';
import Styled, { ImageProps } from 'src/components/media/ResponsivePicture.styles';

interface Props extends ImageProps {
  alt?: string;
  src?: string | File;
  srcMobile?: string | File;
  width?: string;
  maxWidth?: string;
  height?: string;
}

const ResponsivePicture: FC<Props> = props => {
  const { alt = '', src, srcMobile, width = '100%', height = '100vh', maxWidth = '100%' } = props;
  const [loaded, setLoaded] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const imgSrc = typeof src === 'string' || src === undefined || src === null ? src : URL.createObjectURL(src);
  const imgSrcMobile =
    typeof srcMobile === 'string' || srcMobile === undefined || srcMobile === null
      ? srcMobile
      : URL.createObjectURL(srcMobile);

  const handleOnLoad = (): void => {
    setLoaded(true);
  };

  useEffect(() => {
    if (imgRef.current && imgRef.current?.complete && src !== undefined && src !== '' && src !== null) {
      return;
    }

    setLoaded(false);
  }, [src]);

  return (
    <picture>
      <source srcSet={imgSrc} media="(min-width: 768px)" />

      <Styled.Image
        ref={imgRef}
        alt={alt}
        height={height}
        width={width}
        maxWidth={maxWidth}
        onLoad={handleOnLoad}
        src={imgSrcMobile}
        sizes="(max-width: 767px)"
        loaded={loaded}
      />
    </picture>
  );
};

export default ResponsivePicture;
