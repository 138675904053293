/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useQuery } from 'src/tools/useQuery';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { linkFarcaster } from 'src/api/farcaster/link-farcaster';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/farcaster/Farcaster.styles';
import { logMain } from 'src/modules/logger/logger';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { setCurrentProfile } from 'src/redux/slices/current-profile/current-profile';
import { useMediaQuery } from '@mui/material';

const Farcaster: FC = () => {
  const params = useQuery(urlParams.farcaster);
  const token = getURLParamValue('token', urlParams.farcaster, params);
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t } = useTranslation(nameSpace.farcaster);
  const bpMaxWidth500OrMaxHeight500 = useMediaQuery('(max-width: 500px) or (max-height: 500px)');
  const dispatch = useAppDispatch();
  const farcasterLinked = !!currentProfile?.farcasterUser || false;
  const navigateInternal = useAppNavigateInternal();

  useEffect(() => {
    if (authenticated && !farcasterLinked && token) {
      logMain.debug('Farcaster token:', token);

      dispatch(linkFarcaster({ data: { token } }))
        .then(data => {
          if (Object.keys(data).length > 0) {
            dispatch(setCurrentProfile(data)); // Update the current profile with the new data
          }
        })
        .catch((e: any) => {
          logMain.error('Failed to link farcaster:', e);
        });
    }
  }, [authenticated, dispatch, farcasterLinked, token]);

  return (
    <Styled.Root>
      <Styled.ContentContainer>
        <Styled.FarcasterLogo
          src="/assets/farcaster_logo.webp"
          width="100"
          height="100"
          alt="Farcaster Logo"
          title="Farcaster logo"
        />
        <Styled.Heading
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t(`farcaster.title.${farcasterLinked ? 'linked' : 'linking'}`)),
          }}
        />
        <Styled.Subheading
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(t(`farcaster.description.${farcasterLinked ? 'linked' : 'linking'}`)),
          }}
        />

        {farcasterLinked && (
          <ButtonHex
            hoverEffect
            variant="gold"
            width={150}
            height={50}
            title={t('farcaster.action')}
            style={{
              display: 'block',
              margin: bpMaxWidth500OrMaxHeight500 ? '1rem auto 0 auto' : '1.5rem auto 0 auto',
            }}
            onClick={() => navigateInternal('/profile', { pooleData: { fromContext: 'farcaster' } })}
          />
        )}
      </Styled.ContentContainer>
    </Styled.Root>
  );
};

export default Farcaster;
