import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { getPendingPurchases } from 'src/api/secondary-markets/get-pending-purchases';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { setPendingPurchases, clearPendingPurchases } from 'src/redux/slices/pending-purchases/pending-purchases';
import PendingPurchasesContext, {
  initialContext,
  IPendingPurchasesContext,
} from 'src/context/pending-purchases/PendingPurchases.context';
import { logMain } from 'src/modules/logger/logger';

interface PendingPurchasesProviderProps {
  children: ReactNode;
}

const PendingPurchasesProvider: FC<PendingPurchasesProviderProps> = ({ children }) => {
  const [loadPendingPurchases, setLoadPendingPurchases] = useState<IPendingPurchasesContext['loadPendingPurchases']>(
    initialContext.loadPendingPurchases,
  );
  const [concurrentPurchaseLimit, setConcurrentPurchaseLimit] = useState<
    IPendingPurchasesContext['concurrentPurchaseLimit']
  >(initialContext.concurrentPurchaseLimit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadPendingPurchases) {
      setLoadPendingPurchases(false);

      dispatch(getPendingPurchases())
        .then(data => {
          if (data?.purchases && data?.purchases?.length > 0) {
            dispatch(setPendingPurchases(data.purchases));
          } else {
            dispatch(clearPendingPurchases());
          }

          if (data?.concurrentPurchaseLimit) {
            setConcurrentPurchaseLimit(data.concurrentPurchaseLimit);
          } else {
            setConcurrentPurchaseLimit(initialContext.concurrentPurchaseLimit);
          }
        })
        .catch(e => {
          logMain.debug(e);
        });
    }
  }, [dispatch, loadPendingPurchases]);

  const value = useMemo(
    () => ({
      loadPendingPurchases,
      setLoadPendingPurchases,
      concurrentPurchaseLimit,
      setConcurrentPurchaseLimit,
    }),
    [loadPendingPurchases, setLoadPendingPurchases, concurrentPurchaseLimit, setConcurrentPurchaseLimit],
  );

  return <PendingPurchasesContext.Provider value={value}>{children}</PendingPurchasesContext.Provider>;
};

export default PendingPurchasesProvider;
