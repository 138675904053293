import { LocalStorage } from 'src/local-storage/local-storage';

export const saveDevicePlayerId = (playerId: string | null | undefined): void => {
  // If player ID is returned (SHOULD BE) add to device player IDs in localstorage
  if (playerId) {
    const devicePlayerIds = LocalStorage.get('device_player_ids') || '';

    if (devicePlayerIds.length === 0) {
      LocalStorage.set('device_player_ids', playerId);
    } else {
      const devicePlayerIdsArray = devicePlayerIds.split(',');

      if (!devicePlayerIdsArray.includes(playerId)) {
        devicePlayerIdsArray.push(playerId);
        LocalStorage.set('device_player_ids', devicePlayerIdsArray.join(','));
      }
    }
  }
};
