import { FC } from 'react';
import { usePentarcDustIcon } from 'src/hooks/usePentarcDustIcon';
import Styled from 'src/components/icons/ATCIcon.styles';

interface PentarcDustIconProps {
  resolution?: 'small' | 'large' | 'full';
  size?: number;
  style?: React.CSSProperties;
}

const PentarcDustIcon: FC<PentarcDustIconProps> = ({ resolution = 'small', size = 16, style = {} }) => {
  const { title, width, height, path } = usePentarcDustIcon(resolution);

  return (
    <Styled.Root
      size={size}
      src={`/assets/${path}`}
      width={width}
      height={height}
      alt={title}
      title={title}
      style={style}
    />
  );
};

export default PentarcDustIcon;
