import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { Waypoint } from 'react-waypoint';
import ButtonLogin from 'src/components/input/ButtonLogin';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/pages/getting-started/components/SectionAuth.styles';

type BG = {
  path: string;
};

const SectionAuth: FC = () => {
  const [inView, setInView] = useState(false);
  const { t: tGettingStarted } = useTranslation(nameSpace.gettingStarted);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const bg: BG = tGettingStarted('getting.started.auth.bg.asset', { returnObjects: true });

  return (
    <Styled.Root>
      <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
        <Styled.InnerContainer
          className={`inview animated ${inView ? 'fadeInUp' : ''}`}
          style={{ backgroundImage: `url(/assets/${bg.path})` }}
        >
          <Styled.Title>{tGettingStarted('getting.started.sign.in')}</Styled.Title>

          <Styled.ButtonContainer>
            <ButtonLogin
              height={mobileView ? 54 : 61}
              width={mobileView ? 200 : 185}
              title={tCommon('common.auth.sign.in')}
              useMobileIcon={false}
              textStyle={{ textTransform: 'uppercase', fontSize: mobileView ? '.7rem' : '.8rem' }}
              style={{ display: 'inline-block' }}
            />
          </Styled.ButtonContainer>
        </Styled.InnerContainer>
      </Waypoint>
    </Styled.Root>
  );
};

export default SectionAuth;
