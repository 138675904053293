import { styled } from '@mui/material';
// import { config } from 'src/config/config';

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const BackgroundImage = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 19 / 10;
  position: absolute;
  bottom: 0;
`;

const BackgroundImageGradient = styled('div')`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 55rem;
  margin: 0 auto;
  padding: 2rem 2rem 4rem 2rem;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 500px) {
    padding: 1.8rem 1.5rem 2rem 1.5rem;
  }
`;

export default {
  Root,
  BackgroundImage,
  BackgroundImageGradient,
  CenteredContainer,
};
