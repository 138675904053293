import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import Select from 'src/components/pages/leaderboard/components/Select';
import Styled from 'src/components/pages/leaderboard/components/SelectBar.styles';

const SelectBar: FC = () => {
  const [inView, setInView] = useState(false);
  const { t } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();

  return (
    <Styled.Root>
      <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
        <Styled.CenteredContainer className={`inview animated delay-0-5s ${inView ? 'fadeIn' : ''}`}>
          <Styled.BackButton
            onClick={() => navigateInternal('/leaderboards', { pooleData: { fromContext: 'leaderboard' } })}
          >
            <span className="material-icons-outlined">arrow_back_ios</span>
            <span>{`${t('common.term.back')}`}</span>
          </Styled.BackButton>

          <Select />
        </Styled.CenteredContainer>
      </Waypoint>
    </Styled.Root>
  );
};

export default SelectBar;
