import { logMain } from 'src/modules/logger/logger';

type DisplayMode = 'fullscreen' | 'standalone' | 'minimal-ui' | 'browser';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const checkDisplayMode = (displayMode: DisplayMode): boolean => {
  logMain.debug({
    windowNavigatorFullscreen: 'fullscreen' in window.navigator && (window.navigator as any).fullscreen,
    windowNavigatorStandalone: 'standalone' in window.navigator && (window.navigator as any).standalone,
    displayModeStandalone: window.matchMedia('(display-mode: standalone)').matches,
    displayModeFullscreen: window.matchMedia('(display-mode: fullscreen)').matches,
    displayModeMinimalUi: window.matchMedia('(display-mode: minimal-ui)').matches,
    displayModeBrowser: window.matchMedia('(display-mode: browser)').matches,
  });
  return (
    (displayMode in window.navigator && (window.navigator as any)[displayMode]) ||
    window.matchMedia(`(display-mode: ${displayMode})`).matches
  );
};
