import { styled } from '@mui/material';

interface RootProps {
  bgColor: string;
}

const Root = styled('div')<RootProps>`
  background-color: ${({ bgColor }) => bgColor};
  display: block;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;

  @media (max-width: 500px) {
    padding: 0 1.5rem;
  }
`;

interface InnerContainerProps {
  bgColor: string;
}

const ContentOuterContainer = styled('div')<InnerContainerProps>`
  background-color: ${({ bgColor }) => bgColor};
  display: block;
  width: 100%;
  max-width: 80rem;
  padding: 2rem 5rem 5rem 5rem;
  margin: -10rem auto 0 auto;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 700px) {
    padding: 2rem 3rem 5rem 3rem;
    margin: -5rem auto 0 auto;
  }

  @media (max-width: 400px) {
    padding: 1rem 2rem 5rem 2rem;
    margin: -5rem auto 0 auto;
  }
`;

const ContentInnerContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 50rem;
  margin: 1rem auto 0 auto;
`;

const DetailSection = styled('div')`
  display: block;
  width: 60%;
  padding: 3rem 0;
  margin: 0 auto;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const SectionTitle = styled('p')`
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const SectionDesc = styled('p')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400;
  color: #ffffff;
`;

const SectionBullets = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
`;

const Bullet = styled('div')`
  margin-top: 0.5rem;

  & > div:nth-of-type(odd) {
    margin-right: 1rem;
  }
`;

const BulletValue = styled('span')`
  display: inline-block;
  width: calc(100% - 5px - 1rem);
  margin-top: 0.1rem;
  vertical-align: top;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const RaritiesContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
`;

const RaritiesLabel = styled('p')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const RarityContainer = styled('div')`
  display: block;
  width: 100%;
  font-size: 0;

  &:nth-of-type(1) {
    margin-top: 1rem;
  }

  &:nth-of-type(n + 2) {
    margin-top: 0.5rem;
  }
`;

const RarityImgContainer = styled('div')`
  display: inline-block;
  width: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
  text-align: center;
`;

const RarityImg = styled('img')`
  display: inline-block;
  width: auto;
  height: 1.5rem;
  vertical-align: middle;
`;

const RarityInfoContainer = styled('div')`
  display: inline-block;
  width: calc(70% - 3rem);
  margin-right: 1rem;
  vertical-align: middle;
`;

const RarityName = styled('p')`
  display: block;
  width: 100%;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const RarityDesc = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.1rem;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 400;
  color: #ffffff;
`;

const RarityChance = styled('div')`
  display: inline-block;
  width: calc(30% - 1rem);
  vertical-align: middle;
  text-align: right;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const SectionCommunity = styled('div')`
  display: block;
  width: 100%;
  max-width: 30rem;
  margin: 5rem auto 0 auto;
`;

const CommunityTitle = styled('p')`
  display: block;
  width: 100%;
  text-align: center;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 600;
  color: #ffffff;

  @media (max-width: 400px) {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
`;

const CommunityDesc = styled('p')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #ffffff;
`;

const CommunitySocialsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  text-align: center;
`;

const SocialContainer = styled('div')`
  display: inline-block;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  position: relative;
`;

const SocialDiamond = styled('div')`
  display: block;
  width: 2.7rem;
  height: 2.7rem;
  border: 2px solid #dbba5a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
`;

export default {
  Root,
  ContentOuterContainer,
  ContentInnerContainer,
  DetailSection,
  SectionTitle,
  SectionDesc,
  SectionBullets,
  Bullet,
  BulletValue,
  RaritiesContainer,
  RaritiesLabel,
  RarityContainer,
  RarityImgContainer,
  RarityImg,
  RarityInfoContainer,
  RarityName,
  RarityDesc,
  RarityChance,
  SectionCommunity,
  CommunityTitle,
  CommunityDesc,
  CommunitySocialsContainer,
  SocialContainer,
  SocialDiamond,
};
