import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer2};
  display: block;
  width: 100%;
  /* height: 35rem; */
  aspect-ratio: 16 / 9;
  border: 2px solid #555555;
  /* border-radius: 0.3rem; */
  box-shadow: 0 0 10px 7px #00000055;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &:hover {
    & > #featured-card-background-image {
      width: 103%;
      height: 103%;
    }
  }
`;

const BackgroundImage = styled('img')`
  display: block;
  width: 100%;
  height: 100%;
  /* border-radius: 0.3rem; */
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 1.3s ease-in-out, height 1.3s ease-in-out;
`;

const GradientOverlay = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  /* border-radius: 0.3rem; */
  position: absolute;
  top: 0;
  left: 0;
`;

const Logo = styled('img')`
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: solid 3px ${({ theme }) => theme.custom.colors.BgLayer2};
  position: absolute;
  top: 2rem;
  left: 2rem;
`;

const ContentContainer = styled('div')`
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.7) 55%,
    rgba(0, 0, 0, 0.4) 80%,
    rgba(0, 0, 0, 0.2) 90%,
    rgba(0, 0, 0, 0) 100%
  );
  display: block;
  width: 100%;
  padding: 6rem 2rem 2rem 2rem;
  /* border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; */
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Title = styled('p')`
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};
  /* color: #ffffff; */
`;

const Subtitle = styled('p')`
  display: -webkit-box;
  width: 100%;
  max-height: 2.6rem;
  margin-top: 1rem;
  overflow: hidden;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
  color: #ffffff;

  & * {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.3rem;
  }

  & > * {
    display: inline;
  }

  & > *:not(:first-of-type) {
    margin-left: 0.2rem;
  }
`;

const Date = styled('p')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1rem;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
  color: #ffffff77;
`;

export default {
  Root,
  BackgroundImage,
  GradientOverlay,
  Logo,
  ContentContainer,
  Title,
  Subtitle,
  Date,
};
