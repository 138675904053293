import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { logMain } from 'src/modules/logger/logger';
import { discordAuthStart } from 'src/api/discord/discord-auth-start';
import { discordUnlink } from 'src/api/discord/discord-unlink';
import { setCurrentProfile } from 'src/redux/slices/current-profile/current-profile';
import { setSnackbar } from 'src/redux/slices/snackbar/snackbar';

interface UseDiscordAuthenticationprops {
  fromContext: string;
}

export const useDiscordAuthentication = ({ fromContext }: UseDiscordAuthenticationprops) => {
  const [processingLink, setProcessingLink] = useState(false);
  const [processingUnlink, setProcessingUnlink] = useState(false);
  const { t } = useTranslation(nameSpace.common);
  const dispatch = useAppDispatch();
  const navigateExternal = useAppNavigateExternal();

  const linkDiscord = () => {
    setProcessingLink(true);

    dispatch(discordAuthStart())
      .then(data => {
        if (data.authUrl) {
          navigateExternal(data.authUrl, {
            pooleData: { pageName: 'Discord Link', fromContext },
          });
        }

        setProcessingLink(false);
      })
      .catch(e => {
        logMain.debug({ e });

        setProcessingLink(false);
      });
  };

  const unlinkDiscord = () => {
    setProcessingUnlink(true);

    dispatch(discordUnlink())
      .then(data => {
        if (data && Object.keys(data).length > 0) {
          dispatch(setCurrentProfile(data)); // Redux: Set current profile as returned profile
        }

        setProcessingUnlink(false);
      })
      .catch(e => {
        logMain.debug({ e });

        dispatch(setSnackbar({ severity: 'error', msg: t('common.discord.unlink.error') }));
        setProcessingUnlink(false);
      });
  };

  return {
    linkDiscord,
    processingLink,
    unlinkDiscord,
    processingUnlink,
  };
};
