import { CSSProperties } from 'react';
import { InputBase, InputProps as InputPropsBase, styled } from '@mui/material';

interface InputProps extends InputPropsBase {
  height?: CSSProperties['height'];
}

export const Input = styled(InputBase)<InputProps>`
  &.MuiInputBase-root {
    width: 100%;
  }

  &.MuiInputBase-root .MuiInputBase-input {
    border: 1px solid ${({ theme }) => theme.custom.colors.BorderField};
    border-radius: 4px;
    background-color: ${({ theme }) => theme.custom.colors.BgDefault};
    font-size: 16px;
    height: ${({ height = '28px' }) => height};
    padding: 8px 12px;
    position: relative;
    width: 100%;

    &:focus {
      background-color: ${({ theme }) => theme.custom.colors.FieldHighlight};
      outline: 2px solid ${({ theme }) => theme.custom.colors.BorderHighlight};
    }
  }
`;
