import { useContext } from 'react';
import BroadcastChannelContext from 'src/context/broadcast-channel/BroadcastChannel.context';

const useBroadcastChannelContext = () => {
  const broadcastChannelContext = useContext(BroadcastChannelContext);

  return broadcastChannelContext;
};

export default useBroadcastChannelContext;
