import { createContext } from 'react';

export interface IPendingPurchasesContext {
  loadPendingPurchases: boolean;
  setLoadPendingPurchases: (loadPendingPurchases: boolean) => void;
  concurrentPurchaseLimit: number;
  setConcurrentPurchaseLimit: (concurrentPurchaseLimit: number) => void;
}

export const initialContext: IPendingPurchasesContext = {
  loadPendingPurchases: false,
  setLoadPendingPurchases: () => undefined,
  concurrentPurchaseLimit: 0,
  setConcurrentPurchaseLimit: () => undefined,
};

const PendingPurchasesContext = createContext<IPendingPurchasesContext>(initialContext);

PendingPurchasesContext.displayName = 'PendingPurchasesContext';

export default PendingPurchasesContext;
