import { CSSProperties } from 'react';
import { styled } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';

export interface IImageStyledProps {
  borderRadius?: CSSProperties['borderRadius'];
  height?: CSSProperties['height'];
  loaded?: boolean;
  margin?: CSSProperties['margin'];
  objectFit?: CSSProperties['objectFit'];
  padding?: CSSProperties['padding'];
  width?: CSSProperties['width'];
}

const Root = styled('img')<IImageStyledProps>`
  border-radius: ${({ borderRadius = '0px' }) => borderRadius};
  height: ${({ height = '100%' }) => height};
  margin: ${({ margin = '0px' }) => margin};
  object-fit: ${({ objectFit = 'initial' }) => objectFit};
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  padding: ${({ padding = '0px' }) => padding};
  transition: opacity 0.3s ease-in;
  width: ${({ width = '100%' }) => width};
  max-height: 100%;
`;

const Wrapper = styled('div')<IImageStyledProps>`
  height: ${({ height = '100%' }) => height};
  position: relative;
  width: ${({ width = '100%' }) => width};
`;

const ImagePlaceholder = styled('div')<IImageStyledProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ borderRadius = '0px' }) => borderRadius};
  display: ${({ loaded }) => (loaded ? 'none' : 'flex')};
  height: ${({ height = '100%' }) => height};
  justify-content: center;
  position: absolute;
  top: 0;
  transition: opacity 0.3s ease-out;
  width: ${({ width = '100%' }) => width};
  padding: 20%;
`;

const HoverIconWrapper = styled('div')<IImageStyledProps>`
  align-items: center;
  border-radius: ${({ borderRadius = '0px' }) => borderRadius};
  display: flex;
  height: ${({ height = '100%' }) => height};
  justify-content: center;
  position: absolute;
  top: 0;
  width: ${({ width = '100%' }) => width};
  padding: 20%;
  opacity: 0;
  transition: opacity 0.3s linear;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.8;
  }
`;

const Icon = styled(BaseIcon)`
  width: 38px;
  height: 38px;
  color: ${({ theme }) => theme.custom.colors.IconWeak};
  background-color: transparent;
`;

export default { Root, Wrapper, ImagePlaceholder, HoverIconWrapper, Icon };
