import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import AccountDropdown from 'src/components/header/AccountDropdown';
// import ButtonHex from 'src/components/input/ButtonHex';
import ButtonLogin from 'src/components/input/ButtonLogin';
import { useMediaQuery, useTheme } from '@mui/material';
import { config } from 'src/config/config';
import Styled from 'src/components/header/MenuRight.styles';
import ButtonPrimary from '../input/ButtonPrimary';

const MenuRight: FC = () => {
  const { pathname } = useLocation();
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  // const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const navigateInternal = useAppNavigateInternal();
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(config.ui_variables.mobile_breakpoints.header));

  // UNCOMMENT IF: Want to conditionally show play button when game is enabled / disabled
  // const gameIsGated = currentProfile?.features?.gameAccess?.eventGated;
  // const gatedGameStartTimestampMs = currentProfile?.features?.gameAccess?.startTimestampMs;
  // const gatedGameEndTimestampMs = currentProfile?.features?.gameAccess?.endTimestampMs;
  // const gameAccessAllowed = currentProfile?.features?.gameAccess?.allowed;
  // const gameAvailable =
  //   !gameIsGated ||
  //   (gameIsGated &&
  //     gatedGameStartTimestampMs &&
  //     gatedGameEndTimestampMs &&
  //     Date.now() >= gatedGameStartTimestampMs &&
  //     Date.now() < gatedGameEndTimestampMs);

  const handlePlay = (path: string, gtmEvent?: () => void) => {
    navigateInternal(path, { pooleData: { fromContext: 'play_now_button' }, gtmEvent: gtmEvent || null });
  };

  return (
    <Styled.Root>
      {pathname.indexOf('play') === -1 && authenticated && (
        // <ButtonPrimary
        //   type="button"
        //   hoverEffect
        //   variant="gold"
        //   width={mobileView ? 100 : 110}
        //   height={42}
        //   cornerOffset={14}
        //   title={t('common.play.now')}
        //   textStyle={{ paddingTop: '1px', fontWeight: '600', fontSize: '0.7rem' }}
        //   style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: mobileView ? '0.5rem' : '1rem' }}
        //   onClick={() => handlePlay('/play')}
        // />
        <ButtonPrimary
          type="button"
          title={t('common.play.now')}
          width={mobileView ? '110px' : '148px'}
          height={mobileView ? '42px' : '50px'}
          hideBackgroundImg
          enablePulseAnimation
          fontSize={mobileView ? 14 : 17}
          textStyle={{ paddingTop: '1px' }}
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: mobileView ? '1px 0.5rem 0 0' : '1px 1rem 0 0',
          }}
          onClick={() => handlePlay('/play')}
        />
      )}

      {!authenticated && (
        <ButtonLogin
          width={150}
          height={42}
          textStyle={{ paddingTop: '1px', fontWeight: '700', fontSize: '0.7rem' }}
          style={{ display: 'inline-block', verticalAlign: 'middle' }}
        />
      )}

      {authenticated && <AccountDropdown />}
    </Styled.Root>
  );
};

export default MenuRight;
