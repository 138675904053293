/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
// import { PooleEvents } from 'src/modules/poole/events';
import GameClientContext, { initialContext, IGameClientContext } from 'src/context/game-client/GameClient.context';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import useBroadcastChannelContext from 'src/hooks/useBroadcastChannelContext';
import useAlertOverrideContext from 'src/hooks/useAlertOverrideContext';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { playStart } from 'src/api/play/play-start';
import createGameIFrameBlob from 'src/tools/createGameIFrameBlob';
import isActiveBlobUrl from 'src/tools/isActiveBlobUrl';
import { GTMEvents } from 'src/modules/gtm/events';
import { getServerGameTime } from 'src/tools/getGameTime';
import { updateGameAccess } from 'src/redux/slices/current-profile/current-profile';
import { ServerError } from 'src/api/request';
import { LocalStorage } from 'src/local-storage/local-storage';
import { logMain } from 'src/modules/logger/logger';

interface GameClientProviderProps {
  children: ReactNode;
}

const GameClientProvider: FC<GameClientProviderProps> = ({ children }) => {
  const { sendBroadcastMessage } = useBroadcastChannelContext();
  const { enableGameOverlay } = useGameOverlayContext();
  const { setEnableAlertOverride } = useAlertOverrideContext();
  const dispatch = useAppDispatch();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);

  const [dimensions, setDimensions] = useState<IGameClientContext['dimensions']>(initialContext.dimensions);
  const [iFrameBlobObjectURL, setIFrameBlobObjectURL] = useState<IGameClientContext['iFrameBlobObjectURL']>(
    initialContext.iFrameBlobObjectURL,
  );
  const [loadGameConfig, setLoadGameConfig] = useState<IGameClientContext['loadGameConfig']>(
    initialContext.loadGameConfig,
  );
  const [isLoadingGameConfig, setIsLoadingGameConfig] = useState<IGameClientContext['isLoadingGameConfig']>(
    initialContext.isLoadingGameConfig,
  );
  const [configLoaded, setConfigLoaded] = useState<IGameClientContext['configLoaded']>(initialContext.configLoaded);
  const [clearUnityClient, setClearUnityClient] = useState<IGameClientContext['clearUnityClient']>(
    initialContext.clearUnityClient,
  );
  const [isClearingUnityClient, setIsClearingUnityClient] = useState<IGameClientContext['isClearingUnityClient']>(
    initialContext.isClearingUnityClient,
  );
  const [showGamePage, setShowGamePage] = useState<IGameClientContext['showGamePage']>(initialContext.showGamePage);
  const [checkGameAvailability, setCheckGameAvailability] = useState<IGameClientContext['checkGameAvailability']>(
    initialContext.checkGameAvailability,
  );
  const [gameAvailable, setGameAvailable] = useState<IGameClientContext['gameAvailable']>(initialContext.gameAvailable);
  const [gameClientColor, setGameClientColor] = useState<IGameClientContext['gameClientColor']>(
    initialContext.gameClientColor,
  );
  const [devicePixelRatio, setDevicePixelRatio] = useState<IGameClientContext['devicePixelRatio']>(
    initialContext.devicePixelRatio,
  );
  const [unityClientLoadPercentage, setUnityClientLoadPercentage] = useState<
    IGameClientContext['unityClientLoadPercentage']
  >(initialContext.unityClientLoadPercentage);
  const [unityClientLoaded, setUnityClientLoaded] = useState<IGameClientContext['unityClientLoaded']>(
    initialContext.unityClientLoaded,
  );
  const [theatreMode, setTheatreMode] = useState<IGameClientContext['theatreMode']>(initialContext.theatreMode);
  const [fullscreen, setFullscreen] = useState<IGameClientContext['fullscreen']>(initialContext.fullscreen);
  const [leaving, setLeaving] = useState<IGameClientContext['leaving']>(initialContext.leaving);
  const [leavingTo, setLeavingTo] = useState<IGameClientContext['leavingTo']>(initialContext.leavingTo);
  const [leavingOutbound, setLeavingOutbound] = useState<IGameClientContext['leavingOutbound']>(
    initialContext.leavingOutbound,
  );
  const [gameConfigError, setGameConfigError] = useState<IGameClientContext['gameConfigError']>(
    initialContext.gameConfigError,
  );
  const [fullscreenModal, setFullscreenModal] = useState<IGameClientContext['fullscreenModal']>(
    initialContext.fullscreenModal,
  );
  const [gameConfigErrorMessage, setGameConfigErrorMessage] = useState<IGameClientContext['gameConfigErrorMessage']>(
    initialContext.gameConfigErrorMessage,
  );
  const [listNFTModalNonfungibleId, setListNFTModalNonfungibleId] = useState<
    IGameClientContext['listNFTModalNonfungibleId']
  >(initialContext.listNFTModalNonfungibleId);
  const [multipleInstanceModal, setMultipleInstanceModal] = useState<IGameClientContext['multipleInstanceModal']>(
    initialContext.multipleInstanceModal,
  );
  const [searchMarketsFailureModal, setSearchMarketsFailureModal] = useState<
    IGameClientContext['searchMarketsFailureModal']
  >(initialContext.searchMarketsFailureModal);
  const [gameOverlayErrorModal, setGameOverlayErrorModal] = useState<IGameClientContext['gameOverlayErrorModal']>(
    initialContext.gameOverlayErrorModal,
  );
  // For Debug Widget
  const [disableGameClientByDebug, setDisableGameClientByDebug] = useState<
    IGameClientContext['disableGameClientByDebug']
  >(initialContext.disableGameClientByDebug);
  const [isGameClientDisabledByDebug, setIsGameClientDisabledByDebug] = useState<
    IGameClientContext['isGameClientDisabledByDebug']
  >(initialContext.isGameClientDisabledByDebug);
  const [disableGameClientByMultipleInstances, setDisableGameClientByMultipleInstances] = useState<
    IGameClientContext['disableGameClientByMultipleInstances']
  >(initialContext.disableGameClientByMultipleInstances);
  const [isGameClientDisabledByMultipleInstances, setIsGameClientDisabledByMultipleInstances] = useState<
    IGameClientContext['isGameClientDisabledByMultipleInstances']
  >(initialContext.isGameClientDisabledByMultipleInstances);
  const [isDisablingGameClient, setIsDisablingGameClient] = useState<IGameClientContext['isDisablingGameClient']>(
    initialContext.isDisablingGameClient,
  );

  // Load Game Config
  useEffect(() => {
    const handleLoadSuccess = async (data: any) => {
      // Fire GTM event for laod game config success
      GTMEvents.loadGameConfigSuccess({ eventContext: 'load-game-config-success' });

      const gameIframeBlobURL = createGameIFrameBlob({
        codeUrl: data?.codeUrl || '',
        dataUrl: data?.dataUrl || '',
        frameworkUrl: data?.frameworkUrl || '',
        loaderUrl: data?.loaderUrl || '',
        version: data?.version || '',
        devicePixelRatio,
        marketOverlayEnabled: enableGameOverlay,
        listNFTModalNonfungibleId,
      });
      const validURL = await isActiveBlobUrl(gameIframeBlobURL);

      if (validURL) {
        await setIFrameBlobObjectURL(gameIframeBlobURL);
        await setGameConfigError(false);
        await setGameConfigErrorMessage('');
      } else {
        await setIFrameBlobObjectURL('');
        await setGameConfigError(true);
        await setGameConfigErrorMessage('Error generating iFrame for game client');
      }

      await setSearchMarketsFailureModal(initialContext.searchMarketsFailureModal);
      await setGameOverlayErrorModal(initialContext.gameOverlayErrorModal);
      await setListNFTModalNonfungibleId(initialContext.listNFTModalNonfungibleId);
      await setMultipleInstanceModal(initialContext.multipleInstanceModal);
      await setEnableAlertOverride(true);
      await setIsLoadingGameConfig(false);

      // Send broadcast message to other browser tabs / windows to disable their clients
      sendBroadcastMessage({ unityClientInitializing: true });

      // Set the Local Storage value to denote user having navigated to /play page
      LocalStorage.set('navigated_to_play_page', 'true');

      logMain.debug('[GAME_CLIENT]: Successfully loaded game client config.');
    };

    const handleLoadFail = async (e: Error) => {
      // Fire GTM event for laod game config success
      GTMEvents.loadGameConfigFail({ eventContext: 'load-game-config-fail' });

      await setGameConfigError(true);
      await setGameConfigErrorMessage(e instanceof ServerError ? e.message : '');
      await setSearchMarketsFailureModal(initialContext.searchMarketsFailureModal);
      await setGameOverlayErrorModal(initialContext.gameOverlayErrorModal);
      await setListNFTModalNonfungibleId(initialContext.listNFTModalNonfungibleId);
      await setMultipleInstanceModal(initialContext.multipleInstanceModal);
      await setEnableAlertOverride(false);
      await setIsLoadingGameConfig(false);

      logMain.debug('[GAME_CLIENT]: Failed to load game client config...');
    };

    if (loadGameConfig) {
      logMain.debug('[GAME_CLIENT]: Loading game client config...');

      setLoadGameConfig(false);
      setIsLoadingGameConfig(true);
      setConfigLoaded(true);

      // Fire GTM event for laod game config
      GTMEvents.loadGameConfig({ eventContext: 'load-game-config' });

      dispatch(playStart())
        .then((data: any) => {
          handleLoadSuccess(data);
          dispatch(updateGameAccess({ ...currentProfile?.features?.gameAccess, allowed: true }));
        })
        .catch((e: any) => {
          logMain.debug(e);

          handleLoadFail(e);
        });
    }
  }, [
    currentProfile?.features?.gameAccess,
    devicePixelRatio,
    dispatch,
    enableGameOverlay,
    listNFTModalNonfungibleId,
    loadGameConfig,
    sendBroadcastMessage,
    setEnableAlertOverride,
    unityClientLoadPercentage,
  ]);

  // Clear the Unity Client config and unload client
  useEffect(() => {
    const handleClearUnityClient = async () => {
      setIsClearingUnityClient(true);

      // IMPORTANT: In order to prevent memory leaks, we must revoke the object URL, which should automatically unmount the iframe afterwards
      if (iFrameBlobObjectURL) URL.revokeObjectURL(iFrameBlobObjectURL);

      setIFrameBlobObjectURL(initialContext.iFrameBlobObjectURL);
      setEnableAlertOverride(false);
      setFullscreen(initialContext.fullscreen);
      setUnityClientLoadPercentage(initialContext.unityClientLoadPercentage);
      setUnityClientLoaded(initialContext.unityClientLoaded);
      setConfigLoaded(initialContext.configLoaded);
      setGameConfigError(initialContext.gameConfigError);
      setSearchMarketsFailureModal(initialContext.searchMarketsFailureModal);
      setGameOverlayErrorModal(initialContext.gameOverlayErrorModal);
      setListNFTModalNonfungibleId(initialContext.listNFTModalNonfungibleId);
      setIsClearingUnityClient(initialContext.isClearingUnityClient);

      logMain.debug('[GAME_CLIENT]: Successfully cleared unity client.');
    };

    if (clearUnityClient) {
      logMain.debug('[GAME_CLIENT]: Clearing unity client...');

      setClearUnityClient(false);

      handleClearUnityClient();
    }
  }, [clearUnityClient, iFrameBlobObjectURL, setEnableAlertOverride]);

  // Check the game availability given the gated availability period
  useEffect(() => {
    const handleCheckGameAvailability = async () => {
      const eventGated = currentProfile?.features?.gameAccess?.eventGated;
      const startTimestampMs = currentProfile?.features?.gameAccess?.startTimestampMs;
      const endTimestampMs = currentProfile?.features?.gameAccess?.endTimestampMs;
      const now = getServerGameTime();

      // Check if game play period is gated (alpha, beta, etc...)
      if (eventGated && startTimestampMs && endTimestampMs) {
        // If game is available, load game config, else unload game
        if (now >= startTimestampMs && now < endTimestampMs) {
          // If game is available and isn't already loaded or being loaded, load game config
          if (!configLoaded && !isLoadingGameConfig) setLoadGameConfig(true);

          !gameAvailable && setGameAvailable(true);
        } else {
          // If game is not available and isn't already unloaded or being unloaded, unload game
          if (unityClientLoaded && !isClearingUnityClient) setClearUnityClient(true);

          gameAvailable && setGameAvailable(false);
        }
      } else {
        // If game is available and isn't already loaded or being loaded, load game config
        if (!configLoaded && !isLoadingGameConfig) setLoadGameConfig(true);

        !gameAvailable && setGameAvailable(true);
      }
    };

    if (checkGameAvailability) {
      setCheckGameAvailability(false);

      handleCheckGameAvailability();
    }
  }, [
    checkGameAvailability,
    configLoaded,
    currentProfile?.features?.gameAccess?.endTimestampMs,
    currentProfile?.features?.gameAccess?.eventGated,
    currentProfile?.features?.gameAccess?.startTimestampMs,
    gameAvailable,
    isClearingUnityClient,
    isLoadingGameConfig,
    unityClientLoaded,
  ]);

  // DEBUG WIDGET: Clear the Unity Client config, unload client and disable game client
  useEffect(() => {
    const handleDisableGameClient = async (fromDebug: boolean) => {
      setIsDisablingGameClient(true);

      // IMPORTANT: In order to prevent memory leaks, we must revoke the object URL, which should automatically unmount the iframe afterwards
      if (iFrameBlobObjectURL) URL.revokeObjectURL(iFrameBlobObjectURL);

      setIFrameBlobObjectURL(initialContext.iFrameBlobObjectURL);
      setEnableAlertOverride(false);
      setFullscreen(initialContext.fullscreen);
      setUnityClientLoadPercentage(initialContext.unityClientLoadPercentage);
      setUnityClientLoaded(initialContext.unityClientLoaded);
      setConfigLoaded(initialContext.configLoaded);
      setSearchMarketsFailureModal(initialContext.searchMarketsFailureModal);
      setGameOverlayErrorModal(initialContext.gameOverlayErrorModal);
      setListNFTModalNonfungibleId(initialContext.listNFTModalNonfungibleId);
      setIsGameClientDisabledByDebug(fromDebug);
      setIsGameClientDisabledByMultipleInstances(!fromDebug);
      setIsDisablingGameClient(false);
    };

    if (disableGameClientByDebug || disableGameClientByMultipleInstances) {
      const fromDebug = disableGameClientByDebug;

      if (disableGameClientByDebug) setDisableGameClientByDebug(false);
      if (disableGameClientByMultipleInstances) setDisableGameClientByMultipleInstances(false);

      handleDisableGameClient(fromDebug);
    }
  }, [disableGameClientByDebug, disableGameClientByMultipleInstances, iFrameBlobObjectURL, setEnableAlertOverride]);

  // Listen to changes in listing modal status and make the <listingInProgress> boolean accessible to the
  // game client by exposing it as a global variable
  useEffect(() => {
    // Send broadcast message to signal unity to open overlay or listing modal
    sendBroadcastMessage({
      enableGameOverlay,
      listingInProgress: !!listNFTModalNonfungibleId,
    });
  }, [enableGameOverlay, listNFTModalNonfungibleId, sendBroadcastMessage]);

  // Listen to unityClientLoaded and send GTM event for load game client
  useEffect(() => {
    //   // Fire GTM event for load game config success
    GTMEvents.loadGameClient({ eventContext: 'load-game-client' });
  }, [unityClientLoaded]);

  // Listen to changes in fullscreen and send broadcast message to unity client
  useEffect(() => {
    if (fullscreen) {
      sendBroadcastMessage({ unityClientFullscreen: true });
      setFullscreen(false);
    }
  }, [fullscreen, sendBroadcastMessage]);

  const value = useMemo(
    () => ({
      dimensions,
      setDimensions,
      iFrameBlobObjectURL,
      setIFrameBlobObjectURL,
      loadGameConfig,
      setLoadGameConfig,
      isLoadingGameConfig,
      setIsLoadingGameConfig,
      configLoaded,
      setConfigLoaded,
      clearUnityClient,
      setClearUnityClient,
      isClearingUnityClient,
      setIsClearingUnityClient,
      showGamePage,
      setShowGamePage,
      checkGameAvailability,
      setCheckGameAvailability,
      gameAvailable,
      setGameAvailable,
      gameClientColor,
      setGameClientColor,
      devicePixelRatio,
      setDevicePixelRatio,
      unityClientLoadPercentage,
      setUnityClientLoadPercentage,
      unityClientLoaded,
      setUnityClientLoaded,
      theatreMode,
      setTheatreMode,
      fullscreen,
      setFullscreen,
      leaving,
      setLeaving,
      leavingTo,
      setLeavingTo,
      leavingOutbound,
      setLeavingOutbound,
      gameConfigError,
      setGameConfigError,
      gameConfigErrorMessage,
      setGameConfigErrorMessage,
      fullscreenModal,
      setFullscreenModal,
      searchMarketsFailureModal,
      setSearchMarketsFailureModal,
      gameOverlayErrorModal,
      setGameOverlayErrorModal,
      listNFTModalNonfungibleId,
      setListNFTModalNonfungibleId,
      multipleInstanceModal,
      setMultipleInstanceModal,
      disableGameClientByDebug,
      setDisableGameClientByDebug,
      isGameClientDisabledByDebug,
      setIsGameClientDisabledByDebug,
      disableGameClientByMultipleInstances,
      setDisableGameClientByMultipleInstances,
      isGameClientDisabledByMultipleInstances,
      setIsGameClientDisabledByMultipleInstances,
      isDisablingGameClient,
      setIsDisablingGameClient,
    }),
    [
      dimensions,
      setDimensions,
      iFrameBlobObjectURL,
      setIFrameBlobObjectURL,
      loadGameConfig,
      setLoadGameConfig,
      isLoadingGameConfig,
      setIsLoadingGameConfig,
      configLoaded,
      setConfigLoaded,
      clearUnityClient,
      setClearUnityClient,
      isClearingUnityClient,
      setIsClearingUnityClient,
      showGamePage,
      setShowGamePage,
      checkGameAvailability,
      setCheckGameAvailability,
      gameAvailable,
      setGameAvailable,
      gameClientColor,
      setGameClientColor,
      devicePixelRatio,
      setDevicePixelRatio,
      unityClientLoadPercentage,
      setUnityClientLoadPercentage,
      unityClientLoaded,
      setUnityClientLoaded,
      theatreMode,
      setTheatreMode,
      fullscreen,
      setFullscreen,
      leaving,
      setLeaving,
      leavingTo,
      setLeavingTo,
      leavingOutbound,
      setLeavingOutbound,
      gameConfigError,
      setGameConfigError,
      gameConfigErrorMessage,
      setGameConfigErrorMessage,
      fullscreenModal,
      setFullscreenModal,
      searchMarketsFailureModal,
      setSearchMarketsFailureModal,
      gameOverlayErrorModal,
      setGameOverlayErrorModal,
      listNFTModalNonfungibleId,
      setListNFTModalNonfungibleId,
      multipleInstanceModal,
      setMultipleInstanceModal,
      disableGameClientByDebug,
      setDisableGameClientByDebug,
      isGameClientDisabledByDebug,
      setIsGameClientDisabledByDebug,
      disableGameClientByMultipleInstances,
      setDisableGameClientByMultipleInstances,
      isGameClientDisabledByMultipleInstances,
      setIsGameClientDisabledByMultipleInstances,
      isDisablingGameClient,
      setIsDisablingGameClient,
    ],
  );

  return <GameClientContext.Provider value={value}>{children}</GameClientContext.Provider>;
};

export default GameClientProvider;
