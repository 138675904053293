import { FC, useState } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import OpenOddsModal from 'src/components/modals/OpenOddsModal';
import Styled from 'src/components/primary-marketplace/components/OpenOddsButton.styles';

interface OpenOddsButtonProps {
  lpmId: string;
  style?: React.CSSProperties;
}

const OpenOddsButton: FC<OpenOddsButtonProps> = ({ lpmId, style = {} }) => {
  const [oddsModalOpen, setOddsModalOpen] = useState(false);
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const { color } = listPriceMarket;

  if (!listPriceMarket) return null;

  return (
    <Styled.Root style={style}>
      <OpenOddsModal open={oddsModalOpen} lpm={listPriceMarket} handleClose={() => setOddsModalOpen(false)} />

      <Styled.Button color={color} onClick={() => setOddsModalOpen(!oddsModalOpen)}>
        <span className="material-icons-outlined">info</span>
      </Styled.Button>
    </Styled.Root>
  );
};

export default OpenOddsButton;
