import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  position: absolute;
  top: 2.7rem;
  right: 2rem;
`;

const Separator = styled('div')`
  background-color: #ffffff33;
  display: inline-block;
  height: 1.2rem;
  width: 1px;
  margin: 0.5rem 1.4rem 0 1.4rem;
  vertical-align: middle;

  @media (max-width: 768px) {
    display: none;
  }

  /* @media (max-width: 400px) {
    margin: 0.5rem 1rem 0 1rem;
  } */
`;

const DropdownsButtons = styled('div')`
  display: inline-block;
  vertical-align: middle;

  & > span {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0.5rem;
    cursor: pointer;
  }

  & > span:not(:last-child) {
    margin-right: 1.4rem;

    @media (max-width: 400px) {
      margin-right: 0 1rem;
    }
  }

  & .material-icons {
    font-size: 1.2rem;
    transition: color 0.4s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }
`;

const MenuItem = styled('div')`
  display: block;
  width: 100%;
  padding: 0.4rem 0.8rem;
  box-sizing: border-box;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;

  & .material-icons {
    display: inline-block;
    margin-right: 0.8rem;
    vertical-align: middle;
    font-size: 1.1rem;

    &:hover {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }

  &:hover {
    background-color: #ffffff33;
  }
`;

const MenuItemText = styled('div')`
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  /* color: #000; */
`;

export default {
  Root,
  Separator,
  DropdownsButtons,
  MenuItem,
  MenuItemText,
};
