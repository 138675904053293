import { FC } from 'react';
import useDiscordWidgetContext from 'src/hooks/useDiscordWidgetContext';
import useDebugWidgetContext from 'src/hooks/useDebugWidgetContext';
import Styled from 'src/components/debug-widget/ToggleButton.styles';

const ToggleButton: FC = () => {
  const { showDiscordWidget } = useDiscordWidgetContext();
  const { debugWidgetOpen, setDebugWidgetOpen } = useDebugWidgetContext();

  return (
    <Styled.Root showDiscordWidget={showDiscordWidget} onClick={() => setDebugWidgetOpen(!debugWidgetOpen)}>
      <span className="material-icons">settings</span>
    </Styled.Root>
  );
};

export default ToggleButton;
