import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-block;
  min-width: 20rem;
  padding: 0 1rem 0 2.5rem;

  & > div:first-of-type {
    margin-top: 1.3rem;
  }

  @media (max-width: 900px) {
    padding: 0 0 0 2rem;
  }

  @media (max-height: 600px) {
    padding: 0 0rem 0 1.5rem;
  }

  @media (max-height: 500px) {
    min-width: 18rem;
    padding: 0 0rem 0 1.5rem;

    & > div:first-of-type {
      margin-top: 0.8rem;
    }
  }
`;

const RequirementsLabel = styled('p')`
  display: block;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Heading};

  @media (max-height: 600px) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  @media (max-height: 500px) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  @media (max-height: 400px) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`;

const Requirements = styled('div')`
  display: block;
  width: 100%;
  min-width: 18rem;
  max-width: 25rem;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:not(:first-of-type) {
    margin-top: 0.6rem;
  }

  @media (max-height: 500px) {
    min-width: 12rem;

    & > div:not(:first-of-type) {
      margin-top: 0.4rem;
    }
  }

  @media (max-height: 350px) {
    max-height: 6rem;
  }
`;

const Requirement = styled('div')`
  display: block;
  width: 100%;
`;

const RequirementText = styled('p')`
  display: block;
  margin-left: 2rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: ${({ theme }) => `${theme.custom.colors.BgLayer3Heading}cc`};

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin: 0 0 0 0.3rem;
    vertical-align: middle;
    font-size: 1.3rem;
    line-height: 1.2rem;
    font-weight: 400;
  }

  @media (max-height: 600px) {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media (max-height: 500px) {
    margin-left: 1.7rem;
    font-size: 0.9rem;
    line-height: 1.3rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 1rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 400px) {
    font-size: 0.7rem;
    line-height: 1.1rem;
  }
`;

interface RequirementHelpProps {
  disabled: boolean;
}

const RequirementHelp = styled('p')<RequirementHelpProps>`
  display: inline-block;
  margin-top: 0.5rem;
  margin-left: 2rem;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.Yellow};
  /* color: cyan; */
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    text-decoration: underline;
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin: 0 0 0.1rem 0.1rem;
    vertical-align: middle;
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-height: 600px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-height: 500px) {
    margin-left: 1.7rem;
    margin-top: 0.3rem;
    font-size: 0.7rem;
    line-height: 1.1rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  @media (max-height: 400px) {
    margin-top: 0.1rem;
    font-size: 0.6rem;
    line-height: 1rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }
  }
`;

const TooltipContainer = styled('div')`
  display: block;
  width: 100%;
`;

const TooltipHeading = styled('p')`
  display: block;
  font-family: 'Barlow';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #ffffff;
`;

const TooltipRows = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;
`;

const TooltipRow = styled('p')`
  display: block;
  /* padding-left: 1rem; */
  font-family: 'Barlow';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const TooltipNotice = styled('p')`
  display: block;
  /* padding-left: 1rem; */
  margin-top: 1rem;
  font-family: 'Barlow';
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 400;
  font-style: italic;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

export default {
  Root,
  RequirementsLabel,
  Requirements,
  Requirement,
  RequirementText,
  RequirementHelp,
  TooltipContainer,
  TooltipHeading,
  TooltipRows,
  TooltipRow,
  TooltipNotice,
};
