import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useLoadDropEvents } from 'src/hooks/useLoadDropEvents';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getDropEventsArr } from 'src/redux/slices/drop-events/drop-events';
import { getListPriceMarketsArr } from 'src/redux/slices/list-price-markets/list-price-markets';
import nestedSort from 'src/tools/nestedSort';
import LineTextureBackground from 'src/components/backgrounds/LineTextureBackground';
import PageError from 'src/components/feedback/PageError';
import SwiperNavigation from 'src/components/primary-marketplace/components/SwiperNavigation';
// import DropEventsTabs from 'src/components/primary-marketplace/components/DropEventsTabs';
import ListPriceMarket from 'src/components/primary-marketplace/components/ListPriceMarket';
import Styled from 'src/components/primary-marketplace/PrimaryMarketplace.styles';

type DropEventTag = 'pack' | 'daily' | 'hero';

interface PrimaryMarketplaceProps {
  dropEventTags?: DropEventTag[];
  disablePageError?: boolean;
}

const PrimaryMarketplace: FC<PrimaryMarketplaceProps> = ({ dropEventTags = [], disablePageError = false }) => {
  const { gameOverlayLocation } = useGameOverlayContext();
  const emptyDropEventTags = useRef<DropEventTag[]>([]);
  const { loading, error } = useLoadDropEvents({
    dropEventTags: emptyDropEventTags.current,
    loadListPriceMarkets: true,
    redirectOnError: false,
    replaceDropEvents: true,
    replaceListPriceMarkets: true,
    fromContext: 'game_overlay_drop_events',
  });
  const dropEvents = useAppSelector(state => getDropEventsArr(state));
  const lpms = useAppSelector(state => getListPriceMarketsArr(state));
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const [hasSetInitialSlide, setHasSetInitialSlide] = useState<boolean>(false);
  const [initialSlide, setInitialSlide] = useState<number>(0);
  const [swiperIndex, setSwiperIndex] = useState<number>(0);
  const flattenedListPriceMarkets = useRef<ListPriceMarket[]>([]);
  const targetLPMId = useMemo(
    () => gameOverlayLocation?.params?.listPriceMarketId,
    [gameOverlayLocation?.params?.listPriceMarketId],
  );

  useEffect(() => {
    const filteredDropEvents = dropEvents.filter(
      dropEvent => dropEventTags.indexOf(dropEvent.type as DropEventTag) !== -1,
    );

    if (isLoadingPage && !loading && filteredDropEvents.length > 0 && lpms.length > 0) {
      setIsLoadingPage(false);

      const sortedDropEvents = nestedSort(filteredDropEvents, 1, 'displayOrder');
      const newFlattenedListPriceMarkets: ListPriceMarket[] = [];

      sortedDropEvents.forEach(dropEvent => {
        const filteredLPMs = lpms.filter(lpm => lpm.dropEventId === dropEvent.id);
        const sortedLPMs = nestedSort(filteredLPMs, 1, 'displayOrder');

        sortedLPMs.forEach(lpm => {
          newFlattenedListPriceMarkets.push(lpm);
        });
      });

      flattenedListPriceMarkets.current = [...newFlattenedListPriceMarkets];

      if (!hasSetInitialSlide) {
        if (targetLPMId) {
          // If there is a listPriceMarketId in the gameOverlayLocation, set the initial slide to that index
          const targetLPMIndex = flattenedListPriceMarkets.current.findIndex(lpm => lpm.id === targetLPMId);

          setInitialSlide(targetLPMIndex > -1 ? targetLPMIndex : 0);
          setHasSetInitialSlide(true);
        } else {
          setInitialSlide(0);
          setHasSetInitialSlide(true);
        }
      }
    }
  }, [dropEventTags, dropEvents, hasSetInitialSlide, isLoadingPage, loading, lpms, targetLPMId]);

  // If data is not loaded return blank page
  if (loading || !hasSetInitialSlide || flattenedListPriceMarkets.current.length === 0) return <Styled.Root />;
  // If data loaded incorrectly return page error
  if (error && !disablePageError) {
    return (
      <PageError
        style={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', width: 'auto', height: 'auto' }}
      />
    );
  }

  return (
    <Styled.Root numSlides={flattenedListPriceMarkets.current.length || 0}>
      <LineTextureBackground stroke={'#6FB6E709'} />

      {flattenedListPriceMarkets.current.map((listPriceMarket, i) => {
        const { color } = listPriceMarket;

        return <Styled.BottomGradient key={`bottom-gradient-${i}`} color={color} active={i === swiperIndex} />;
      })}

      <Swiper
        initialSlide={initialSlide}
        slidesPerView={'auto'}
        spaceBetween={0}
        freeMode
        speed={1000}
        allowTouchMove
        simulateTouch
        mousewheel
        onSlideChange={e => setSwiperIndex(e.realIndex)}
        modules={[FreeMode]}
        centerInsufficientSlides={false}
      >
        {flattenedListPriceMarkets.current.map(listPriceMarket => {
          return (
            <SwiperSlide key={`list-price-market-slide-${listPriceMarket.id}`}>
              <ListPriceMarket
                lpmId={listPriceMarket.id}
                singleMarketExists={flattenedListPriceMarkets.current.length === 1}
              />
            </SwiperSlide>
          );
        })}

        <SwiperNavigation swiperIndex={swiperIndex} numSlides={flattenedListPriceMarkets.current.length} />
      </Swiper>
    </Styled.Root>
  );
};

export default PrimaryMarketplace;
