import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyDropEvents } from 'src/redux/slices/drop-events/helpers/copy-drop-events';
import nestedSort from 'src/tools/nestedSort';

// Define the initial state using that type
const initialState: DropEventsState = {
  dropEvents: {},
};

const dropEventsSlice = createSlice({
  name: 'dropEvents',
  initialState,
  reducers: {
    setDropEvents: {
      reducer(state, action: PayloadAction<DropEventsPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.dropEvents = copyDropEvents(action.payload.dropEvents);
      },
      prepare(dropEvents: DropEvent[]) {
        return {
          payload: {
            dropEvents,
          },
        };
      },
    },
    addDropEvents: {
      reducer(state, action: PayloadAction<DropEventsPayload>) {
        const existingDropEvents: DropEventsKC = state.dropEvents;
        const existingItemsArr = Object.keys(existingDropEvents).map((dropEventId: DropEventId) => {
          return existingDropEvents[dropEventId];
        });

        // eslint-disable-next-line no-param-reassign
        state.dropEvents = copyDropEvents(existingItemsArr.concat(action.payload.dropEvents));
      },
      prepare(dropEvents: DropEvent[]) {
        return {
          payload: {
            dropEvents,
          },
        };
      },
    },
    clearDropEvents: state => {
      // eslint-disable-next-line no-param-reassign
      state.dropEvents = {};
    },
  },
});

export const { setDropEvents, addDropEvents, clearDropEvents } = dropEventsSlice.actions;

// Other Selectors
export const getDropEventsMap = (state: RootState): DropEventsKC => state.dropEvents.dropEvents;
export const getDropEventsArr = (state: RootState): DropEvent[] => {
  const { dropEvents }: { dropEvents: DropEventsKC } = state.dropEvents;
  return Object.keys(dropEvents).map((key: DropEventId) => dropEvents[key]);
};
export const getSortedDropEventsArr = (state: RootState): DropEvent[] => {
  const { dropEvents }: { dropEvents: DropEventsKC } = state.dropEvents;
  const dropEventsArr = Object.keys(dropEvents).map((key: DropEventId) => dropEvents[key]);
  return nestedSort(dropEventsArr, 1, 'displayOrder');
};
export const getFeaturedDropEvent = (state: RootState): DropEvent | undefined => {
  const dropEvents: DropEvent[] = getDropEventsArr(state);
  return dropEvents.find(event => event.featured) || undefined;
};

export default dropEventsSlice.reducer;
