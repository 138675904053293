import { FC } from 'react';
import NavBar from 'src/components/header/NavBar';
import Styled from 'src/components/header/Header.styles';

const Header: FC = () => {
  return (
    <Styled.Root>
      <NavBar />
    </Styled.Root>
  );
};

export default Header;
