import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { useQuery } from 'src/hooks/useQuery';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getCurrentProfile } from 'src/api/profile/get-current-profile';
import { getProfile } from 'src/api/profile/get-profile';
import { setCurrentProfile } from 'src/redux/slices/current-profile/current-profile';
import {
  getLoadedProfileByUsernameOrPlayerId,
  setLoadedProfiles,
} from 'src/redux/slices/loaded-profiles/loaded-profiles';
import PageError from 'src/components/feedback/PageError';
import SectionHero from 'src/components/pages/profile/components/SectionHero';
import SectionDetails from 'src/components/pages/profile/components/SectionDetails';
import SectionTabResults from 'src/components/pages/profile/components/SectionTabResults';
import { logMain } from 'src/modules/logger/logger';
import Styled from 'src/components/pages/profile/Profile.styles';

const Profile: FC = () => {
  const { playerId = '' } = useParams();
  const params: (string | null)[] = useQuery(urlParams.profile);
  const defaultTab = getURLParamValue('default_tab', urlParams.profile, params);
  const [loading, setLoading] = useState<boolean>(true);
  const [tab, setTab] = useState(0);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const loadedProfile = useAppSelector(state => getLoadedProfileByUsernameOrPlayerId(state, playerId));
  const dispatch = useAppDispatch();
  const navigateInternal = useAppNavigateInternal();
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;

  // Load Page Data
  useEffect(() => {
    // Set default tab if query param exists
    if (defaultTab && !isNaN(parseInt(defaultTab, 10))) setTab(parseInt(defaultTab, 10));

    dispatch(isCurrentProfile ? getCurrentProfile() : getProfile({ urlParams: { playerId } }))
      .then(data => {
        if (data && Object.keys(data.length > 0)) {
          if (isCurrentProfile) {
            dispatch(setCurrentProfile(data));
          } else {
            dispatch(setLoadedProfiles([data]));
          }
        }

        setLoading(false);
      })
      .catch(e => {
        logMain.debug(e);
        navigateInternal('/page-not-found', { pooleData: { fromContext: 'profile' } });
      });
  }, [defaultTab, dispatch, isCurrentProfile, navigateInternal, playerId]);

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;
  // If were on current user's profile and data loaded incorrectly return page error
  if (isCurrentProfile && Object.keys(currentProfile).length === 0) return <PageError />;
  // If were on other user's profile and data loaded incorrectly return page error
  if (!isCurrentProfile && !loadedProfile) return <PageError />;

  return (
    <Styled.Root>
      <SectionHero />
      <SectionDetails tab={tab} setTab={setTab} />
      <SectionTabResults tab={tab} />
    </Styled.Root>
  );
};

export default Profile;
