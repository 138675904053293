import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 90%;
  max-width: 40rem;
  height: auto;
  max-height: 90vh;
  padding: 2rem 2rem 2rem 2rem;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(90vh - 4rem);
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  margin: 0 auto;
  background: -webkit-linear-gradient(#cccccc, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const SubheadingsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;

  & > p:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: #ffffff;

  & span:first-of-type {
    margin-right: 0.5rem;
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5rem;
    line-height: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
  }
`;

const InstallButton = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer3};
  display: block;
  width: 100%;
  max-width: 14rem;
  margin: 1.5rem auto 0 auto;
  padding: 0.5rem 0.5rem 0.4rem 0.5rem;
  border: 1px solid ${({ theme }) => theme.custom.colors.BgLayer3Accent};
  border-radius: 0.2rem;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};

    & > span {
      color: ${({ theme }) => theme.custom.colors.BgLayer3};
    }
  }

  & > span {
    display: inline-block;
    margin: 0.3rem 0.2rem;
    vertical-align: middle;
    transition: color 0.2s ease-in-out;
  }

  & .material-icons,
  & .material-icons-outlined {
    margin-top: 0.3rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
  }
`;

const OptOutContainer = styled('div')`
  display: flex;
  margin-top: 1.5rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 0.1rem;
  text-align: center;
`;

const OptOutCopy = styled('div')`
  display: inline-block;
  margin-top: 1px;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
`;

const ButtonsContainer = styled('div')`
  display: block;
  margin-top: 1.5rem;
  text-align: center;
`;

export default {
  Root,
  ContentContainer,
  Heading,
  SubheadingsContainer,
  Subheading,
  InstallButton,
  OptOutContainer,
  OptOutCopy,
  ButtonsContainer,
};
