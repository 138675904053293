import { useContext } from 'react';
import PaymentPopupContext from 'src/context/payment-popup/PaymentPopup.context';

const usePaymentPopupContext = () => {
  const paymentPopupContext = useContext(PaymentPopupContext);

  return paymentPopupContext;
};

export default usePaymentPopupContext;
