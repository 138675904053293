import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  margin-top: 0.5rem;
  font-size: 0;

  & > span {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.3rem;
  }

  & > span:not(:last-of-type) {
    margin-right: 0.4rem;
  }
`;

// const RarityImg = styled('img')`
//   display: inline-block;
//   width: auto;
//   height: 1.4rem;
//   margin-right: 0.6rem;
//   vertical-align: middle;

//   @media (max-width: 768px) {
//     height: 1.3rem;
//   }
// `;

interface RarityProps {
  rarityColor?: string;
}

const Rarity = styled('span')<RarityProps>`
  text-transform: uppercase;
  color: ${({ rarityColor }) => rarityColor || '#fff'};
`;

// const SerialNumber = styled('span')<RarityProps>`
//   text-transform: uppercase;
//   color: ${({ colorPrimary }) => colorPrimary || '#fff'};
// `;

const SerialNumber = styled('span')`
  color: #ffffff77;
`;

const Separator = styled('span')`
  color: #ffffff77;
`;

interface AffinityProps {
  affinityColor?: string;
}

const Affinity = styled('span')<AffinityProps>`
  text-transform: uppercase;
  color: ${({ affinityColor }) => affinityColor || '#fff'};
`;

const Class = styled('span')`
  text-transform: uppercase;
  color: #ffffff77;
`;

export default {
  Root,
  //   RarityImg,
  Rarity,
  SerialNumber,
  Separator,
  Affinity,
  Class,
};
