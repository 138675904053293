import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useViewportContext from 'src/hooks/useViewportContext';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useDebouncedCallback } from 'use-debounce';
// import { logMain } from 'src/modules/logger/logger';

const getGuttersFromWindowSize = (vw: number, vh: number) => {
  const gutters = {
    x: 48,
    y: 80,
  };

  if (isMobile) {
    if (vw < 1000 || vh < 700) {
      gutters.x = -24;
      gutters.y = 24;
    } else {
      gutters.x = -24;
      gutters.y = 36;
    }
  } else if (vw < 1000 || vh < 700) {
    gutters.x = -24;
    gutters.y = 24;
  } else {
    // gutters.x = 24;
    // gutters.y = 48;
    gutters.x = -24;
    gutters.y = 36;
  }

  return gutters;
};

export const useGameOverlayResize = () => {
  const { viewportWidth, viewportHeight, landscape } = useViewportContext();
  const { enableGameOverlay, setDimensions } = useGameOverlayContext();
  const gutters = getGuttersFromWindowSize(viewportWidth, viewportHeight);

  const resizeOverlay = useDebouncedCallback(() => {
    // The maximum width the overlay page container can be
    const maxWidth = 90 * 16; // 80rem * 16px
    // The maximum height the overlay page container can be
    const maxHeight = 52 * 16; // 48rem * 16px
    // The amount of horizontal gutter space to leave between the game overlay page container and the edge of the screen
    const xGutter = gutters.x;
    // The amount of vertical gutter space to leave between the game overlay page container and the edge of the screen
    const yGutter = gutters.y;
    // The new width of the game overlay page container
    const newWidth = Math.min(viewportWidth - xGutter, maxWidth);
    // The new height of the game overlay page container
    const newHeight = Math.min(viewportHeight - yGutter, maxHeight);
    // The actual ratio of the game overlay container
    const realRatio = newWidth / newHeight;
    // The target ratio of the game overlay page container
    const targetRatio = 16 / 9;

    if (isMobile) {
      // If the actual ratio is greater than the target ratio, then the game overlay page container width should be the maximum width
      setDimensions({ width: `${newWidth}px`, height: `${newHeight}px` });
    } else if (realRatio > targetRatio) {
      // If the actual ratio is greater than the target ratio, then the game overlay page container width should be the maximum width
      setDimensions({ width: `${newWidth}px`, height: `${newHeight}px` });
    } else {
      // const adjustedHeight = newWidth / targetRatio;

      // // If the height of the game gradient container and utils bar together are too big, adjust the size
      // if (adjustedHeight > heightBoundary) {
      //   newHeight = heightBoundary;
      //   newWidth = newHeight * targetRatio;
      // }

      // // If the actual ratio is less than the target ratio, then the game overlay page container height should be the maximum height
      // gameOverlayPageContainerEl.style.width = `${newWidth}px`;
      // gameOverlayPageContainerEl.style.height = `${adjustedHeight}px`;

      // If the actual ratio is greater than the target ratio, then the game overlay page container width should be the maximum width
      setDimensions({ width: `${newWidth}px`, height: `${newHeight}px` });
    }
  }, 300);

  // Listen for window resize and adjust canvas container and canvas size accordingly
  useEffect(() => {
    resizeOverlay();
  }, [viewportWidth, viewportHeight, landscape, enableGameOverlay, gutters.x, gutters.y, setDimensions, resizeOverlay]);
};
