import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { nameSpace } from 'src/modules/i18n';
import { GTMEvents } from 'src/modules/gtm/events';
import BaseIcon from 'src/components/icons/BaseIcon';
import PGGIcon from 'src/assets/icons/pgg-icon-black.webp';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import TwitterSocialIcon from 'src/components/icons/TwitterSocialIcon';
import TikTokSocialIcon from 'src/components/icons/TikTokSocialIcon';
import { ReactComponent as ArrowIcon } from 'src/components/icons/iconic/custom-arrow.svg';
import { YouTube as YouTubeIcon, Instagram as InstagramIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import Styled from './SectionSocialPromo.styles';

const SectionSocialPromo: FC = () => {
  const { t } = useTranslation(nameSpace.common);
  const [inView, setInView] = useState(false);
  const navigateExternal = useAppNavigateExternal();

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.SocialSection id="section-social-promo">
        <Styled.SocialSectionInner className={`inview animated ${inView ? 'fadeIn' : ''}`}>
          <Styled.ArrowIcon icon={<ArrowIcon />} fill="#FEC300" />
          <Styled.ArrowIcon icon={<ArrowIcon />} fill="#FEC300" />
          <Styled.ArrowIcon icon={<ArrowIcon />} fill="#FEC300" direction="-1" />
          <Styled.ArrowIcon icon={<ArrowIcon />} fill="#FEC300" direction="-1" />

          <Styled.SocialText>
            <span>{t('common.section.social.promo.discord.title1')}</span>{' '}
            {t('common.section.social.promo.discord.title2')}
          </Styled.SocialText>

          <Styled.SocialIcons>
            <BaseIcon
              fill="#000"
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<DiscordSocialIcon />}
              height="30px"
              onClick={() => {
                navigateExternal(t('common.social.discord'), {
                  newWindow: true,
                  pooleData: { pageName: 'Discord', fromContext: 'socials_page_break' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnDiscord({ eventContext: 'home-social-section', eventIsOutboundLink: true }),
                });
              }}
            />
            <BaseIcon
              fill="#000"
              hoverColor="#FEC300"
              cursor="pointer"
              icon={<TwitterSocialIcon />}
              height="18px"
              onClick={() => {
                navigateExternal(t('common.social.twitter'), {
                  newWindow: true,
                  pooleData: { pageName: 'Twitter', fromContext: 'socials_page_break' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnTwitter({ eventContext: 'home-social-section', eventIsOutboundLink: true }),
                });
              }}
            />
            <BaseIcon
              fill="#000"
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<YouTubeIcon />}
              height="30px"
              onClick={() => {
                navigateExternal(t('common.social.youtube'), {
                  newWindow: true,
                  pooleData: { pageName: 'Youtube', fromContext: 'socials_page_break' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnYoutube({ eventContext: 'home-social-section', eventIsOutboundLink: true }),
                });
              }}
            />
            <BaseIcon
              fill="#000"
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<InstagramIcon />}
              height="25px"
              onClick={() => {
                navigateExternal(t('common.social.instagram'), {
                  newWindow: true,
                  pooleData: { pageName: 'Instagram', fromContext: 'socials_page_break' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnInstagram({ eventContext: 'home-social-section', eventIsOutboundLink: true }),
                });
              }}
            />
            <BaseIcon
              fill="#000"
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<TikTokSocialIcon />}
              height="25px"
              onClick={() => {
                navigateExternal(t('common.social.tiktok'), {
                  newWindow: true,
                  pooleData: { pageName: 'TikTok', fromContext: 'socials_page_break' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnTikTok({ eventContext: 'home-social-section', eventIsOutboundLink: true }),
                });
              }}
            />
            <BaseIcon
              fill="#000"
              cursor="pointer"
              hoverColor="#FEC300"
              icon={<FacebookIcon />}
              height="28px"
              onClick={() => {
                navigateExternal(t('common.social.facebook'), {
                  newWindow: true,
                  pooleData: { pageName: 'Facebook', fromContext: 'socials_page_break' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnFacebook({ eventContext: 'home-social-section', eventIsOutboundLink: true }),
                });
              }}
            />
            <img
              src={PGGIcon}
              width="100"
              height="100"
              alt="Polkastarter Icon"
              title="PolkaStarterIcon"
              onClick={() => {
                navigateExternal(t('common.social.polkastarter'), {
                  newWindow: true,
                  pooleData: { pageName: 'Polkastarter', fromContext: 'socials_page_break' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnPolkastarter({ eventContext: 'home-social-section', eventIsOutboundLink: true }),
                });
              }}
            />
          </Styled.SocialIcons>
        </Styled.SocialSectionInner>
      </Styled.SocialSection>
    </Waypoint>
  );
};

export default SectionSocialPromo;
