import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface RootProps {
  showDiscordWidget: boolean;
}

const Root = styled('div')<RootProps>`
  background-color: #ffffffcc;
  display: block;
  width: calc(100% - 40px);
  max-width: 24rem;
  /* padding: 0 0 0.5rem 0; */
  /* border: 4px solid ${({ theme }) => theme.custom.colors.Yellow}; */
  border-radius: 0.5rem;
  box-sizing: border-box;
  position: fixed;
  right: 20px;
  bottom: 96px;
  z-index: ${customThemeOptions.custom.zIndex.debugWidget.toggleButton};

  @media (max-width: 500px) {
    width: calc(100% - 4px);
    /* max-width: none; */
    /* border-radius: 0rem; */
    right: 2px;
    bottom: 68px;
  }
`;

const DebugTitle = styled('p')`
  display: block;
  padding: 0.8rem 1rem 0.6rem 1rem;
  /* border-bottom: 2px solid ${({ theme }) => theme.custom.colors.BgLayer3Accent}; */
  box-shadow: 0 2px 4px 0 #00000044;
  font-family: 'Kevlar Underwear';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: center;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
`;

const DebugActionButton = styled('button')`
  background-color: ${({ disabled }) => (disabled ? '#00000022' : '#ffffff00')};
  display: block;
  width: 100%;
  padding: 0.6rem 1rem 0.4rem 1rem;
  border: none;
  outline: none;
  font-family: 'Kevlar Underwear';
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  text-align: left;
  color: ${({ disabled }) => (disabled ? '#777777' : '#333333')};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #00000022;

    & > span:first-of-type {
      margin: ${({ disabled }) => (disabled ? '0 0.5rem 0.1rem 0' : '0 1rem 0.1rem 0')};
    }
  }

  &:active {
    background-color: ${({ disabled }) => (disabled ? '#00000022' : '#00000044')};
  }

  & > span {
    display: inline-block;
    vertical-align: middle;
  }

  & > span:first-of-type {
    margin: 0 0.5rem 0.1rem 0;
    transition: margin 0.2s ease-in-out;
  }

  & .material-icons {
    font-size: 1.2rem;
    color: ${({ disabled }) => (disabled ? '#777777' : '#333333')};
  }

  &:last-of-type {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
`;

export default {
  Root,
  DebugTitle,
  DebugActionButton,
};
