import { styled } from '@mui/material';

interface RootProps {
  variantStyles: {
    size: string;
    fontSize: string;
    letterSpacing: string;
  };
}

const Root = styled('div')<RootProps>`
  background-color: #000000;
  display: flex;
  width: ${({ variantStyles }) => variantStyles.size};
  height: ${({ variantStyles }) => variantStyles.size};
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-family: 'LHU Numerals';
  font-size: ${({ variantStyles }) => variantStyles.fontSize};
  font-weight: 400;
  line-height: ${({ variantStyles }) => variantStyles.fontSize};
  letter-spacing: ${({ variantStyles }) => variantStyles.letterSpacing};
  position: relative;

  & span {
    display: inline-block;
    margin-left: ${({ variantStyles }) => variantStyles.letterSpacing};
  }
`;

const CircleOuter = styled('div')`
  display: block;
  width: 135%;
  height: 135%;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #ffffff55;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CircleInner = styled('div')`
  display: block;
  width: 115%;
  height: 115%;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #ffffff55;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const DiamondOuter = styled('div')`
  display: block;
  width: 140%;
  height: 140%;
  box-sizing: border-box;
  border: 1px solid #ffffff55;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
`;

const DiamondInner = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #ffffff99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
`;

export default {
  Root,
  CircleOuter,
  CircleInner,
  DiamondOuter,
  DiamondInner,
};
