import { FC } from 'react';
import { useSwiper } from 'swiper/react';
import Styled from 'src/components/pages/event-pack/components/SwiperNavigation.styles';

interface SwiperNavigationProps {
  swiperIndex: number;
  numSlides: number;
}

const SwiperNavigation: FC<SwiperNavigationProps> = ({ swiperIndex, numSlides }) => {
  const swiper = useSwiper();

  if (numSlides <= 1) return null;

  return (
    <>
      <Styled.SwiperPrev disabled={swiperIndex === 0} onClick={() => swiper.slidePrev()}>
        <span className="material-icons-outlined">navigate_before</span>
      </Styled.SwiperPrev>

      <Styled.SwiperNext disabled={swiperIndex === numSlides - 1} onClick={() => swiper.slideNext()}>
        <span className="material-icons-outlined">navigate_next</span>
      </Styled.SwiperNext>
    </>
  );
};

export default SwiperNavigation;
