import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useCountdown } from 'src/hooks/useCountdown';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import Styled from 'src/components/primary-marketplace/components/Timer.styles';
// import { logMain } from 'src/modules/logger/logger';

interface TimerProps {
  dropEventId: string;
  lpmId: string;
}

const Timer: FC<TimerProps> = ({ dropEventId, lpmId }) => {
  const dropEvent = useAppSelector(state => state.dropEvents.dropEvents)[dropEventId];
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const { type: dropEventType } = dropEvent || {};
  const { repeatMs = 0, startTimestampMs = 0 } = listPriceMarket || {};
  const timeLeft = startTimestampMs + repeatMs;
  const { isLessThanDay, daysDD, hoursDD, minsDD, secsDD } = useCountdown(timeLeft);

  if (dropEventType !== 'daily') return null;

  return (
    <Styled.Root>
      <Styled.TimerTitle>Next Chest</Styled.TimerTitle>

      <Styled.TimerContainer>
        <span className="material-icons-outlined">hourglass_top</span>

        {!isLessThanDay && (
          <Styled.TimerGrouping>
            <Styled.TimerValue>{daysDD}</Styled.TimerValue>
            <Styled.TimerLabel>days</Styled.TimerLabel>
          </Styled.TimerGrouping>
        )}

        <Styled.TimerGrouping>
          <Styled.TimerValue>{hoursDD}</Styled.TimerValue>
          <Styled.TimerLabel>hours</Styled.TimerLabel>
        </Styled.TimerGrouping>

        <Styled.TimerGrouping>
          <Styled.TimerValue>{minsDD}</Styled.TimerValue>
          <Styled.TimerLabel>mins</Styled.TimerLabel>
        </Styled.TimerGrouping>

        {isLessThanDay && (
          <Styled.TimerGrouping>
            <Styled.TimerValue>{secsDD}</Styled.TimerValue>
            <Styled.TimerLabel>secs</Styled.TimerLabel>
          </Styled.TimerGrouping>
        )}
      </Styled.TimerContainer>
    </Styled.Root>
  );
};

export default Timer;
