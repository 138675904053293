import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  display: block;
  width: 100%;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 7px #00000055;
  cursor: pointer;

  &:hover {
    & > div:first-of-type > img:first-of-type {
      width: 103%;
      height: 103%;
    }
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  @media (max-width: 900px) {
    padding: 2rem 2rem;
  }

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem;
  }
`;

const ImageContainer = styled('div')`
  display: inline-block;
  width: 40%;
  vertical-align: top;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  position: relative;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    border-radius: 0.2rem;
  }
`;

const Image = styled('img')`
  display: block;
  width: 100%;
  height: 100%;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 1s ease-in-out, height 1s ease-in-out;
`;

const ContentContainer = styled('div')`
  display: inline-block;
  width: 60%;
  height: 100%;
  max-height: 100%;
  padding: 2rem;
  vertical-align: top;
  overflow: hidden;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    display: block;
    width: 100%;
    max-height: none;
    padding: 0;
    margin-top: 2rem;
  }

  @media (max-width: 500px) {
    margin-top: 1.5rem;
  }
`;

const Logo = styled('img')`
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: solid 3px ${({ theme }) => theme.custom.colors.BgLayer2};
`;

const Title = styled('p')`
  display: -webkit-box;
  width: 100%;
  overflow: hidden;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 1.9rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  @media (max-width: 768px) {
    font-size: 1.4rem;
    font-weight: 800;
    line-height: 1.7rem;
  }
`;

const Subtitle = styled('p')`
  display: -webkit-box;
  width: 100%;
  max-height: 2.6rem;
  margin-top: 0.7rem;
  overflow: hidden;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: #ffffff;

  & * {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.3rem;
  }
`;

const Date = styled('p')`
  display: block;
  width: 100%;
  margin-top: 0.7rem;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1rem;
  color: #ffffff77;
`;

export default {
  Root,
  ImageContainer,
  Image,
  Logo,
  ContentContainer,
  Title,
  Subtitle,
  Date,
};
