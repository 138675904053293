import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  box-sizing: border-box;
`;

// const ScrollContainer = styled('div')`
//   display: block;
//   width: 100%;
//   height: 100%;
//   overflow: auto;
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */

//   &::-webkit-scrollbar {
//     display: none;
//   }
// `;

export default {
  Root,
  // ScrollContainer
};
