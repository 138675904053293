import { createContext } from 'react';

export interface PopupCallbacks {
  onReturn: (paymentSuccessful: boolean) => void;
  onClose: () => void;
}

export interface IPaymentPopupContext {
  popup: Window | null;
  setPopup?: (popup: Window | null) => void;
  handleOpenPopup: (url: string, callbacks: PopupCallbacks) => void;
}

export const initialContext: IPaymentPopupContext = {
  popup: null,
  setPopup: () => undefined,
  handleOpenPopup: () => undefined,
};

const PaymentPopupContext = createContext<IPaymentPopupContext>(initialContext);

PaymentPopupContext.displayName = 'PaymentPopupContext';

export default PaymentPopupContext;
