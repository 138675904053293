import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getNonfungible } from 'src/api/nonfungibles/get-nonfungible';
import { logMain } from 'src/modules/logger/logger';
import { setNonfungibles } from 'src/redux/slices/nonfungibles/nonfungibles';
import usePendingPurchasesContext from './usePendingPurchasesContext';

interface UseLoadNonfungibleProps {
  seriesId: string;
  serialNumber: string;
  disablePendingPurchases?: boolean;
  redirectOnError?: boolean;
  fromContext: string;
}

export const useLoadNonfungible = ({
  seriesId,
  serialNumber,
  disablePendingPurchases = false,
  redirectOnError = false,
  fromContext,
}: UseLoadNonfungibleProps) => {
  const { setLoadPendingPurchases } = usePendingPurchasesContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();
  const dispatch = useAppDispatch();

  // Load Data
  useEffect(() => {
    logMain.debug(`Loading nonfungible:`);
    logMain.debug(`Series ID: ${seriesId}`);
    logMain.debug(`Serial Number: ${serialNumber}`);

    setLoading(true);

    dispatch(getNonfungible({ urlParams: { seriesId, serialNumber } }))
      .then(data => {
        if (data && Object.keys(data).length > 0) {
          dispatch(setNonfungibles([data]));
        }

        setLoading(false);
      })
      .catch(e => {
        logMain.debug(e);

        if (redirectOnError) {
          navigateInternal('/page-not-found', { pooleData: { fromContext } });
        }

        setError(t('common.error.message.1'));
        setLoading(false);
      });
  }, [dispatch, fromContext, navigateInternal, redirectOnError, serialNumber, seriesId, t]);

  // Get pending purchases for current user
  useEffect(() => {
    if (!disablePendingPurchases) {
      setLoadPendingPurchases(true);
    }
  }, [disablePendingPurchases, setLoadPendingPurchases]);

  return { loading, error };
};
