import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-block;
  width: 45%;
  margin: 0 auto;
  padding-bottom: 3rem;
  vertical-align: top;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    max-width: 30rem;
    padding-bottom: 0;
  }
`;

const PrimaryAssetContainer = styled('div')`
  background: linear-gradient(0deg, #ffffff1a 0%, #000000 80%);
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  display: block;
  width: 100%;
  aspect-ratio: 1;
  /* border: 1px solid cyan; */
  border-radius: 0.3rem;
  overflow: hidden;
  position: relative;
`;

const PrimaryImg = styled('img')`
  display: block;
  width: auto;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  object-fit: contain; // use cover to remove distortion
  position: relative;
`;

const PrimaryVideo = styled('video')`
  display: flex;
  width: auto;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  object-fit: contain; // use cover to remove distortion
  position: relative;
`;

const FullscreenButton = styled('div')`
  background-color: #000000;
  display: block;
  width: 2.3rem;
  height: 2.3rem;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #ffffff;

    & .material-icons-outlined {
      color: #000000;
    }
  }

  &:active {
    background-color: #ffffff77;
  }

  & .material-icons-outlined {
    font-size: 1.6rem;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    height: 2rem;
    width: 2rem;
    right: 0.7rem;
    bottom: 0.7rem;

    & .material-icons-outlined {
      font-size: 1.4rem;
    }
  }
`;

const CloseButton = styled('div')`
  background-color: #000000;
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.5rem;
  right: 2.7rem;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #ffffff;

    & .material-icons {
      color: #000000;
    }
  }

  &:active {
    background-color: #ffffff77;
  }

  & .material-icons {
    font-size: 1.6rem;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
    top: 1.4rem;
    right: 1.7rem;

    & .material-icons {
      font-size: 1.4rem;
    }
  }
`;

const SecondaryAssetsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 2rem;
  text-align: center;

  & > div:not(:first-of-type) {
    margin-left: 2rem;
  }
`;

interface SecondaryAssetContainerProps {
  active: boolean;
}

const SecondaryAssetContainer = styled('div')<SecondaryAssetContainerProps>`
  /* background: linear-gradient(0deg, #ffffff1a, #000000); */
  background-color: #ffffff22;
  display: inline-block;
  width: 30%;
  padding: 0.5rem;
  aspect-ratio: 1;
  box-sizing: border-box;
  /* border: ${({ active }) => (active ? `2px solid #00ffff` : '0 solid #00000000')};
  border-color: ${({ active }) => (active ? `#00ffff` : '#00000000')}; */
  border: ${({ active, theme }) => (active ? `1px solid ${theme.custom.colors.Yellow}` : '0 solid #00000000')};
  border-color: ${({ active, theme }) => (active ? `${theme.custom.colors.Yellow}` : '#00000000')};
  border-radius: 0.3rem;
  position: relative;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
`;

const SecondaryImg = styled('img')`
  display: block;
  height: 100%;
  width: auto;
  margin: 0 auto;
  object-fit: fill;
`;

interface SecondaryVideoOverlayProps {
  active: boolean;
}

const SecondaryVideoOverlay = styled('div')<SecondaryVideoOverlayProps>`
  background-color: #00000077;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  & .material-icons-outlined {
    font-size: 3rem;
    color: ${({ active, theme }) => (active ? theme.custom.colors.Yellow : '#ffffff')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.3s ease-in-out;

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }

    @media (max-width: 500px) {
      font-size: 2rem;
    }
  }
`;

export default {
  Root,
  PrimaryAssetContainer,
  PrimaryImg,
  PrimaryVideo,
  FullscreenButton,
  CloseButton,
  SecondaryAssetsContainer,
  SecondaryAssetContainer,
  SecondaryImg,
  SecondaryVideoOverlay,
};
