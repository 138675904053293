import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyLeaderboardItems } from 'src/redux/slices/leaderboard-items/helpers/copy-leaderboard-items';
import nestedSort from 'src/tools/nestedSort';

// Define the initial state using that type
const initialState: LeaderboardItemsState = {
  leaderboardItems: [],
};

const leaderboardItemsSlice = createSlice({
  name: 'leaderboardItems',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLeaderboardItems: {
      reducer(state, action: PayloadAction<LeaderboardItemsPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.leaderboardItems = copyLeaderboardItems(action.payload.leaderboardItems);
      },
      prepare(leaderboardItems: LeaderboardItem[]) {
        return {
          payload: {
            leaderboardItems,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    addLeaderboardItems: {
      reducer(state, action: PayloadAction<LeaderboardItemsPayload>) {
        const existingItems = state.leaderboardItems;
        const newItems = action.payload.leaderboardItems || [];
        const allItems = [...existingItems, ...newItems];

        // eslint-disable-next-line no-param-reassign
        state.leaderboardItems = copyLeaderboardItems(allItems);
      },
      prepare(leaderboardItems: LeaderboardItem[]) {
        return {
          payload: {
            leaderboardItems,
          },
        };
      },
    },
    clearLeaderboardItems: state => {
      // eslint-disable-next-line no-param-reassign
      state.leaderboardItems = initialState.leaderboardItems;
    },
  },
});

export const { setLeaderboardItems, addLeaderboardItems, clearLeaderboardItems } = leaderboardItemsSlice.actions;

// Other Selectors
export const getLeaderboardItemsByLeaderboardId = (state: RootState, leaderboardId: string): LeaderboardItem[] => {
  const { leaderboardItems } = state.leaderboardItems;
  const filteredLeaderboardItems = leaderboardItems.filter(item => item.leaderboardId === leaderboardId);
  return nestedSort(filteredLeaderboardItems, -1, 'score');
};

export default leaderboardItemsSlice.reducer;
