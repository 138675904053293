import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import useBlogContext from 'src/hooks/useBlogContext';
import PageError from 'src/components/feedback/PageError';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/blog-details/components/SectionDetails.styles';
import { useMediaQuery, useTheme } from '@mui/material';

interface BG {
  path: string;
}

const SectionDetails: FC = () => {
  const { blogPosts } = useBlogContext();
  const { blogTitle = '' } = useParams();
  const [inView, setInView] = useState(false);
  const navigateExternal = useAppNavigateExternal();
  const navigateInternal = useAppNavigateInternal();
  const { t } = useTranslation(nameSpace.blogDetails);
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.down(501));
  const decodedTitle = decodeURIComponent(blogTitle);
  const blogPost = blogPosts.find(post => post.title === decodedTitle);
  const blogIndex = blogPosts.findIndex(post => post.title === decodedTitle);
  const prevBlog = blogPosts[blogIndex - 1];
  const nextBlog = blogPosts[blogIndex + 1];
  const { contentRaw = '', categories = [], link = '' } = blogPost || {};
  const bgBottom: BG = t('blog.details.bkg.bottom', { returnObjects: true });

  if (!blogPost) return <PageError />;

  return (
    <Styled.Root>
      <Styled.BackgroundImage style={{ backgroundImage: `url(/assets/${bgBottom.path})` }}>
        <Styled.BackgroundImageGradient />
      </Styled.BackgroundImage>

      <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
        <Styled.CenteredContainer className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
          <Styled.Content
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(contentRaw, {
                ADD_TAGS: ['iframe'],
                ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
              }),
            }}
          />

          <Styled.Separator />

          {link && breakpoint && (
            <ButtonHex
              variant="gold"
              width={220}
              height={50}
              fontSize={12}
              enableDropShadow
              hoverEffect
              title={t('blog.details.read.medium')}
              textStyle={{ paddingTop: '1px' }}
              style={{ display: 'block', margin: '2rem auto 0 auto' }}
              onClick={() => null}
            />
          )}

          <Styled.TagsContainer>
            {categories.map((category: string, i: number) => (
              <Styled.Tag key={`tag-${i}`}>{category}</Styled.Tag>
            ))}
          </Styled.TagsContainer>

          {link && !breakpoint && (
            <ButtonHex
              variant="gold"
              width={160}
              height={50}
              fontSize={12}
              enableDropShadow
              hoverEffect
              title={t('blog.details.read.medium')}
              textStyle={{ paddingTop: '1px' }}
              style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: '2rem' }}
              onClick={() => {
                navigateExternal(link, {
                  newWindow: true,
                  pooleData: { pageName: 'medium_blog_post', fromContext: 'blog_post' },
                });
              }}
            />
          )}

          <Styled.ButtonsContainer>
            {blogIndex > 0 && (
              <ButtonHex
                variant="white"
                width={breakpoint ? 200 : 160}
                height={50}
                fontSize={12}
                enableDropShadow
                hoverEffect
                title={t('blog.details.button.prev')}
                textStyle={{ paddingTop: '1px' }}
                style={
                  breakpoint
                    ? { display: 'block', margin: '1rem auto 0 auto' }
                    : { display: 'inline-block', verticalAlign: 'middle' }
                }
                onClick={() => {
                  navigateInternal(`/blog/${encodeURIComponent(prevBlog.title)}`, {
                    pooleData: { fromContext: 'prev_blog_post_button' },
                    gtmEvent: null,
                  });
                }}
              />
            )}

            {blogIndex < blogPosts.length - 1 && (
              <ButtonHex
                variant="white"
                width={breakpoint ? 200 : 160}
                height={50}
                fontSize={12}
                enableDropShadow
                hoverEffect
                title={t('blog.details.button.next')}
                textStyle={{ paddingTop: '1px' }}
                style={
                  breakpoint
                    ? { display: 'block', margin: '1rem auto 0 auto' }
                    : { display: 'inline-block', verticalAlign: 'middle' }
                }
                onClick={() => {
                  navigateInternal(`/blog/${encodeURIComponent(nextBlog.title)}`, {
                    pooleData: { fromContext: 'next_blog_post_button' },
                    gtmEvent: null,
                  });
                }}
              />
            )}
          </Styled.ButtonsContainer>
        </Styled.CenteredContainer>
      </Waypoint>
    </Styled.Root>
  );
};

export default SectionDetails;
