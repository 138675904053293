import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useInterval } from 'src/hooks/useInterval';
import { getAsyncOperation } from 'src/api/async-operations/getAsyncOperation';
import { setSnackbar } from 'src/redux/slices/snackbar/snackbar';
import { removeListedCollectionItem } from 'src/redux/slices/collection-items/collection-items';
import { removeListings } from 'src/redux/slices/listings/listings';
import { removeListedNonfungible } from 'src/redux/slices/nonfungibles/nonfungibles';
import { deleteListing } from 'src/api/secondary-markets/delete-listing';
import ErrorMessage from 'src/components/feedback/ErrorMessage';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import {
  // CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { logMain } from 'src/modules/logger/logger';
import { config } from 'src/config/config';
import Styled from 'src/components/modals/DelistNFTModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface DelistNFTModalProps {
  nft: Nonfungible | Listing | CollectionItem;
  open: boolean;
  onClose: () => Promise<void>;
  style?: React.CSSProperties;
}

const DelistNFTModal: FC<DelistNFTModalProps> = ({ nft, open, onClose, style = {} }) => {
  const { enableGameOverlay } = useGameOverlayContext();
  const [step, setStep] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [polling, setPolling] = useState(false);
  const [asyncOperationId, setAsyncOperationId] = useState('');
  const [error, setError] = useState('');
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const dispatch = useAppDispatch();
  const navigateExternal = useAppNavigateExternal();
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const listingId = nft?.listingId || '';
  const nonfungibleId = nft?.id || '';

  const handleClose = () => {
    if (step === 1) {
      dispatch(removeListedCollectionItem(nft.id));
      dispatch(removeListings([nonfungibleId]));
      dispatch(removeListedNonfungible(nonfungibleId));
    }

    // Fire Poole event for remove listing close
    PooleEvents.RemoveListingClose({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'remove_listing_modal',
      nonfungibleId: nft?.id || '',
      listingId: nft?.listingId || '',
      priceLC: nft?.price || 0,
      priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    if (polling) {
      // Fire Poole event for secondary purchase polling start
      // PooleEvents.RemoveListingPollingAbort({
      //   playerId: currentProfile.id,
      //   from: window.location.pathname,
      //   fromContext: 'secondary_purchase_modal',
      //   nonfungibleId: nft?.id || '',
      //   listingId: nft?.listingId || '',
      //   priceLC: (nft?.price || 0).toString(),
      //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
      //   asyncOperationId,
      // });
    }

    onClose().then(() => {
      setPolling(false);
      setAsyncOperationId('');
      setProcessing(false);
      setStep(0);
      setError('');
    });
  };

  const handleSubmit = () => {
    setProcessing(true);

    // Fire Poole event for remove listing
    PooleEvents.RemoveListingConfirm({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'remove_listing_modal',
      nonfungibleId: nft?.id || '',
      listingId: nft?.listingId || '',
      priceLC: nft?.price || 0,
      priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    dispatch(deleteListing({ data: { listingId } }))
      .then(data => {
        // Fire Poole event for remove listing success
        PooleEvents.RemoveListingConfirmSuccess({
          dataset: enableGameOverlay ? 'unity' : 'website',
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'remove_listing_modal',
          nonfungibleId: nft?.id || '',
          listingId: nft?.listingId || '',
          priceLC: (nft?.price || 0).toString(),
          priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
        });

        if (data?.id) {
          setAsyncOperationId(data?.id);
          setPolling(true);
        } else {
          dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
          setError(t('common.error.message.1'));
        }

        setProcessing(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        logMain.debug(e);

        // Fire Poole event for remove listing fail
        PooleEvents.RemoveListingConfirmFail({
          dataset: enableGameOverlay ? 'unity' : 'website',
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'remove_listing_modal',
          nonfungibleId: nft?.id || '',
          listingId: nft?.listingId || '',
          priceLC: nft?.price || 0,
          priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
          reason: e instanceof Error ? e.message : String(e),
        });

        dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
        setError(t('common.error.message.1'));
        setProcessing(false);
        setPolling(false);
      });
  };

  // If user initiated creating a listing, poll the async op endpoint to recieve redirect URL
  useInterval(() => {
    if (polling && asyncOperationId) {
      // Fire Poole event for secondary purchase polling start
      // PooleEvents.CreateListingPollingStart({
      //   playerId: currentProfile.id,
      //   from: window.location.pathname,
      //   fromContext: 'secondary_purchase_modal',
      //   nonfungibleId: nft?.id || '',
      //   listingId: nft?.listingId || '',
      //   priceLC: (nft?.price || 0).toString(),
      //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
      //   asyncOperationId,
      // });

      dispatch(getAsyncOperation({ urlParams: { asyncOperationId } }))
        .then(data => {
          if (data?.status === 'pending') {
            logMain.debug('[DEBUG]: Operation pending');
            logMain.debug({ data });

            // Fire Poole event for secondary purchase polling pending
            // PooleEvents.RemoveListingPollingPending({
            //   playerId: currentProfile.id,
            //   from: window.location.pathname,
            //   fromContext: 'secondary_purchase_modal',
            //   nonfungibleId: nft?.id || '',
            //   listingId: nft?.listingId || '',
            //   priceLC: (nft?.price || 0).toString(),
            //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            //   asyncOperationId,
            // });
          } else if (data?.status === 'success') {
            logMain.debug('[DEBUG]: Operation succeeded');
            logMain.debug({ data, uri: data?.result?.redirectUri });

            // Fire Poole event for secondary purchase polling start
            // PooleEvents.RemoveListingPollingSuccess({
            //   playerId: currentProfile.id,
            //   from: window.location.pathname,
            //   fromContext: 'secondary_purchase_modal',
            //   nonfungibleId: nft?.id || '',
            //   listingId: nft?.listingId || '',
            //   priceLC: (nft?.price || 0).toString(),
            //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            //   asyncOperationId,
            // });

            // Redirect User if succcessful and there is redirectUrl
            if (data?.result?.redirectUri) {
              navigateExternal(data.result.redirectUri, {
                pooleData: { pageName: 'Forte Pay', fromContext: 'remove_listing' },
              });
            } else {
              dispatch(setSnackbar({ severity: 'success', msg: t('common.popup.listing.success') }));
              setError('');
              setPolling(false);
              setAsyncOperationId('');
              setStep(1);
            }
          } else if (data?.status === 'failure' || data?.error) {
            logMain.debug('[DEBUG]: Operation failed', data?.error);
            logMain.debug({ data });

            // Fire Poole event for secondary purchase polling start
            // PooleEvents.RemoveListingPollingFail({
            //   playerId: currentProfile.id,
            //   from: window.location.pathname,
            //   fromContext: 'secondary_purchase_modal',
            //   nonfungibleId: nft?.id || '',
            //   listingId: nft?.listingId || '',
            //   priceLC: (nft?.price || 0).toString(),
            //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            //   asyncOperationId,
            //   reason: data?.error.toString() || 'Error during secondary purchase polling.',
            // });

            dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
            setError(t('common.error.message.1'));
            setPolling(false);
            setAsyncOperationId('');
            setStep(0);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug(e);

          // Fire Poole event for secondary purchase polling start
          // PooleEvents.RemoveListingPollingFail({
          //   playerId: currentProfile.id,
          //   from: window.location.pathname,
          //   fromContext: 'secondary_purchase_modal',
          //   nonfungibleId: nft?.id || '',
          //   listingId: nft?.listingId || '',
          //   priceLC: (nft?.price || 0).toString(),
          //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
          //   asyncOperationId,
          //   reason: e instanceof Error ? e.message : String(e),
          // });

          dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
          setError(t('common.error.message.1'));
          setPolling(false);
          setAsyncOperationId('');
          setStep(0);
        });
    }
  }, config.poll_interval.nft_purchase);

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.delistNFT}` }}
    >
      <Fade in={open}>
        <Styled.Root style={style}>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            {step === 0 && <Styled.Heading>{t('common.popup.delisting.heading')}</Styled.Heading>}

            {step === 0 && <ErrorMessage msg={error} style={{ margin: '1.5rem 0 0 0' }} />}

            {step === 0 && (
              <Styled.SubheadingsContainer>
                <Styled.Subheading>{t('common.popup.delisting.step.0.subheading.1')}</Styled.Subheading>
              </Styled.SubheadingsContainer>
            )}

            {step === 1 && (
              <>
                <Styled.ConfirmationIconContainer>
                  <span className="material-icons-outlined">check_circle</span>
                </Styled.ConfirmationIconContainer>

                <Styled.ConfirmationTitle>{t('common.popup.delisting.step.1.title')}</Styled.ConfirmationTitle>
              </>
            )}

            {step === 0 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant={'white'}
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.cancel')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '0 auto 0 auto' : '0',
                  }}
                  disabled={processing || polling}
                  onClick={handleClose}
                />

                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.remove.listing')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  processing={processing || polling}
                  disabled={processing || polling}
                  onClick={handleSubmit}
                />
              </Styled.ButtonsContainer>
            )}

            {step === 1 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.confirm')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  onClick={handleClose}
                />
              </Styled.ButtonsContainer>
            )}
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default DelistNFTModal;
