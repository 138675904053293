import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  /* background-color: #770000ff; */
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: ${customThemeOptions.custom.zIndex.gameOverlay.basePageUnfocused};
`;

export default { Root };
