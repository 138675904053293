import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  padding: 0 0 6rem 7rem;
  box-sizing: border-box;
  /* border: 1px solid #000000; */
  position: relative;

  @media (max-width: 600px) {
    padding: 0 0 6rem 4.9rem;
  }
`;

const StepIcon = styled('img')`
  display: block;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 600px) {
    width: 3.6rem;
    height: 3.6rem;
  }
`;

const Line = styled('div')`
  background-color: #000000;
  display: inline-block;
  width: 2px;
  position: absolute;
  top: 7rem;
  bottom: 0;
  left: 2.5rem;

  @media (max-width: 600px) {
    top: 5.6rem;
    left: 1.8rem;
  }
`;

const ContentContainer = styled('div')`
  display: inline-block;
  width: calc(100% - 2rem - 32rem);
  padding-top: 1.5rem;
  margin-right: 2rem;
  vertical-align: top;

  @media (max-width: 1000px) {
    display: block;
    width: 100%;
    padding-top: 0.7rem;
    margin-right: 0;
  }
`;

const ContentTitle = styled('p')`
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2rem;
  color: #000000;
`;

const ContentDesc = styled('p')`
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: #000000;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  &:first-of-type {
    margin-top: 2rem;
  }
`;

const ContentWarning = styled('p')`
  background-color: #000000;
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  border-radius: 0.3rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  @media (max-width: 500px) {
    padding: 1rem 1rem;
  }
`;

const MediaContainer = styled('div')`
  display: inline-block;
  width: 32rem;
  padding-top: 1.5rem;
  vertical-align: top;

  @media (max-width: 1000px) {
    display: block;
    width: 100%;
    max-width: 36rem;
    padding-top: 0.7rem;
    margin-top: 2rem;
  }

  @media (max-width: 600px) {
    margin-top: 2rem;
  }
`;

const Video = styled('video')`
  background-color: #777777;
  display: block;
  width: 100%;
  height: auto;
  /* margin: 0 auto; */
  /* object-fit: fit; // use cover to remove distortion */
  border-radius: 0.5rem;
  box-shadow: 0 0 15px #00000099;
`;

// const Media = styled('img')`
//   background-color: #777777;
//   display: block;
//   width: 100%;
//   height: auto;
//   border-radius: 0.5rem;
//   box-shadow: 0 0 15px #00000099;
//   /* border: 3px solid ${({ theme }) => theme.custom.colors.Yellow}; */
// `;

export default {
  Root,
  StepIcon,
  Line,
  ContentContainer,
  ContentTitle,
  ContentDesc,
  ContentWarning,
  MediaContainer,
  Video,
  // Media,
};
