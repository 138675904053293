/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import { logMain } from 'src/modules/logger/logger';
// import { logMain } from 'src/modules/logger/logger';

export const useDocumentVisibilityChange = () => {
  const hideTimestampMs = useRef<number | null>(null);
  const timeLimitMins = 5;
  const timeLimitMs = 1000 * 60 * timeLimitMins; // 5 minutes

  useEffect(() => {
    const handleVisibilityChange = () => {
      logMain.debug('[DOCUMENT_VISIBILITY_LISTENER]: Document visibility changed', { hidden: document.hidden });

      if (document.hidden) {
        hideTimestampMs.current = Date.now();
      } else if (hideTimestampMs.current !== null) {
        const timeDiffMs = Date.now() - hideTimestampMs.current;

        if (timeDiffMs >= timeLimitMs) {
          logMain.debug(
            `[DOCUMENT_VISIBILITY_LISTENER]: Document was in background for more than ${timeLimitMins}min. Reloading...`,
          );

          window.location.reload();
        } else {
          logMain.debug(
            `[DOCUMENT_VISIBILITY_LISTENER]: Document was in background for less than ${timeLimitMins}min. Returning to normal state...`,
          );

          hideTimestampMs.current = null;
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [timeLimitMs]);

  return useDocumentVisibilityChange;
};
