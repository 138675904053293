import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { GTMEvents } from 'src/modules/gtm/events';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import BaseIcon from 'src/components/icons/BaseIcon';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import TwitterSocialIcon from 'src/components/icons/TwitterSocialIcon';
import TikTokSocialIcon from 'src/components/icons/TikTokSocialIcon';
import Menu from '@mui/material/Menu';
import { YouTube as YouTubeIcon, Instagram as InstagramIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import Styled from 'src/components/pages/marketplace/components/ActionsBar.styles';

interface ActionsBarProps {
  className?: string;
}

const ActionsBar: FC<ActionsBarProps> = ({ className = '' }) => {
  const { t } = useTranslation(nameSpace.common);
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const shareMenuOpen = Boolean(shareAnchorEl);
  const navigateExternal = useAppNavigateExternal();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setShareAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setShareAnchorEl(null);
  };

  const handleCopyURL = () => {
    const url = location.href;
    navigator.clipboard.writeText(url);

    handleClose();
  };

  return (
    <Styled.Root className={className}>
      <Styled.SocialLinks>
        <BaseIcon
          cursor="pointer"
          hoverColor="#FEC300"
          icon={<DiscordSocialIcon />}
          height="22px"
          style={{ display: 'inline-block', height: '22px', verticalAlign: 'middle' }}
          onClick={() => {
            navigateExternal(t('common.social.discord'), {
              newWindow: true,
              pooleData: { pageName: 'Discord', fromContext: 'marketplace' },
              gtmEvent: () => GTMEvents.clickBtnDiscord({ eventContext: 'marketplace', eventIsOutboundLink: true }),
            });
          }}
        />
        <BaseIcon
          cursor="pointer"
          hoverColor="#FEC300"
          icon={<TwitterSocialIcon />}
          height="14px"
          style={{ display: 'inline-block', height: '14px', verticalAlign: 'middle' }}
          onClick={() => {
            navigateExternal(t('common.social.twitter'), {
              newWindow: true,
              pooleData: { pageName: 'Twitter', fromContext: 'marketplace' },
              gtmEvent: () => GTMEvents.clickBtnTwitter({ eventContext: 'marketplace', eventIsOutboundLink: true }),
            });
          }}
        />
        <BaseIcon
          cursor="pointer"
          hoverColor="#FEC300"
          icon={<YouTubeIcon />}
          height="22px"
          style={{ display: 'inline-block', height: '22px', verticalAlign: 'middle' }}
          onClick={() => {
            navigateExternal(t('common.social.youtube'), {
              newWindow: true,
              pooleData: { pageName: 'Youtube', fromContext: 'marketplace' },
              gtmEvent: () => GTMEvents.clickBtnYoutube({ eventContext: 'marketplace', eventIsOutboundLink: true }),
            });
          }}
        />
        <BaseIcon
          cursor="pointer"
          hoverColor="#FEC300"
          icon={<InstagramIcon />}
          height="18px"
          style={{ display: 'inline-block', height: '18px', verticalAlign: 'middle' }}
          onClick={() => {
            navigateExternal(t('common.social.instagram'), {
              newWindow: true,
              pooleData: { pageName: 'Instagram', fromContext: 'marketplace' },
              gtmEvent: () => GTMEvents.clickBtnInstagram({ eventContext: 'marketplace', eventIsOutboundLink: true }),
            });
          }}
        />
        <BaseIcon
          cursor="pointer"
          hoverColor="#FEC300"
          icon={<TikTokSocialIcon />}
          height="18px"
          style={{ display: 'inline-block', height: '18px', verticalAlign: 'middle' }}
          onClick={() => {
            navigateExternal(t('common.social.tiktok'), {
              newWindow: true,
              pooleData: { pageName: 'TikTok', fromContext: 'marketplace' },
              gtmEvent: () => GTMEvents.clickBtnTikTok({ eventContext: 'marketplace', eventIsOutboundLink: true }),
            });
          }}
        />
        <BaseIcon
          cursor="pointer"
          hoverColor="#FEC300"
          icon={<FacebookIcon />}
          height="20px"
          style={{ display: 'inline-block', height: '20px', verticalAlign: 'middle' }}
          onClick={() => {
            navigateExternal(t('common.social.facebook'), {
              newWindow: true,
              pooleData: { pageName: 'Facebook', fromContext: 'marketplace' },
              gtmEvent: () => GTMEvents.clickBtnFacebook({ eventContext: 'marketplace', eventIsOutboundLink: true }),
            });
          }}
        />
      </Styled.SocialLinks>

      <Styled.Separator />

      <Styled.DropdownsButtons>
        <span
          className="material-icons"
          id="share-menu-button"
          aria-controls={shareMenuOpen ? 'share-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={shareMenuOpen ? 'true' : undefined}
          onClick={handleClick}
        >
          share
        </span>

        <Menu
          id="share-menu"
          anchorEl={shareAnchorEl}
          open={shareMenuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'share-menu-button',
          }}
        >
          <Styled.MenuItem onClick={handleCopyURL}>
            <span className="material-icons">content_copy</span>
            <Styled.MenuItemText>{t('common.menu.button.copy.link')}</Styled.MenuItemText>
          </Styled.MenuItem>
        </Menu>
      </Styled.DropdownsButtons>
    </Styled.Root>
  );
};

export default ActionsBar;
