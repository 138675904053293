import { styled } from '@mui/material';

interface RootProps {
  disabled: boolean;
  buttonWidth: number;
  buttonHeight: number;
  position: 'top' | 'bottom';
}

const Root = styled('div')<RootProps>`
  display: flex;
  height: ${({ buttonHeight }) => `${buttonHeight}px`};
  width: ${({ buttonWidth }) => `${buttonWidth}px`};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: ${({ position }) => (position === 'top' ? '0' : 'auto')};
  bottom: ${({ position }) => (position === 'bottom' ? '0' : 'auto')};
  left: 50%;
  transform: translateX(-50%);
  filter: ${({ disabled }) => (!disabled ? 'none' : 'grayscale(60%)')};
  transition: filter 0.3s ease-in-out;
  pointer-events: ${({ disabled }) => (!disabled ? 'auto' : 'none')};
`;

interface ButtonProps {
  disabled: boolean;
}

const Button = styled('div')<ButtonProps>`
  background: linear-gradient(0deg, #2f4776, #1a2a49);
  display: inline-flex;
  height: calc(100% - 0.8rem);
  width: calc(100% - 0.8rem);
  padding: 0 0.5rem;
  justify-content: center;
  align-items: center;
  border: 2px solid #2f4776;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  pointer-events: ${({ disabled }) => (!disabled ? 'auto' : 'none')};

  &:hover {
    background: linear-gradient(0deg, #3e5b94, #263e6b);
    border: 2px solid #437cbe;
  }

  &:active {
    background: linear-gradient(0deg, #2f4776, #1a2a49);
    border: 2px solid #1d4676;
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin-top: 1px;
    /* margin-right: 0.2rem; */
    font-size: 1rem;
    font-weight: 700;
    transition: color 0.3s ease-in-out;
    color: ${({ disabled }) => (!disabled ? '#ffffff' : '#999999')};
  }

  @media (max-width: 900px) or (max-height: 500px) {
    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.8rem;
    }
  }
`;

export default { Root, Button };
