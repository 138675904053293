import { styled } from '@mui/material';

const Root = styled('div')<{ show: number }>`
  filter: ${({ show = false }) => (show ? 'blur(12px)' : 'none')};
  transition: filter 300ms;
  z-index: ${({ show = false }) => (show ? -1 : 'initial')};
  height: 100%;
  padding: 0 0 0;
`;

export default { Root };
