/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

interface UseElementOnCLick {
  ids: string[];
  onClick: () => void;
}

const useElementsOnClick = ({ ids, onClick }: UseElementOnCLick) => {
  useEffect(() => {
    const handleClick = (e: any) => {
      if (e) {
        if (ids.indexOf(e.target?.id) !== -1) {
          onClick();
        }
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [ids, onClick]);
};

export default useElementsOnClick;
