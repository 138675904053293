import { FC, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { getFeaturedDropEvent } from 'src/redux/slices/drop-events/drop-events';
import ButtonDiscordJoin from 'src/components/input/ButtonDiscordJoin';
import PolygonContainer from 'src/components/layout/PolygonContainer';
import CheckboxDiamond from 'src/components/input/CheckboxDiamond';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/events/components/SectionUpcomingEvent.styles';
import { config } from 'src/config/config';

interface Asset {
  title: string;
  path: string;
}

const SectionUpcomingEvents: FC = () => {
  const authenticated = useAppSelector(state => state.authenticated.authenticated);
  const currentProfile = useAppSelector(state => state.currentProfile.currentProfile);
  const featuredDropEvent = useAppSelector(state => getFeaturedDropEvent(state));
  const [inView1, setInView1] = useState(false);
  const [inView2, setInView2] = useState(false);
  const { t: tEvents } = useTranslation(nameSpace.events);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();
  const navigateExternal = useAppNavigateExternal();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const hero: Asset = tEvents('events.upcoming.content.hero.asset', { returnObjects: true });
  const headlineImg: Asset = tEvents('events.upcoming.content.headline.image', { returnObjects: true });
  const featuredDropEventId = featuredDropEvent ? featuredDropEvent.id : '';
  const featuredDropEventType = featuredDropEvent ? featuredDropEvent.type : '';

  useEffect(() => {
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      // set all elements owning target to target=_blank
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    });
  }, []);

  const handleGoToEvent = (path: string) => {
    if (config.app_variables.force_in_game_purchases_only) {
      navigateInternal('/play', { pooleData: { fromContext: 'events' } });
    } else {
      navigateInternal(path, { pooleData: { fromContext: 'events' } });
    }
  };

  return (
    <Styled.Root>
      <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
        <div className={`inview animated ${inView1 ? 'fadeInUp' : ''}`}>
          <Styled.HeaderContainer>
            <Styled.Heading>{tEvents('events.upcoming.heading')}</Styled.Heading>
            <Styled.Subheading>{tEvents('events.upcoming.subheading')}</Styled.Subheading>

            <ButtonDiscordJoin
              variant={'gold'}
              onClick={() => {
                navigateExternal(tCommon('common.social.discord'), {
                  newWindow: true,
                  pooleData: { pageName: 'Discord', fromContext: 'events' },
                });
              }}
              style={{ margin: '2rem auto 0 auto' }}
            />

            <Styled.EmailContainer>
              <Styled.EmailHeading>{tEvents('events.upcoming.email.heading')}</Styled.EmailHeading>
              <Styled.EmailSubheading>{tEvents('events.upcoming.email.subheading')}</Styled.EmailSubheading>
            </Styled.EmailContainer>
          </Styled.HeaderContainer>
        </div>
      </Waypoint>

      <Waypoint onEnter={() => setInView2(true)} bottomOffset="20px">
        <div className={`inview animated ${inView2 ? 'fadeInUp' : ''}`}>
          <Styled.ContentOuterContainer>
            <Styled.ContentInnerContainer>
              <PolygonContainer strokeWidth={2} glowRadius={6} scaleY={mobileView ? 3 : 1.5}>
                <Styled.DetailsContainer>
                  <Styled.Detail>
                    <Styled.DetailTitle>{tEvents('events.upcoming.content.detail1.title')}</Styled.DetailTitle>
                    <Styled.DetailBody>{tEvents('events.upcoming.content.detail1.body1')}</Styled.DetailBody>

                    <Styled.RequirementsContainer>
                      <Styled.Requirement>
                        <CheckboxDiamond
                          defaultValue={authenticated}
                          clickable={false}
                          size={12}
                          style={{ display: 'block', float: 'left', margin: '4px 16px 0 2px' }}
                        />
                        <Styled.RequirementText>
                          {tCommon('common.drop.event.requirements.req1')}
                        </Styled.RequirementText>
                      </Styled.Requirement>

                      <Styled.Requirement>
                        <CheckboxDiamond
                          defaultValue={authenticated && currentProfile.discordUser !== null}
                          clickable={false}
                          size={12}
                          style={{ display: 'block', float: 'left', margin: '4px 16px 0 2px' }}
                        />
                        <Styled.RequirementText>
                          {tCommon('common.drop.event.requirements.req2')}
                        </Styled.RequirementText>
                      </Styled.Requirement>

                      <Styled.Requirement>
                        <CheckboxDiamond
                          defaultValue={
                            featuredDropEvent?.allowedViaAllowListIds &&
                            featuredDropEvent?.allowedViaAllowListIds.length > 0
                          }
                          clickable={false}
                          size={12}
                          style={{ display: 'block', float: 'left', margin: '4px 16px 0 2px' }}
                        />
                        <Styled.RequirementText>
                          {tCommon('common.drop.event.requirements.req3')}
                        </Styled.RequirementText>
                      </Styled.Requirement>
                    </Styled.RequirementsContainer>
                  </Styled.Detail>

                  <Styled.Detail>
                    <Styled.DetailTitle>{tEvents('events.upcoming.content.detail2.title')}</Styled.DetailTitle>

                    <Styled.DetailBody>{tEvents('events.upcoming.content.detail2.body1')}</Styled.DetailBody>

                    <Styled.RequirementsContainer>
                      <Styled.Requirement>
                        <CheckboxDiamond
                          defaultValue={authenticated}
                          clickable={false}
                          size={12}
                          style={{ display: 'block', float: 'left', margin: '4px 16px 0 2px' }}
                        />
                        <Styled.RequirementText>
                          {tCommon('common.drop.event.requirements.req1')}
                        </Styled.RequirementText>
                      </Styled.Requirement>
                    </Styled.RequirementsContainer>
                  </Styled.Detail>

                  <Styled.Detail style={{ maxWidth: '26rem' }}>
                    <Styled.DetailTitle>{tEvents('events.upcoming.content.detail3.title')}</Styled.DetailTitle>
                    <Styled.DetailBody>{tEvents('events.upcoming.content.detail3.body1')}</Styled.DetailBody>

                    <Styled.KeyDatesContainer>
                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.1.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{tCommon('common.key.dates.founders.1.value')}</Styled.KeyDateValue>
                      </Styled.KeyDate>

                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.2.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{`${tCommon('common.key.dates.founders.2.value')}`}</Styled.KeyDateValue>
                      </Styled.KeyDate>

                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.3.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{`${tCommon('common.key.dates.founders.3.value')}`}</Styled.KeyDateValue>
                      </Styled.KeyDate>

                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.4.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{`${tCommon('common.key.dates.founders.4.value')}`}</Styled.KeyDateValue>
                      </Styled.KeyDate>

                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.5.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{`${tCommon('common.key.dates.founders.5.value')}`}</Styled.KeyDateValue>
                      </Styled.KeyDate>

                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.6.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{`${tCommon('common.key.dates.founders.6.value')}`}</Styled.KeyDateValue>
                      </Styled.KeyDate>

                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.7.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{`${tCommon('common.key.dates.founders.7.value')}`}</Styled.KeyDateValue>
                      </Styled.KeyDate>

                      <Styled.KeyDate>
                        <Styled.KeyDateLabel>{tCommon('common.key.dates.founders.8.label')}</Styled.KeyDateLabel>
                        <Styled.KeyDateValue>{`${tCommon('common.key.dates.founders.8.value')}`}</Styled.KeyDateValue>
                      </Styled.KeyDate>
                    </Styled.KeyDatesContainer>
                  </Styled.Detail>

                  <Styled.HeadlineContainer>
                    <Styled.HeadlineTitle>{tEvents('events.upcoming.content.headline.title')}</Styled.HeadlineTitle>
                    <Styled.HeadlineSubtitle
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(tEvents('events.upcoming.content.headline.subtitle')),
                      }}
                    />

                    <Styled.HeadlineImageContainer>
                      <Styled.HeadlineImage
                        src={`/assets/${headlineImg.path}`}
                        width="1200"
                        height="627"
                        loading="lazy"
                        title={headlineImg.title}
                      />

                      <ButtonHex
                        variant="gold"
                        width={mobileView ? 110 : 130}
                        height={mobileView ? 36 : 46}
                        cornerOffset={14}
                        fontSize={mobileView ? 12 : 14}
                        hoverEffect
                        title={tEvents('events.upcoming.content.headline.button')}
                        textStyle={mobileView ? { fontSize: '12px' } : {}}
                        style={{ position: 'absolute', bottom: '0', left: '50%', transform: 'translate(-50%, 0)' }}
                        onClick={() => handleGoToEvent(`/event/${featuredDropEventType}/${featuredDropEventId}`)}
                      />
                    </Styled.HeadlineImageContainer>
                  </Styled.HeadlineContainer>
                </Styled.DetailsContainer>
              </PolygonContainer>
            </Styled.ContentInnerContainer>

            <Styled.HeroImageOverlay src={`/assets/${hero.path}`} width="1920" height="1692" title={hero.title} />
          </Styled.ContentOuterContainer>
        </div>
      </Waypoint>
    </Styled.Root>
  );
};

export default SectionUpcomingEvents;
