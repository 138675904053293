import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  background-color: #00000077;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${customThemeOptions.custom.zIndex.tutorial.overlay};
  cursor: pointer;
`;

interface VideoProps {
  right: string;
}

const Video = styled('video')<VideoProps>`
  display: block;
  width: auto;
  height: 105%;
  margin: 0 auto;
  object-fit: cover; // use cover to remove distortion
  position: absolute;
  bottom: -4rem;
  /* right: -100rem; */
  right: ${({ right }) => right};
  pointer-events: none;

  @media (max-height: 600px) {
    height: 120%;
  }

  @media (max-height: 400px) {
    height: 130%;
  }
`;

export default { Root, Video };
