import { useContext } from 'react';
import BlogContext from 'src/context/blog/Blog.context';

const useBlogContext = () => {
  const blogContext = useContext(BlogContext);

  return blogContext;
};

export default useBlogContext;
