import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.Yellow};
  display: block;
  width: 100%;
  height: 2.5rem;
`;

const CenteredContainer = styled('div')`
  display: flex;
  width: 100%;
  max-width: 55rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    padding: 0 1.5rem;
    justify-content: center;
  }
`;

const Title = styled('span')`
  display: inline-block;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  color: #000000;

  @media (max-width: 500px) {
    display: none;
  }
`;

export default {
  Root,
  CenteredContainer,
  Title,
};
