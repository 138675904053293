import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useQuery } from 'src/hooks/useQuery';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { GTMEvents } from 'src/modules/gtm/events';
import LogoSrc from 'src/assets/logo/lhu_logo.webp';
import BaseIcon from 'src/components/icons/BaseIcon';
import PGGIcon from 'src/assets/icons/pgg-icon-white.webp';
import { ReactComponent as MenuIcon } from 'src/components/icons/iconic/menu.svg';
import { ReactComponent as CloseIcon } from 'src/components/icons/iconic/close.svg';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import TwitterSocialIcon from 'src/components/icons/TwitterSocialIcon';
import TikTokSocialIcon from 'src/components/icons/TikTokSocialIcon';
import { YouTube as YouTubeIcon, Instagram as InstagramIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import Styled from 'src/components/header/MenuLeftMobile.styles';

const MenuLeftMobile: FC = () => {
  const [open, setOpen] = useState(false);
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t } = useTranslation(nameSpace.common);
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const navigateInternal = useAppNavigateInternal();
  const navigateExternal = useAppNavigateExternal();
  const leaderboardsEnabled = currentProfile?.features?.leaderboards?.enabled;

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleOnGoTo = (path: string, gtmEvent?: () => void) => {
    setOpen(false);
    navigateInternal(path, { pooleData: { fromContext: 'header_navigation' }, gtmEvent: gtmEvent || null });
  };

  const checkLinkIsActive = ({ path = '', hash = '' }: { path: string; hash?: string }): boolean => {
    if (hash) {
      if (location.pathname.toLowerCase().indexOf(path) !== -1) {
        if (location.hash.toLowerCase().indexOf(hash) !== -1) {
          return true;
        }
      }
    } else if (location.pathname.toLowerCase().indexOf(path) !== -1) {
      if (!location.hash) {
        return true;
      }
    }

    return false;
  };

  return (
    <Styled.Root>
      <BaseIcon
        cursor="pointer"
        hoverColor="#FEC300"
        icon={<MenuIcon />}
        opacity={1}
        height={36}
        margin="0"
        onClick={handleToggleMenu}
      />

      <Styled.SideMenu open={open}>
        <BaseIcon
          cursor="pointer"
          hoverColor="#FEC300"
          icon={<CloseIcon />}
          opacity={1}
          height={35}
          margin="-10px 0 0 -10px"
          onClick={handleToggleMenu}
        />

        <Styled.Logo src={LogoSrc} width="406" height="213" loading="lazy" alt={t('common.project.name')} />

        <Styled.Links>
          <Styled.Col>
            <Styled.Link
              active={checkLinkIsActive({ path: 'home' })}
              onClick={() => handleOnGoTo(trim === '1' ? '/home?trim=1' : '/home')}
            >
              {t('common.nav.home')}
            </Styled.Link>

            <Styled.Link
              active={checkLinkIsActive({ path: 'events' })}
              onClick={() => handleOnGoTo(trim === '1' ? '/events?trim=1' : '/events')}
            >
              {t('common.nav.events')}
            </Styled.Link>

            {authenticated && (
              <Styled.Link
                active={checkLinkIsActive({ path: 'marketplace' })}
                onClick={() => handleOnGoTo('/marketplace')}
              >
                {t('common.nav.marketplace')}
              </Styled.Link>
            )}

            {authenticated && leaderboardsEnabled && (
              <Styled.Link
                active={checkLinkIsActive({ path: 'leaderboards' })}
                onClick={() => handleOnGoTo('/leaderboards')}
              >
                {t('common.nav.leaderboards')}
              </Styled.Link>
            )}
          </Styled.Col>

          <Styled.Col>
            <Styled.Link
              active={checkLinkIsActive({ path: 'getting-started' })}
              onClick={() => handleOnGoTo('/getting-started')}
            >
              {t('common.nav.getting.started')}
            </Styled.Link>

            <Styled.Link
              active={checkLinkIsActive({ path: 'home', hash: 'roadmap' })}
              onClick={() => {
                handleOnGoTo(trim === '1' ? '/home?trim=1#roadmap' : '/home#roadmap', () =>
                  GTMEvents.clickBtnRoadmap({ eventContext: 'header_navigation' }),
                );
              }}
            >
              {t('common.nav.roadmap')}
            </Styled.Link>

            {/* <Styled.Link
              active={false}
              onClick={() => {
                navigateExternal(t('common.nav.whitepaper.link'), {
                  newWindow: true,
                  pooleData: { pageName: 'Whitepaper', fromContext: 'header_navigation' },
                  gtmEvent: () =>
                    GTMEvents.clickBtnWhitepaper({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
                });
              }}
            >
              {t('common.nav.whitepaper')}
            </Styled.Link> */}

            <Styled.Link active={checkLinkIsActive({ path: 'blog' })} onClick={() => handleOnGoTo('/blog')}>
              {t('common.nav.blog')}
            </Styled.Link>

            <Styled.Link
              active={checkLinkIsActive({ path: 'home', hash: 'faqs' })}
              onClick={() => {
                handleOnGoTo(trim === '1' ? '/home?trim=1#faqs' : '/home#faqs', () =>
                  GTMEvents.clickBtnFAQs({ eventContext: 'header_navigation' }),
                );
              }}
            >
              {t('common.nav.faqs')}
            </Styled.Link>
          </Styled.Col>
        </Styled.Links>

        <Styled.Socials>
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<DiscordSocialIcon />}
            height="30px"
            onClick={() => {
              navigateExternal(t('common.social.discord'), {
                newWindow: true,
                pooleData: { pageName: 'Discord', fromContext: 'header_navigation' },
                gtmEvent: () =>
                  GTMEvents.clickBtnDiscord({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            hoverColor="#FEC300"
            cursor="pointer"
            icon={<TwitterSocialIcon />}
            height="18px"
            onClick={() => {
              navigateExternal(t('common.social.twitter'), {
                newWindow: true,
                pooleData: { pageName: 'Twitter', fromContext: 'header_navigation' },
                gtmEvent: () =>
                  GTMEvents.clickBtnTwitter({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<YouTubeIcon />}
            height="30px"
            onClick={() => {
              navigateExternal(t('common.social.youtube'), {
                newWindow: true,
                pooleData: { pageName: 'Youtube', fromContext: 'header_navigation' },
                gtmEvent: () =>
                  GTMEvents.clickBtnYoutube({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<InstagramIcon />}
            height="25px"
            onClick={() => {
              navigateExternal(t('common.social.instagram'), {
                newWindow: true,
                pooleData: { pageName: 'Instagram', fromContext: 'header_navigation' },
                gtmEvent: () =>
                  GTMEvents.clickBtnInstagram({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<TikTokSocialIcon />}
            height="25px"
            onClick={() => {
              navigateExternal(t('common.social.tiktok'), {
                newWindow: true,
                pooleData: { pageName: 'TikTok', fromContext: 'header_navigation' },
                gtmEvent: () =>
                  GTMEvents.clickBtnTikTok({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<FacebookIcon />}
            height="28px"
            onClick={() => {
              navigateExternal(t('common.social.facebook'), {
                newWindow: true,
                pooleData: { pageName: 'Facebook', fromContext: 'header_navigation' },
                gtmEvent: () =>
                  GTMEvents.clickBtnFacebook({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
              });
            }}
          />
          <img
            src={PGGIcon}
            width="100"
            height="100"
            alt="Polkastarter Icon"
            title="PolkaStarterIcon"
            onClick={() => {
              navigateExternal(t('common.social.polkastarter'), {
                newWindow: true,
                pooleData: { pageName: 'Polkastarter', fromContext: 'header_navigation' },
                gtmEvent: () =>
                  GTMEvents.clickBtnPolkastarter({ eventContext: 'header_navigation', eventIsOutboundLink: true }),
              });
            }}
          />
        </Styled.Socials>
      </Styled.SideMenu>
    </Styled.Root>
  );
};

export default MenuLeftMobile;
