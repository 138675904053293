import { CSSProperties, FC } from 'react';
import { InputBaseProps } from '@mui/material/InputBase/InputBase';
import * as Styled from './Input.styles';

interface IInputProps extends InputBaseProps {
  id: string;
  height?: CSSProperties['height'];
}

const Input: FC<IInputProps> = ({ id, ...props }) => {
  return <Styled.Input id={id} {...props} />;
};

export default Input;
