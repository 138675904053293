import { FC } from 'react';
import { nameSpace } from 'src/modules/i18n';
import { useTranslation } from 'react-i18next';
import { getRarityColor } from 'src/tools/getRarityColor';
import { getRarityNameFromEnum } from 'src/tools/getRarityNameFromEnum';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ModalCloseButton from 'src/components/modals/components/ModalCloseButton';
import Modal from '@mui/material/Modal';
import Skills from 'src/components/pages/nft/components/Skills';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled, useMediaQuery } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';
import Styled from 'src/components/modals/OpenOddsModal.styles';

interface HTMLTooltipProps extends TooltipProps {
  customColor: string;
  tooltipBreakpoint: boolean;
}

const HtmlTooltip = styled(({ customColor, tooltipBreakpoint, className, ...props }: HTMLTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ customColor, tooltipBreakpoint }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#222126',
    maxWidth: '700px',
    height: tooltipBreakpoint ? '50vh' : 'auto',
    maxHeight: tooltipBreakpoint ? '28rem' : 'none',
    padding: '1rem',
    border: `2px solid ${customColor}aa`,
    borderRadius: '0',
    fontFamily: 'Barlow',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: `${customColor}aa`,
  },
}));

interface OpenOddsModalProps {
  open: boolean;
  lpm: ListPriceMarket;
  handleClose: () => void;
  style?: React.CSSProperties;
}

const OpenOddsModal: FC<OpenOddsModalProps> = ({ open, lpm, handleClose, style = {} }) => {
  const tooltipBreakpoint = useMediaQuery('(max-height:950px)');
  const { t: tPlay } = useTranslation(nameSpace.play);
  const { rarities: rewards } = lpm;

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={open}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.openOdds}` }}
    >
      <Fade in={open}>
        <Styled.Root style={style}>
          <ModalCloseButton onClose={handleClose} />

          <PrimaryModalBackground stroke={'#6FB6E744'} />

          <Styled.ContentContainer>
            <Styled.Heading>{tPlay('play.popup.open.odds.heading')}</Styled.Heading>

            {rewards.map((reward, i) => {
              return (
                <Styled.RewardRow key={`reward${i}`}>
                  <Styled.RewardTitle>{rewards.length > 1 ? `Reward ${i + 1}` : 'Reward'}</Styled.RewardTitle>

                  {reward.map((rarity, j) => {
                    const rarityEnum =
                      (rarity?.items && rarity?.items?.length > 0 && rarity?.items[0]?.heroDetails?.rarity) || 0;
                    const rarityName = getRarityNameFromEnum(rarityEnum);
                    const rarityColor = getRarityColor(rarityName);

                    return (
                      <Styled.RarityRow key={`reward${i}rarity${j}`}>
                        <Styled.RarityLabel color={rarityColor}>
                          <span>{rarity?.name}</span>
                          {` - ${rarity?.percentage || 0}%`}
                        </Styled.RarityLabel>

                        <Styled.RarityResults>
                          {rarity?.items?.map((item, k) => {
                            if (item?.heroDetails) {
                              return (
                                <HtmlTooltip
                                  key={`reward${i}rarity${j}item${k}`}
                                  arrow
                                  title={
                                    <Styled.TooltipRoot>
                                      <Styled.TooltipImage
                                        loading="lazy"
                                        width={'50'}
                                        height={'50'}
                                        src={item?.imageUrl || ''}
                                        alt={item?.heroDetails?.configName}
                                        color={rarityColor}
                                      />

                                      <Styled.TooltipName>{item?.heroDetails?.configName}</Styled.TooltipName>
                                      <Styled.TooltipRarity color={rarityColor}>{rarityName}</Styled.TooltipRarity>

                                      <Styled.TooltipSkillsContainer>
                                        <Skills
                                          emptyText={'No skills to display.'}
                                          skills={item?.heroDetails?.skills || []}
                                          maxStarLevel={0}
                                          style={{ marginTop: '0' }}
                                        />
                                      </Styled.TooltipSkillsContainer>
                                    </Styled.TooltipRoot>
                                  }
                                  customColor={rarityColor}
                                  tooltipBreakpoint={tooltipBreakpoint}
                                >
                                  <Styled.RarityResult
                                    loading="lazy"
                                    width={'50'}
                                    height={'50'}
                                    src={item?.imageUrl || ''}
                                    alt={item?.heroDetails?.configName}
                                  />
                                </HtmlTooltip>
                              );
                            }

                            return (
                              <Styled.RarityResult
                                key={`reward${i}rarity${j}item${k}`}
                                width={'50'}
                                height={'50'}
                                src={item?.imageUrl || ''}
                                alt={item?.heroDetails?.configName}
                                title={item?.heroDetails?.configName}
                              />
                            );
                          })}
                        </Styled.RarityResults>
                      </Styled.RarityRow>
                    );
                  })}
                </Styled.RewardRow>
              );
            })}
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default OpenOddsModal;
