import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyPurchases } from 'src/redux/slices/purchases/helpers/copy-purchases';

// Define the initial state using that type
const initialState: PurchasesState = {
  purchases: [],
};

const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setPurchases: {
      reducer(state, action: PayloadAction<PurchasesPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.purchases = copyPurchases(action.payload.purchases);
      },
      prepare(purchases: Purchase[]) {
        return {
          payload: {
            purchases,
          },
        };
      },
    },
    clearPurchases: state => {
      // eslint-disable-next-line no-param-reassign
      state.purchases = initialState.purchases;
    },
  },
});

export const { setPurchases, clearPurchases } = purchasesSlice.actions;

// Other Selectors
export const getPurchasesArr = (state: RootState): Purchase[] => state.purchases.purchases;

export default purchasesSlice.reducer;
