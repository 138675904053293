import { keyframes, styled } from '@mui/material';

const pulse = keyframes`
  0% {
    box-shadow: 0px 0px 18px 4px #ffef6000;
    filter: brightness(1);
  }
  20% {
    box-shadow: 0px 0px 18px 4px #ffef6077;
    filter: brightness(1.4);
  }
  40% {
    box-shadow: 0px 0px 18px 4px #ffef6000;
    filter: brightness(1);
  }
  100% {
    box-shadow: 0px 0px 18px 4px #ffef6000;
    filter: brightness(1);
  }
`;

interface RootProps {
  width: string;
  minWidth: string;
  maxWidth: string;
  height: string;
  minHeight: string;
  maxHeight: string;
}

const Root = styled('div')<RootProps>`
  display: inline-block;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  box-sizing: border-box;
  position: relative;
`;

const HexBackgroundImg = styled('img')`
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface InnerButtonContainerProps {
  hideBackgroundImg: boolean;
}

const InnerButtonContainer = styled('div')<InnerButtonContainerProps>`
  width: ${({ hideBackgroundImg }) => (hideBackgroundImg ? '100%' : 'calc(100% - 2rem)')};
  height: ${({ hideBackgroundImg }) => (hideBackgroundImg ? '100%' : 'calc(100% - 1rem)')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;

  &.pulse {
    animation: ${pulse} 5s ease-in-out infinite;
  }

  &:hover {
    animation: none;

    &.pulse {
      transition: box-shadow 0.4s ease-in-out, filter 0.4s ease-in-out;
      filter: brightness(1.1);
      box-shadow: 0px 0px 14px 4px #ffef6077;
    }
  }
`;

interface ButtonProps {
  fontSize: number;
  fontWeight: number;
  disabled?: boolean;
}

const Button = styled('button')<ButtonProps>`
  background-color: #a96314;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px 0px #ffef60 inset;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  filter: ${({ disabled }) => (disabled ? 'grayscale(100%) brightness(0.7)' : 'grayscale(0%)')};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  transition: box-shadow 0.2s ease-in-out, filter 0.2s ease-in-out;

  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '0px 0px 16px 0px #ffef60 inset' : '0px 0px 30px 0px #ffef60 inset')};

    & > .button-primary-hover-shadow {
      box-shadow: ${({ disabled }) => (disabled ? '0px 0px 12px 0px #ffef6000' : '0px 0px 12px 0px #ffef6055')};
    }
  }

  & > .button-primary-text {
    background: linear-gradient(0deg, #fff 50%, #9ce7ff 108.89%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    width: 85%;
    text-align: center;
    text-shadow: 0px 4px 6px rgba(0, 28, 69, 0.25);
    font-family: 'Kevlar Underwear';
    font-size: ${({ fontSize }) => `${fontSize}px`};
    font-weight: ${({ fontWeight }) => fontWeight};
    line-height: ${({ fontSize }) => `${fontSize + 1}px`};
    color: #ffffff;
    position: absolute;
    top: calc(50% + 1px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const HoverShadow = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 12px 0px #ffef6000;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: box-shadow 0.2s ease-in-out;
  pointer-events: none;
`;

const FadedBorder = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  border: 2px solid #ffef60;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(to top, transparent 30%, #ffffffff 100%);
  mask-image: linear-gradient(to top, transparent 30%, #ffffff 100%);
  pointer-events: none;
`;

export default {
  Root,
  HexBackgroundImg,
  InnerButtonContainer,
  Button,
  HoverShadow,
  FadedBorder,
};
