import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPublicDropEventDetails = (options: RequestOptions) => (_dispatch: any) => {
  const { urlParams } = options;

  const config = {
    method: 'get',
    url: `/api/web/public/dropevent/${urlParams?.dropEventId}/details`,
    headers: {},
  };

  return request(config, { isPublic: true });
};
