import { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { GTMEvents } from 'src/modules/gtm/events';
import { useQuery } from 'src/hooks/useQuery';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
// import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { logout } from 'src/api/auth/logout';
// import { accountSettings } from 'src/api/auth/account-settings';
import { getProfileImage } from 'src/tools/getProfileImage';
import { formatPrice } from 'src/tools/formatPrice';
import ATCIcon from 'src/components/icons/ATCIcon';
import { Menu, IconButton } from '@mui/material';
import ButtonDiscordLogin from 'src/components/input/ButtonDiscordLogin';
import Styled from 'src/components/header/AccountDropdown.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const AccountDropdown: FC = () => {
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(nameSpace.common);
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const navigateInternal = useAppNavigateInternal();
  // const navigateExternal = useAppNavigateExternal();
  const leaderboardsEnabled = currentProfile?.features?.leaderboards?.enabled;
  const balance = formatPrice(currentProfile?.atcBalance, { decimals: 0, hideSymbol: true });

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnGoTo = (path: string, gtmEvent?: () => void) => {
    setOpen(false);

    navigateInternal(path, { pooleData: { fromContext: 'header_navigation' }, gtmEvent: gtmEvent || null });
  };

  // const handleWhitepaper = async () => {
  //   navigateExternal(t('common.nav.whitepaper.link'), {
  //     newWindow: true,
  //     pooleData: { pageName: 'Whitepaper', fromContext: 'header_navigation' },
  //   });
  // };

  // const handleAccountSettings = async () => {
  //   const { authUrl } = await dispatch(accountSettings());

  //   navigateExternal(authUrl, {
  //     pooleData: { pageName: 'Codsworth Account Settings', fromContext: 'header_navigation' },
  //   });
  // };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Styled.Root>
      <Styled.AccountButton ref={buttonRef}>
        <IconButton
          id="account-dropdown-button"
          aria-controls={'account-dropdown-menu'}
          aria-haspopup="true"
          aria-expanded={'true'}
          onClick={handleClick}
        >
          <span className="material-icons-outlined">account_circle</span>
        </IconButton>
      </Styled.AccountButton>

      <Menu
        id="account-dropdown-menu"
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-dropdown-button',
        }}
        PaperProps={{
          style: {
            display: 'block',
            marginLeft: '-1rem',
            marginRight: '-1rem',
            maxWidth: '25rem',
            width: '100%',
          },
        }}
        sx={{
          margin: '0 1rem',
          zIndex: `${customThemeOptions.custom.zIndex.header.accountDropdown}`,
          '& ul': { padding: '0' },
        }}
      >
        <Styled.MenuContainer>
          <Styled.OverviewContainer>
            <Styled.ProfilePicture
              style={{ backgroundImage: `url(${getProfileImage(currentProfile?.discordUser?.avatarUrl || '')}` }}
            />

            <Styled.ProfileDetails>
              <Styled.ProfilePrimary>{currentProfile?.username?.trim() || '- -'}</Styled.ProfilePrimary>

              <Styled.ProfileSecondaryContainer>
                {/* {currentProfile?.discordUser && (
                  <Styled.ProfileSecondary>
                    <BaseIcon
                      icon={<DiscordSocialIcon />}
                      fill="#ffffff99"
                      height="14px"
                      width="14px"
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        margin: '0 6px 0 -.1rem',
                      }}
                    />
                    {currentProfile?.discordUser?.username || '- -'}
                  </Styled.ProfileSecondary>
                )} */}

                <Styled.ProfileSecondary>
                  {/* <BaseIcon
                    icon={<EmailIcon />}
                    fill="#ffffff99"
                    height="12px"
                    width="12px"
                    style={{
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      margin: '1px 6px 0 0',
                    }}
                  /> */}
                  {currentProfile?.email?.trim() || '- -'}
                </Styled.ProfileSecondary>
              </Styled.ProfileSecondaryContainer>
            </Styled.ProfileDetails>
          </Styled.OverviewContainer>

          <Styled.MenuItems>
            <Styled.BalanceContainer>
              <ATCIcon
                resolution={'large'}
                size={24}
                style={{ display: 'inline-block', margin: '0 0.6rem 0 0', verticalAlign: 'bottom' }}
              />

              <Styled.Balance>{balance}</Styled.Balance>
              <Styled.BalanceLabel>{t('common.project.currency')}</Styled.BalanceLabel>

              <Styled.BalanceButton disabled>{t('common.term.sell')}</Styled.BalanceButton>
            </Styled.BalanceContainer>

            <ButtonDiscordLogin fullWidth style={{ margin: '0.7rem 1.5rem' }} />

            <Styled.MenuItem onClick={() => handleOnGoTo('/profile')}>{t('common.nav.profile')}</Styled.MenuItem>

            <Styled.MenuItem onClick={() => handleOnGoTo('/marketplace')}>
              {t('common.nav.marketplace')}
            </Styled.MenuItem>

            {leaderboardsEnabled && (
              <Styled.MenuItem onClick={() => handleOnGoTo('/leaderboards')}>
                {t('common.nav.leaderboards')}
              </Styled.MenuItem>
            )}

            <Styled.MenuItem onClick={() => handleOnGoTo('/getting-started')}>
              {t('common.nav.getting.started')}
            </Styled.MenuItem>

            {/* <Styled.MenuItem onClick={handleWhitepaper}>{t('common.nav.whitepaper')}</Styled.MenuItem> */}

            <Styled.MenuItem onClick={() => handleOnGoTo('/blog')}>{t('common.nav.blog')}</Styled.MenuItem>

            <Styled.MenuItem
              onClick={() => {
                handleOnGoTo(trim === '1' ? '/home?trim=1#faqs' : '/home#faqs', () =>
                  GTMEvents.clickBtnFAQs({ eventContext: 'nav-bar-right' }),
                );
              }}
            >
              {t('common.nav.faqs')}
            </Styled.MenuItem>

            {/* <Styled.MenuItem>FortePay Account</Styled.MenuItem> */}

            <Styled.Separator />

            {/* <Styled.MenuItem onClick={handleAccountSettings}>{t('common.nav.account')}</Styled.MenuItem> */}

            <Styled.MenuItem onClick={handleLogout}>{t('common.auth.sign.out')}</Styled.MenuItem>
          </Styled.MenuItems>
        </Styled.MenuContainer>
      </Menu>
    </Styled.Root>
  );
};

export default AccountDropdown;
