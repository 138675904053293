/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import useBroadcastChannelContext from 'src/hooks/useBroadcastChannelContext';
import useProgressiveWebAppContext from 'src/hooks/useProgressiveWebAppContext';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import { searchListings } from 'src/api/secondary-markets/search-listings';
import { getDropEvents } from 'src/api/drop-events/get-drop-events';
import { getDropEventDetails } from 'src/api/drop-events/get-drop-event-details';
import { config } from 'src/config/config';
import { logMain } from 'src/modules/logger/logger';
import { useAppSelector } from './useAppSelector';

// type ReactUnityEventParameter = string | number | undefined;
// type SendMessageFn = (gameObjectName: string, methodName: string, parameter?: ReactUnityEventParameter) => void;
type PageName = 'primary-marketplace' | 'secondary-marketplace' | 'rewards' | 'collection' | 'nft';
interface GameEventParams {
  page?: 'primary' | 'secondary' | 'rewards' | 'collection' | 'nft' | null;
  dropEventId?: string | null;
  listPriceMarketId?: string | null;
  configId?: string | null;
  nonFungibleId?: string | null;
  from?: string | null;
}

const parseJSONParams = (paramsJSONStr: string): GameEventParams | null => {
  let parsedParams: GameEventParams = { page: 'primary' };

  try {
    parsedParams = JSON.parse(paramsJSONStr);
  } catch (e) {
    logMain.debug('Error parsing game client event params - OpenMarketWindow');

    return null;
  }

  return parsedParams;
};

const getPageNameFromEvent = (params?: GameEventParams): PageName => {
  const { page } = params || {};

  switch (page) {
    case 'primary':
      return 'primary-marketplace';
    case 'secondary':
      return 'secondary-marketplace';
    case 'rewards':
      return 'rewards';
    case 'collection':
      return 'collection';
    default:
      logMain.error('[GAME_OVERLAY_LISTENER]: Invalid page name: ', page);

      return 'primary-marketplace';
  }
};

export const useGameClientListener = () => {
  const { broadcastChannel, sendBroadcastMessage } = useBroadcastChannelContext();
  const { showSaveToPhoneModal } = useProgressiveWebAppContext();
  const {
    iFrameBlobObjectURL,
    setClearUnityClient,
    setGameConfigError,
    setUnityClientLoadPercentage,
    setUnityClientLoaded,
    setSearchMarketsFailureModal,
    setGameOverlayErrorModal,
    setListNFTModalNonfungibleId,
    setMultipleInstanceModal,
    isGameClientDisabledByDebug,
    disableGameClientByMultipleInstances,
    isGameClientDisabledByMultipleInstances,
    setDisableGameClientByMultipleInstances,
  } = useGameClientContext();
  const { openGameOverlay, closeGameOverlay } = useGameOverlayContext();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const [oAuthPopup, setOAuthPopup] = useState<Window>();
  const dispatch = useAppDispatch();
  const marketplaceEnabled = currentProfile?.features?.marketplace?.enabled;

  // Add event listener for opening OAuth window.
  useEffect(() => {
    // EVENT HANDLER -->
    // Open Game Overlay
    const handleOpenGameOverlay = (paramsJSONStr: string) => {
      const params = parseJSONParams(paramsJSONStr);

      logMain.debug('[GAME_OVERLAY_LISTENER]: Game client is requesting to open game overlay', params);

      // If no params are provided, send error message back to client.
      if (!params) {
        logMain.debug('[GAME_OVERLAY_LISTENER]: Invalid params provided to game overlay <OpenMarketWindow> event');

        setGameOverlayErrorModal(true);

        return;
      }

      openGameOverlay({
        page: getPageNameFromEvent(params),
        params: {
          dropEventId: params?.dropEventId || null,
          listPriceMarketId: params?.listPriceMarketId || null,
          from: params?.from || null,
        },
      });
    };

    // EVENT HANDLER -->
    // Search Markets
    const handleSearchMarkets = async (paramsJSONStr: string) => {
      const params = parseJSONParams(paramsJSONStr);
      let existsInSecondary = false;
      let existsInPrimary = false;
      let matchedLPMId = null;
      let matchedLPMType = null;

      logMain.debug('[GAME_OVERLAY_LISTENER]: Game client is requesting to search markets', params);

      // If no params are provided, send error message back to client.
      if (!params || !params?.configId) {
        logMain.debug('[GAME_OVERLAY_LISTENER]: Invalid params provided to <SearchMarkets> event');

        if (!params?.configId) logMain.error('[GAME_OVERLAY_LISTENER]: No <configId> param provided');

        setGameOverlayErrorModal(true);

        return;
      }

      // Search for secondary market listings and set <existsInSecondary> to true if any listings are found.
      if (marketplaceEnabled) {
        try {
          const searchListingsResults = await dispatch(
            searchListings({
              queryParams: {
                config_id: params.configId,
              },
            }),
          );

          if (searchListingsResults?.listings && searchListingsResults?.listings?.length > 0) {
            existsInSecondary = true;
          }
        } catch (e: any) {
          logMain.debug('[GAME_OVERLAY_LISTENER]: Error searching listings.', e);
        }
      }

      // If secondary market listing exists, open the secondary marketplace and automatically load listings for that configId.
      if (existsInSecondary) {
        openGameOverlay({
          page: 'secondary-marketplace',
          params: {
            configId: params.configId,
          },
        });

        return;
      }

      // Search for primary market contents and set <existsInPrimary> to true if any configId is found in any LPM.
      try {
        // Get drop events
        const dropEventsResult = await dispatch(getDropEvents());
        const dropEvents = dropEventsResult?.dropEvents || [];

        // Create an array of promises to get drop event details for each drop event.
        const promises =
          dropEvents.length > 0
            ? dropEvents.map((dropEvent: any) => {
                return dispatch(getDropEventDetails({ urlParams: { dropEventId: dropEvent.id } }));
              })
            : [Promise.resolve([])];

        // Get drop events details using Promise.all()
        const dropEventDetailsResults = await Promise.all(promises);

        // Loop through drop event details
        for (let i = 0; i < dropEventDetailsResults.length; i++) {
          if (existsInPrimary) break;

          const dropEventDetails = dropEventDetailsResults[i];
          const markets = dropEventDetails?.markets || [];

          // Loop through markets of each drop event
          for (let j = 0; j < markets.length; j++) {
            if (existsInPrimary) break;

            const market = markets[j];
            const rarities = market?.rarities || [];

            // Loop through market rarities
            for (let k = 0; k < rarities.length; k++) {
              if (existsInPrimary) break;

              const rarity = rarities[k];

              // Loop through rarity items
              for (let l = 0; l < rarity.length; l++) {
                if (existsInPrimary) break;

                const raritySegment = rarity[l];
                const items = raritySegment?.items || [];
                const configIdExists = items.some((item: any) => item?.heroDetails?.configId === params.configId);

                if (configIdExists) {
                  existsInPrimary = true;
                  matchedLPMId = market.id;
                  matchedLPMType = market.type;

                  break;
                }
              }
            }
          }
        }
      } catch (e: any) {
        logMain.debug('[GAME_OVERLAY_LISTENER]: Error searching drop events.', e);
      }

      // If primary market or daily chest contains config ID, open the appropriate tab to correct market.
      if (existsInPrimary) {
        openGameOverlay({
          page: matchedLPMType === 'daily' ? 'rewards' : 'primary-marketplace',
          params: {
            listPriceMarketId: matchedLPMId,
          },
        });

        return;
      }

      logMain.debug('[GAME_OVERLAY_LISTENER]: No results found for <SearchMarkets> configId.', params.configId);

      setSearchMarketsFailureModal(true);
    };

    // EVENT HANDLER -->
    // Create Listing
    const handleCreateListing = (paramsJSONStr: string) => {
      const params = parseJSONParams(paramsJSONStr);

      logMain.debug('[GAME_OVERLAY_LISTENER]: Game client is requesting to create listing', params);

      // If no params are provided, send error message back to client.
      if (!params || !params?.nonFungibleId) {
        logMain.debug('[GAME_OVERLAY_LISTENER]: Invalid params provided to <CreateListing> event');

        if (!params?.nonFungibleId) logMain.error('[GAME_OVERLAY_LISTENER]: No <nonfungibleId> param provided');

        setGameOverlayErrorModal(true);

        return;
      }

      setListNFTModalNonfungibleId(params.nonFungibleId);
    };

    // EVENT HANDLER -->
    // Open OAuth Window
    const handleOpenOAuthUrl = (url: string) => {
      logMain.debug('[GAME_OVERLAY_LISTENER]: Received event to open OAuth window', url);

      const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=1000';
      const popupWindow = window.open(url, 'Login', params);

      if (popupWindow !== null) {
        setOAuthPopup(popupWindow);
      }
    };

    // WINDOW MESSAGE HANDLER -->
    // Handle OAuth complete and close OAuth window
    const handleOAuthComplete = (event: MessageEvent<any>) => {
      // Ignore messages from other sources.
      if (event.source !== oAuthPopup) return;
      // Ignore messages that aren't from the OAuth window.
      if (event.data.sender !== 'lhu-oauth' || typeof event.data.redirectUrl !== 'string') return;

      logMain.debug('[GAME_OVERLAY_LISTENER]: Received event from OAuth window', event);
      logMain.debug('[BROADCAST_CHANNEL_WEBSITE]: Sending message to broadcast channel...');

      // Send broadcast message to Close the OAuth window
      sendBroadcastMessage({
        unityClientOAuthComplete: true,
        unityClientOAuthRedirectUrl: event.data.redirectUrl,
      });
    };

    // BROADCAST CHANNEL MESSAGE HANDLER -->
    // Handle incoming messages from broadcast channel.
    const handleBroadcastChannelMessage = (event: MessageEvent) => {
      const message = event.data;

      logMain.debug(
        `[BROADCAST_CHANNEL_GAME_CLIENT_LISTENER]: Message from broadcast channel <${broadcastChannel.name}>`,
        message,
      );

      // If a unity game event has been fired
      if (message.gameEventName) {
        switch (message.gameEventName) {
          case 'OpenMarketWindow':
            handleOpenGameOverlay(message.gameEventData);
            break;
          case 'SearchMarkets':
            handleSearchMarkets(message.gameEventData);
            break;
          case 'CreateListing':
            handleCreateListing(message.gameEventData);
            break;
          case 'OpenOAuthWindow':
            handleOpenOAuthUrl(message.gameEventData);
            break;
          default:
            logMain.error('[BROADCAST_CHANNEL_GAME_EVENT_LISTENER]: Invalid game event name', message.gameEventName);
            break;
        }
      }

      // If another tab or window is trying to load the game client, disable the game client.
      if (
        message?.uuid !== config.uuid &&
        message?.unityClientInitializing &&
        iFrameBlobObjectURL &&
        !showSaveToPhoneModal &&
        !isGameClientDisabledByDebug &&
        !isGameClientDisabledByMultipleInstances
      ) {
        logMain.debug('[GAME_CLIENT]: Multiple game instances detected, disabling game client.');

        closeGameOverlay();
        setDisableGameClientByMultipleInstances(!disableGameClientByMultipleInstances);
        setMultipleInstanceModal(true);
      }

      // If game is loading, update the unity client load percentage.
      if (
        message?.uuid === config.uuid &&
        (message?.unityClientLoadPercentage || message?.unityClientLoadPercentage === 0)
      ) {
        setUnityClientLoadPercentage(message?.unityClientLoadPercentage);
      }

      // If game client has loaded or unloaded, set the unity client loaded state.
      if (message?.uuid === config.uuid && typeof message?.unityClientLoaded !== 'undefined') {
        setUnityClientLoaded(message?.unityClientLoaded);
      }

      // If game client encountered an error during loading, set unity client error state.
      if (message?.uuid === config.uuid && message?.unityClientError) {
        setGameConfigError(true);
      }

      // If game client has quit, clear the unity client.
      if (message?.uuid === config.uuid && message?.unityClientQuit) {
        setClearUnityClient(true);
      }
    };

    window.addEventListener('message', handleOAuthComplete, false);
    broadcastChannel.addEventListener('message', handleBroadcastChannelMessage);

    return () => {
      window.removeEventListener('message', handleOAuthComplete, false);
      broadcastChannel.removeEventListener('message', handleBroadcastChannelMessage);
    };
  }, [
    broadcastChannel,
    closeGameOverlay,
    disableGameClientByMultipleInstances,
    dispatch,
    iFrameBlobObjectURL,
    isGameClientDisabledByDebug,
    isGameClientDisabledByMultipleInstances,
    marketplaceEnabled,
    oAuthPopup,
    openGameOverlay,
    sendBroadcastMessage,
    setClearUnityClient,
    setDisableGameClientByMultipleInstances,
    setGameConfigError,
    setGameOverlayErrorModal,
    setListNFTModalNonfungibleId,
    setMultipleInstanceModal,
    setSearchMarketsFailureModal,
    setUnityClientLoadPercentage,
    setUnityClientLoaded,
    showSaveToPhoneModal,
  ]);
};
