import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.Yellow};
  display: block;
  width: 100%;
  height: 2.5rem;
`;

const CenteredContainer = styled('div')`
  display: flex;
  width: 100%;
  max-width: 55rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    padding: 0 1.5rem;
  }
`;

const BackButton = styled('div')`
  background-color: #ffffff55;
  display: flex;
  margin-right: 0.5rem;
  padding: 0 0.8rem 0 0.5rem;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffffff77;
  }

  & > span:not(:first-of-type) {
    display: inline-block;
    vertical-align: middle;
    word-break: break-word;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
    color: #000000;

    @media (max-width: 500px) {
      display: none;
    }
  }

  & .material-icons-outlined {
    display: inline-block;
    vertical-align: middle;
    margin-top: 1px;
    margin-right: 0.2rem;
    font-size: 1rem;
    font-weight: 800;
    color: #000000;

    @media (max-width: 500px) {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
  }

  @media (max-width: 500px) {
    padding: 0 0.8rem 0 0.7rem;
  }
`;

export default {
  Root,
  CenteredContainer,
  BackButton,
};
