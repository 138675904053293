import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const FusionStarIcon: FC = () => (
  <SvgIcon width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7" filter="url(#filter0_d_184_351)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.9632 83.6703L117.529 64.7071L82.9632 45.7438L64 11.178L45.0367 45.7438L10.4709 64.707L45.0367 83.6703L63.9999 118.236L82.9632 83.6703Z"
        fill="url(#paint0_linear_184_351)"
      />
    </g>
    <g opacity="0.7" filter="url(#filter1_d_184_351)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8751 81.5822L111.635 64.7071L80.8751 47.8319L64 17.0721L47.1248 47.8319L16.365 64.707L47.1248 81.5822L63.9999 112.342L80.8751 81.5822ZM63.9999 118.236L45.0367 83.6703L10.4709 64.707L45.0367 45.7438L64 11.178L82.9632 45.7438L117.529 64.7071L82.9632 83.6703L63.9999 118.236Z"
        fill="white"
        style={{ mixBlendMode: 'overlay' }}
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_di_184_351)">
      <path
        d="M64.0001 18.9419L80.2129 48.4942L109.765 64.7069L80.2129 80.9196L64.0001 110.472L47.7874 80.9196L18.2351 64.7069L47.7874 48.4942L64.0001 18.9419Z"
        fill="url(#paint1_linear_184_351)"
      />
    </g>
    <mask
      id="mask0_184_351"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="18"
      y="18"
      width="92"
      height="93"
    >
      <path
        d="M64.0001 18.9419L80.2129 48.4942L109.765 64.7069L80.2129 80.9196L64.0001 110.472L47.7874 80.9196L18.2351 64.7069L47.7874 48.4942L64.0001 18.9419Z"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_184_351)">
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M61.0843 66.5017L2.70803 63.9636L28.7235 98.8624L61.0843 66.5017Z"
          fill="url(#paint2_linear_184_351)"
        />
      </g>
      <g style={{ mixBlendMode: 'overlay' }}>
        <path d="M64.0002 65.6044L20.9272 26.1208L64.0002 19.8394V65.6044Z" fill="url(#paint3_linear_184_351)" />
      </g>
      <path d="M63.1028 65.6045L139.378 63.8098L100.792 27.0183L63.1028 65.6045Z" fill="url(#paint4_radial_184_351)" />
      <path
        d="M64.0002 64.7068L46.9504 80.8591L64.0002 113.164L90.9207 89.8327L64.0002 64.7068Z"
        fill="url(#paint5_linear_184_351)"
      />
    </g>
    <path
      d="M64.0001 18.9419L67.5598 61.1472L109.765 64.7069L67.5598 68.2666L64.0001 110.472L60.4404 68.2666L18.2351 64.7069L60.4404 61.1472L64.0001 18.9419Z"
      fill="url(#paint6_linear_184_351)"
    />
    <g style={{ mixBlendMode: 'overlay' }} filter="url(#filter3_f_184_351)">
      <path
        d="M64.0001 18.9419L65.6182 63.0889L109.765 64.7069L65.6182 66.3249L64.0001 110.472L62.3821 66.3249L18.2351 64.7069L62.3821 63.0889L64.0001 18.9419Z"
        fill="white"
      />
    </g>
    <g style={{ mixBlendMode: 'overlay' }} opacity="0.7">
      <path
        d="M64.0001 18.9419L64.6473 64.0597L109.765 64.7069L64.6473 65.3541L64.0001 110.472L63.3529 65.3541L18.2351 64.7069L63.3529 64.0597L64.0001 18.9419Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.2129 48.4942L64.0001 18.9419L47.7874 48.4942L18.2351 64.7069L47.7874 80.9196L64.0001 110.472L80.2129 80.9196L109.765 64.7069L80.2129 48.4942ZM104.534 64.7069L78.3595 50.3475L64.0001 24.1734L49.6407 50.3475L23.4666 64.7069L49.6407 79.0663L64.0001 105.24L78.3595 79.0663L104.534 64.7069Z"
      fill="url(#paint7_linear_184_351)"
    />
    <defs>
      <filter
        id="filter0_d_184_351"
        x="8.47095"
        y="9.17798"
        width="111.058"
        height="111.058"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.84 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_351" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_351" result="shape" />
      </filter>
      <filter
        id="filter1_d_184_351"
        x="8.47095"
        y="9.17798"
        width="111.058"
        height="111.058"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.84 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_351" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_351" result="shape" />
      </filter>
      <filter
        id="filter2_di_184_351"
        x="16.2351"
        y="16.9419"
        width="95.53"
        height="95.53"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.283333 0 0 0 0 0.398 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_351" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_351" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5552" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.408333 0 0 0 0 0.940833 0 0 0 1 0" />
        <feBlend mode="overlay" in2="shape" result="effect2_innerShadow_184_351" />
      </filter>
      <filter
        id="filter3_f_184_351"
        x="14.6063"
        y="15.3131"
        width="98.7876"
        height="98.7876"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.8144" result="effect1_foregroundBlur_184_351" />
      </filter>
      <linearGradient
        id="paint0_linear_184_351"
        x1="117.529"
        y1="64.7071"
        x2="10.4709"
        y2="64.707"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="0.484375" stopColor="#E43DFF" />
        <stop offset="1" stopColor="#FF5C00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_184_351"
        x1="63.9999"
        y1="27.0181"
        x2="63.9999"
        y2="86.2434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFDA7C" />
        <stop offset="1" stopColor="#FF8A35" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_184_351"
        x1="57.2814"
        y1="83.342"
        x2="36.5544"
        y2="39.8151"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7B7B" />
        <stop offset="1" stopColor="#FF7B7B" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_184_351"
        x1="70.9615"
        y1="14.5281"
        x2="90.4449"
        y2="42.7219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_184_351"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(91.8181 53.0416) rotate(159.165) scale(28.082 55.0575)"
      >
        <stop stopColor="#00FFE0" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint5_linear_184_351"
        x1="63.5515"
        y1="65.6041"
        x2="63.5515"
        y2="86.2433"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_184_351"
        x1="63.9999"
        y1="27.0181"
        x2="63.9999"
        y2="86.2434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB31F" />
        <stop offset="1" stopColor="#FFD747" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_184_351"
        x1="64.0001"
        y1="18.9419"
        x2="64.0001"
        y2="110.472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="0.484375" stopColor="#E43DFF" />
        <stop offset="1" stopColor="#FF5C00" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default FusionStarIcon;
