import { FC, memo } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import CustomScroll from 'src/components/custom-scroll/CustomScroll';
import SectionResults from 'src/components/pages/marketplace/components/SectionResults';
import Styled from 'src/components/game-overlay/pages/PageSecondaryMarketplace.styles';

const PageSecondaryMarketplace: FC = () => {
  const listings = useAppSelector(({ listings }) => listings.listings);
  const dependencies = [listings];

  return (
    <Styled.Root>
      <CustomScroll dependencies={dependencies}>
        <SectionResults style={{ padding: '0 0 3rem 0' }} />
      </CustomScroll>
    </Styled.Root>
  );
};

export default memo(PageSecondaryMarketplace);
