import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  min-height: 38rem;
  height: 100vh;
  position: relative;
`;

const Overlay = styled('div')`
  display: block;
  width: 100%;
  max-width: 55rem;
  padding: 0 10rem;
  word-wrap: break-word;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 1;

  @media (max-width: 1200px) {
    max-width: 42rem;
    padding: 0 5rem;
  }

  @media (max-width: 768px) {
    background: linear-gradient(to top, #000000aa 0%, #000000aa 70%, #00000000 100%);
    width: 100%;
    max-width: 100%;
    padding: 7rem 2.5rem 2.5rem 2.5rem;
    top: auto;
    bottom: 0;
    transform: none;
  }

  @media (max-width: 500px) {
    padding: 7rem 2rem 2rem 2rem;
  }
`;

interface HeadingProps {
  color: string;
}

const Heading = styled('p')<HeadingProps>`
  display: block;
  margin: 1.2rem 0 0 -0.3rem;
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 5.3rem;
  font-weight: 700;
  color: ${({ color }) => color || '#ffffff'};

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1200px) {
    font-size: 5rem;
    line-height: 4.3rem;
  }

  @media (max-width: 768px) {
    font-size: 4.5rem;
    line-height: 4.3rem;
    color: #ffffff;
  }

  @media (max-width: 700px) {
    font-size: 4rem;
    line-height: 4rem;
  }

  @media (max-width: 400px) {
    font-size: 3.5rem;
    line-height: 3.2rem;
  }
`;

interface SubheadingProps {
  color: string;
}

const Subheading = styled('p')<SubheadingProps>`
  display: block;
  width: 100%;
  max-width: 24rem;
  margin-top: 1.3rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: ${({ color }) => color || '#ffffff'};

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1200px) {
    margin-top: 1.5rem;
  }

  @media (max-width: 768px) {
    max-width: 20rem;
    margin-top: 1.2rem;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #ffffff;
  }

  @media (max-width: 700px) {
    margin-top: 1.2rem;
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media (max-width: 500px) {
    margin-top: 1.2rem;
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

export default {
  Root,
  Overlay,
  Heading,
  Subheading,
};
