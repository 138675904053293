import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const purchaseNextListing = (options: RequestOptions) => (_dispatch: any) => {
  const { urlParams } = options;

  const config = {
    method: 'post',
    url: `/api/web/market/${urlParams?.marketId}/purchase-next`,
    withCredentials: true,
  };

  return request(config);
};
