/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ScrollButton from 'src/components/custom-scroll/ScrollButton';
import Styled from 'src/components/custom-scroll/CustomScroll.styles';
import { logMain } from 'src/modules/logger/logger';
import { useMediaQuery } from '@mui/material';

interface CustomScrollProps {
  children: ReactNode;
  dependencies: any[][];
}

const CustomScroll: FC<CustomScrollProps> = ({ children, dependencies }) => {
  const bp = useMediaQuery('(max-width: 900px) or (max-height: 500px)');
  const trackWidth = 16;
  const thumbWidth = 16;
  const buttonWidth = bp ? 36 : 42;
  const buttonHeight = bp ? 52 : 64;
  const trackButtonOffset = bp ? 8 : 10;
  const scrollbarGutterWidth = bp ? 8 : 16;
  const defaultScrollAmount = 350;
  const minThumbHeight = 20;
  const observerRef = useRef<ResizeObserver | null>(null);
  const didManuallySendResizeEvent = useRef<boolean>(false);
  const [enableScrollbar, setEnableScrollbar] = useState<boolean>(false);
  const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);
  const [trackEl, setTrackEl] = useState<HTMLDivElement | null>(null);
  const [thumbEl, setThumbEl] = useState<HTMLDivElement | null>(null);
  const [thumbHeight, setThumbHeight] = useState<number>(minThumbHeight);
  const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(null);
  const [initialScrollTop, setInitialScrollTop] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);
  const [topButtonDisabled, setTopButtonDisabled] = useState(false);
  const [bottomButtonDisabled, setBottomButtonDisabled] = useState(false);
  const scrollbarWidth = Math.max(buttonWidth, trackWidth, thumbWidth);

  // Handle setting references for content, track, and thumb elements
  const onRefChange = useCallback((node: any, component: 'content' | 'track' | 'thumb') => {
    switch (component) {
      case 'content':
        if (node) setContentEl(node);
        break;

      case 'track':
        if (node) setTrackEl(node);
        break;

      case 'thumb':
        if (node) setThumbEl(node);
        break;

      default:
        break;
    }
  }, []);

  // Handle resizing the thumb based on content and track size
  const handleResize = useCallback(() => {
    if (!contentEl || !trackEl) return;

    const {
      scrollTop: contentScrollTop,
      scrollHeight: contentScrollHeight,
      clientHeight: contentClientHeight,
    } = contentEl;
    const { clientHeight: trackClientHeight } = trackEl;
    const enable = contentScrollHeight > contentClientHeight;

    // logMain.debug('[CUSTOM_SCROLL]: Handling resize event...', {
    //   contentScrollHeight,
    //   contentClientHeight,
    //   trackClientHeight,
    //   enable,
    // });

    setEnableScrollbar(enable && !isMobile);
    setThumbHeight(Math.max((contentClientHeight / contentScrollHeight) * trackClientHeight, minThumbHeight));

    // Disable top and bottom buttons if at the top or bottom of the content
    setTopButtonDisabled(contentScrollTop === 0);
    setBottomButtonDisabled(contentScrollTop === contentScrollHeight - contentClientHeight);
  }, [contentEl, minThumbHeight, setEnableScrollbar, trackEl]);

  // Handle setting thumb position based on scroll position
  const handleThumbPosition = useCallback(() => {
    if (!contentEl || !trackEl || !thumbEl) return;

    const {
      scrollTop: contentScrollTop,
      scrollHeight: contentScrollHeight,
      clientHeight: contentClientHeight,
    } = contentEl;
    const { clientHeight: trackClientHeight } = trackEl;

    let newTop = (contentScrollTop / contentScrollHeight) * trackClientHeight;
    newTop = Math.min(newTop, trackClientHeight - thumbHeight);

    thumbEl.style.top = `${newTop}px`;

    // Disable top and bottom buttons if at the top or bottom of the content
    setTopButtonDisabled(contentScrollTop === 0);
    setBottomButtonDisabled(contentScrollTop === contentScrollHeight - contentClientHeight);
  }, [contentEl, thumbEl, thumbHeight, trackEl]);

  // Handle scrolling up or down using scroll buttons
  const handleScrollButton = (direction: 'up' | 'down') => {
    if (contentEl) {
      const scrollAmount = direction === 'down' ? defaultScrollAmount : -defaultScrollAmount;

      contentEl.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  };

  // Handle clicking on the scrollbar track
  const handleTrackClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (trackEl && contentEl) {
        // First, figure out where we clicked
        const { clientY } = e;
        // Next, figure out the distance between the top of the track and the top of the viewport
        const { top: trackTop } = (e.target as HTMLDivElement).getBoundingClientRect();
        // We want the middle of the thumb to jump to where we clicked, so we subtract half the thumb's height to offset the position
        const thumbOffset = -(thumbHeight / 2);
        // Find the ratio of the new position to the total content length using the thumb and track values...
        const clickRatio = (clientY - trackTop + thumbOffset) / trackEl.clientHeight;
        // ...so that you can compute where the content should scroll to.
        const scrollAmount = Math.floor(clickRatio * contentEl.scrollHeight);

        // And finally, scroll to the new position!
        contentEl.scrollTo({
          top: scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [contentEl, thumbHeight, trackEl],
  );

  // Handle onmousedown event for the thumb
  const handleThumbMouseDown = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      setScrollStartPosition(e?.clientY);

      if (contentEl) setInitialScrollTop(contentEl.scrollTop);

      setIsDragging(true);
    },
    [contentEl],
  );

  // Handle onmouseup event for the thumb
  const handleThumbMouseUp = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      if (isDragging) setIsDragging(false);
    },
    [isDragging],
  );

  // Handle onmousemove event for the thumb
  const handleThumbMouseMove = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      if (isDragging && contentEl) {
        const { scrollHeight: contentScrollHeight, offsetHeight: contentOffsetHeight } = contentEl;

        // Subtract the current mouse y position from where you started to get the pixel difference in mouse position. Multiply by ratio of visible content height to thumb height to scale up the difference for content scrolling.
        const deltaY = (e.clientY - (scrollStartPosition || 0)) * (contentOffsetHeight / thumbHeight);
        const newScrollTop = Math.min(initialScrollTop + deltaY, contentScrollHeight - contentOffsetHeight);

        // eslint-disable-next-line no-param-reassign
        contentEl.scrollTop = newScrollTop;
      }
    },
    [contentEl, initialScrollTop, isDragging, scrollStartPosition, thumbHeight],
  );

  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    logMain.debug('[CUSTOM_SCROLL]: Initializing mousemove, mouseup, and mouseleave listeners');

    document.addEventListener('mousemove', handleThumbMouseMove);
    document.addEventListener('mouseup', handleThumbMouseUp);
    document.addEventListener('mouseleave', handleThumbMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleThumbMouseMove);
      document.removeEventListener('mouseup', handleThumbMouseUp);
      document.removeEventListener('mouseleave', handleThumbMouseUp);
    };
  }, [handleThumbMouseMove, handleThumbMouseUp]);

  // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentEl && trackEl) {
      logMain.debug('[CUSTOM_SCROLL]: Initializing resize observer and scroll listeners', { contentEl });

      // Do something when the element is resized
      // This will listen to DOM element sizing changes (a new feature)
      observerRef.current = new ResizeObserver(() => {
        handleResize();
      });

      observerRef.current.observe(contentEl);
      contentEl.addEventListener('scroll', handleThumbPosition);

      handleResize();
    }

    return () => {
      // Cleanup the observer by unobserving all elements
      if (observerRef.current && contentEl) observerRef.current.unobserve(contentEl);
      if (contentEl) contentEl.removeEventListener('scroll', handleThumbPosition);
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [contentEl, trackEl, handleResize, handleThumbPosition]);

  // Resize thumb when track width changes
  useEffect(() => {
    // If dependencies are not populated, don't do anything
    let dependenciesPopulated = true;

    for (let i = 0; i < dependencies.length; i += 1) {
      if (dependencies[i].length === 0) {
        dependenciesPopulated = false;

        break;
      }
    }

    // If the content and track elements exist, and dependencies are populated, manually send a resize event after 1 second
    if (!didManuallySendResizeEvent.current && contentEl && trackEl && dependenciesPopulated) {
      didManuallySendResizeEvent.current = true;

      setTimeout(() => {
        logMain.debug('[CUSTOM_SCROLL]: Dependencies loaded! Manually sending resize event 1 second.');

        handleResize();
      }, 1000);
    }
  }, [contentEl, trackEl, handleResize, dependencies]);

  return (
    <Styled.Root>
      <Styled.CustomScrollContent
        ref={node => onRefChange(node, 'content')}
        enableScrollbar={enableScrollbar}
        scrollbarWidth={scrollbarWidth}
        scrollbarGutterWidth={scrollbarGutterWidth}
      >
        {children}
      </Styled.CustomScrollContent>

      <Styled.CustomScrollScrollbar
        enableScrollbar={enableScrollbar}
        scrollbarWidth={scrollbarWidth}
        scrollbarGutterWidth={scrollbarGutterWidth}
      >
        <Styled.CustomScrollTrackAndThumbContainer
          trackButtonOffset={trackButtonOffset}
          buttonHeight={buttonHeight}
          scrollbarWidth={scrollbarWidth}
        >
          <Styled.CustomScrollTrack
            ref={node => onRefChange(node, 'track')}
            trackWidth={trackWidth}
            onClick={handleTrackClick}
          />

          <Styled.CustomScrollThumb
            ref={node => onRefChange(node, 'thumb')}
            thumbWidth={thumbWidth}
            thumbHeight={thumbHeight}
            onMouseDown={handleThumbMouseDown}
            //   onTouchStart={handleThumbMouseDown}
          />
        </Styled.CustomScrollTrackAndThumbContainer>

        <ScrollButton
          disabled={topButtonDisabled}
          materialIconId="arrow_upward"
          buttonWidth={buttonWidth}
          buttonHeight={buttonHeight}
          position="top"
          onClick={() => handleScrollButton('up')}
        />

        <ScrollButton
          disabled={bottomButtonDisabled}
          materialIconId="arrow_downward"
          buttonWidth={buttonWidth}
          buttonHeight={buttonHeight}
          position="bottom"
          onClick={() => handleScrollButton('down')}
        />
      </Styled.CustomScrollScrollbar>
    </Styled.Root>
  );
};

export default CustomScroll;
