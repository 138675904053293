import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import Styled from 'src/components/primary-marketplace/components/EventType.styles';

interface EventTypeProps {
  dropEventId: string;
}

const EventType: FC<EventTypeProps> = ({ dropEventId }) => {
  const dropEvent = useAppSelector(state => state.dropEvents.dropEvents)[dropEventId];
  const { name } = dropEvent || {};

  if (!name) return null;

  return (
    <Styled.Root>
      <Styled.EventType>{name}</Styled.EventType>
    </Styled.Root>
  );
};

export default EventType;
