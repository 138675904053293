import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'src/tools/useQuery';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import Styled from './SectionRoadmap.styles';
import RoadMapHeaderBody from './RoadMapHeaderBody';
import { nameSpace } from '../../../../modules/i18n';

type itemProps = {
  id: number;
  date: string;
  title: string;
  content: string;
};

const SectionRoadmap: FC = () => {
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const { t } = useTranslation(trim === '1' ? nameSpace.homeTrim : nameSpace.home);
  const roadMapItems = t<string, itemProps[]>('home.roadmap.items', { returnObjects: true });
  return (
    <Styled.ScrollWrapper>
      {roadMapItems.map(({ id, date, title, content }) => (
        <Fragment key={id}>
          <RoadMapHeaderBody id={id} date={date} header={title} body={content} />
        </Fragment>
      ))}
    </Styled.ScrollWrapper>
  );
};

export default SectionRoadmap;
