/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PooleEvents } from 'src/modules/poole/events';
import { useAppSelector } from 'src/hooks/useAppSelector';
import useGameClientContext from 'src/hooks/useGameClientContext';
// import { logMain } from 'src/modules/logger/logger';

interface PooleData {
  playerId?: string;
  pageName: string;
  fromContext: string;
}

interface Options {
  newWindow?: boolean;
  pooleData?: PooleData;
  gtmEvent?: (() => void) | null;
  force?: boolean;
}

export const useAppNavigateExternal = () => {
  const { unityClientLoaded, setLeaving, setLeavingTo, setLeavingOutbound } = useGameClientContext();
  const routerLocation = useLocation();
  const playerId = useAppSelector(({ currentProfile }) => currentProfile.currentProfile.id) || '';

  const handleNavigateExternal = useMemo(
    () => (to: string, options: Options) => {
      const { newWindow = false, pooleData, gtmEvent = () => null, force = false } = options;
      const { pageName = '', fromContext = '' } = pooleData || {};

      // IMPORTANT: if user is on '/play' and they are trying to leave while game is running,
      // we must activate popup that asks them if they are sure they want to leave
      if (routerLocation.pathname.indexOf('play') !== -1 && !force && !newWindow && unityClientLoaded) {
        setLeaving(true);
        setLeavingTo(to);
        setLeavingOutbound(true);

        return;
      }

      // IMPORTANT: If the force param is true, deactivate the any popup caused by the user trying to leave
      if (force) {
        setLeaving(false);
        setLeavingTo('');
        setLeavingOutbound(false);
      }

      // If a google tag manager event is passed in, fire it
      if (gtmEvent) {
        gtmEvent();
      }

      // Fire the poole event
      PooleEvents.NavigateExternal({
        playerId: playerId || pooleData?.playerId || '',
        pageName,
        from: routerLocation.pathname,
        to,
        fromContext,
      });

      // Open the link in a new window or redirect to the link
      if (newWindow) {
        window.open(to, '_blank');
      } else {
        window.location.href = to;
      }
    },
    [playerId, routerLocation.pathname, setLeaving, setLeavingOutbound, setLeavingTo, unityClientLoaded],
  );

  return handleNavigateExternal;
};
