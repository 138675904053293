import { useContext } from 'react';
import SoundContext from 'src/context/sound/Sound.context';

const useSoundContext = () => {
  const soundContext = useContext(SoundContext);

  return soundContext;
};

export default useSoundContext;
