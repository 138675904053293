/* eslint-disable no-plusplus */
import { RARITIES } from 'src/constants/rarities';

export const getRarityEnumFromName = (name: string | null | undefined): number => {
  for (let i = 0; i < Object.keys(RARITIES).length; i++) {
    const key: number = parseInt(Object.keys(RARITIES)[i], 10);
    const rarityValue = RARITIES[key];

    // Handle different naming conventions for Ultra Rare
    if (rarityValue === 'ultraRare') {
      if (name === 'ultraRare' || name === 'ultra rare') {
        return key;
      }
    }

    // Handle different naming conventions for Epic
    if (rarityValue === 'epic') {
      if (name === 'mythic') {
        return key;
      }
    }

    if (rarityValue === name) {
      return key;
    }
  }

  return -1;
};
