import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import BaseIcon from 'src/components/icons/BaseIcon';
import LockIcon from 'src/components/icons/LockIcon';
import { Tooltip } from '@mui/material';
import Styled from 'src/components/data-display/NFTStatus.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const getTooltipCopyFromStatus = (status: string) => {
  switch (status) {
    case 'opening':
      return {
        primary: 'common.nft.locked.opening.primary',
        secondary: 'common.nft.locked.opening.secondary',
      };
    case 'transferring':
      return {
        primary: 'common.nft.locked.transferring.primary',
        secondary: 'common.nft.locked.transferring.secondary',
      };
    case 'in_dungeon':
      return {
        primary: 'common.nft.locked.in.dungeon.primary',
        secondary: 'common.nft.locked.in.dungeon.secondary',
      };
    case 'in_pvp_defense':
      return {
        primary: 'common.nft.locked.pvp.defense.primary',
        secondary: 'common.nft.locked.pvp.defense.secondary',
      };
    case 'in_pvp_offense':
      return {
        primary: 'common.nft.locked.pvp.offense.primary',
        secondary: 'common.nft.locked.pvp.offense.secondary',
      };
    default:
      return {
        primary: 'common.nft.locked.default.primary',
        secondary: '',
      };
  }
};

interface NFTStatusProps {
  nft: Listing | CollectionItem | Nonfungible;
  style?: React.CSSProperties;
}

const NFTStatus: FC<NFTStatusProps> = ({ nft, style = {} }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation(nameSpace.common);
  const status = nft?.statuses && nft?.statuses.length > 0 ? nft?.statuses[0] : '';
  const statusText = getTooltipCopyFromStatus(status);

  // Parse NFT data
  const isLocked = nft?.statuses && nft?.statuses.length > 0;

  if (!isLocked) return null;

  return (
    <Tooltip
      arrow
      title={
        <Styled.TooltipRoot>
          <Styled.TooltipPrimary>{t(statusText.primary)}</Styled.TooltipPrimary>

          {statusText.secondary && <Styled.TooltipSecondary>{t(statusText.secondary)}</Styled.TooltipSecondary>}
        </Styled.TooltipRoot>
      }
      componentsProps={{
        arrow: {
          sx: {
            color: customThemeOptions.custom.colors.Yellow,
          },
        },
        tooltip: {
          sx: {
            backgroundColor: '#222126',
            padding: '0.7rem',
            border: `1px solid ${customThemeOptions.custom.colors.Yellow}`,
            borderRadius: '0',
          },
        },
      }}
      // sx={{
      //   zIndex: '100000 !important',
      // }}
    >
      <Styled.Root style={style} onClick={() => setShowTooltip(!showTooltip)}>
        <BaseIcon
          hoverColor="#FEC300"
          cursor="pointer"
          icon={<LockIcon />}
          height={'16px'}
          padding="0"
          style={{
            display: 'inline-block',
            position: 'absolute',
            top: '57%',
            right: '50%',
            transform: 'translate(50%, -50%)',
          }}
        />
      </Styled.Root>
    </Tooltip>
  );
};

export default NFTStatus;
