import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { useQuery } from 'src/hooks/useQuery';
import { useLoadCollection } from 'src/hooks/useLoadCollection';
import { useAppSelector } from 'src/hooks/useAppSelector';
import PageError from 'src/components/feedback/PageError';
import Alert from 'src/components/feedback/Alert';
import Collectibles from 'src/components/collectibles/Collectibles';
import Styled from 'src/components/collection/Collection.styles';

interface CollectionProps {
  disablePageError?: boolean;
  style?: React.CSSProperties;
}

const Collection: FC<CollectionProps> = ({ disablePageError = false, style = {} }) => {
  const { loading, error } = useLoadCollection({ redirectOnError: false, fromContext: 'game_overlay_collection' });
  const params: (string | null)[] = useQuery(urlParams.profile);
  const fromPurchase = getURLParamValue('from_purchase', urlParams.profile, params);
  const [inView1, setInView1] = useState(false);
  const collectionItems = useAppSelector(({ collectionItems }) => collectionItems.collectionItems);
  const { t } = useTranslation(nameSpace.common);
  const packs = collectionItems.filter(item => item.type === 'pack' && !item.listed);
  const heroes = collectionItems.filter(item => item.type === 'hero' && !item.listed);

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;
  // If data loaded incorrectly return page error
  if (error && !disablePageError) {
    return (
      <PageError
        style={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', width: 'auto', height: 'auto' }}
      />
    );
  }

  return (
    <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated delay-2 ${inView1 ? 'fadeInUp' : ''}`} style={style}>
        {fromPurchase === 'true' && (
          <Alert severity={'info'} msg={t('common.message.purchase.delay')} style={{ marginBottom: '1.5rem' }} />
        )}

        {packs.length > 0 && (
          <>
            <Styled.CollectionTitle>{t('common.term.packs')}</Styled.CollectionTitle>

            <Collectibles collectibles={packs} style={{ marginTop: '0.8rem' }} condensed />
          </>
        )}

        {heroes.length > 0 && (
          <>
            <Styled.CollectionTitle>{t('common.term.heroes')}</Styled.CollectionTitle>

            <Collectibles collectibles={heroes} style={{ marginTop: '0.8rem' }} condensed />
          </>
        )}

        {packs.length === 0 && heroes.length === 0 && (
          <Styled.NoResults>{t('common.message.collection.no.results')}</Styled.NoResults>
        )}
      </Styled.Root>
    </Waypoint>
  );
};

export default Collection;
