import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/not-found/NotFound.styles';

const NotFound: FC = () => {
  const { t } = useTranslation(nameSpace.notFound);
  const navigateInternal = useAppNavigateInternal();

  return (
    <Styled.Root>
      <Styled.ContentContainer>
        <Styled.Heading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('notFound.title')) }} />
        <Styled.Subheading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('notFound.description')) }} />

        <ButtonHex
          hoverEffect
          variant="gold"
          width={150}
          height={50}
          title={t('notFound.action')}
          style={{ display: 'block', margin: '1.5rem auto 0 auto' }}
          onClick={() => navigateInternal('/home', { pooleData: { fromContext: 'page-not-found' } })}
        />
      </Styled.ContentContainer>
    </Styled.Root>
  );
};

export default NotFound;
