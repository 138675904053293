import { styled } from '@mui/material';

interface RootProps {
  size?: number;
}

const Root = styled('img')<RootProps>`
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  vertical-align: middle;
`;

export default { Root };
