import { styled } from '@mui/material';
import { isMobile, isSafari } from 'react-device-detect';

interface RootProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
}

const Root = styled('div')<RootProps>`
  background-color: ${({ colors }) => `${colors.gradientColor1}33`};
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  /* left: ${isMobile || isSafari ? '2.7rem' : '5.4rem'}; */
  left: 2.7rem;
  top: -3rem;
  /* border-radius: 0.3rem; */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ colors }) => colors.gradientColor1};

    & .material-icons-outlined {
      color: #ffffff;
    }
  }

  &:active {
    background-color: #222222;
  }

  & .material-icons-outlined {
    font-size: 1.2rem;
    color: ${({ colors }) => colors.gradientColor1};
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    height: 1.7rem;
    width: 1.7rem;
    top: -2.7rem;
    /* left: ${isMobile || isSafari ? '2.4rem' : '4.8rem'}; */
    left: 2.4rem;

    & .material-icons-outlined {
      font-size: 1rem;
    }
  }
`;

export default {
  Root,
};
