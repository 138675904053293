import { styled } from '@mui/material';

interface RootProps {
  linked: boolean;
}

const Root = styled('div')<RootProps>`
  display: flex;
  width: 100%;
  margin-top: ${({ linked }) => (linked ? '0.5rem' : '0.5rem')};
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0;

  & #discord-name {
    margin: ${({ linked }) => (linked ? '0 0.5rem 0 -2px' : '0 0 0 -2px')};
  }

  & #discord-status {
    margin: ${({ linked }) => (linked ? '0 0 0 0' : '0')};
  }
`;

const UsernameContainer = styled('div')`
  display: inline-flex;
  height: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  word-break: break-word;
`;

const Username = styled('span')`
  display: inline-block;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
  color: #ffffff99;
`;

const StatusContainer = styled('div')`
  display: inline-flex;
  height: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  word-break: break-word;
`;

interface StatusTextProps {
  linked: boolean;
}

const StatusText = styled('span')<StatusTextProps>`
  display: inline-block;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
  color: ${({ linked }) => (linked ? '#55ff55' : '#ff5555')};
  filter: brightness(0.8);
`;

const UnlinkButton = styled('div')`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.1rem;
  margin-left: 0.5rem;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.custom.colors.BgLayer1};

    & .material-icons,
    & .material-icons-outlined {
      color: #ff2222;
    }
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    color: #fff;
    transition: color 0.4s ease-in-out;
  }

  & > span:nth-of-type(2) {
    display: none;
  }

  @media (max-width: 500px) {
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.8rem;
      line-height: 1rem;
    }
    /* display: block;
    width: 100%;
    max-width: 9rem;
    padding: 0.3rem 0.5rem 0.2rem 0.5rem;
    margin-top: 1rem;
    text-align: center;

    & > span:nth-of-type(1) {
      margin-right: 0.4rem;
    }

    & > span:nth-of-type(2) {
      display: inline-block;
    } */
  }
`;

export default {
  Root,
  UsernameContainer,
  Username,
  StatusContainer,
  StatusText,
  UnlinkButton,
};
