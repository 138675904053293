import { styled } from '@mui/material';

interface RootProps {
  isDaily: boolean;
}

const Root = styled('div')<RootProps>`
  display: block;
  width: 100%;
  max-height: 10rem;
  margin-top: 1.5rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.2rem;
  font-style: italic;
  color: #ffffffcc;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;
  -webkit-mask-image: linear-gradient(to top, transparent 0%, #000 2rem, #000 100%);
  mask-image: linear-gradient(to top, transparent 0%, #000 2rem, #000 100%);

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 900px) {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  @media (max-height: 700px) {
    max-height: 7rem;
    margin-top: 0.8rem;
    font-size: 0.8rem;
    line-height: 1.1rem;
  }

  @media (max-height: 600px) {
    max-height: 4rem;
    font-size: 0.7rem;
    line-height: 0.9rem;
  }

  @media (max-height: 500px) {
    margin-top: 0.5rem;
  }

  @media (max-height: 450px) {
    -webkit-mask-image: linear-gradient(to top, transparent 0%, #000 1.5rem, #000 100%);
    mask-image: linear-gradient(to top, transparent 0%, #000 1.5rem, #000 100%);
    padding-bottom: 1.5rem;
    max-height: 3rem;
    margin-top: 0.4rem;
  }

  @media (max-height: 350px) {
    display: none;
  }
`;

export default {
  Root,
};
