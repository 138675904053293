import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface RootProps {
  showDiscordWidget: boolean;
}

const Root = styled('div')<RootProps>`
  background-color: ${({ theme }) => theme.custom.colors.Yellow};
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: rgba(254, 195, 0, 0.3) 0px 3px 5px -1px, rgba(254, 195, 0, 0.14) 0px 6px 10px 0px,
    rgba(254, 195, 0, 0.12) 0px 1px 18px 0px;
  position: fixed;
  right: ${({ showDiscordWidget }) => (showDiscordWidget ? '96px' : '20px')};
  bottom: 20px;
  z-index: ${customThemeOptions.custom.zIndex.debugWidget.toggleButton};

  & .material-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: #000000bb;
    transition: font-size 0.1s ease-in-out;

    &:active {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 500px) {
    border-bottom-right-radius: 1rem;
    right: ${({ showDiscordWidget }) => (showDiscordWidget ? '68px' : '0px')};
    bottom: 2px;
  }
`;

export default {
  Root,
};
