import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
`;

const BackgroundImage = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 19 / 10;
  position: absolute;
  bottom: 0;
`;

const BackgroundImageGradient = styled('div')`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 55rem;
  padding: 0 2rem 6rem 2rem;
  margin: 4rem auto 0 auto;
  box-sizing: border-box;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  position: relative;

  @media (max-width: 1200px) {
    margin: 2rem auto 0 auto;
  }

  @media (max-width: 500px) {
    margin: 1.5rem auto 0 auto;
    padding: 0 1.5rem 6rem 1.5rem;
  }
`;

const Content = styled('div')`
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #dddddd;

  & * {
    margin-top: 2rem;
    word-break: break-word;
  }

  &:nth-of-type() {
    margin-top: 0;
  }

  & strong {
    font-weight: 800;
    color: #ffffff;
  }

  & h1,
  & h2,
  & h3 {
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2rem;
    /* color: #ffffff; */
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  & h4,
  & h5,
  & h6 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.5rem;
    color: #ffffff;
  }

  & a {
    color: ${({ theme }) => theme.custom.colors.Yellow};

    &:hover {
      text-decoration: underline;
    }
  }

  & figure {
    margin: 2rem auto 0 auto;
  }

  & img {
    display: block;
    width: 100%;
    /* max-width: 40rem; */
    border-radius: 0.2rem;
    box-shadow: 0 0 10px 7px #ffffff0a;
  }

  & > img:last-of-type {
    display: none;
  }

  & iframe {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
    border: none;
    outline: none;
  }
`;

const Separator = styled('div')`
  background-color: #555555;
  display: block;
  width: 100%;
  height: 2px;
  margin: 2rem 0 1.2rem 0;
`;

const TagsContainer = styled('div')`
  display: inline-block;
  width: calc(100% - 162px - 2rem);
  vertical-align: top;

  & > div:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  @media (max-width: 500px) {
    display: block;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
`;

const Tag = styled('div')`
  background-color: #555555;
  display: inline-block;
  padding: 0.3rem 0.8rem;
  margin-top: 0.7rem;
  border-radius: 0.7rem;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: #dddddd;
`;

const ButtonsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 6rem;
  text-align: center;

  & > button:last-of-type {
    margin-left: 1rem;
  }
`;

export default {
  Root,
  BackgroundImage,
  BackgroundImageGradient,
  CenteredContainer,
  Content,
  Separator,
  TagsContainer,
  Tag,
  ButtonsContainer,
};
