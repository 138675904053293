import { FC, useEffect, useRef, useState } from 'react';
import LineTextureBackground from 'src/components/backgrounds/LineTextureBackground';
import Styled from 'src/components/backgrounds/PrimaryModalBackground.styles';
import useViewportContext from 'src/hooks/useViewportContext';

interface PrimaryModalBackgroundProps {
  disableLineTexture?: boolean;
  stroke?: string;
  style?: React.CSSProperties;
}

const PrimaryModalBackground: FC<PrimaryModalBackgroundProps> = ({
  disableLineTexture = false,
  stroke = '#6FB6E766',
  style = {},
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { viewportWidth, viewportHeight } = useViewportContext();
  const [size, setSize] = useState<number>(0);

  // Listen for viewport changes and update the size of the element
  useEffect(() => {
    if (modalRef?.current) {
      const { clientWidth = 0, clientHeight = 0 } = modalRef.current;

      setSize(Math.max(clientWidth, clientHeight));
    }
  }, [viewportWidth, viewportHeight]);

  return (
    <Styled.Root ref={modalRef} style={style}>
      {!disableLineTexture && <LineTextureBackground size={size} stroke={stroke} strokeWidth={0.1} />}
    </Styled.Root>
  );
};

export default PrimaryModalBackground;
