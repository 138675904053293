export const getServerGameTime = () => {
  const offsetString = sessionStorage.getItem('game_time_offset');

  if (offsetString) {
    const offset = Number(offsetString);
    return Date.now() + offset;
  }

  return Date.now();
};
