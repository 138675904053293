/* eslint-disable @typescript-eslint/no-empty-function */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import ButtonHex from 'src/components/input/ButtonHex';
import BaseIcon from 'src/components/icons/BaseIcon';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
// import { logMain } from 'src/modules/logger/logger';
// import Styled from 'src/components/input/ButtonDiscordJoin.styles';

export interface ButtonDiscordJoinProps {
  variant?: 'bronze' | 'silver' | 'gold' | 'white';
  width?: number;
  height?: number;
  cornerOffset?: number;
  onClick: () => void;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const ButtonDiscordJoin: FC<ButtonDiscordJoinProps> = ({
  variant = 'white',
  width = 190,
  height = 61,
  cornerOffset = 17,
  onClick = () => {},
  style = {},
  textStyle = {},
}) => {
  const { t } = useTranslation(nameSpace.common);

  return (
    <ButtonHex
      hoverEffect
      type="button"
      variant={variant}
      width={width}
      height={height}
      cornerOffset={cornerOffset}
      fontWeight={700}
      title={t('common.discord.join')}
      textStyle={{ ...textStyle, margin: '2px 0 0 0' }}
      style={style}
      icon={
        <BaseIcon
          cursor="pointer"
          fill={variant === 'gold' || variant === 'bronze' ? '#fff' : '#000'}
          // hoverColor="#FEC300"
          icon={<DiscordSocialIcon />}
          height="33px"
          style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '6px' }}
        />
      }
      onClick={onClick}
    />
  );
};

export default ButtonDiscordJoin;
