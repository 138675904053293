import { styled } from '@mui/material';

const Root = styled('div')`
  background-image: url('/assets/lhu_bkg_004.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  padding: 4.5rem 2rem;
  box-sizing: border-box;

  & > div:nth-of-type(n + 2) {
    margin-top: 3rem;
  }
`;

const Row = styled('div')`
  display: block;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
`;

const RowContentContainer = styled('div')`
  display: inline-block;
  vertical-align: middle;
  width: 49%;
  padding: 0 4rem;
  box-sizing: border-box;

  @media (max-width: 900px) {
    padding: 0 2rem;
  }

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    max-width: 25rem;
    padding: 0 2rem;
    margin: 0 auto;
  }
`;

const ContentTitle = styled('p')`
  display: block;
  width: 100%;
  word-break: break-word;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const ContentBody = styled('p')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  text-align: left;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
  color: #000000;
`;

const RowImageContainer = styled('div')`
  display: inline-block;
  vertical-align: middle;
  width: 51%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    max-width: 30rem;
    margin: 3rem auto 0 auto;
  }
`;

const RowImage = styled('img')`
  display: block;
  width: 100%;
  height: auto;
`;

export default {
  Root,
  Row,
  RowContentContainer,
  ContentTitle,
  ContentBody,
  RowImageContainer,
  RowImage,
};
