import { FC, useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { randomIntFromInterval } from 'src/tools/randomIntFromInterval';
import { getServerGameTime } from 'src/tools/getGameTime';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getFeaturedDropEvent } from 'src/redux/slices/drop-events/drop-events';
import ResponsivePicture from 'src/components/media/ResponsivePicture';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/events/components/SectionHero.styles';
import { config } from 'src/config/config';

type Hero = {
  id: number;
  title: string;
  color: string;
  textColor: string;
  path: string;
};

const SectionHero: FC = () => {
  const [timeInMilli, setTimeInMilli] = useState<number>(getServerGameTime());
  const featuredDropEvent = useAppSelector(state => getFeaturedDropEvent(state));
  const navigateInternal = useAppNavigateInternal();
  const { t: tEvents } = useTranslation(nameSpace.events);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const heroes = tEvents<string, Hero[]>('events.hero.assets', { returnObjects: true });
  const randomHeroIndex = useRef(randomIntFromInterval(0, 2));
  const hero = heroes[randomHeroIndex.current];
  const { path, title, textColor } = hero;
  const featuredDropEventId = featuredDropEvent ? featuredDropEvent.id : '';
  const featuredDropEventType = featuredDropEvent ? featuredDropEvent.type : '';
  const eventStarted = featuredDropEvent && timeInMilli >= featuredDropEvent.startTimestampMs;
  const eventEnded = featuredDropEvent && timeInMilli >= featuredDropEvent.closeTimestampMs;

  useEffect(() => {
    const interval = setInterval(() => setTimeInMilli(getServerGameTime()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleGoToEvent = (path: string) => {
    if (config.app_variables.force_in_game_purchases_only) {
      navigateInternal('/play', { pooleData: { fromContext: 'events' } });
    } else {
      navigateInternal(path, { pooleData: { fromContext: 'events' } });
    }
  };

  const handleGoToHome = () => {
    navigateInternal('/home', { pooleData: { fromContext: 'events' } });
  };

  return (
    <Styled.Root>
      <ResponsivePicture src={`/assets/${path}`} srcMobile={`/assets/mobile_${path}`} alt={title} height={'100%'} />

      <Styled.Overlay className={'inview animated fadeIn'}>
        {featuredDropEvent ? (
          <>
            <Styled.Heading
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tEvents('events.hero.heading')) }}
              color={textColor}
            />

            <Styled.Subheading
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tEvents('events.hero.subheading')) }}
              color={textColor}
            />

            <ButtonHex
              hoverEffect
              variant="white"
              width={170}
              title={
                eventStarted && !eventEnded
                  ? tCommon('common.event.button.mint')
                  : tCommon('common.event.button.details')
              }
              textStyle={{ textTransform: 'uppercase' }}
              style={{ margin: '1.7rem 0 0 0' }}
              onClick={() => handleGoToEvent(`/event/${featuredDropEventType}/${featuredDropEventId}`)}
            />
          </>
        ) : (
          <>
            <Styled.Heading
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tEvents('events.hero.heading.no.events')) }}
              color={textColor}
            />

            <Styled.Subheading
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tEvents('events.hero.subheading.no.events')) }}
              color={textColor}
            />

            <ButtonHex
              hoverEffect
              variant="white"
              width={170}
              title={tCommon('common.page.error.action')}
              textStyle={{ textTransform: 'uppercase' }}
              style={{ margin: '1.7rem 0 0 0' }}
              onClick={handleGoToHome}
            />
          </>
        )}
      </Styled.Overlay>
    </Styled.Root>
  );
};

export default SectionHero;
