import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  background-color: #000000aa;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  box-sizing: border-box;
  z-index: ${customThemeOptions.custom.zIndex.gameOverlay.blurb};
`;

const BlurbContainer = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer3};
  display: block;
  width: 100%;
  padding: 2rem 4rem 3rem 4rem;
  box-sizing: border-box;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  filter: brightness(1.1);

  & .material-icons-outlined,
  & .material-icons {
    display: inline-block;
    font-size: 2.6rem;
    font-weight: 200;
    color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
    filter: brightness(1.3);
  }

  @media (max-height: 500px) {
    padding: 1.9rem 2rem 2.5rem 2rem;

    & .material-icons-outlined,
    & .material-icons {
      font-size: 2rem;
    }
  }

  @media (max-height: 300px) {
    padding: 1.5rem 2rem 2rem 2rem;

    & .material-icons-outlined,
    & .material-icons {
      font-size: 1.6rem;
    }
  }
`;

const BlurbCopy = styled('div')`
  display: block;
  width: 100%;
  max-width: 45rem;
  margin: 1rem auto 0 auto;
  box-sizing: border-box;
  text-align: center;
  /* font-family: 'Kevlar Underwear'; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
  filter: brightness(1.4);

  & > div {
    margin-top: 0.5rem;
  }

  @media (max-height: 500px) {
    margin-top: 0.7rem;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }

  @media (max-height: 300px) {
    margin-top: 0.3rem;
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
`;

export default { Root, BlurbContainer, BlurbCopy };
