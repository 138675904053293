import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-block;
  margin-bottom: -0.1rem;
  color: #ffffff;

  & span:first-of-type {
    margin-right: 0.3rem;
    font-family: 'Kevlar Underwear';
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  & span:last-of-type {
    margin-right: 0.3rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.1rem;
  }

  @media (max-width: 900px) {
    & span:first-of-type {
      font-size: 1rem;
      line-height: 1rem;
    }

    & span:last-of-type {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 700px) {
    & span:first-of-type {
      font-size: 1rem;
      line-height: 1rem;
    }

    & span:last-of-type {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 500px) {
    & span:first-of-type {
      font-size: 1rem;
      line-height: 1rem;
    }

    & span:last-of-type {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 450px) {
    & span:first-of-type {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }

    & span:last-of-type {
      font-size: 0.6rem;
      line-height: 0.8rem;
    }
  }

  /* @media (max-height: 350px) {
    & span:first-of-type {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }

    & span:last-of-type {
      font-size: 0.6rem;
      line-height: 0.8rem;
    }
  } */
`;

export default {
  Root,
};
