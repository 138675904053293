import { FC, useRef } from 'react';
import useTutorialHeroName from 'src/hooks/useTutorialHeroName';
import NameBackground from 'src/components/tutorial-overlay/NameBackground';
import Styled from 'src/components/tutorial-overlay/Name.styles';
import { useElementDimensions } from 'src/hooks/useElementDimensions';

interface NameProps {
  style?: React.CSSProperties;
}

const Name: FC<NameProps> = ({ style = {} }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { name } = useTutorialHeroName();
  const { width, height } = useElementDimensions({ element: rootRef });

  return (
    <Styled.Root ref={rootRef} style={style}>
      <NameBackground width={width} height={height} />
      <Styled.Name>{name}</Styled.Name>
    </Styled.Root>
  );
};

export default Name;
