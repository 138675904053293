import isValidBlobUrl from 'src/tools/isValidBlobUrl';
import { logMain } from 'src/modules/logger/logger';

const isActiveBlobUrl = async (url: string | null) => {
  if (!isValidBlobUrl(url)) return false; // URL format is not valid

  try {
    const response = await fetch(url as string);

    if (response.ok) {
      const blob = await response.blob(); // Gets the Blob from the response

      return blob.size > 0; // Checks if the Blob has content
    }

    return false; // The fetch was not successful, indicating the URL is not valid
  } catch (error) {
    logMain.debug('[BLOB]: Blob URL is inaccessible or has been revoked', { url, error });

    return false; // An error occurred, likely the URL has been revoked or is inaccessible
  }
};

export default isActiveBlobUrl;
