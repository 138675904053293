/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import WebMetricsContext, { initialContext, IWebMetricsContext } from 'src/context/web-metrics/WebMetrics.context';
// import { logMain } from 'src/modules/logger/logger';

interface WebMetricsProviderProps {
  children: ReactNode;
}

const WebMetricsProvider: FC<WebMetricsProviderProps> = ({ children }) => {
  const intervalRef = useRef<any | null>(null);
  const [enableWebMetrics, setEnableWebMetrics] = useState<IWebMetricsContext['enableWebMetrics']>(
    initialContext.enableWebMetrics,
  );
  const [memoryUsed, setMemoryUsed] = useState<IWebMetricsContext['memoryUsed']>(initialContext.memoryUsed);
  const [memoryTotal, setMemoryTotal] = useState<IWebMetricsContext['memoryTotal']>(initialContext.memoryTotal);
  const [memoryLimit, setMemoryLimit] = useState<IWebMetricsContext['memoryLimit']>(initialContext.memoryLimit);
  const [snapshots, setSnapshots] = useState<IWebMetricsContext['snapshots']>(initialContext.snapshots);

  const clearSnapshots = useCallback(() => setSnapshots([]), []);

  useEffect(() => {
    const updateMemoryUsage = () => {
      if (window?.performance && window?.performance?.memory) {
        const { memory } = window.performance;
        const memUsed = (memory.usedJSHeapSize / 1048576).toFixed(2);
        const memTotal = (memory.totalJSHeapSize / 1048576).toFixed(2);
        const memLimit = (memory.jsHeapSizeLimit / 1048576).toFixed(2);

        setMemoryUsed(memUsed);
        setMemoryTotal(memTotal);
        setMemoryLimit(memLimit);
      } else {
        setMemoryUsed('');
        setMemoryTotal('');
        setMemoryLimit('');
      }
    };

    if (enableWebMetrics) {
      intervalRef.current = setInterval(updateMemoryUsage, 500);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [enableWebMetrics]);

  const value = useMemo(
    () => ({
      clearSnapshots,
      enableWebMetrics,
      setEnableWebMetrics,
      memoryUsed,
      setMemoryUsed,
      memoryTotal,
      setMemoryTotal,
      memoryLimit,
      setMemoryLimit,
      snapshots,
      setSnapshots,
    }),
    [
      clearSnapshots,
      enableWebMetrics,
      setEnableWebMetrics,
      memoryUsed,
      setMemoryUsed,
      memoryTotal,
      setMemoryTotal,
      memoryLimit,
      setMemoryLimit,
      snapshots,
      setSnapshots,
    ],
  );

  return <WebMetricsContext.Provider value={value}>{children}</WebMetricsContext.Provider>;
};

export default WebMetricsProvider;
