import { FC } from 'react';
import Styled from 'src/components/backgrounds/DecorativeBackgroundPrimary.styles';

interface DecorativeBackgroundPrimaryProps {
  hideBorder?: boolean;
  style?: React.CSSProperties;
}

const DecorativeBackgroundPrimary: FC<DecorativeBackgroundPrimaryProps> = ({ hideBorder = false, style = {} }) => {
  return (
    <Styled.Root hideBorder={hideBorder} style={style}>
      <Styled.Gradient />
      <Styled.Background />
    </Styled.Root>
  );
};

export default DecorativeBackgroundPrimary;
