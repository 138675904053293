/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import Styled from 'src/components/game-overlay/BorderMask.styles';

interface BorderMaskProps {
  id?: string;
  width: number;
  height: number;
  style?: React.CSSProperties;
}

const BorderMask: FC<BorderMaskProps> = ({ id = 'game-overlay-border-mask', width, height, style = {} }) => {
  const point1 = `12 0`;
  const point2 = `${width - 12} 0`;
  const point3 = `${width} 12`;
  const point4 = `${width} 66`;
  const point5 = `${width - 12} 78`;
  const point6 = `${width - 12} ${height - 79}`;
  const point7 = `${width} ${height - 67}`;
  const point8 = `${width} ${height - 12}`;
  const point9 = `${width - 12} ${height}`;
  const point10 = `12 ${height}`;
  const point11 = `0 ${height - 12}`;
  const point12 = `0 ${height - 66}`;
  const point13 = `12 ${height - 78}`;
  const point14 = `12 78`;
  const point15 = `0 66`;
  const point16 = `0 12`;

  return (
    <Styled.Root
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      style={style}
    >
      <defs>
        <clipPath id="game-overlay-clip-path">
          <path
            d={`M${point1} L${point2} L${point3} L${point4} L${point5} L${point6} L${point7} L${point8} L${point9} L${point10} L${point11} L${point12} L${point13} L${point14} L${point15} L${point16} L${point1} Z`}
          />
        </clipPath>
      </defs>

      {/* <path
        strokeWidth={0}
        stroke={'#ffffff'}
        fill={'#00ff00'}
        d={`M${point1} L${point2} L${point3} L${point4} L${point5} L${point6} L${point7} L${point8} L${point9} L${point10} L${point11} L${point12} L${point13} L${point14} L${point15} L${point16} L${point1} Z`}
      /> */}
    </Styled.Root>
  );
};

export default BorderMask;
