/**
 * Do not edit directly
 * Generated on Wed, 01 Jun 2022 13:46:22 GMT
 */

export const BorderRadiusRadiusScale = 2;
export const BorderRadiusRadiusNone = 0;
export const BorderRadiusRadiusSm = 2;
export const BorderRadiusRadiusBase = 4;
export const BorderRadiusRadiusMd = 6;
export const BorderRadiusRadiusLg = 8;
export const BorderRadiusRadiusXl = 12;
export const BorderRadiusRadius2Xl = 16;
export const BorderRadiusRadius3Xl = 24;
export const BorderRadiusRadiusFull = 9999;
