import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  padding: 0 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FarcasterLogo = styled('img')`
  display: block;
  width: 4rem;
  height: auto;
  margin: 0 auto;
  border-radius: 0.5rem;

  @media (max-width: 500px) or (max-height: 500px) {
    width: 3rem;
  }
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  max-width: 45rem;
  margin: 1.5rem auto 0 auto;
  text-align: center;
  font-family: 'Kevlar Underwear';
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: #fff;

  & span {
    color: #865fca;
  }

  @media (max-width: 500px) or (max-height: 500px) {
    margin: 1.2rem auto 0 auto;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  width: 100%;
  max-width: 30rem;
  margin: 1.3rem auto 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
  color: #fff;

  & span {
    color: #865fca;
  }

  @media (max-width: 500px) or (max-height: 500px) {
    margin: 1rem auto 0 auto;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
`;

export default { Root, ContentContainer, FarcasterLogo, Heading, Subheading };
