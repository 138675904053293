/* eslint-disable @typescript-eslint/no-explicit-any */
import { getLeaderboardConfig } from 'src/api/leaderboards/get-leaderboard-config';
import { getLeaderboard } from 'src/api/leaderboards/get-leaderboard';
import { getLeaderboardStats } from 'src/api/leaderboards/get-leaderboard-stats';
import { clearLeaderboards, setLeaderboards } from 'src/redux/slices/leaderboards/leaderboards';
import {
  addLeaderboardItems,
  clearLeaderboardItems,
  setLeaderboardItems,
} from 'src/redux/slices/leaderboard-items/leaderboard-items';
import type { AppDispatch } from 'src/redux/store';
import { logMain } from 'src/modules/logger/logger';

const loadLeaderboard = async (
  dispatch: AppDispatch,
  leaderboardId: string,
  setResultsFound: (resultsFound: boolean) => void,
  pageNumber?: number,
) => {
  const isLoadingMore = typeof pageNumber !== 'undefined' && pageNumber > 0;

  if (!isLoadingMore) {
    await dispatch(clearLeaderboards());
    await dispatch(clearLeaderboardItems());
  }

  try {
    const leaderboardConfig = await dispatch(getLeaderboardConfig());
    const leaderboards = leaderboardConfig?.leaderboards || [];

    // Get leaderboard stats and save leaderboards if they exist
    if (leaderboards.length > 0) {
      const selectedLeaderboard = await dispatch(
        getLeaderboard({ urlParams: { leaderboardId }, queryParams: { page_num: pageNumber || 0 } }),
      );
      const leaderboardItemsForRedux =
        selectedLeaderboard?.positions?.map((position: any) => {
          return {
            ...position,
            leaderboardId,
            info: { ...position.info },
          };
        }) || [];
      const selectedLeaderboardStats = await dispatch(
        getLeaderboardStats({ queryParams: { leaderboard_ids: leaderboardId } }),
      );
      const leaderboardsForRedux: any[] = leaderboardConfig.leaderboards.map((leaderboard: any) => {
        const stats =
          selectedLeaderboardStats?.stats.find(
            (leaderboardStat: any) => leaderboardStat.leaderboardId === leaderboardId,
          ) || {};
        const isCurrentLeaderboard = leaderboard.configId === leaderboardId;

        return {
          ...leaderboard,
          ...stats,
          closeTimestampMs: isCurrentLeaderboard ? selectedLeaderboard.closeTimestampMs : null,
          pagination: isCurrentLeaderboard
            ? { ...selectedLeaderboard?.pagination }
            : {
                nextPageToken: null,
                pageCount: 1,
                pageNumber: 0,
                prevPageToken: null,
              },
          totalPlayers: isCurrentLeaderboard ? selectedLeaderboard.totalPlayers : null,
        };
      });

      dispatch(setLeaderboards(leaderboardsForRedux));

      if (!isLoadingMore) {
        dispatch(setLeaderboardItems(leaderboardItemsForRedux));
      } else {
        dispatch(addLeaderboardItems(leaderboardItemsForRedux));
      }

      if (selectedLeaderboard?.positions?.length > 0) {
        setResultsFound(true);
      } else {
        setResultsFound(false);
      }
    }
  } catch (e: any) {
    logMain.debug(e);
  }
};

export default loadLeaderboard;
