import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const RotateScreenOuterContainer = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer3};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ theme }) => `3px solid ${theme.custom.colors.BgLayer3Accent}aa`};
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: ${customThemeOptions.custom.zIndex.rotateScreenOverlay};

  &.device-orientation-enter {
    opacity: 0;
  }

  &.device-orientation-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  &.device-orientation-exit {
    opacity: 1;
  }

  &.device-orientation-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
`;

const RotateScreenInnerContainer = styled('div')`
  /* background-color: #080a12; */
  display: inline-block;
  max-width: 15rem;
  padding: 2rem;
  border-radius: 1rem;
  box-sizing: border-box;
  text-align: center;
  position: relative;

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    font-size: 4rem;
    /* color: #fff; */
    color: ${({ theme }) => theme.custom.colors.BgLayer3Font};
  }
`;

const RotateScreenTitle = styled('p')`
  /* background-color: #080a12; */
  display: block;
  margin-top: 0.5rem;
  box-sizing: border-box;
  /* text-transform: uppercase; */
  font-family: 'Kevlar Underwear';
  text-align: center;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Font};
`;

export default {
  RotateScreenOuterContainer,
  RotateScreenInnerContainer,
  RotateScreenTitle,
};
