import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  display: inline-flex;
  height: 3rem;
  width: auto;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 0.9rem);
  left: 50%;
  z-index: ${customThemeOptions.custom.zIndex.gameOverlay.balances};
  transform: translate(-50%, -50%);

  @media (max-height: 700px) or (max-width: 1000px) {
    height: 2rem;
  }

  /* @media (max-width: 1000px) {
    height: 2rem;
  } */
`;

const BalancesContainer = styled('div')`
  /* background-color: red; */
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  @media (max-height: 700px) or (max-width: 1000px) {
    padding: 0 0.9rem;
  }

  /* @media (max-width: 1000px) {
    padding: 0 0.9rem;
  } */
`;

const Balance = styled('div')`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const BalanceValue = styled('span')`
  display: inline-block;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: #ffffffff;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */

  @media (max-height: 700px) or (max-width: 1000px) {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }

  /* @media (max-width: 1000px) {
    font-size: 0.9rem;
    line-height: 0.9rem;
  } */
`;

// const BalanceLabel = styled('div')`
//   display: inline-block;
//   text-transform: uppercase;
//   font-size: 0.6rem;
//   font-weight: 300;
//   line-height: 11px;
//   color: #ffffff;
//   /* color: ${({ theme }) => theme.custom.colors.Yellow}; */

//   @media (max-width: 1000px) {
//   }
// `;

const Separator = styled('div')`
  display: inline-flex;
  width: 6px;
  height: 6px;
  border: 1px solid #ffffff55;
  margin: 1px 0.2rem 0 0.2rem;
  transform: rotate(45deg);

  @media (max-height: 700px) or (max-width: 1000px) {
    width: 4px;
    height: 4px;
  }
`;

export default {
  Root,
  BalancesContainer,
  Balance,
  BalanceValue,
  // BalanceLabel,
  Separator,
};
