import { FC } from 'react';
import DOMPurify from 'dompurify';
import Name from 'src/components/tutorial-overlay//Name';
import Styled from 'src/components/tutorial-overlay/Dialog.styles';

interface DialogProps {
  dialog: string;
  style?: React.CSSProperties;
}

const Dialog: FC<DialogProps> = ({ dialog, style = {} }) => {
  return (
    <Styled.Root
      style={style}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0, transition: { type: 'tween', duration: 0.2, delay: 0 } }}
      // transition={{ duration: 0.6 }}
    >
      <Name />

      <Styled.DialogContainer>
        <Styled.Dialog dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dialog) }} />
      </Styled.DialogContainer>

      <Styled.NextArrow
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, repeat: Infinity, repeatType: 'reverse' }}
      >
        <span className="material-icons">play_arrow</span>
      </Styled.NextArrow>
    </Styled.Root>
  );
};

export default Dialog;
