import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyNonfungibles } from 'src/redux/slices/nonfungibles/helpers/copy-nonfungibles';

// Define the initial state using that type
const initialState: NonfungiblesState = {
  nonfungibles: [],
};

const nonfungiblesSlice = createSlice({
  name: 'nonfungibles',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setNonfungibles: {
      reducer(state, action: PayloadAction<NonfungiblesPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.nonfungibles = copyNonfungibles(action.payload.nonfungibles);
      },
      prepare(nonfungibles: Nonfungible[]) {
        return {
          payload: {
            nonfungibles,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    openPackNonfungible: {
      reducer(state, action: PayloadAction<{ id: string }>) {
        const existingItems = copyNonfungibles(state.nonfungibles);
        const listedItemIndex = existingItems.findIndex(item => action.payload.id === item.id);

        if (listedItemIndex !== -1) {
          const currentItem = existingItems[listedItemIndex];

          existingItems[listedItemIndex].packOpenable = currentItem.type === 'pack' ? false : null;
          existingItems[listedItemIndex].listable = false;
        }

        // eslint-disable-next-line no-param-reassign
        state.nonfungibles = existingItems;
      },
      prepare(id: string) {
        return {
          payload: {
            id,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    listNonfungible: {
      reducer(state, action: PayloadAction<{ id: string; price: number }>) {
        const existingItems = copyNonfungibles(state.nonfungibles);
        const listedItemIndex = existingItems.findIndex(item => action.payload.id === item.id);

        if (listedItemIndex !== -1) {
          existingItems[listedItemIndex].listingId = null;
          existingItems[listedItemIndex].listable = false;
          existingItems[listedItemIndex].listed = true;
          existingItems[listedItemIndex].price = action.payload.price;

          if (existingItems[listedItemIndex].type === 'pack') {
            existingItems[listedItemIndex].packOpenable = false;
          }
        }

        // eslint-disable-next-line no-param-reassign
        state.nonfungibles = existingItems;
      },
      prepare(id: string, price: number) {
        return {
          payload: {
            id,
            price,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    removeListedNonfungible: {
      reducer(state, action: PayloadAction<{ id: string }>) {
        const existingItems = copyNonfungibles(state.nonfungibles);
        const listedItemIndex = existingItems.findIndex(item => action.payload.id === item.id);

        if (listedItemIndex !== -1) {
          existingItems[listedItemIndex].listingId = null;
          existingItems[listedItemIndex].listable = true;
          existingItems[listedItemIndex].listed = false;
          existingItems[listedItemIndex].price = null;

          if (existingItems[listedItemIndex].type === 'pack') {
            existingItems[listedItemIndex].packOpenable = true;
          }
        }

        // eslint-disable-next-line no-param-reassign
        state.nonfungibles = existingItems;
      },
      prepare(id: string) {
        return {
          payload: {
            id,
          },
        };
      },
    },
    clearNonfungibles: state => {
      // eslint-disable-next-line no-param-reassign
      state.nonfungibles = initialState.nonfungibles;
    },
  },
});

export const { setNonfungibles, openPackNonfungible, listNonfungible, removeListedNonfungible, clearNonfungibles } =
  nonfungiblesSlice.actions;

// Other Selectors
export const getNonfungiblesBySeries = (state: RootState, seriesId: string): Nonfungible[] => {
  if (!seriesId) return [];

  return state.nonfungibles.nonfungibles.filter(nonfungible => nonfungible.seriesId === seriesId);
};

// Other Selectors
export const getNonfungibleBySeriesAndSerialNumber = (
  state: RootState,
  seriesId: string,
  serialNumber: number,
): Nonfungible | null => {
  if (!seriesId) return null;
  if ((!serialNumber && serialNumber !== 0) || serialNumber < 0) return null;

  return (
    state.nonfungibles.nonfungibles.find(
      nonfungible => nonfungible.seriesId === seriesId && nonfungible.serialNumber === serialNumber,
    ) || null
  );
};

export default nonfungiblesSlice.reducer;
