import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getLoadedProfileByUsernameOrPlayerId } from 'src/redux/slices/loaded-profiles/loaded-profiles';
import { getProfileImage } from 'src/tools/getProfileImage';
import { useMediaQuery, useTheme } from '@mui/material';
import ParticlesOverlay from 'src/components/vfx/ParticlesOverlay';
import Styled from 'src/components/pages/profile/components/SectionHero.styles';

type Hero = {
  path: string;
};

const SectionHero: FC = () => {
  const { playerId = '' } = useParams();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const loadedProfile = useAppSelector(state => getLoadedProfileByUsernameOrPlayerId(state, playerId));
  const { t } = useTranslation(nameSpace.profile);
  const hero: Hero = t('profile.hero.asset', { returnObjects: true });
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const profile = isCurrentProfile ? currentProfile : loadedProfile;

  return (
    <Styled.Root style={{ backgroundImage: `url(/assets/${mobileView ? 'mobile_' : ''}${hero.path})` }}>
      <ParticlesOverlay id={'profile-green'} color={'green'} number={150} sizes={{ min: 1, max: 5 }} />

      <Styled.CenteredContainer>
        <Styled.ProfilePictureOuterContainer>
          <Styled.ProfilePictureInnerContainer>
            <Styled.ProfilePicture
              style={{ backgroundImage: `url(${getProfileImage(profile?.discordUser?.avatarUrl || '')})` }}
            />
          </Styled.ProfilePictureInnerContainer>
        </Styled.ProfilePictureOuterContainer>
      </Styled.CenteredContainer>
    </Styled.Root>
  );
};

export default SectionHero;
