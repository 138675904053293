import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import useLegalNoticeContext from 'src/hooks/useLegalNoticeContext';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import ErrorMessage from 'src/components/feedback/ErrorMessage';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/modals/LegalNoticeModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

type LegalNoticeBannerVariant = 'terms' | 'privacy' | 'both';

const getPopupText = (legalNoticeBannerVariant: LegalNoticeBannerVariant) => {
  switch (legalNoticeBannerVariant) {
    case 'both':
      return {
        title: 'legal.notice.popup.both.title',
        body: 'legal.notice.popup.both.body',
        secondary: 'legal.notice.popup.both.secondary',
      };
    case 'terms':
      return {
        title: 'legal.notice.popup.terms.title',
        body: 'legal.notice.popup.terms.body',
        secondary: 'legal.notice.popup.terms.secondary',
      };
    case 'privacy':
      return {
        title: 'legal.notice.popup.privacy.title',
        body: 'legal.notice.popup.privacy.body',
        secondary: 'legal.notice.popup.privacy.secondary',
      };
    default:
      return {
        title: 'legal.notice.popup.both.title',
        body: 'legal.notice.popup.both.body',
        secondary: 'legal.notice.popup.both.secondary',
      };
  }
};

type DocumentID = 'terms_of_service' | 'privacy_policy';

const getLegalDocumentName = (documentId: DocumentID) => {
  switch (documentId) {
    case 'terms_of_service':
      return 'common.nav.terms';
    case 'privacy_policy':
      return 'common.nav.privacy';
    default:
      return '';
  }
};

interface LegalNoticeModalProps {
  style?: React.CSSProperties;
}

const LegalNoticeModal: FC<LegalNoticeModalProps> = ({ style = {} }) => {
  const { showLegalNoticePopup, legalNoticePopupVariant, setSubmitLegalNoticeAgree, legalSubmissionError } =
    useLegalNoticeContext();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tLegal } = useTranslation(nameSpace.legal);
  const navigateExternal = useAppNavigateExternal();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const popupText = getPopupText(legalNoticePopupVariant);
  const legalStates = currentProfile?.features?.legal?.states || [];
  const filteredLegalStates = legalStates.filter(state => state.requiredAction === 'agree');

  const handleAgree = () => {
    setSubmitLegalNoticeAgree(true);
  };

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={showLegalNoticePopup}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.legalNotice}` }}
    >
      <Fade in={showLegalNoticePopup}>
        <Styled.Root style={style}>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            <Styled.Heading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tLegal(popupText.title)) }} />

            <ErrorMessage msg={legalSubmissionError} style={{ margin: '2rem 0 0 0' }} />

            <Styled.SubheadingsContainer>
              <Styled.Subheading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tLegal(popupText.body)) }} />
            </Styled.SubheadingsContainer>

            {filteredLegalStates.length > 0 && (
              <Styled.LinksContainer>
                {filteredLegalStates.map((state, index) => {
                  const documentName = getLegalDocumentName(state.document);

                  if (!documentName) return null;

                  return (
                    <Styled.Link
                      key={`legal-state-${index}`}
                      onClick={() => {
                        navigateExternal(state.url, {
                          newWindow: true,
                          pooleData: { pageName: documentName, fromContext: 'legal_notice_popup' },
                        });
                      }}
                    >
                      {tCommon(documentName)}
                    </Styled.Link>
                  );
                })}
              </Styled.LinksContainer>
            )}

            <Styled.Note dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tLegal(popupText.secondary)) }} />

            <Styled.ButtonsContainer>
              <ButtonHex
                hoverEffect
                variant="gold"
                width={mobileView ? 225 : 200}
                height={45}
                title={tCommon('common.term.agree')}
                style={{
                  display: mobileView ? 'block' : 'inline-block',
                  margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                }}
                onClick={handleAgree}
              />
            </Styled.ButtonsContainer>
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default LegalNoticeModal;
