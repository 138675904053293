/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCurrentProfileCollection } from 'src/api/profile/get-current-profile-collection';
import { getProfileCollection } from 'src/api/profile/get-profile-collection';
import { logMain } from 'src/modules/logger/logger';
import { clearCollectionItems, setCollectionItems } from 'src/redux/slices/collection-items/collection-items';

interface LoadCollectionParams {
  dispatch: any;
  currentProfileId: string;
  currentProfileUsername: string;
  playerId: string;
}

const loadCollection = async ({
  dispatch,
  currentProfileId,
  currentProfileUsername,
  playerId = '',
}: LoadCollectionParams) => {
  const isCurrentProfile = !playerId || playerId === currentProfileId || playerId === currentProfileUsername;

  logMain.debug('Loading collection...');

  try {
    const collectionData = await dispatch(
      isCurrentProfile ? getCurrentProfileCollection() : getProfileCollection({ urlParams: { playerId } }),
    );

    if (collectionData && collectionData.items && collectionData.items.length > 0) {
      dispatch(setCollectionItems(collectionData.items));
    } else if (collectionData && collectionData.items && collectionData.items.length === 0) {
      dispatch(clearCollectionItems());
    }
  } catch (e) {
    dispatch(clearCollectionItems());
  }
};

export default loadCollection;
