import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
import { useInterval } from 'src/hooks/useInterval';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { setSnackbar } from 'src/redux/slices/snackbar/snackbar';
import { purchaseListing } from 'src/api/secondary-markets/purchase-listing';
import { getAsyncOperation } from 'src/api/async-operations/getAsyncOperation';
import ErrorMessage from 'src/components/feedback/ErrorMessage';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import BaseIcon from 'src/components/icons/BaseIcon';
import ForteWhiteHeaderIcon from 'src/components/icons/ForteWhiteHeaderIcon';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { logMain } from 'src/modules/logger/logger';
import { config } from 'src/config/config';
import Styled from 'src/components/modals/SecondaryPurchaseModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface SecondaryPurchaseModalProps {
  nft: Nonfungible | Listing | CollectionItem;
  open: boolean;
  onClose: () => Promise<void>;
  style?: React.CSSProperties;
}

const SecondaryPurchaseModal: FC<SecondaryPurchaseModalProps> = ({ nft, open, onClose, style = {} }) => {
  const { enableGameOverlay } = useGameOverlayContext();
  const [step, setStep] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [polling, setPolling] = useState(false);
  const [asyncOperationId, setAsyncOperationId] = useState('');
  const [error, setError] = useState('');
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const dispatch = useAppDispatch();
  const navigateExternal = useAppNavigateExternal();
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    // Fire Poole event for secondary purchase close
    PooleEvents.SecondaryPurchaseClose({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'secondary_purchase_modal',
      nonfungibleId: nft?.id || '',
      listingId: nft?.listingId || '',
      priceLC: (nft?.price || 0).toString(),
      priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    if (polling) {
      // Fire Poole event for secondary purchase polling start
      PooleEvents.SecondaryPurchasePollingAbort({
        dataset: enableGameOverlay ? 'unity' : 'website',
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'secondary_purchase_modal',
        nonfungibleId: nft?.id || '',
        listingId: nft?.listingId || '',
        priceLC: (nft?.price || 0).toString(),
        priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
        asyncOperationId,
      });
    }

    onClose().then(() => {
      setPolling(false);
      setAsyncOperationId('');
      setProcessing(false);
      setStep(0);
      setError('');
    });
  };

  const handleSubmit = () => {
    setProcessing(true);

    // Fire Poole event for secondary purchase confirm
    PooleEvents.SecondaryPurchaseConfirm({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'secondary_purchase_modal',
      nonfungibleId: nft?.id || '',
      listingId: nft?.listingId || '',
      priceLC: (nft?.price || 0).toString(),
      priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    dispatch(purchaseListing({ data: { listingId: nft?.listingId || undefined } }))
      .then(data => {
        // Fire Poole event for secondary purchase confirm
        PooleEvents.SecondaryPurchaseConfirmSuccess({
          dataset: enableGameOverlay ? 'unity' : 'website',
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'secondary_purchase_modal',
          nonfungibleId: nft?.id || '',
          listingId: nft?.listingId || '',
          priceLC: (nft?.price || 0).toString(),
          priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
          asyncOperationId: data?.id || '',
        });

        if (data?.id) {
          setAsyncOperationId(data?.id);
          setPolling(true);
          setStep(1);
        } else {
          dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
          setError(t('common.error.message.1'));
          setStep(0);
        }

        setProcessing(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        logMain.debug(e);

        // Fire Poole event for secondary purchase fail
        PooleEvents.SecondaryPurchaseConfirmFail({
          dataset: enableGameOverlay ? 'unity' : 'website',
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'secondary_purchase_modal',
          nonfungibleId: nft?.id || '',
          listingId: nft?.listingId || '',
          priceLC: (nft?.price || 0).toString(),
          priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
          reason: e instanceof Error ? e.message : String(e),
        });

        dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
        setError(t('common.error.message.1'));
        setProcessing(false);
        setPolling(false);
      });
  };

  // If user initiated a payment, poll the async op endpoint to recieve redirect URL
  useInterval(() => {
    if (polling && asyncOperationId) {
      // Fire Poole event for secondary purchase polling start
      PooleEvents.SecondaryPurchasePollingStart({
        dataset: enableGameOverlay ? 'unity' : 'website',
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'secondary_purchase_modal',
        nonfungibleId: nft?.id || '',
        listingId: nft?.listingId || '',
        priceLC: (nft?.price || 0).toString(),
        priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
        asyncOperationId,
      });

      dispatch(getAsyncOperation({ urlParams: { asyncOperationId } }))
        .then(data => {
          if (data?.status === 'pending') {
            logMain.debug('[DEBUG]: Operation pending');
            logMain.debug({ data });

            // Fire Poole event for secondary purchase polling pending
            PooleEvents.SecondaryPurchasePollingPending({
              dataset: enableGameOverlay ? 'unity' : 'website',
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'secondary_purchase_modal',
              nonfungibleId: nft?.id || '',
              listingId: nft?.listingId || '',
              priceLC: (nft?.price || 0).toString(),
              priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
              asyncOperationId,
            });
          } else if (data?.status === 'success') {
            logMain.debug('[DEBUG]: Operation succeeded');
            logMain.debug({ data, uri: data.result.redirectUri });

            // Fire Poole event for secondary purchase polling start
            PooleEvents.SecondaryPurchasePollingSuccess({
              dataset: enableGameOverlay ? 'unity' : 'website',
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'secondary_purchase_modal',
              nonfungibleId: nft?.id || '',
              listingId: nft?.listingId || '',
              priceLC: (nft?.price || 0).toString(),
              priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
              asyncOperationId,
            });

            // Redirect User if succcessful
            if (data?.result?.redirectUri) {
              navigateExternal(data.result.redirectUri, {
                pooleData: { pageName: 'Forte Pay', fromContext: 'secondary_market_purchase' },
              });
            } else {
              dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
              setError(t('common.error.message.1'));

              setPolling(false);
              setAsyncOperationId('');
              setStep(0);
            }
          } else if (data?.status === 'failure' || data?.error) {
            logMain.debug('[DEBUG]: Operation failed', data?.error);
            logMain.debug({ data });

            // Fire Poole event for secondary purchase polling start
            PooleEvents.SecondaryPurchasePollingFail({
              dataset: enableGameOverlay ? 'unity' : 'website',
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'secondary_purchase_modal',
              nonfungibleId: nft?.id || '',
              listingId: nft?.listingId || '',
              priceLC: (nft?.price || 0).toString(),
              priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
              asyncOperationId,
              reason: data?.error.toString() || 'Error during secondary purchase polling.',
            });

            dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
            setError(t('common.error.message.1'));

            setPolling(false);
            setAsyncOperationId('');
            setStep(0);
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug(e);

          // Fire Poole event for secondary purchase polling start
          PooleEvents.SecondaryPurchasePollingFail({
            dataset: enableGameOverlay ? 'unity' : 'website',
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'secondary_purchase_modal',
            nonfungibleId: nft?.id || '',
            listingId: nft?.listingId || '',
            priceLC: (nft?.price || 0).toString(),
            priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            asyncOperationId,
            reason: e instanceof Error ? e.message : String(e),
          });

          dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
          setError(t('common.error.message.1'));

          setPolling(false);
          setAsyncOperationId('');
          setStep(0);
        });
    }
  }, config.poll_interval.nft_purchase);

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.purchaseNFT}` }}
    >
      <Fade in={open}>
        <Styled.Root style={style}>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            <Styled.Heading>{t('common.popup.purchase.secondary.heading')}</Styled.Heading>

            <ErrorMessage msg={error} style={{ margin: '1.5rem 0 0 0' }} />

            {step === 0 && (
              <>
                <Styled.SubheadingsContainer>
                  <Styled.Subheading>{t('common.popup.purchase.secondary.step.0.subheading.1')}</Styled.Subheading>
                </Styled.SubheadingsContainer>

                <Styled.FeesContainer>
                  <Styled.FeeRow>
                    <Styled.FeeLabel>{t('common.term.fees')}</Styled.FeeLabel>

                    <Styled.FeeValue>
                      {config.app_variables.listing_fee
                        ? `${config.app_variables.listing_fee}%`
                        : t('common.fees.none')}
                    </Styled.FeeValue>
                  </Styled.FeeRow>

                  <Styled.FeeRow>
                    <Styled.FeeLabel>{t('common.term.royalties')}</Styled.FeeLabel>

                    <Styled.FeeValue>
                      {config.app_variables.royalty_fee
                        ? `${config.app_variables.royalty_fee * 100}%`
                        : t('common.royalties.none')}
                    </Styled.FeeValue>
                  </Styled.FeeRow>
                </Styled.FeesContainer>
              </>
            )}

            {step === 1 && (
              <>
                {polling && (
                  <Styled.ProgressContainer>
                    <CircularProgress color="inherit" size={'1.5rem'} />
                  </Styled.ProgressContainer>
                )}

                <Styled.ConfirmationTitle>{t('common.popup.purchase.secondary.step.1.title')}</Styled.ConfirmationTitle>
              </>
            )}

            <Styled.ForteContainer>
              <span className="material-icons">lock</span>
              <Styled.ForteBrandingLabel>{t('common.powered.by')}</Styled.ForteBrandingLabel>

              <BaseIcon
                hoverColor="#FEC300"
                cursor="pointer"
                icon={<ForteWhiteHeaderIcon />}
                padding="0"
                style={{ display: 'inline-block', verticalAlign: 'middle', margin: '1.1rem 0 0 0' }}
                onClick={() => {
                  navigateExternal(t('common.forte.link'), {
                    newWindow: true,
                    pooleData: { pageName: 'Forte', fromContext: 'purchase_listing_modal' },
                  });
                }}
              />
            </Styled.ForteContainer>

            {step === 0 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant={'white'}
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.cancel')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '0 auto 0 auto' : '0',
                  }}
                  onClick={handleClose}
                  disabled={processing}
                />

                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.purchase')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  onClick={handleSubmit}
                  disabled={processing}
                />
              </Styled.ButtonsContainer>
            )}

            {step === 1 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.close')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  onClick={handleClose}
                />
              </Styled.ButtonsContainer>
            )}
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default SecondaryPurchaseModal;
