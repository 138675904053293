import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: #00000077;
  display: inline-block;
  padding: 0.7rem 1rem;
  border-radius: 0.2rem;
  position: absolute;
  right: 0;
  bottom: 0rem;
  left: 0;
  /* transform: translateX(-50%); */

  @media (max-height: 350px) {
    padding: 0.4rem 0.5rem 0.5rem 0.5rem;
  }
`;

const TimerTitle = styled('div')`
  display: block;
  background: -webkit-linear-gradient(#aaaaaa, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-align: center;
  font-family: 'Kevlar Underwear';
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.3rem;

  @media (max-height: 500px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-height: 350px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
`;

const TimerContainer = styled('div')`
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.7rem;
  width: auto;
  /* border: 2px solid ${({ theme }) => theme.custom.colors.Yellow}; */

  & .material-icons-outlined,
  & .material-icons {
    /* display: none; */
    margin-top: 3px;
    margin-right: -3px;
    /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
    font-size: 1.8rem;
  }

  @media (max-height: 500px) {
    margin-top: 0.3rem;
    gap: 0.3rem;

    & .material-icons-outlined,
    & .material-icons {
      margin-top: 2px;
      margin-right: -1px;
      /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
      font-size: 1.4rem;
    }
  }

  @media (max-height: 400px) {
    & .material-icons-outlined,
    & .material-icons {
      display: none;
    }
  }
`;

const TimerGrouping = styled('div')`
  display: inline-block;
`;

const TimerValue = styled('div')`
  display: block;
  width: 100%;
  background: -webkit-linear-gradient(#aaaaaa, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-align: center;
  font-family: 'Kevlar Underwear';
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */

  @media (max-height: 500px) {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 0.9rem;
  }

  @media (max-height: 350px) {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 0.8rem;
  }
`;

const TimerLabel = styled('div')`
  display: block;
  margin-top: 0.2rem;
  text-transform: uppercase;
  text-align: center;
  font-family: 'BarlowSemiCondensed';
  font-size: 0.5rem;
  font-weight: 600;
  line-height: 0.5rem;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */

  @media (max-height: 500px) {
    margin-top: 0.1rem;
    font-size: 0.4rem;
    font-weight: 600;
    line-height: 0.4rem;
  }
`;

export default {
  Root,
  TimerContainer,
  TimerTitle,
  TimerGrouping,
  TimerValue,
  TimerLabel,
};
