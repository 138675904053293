/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import Styled from 'src/components/pages/profile/components/Allowlists.styles';

interface Title {
  default: string;
  empty: string;
}

interface AllowlistsProps {
  tab: number;
}

const Allowlists: FC<AllowlistsProps> = ({ tab }) => {
  const { playerId = '' } = useParams();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t: tProfile } = useTranslation(nameSpace.profile);
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const titles: Title[] = tProfile(
    isCurrentProfile ? 'profile.tab.results.titles' : 'profile.tab.results.titles.other.user',
    { returnObjects: true },
  );
  // const cols: string[] = tProfile('profile.allowlists.columns', { returnObjects: true });
  const allowlists = currentProfile?.allowLists || [];

  return (
    <Styled.Root>
      <Styled.Title>{allowlists.length > 0 ? titles[tab].default : titles[tab].empty}</Styled.Title>

      {allowlists.length > 0 && (
        <Styled.ResultsContainer>
          {allowlists.map(allowlist => {
            const { id, name } = allowlist;

            return <Styled.Allowlist key={`allowlist-${id}`}>{name}</Styled.Allowlist>;
          })}
        </Styled.ResultsContainer>
      )}
    </Styled.Root>
  );
};

export default Allowlists;
