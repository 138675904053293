import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useInterval } from 'src/hooks/useInterval';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import nestedSort from 'src/tools/nestedSort';
import { getDropEventDetails } from 'src/api/drop-events/get-drop-event-details';
import { getPublicDropEventDetails } from 'src/api/drop-events/get-public-drop-event-details';
import { purchaseNextListing } from 'src/api/markets/purchase-next-listing';
import { getAsyncOperation } from 'src/api/async-operations/getAsyncOperation';
import { getDropEventsMap, setDropEvents } from 'src/redux/slices/drop-events/drop-events';
import {
  getDropEventListPriceMarkets,
  setListPriceMarkets,
} from 'src/redux/slices/list-price-markets/list-price-markets';
import BaseModal from 'src/components/modals/BaseModal';
import PageError from 'src/components/feedback/PageError';
import SectionHero from 'src/components/pages/event-pack/components/SectionHero';
import SectionDetails from 'src/components/pages/event-pack/components/SectionDetails';
import SwiperNavigation from 'src/components/pages/event-pack/components/SwiperNavigation';
import { logMain } from 'src/modules/logger/logger';
import { config } from 'src/config/config';
import Styled from 'src/components/pages/event-pack/EventPack.styles';

interface EventPackProps {
  passedEventId?: string;
  fromGameOverlay?: boolean;
  style?: React.CSSProperties;
}

const EventPack: FC<EventPackProps> = ({ passedEventId = '', fromGameOverlay = false, style = {} }) => {
  const eventId = useParams()?.eventId || passedEventId;
  const [loading, setLoading] = useState<boolean>(true);
  const [asyncOperationId, setAsyncOperationId] = useState('');
  const [swiperIndex, setSwiperIndex] = useState<number>(0);
  const [processing, setProcessing] = useState(false);
  const [polling, setPolling] = useState(false);
  const [pollingModalOpen, setPollingModalOpen] = useState(false);
  const [error, setError] = useState('');
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(state => state.currentProfile.currentProfile);
  const dropEvents = useAppSelector(state => getDropEventsMap(state));
  const eventListPriceMarkets = useAppSelector(state => getDropEventListPriceMarkets(state, eventId));
  const { t: tEvent } = useTranslation(nameSpace.event);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const sortedListPriceMarkets = nestedSort(eventListPriceMarkets, -1, 'displayOrder');
  const dispatch = useAppDispatch();
  const navigateInternal = useAppNavigateInternal();
  const navigateExternal = useAppNavigateExternal();

  // Load Page Data
  useEffect(() => {
    logMain.debug('Loading Packs');

    if (config.app_variables.force_in_game_purchases_only) {
      navigateInternal('/page-not-found', { pooleData: { fromContext: 'event_details' } });
    } else {
      dispatch(
        authenticated
          ? getDropEventDetails({ urlParams: { dropEventId: eventId } })
          : getPublicDropEventDetails({ urlParams: { dropEventId: eventId } }),
      )
        .then(data => {
          if (data.dropEvent && Object.keys(data.dropEvent).length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const mappedMarkets = data.markets.map((market: any) => ({ ...market, dropEventId: eventId }));

            dispatch(setDropEvents([data.dropEvent]));
            dispatch(setListPriceMarkets(mappedMarkets));
          }

          setLoading(false);
        })
        .catch(e => {
          logMain.debug(e);

          navigateInternal('/page-not-found', { pooleData: { fromContext: 'event_details' } });
        });
    }
  }, [authenticated, dispatch, eventId, navigateInternal]);

  const handleClosePollingModal = () => {
    // Fire Poole event for primary market polling abort
    PooleEvents.PrimaryPurchasePollingAbort({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'primary_purchase_modal',
      dropEventId: eventId || '',
      asyncOperationId,
    });

    setAsyncOperationId('');
    setPollingModalOpen(false);
    setPolling(false);
  };

  // Reserve a listing for purchase, and then, if successful, set polling = true in
  // order to initialize polling for the redirect URL
  const handlePurchase = (marketId: string) => {
    setProcessing(true);

    const dropEvent = dropEvents[eventId];
    const lpm = eventListPriceMarkets.find(lpm => lpm.id === marketId);

    // Fire Poole event for primary market purchase start
    PooleEvents.PrimaryPurchaseStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'event_details',
      dropEventId: dropEvent?.id || '',
      dropEventType: dropEvent?.type || '',
      dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
      lpmId: lpm?.id || '',
      lpmType: lpm?.type || '',
      lpmAllowlistIds: lpm?.allowedViaAllowListIds || [],
      lpmPriceLC: lpm?.price.toString() || '',
      lpmPriceUSD: ((lpm?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
      lpmPurchaseCount: lpm?.purchaseCount.toString() || '',
      lpmPurchaseLimit: lpm?.purchaseLimit.toString() || '',
      lpmTotalCount: lpm?.totalCount.toString() || '',
      lpmCurrencyName: lpm?.priceCurrencyName || 'USD',
      lpmCurrencyBalance: lpm?.priceCurrencyBalance?.toString() || '',
    });

    dispatch(purchaseNextListing({ urlParams: { marketId } }))
      .then(data => {
        if (data?.id) {
          // Fire Poole event for primary market purchase start success
          PooleEvents.PrimaryPurchaseStartSuccess({
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'event_details',
            dropEventId: dropEvent?.id || '',
            dropEventType: dropEvent?.type || '',
            dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
            lpmId: lpm?.id || '',
            lpmType: lpm?.type || '',
            lpmAllowlistIds: lpm?.allowedViaAllowListIds || [],
            lpmPriceLC: lpm?.price.toString() || '',
            lpmPriceUSD: ((lpm?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            lpmPurchaseCount: lpm?.purchaseCount.toString() || '',
            lpmPurchaseLimit: lpm?.purchaseLimit.toString() || '',
            lpmTotalCount: lpm?.totalCount.toString() || '',
            lpmCurrencyName: lpm?.priceCurrencyName || 'USD',
            lpmCurrencyBalance: lpm?.priceCurrencyBalance?.toString() || '',
            asyncOperationId: data?.id || '',
          });

          setAsyncOperationId(data?.id);
          setPollingModalOpen(true);
          setPolling(true);
        } else {
          // Fire Poole event for primary market purchase queue enter success
          PooleEvents.PrimaryPurchaseQueueEnterSuccess({
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'event_details',
            dropEventId: dropEvent?.id || '',
            dropEventType: dropEvent?.type || '',
            dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
            lpmId: lpm?.id || '',
            lpmType: lpm?.type || '',
            lpmAllowlistIds: lpm?.allowedViaAllowListIds || [],
            lpmPriceLC: lpm?.price.toString() || '',
            lpmPriceUSD: ((lpm?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            lpmPurchaseCount: lpm?.purchaseCount.toString() || '',
            lpmPurchaseLimit: lpm?.purchaseLimit.toString() || '',
            lpmTotalCount: lpm?.totalCount.toString() || '',
            lpmCurrencyName: lpm?.priceCurrencyName || 'USD',
            lpmCurrencyBalance: lpm?.priceCurrencyBalance?.toString() || '',
          });

          // setError(tCommon('common.error.message.1'));
        }

        setProcessing(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        logMain.debug(e);

        // Fire Poole event for primary market purchase start success
        PooleEvents.PrimaryPurchaseStartFail({
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'event_details',
          dropEventId: dropEvent?.id || '',
          dropEventType: dropEvent?.type || '',
          dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
          lpmId: lpm?.id || '',
          lpmType: lpm?.type || '',
          lpmAllowlistIds: lpm?.allowedViaAllowListIds || [],
          lpmPriceLC: lpm?.price.toString() || '',
          lpmPriceUSD: ((lpm?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
          lpmPurchaseCount: lpm?.purchaseCount.toString() || '',
          lpmPurchaseLimit: lpm?.purchaseLimit.toString() || '',
          lpmTotalCount: lpm?.totalCount.toString() || '',
          lpmCurrencyName: lpm?.priceCurrencyName || 'USD',
          lpmCurrencyBalance: lpm?.priceCurrencyBalance?.toString() || '',
          reason: e instanceof Error ? e.message : String(e),
        });

        setError(tCommon('common.error.message.1'));
        setProcessing(false);
      });
  };

  // If user initiated a payment, poll the async op endpoint to recieve redirect URL
  useInterval(() => {
    if (polling && asyncOperationId) {
      // Fire Poole event for primary market polling start
      PooleEvents.PrimaryPurchasePollingStart({
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'primary_purchase_modal',
        dropEventId: eventId || '',
        asyncOperationId,
      });

      dispatch(getAsyncOperation({ urlParams: { asyncOperationId } }))
        .then(data => {
          if (data?.status === 'pending') {
            logMain.debug('[DEBUG]: Operation pending');

            // Fire Poole event for primary market polling pending
            PooleEvents.PrimaryPurchasePollingPending({
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'primary_purchase_modal',
              dropEventId: eventId || '',
              asyncOperationId,
            });
          } else if (data?.status === 'success') {
            logMain.debug('[DEBUG]: Operation succeeded');

            // Fire Poole event for primary market polling success
            PooleEvents.PrimaryPurchasePollingSuccess({
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'primary_purchase_modal',
              dropEventId: eventId || '',
              asyncOperationId,
            });

            // Redirect User if succcessful
            if (data?.result?.redirectUri) {
              navigateExternal(data.result.redirectUri, {
                pooleData: { pageName: 'forte_pay', fromContext: 'primary_market_purchase' },
              });
            }

            setPolling(false);
            setPollingModalOpen(false);
          } else if (data?.status === 'failure' || data?.error) {
            logMain.debug('[DEBUG]: Operation failed', data?.error);

            // Fire Poole event for primary market polling fail
            PooleEvents.PrimaryPurchasePollingFail({
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'primary_purchase_modal',
              dropEventId: eventId || '',
              asyncOperationId,
              reason: data?.error.toString() || 'Error during primary purchase polling.',
            });

            setPolling(false);
            setPollingModalOpen(false);
            setError(tCommon('common.error.message.1'));
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug(e);

          // Fire Poole event for primary market polling fail
          PooleEvents.PrimaryPurchasePollingFail({
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'primary_purchase_modal',
            dropEventId: eventId || '',
            asyncOperationId,
            reason: e instanceof Error ? e.message : String(e),
          });

          setError(tCommon('common.error.message.1'));
        });
    }
  }, config.poll_interval.event_purchase);

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;
  // If data loaded incorrectly return page error
  if (!dropEvents[eventId] || eventListPriceMarkets.length === 0) return <PageError />;

  return (
    <Styled.Root style={style}>
      <BaseModal
        open={pollingModalOpen}
        onClose={handleClosePollingModal}
        heading={tEvent('event.modal.polling.heading')}
        subheading={tEvent('event.modal.polling.subheading')}
        secondaryButton={tEvent('event.modal.polling.button')}
        showProgressBar
      />

      <Swiper
        initialSlide={swiperIndex}
        slidesPerView="auto"
        speed={1000}
        allowTouchMove={false}
        onSlideChange={e => setSwiperIndex(e.realIndex)}
      >
        {sortedListPriceMarkets.map(listPriceMarket => {
          return (
            <SwiperSlide key={`list-price-market-${listPriceMarket.id}`}>
              <SectionHero
                listing={listPriceMarket.type}
                marketId={listPriceMarket.id}
                processing={processing}
                polling={polling}
                error={error}
                handlePurchase={handlePurchase}
                fromGameOverlay={fromGameOverlay}
              />

              {!fromGameOverlay && <SectionDetails listing={listPriceMarket.type} marketId={listPriceMarket.id} />}
            </SwiperSlide>
          );
        })}

        <SwiperNavigation swiperIndex={swiperIndex} numSlides={sortedListPriceMarkets.length} />
      </Swiper>
    </Styled.Root>
  );
};

export default EventPack;
