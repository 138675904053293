/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { LocalStorage } from 'src/local-storage/local-storage';

type GameOverlayPage = 'secondary-marketplace' | 'primary-marketplace' | 'rewards' | 'collection' | 'nft';
export interface GameOverlayLocation {
  page: GameOverlayPage;
  params: Record<string, string | number | null | undefined>;
}

export interface IGameOverlayContext {
  dimensions: { width: string; height: string };
  setDimensions: (frameDimensions: { width: string; height: string }) => void;
  openGameOverlay: (gameOverlayLocation: GameOverlayLocation) => void;
  closeGameOverlay: () => void;
  navigateGameOverlay: (gameOverlayLocation: GameOverlayLocation) => void;
  navigateBackGameOverlay: () => void;
  progressBlurbs: () => void;
  addBlurbs: (blurbs: string[]) => void;
  enableGameOverlay: boolean;
  setEnableGameOverlay?: (enableGameOverlay: boolean) => void;
  gameOverlayLocation: GameOverlayLocation;
  setGameOverlayLocation?: (gameOverlayLocation: GameOverlayLocation) => void;
  gameOverlayHistory: GameOverlayLocation[];
  setGameOverlayHistory?: (gameOverlayLocations: GameOverlayLocation[]) => void;
  blurbs: string[];
  setBlurbs: (blurbs: string[]) => void;
  // Debug States for testing
  enableGameOverlayContents: boolean;
  setEnableGameOverlayContents: (enableGameOverlayContents: boolean) => void;
  enablePipsUnopenedPacks: boolean;
  setEnablePipsUnopenedPacks: (enablePipsUnopenedPacks: boolean) => void;
}

export const initialContext: IGameOverlayContext = {
  dimensions: { width: '0', height: '0' },
  setDimensions: () => undefined,
  openGameOverlay: () => undefined,
  closeGameOverlay: () => undefined,
  navigateGameOverlay: () => undefined,
  navigateBackGameOverlay: () => undefined,
  progressBlurbs: () => undefined,
  addBlurbs: () => undefined,
  enableGameOverlay: false,
  setEnableGameOverlay: () => undefined,
  gameOverlayLocation: {
    page: 'primary-marketplace',
    params: {},
  },
  setGameOverlayLocation: () => undefined,
  gameOverlayHistory: [],
  setGameOverlayHistory: () => undefined,
  blurbs: [],
  setBlurbs: () => undefined,
  // Debug States for testing
  enableGameOverlayContents: true,
  setEnableGameOverlayContents: () => undefined,
  enablePipsUnopenedPacks: LocalStorage.get('gameplay_enable_pips_unopened_packs') !== 'false',
  setEnablePipsUnopenedPacks: () => undefined,
};

const GameOverlayContext = createContext<IGameOverlayContext>(initialContext);

GameOverlayContext.displayName = 'GameOverlayContext';

export default GameOverlayContext;
