import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SearchAndFilterBar from 'src/components/pages/marketplace/components/SearchAndFilterBar';
import FilterMenu from 'src/components/pages/marketplace/components/FilterMenu';
import Listings from 'src/components/pages/marketplace/components/Listings';
import Styled from 'src/components/pages/marketplace/components/SectionResults.styles';

interface SectionResultsProps {
  style?: React.CSSProperties;
}

const SectionResults: FC<SectionResultsProps> = ({ style = {} }) => {
  const [inView, setInView] = useState(false);

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated delay-2 ${inView ? 'fadeInUp' : ''}`} style={style}>
        <SearchAndFilterBar />
        <FilterMenu />
        <Listings />
      </Styled.Root>
    </Waypoint>
  );
};

export default SectionResults;
