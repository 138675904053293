interface Rarity {
  [key: number]: string;
}

export const RARITIES: Rarity = {
  0: 'starter',
  10: 'promo',
  50: 'common',
  60: 'uncommon',
  70: 'rare',
  80: 'ultraRare',
  90: 'epic',
  100: 'legendary',
};
