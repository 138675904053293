import { styled } from '@mui/material';

interface RootProps {
  filtering: boolean;
}

const Root = styled('div')<RootProps>`
  display: inline-block;
  width: ${({ filtering }) => (filtering ? 'calc(100% - 20rem - 2rem)' : '100%')};
  margin-top: 2rem;
  vertical-align: top;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const ProgressContainer = styled('div')`
  display: block;
  width: 100%;
  text-align: center;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const Disabled = styled('div')`
  display: block;
  width: 100%;
  font-family: 'Kevlar Underwear';
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};

  &:not(:first-of-type) {
    margin-top: 0.7rem;
    text-transform: uppercase;
    font-family: 'BarlowSemiCondensed';
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;
  }

  @media (max-height: 500px) {
    font-size: 0.8rem;
    line-height: 1rem;

    &:not(:first-of-type) {
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-family: 'BarlowSemiCondensed';
      font-size: 0.7rem;
      line-height: 0.9rem;
      font-weight: 400;
    }
  }
`;

const EmptyResults = styled('div')`
  display: block;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #ffffff77;
`;

const LoadMoreContainer = styled('div')`
  display: block;
  width: 100%;
  /* margin-top: 1rem; */
  text-align: center;
`;

interface LoadMoreButtonProps {
  disabled?: boolean;
}

const LoadMoreButton = styled('div')<LoadMoreButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: calc(2.7rem - 2px);
  margin: 0 auto;
  border: 1px solid #ffffff77;
  border-radius: 0.2rem;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: relative;

  &:hover {
    background-color: #ffffff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};

    & > span {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};

      & > span {
        color: ${({ theme }) => theme.custom.colors.BgLayer1};
      }
    }
  }
`;

const LoadMoreButtonText = styled('span')`
  display: inline-block;
  width: calc(100% - 2rem);
  /* text-transform: uppercase; */
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;

  & > .material-icons {
    display: inline-block;
    margin: 1px 0.5rem 0 0;
    vertical-align: middle;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    color: #fff;
  }

  & > span:nth-of-type(2) {
    display: inline-block;
    vertical-align: middle;
  }
`;

export default {
  Root,
  ProgressContainer,
  Disabled,
  EmptyResults,
  LoadMoreContainer,
  LoadMoreButton,
  LoadMoreButtonText,
};
