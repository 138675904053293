/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getDropEvents } from 'src/api/drop-events/get-drop-events';
import { getPublicDropEvents } from 'src/api/drop-events/get-public-drop-events';
import { getDropEventDetails } from 'src/api/drop-events/get-drop-event-details';
import { getPublicDropEventDetails } from 'src/api/drop-events/get-public-drop-event-details';
import {
  addDropEvents,
  // clearDropEvents,
  setDropEvents,
} from 'src/redux/slices/drop-events/drop-events';
import {
  addListPriceMarkets,
  // clearListPriceMarkets,
  setListPriceMarkets,
} from 'src/redux/slices/list-price-markets/list-price-markets';
import { logMain } from 'src/modules/logger/logger';

type DropEventTag = 'pack' | 'daily' | 'hero';

interface UseLoadDropEventsProps {
  dropEventTags?: DropEventTag[];
  loadListPriceMarkets?: boolean;
  replaceDropEvents?: boolean;
  replaceListPriceMarkets?: boolean;
  redirectOnError?: boolean;
  fromContext: string;
}

export const useLoadDropEvents = ({
  dropEventTags = [],
  loadListPriceMarkets = false,
  replaceDropEvents = false,
  replaceListPriceMarkets = false,
  redirectOnError = false,
  fromContext,
}: UseLoadDropEventsProps) => {
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();
  const dispatch = useAppDispatch();

  // Load Page Data
  useEffect(() => {
    if (loading) {
      logMain.debug('Loading drop events: ');

      // Clear existing data before loading new data
      // dispatch(clearDropEvents());
      // dispatch(clearListPriceMarkets());

      // Load drop events and associated LPMs
      dispatch(
        authenticated
          ? getDropEvents({ queryParams: { tags: dropEventTags } })
          : getPublicDropEvents({ queryParams: { tags: dropEventTags } }),
      )
        .then(data => {
          if (data.dropEvents.length > 0) {
            dispatch(setDropEvents(data.dropEvents));
          }

          const promises = loadListPriceMarkets
            ? data.dropEvents.map((dropEvent: any) => {
                return dispatch(
                  authenticated
                    ? getDropEventDetails({ urlParams: { dropEventId: dropEvent.id } })
                    : getPublicDropEventDetails({ urlParams: { dropEventId: dropEvent.id } }),
                );
              })
            : [Promise.resolve([])];

          return Promise.all(promises);
        })
        .then(data => {
          if (data.length > 0) {
            const dropEvents: any[] = [];
            let listPriceMarkets: any[] = [];

            data.forEach((result: any) => {
              if (result.dropEvent && Object.keys(result.dropEvent).length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const mappedMarkets = result.markets.map((market: any) => ({
                  ...market,
                  dropEventId: result.dropEvent.id,
                }));

                dropEvents.push(result.dropEvent);
                listPriceMarkets = listPriceMarkets.concat(mappedMarkets);
              }
            });

            dispatch(replaceDropEvents ? setDropEvents(dropEvents) : addDropEvents(dropEvents));
            dispatch(
              replaceListPriceMarkets ? setListPriceMarkets(listPriceMarkets) : addListPriceMarkets(listPriceMarkets),
            );
          }

          setLoading(false);
        })
        .catch(e => {
          logMain.debug(e);

          if (redirectOnError) {
            navigateInternal('/page-not-found', { pooleData: { fromContext } });
          }

          setError(t('common.error.message.1'));
          setLoading(false);
        });
    }
  }, [
    authenticated,
    dispatch,
    dropEventTags,
    fromContext,
    loadListPriceMarkets,
    loading,
    navigateInternal,
    redirectOnError,
    replaceDropEvents,
    replaceListPriceMarkets,
    t,
  ]);

  return { loading, error };
};
