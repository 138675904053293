import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;
`;

const Separator = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  border-bottom: 1px solid #ffffff33;
`;

const BalanceContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 2rem;
`;

const Balance = styled('span')`
  display: inline-block;
  margin-bottom: 0.3rem;
  margin-right: 0.4rem;
  vertical-align: bottom;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 400;
  color: #fff;
`;

const BalanceLabel = styled('span')`
  display: inline-block;
  margin-top: 0.5rem;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 300;
  color: #ffffff;
`;

interface ButtonProps {
  disabled?: boolean;
}

const Button = styled('div')<ButtonProps>`
  display: block;
  width: 100%;
  max-width: 15rem;
  padding: 0.6rem 0.8rem 0.7rem 0.8rem;
  margin-top: 2rem;
  border: ${({ disabled }) => `1px solid ${disabled ? '#ffffff77' : '#fff'}`};
  border-radius: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  text-transform: capitalize;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ disabled }) => (disabled ? '#ffffff77' : '#fff')};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & > span {
    display: inline-block;
    vertical-align: middle;
  }

  & .material-icons,
  & .material-icons-outlined {
    margin-top: 1px;
    margin-right: 0.3rem;
    font-size: 0.9rem;
    color: ${({ disabled }) => (disabled ? '#ffffff77' : '#fff')};
  }

  &:hover {
    & .material-icons,
    & .material-icons-outlined {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }

    background-color: #fff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }

  @media (max-width: 900px) {
    font-size: 0.7rem;
    line-height: 0.9rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.8rem;
    }
  }
`;

export default {
  Root,
  Title,
  Separator,
  BalanceContainer,
  Balance,
  BalanceLabel,
  Button,
};
