import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useWebMetricsContext from 'src/hooks/useWebMetricsContext';
import useDiscordWidgetContext from 'src/hooks/useDiscordWidgetContext';
import useSoundContext from 'src/hooks/useSoundContext';
import { Checkbox } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';
import Styled from 'src/components/pages/profile/components/Settings.styles';
import { config } from 'src/config/config';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';

const Settings: FC = () => {
  const {
    enableGameOverlayContents,
    setEnableGameOverlayContents,
    enablePipsUnopenedPacks,
    setEnablePipsUnopenedPacks,
  } = useGameOverlayContext();
  const { enableWebMetrics, setEnableWebMetrics } = useWebMetricsContext();
  const {
    enableWelcomeMessage,
    setEnableWelcomeMessage,
    enableNotifications,
    setEnableNotifications,
    enableIndicators,
    setEnableIndicators,
    showDiscordWidget,
    setShowDiscordWidget,
  } = useDiscordWidgetContext();
  const { enableClickSounds, setEnableClickSounds } = useSoundContext();
  const { t } = useTranslation(nameSpace.profile);

  return (
    <Styled.Root>
      {!config.is_prod && (
        <>
          <Styled.SettingCategory>{t('profile.settings.game.overlay.title')}</Styled.SettingCategory>

          <Styled.Separator />

          <Styled.SettingGrouping>
            <Styled.SettingContainer>
              <Styled.CheckboxGroup>
                <Checkbox
                  checked={enableGameOverlayContents}
                  onChange={e => setEnableGameOverlayContents(e.target.checked)}
                  sx={{
                    '&.Mui-checked': {
                      color: customThemeOptions.custom.colors.Yellow,
                    },
                  }}
                />

                <Styled.CheckboxLabel>
                  {t('profile.settings.game.overlay.contents.label')}
                  <div>{t('profile.settings.game.overlay.contents.description')}</div>
                </Styled.CheckboxLabel>
              </Styled.CheckboxGroup>
            </Styled.SettingContainer>
          </Styled.SettingGrouping>

          <Styled.SettingCategory>{t('profile.settings.performance.title')}</Styled.SettingCategory>

          <Styled.Separator />

          <Styled.SettingGrouping>
            <Styled.SettingContainer>
              <Styled.CheckboxGroup>
                <Checkbox
                  checked={enableWebMetrics}
                  onChange={e => setEnableWebMetrics(e.target.checked)}
                  sx={{
                    '&.Mui-checked': {
                      color: customThemeOptions.custom.colors.Yellow,
                    },
                  }}
                />

                <Styled.CheckboxLabel>
                  {t('profile.settings.performance.web.metrics.label')}
                  <div>{t('profile.settings.performance.web.metrics.description')}</div>
                </Styled.CheckboxLabel>
              </Styled.CheckboxGroup>
            </Styled.SettingContainer>
          </Styled.SettingGrouping>
        </>
      )}

      <Styled.SettingCategory>{t('profile.settings.gameplay.title')}</Styled.SettingCategory>

      <Styled.Separator />

      <Styled.SettingGrouping>
        <Styled.SettingContainer>
          <Styled.CheckboxGroup>
            <Checkbox
              checked={enablePipsUnopenedPacks}
              onChange={e => setEnablePipsUnopenedPacks(e.target.checked)}
              sx={{
                '&.Mui-checked': {
                  color: customThemeOptions.custom.colors.Yellow,
                },
              }}
            />

            <Styled.CheckboxLabel>
              {t('profile.settings.gameplay.pips.unopened.packs.label')}
              <div>{t('profile.settings.gameplay.pips.unopened.packs.description')}</div>
            </Styled.CheckboxLabel>
          </Styled.CheckboxGroup>
        </Styled.SettingContainer>
      </Styled.SettingGrouping>

      <Styled.SettingCategory>{t('profile.settings.audio.title')}</Styled.SettingCategory>

      <Styled.Separator />

      <Styled.SettingGrouping>
        <Styled.SettingContainer>
          <Styled.CheckboxGroup>
            <Checkbox
              checked={enableClickSounds}
              onChange={e => setEnableClickSounds(e.target.checked)}
              sx={{
                '&.Mui-checked': {
                  color: customThemeOptions.custom.colors.Yellow,
                },
              }}
            />

            <Styled.CheckboxLabel>
              {t('profile.settings.audio.cursor.label')}
              <div>{t('profile.settings.audio.cursor.description')}</div>
            </Styled.CheckboxLabel>
          </Styled.CheckboxGroup>
        </Styled.SettingContainer>
      </Styled.SettingGrouping>

      <Styled.SettingCategory>{t('profile.settings.discord.title')}</Styled.SettingCategory>

      <Styled.Separator />

      <Styled.SettingGrouping>
        <Styled.SettingContainer>
          <Styled.CheckboxGroup>
            <Checkbox
              checked={enableWelcomeMessage}
              onChange={e => setEnableWelcomeMessage(e.target.checked)}
              sx={{
                '&.Mui-checked': {
                  color: customThemeOptions.custom.colors.Yellow,
                },
              }}
            />

            <Styled.CheckboxLabel>
              {t('profile.settings.discord.welcome.message.label')}
              <div>{t('profile.settings.discord.welcome.message.description')}</div>
            </Styled.CheckboxLabel>
          </Styled.CheckboxGroup>
        </Styled.SettingContainer>

        <Styled.SettingContainer>
          <Styled.CheckboxGroup>
            <Checkbox
              checked={enableNotifications}
              onChange={e => setEnableNotifications(e.target.checked)}
              sx={{
                '&.Mui-checked': {
                  color: customThemeOptions.custom.colors.Yellow,
                },
              }}
            />

            <Styled.CheckboxLabel>
              {t('profile.settings.discord.notifications.label')}
              <div>{t('profile.settings.discord.notifications.description')}</div>
            </Styled.CheckboxLabel>
          </Styled.CheckboxGroup>
        </Styled.SettingContainer>

        <Styled.SettingContainer>
          <Styled.CheckboxGroup>
            <Checkbox
              checked={enableIndicators}
              onChange={e => setEnableIndicators(e.target.checked)}
              sx={{
                '&.Mui-checked': {
                  color: customThemeOptions.custom.colors.Yellow,
                },
              }}
            />

            <Styled.CheckboxLabel>
              {t('profile.settings.discord.indicators.label')}
              <div>{t('profile.settings.discord.indicators.description')}</div>
            </Styled.CheckboxLabel>
          </Styled.CheckboxGroup>
        </Styled.SettingContainer>

        <Styled.SettingContainer>
          <Styled.CheckboxGroup>
            <Checkbox
              checked={showDiscordWidget}
              onChange={e => setShowDiscordWidget(e.target.checked)}
              sx={{
                '&.Mui-checked': {
                  color: customThemeOptions.custom.colors.Yellow,
                },
              }}
            />

            <Styled.CheckboxLabel>
              {t('profile.settings.discord.show.widget.label')}
              <div>{t('profile.settings.discord.show.widget.description')}</div>
            </Styled.CheckboxLabel>
          </Styled.CheckboxGroup>
        </Styled.SettingContainer>
      </Styled.SettingGrouping>
    </Styled.Root>
  );
};

export default Settings;
