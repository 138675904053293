import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
`;

const SecondaryPriceContainer = styled('div')`
  display: inline-block;
  position: relative;
`;

const SecondaryPrice = styled('span')`
  display: inline-block;

  & > span {
    display: inline-block;
    vertical-align: bottom;
    text-transform: uppercase;
    color: #777777;
  }

  & > span:nth-of-type(1) {
    margin-right: 0.3rem;
    font-size: 1.6rem;
    line-height: 1.7rem;
    font-weight: 600;
  }

  & > span:nth-of-type(2) {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;
  }

  @media (max-width: 1050px) {
    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 1.1rem;
      line-height: 1.2rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-width: 900px) {
    & > span:nth-of-type(1) {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  @media (max-height: 500px) {
    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 1rem;
      line-height: 1.2rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 350px) {
    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 0.8rem;
      line-height: 1rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.5rem;
      line-height: 0.7rem;
    }
  }
`;

const Strikethrough = styled('div')`
  background-color: #777777;
  /* background-color: ${({ theme }) => theme.custom.colors.Yellow}; */
  display: block;
  width: 100%;
  height: 2.5px;
  position: absolute;
  top: 55%;
  left: 0;
  transform: translate(0, -50%);

  @media (max-width: 1050px) {
    height: 1.5px;
  }

  @media (max-height: 500px) {
    height: 1.5px;
  }
`;

export default {
  Root,
  SecondaryPriceContainer,
  SecondaryPrice,
  Strikethrough,
};
