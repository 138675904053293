type PackTier = 'starter' | 'alpha' | 'bronze' | 'silver' | 'gold';

export const getPackTierFromType = (type: string): PackTier => {
  if (type.indexOf('starter') !== -1) return 'starter';
  if (type.indexOf('alpha') !== -1) return 'alpha';
  if (type.indexOf('bronze') !== -1) return 'bronze';
  if (type.indexOf('silver') !== -1) return 'silver';
  if (type.indexOf('gold') !== -1) return 'gold';

  return 'gold';
};
