import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const N3tworkWhiteHeaderIcon: FC = () => (
  <SvgIcon width="77" height="20" viewBox="0 0 427.38 130.31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Layer_1-2">
      <g>
        <path
          fillRule="evenodd"
          fill="#ffffff"
          className="cls-3"
          d="M383.96,31.18L403.78,1.27l.03-.03-31.18,1.64-6.84,7.21V3.23l-26.33,1.38-2.15,2.27c-4.08-1.62-9.02-1.68-13.9-1.43l-35.07,1.84-3.28,3.45c-5.97-3.16-13.78-3.33-21.42-2.93-7.26,.38-14.61,1.25-20.42,4.58l.51-2.76-26.88,1.41-2.79,2.95-.51-2.77-31.18,1.64-2.83,2.98-.55-2.8-67.44,3.54-1.61,1.69c-4.62-1.69-10.57-1.83-17.89-1.44-8.9,.47-15.86,1.54-20.78,5.24-.19,.14-.35,.28-.51,.41h0v-3.77l-26.33,1.38-7.23,7.62-5.85-6.94-22.26,1.17L0,31.53v63.09l26.33-1.38,7.23-7.62,3.98,4.68,1.93,2.28,22.19-1.16,2.28-2.41c4.75,2.26,11.11,2.48,19.01,2.06,8.86-.47,15.77-1.47,20.68-4.92,.96-.66,1.77-1.38,2.51-2.13l9.09-9.59c1.09-1.15,1.93-2.39,2.57-3.67v17.7l26.33-1.38,8.83-9.32v34.83c0,4.96,.67,11.13,6.07,14.78,4.24,2.87,9.95,3.18,17.28,2.79,6.97-.36,12.46-1.14,16.44-3.79,.96-.63,1.8-1.32,2.47-2.06l3.4-3.59v7.69l22.32-1.17,3.69-3.89c4.34,3.47,10.37,3.67,16.28,3.36,4.59-.24,9.28-.81,13.17-2.88v1.64l24.57-1.29c4.4-.23,9.18-.84,13.2-3.04v2.35l22.29-1.17,1.51-1.59c4.17,1.52,9.06,1.58,13.87,1.33,6.94-.36,14-1.38,18.82-6.2,.51,.52,1.09,.97,1.73,1.42,4.24,2.87,9.95,3.18,17.28,2.79,6.97-.36,12.46-1.14,16.44-3.79,.96-.63,1.8-1.32,2.47-2.06l8.9-9.38c.51-.51,.93-1.01,1.32-1.55,.02-.02,.03-.04,.05-.07,.02-.02,.03-.05,.05-.07,.06-.1,.13-.19,.19-.28,.07-.09,.13-.18,.19-.28,.06-.1,.13-.2,.16-.27h0c.06-.1,.13-.2,.16-.3,.06-.12,.12-.22,.18-.33,.06-.11,.11-.22,.18-.33,0-.02,0-.03,.02-.05,0-.02,.02-.03,.02-.05,.93-1.98,1.35-4.03,1.51-5.91,0-.03,0-.06,0-.09,.02-.15,.02-.24,.02-.62V60.51c0-2.7-.71-5.31-2.09-7.55l-13.17-21.78Zm37.38-23.69l1.48-7.26,4.56-.24V9.66l-2.6,.14V2.61h-.06l-1.48,7.26-4.02,.21-1.45-7.1h-.06v7.18l-2.6,.14V2.86l-2.99,.16v7.44l-2.6,.14V3.16l-2.96,.16V1.09l8.54-.45,4.69-.25,1.45,7.1h.1Z"
        />
        <path
          fill="#1b1d25"
          className="cls-1"
          d="M92.04,23.26c17.18-.9,21.49,1.67,21.49,12.81,0,6.89-1.96,10.72-6.36,12.28,4.4,1.06,6.36,4.72,6.36,11.61,0,11.17-4.3,14.16-21.49,15.06-17.08,.9-21.36-1.87-21.36-13.82l12.59-.66c0,3.86,1.38,4.24,8.74,3.85,7.48-.39,8.9-1.27,8.9-5.52s-1.38-5.11-8.9-4.72l-8.41,.44v-10.04l8.41-.44c7.48-.39,8.9-1.27,8.9-5.65s-1.38-4.98-8.9-4.59c-7.35,.39-8.74,.91-8.74,4.77l-12.59,.66c0-11.94,4.27-15.16,21.36-16.05Z"
        />
        <path
          fillRule="evenodd"
          fill="#1b1d25"
          className="cls-2"
          d="M263.63,14.26c20.81-1.09,26.01,3.79,26.01,24.51s-5.2,26.15-26.01,27.24c-20.81,1.09-26.01-3.79-26.01-24.51s5.2-26.15,26.01-27.24Zm0,40.81c10.73-.56,11.88-2.1,11.88-15.56s-1.16-14.88-11.88-14.31c-10.73,.56-11.88,2.1-11.88,15.56s1.16,14.88,11.88,14.31Z"
        />
        <path
          fill="#1b1d25"
          className="cls-1"
          d="M376.48,31.25l15.16-22.94-15.61,.82-16.64,25.34V10l-13.49,.71V60.92l13.49-.71v-10.56l7.71-9.03,10.92,18.61,14.9-.78-16.44-27.21Z"
        />
        <path
          fillRule="evenodd"
          fill="#1b1d25"
          className="cls-2"
          d="M323.42,11.89c13.36-.7,16.7,2.02,16.7,13.67,0,8.3-3.08,12.39-10.08,13.73,6.68,.36,10.08,3.14,10.08,9.58v12.36l-14.13,.74v-11.59c0-5.54-.71-6.08-7.71-5.71l-10.02,.53v17.7l-13.49,.71V13.39l28.65-1.5Zm-15.16,23.01l11.69-.61c6.04-.32,6.68-.93,6.68-6.08s-.64-5.5-6.68-5.19l-11.69,.61v11.27Z"
        />
        <path
          fill="#1b1d25"
          className="cls-1"
          d="M226.31,67.2l9.63-50.72-13.81,.72-7,36.8-6.94-36.07-20.55,1.08-7,36.8-6.97-36.07-55.72,2.92v11.59l15.35-.8v38.63l13.49-.71V32.74l15.29-.8,7.42,38.24,22.32-1.17,6.9-38.12,6.26,37.43,21.32-1.12Z"
        />
        <path
          fill="#1b1d25"
          className="cls-1"
          d="M64.32,25.48v50.21l-12.72,.67-22.22-26.19-.39,.02v27.36l-13.49,.71V28.04l12.78-.67,22.16,26.26,.39-.02V26.19l13.49-.71Z"
        />
        <path
          fill="#1b1d25"
          className="cls-1"
          d="M392.7,79.84c0-10.3-3.34-12.67-16.25-11.99-12.81,.67-16.12,2.97-16.15,11.44,0,6.53,2.89,8.57,14.35,10.26,6.62,.97,8.38,1.72,8.38,3.74,0,2.58-.74,2.87-6.84,3.19-6.04,.32-6.74-.1-6.74-4.35l-9.89,.52c0,9.85,3.44,12.18,16.89,11.47,13.1-.69,16.47-2.86,16.47-10.87,0-7.11-3.5-9.34-16.67-11.23-4.78-.78-6.07-1.35-6.07-3.22,0-2.64,.71-3,6.26-3.29,5.68-.3,6.36,.15,6.36,4.85l9.89-.52Z"
        />
        <path
          fillRule="evenodd"
          fill="#1b1d25"
          className="cls-2"
          d="M338.61,69.84c14.58-.77,18.21,2.65,18.21,17.17s-3.66,18.31-18.21,19.08-18.21-2.65-18.21-17.17,3.66-18.31,18.21-19.08Zm0,28.58c7.52-.39,8.32-1.47,8.32-10.9s-.8-10.42-8.32-10.02c-7.51,.39-8.32,1.5-8.32,10.9s.8,10.42,8.32,10.03Z"
        />
        <path
          fill="#1b1d25"
          className="cls-1"
          d="M201.61,89.87c0-10.3-3.34-12.67-16.25-11.99-12.81,.67-16.15,2.97-16.15,11.44,0,6.53,2.89,8.57,14.36,10.26,6.62,.97,8.38,1.72,8.38,3.74,0,2.58-.74,2.87-6.84,3.19-6.04,.32-6.74-.1-6.74-4.34l-9.89,.52c0,9.85,3.44,12.18,16.89,11.47,13.1-.69,16.47-2.86,16.47-10.88-.03-7.11-3.53-9.34-16.67-11.23-4.79-.78-6.07-1.36-6.07-3.22,0-2.64,.71-3,6.26-3.29,5.68-.3,6.36,.15,6.36,4.85l9.89-.52Z"
        />
        <path fill="#1b1d25" className="cls-1" d="M316.81,71.5v35.18l-9.44,.5v-35.18l9.44-.49Z" />
        <path
          fillRule="evenodd"
          fill="#1b1d25"
          className="cls-2"
          d="M303.77,89.79c0-14.07-3.21-17.44-16.02-16.77l-18.14,.95v35.18l18.14-.95c12.81-.67,16.02-4.35,16.02-18.41Zm-17.02,10.36l-7.71,.4-.07-9.46,.07-9.47,7.71-.4c6.39-.34,7.13,.56,7.13,9.09s-.74,9.5-7.13,9.84Z"
        />
        <path
          fill="#1b1d25"
          className="cls-1"
          d="M265.59,74.21v20.73c0,12.01-3.08,15.13-15.58,15.78-12.46,.65-15.57-2.14-15.57-14.15v-12.62l-10.82,.57v27.04l-9.44,.49v-27.04l-10.79,.57v-8.11l40.5-2.12v20.73c0,6.6,.64,7.27,6.1,6.99,5.49-.29,6.1-1.06,6.1-7.63h.03v-20.73l9.47-.5Z"
        />
      </g>
    </g>
  </SvgIcon>
);

export default N3tworkWhiteHeaderIcon;
