import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/pages/blog/components/SectionHero.styles';

type Hero = {
  path: string;
};

const SectionHero: FC = () => {
  const { t } = useTranslation(nameSpace.blog);
  const hero: Hero = t('blog.hero.asset', { returnObjects: true });
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(769));

  return (
    <Styled.Root style={{ backgroundImage: `url(/assets/${mobileView ? 'mobile_' : ''}${hero.path})` }}>
      <Styled.HeroBottomBorder />

      <Styled.Overlay className={'inview animated fadeIn'}>
        <Styled.CopyContainer>
          <Styled.Heading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('blog.hero.title')) }} />
          <Styled.Subheading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('blog.hero.subtitle')) }} />
        </Styled.CopyContainer>
      </Styled.Overlay>
    </Styled.Root>
  );
};

export default SectionHero;
