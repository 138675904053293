import { FC } from 'react';
import Styled from 'src/components/layout/BulletPoint.styles';

interface BulletPointProps {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
}

const BulletPoint: FC<BulletPointProps> = ({ color = '#ffffff', size = 5, style = {} }) => {
  return <Styled.Root color={color} size={size} style={style} />;
};

export default BulletPoint;
