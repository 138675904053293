import { createContext } from 'react';
import { LocalStorage } from 'src/local-storage/local-storage';

export interface ISoundContext {
  playSoundClick: (e?: Event) => void;
  enableClickSounds: boolean;
  setEnableClickSounds: (enableClickSounds: boolean) => void;
}

export const initialContext: ISoundContext = {
  playSoundClick: () => undefined,
  enableClickSounds: LocalStorage.get('audio_enable_click_sounds') !== 'false',
  setEnableClickSounds: () => undefined,
};

const SoundContext = createContext<ISoundContext>(initialContext);

SoundContext.displayName = 'SoundContext';

export default SoundContext;
