import { styled } from '@mui/material';
// import { config } from 'src/config/config';

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const BackgroundImage = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 19 / 10;
  position: absolute;
  bottom: 0;
`;

const BackgroundImageGradient = styled('div')`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 20%,
    rgba(0, 0, 0, 0) 80%,
    rgba(0, 0, 0, 0) 100%
  );
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const LeftGutterWhiteShadow = styled('div')`
  display: block;
  width: 0;
  box-shadow: 70px 0 100px 100px #ffffff11;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

const RightGutterWhiteShadow = styled('div')`
  display: block;
  width: 0;
  box-shadow: -70px 0 100px 100px #ffffff11;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 75rem;
  padding: 3rem 2rem 6rem 2rem;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1200px) {
    padding: 2rem 2rem 6rem 2rem;
  }

  @media (max-width: 500px) {
    padding: 2rem 1.5rem 6rem 1.5rem;
  }
`;

const SeparatorContainer = styled('div')`
  display: block;
  width: 100%;
  margin: 2rem 0;
`;

const SeparatorText = styled('span')`
  display: inline-block;
  width: 10rem;
  vertical-align: middle;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.3rem;
  color: #ffffff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const SeparatorRight = styled('div')`
  background-color: #555555;
  display: inline-block;
  width: calc(100% - 10rem);
  height: 2px;
  vertical-align: middle;
  border-radius: 1px;
`;

export default {
  Root,
  BackgroundImage,
  BackgroundImageGradient,
  LeftGutterWhiteShadow,
  RightGutterWhiteShadow,
  CenteredContainer,
  SeparatorContainer,
  SeparatorText,
  SeparatorRight,
};
