import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const Copy = styled('div')`
  display: block;
  padding: 2rem;
  text-align: center;
  font-family: 'Kevlar Underwear';
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > span {
    color: ${customThemeOptions.custom.colors.Yellow};
  }
`;

export default { Root, Copy };
