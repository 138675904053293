import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-block;
  height: 100%;
  width: auto;
  flex-grow: 0;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
`;

interface ListingImageProps {
  isDaily: boolean;
}

const ListingImage = styled('img')<ListingImageProps>`
  display: block;
  height: 100%;
  width: auto;
  margin-top: ${({ isDaily }) => (!isDaily ? '0' : '.4rem')};
  box-shadow: ${({ isDaily }) => (!isDaily ? '0 20px 24px -2px #00000055' : 'none')};
`;

export default {
  Root,
  ListingImage,
};
