import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import Styled from 'src/components/primary-marketplace/components/Title.styles';

interface TitleProps {
  lpmId: string;
}

const Title: FC<TitleProps> = ({ lpmId }) => {
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));

  if (!listPriceMarket) return null;

  const { name, color } = listPriceMarket;

  return (
    <Styled.Root>
      <Styled.Title color={color}>{name}</Styled.Title>
    </Styled.Root>
  );
};

export default Title;
