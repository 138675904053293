import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { Waypoint } from 'react-waypoint';
import Styled from 'src/components/pages/getting-started/components/Step.styles';

interface Asset {
  title: string;
  width: string;
  height: string;
  path: string;
}

interface StepData {
  title: string;
  desc: string[];
  warning?: string;
  stepAsset: Asset;
  mediaAsset: Asset;
}

interface StepProps {
  stepIndex: number;
}

const Step: FC<StepProps> = ({ stepIndex }) => {
  const [inView, setInView] = useState(false);
  const { t: tGettingStarted } = useTranslation(nameSpace.gettingStarted);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const steps: StepData[] = tGettingStarted('getting.started.details.steps', { returnObjects: true });
  const step = steps[stepIndex];

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root key={`getting-started-step-${stepIndex}`} className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
        <Styled.StepIcon
          src={`/assets/${step.stepAsset.path}`}
          width={step.stepAsset.width}
          height={step.stepAsset.height}
          title={step.stepAsset.title}
          alt={step.stepAsset.title}
        />

        {stepIndex !== steps.length - 1 && <Styled.Line />}

        <Styled.ContentContainer>
          <Styled.ContentTitle>{step.title}</Styled.ContentTitle>

          {step.desc.map((desc, j) => {
            return <Styled.ContentDesc key={`getting-started-step-${stepIndex}-desc-${j}`}>{desc}</Styled.ContentDesc>;
          })}

          {step.warning && <Styled.ContentWarning>{`*${step.warning}`}</Styled.ContentWarning>}
        </Styled.ContentContainer>

        <Styled.MediaContainer>
          {/* <Styled.Media
            src={`/assets/${step.mediaAsset.path}`}
            width={step.mediaAsset.width}
            height={step.mediaAsset.height}
            title={step.mediaAsset.title}
            alt={step.mediaAsset.title}
          /> */}
          <Styled.Video
            width={step.mediaAsset.width}
            height={step.mediaAsset.height}
            controls={false}
            loop
            autoPlay
            muted
            playsInline
          >
            <source src={step.mediaAsset.path} type="video/mp4" />
            {tCommon('common.video.support')}
          </Styled.Video>
        </Styled.MediaContainer>
      </Styled.Root>
    </Waypoint>
  );
};

export default Step;
