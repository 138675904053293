import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  font-size: 0;
  position: absolute;
  top: 50%;
  left: 10rem;
  transform: translate(0, -50%);

  & > div:not(:last-child) {
    margin-right: 1.5rem;

    @media (max-width: 1000px) {
      margin-right: 1rem;
    }
  }

  @media (max-width: 1000px) {
    left: 9.3rem;
  }
`;

interface LinkProps {
  active: boolean;
}

const Link = styled('div')<LinkProps>`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  position: relative;

  & > div {
    opacity: ${({ active }) => (active ? '1' : '0')};
  }

  & > p {
    color: ${({ active, theme }) => (active ? theme.custom.colors.Yellow : '#fff')};
  }
`;

const LinkIndicator = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.Yellow};
  display: block;
  width: 5px;
  height: 5px;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0) rotate(45deg);

  @media (max-width: 1000px) {
    width: 4px;
    height: 4px;
    top: -8px;
  }
`;

const LinkTitle = styled('p')`
  display: block;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;
  transition: color 0.4s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1150px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

export default { Root, Link, LinkIndicator, LinkTitle };
