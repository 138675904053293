import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useBlogContext from 'src/hooks/useBlogContext';
import Styled from 'src/components/pages/blog-details/components/SectionHero.styles';

type Hero = {
  path: string;
};

const SectionHero: FC = () => {
  const { blogPosts } = useBlogContext();
  const { blogTitle = '' } = useParams();
  const { t } = useTranslation(nameSpace.blogDetails);
  const hero: Hero = t('blog.details.hero.asset', { returnObjects: true });
  const decodedTitle = decodeURIComponent(blogTitle);
  const blogPost = blogPosts.find(post => post.title === decodedTitle);
  const { pubDate = '', title = '' } = blogPost || {};

  return (
    <Styled.Root style={{ backgroundImage: `url(/assets/${hero.path})` }}>
      <Styled.HeroBottomBorder />

      <Styled.Overlay className={'inview animated fadeIn'}>
        <Styled.CopyContainer>
          <Styled.Title>{title}</Styled.Title>
          <Styled.Date>{formatInTimeZone(new Date(pubDate), 'America/New_York', 'LLLL dd, yyyy')}</Styled.Date>
        </Styled.CopyContainer>
      </Styled.Overlay>
    </Styled.Root>
  );
};

export default SectionHero;
