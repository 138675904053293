import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LocalStorage } from 'src/local-storage/local-storage';
// import { start } from 'src/api/auth/start';
import { finish } from 'src/api/auth/finish';
import { getCurrentProfile } from 'src/api/profile/get-current-profile';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useQuery } from 'src/hooks/useQuery';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { PooleEvents } from 'src/modules/poole/events';
import { GTMEvents } from 'src/modules/gtm/events';
import { logMain } from 'src/modules/logger/logger';
import { setAuthenticated } from 'src/redux/slices/authenticated/authenticated';
import { setCurrentProfile } from 'src/redux/slices/current-profile/current-profile';
import Loader from 'src/components/loader/Loader';
import AuthContext, { initialContext, IAuthContext } from 'src/context/auth/Auth.context';
import { saveDevicePlayerId } from 'src/local-storage/helpers/saveDevicePlayerId';

interface SessionVariables {
  sessionToken?: string | null | undefined;
  sessionTokenStart: string | null | undefined;
  sessionTokenExp?: string | null | undefined;
}

// const checkSessionExistence = ({ sessionToken, sessionTokenStart, sessionTokenExp }: SessionVariables): boolean => {
//   if (sessionToken && sessionTokenStart && sessionTokenExp) return true;

//   return false;
// };

const isSessionValid = ({ sessionToken, sessionTokenStart, sessionTokenExp }: SessionVariables): boolean => {
  if (sessionToken && sessionTokenStart && sessionTokenExp) {
    return parseInt(sessionTokenExp, 10) > Date.now();
  }

  return false;
};

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [processingAuth, setProcessingAuth] = useState<IAuthContext['processingAuth']>(initialContext.processingAuth);
  const params: (string | null)[] = useQuery(urlParams.home);
  const state = getURLParamValue('state', urlParams.home, params);
  const code = getURLParamValue('code', urlParams.home, params);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigateInternal = useAppNavigateInternal();

  useEffect(() => {
    if (processingAuth) {
      // Grab session token and exp from local storage if the exist and check if session is valid
      const localSessionToken = LocalStorage.get('session_token');
      const localSessionTokenStart = LocalStorage.get('session_token_start');
      const localSessionTokenExp = LocalStorage.get('session_token_exp');
      const localRedirectPath = LocalStorage.get('redirect_path');
      const localEnableRedirect = LocalStorage.get('enable_redirect');
      const validSession = isSessionValid({
        sessionToken: localSessionToken,
        sessionTokenStart: localSessionTokenStart,
        sessionTokenExp: localSessionTokenExp,
      });

      logMain.debug({
        location,
        hostLocalStorage: LocalStorage.getAll(),
        validSession,
        state,
        code,
      });

      const handleClearSearchParams = () => {
        if (searchParams.has('state') && searchParams.has('code')) {
          searchParams.delete('state');
          searchParams.delete('code');
          searchParams.delete('error');
          searchParams.delete('error_description');
          setSearchParams(searchParams.toString());
        }
      };

      const handleClearRedirectLocalStorage = () => {
        LocalStorage.set('enable_redirect', 'false');
        LocalStorage.set('redirect_path', '');
      };

      // Attempts to load profile data for user
      const handleGetProfile = async () => {
        try {
          const profile = await dispatch(getCurrentProfile());

          // Save player id to local storage device player ids
          saveDevicePlayerId(profile.id);

          dispatch(setCurrentProfile(profile)); // Redux: Set current profile as returned profile
        } catch (e) {
          logMain.debug('Error loading current profile.');
        } finally {
          setProcessingAuth(false);
        }
      };

      // Attempts to start authentication for user
      // const handleAuthStart = async () => {
      //   try {
      //     const authStartData = await dispatch(start()); // Start Auth if auth not started
      //     const { authUrl } = authStartData;
      //     LocalStorage.set('redirect_path', `${location.pathname}${location.search}${location.hash}`);
      //     LocalStorage.set('enable_redirect', 'true'); // Set "enable_redirect" localStorage field to redirect on return

      //     if (authUrl) {
      //       window.location.href = authUrl; // Redirect user to auth flow
      //     }
      //   } catch (e) {
      //     logMain.debug('Error <handleAuthStart>', e);
      //   } finally {
      //     setProcessingAuth(false);
      //   }
      // };

      // Attempts to finish authentication for user
      const handleAuthFinish = async ({ s, c }: { s: string; c: string }) => {
        try {
          // Fire Poole event for auth finish
          PooleEvents.AuthFinish({
            from: window.location.pathname,
            fromContext: 'app_startup_protocol',
          });

          // Fire GTM event for auth finish
          GTMEvents.authFinish({ eventContext: 'auth-finish' });

          const authFinishData = await dispatch(finish({ data: { state: s, code: c } })); // Finish Auth if auth started
          const { sessionToken, expiration, profile, isNewPlayer } = authFinishData;

          if (sessionToken && expiration) {
            LocalStorage.set('session_token', sessionToken);
            LocalStorage.set('session_token_start', Date.now().toString());
            LocalStorage.set('session_token_exp', expiration);
            LocalStorage.set('session_token_last_refresh', '');
          }

          // Save player id to local storage device player ids
          saveDevicePlayerId(profile.id);

          // Fire Poole event for auth finish success
          PooleEvents.AuthFinishSuccess({
            playerId: profile.id,
            from: window.location.pathname,
            fromContext: 'app_startup_protocol',
            authType: isNewPlayer ? 'signup' : 'login',
          });

          // Fire GTM event for auth finish success
          if (isNewPlayer) {
            GTMEvents.authFinishSuccessSignup({ userId: profile.id, eventContext: 'auth-finish-success-signup' });
          } else {
            GTMEvents.authFinishSuccessLogin({ userId: profile.id, eventContext: 'auth-finish-success-login' });
          }

          dispatch(setCurrentProfile(profile)); // Redux: Set current profile as returned profile
          dispatch(setAuthenticated(true)); // Redux: Set user as Authenticated

          handleClearSearchParams();

          if (localEnableRedirect === 'true' && localRedirectPath) {
            navigateInternal(localRedirectPath, {
              pooleData: { playerId: profile.id, fromContext: 'app_startup_protocol' },
            });
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          logMain.debug('Error <handleAuthFinish>', e);

          // Fire Poole event for auth finish fail
          PooleEvents.AuthFinishFail({
            from: window.location.pathname,
            fromContext: 'app_startup_protocol',
            reason: e instanceof Error ? e.message : String(e),
          });

          // Fire GTM event for auth finish fail
          GTMEvents.authFinishFail({ eventContext: 'auth-finish-fail' });
        } finally {
          handleClearRedirectLocalStorage();
          setProcessingAuth(false);
        }
      };

      // Attempts to start or finish authentication for user depending on state
      const handleInvalidSession = async () => {
        try {
          if (state && code) {
            await handleAuthFinish({ s: state, c: code });
          } else {
            await setProcessingAuth(false);
          }
        } catch (e) {
          logMain.debug('Error <handleAuth>', e);
        }
      };

      // If user's existing session is valid, set user authenticated status to true and load necessary profile data
      // Else, attempt to authenticate user
      if (validSession) {
        handleClearRedirectLocalStorage();
        handleClearSearchParams();
        dispatch(setAuthenticated(true)); // Redux: Set user as Authenticated
        handleGetProfile();
      } else {
        handleInvalidSession();
      }
    }
  }, [code, dispatch, processingAuth, navigateInternal, state, searchParams, setSearchParams]);

  const value = useMemo(
    () => ({
      processingAuth,
      setProcessingAuth,
    }),
    [processingAuth, setProcessingAuth],
  );

  if (processingAuth) return <Loader />;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
