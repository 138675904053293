import { FC, useState } from 'react';
import { nameSpace } from 'src/modules/i18n';
import { useTranslation } from 'react-i18next';
import { PooleEvents } from 'src/modules/poole/events';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import BaseIcon from 'src/components/icons/BaseIcon';
import ForteWhiteHeaderIcon from 'src/components/icons/ForteWhiteHeaderIcon';
import SecondaryPurchaseModal from 'src/components/modals/SecondaryPurchaseModal';
import { config } from 'src/config/config';
import Styled from 'src/components/pages/nft/components/PurchaseButton.styles';

interface PurchaseButtonProps {
  nft: Nonfungible;
}

const PurchaseButton: FC<PurchaseButtonProps> = ({ nft }) => {
  const [purchaseModal, setPurchaseModal] = useState(false);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t } = useTranslation(nameSpace.common);
  const navigateExternal = useAppNavigateExternal();

  // NFT Pack Opening Functions
  const handleOpenPurchaseModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.SecondaryPurchaseStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_details',
      nonfungibleId: nft?.id || '',
      listingId: nft?.listingId || '',
      priceLC: (nft?.price || 0).toString(),
      priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    setPurchaseModal(true);
  };

  const handleClosePurchaseModal = async () => {
    await setPurchaseModal(false);
  };

  if (!nft?.listingId) return null;

  return (
    <Styled.Root>
      <SecondaryPurchaseModal nft={nft} open={purchaseModal} onClose={handleClosePurchaseModal} />

      <Styled.Button disabled={nft?.listingStatus === 'pending'} onClick={handleOpenPurchaseModal}>
        {nft?.listingStatus === 'pending' ? (
          <span>
            <span className="material-icons-outlined">restore</span>
            <span>{t('common.term.purchase.pending')}</span>
          </span>
        ) : (
          <span>
            <span className="material-icons-outlined">shopping_cart</span>
            <span>{t('common.term.purchase')}</span>
          </span>
        )}
      </Styled.Button>

      <Styled.BrandsContainer>
        <Styled.ForteContainer>
          <span className="material-icons">lock</span>
          <Styled.ForteBrandingLabel>{t('common.powered.by')}</Styled.ForteBrandingLabel>

          <BaseIcon
            hoverColor="#FEC300"
            cursor="pointer"
            icon={<ForteWhiteHeaderIcon />}
            padding="0"
            style={{ display: 'inline-block', verticalAlign: 'middle', margin: '1.1rem 0 0 0' }}
            onClick={() => {
              navigateExternal(t('common.forte.link'), {
                newWindow: true,
                pooleData: { pageName: 'Forte', fromContext: 'nft_details' },
              });
            }}
          />
        </Styled.ForteContainer>
      </Styled.BrandsContainer>
    </Styled.Root>
  );
};

export default PurchaseButton;
