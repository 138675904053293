import { createContext } from 'react';

export interface ILeaderboardsContext {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
}

export const initialContext: ILeaderboardsContext = {
  selectedCategory: '',
  setSelectedCategory: () => undefined,
  pageNumber: 1,
  setPageNumber: () => undefined,
};

const LeaderboardsContext = createContext<ILeaderboardsContext>(initialContext);

LeaderboardsContext.displayName = 'LeaderboardsContext';

export default LeaderboardsContext;
