import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: #ffffff;
  display: block;
  width: 100%;
  position: relative;
`;

interface InnerContainerProps {
  extraPadding: boolean;
}

const InnerContainer = styled('div')<InnerContainerProps>`
  display: block;
  width: 100%;
  padding: ${({ extraPadding }) => (extraPadding ? '2rem 2rem 14rem 2rem' : '2rem 2rem 4rem 2rem')};
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: ${({ extraPadding }) => (extraPadding ? '2rem 0 14rem 0' : '2rem 0 4rem 0')};
  }
`;

const BackgroundImage = styled('img')`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 75rem;
  padding: 3rem 2rem 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  & > div:not(:first-of-type) {
    margin-top: 2rem;
  }

  @media (max-width: 600px) {
    padding: 3rem 2rem 0 1.3rem;
  }
`;

const StepContainer = styled('div')`
  display: block;
  width: 100%;
  padding: 0 0 6rem 7rem;
  box-sizing: border-box;
  /* border: 1px solid #000000; */
  position: relative;

  @media (max-width: 600px) {
    padding: 0 0 6rem 4.9rem;
  }
`;

const StepIcon = styled('img')`
  display: block;
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: 600px) {
    width: 3.6rem;
    height: 3.6rem;
  }
`;

const Line = styled('div')`
  background-color: #000000;
  display: inline-block;
  width: 2px;
  position: absolute;
  top: 7rem;
  bottom: 0;
  left: 2.5rem;

  @media (max-width: 600px) {
    top: 5.6rem;
    left: 1.8rem;
  }
`;

const ContentContainer = styled('div')`
  display: inline-block;
  width: calc(100% - 2rem - 30rem);
  padding-top: 1.5rem;
  margin-right: 2rem;
  vertical-align: top;

  @media (max-width: 1000px) {
    display: block;
    width: 100%;
    padding-top: 0.7rem;
    margin-right: 0;
  }
`;

const ContentTitle = styled('p')`
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 800;
  line-height: 2rem;
  color: #000000;
`;

const ContentDesc = styled('p')`
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: #000000;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  &:first-of-type {
    margin-top: 2rem;
  }
`;

const MediaContainer = styled('div')`
  display: inline-block;
  width: 30rem;
  padding-top: 1.5rem;
  vertical-align: top;

  @media (max-width: 1000px) {
    display: block;
    width: 100%;
    max-width: 30rem;
    padding-top: 0.7rem;
    margin-top: 4rem;
  }
`;

const Media = styled('img')`
  background-color: #777777;
  display: block;
  width: 100%;
  height: 18rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 7px #00000033;
`;

export default {
  Root,
  InnerContainer,
  BackgroundImage,
  CenteredContainer,
  StepContainer,
  StepIcon,
  Line,
  ContentContainer,
  ContentTitle,
  ContentDesc,
  MediaContainer,
  Media,
};
