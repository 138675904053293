import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-block;
  width: 55%;
  padding: 1rem 0 2rem 3rem;
  vertical-align: top;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    /* max-width: 30rem; */
    margin: 0 auto;
    padding: 2rem 0;
  }
`;

const TitleContainer = styled('div')`
  display: block;
  position: relative;
`;

const Title = styled('p')`
  display: inline-block;
  margin-left: -0.2rem;
  margin-right: 1rem;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 800;
  line-height: 4rem;
  color: #ffffff;
  /* transform: scale(0.9, 1); */

  @media (max-width: 1000px) {
    margin-left: -1px;
    font-size: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 768px) {
    margin-left: -1px;
    font-size: 3rem;
    line-height: 3rem;
  }
`;

const PriceContainer = styled('div')`
  display: block;
  margin-top: 2rem;
`;

const PricePrimary = styled('p')`
  display: block;
  text-align: left;
  /* text-transform: uppercase; */
  /* color: #ffffff; */
  color: ${({ theme }) => theme.custom.colors.Yellow};

  & > span {
    display: inline-block;
    vertical-align: bottom;
  }

  & > span:first-of-type {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4rem;
  }

  & > span:last-of-type {
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.2rem;
  }
`;

const PriceSecondary = styled('p')`
  display: block;
  margin-top: 0.4rem;
  text-align: left;
  /* text-transform: uppercase; */
  color: #ffffff77;

  & > span {
    display: inline-block;
    vertical-align: bottom;
  }

  & > span:first-of-type {
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.1rem;
  }

  & > span:last-of-type {
    font-size: 0.7rem;
    font-weight: 400;
    line-height: 0.8rem;
  }
`;

const FeesContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;

  & > div:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const FeeRow = styled('div')`
  display: block;
  width: 100%;

  & > div {
    vertical-align: top;
  }
`;

const FeeLabel = styled('div')`
  display: inline-block;
  width: calc(50% - 1rem);
  margin-right: 1rem;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.1rem;
  color: #ffffff77;
`;

const FeeValue = styled('div')`
  display: inline-block;
  width: 50%;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.1rem;
  color: #ffffff;
`;

const ActionButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  margin: 2rem 0;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

interface ButtonProps {
  disabled?: boolean;
}

const ActionButton = styled('div')<ButtonProps>`
  background-color: transparent;
  display: block;
  width: 100%;
  height: calc(2.7rem - 2px);
  border-radius: 0.2rem;
  border: 1px solid #ffffff77;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ disabled }) => (disabled ? '#ffffff77' : '#ffffff')};
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & > span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & > span > span {
    display: inline-block;
    vertical-align: middle;
  }

  & .material-icons,
  & .material-icons-outlined {
    margin-top: 2px;
    margin-right: 0.3rem;
    font-size: 0.9rem;
    color: ${({ disabled }) => (disabled ? '#ffffff77' : '#ffffff')};
  }

  &:hover {
    & .material-icons,
    & .material-icons-outlined {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }

    background-color: #ffffff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }
`;

export default {
  Root,
  TitleContainer,
  Title,
  PriceContainer,
  PricePrimary,
  PriceSecondary,
  FeesContainer,
  FeeRow,
  FeeLabel,
  FeeValue,
  ActionButtonContainer,
  ActionButton,
};
