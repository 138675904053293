import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState: TutorialStatesState = {
  tutorialStates: [],
};

const tutorialStatesSlice = createSlice({
  name: 'tutorialStates',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setTutorialStates: {
      reducer(state, action: PayloadAction<TutorialStatesPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.tutorialStates = [...action.payload.tutorialStates];
      },
      prepare(tutorialStates: TutorialState[]) {
        return {
          payload: {
            tutorialStates,
          },
        };
      },
    },
    clearTutorialStates: state => {
      // eslint-disable-next-line no-param-reassign
      state.tutorialStates = initialState.tutorialStates;
    },
  },
});

export const { setTutorialStates, clearTutorialStates } = tutorialStatesSlice.actions;

export default tutorialStatesSlice.reducer;
