import { styled } from '@mui/material';

interface RootProps {
  starSize?: number;
}

const Root = styled('div')<RootProps>`
  display: flex;
  width: 100%;
  margin: 0 0 0 -0.1rem;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;

  & > svg {
    display: inline-block;
    margin: 0.7rem 0 0 0;
    /* height: 1.4rem; */
    width: ${({ starSize }) => `${starSize}px`};
    height: ${({ starSize }) => `${starSize}px`};
  }
`;

export default {
  Root,
};
