/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useEffect, useCallback } from 'react';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
// import { PooleEvents } from 'src/modules/poole/events';
import ProgressiveWebAppContext, {
  initialContext,
  IProgressiveWebAppContext,
} from 'src/context/progressive-web-app/ProgressiveWebApp.context';
import { checkDisplayMode } from 'src/tools/checkDisplayMode';
import { logMain } from 'src/modules/logger/logger';
import { LocalStorage } from 'src/local-storage/local-storage';

interface ProgressiveWebAppProviderProps {
  children: ReactNode;
}

const ProgressiveWebAppProvider: FC<ProgressiveWebAppProviderProps> = ({ children }) => {
  const [showSaveToPhoneModal, setShowSaveToPhoneModal] = useState<IProgressiveWebAppContext['showSaveToPhoneModal']>(
    initialContext.showSaveToPhoneModal,
  );
  const [optOut, setOptOut] = useState<IProgressiveWebAppContext['optOut']>(initialContext.optOut);
  const [userOptedOut, setUserOptedOut] = useState<IProgressiveWebAppContext['userOptedOut']>(
    initialContext.userOptedOut,
  );
  const [saveToPhoneAcknowledged, setSaveToPhoneAcknowledged] = useState<
    IProgressiveWebAppContext['saveToPhoneAcknowledged']
  >(initialContext.saveToPhoneAcknowledged);
  const [promptEvent, setPromptEvent] = useState<IProgressiveWebAppContext['promptEvent']>(initialContext.promptEvent);

  const handleInstall = useCallback(async () => {
    if (!promptEvent) return;

    // Activate install prompt
    promptEvent.prompt();

    // Wait for user to respond to install prompt
    const { outcome } = await promptEvent.userChoice;

    if (outcome === 'accepted') {
      logMain.debug('[PWA]: User accepted the A2HS prompt and installed app to homescreen.');
      setSaveToPhoneAcknowledged(true);
      setPromptEvent(initialContext.promptEvent);
    } else {
      logMain.debug('[PWA]: User dismissed the A2HS prompt and did NOT install app to homescreen.');
    }
  }, [promptEvent]);

  const handleClose = useCallback(async () => {
    if (optOut) {
      LocalStorage.set('opted_out_of_pwa', 'true');
      setUserOptedOut(true);
    } else {
      LocalStorage.set('opted_out_of_pwa', 'false');
      setUserOptedOut(false);
    }

    setSaveToPhoneAcknowledged(true);
    setPromptEvent(initialContext.promptEvent);
  }, [optOut]);

  useEffect(() => {
    const handleBeforeInstallPromptEvent = (e: any) => {
      e.preventDefault(); // Prevent Chrome 67 and earlier from automatically showing the prompt
      logMain.debug('[PWA]: Event fired - beforeinstallprompt', e);
      setPromptEvent(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);

    return function cleanup() {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);
    };
  }, []);

  // Listen for Device type and localstorage state and see if we should show Save to Phone Modal
  useEffect(() => {
    const handleShowSaveToPhoneModal = () => {
      const isInFullscreenMode = checkDisplayMode('fullscreen');
      const isInStandaloneMode = checkDisplayMode('standalone');

      if (saveToPhoneAcknowledged) {
        // If user has already acknowledged the Save to Phone Modal, don't show it again
        setShowSaveToPhoneModal(false);
      } else if (!isInFullscreenMode && !isInStandaloneMode && !userOptedOut) {
        if (isMobile && (isIOS || isAndroid)) {
          // If user is on mobile and they are on iOS or Android, show Save to Phone Modal
          setShowSaveToPhoneModal(true);
        }
      } else {
        if (isInFullscreenMode) logMain.debug('[PWA]: App is in Fullscreen mode.');
        if (isInStandaloneMode) logMain.debug('[PWA]: App is in Standalone mode.');
        if (userOptedOut) logMain.debug('[PWA]: User opted out of PWA.');
      }
    };

    handleShowSaveToPhoneModal();
  }, [saveToPhoneAcknowledged, userOptedOut]);

  const value = useMemo(
    () => ({
      handleInstall,
      handleClose,
      showSaveToPhoneModal,
      setShowSaveToPhoneModal,
      optOut,
      setOptOut,
      userOptedOut,
      setUserOptedOut,
      saveToPhoneAcknowledged,
      setSaveToPhoneAcknowledged,
      promptEvent,
      setPromptEvent,
    }),
    [
      handleInstall,
      handleClose,
      showSaveToPhoneModal,
      setShowSaveToPhoneModal,
      optOut,
      setOptOut,
      userOptedOut,
      setUserOptedOut,
      saveToPhoneAcknowledged,
      setSaveToPhoneAcknowledged,
      promptEvent,
      setPromptEvent,
    ],
  );

  return <ProgressiveWebAppContext.Provider value={value}>{children}</ProgressiveWebAppContext.Provider>;
};

export default ProgressiveWebAppProvider;
