import { styled } from '@mui/material';

interface RootProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
}

const Root = styled('div')<RootProps>`
  background-color: ${({ colors }) => `${colors.gradientColor1}33`};
  display: flex;
  height: 2.4rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: -3.4rem;
  /* border-radius: 0.3rem; */
  transition: background-color 0.3s ease-in-out;

  /* @media (max-width: 500px) {
    width: 100%;
    text-align: center;
  } */
`;

interface ContentContainerProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
}

const ContentContainer = styled('div')<ContentContainerProps>`
  display: inline-block;
  padding: 0 0.5rem 0 2.1rem;
  position: relative;

  & .material-icons-outlined {
    font-size: 1.3rem;
    color: ${({ colors }) => colors.gradientColor1};
    position: absolute;
    top: 51%;
    left: 0.4rem;
    transform: translate(0, -50%);
    transition: color 0.3s ease-in-out;
  }
`;

interface CountdownTitleProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
}

const CountdownTitle = styled('div')<CountdownTitleProps>`
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.1rem;
  color: ${({ colors }) => colors.gradientColor1};

  & > span {
    color: ${({ colors }) => colors.gradientColor2};
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

interface CountdownContainerProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
}

const CountdownContainer = styled('div')<CountdownContainerProps>`
  display: inline-block;
  /* margin-left: 2rem; */
  margin-top: -0.1rem;
  margin-left: 0.7rem;
  padding-bottom: 0.9rem;
  vertical-align: middle;

  & > div:not(:last-child) {
    margin-right: 0.7rem;
  }

  & span {
    color: ${({ colors }) => colors.gradientColor1};
  }
`;

const CountdownSegment = styled('div')`
  /* background-color: #00000030; */
  display: inline-block;
  width: 1.8rem;
  vertical-align: middle;
  border-radius: 0.3rem;
  position: relative;

  /* @media (max-width: 768px) {
    width: 1.4rem;
  } */
`;

const CountdownSegmentBottom = styled('div')`
  background-color: #ffffff11;
  display: block;
  width: 100%;
  height: 50%;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const CountdownSegmentData = styled('span')`
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 700;
  /* color: #ffffff; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* @media (max-width: 768px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
  } */
`;

const CountdownSegmentLabel = styled('span')`
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.6rem;
  line-height: 0.8rem;
  font-weight: 400;
  /* color: #ffffff; */
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translate(-50%, 0);

  /* @media (max-width: 768px) {
    top: 0.4rem;
  } */
`;

export default {
  Root,
  ContentContainer,
  CountdownTitle,
  CountdownContainer,
  CountdownSegment,
  CountdownSegmentBottom,
  CountdownSegmentData,
  CountdownSegmentLabel,
};
