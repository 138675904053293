import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: visible;

  & > iframe {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: calc(16 / 9);
    border: none;
  }
`;

const LoadingSplash = styled('div')`
  background-image: url('/assets/game/LoadingScreen-Flat.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

interface LoadingBarContainerProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
  progress: number;
}

const LoadingBarContainer = styled('div')<LoadingBarContainerProps>`
  background: ${({ colors }) => `linear-gradient(-90deg, ${colors.gradientColor1} 0%, ${colors.gradientColor2} 100%)`};
  display: block;
  height: 1.5rem;
  border: 2px solid ${({ colors }) => colors.gradientColor1};
  overflow: visible;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;

  & #loading-shrink {
    background: #000000;
    display: block;
    width: ${({ progress }) => `${100 - progress}%`};
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }

  & #loading-grow {
    display: block;
    width: ${({ progress }) => `${progress}%`};
    height: calc(1.5rem - 4px);
    box-shadow: ${({ progress, colors }) => (progress > 0 ? `0 0 7px 7px ${colors.gradientColor1}99` : 'none')};
    overflow: auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export default {
  Root,
  LoadingSplash,
  LoadingBarContainer,
};
