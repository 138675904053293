import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// import PageError from 'src/components/feedback/PageError';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import loadLeaderboard from 'src/components/pages/leaderboard/utils/load-leaderboard';
import SectionHero from 'src/components/pages/leaderboard/components/SectionHero';
import SelectBar from 'src/components/pages/leaderboard/components/SelectBar';
import SectionDetails from 'src/components/pages/leaderboard/components/SectionDetails';
import Styled from 'src/components/pages/leaderboard/Leaderboard.styles';
// import { logMain } from 'src/modules/logger/logger';

const Leaderboard: FC = () => {
  const { leaderboardId = '' } = useParams();
  const leaderboardIdRef = useRef(leaderboardId);
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const [loading, setLoading] = useState<boolean>(true);
  const [resultsFound, setResultsFound] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigateInternal = useAppNavigateInternal();

  useEffect(() => {
    const handleLoad = async () => {
      const leaderboardsEnabled = currentProfile?.features?.leaderboards?.enabled;

      // Prevent access to page if not authenticated or leaderboards are not enabled
      if (!authenticated || !leaderboardsEnabled) {
        navigateInternal('/page-not-found', { pooleData: { fromContext: 'leaderboard' } });
      } else {
        await setLoading(false);
      }

      loadLeaderboard(dispatch, leaderboardId, setResultsFound);
    };

    if (loading) {
      handleLoad();
    }
  }, [
    authenticated,
    currentProfile?.features?.leaderboards?.enabled,
    dispatch,
    leaderboardId,
    loading,
    navigateInternal,
  ]);

  useEffect(() => {
    if (!loading && leaderboardIdRef.current !== leaderboardId) {
      loadLeaderboard(dispatch, leaderboardId, setResultsFound);
      leaderboardIdRef.current = leaderboardId;
    }
  }, [dispatch, leaderboardId, loading]);

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;

  return (
    <Styled.Root>
      <SectionHero />
      <SelectBar />
      <SectionDetails resultsFound={resultsFound} setResultsFound={setResultsFound} />
    </Styled.Root>
  );
};

export default Leaderboard;
