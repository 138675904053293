/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
// import { PooleEvents } from 'src/modules/poole/events';
import BlogContext, { initialContext, IBlogContext } from 'src/context/blog/Blog.context';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getBlog } from 'src/api/blog/getBlog';
import { getItemsFromRSS } from 'src/tools/getItemsFromRSS';
import { logMain } from 'src/modules/logger/logger';

interface BlogProviderProps {
  children: ReactNode;
}

const BlogProvider: FC<BlogProviderProps> = ({ children }) => {
  const [loadBlogPosts, setLoadBlogPosts] = useState<IBlogContext['loadBlogPosts']>(initialContext.loadBlogPosts);
  const [isLoadingBlogPosts, setIsLoadingBlogPosts] = useState<IBlogContext['isLoadingBlogPosts']>(
    initialContext.isLoadingBlogPosts,
  );
  const [blogPosts, setBlogPosts] = useState<IBlogContext['blogPosts']>(initialContext.blogPosts);
  const dispatch = useAppDispatch();

  // Load Blog
  useEffect(() => {
    const handleLoadSuccess = async (data: any) => {
      const blogXML = data;
      const parsedRSSItems = getItemsFromRSS(blogXML);

      if (parsedRSSItems.length === 0) {
        await setBlogPosts(initialContext.blogPosts);
      } else {
        const ammendedRSSItems = parsedRSSItems.map((item, i) => {
          return {
            ...item,
            id: i,
          };
        });

        await setBlogPosts(ammendedRSSItems);
      }

      setIsLoadingBlogPosts(false);
    };

    const handleLoadFail = async () => {
      await setBlogPosts(initialContext.blogPosts);
      setIsLoadingBlogPosts(false);
    };

    if (loadBlogPosts) {
      setLoadBlogPosts(false);
      setIsLoadingBlogPosts(true);

      dispatch(getBlog({}))
        .then((data: any) => {
          handleLoadSuccess(data);
        })
        .catch((e: any) => {
          logMain.debug(e);

          handleLoadFail();
        });
    }
  }, [dispatch, loadBlogPosts]);

  const value = useMemo(
    () => ({
      loadBlogPosts,
      setLoadBlogPosts,
      isLoadingBlogPosts,
      setIsLoadingBlogPosts,
      blogPosts,
      setBlogPosts,
    }),
    [loadBlogPosts, setLoadBlogPosts, isLoadingBlogPosts, setIsLoadingBlogPosts, blogPosts, setBlogPosts],
  );

  return <BlogContext.Provider value={value}>{children}</BlogContext.Provider>;
};

export default BlogProvider;
