import { FC, ReactNode, useCallback, useEffect } from 'react';
import { isSafari } from 'react-device-detect';
import { useLocation, useNavigationType } from 'react-router-dom';
import { PooleEvents } from 'src/modules/poole/events';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { LocalStorage } from 'src/local-storage/local-storage';
import { logMain } from 'src/modules/logger/logger';

const RouteWrapper: FC<{ children: ReactNode; pageType: string }> = ({ children, pageType }) => {
  const routerLocation = useLocation();
  const navigationType = useNavigationType();
  const playerId = useAppSelector(({ currentProfile }) => currentProfile.currentProfile.id) || '';
  const publicUrl =
    process.env.PUBLIC_URL === '/' || !process.env.PUBLIC_URL
      ? `http://localhost:${process.env.PORT || 3000}`
      : process.env.PUBLIC_URL; // Default value for development

  const handleOnPageChange = useCallback(() => {
    let refreshPage = false;

    logMain.debug('%c[ROUTER]: Router updated -->', 'color: orange;', {
      data: {
        routerLocation,
        title: document.title,
        referrer: document.referrer,
        href: location.href,
        path: routerLocation.pathname,
        type: pageType,
      },
    });

    PooleEvents.PageLoad({ playerId, pageName: pageType, url: routerLocation.pathname });

    // Handle case when user hits back button while playing game in Safari
    // This is here to ensure memory is cleared properly in between plays if user hits back button.
    if (isSafari && navigationType === 'POP' && LocalStorage.get('navigated_to_play_page') === 'true') {
      refreshPage = true;

      logMain.debug('[ROUTER]: Detected back button press in Safari while playing game. Reloading page.');
    }

    LocalStorage.set('navigated_to_play_page', 'false');

    // If you need to force reload the page, uncomment the line below
    if (refreshPage) {
      window.location.href = `${publicUrl}${routerLocation.pathname}`; // Uncomment if you really need to force reload
    }
  }, [navigationType, pageType, playerId, publicUrl, routerLocation]);

  useEffect(() => {
    handleOnPageChange();
    // eslint-disable-next-line
  }, [routerLocation]);

  return <div>{children}</div>;
};

export default RouteWrapper;
