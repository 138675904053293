/* eslint-disable no-nested-ternary */
import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.Yellow};
  display: block;
  width: 100%;
  max-width: 19rem;
  height: 100%;
  position: relative;

  @media (max-width: 500px) {
    max-width: none;
  }
`;

const DropdownButton = styled('div')`
  background-color: #ffffff55;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffffff77;
  }

  & .material-icons-outlined {
    color: #000000;
  }
`;

const Value = styled('p')`
  display: inline-block;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  color: #000000;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffffff33;
  }
`;

const MenuContainer = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  display: block;
  width: 100%;
  min-width: 15rem;
  padding: 0.4rem 0;

  @media (max-width: 500px) {
    min-width: auto;
  }
`;

interface MenuItemProps {
  disabled?: boolean;
  selected?: boolean;
}

const MenuItem = styled('div')<MenuItemProps>`
  background-color: ${({ theme, selected }) => (selected ? theme.custom.colors.Yellow : 'inherit')};
  display: block;
  width: 100%;
  padding: 0.8rem 1rem;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  color: ${({ theme, disabled, selected }) =>
    disabled ? '#ffffff33' : selected ? '#000000' : theme.custom.colors.Yellow};
  transition: background-color 0.4s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

  &:hover {
    background-color: ${({ theme, disabled, selected }) =>
      disabled ? '#00000000' : selected ? theme.custom.colors.Yellow : '#ffffff22'};
  }
`;

export default {
  Root,
  DropdownButton,
  Value,
  MenuContainer,
  MenuItem,
};
