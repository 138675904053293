import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  margin-top: 1.3rem;
`;

const HeroProgressContainer = styled('div')`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
`;

const HeroProgressSegment = styled('div')`
  display: inline-block;
  width: 50%;
  padding: 0 1.5rem;
  flex-grow: 1;
`;

const HeroProgressTitle = styled('p')`
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: #ffffff77;
`;

const HeroProgressLevelContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  text-align: center;
`;

const HeroProgressXPContainer = styled('div')`
  display: block;
  width: 100%;
  /* height: 0.5rem; */
  margin-top: 1.5rem;
  text-align: center;
`;

const HeroProgressEvoContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.8rem;
  text-align: center;
`;

const AttributesContainer = styled('div')`
  display: block;
  margin-top: 2.3rem;
`;

const AttributeRow = styled('div')`
  display: flex;
  margin-top: 0.3rem;
  justify-content: space-between;
  align-items: stretch;
`;

const AttributeLabel = styled('div')`
  display: inline-block;
  width: 50%;
  word-break: break-all;
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: #ffffff77;
`;

const AttributeValue = styled('div')`
  display: inline-flex;
  width: 50%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-all;
  text-align: right;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: #ffffff;

  & > svg {
    display: inline-block;
    margin-top: 0.4rem;
    height: 1.7rem;
    width: 1.7rem;
  }
`;

const Username = styled('div')`
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  word-break: break-all;
  text-align: right;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const EmptyText = styled('p')`
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.2rem;
  color: #ffffff77;
`;

export default {
  Root,
  HeroProgressContainer,
  HeroProgressSegment,
  HeroProgressTitle,
  HeroProgressLevelContainer,
  HeroProgressEvoContainer,
  HeroProgressXPContainer,
  AttributesContainer,
  AttributeRow,
  AttributeLabel,
  AttributeValue,
  Username,
  EmptyText,
};
