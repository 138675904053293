import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyListings } from 'src/redux/slices/listings/helpers/copy-listings';

// Define the initial state using that type
const initialState: ListingsState = {
  listings: [],
};

const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setListings: {
      reducer(state, action: PayloadAction<ListingsPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.listings = copyListings(action.payload.listings);
      },
      prepare(listings: Listing[]) {
        return {
          payload: {
            listings,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    addListings: {
      reducer(state, action: PayloadAction<ListingsPayload>) {
        const existingItems = state.listings;
        const newItems = action.payload.listings || [];
        const allItems = [...existingItems, ...newItems];

        // eslint-disable-next-line no-param-reassign
        state.listings = copyListings(allItems);
      },
      prepare(listings: Listing[]) {
        return {
          payload: {
            listings,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    removeListings: {
      reducer(state, action: PayloadAction<{ ids: string[] }>) {
        const existingItems = state.listings;
        const filteredItems = existingItems.filter(item => !action.payload.ids.includes(item.id));

        // eslint-disable-next-line no-param-reassign
        state.listings = copyListings(filteredItems);
      },
      prepare(ids: string[]) {
        return {
          payload: {
            ids,
          },
        };
      },
    },
    clearListings: state => {
      // eslint-disable-next-line no-param-reassign
      state.listings = initialState.listings;
    },
  },
});

export const { setListings, addListings, removeListings, clearListings } = listingsSlice.actions;

// Other Selectors
export const getListingsByPlayerId = (state: RootState, _playerId: string): Listing[] =>
  state.listings.listings.filter((item: Listing) => item.playerId === _playerId);
export const getListingById = (state: RootState, id: string): Listing | null =>
  state.listings.listings.find((item: Listing) => item.id === id) || null;

export default listingsSlice.reducer;
