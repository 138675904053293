import { useState } from 'react';
import { GTMEvents } from 'src/modules/gtm/events';
import { PooleEvents } from 'src/modules/poole/events';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { start } from 'src/api/auth/start';
import { LocalStorage } from 'src/local-storage/local-storage';
import { logMain } from 'src/modules/logger/logger';

interface UseAuthenticationprops {
  fromContext: string;
}

export const useAuthentication = ({ fromContext }: UseAuthenticationprops) => {
  const [processing, setProcessing] = useState(false);
  const navigateExternal = useAppNavigateExternal();
  const dispatch = useAppDispatch();

  const initiateAuthentication = async () => {
    setProcessing(true);

    try {
      // Fire Poole event for auth start
      PooleEvents.AuthStart({ from: window.location.pathname, fromContext });

      // Fire GTM event for auth start
      GTMEvents.authStart({ eventContext: fromContext });

      const authStartData = await dispatch(start()); // Start Auth if auth not started
      const { authUrl } = authStartData;

      LocalStorage.set('redirect_path', `${location.pathname}${location.hash}`);
      LocalStorage.set('enable_redirect', 'true'); // Set "enableRedirect" localStorage field to redirect on return

      if (authUrl) {
        // Fire Poole event for auth start success
        PooleEvents.AuthStartSuccess({ from: window.location.pathname, fromContext });

        // Fire GTM event for auth start success
        GTMEvents.authStartSuccess({ eventContext: fromContext });

        navigateExternal(authUrl, { pooleData: { pageName: 'Azure Authentication', fromContext } });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      logMain.debug('Error <handleAuthStart>', e);

      // Fire Poole event for auth start fail
      PooleEvents.AuthStartFail({
        from: window.location.pathname,
        fromContext,
        reason: e instanceof Error ? e.message : String(e),
      });

      // Fire GTM event for auth start success
      GTMEvents.authStartFail({ eventContext: fromContext });
    } finally {
      setProcessing(false);
    }
  };

  return {
    processing,
    initiateAuthentication,
  };
};
