import { CSSProperties } from 'react';
import { Box as BaseBox, BoxProps as BaseBoxProps, styled } from '@mui/material';
import { flexGap } from '../../tools/flexGapPoly';

interface BoxProps extends BaseBoxProps {
  gap?: CSSProperties['gap'];
  flexDirection?: CSSProperties['flexDirection'];
}
const Box = styled(BaseBox)<BoxProps>`
  ${({ gap, flexDirection, display }) => display === 'flex' && flexGap(gap, flexDirection)}
`;

export default Box;
