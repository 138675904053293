import { FC, FormEvent } from 'react';
import { nameSpace } from 'src/modules/i18n';
import useMarketplaceContext from 'src/hooks/useMarketplaceContext';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import Styled from 'src/components/pages/marketplace/components/SearchAndFilterBar.styles';

const SearchAndFilterBar: FC = () => {
  const {
    loadListings,
    filtering,
    setFiltering,
    applySearch,
    setApplySearch,
    search,
    setSearch,
    lastSearch,
    setClearSearch,
  } = useMarketplaceContext();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tMarketplace } = useTranslation(nameSpace.marketplace);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setApplySearch(true);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Styled.Root>
      <Styled.Form onSubmit={handleSubmit}>
        <Styled.FilterButton filtering={filtering} onClick={() => setFiltering(!filtering)}>
          {filtering ? (
            <span className="material-icons-outlined">filter_list_off</span>
          ) : (
            <span className="material-icons-outlined">filter_list</span>
          )}
        </Styled.FilterButton>

        <Styled.SearchInputContainer>
          <span className="material-icons-outlined">search</span>

          <Styled.SearchInput
            id="marketplace-search-input"
            type="text"
            placeholder={tMarketplace('marketplace.search.placeholder')}
            value={search}
            onChange={handleSearchInputChange}
          />
        </Styled.SearchInputContainer>

        <Styled.SearchButton type="submit" disabled={applySearch || loadListings}>
          {applySearch || loadListings ? (
            <Styled.ProgressContainer>
              <CircularProgress color="inherit" size={'1rem'} sx={{ marginTop: '.3rem' }} />
            </Styled.ProgressContainer>
          ) : (
            <span id="marketplace-search-button">{tCommon('common.term.search')}</span>
          )}
        </Styled.SearchButton>
      </Styled.Form>

      {lastSearch && (
        <Styled.FilterTilesContainer>
          <Styled.FilterTile>
            <Styled.FilterTileIconContainer onClick={() => setClearSearch(true)}>
              <span className="material-icons">close</span>
            </Styled.FilterTileIconContainer>
            {search}
          </Styled.FilterTile>
        </Styled.FilterTilesContainer>
      )}
    </Styled.Root>
  );
};

export default SearchAndFilterBar;
