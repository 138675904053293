import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  & > div:nth-of-type(1) {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translate(0, -50%);

    & svg {
      transition: color 0.4s ease-in-out;
    }
  }
`;

interface SideMenuProps {
  open: boolean;
}

const SideMenu = styled('div')<SideMenuProps>`
  background-color: #202425;
  display: block;
  width: 100%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  font-size: 0;
  position: fixed;
  top: 0;
  left: ${({ open }) => (open ? '0' : '-100%')};
  z-index: ${customThemeOptions.custom.zIndex.header.mobileSideMenu};
  transition: left 0.5s ease-in-out;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  & > div:nth-of-type(1) {
    position: absolute;
    top: 2rem;
    left: 2rem;

    & svg {
      transition: color 0.4s ease-in-out;
    }
  }
`;

const Logo = styled('img')`
  display: block;
  width: auto;
  height: 3rem;
  position: absolute;
  top: 0.9rem;
  right: 1rem;
`;

const Links = styled('div')`
  display: block;
  margin-top: 6rem;

  @media (max-height: 700px) {
    margin-top: 4rem;
  }
`;

const Col = styled('div')`
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-top: 0;

  &:not(:first-of-type) {
    margin-top: 0.5rem;
  }

  & > div:not(:first-of-type) {
    margin-top: 0.5rem;
  }

  @media (max-height: 550px) {
    width: 45%;

    &:not(:first-of-type) {
      margin-top: 0;
      margin-left: 10%;
    }
  }
`;

interface LinkProps {
  active: boolean;
}

const Link = styled('div')<LinkProps>`
  display: block;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 400;
  color: ${({ active, theme }) => (active ? theme.custom.colors.Yellow : '0')};
  transition: color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    background-color: ${({ theme }) => theme.custom.colors.Yellow};
    display: block;
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.4s ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.custom.colors.Yellow};

    &:after {
      width: 100%;
    }
  }

  @media (max-height: 650px) {
    padding-bottom: 0.3rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;

const Socials = styled('div')`
  display: block;
  margin-top: 3rem;
  margin-left: -0.5rem;

  & > div {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.5rem;
    margin-top: 1rem;
  }

  & > img {
    display: inline-block;
    width: 2.5rem;
    height: auto;
    margin-top: 1rem;
    padding: 0 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }

  & svg {
    transition: color 0.4s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }

  @media (max-height: 550px) {
    margin-top: 1.5rem;
  }
`;

export default { Root, Logo, SideMenu, Links, Col, Link, Socials };
