import { styled } from '@mui/material';

interface RootProps {
  color: string;
  glowRadius: number;
}

const Root = styled('div')<RootProps>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  & > svg:nth-of-type(1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: ${({ color, glowRadius }) => `drop-shadow(0 0 ${glowRadius}px ${color})`};
  }

  /* & svg:nth-of-type(2) {
    position: absolute;
    top: 2px;
    left: 18%;
    filter: ${({ color }) => `drop-shadow(0 0 1px ${color})`};
  } */
`;

interface InnerContainerProps {
  padding: string;
}

const InnerContainer = styled('div')<InnerContainerProps>`
  display: block;
  width: 100%;
  height: 100%;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  position: relative;
`;

export default { Root, InnerContainer };
