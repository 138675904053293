import { FC } from 'react';
import { nameSpace } from 'src/modules/i18n';
import { useTranslation } from 'react-i18next';
import useMarketplaceContext from 'src/hooks/useMarketplaceContext';
import { getRarityEnumFromName } from 'src/tools/getRarityEnumFromName';
import ATCIcon from 'src/components/icons/ATCIcon';
// import { getRarityColor } from 'src/tools/getRarityColor';
// import { getAffinityColor } from 'src/tools/getAffinityColor';
import { CircularProgress, Slider, Checkbox } from '@mui/material';
import { Yellow } from 'src/styles/theme/tokens/gen/color';
import { config } from 'src/config/config';
import Styled from 'src/components/pages/marketplace/components/FilterMenu.styles';

const allTypes = ['pack', 'hero', 'gear'];
const allRarities = ['starter', 'promo', 'common', 'uncommon', 'rare', 'ultra rare', 'epic', 'legendary'];
const allAffinities = ['fire', 'water', 'earth', 'light', 'dark'];
const allClasses = ['warrior', 'mage', 'engineer'];
const allVariants = ['balanced', 'killer', 'vigorous', 'swift', 'clever', 'wise', 'lucky'];
const allSpecialties = ['soldier', 'brute', 'medic', 'assassin', 'sapper', 'enchanter', 'trickster'];

const getLevelText = (value: number, _index: number): string => (value + 1).toString();
const getLevelMarks = () => {
  const marks = [];

  for (let i = 0; i <= config.app_variables.levels.max; i += 1) {
    if ((i + 1) % 5 === 0 && i !== config.app_variables.levels.max) {
      marks.push({ value: i, label: (i + 1).toString() });
    }
  }

  return marks;
};

// const getEvoText = (value: number, _index: number, tCommon: (s: string) => string): string => {
//   if (value === 0) {
//     return tCommon('common.term.base').toUpperCase();
//   }

//   return value.toString();
// };
// const getEvoMarks = () => {
//   const marks = [];

//   for (let i = 0; i <= config.app_variables.evolutions.max; i += 1) {
//     if (i % 1 === 0 && i !== 0 && i !== config.app_variables.evolutions.max) {
//       marks.push({ value: i, label: i.toString() });
//     }
//   }

//   return marks;
// };

const FilterMenu: FC = () => {
  const {
    loadListings,
    filtering,
    viewAllFilters,
    setViewAllFilters,
    applyFilters,
    setApplyFilters,
    priceMin,
    setPriceMin,
    priceMax,
    setPriceMax,
    levelMin,
    setLevelMin,
    levelMax,
    setLevelMax,
    // evoMin,
    // setEvoMin,
    // evoMax,
    // setEvoMax,
    types,
    setTypes,
    rarities,
    setRarities,
    affinities,
    setAffinities,
    classes,
    setClasses,
    variants,
    setVariants,
    specialties,
    setSpecialties,
    clearFilters,
    setClearFilters,
  } = useMarketplaceContext();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tMarketplace } = useTranslation(nameSpace.marketplace);

  const handleOnChangePriceMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setPriceMin(parseInt(value, 10));
  };

  const handleOnChangePriceMax = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setPriceMax(parseInt(value, 10));
  };

  const handleOnChangeLevel = (_e: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setLevelMin(newValue);
      setLevelMax(newValue);
    } else {
      setLevelMin(newValue[0]);
      setLevelMax(newValue[1]);
    }
  };

  // const handleOnChangeEvo = (_e: Event, newValue: number | number[]) => {
  //   if (typeof newValue === 'number') {
  //     setEvoMin(newValue);
  //     setEvoMax(newValue);
  //   } else {
  //     setEvoMin(newValue[0]);
  //     setEvoMax(newValue[1]);
  //   }
  // };

  const handleOnChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const copiedTypes = types.split(',');

    if (copiedTypes.indexOf(value) === -1) {
      copiedTypes.push(value);
    } else {
      copiedTypes.splice(copiedTypes.indexOf(value), 1);
    }

    setTypes(copiedTypes.filter(type => !!type).join(','));
  };

  const handleOnChangeRarity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const selectedRarityEnum = getRarityEnumFromName(value);
    const copiedRarities = rarities.split(',');

    if (copiedRarities.indexOf(selectedRarityEnum.toString()) === -1) {
      copiedRarities.push(selectedRarityEnum.toString());
    } else {
      copiedRarities.splice(copiedRarities.indexOf(selectedRarityEnum.toString()), 1);
    }

    setRarities(copiedRarities.filter(rarity => !!rarity).join(','));
  };

  const handleOnChangeAffinity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const copiedAffinities = affinities.split(',');

    if (copiedAffinities.indexOf(value) === -1) {
      copiedAffinities.push(value);
    } else {
      copiedAffinities.splice(copiedAffinities.indexOf(value), 1);
    }

    setAffinities(copiedAffinities.filter(affinity => !!affinity).join(','));
  };

  const handleOnChangeClass = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const copiedClasses = classes.split(',');

    if (copiedClasses.indexOf(value) === -1) {
      copiedClasses.push(value);
    } else {
      copiedClasses.splice(copiedClasses.indexOf(value), 1);
    }

    setClasses(copiedClasses.filter(nftClass => !!nftClass).join(','));
  };

  const handleOnChangeVariant = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const copiedVariants = variants.split(',');

    if (copiedVariants.indexOf(value) === -1) {
      copiedVariants.push(value);
    } else {
      copiedVariants.splice(copiedVariants.indexOf(value), 1);
    }

    setVariants(copiedVariants.filter(variant => !!variant).join(','));
  };

  const handleOnChangeSpecialty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const copiedSpecialties = specialties.split(',');

    if (copiedSpecialties.indexOf(value) === -1) {
      copiedSpecialties.push(value);
    } else {
      copiedSpecialties.splice(copiedSpecialties.indexOf(value), 1);
    }

    setSpecialties(copiedSpecialties.filter(specialty => !!specialty).join(','));
  };

  return (
    <Styled.Root filtering={filtering}>
      <Styled.FiltersContainer>
        <Styled.FilterRow>
          <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.price')}</Styled.FilterTitle>

          <Styled.FilterInputsContainer>
            <Styled.PriceInputGroup>
              <Styled.PriceInputLabel>{tCommon('common.term.min')}</Styled.PriceInputLabel>

              <Styled.PriceInputContainer>
                <Styled.PriceSectionRight>
                  <Styled.PriceInput
                    id="marketplace-price-min-input"
                    type="number"
                    placeholder={tCommon('common.term.min')}
                    value={priceMin}
                    onChange={handleOnChangePriceMin}
                  />
                </Styled.PriceSectionRight>

                <Styled.PriceSectionLeft>
                  <p>
                    <ATCIcon size={17} style={{ margin: '0 0 1px 0' }} />
                    {/* <ATCIcon size={14} style={{ margin: '0 0.3rem 2px 0' }} />
                    {tCommon('common.project.currency.shorthand')} */}
                  </p>
                </Styled.PriceSectionLeft>
              </Styled.PriceInputContainer>
            </Styled.PriceInputGroup>

            <Styled.PriceInputGroup>
              <Styled.PriceInputLabel>{tCommon('common.term.max')}</Styled.PriceInputLabel>

              <Styled.PriceInputContainer>
                <Styled.PriceSectionRight>
                  <Styled.PriceInput
                    id="marketplace-price-max-input"
                    type="number"
                    placeholder={tCommon('common.term.max')}
                    value={priceMax}
                    onChange={handleOnChangePriceMax}
                  />
                </Styled.PriceSectionRight>

                <Styled.PriceSectionLeft>
                  <p>
                    <ATCIcon size={17} style={{ margin: '0 0 1px 0' }} />
                    {/* <ATCIcon size={14} style={{ margin: '0 0.3rem 2px 0' }} />
                    {tCommon('common.project.currency.shorthand')} */}
                  </p>
                </Styled.PriceSectionLeft>
              </Styled.PriceInputContainer>
            </Styled.PriceInputGroup>
          </Styled.FilterInputsContainer>
        </Styled.FilterRow>

        <Styled.FilterRow style={{ marginTop: '1.5rem' }}>
          <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.level')}</Styled.FilterTitle>

          <Slider
            getAriaLabel={() => 'Level range'}
            size="small"
            value={[levelMin, levelMax]}
            min={config.app_variables.levels.min}
            max={config.app_variables.levels.max}
            marks={getLevelMarks()}
            step={1}
            onChange={handleOnChangeLevel}
            valueLabelDisplay="auto"
            valueLabelFormat={(v, i) => getLevelText(v, i)}
            getAriaValueText={(v, i) => getLevelText(v, i)}
            sx={{ marginTop: '.6rem', color: Yellow, fontFamily: 'Barlow' }}
          />
        </Styled.FilterRow>

        {/* <Styled.FilterRow>
          <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.evo')}</Styled.FilterTitle>

          <Slider
            getAriaLabel={() => 'Evolution range'}
            size="small"
            value={[evoMin, evoMax]}
            min={config.app_variables.evolutions.min}
            max={config.app_variables.evolutions.max}
            marks={getEvoMarks()}
            step={1}
            onChange={handleOnChangeEvo}
            valueLabelDisplay="auto"
            valueLabelFormat={(v, i) => getEvoText(v, i, tCommon)}
            getAriaValueText={(v, i) => getEvoText(v, i, tCommon)}
            sx={{ marginTop: '.6rem', color: Yellow, fontFamily: 'Barlow' }}
          />
        </Styled.FilterRow> */}

        <Styled.FilterRow style={{ marginTop: '0.5rem' }}>
          <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.type')}</Styled.FilterTitle>

          <Styled.FilterInputsContainer>
            {allTypes.map((type, i) => (
              <Styled.CheckboxGroup key={`marketplace-filter-type-${i}`}>
                <Checkbox
                  value={type}
                  checked={types.indexOf(type) !== -1}
                  onChange={handleOnChangeType}
                  sx={{
                    '&.Mui-checked': {
                      color: Yellow,
                    },
                  }}
                />

                <Styled.CheckboxLabel>{type}</Styled.CheckboxLabel>
              </Styled.CheckboxGroup>
            ))}
          </Styled.FilterInputsContainer>
        </Styled.FilterRow>

        <Styled.FilterRow>
          <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.rarity')}</Styled.FilterTitle>

          <Styled.FilterInputsContainer>
            {allRarities.map((rarity, i) => (
              <Styled.CheckboxGroup key={`marketplace-filter-rarity-${i}`}>
                <Checkbox
                  value={rarity}
                  checked={rarities.split(',').indexOf(getRarityEnumFromName(rarity).toString()) !== -1}
                  onChange={handleOnChangeRarity}
                  //   sx={{
                  //     color: `${getRarityColor(rarity)}`,
                  //     '&.Mui-checked': {
                  //       color: `${getRarityColor(rarity)}`,
                  //     },
                  //   }}
                  sx={{
                    '&.Mui-checked': {
                      color: Yellow,
                    },
                  }}
                />

                <Styled.CheckboxLabel>{rarity}</Styled.CheckboxLabel>
              </Styled.CheckboxGroup>
            ))}
          </Styled.FilterInputsContainer>
        </Styled.FilterRow>

        <Styled.FilterRow>
          <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.affinity')}</Styled.FilterTitle>

          <Styled.FilterInputsContainer>
            {allAffinities.map((affinity, i) => (
              <Styled.CheckboxGroup key={`marketplace-filter-affinity-${i}`}>
                <Checkbox
                  value={affinity}
                  checked={affinities.indexOf(affinity) !== -1}
                  onChange={handleOnChangeAffinity}
                  //   sx={{
                  //     color: `${getAffinityColor(affinity)}`,
                  //     '&.Mui-checked': {
                  //       color: `${getAffinityColor(affinity)}`,
                  //     },
                  //   }}
                  sx={{
                    '&.Mui-checked': {
                      color: Yellow,
                    },
                  }}
                />

                <Styled.CheckboxLabel>{affinity}</Styled.CheckboxLabel>
              </Styled.CheckboxGroup>
            ))}
          </Styled.FilterInputsContainer>
        </Styled.FilterRow>

        {viewAllFilters && (
          <>
            <Styled.FilterRow>
              <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.class')}</Styled.FilterTitle>

              <Styled.FilterInputsContainer>
                {allClasses.map((nftClass, i) => (
                  <Styled.CheckboxGroup key={`marketplace-filter-class-${i}`}>
                    <Checkbox
                      value={nftClass}
                      checked={classes.indexOf(nftClass) !== -1}
                      onChange={handleOnChangeClass}
                      //   sx={{
                      //     color: `${getAffinityColor(affinity)}`,
                      //     '&.Mui-checked': {
                      //       color: `${getAffinityColor(affinity)}`,
                      //     },
                      //   }}
                      sx={{
                        '&.Mui-checked': {
                          color: Yellow,
                        },
                      }}
                    />

                    <Styled.CheckboxLabel>{nftClass}</Styled.CheckboxLabel>
                  </Styled.CheckboxGroup>
                ))}
              </Styled.FilterInputsContainer>
            </Styled.FilterRow>

            <Styled.FilterRow>
              <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.variant')}</Styled.FilterTitle>

              <Styled.FilterInputsContainer>
                {allVariants.map((variant, i) => (
                  <Styled.CheckboxGroup key={`marketplace-filter-variant-${i}`}>
                    <Checkbox
                      value={variant}
                      checked={variants.indexOf(variant) !== -1}
                      onChange={handleOnChangeVariant}
                      //   sx={{
                      //     color: `${getAffinityColor(affinity)}`,
                      //     '&.Mui-checked': {
                      //       color: `${getAffinityColor(affinity)}`,
                      //     },
                      //   }}
                      sx={{
                        '&.Mui-checked': {
                          color: Yellow,
                        },
                      }}
                    />

                    <Styled.CheckboxLabel>{variant}</Styled.CheckboxLabel>
                  </Styled.CheckboxGroup>
                ))}
              </Styled.FilterInputsContainer>
            </Styled.FilterRow>

            <Styled.FilterRow>
              <Styled.FilterTitle>{tMarketplace('marketplace.filter.category.specialty')}</Styled.FilterTitle>

              <Styled.FilterInputsContainer>
                {allSpecialties.map((specialty, i) => (
                  <Styled.CheckboxGroup key={`marketplace-filter-specialty-${i}`}>
                    <Checkbox
                      value={specialty}
                      checked={specialties.indexOf(specialty) !== -1}
                      onChange={handleOnChangeSpecialty}
                      //   sx={{
                      //     color: `${getAffinityColor(affinity)}`,
                      //     '&.Mui-checked': {
                      //       color: `${getAffinityColor(affinity)}`,
                      //     },
                      //   }}
                      sx={{
                        '&.Mui-checked': {
                          color: Yellow,
                        },
                      }}
                    />

                    <Styled.CheckboxLabel>{specialty}</Styled.CheckboxLabel>
                  </Styled.CheckboxGroup>
                ))}
              </Styled.FilterInputsContainer>
            </Styled.FilterRow>
          </>
        )}
      </Styled.FiltersContainer>

      <Styled.MoreFiltersButton onClick={() => setViewAllFilters(!viewAllFilters)}>
        {viewAllFilters ? (
          <>
            <span className="material-icons">expand_less</span>
            <span>{tMarketplace('marketplace.filter.less')}</span>
          </>
        ) : (
          <>
            <span className="material-icons">expand_more</span>
            <span>{tMarketplace('marketplace.filter.more')}</span>
          </>
        )}
      </Styled.MoreFiltersButton>

      <Styled.ClearButton disabled={clearFilters || loadListings} onClick={() => setClearFilters(true)}>
        {clearFilters || loadListings ? (
          <Styled.ProgressContainer>
            <CircularProgress color="inherit" size={'1rem'} sx={{ marginTop: '.3rem' }} />
          </Styled.ProgressContainer>
        ) : (
          <span id="marketplace-clear-filters-button">{tMarketplace('marketplace.filter.clear')}</span>
        )}
      </Styled.ClearButton>

      <Styled.ApplyButton disabled={applyFilters || loadListings} onClick={() => setApplyFilters(true)}>
        {applyFilters || loadListings ? (
          <Styled.ProgressContainer>
            <CircularProgress color="inherit" size={'1rem'} sx={{ marginTop: '.3rem', color: Yellow }} />
          </Styled.ProgressContainer>
        ) : (
          <span id="marketplace-apply-filters-button">{tCommon('common.term.apply')}</span>
        )}
      </Styled.ApplyButton>
    </Styled.Root>
  );
};

export default FilterMenu;
