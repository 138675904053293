import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useQuery } from 'src/tools/useQuery';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { GTMEvents } from 'src/modules/gtm/events';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import routeConfig from 'src/routes/config';
import LegendaryIcon from 'src/assets/icons/LegendaryIcon.webp';
import BaseIcon from 'src/components/icons/BaseIcon';
import PGGIcon from 'src/assets/icons/pgg-icon-white.webp';
import ForteWhiteHeaderIcon from 'src/components/icons/ForteWhiteHeaderIcon';
import N3tworkWhiteHeaderIcon from 'src/components/icons/N3tworkWhiteHeaderIcon';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import TwitterSocialIcon from 'src/components/icons/TwitterSocialIcon';
import TikTokSocialIcon from 'src/components/icons/TikTokSocialIcon';
import { YouTube as YouTubeIcon, Instagram as InstagramIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import Styled from 'src/components/footer/Footer.styles';

const Footer: FC = () => {
  const location = useLocation();
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const { t } = useTranslation(nameSpace.common);
  const [inView, setInView] = useState(false);
  const navigateInternal = useAppNavigateInternal();
  const navigateExternal = useAppNavigateExternal();

  const handleOnGoTo = (path: string, gtmEvent?: () => void) => {
    navigateInternal(path, { pooleData: { fromContext: 'footer' }, gtmEvent: gtmEvent || null });
  };

  // Hide footer on play page.
  if (location.pathname.indexOf('play') !== -1) return null;

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root>
        <Styled.LogoRarity
          className={`inview animated ${inView ? 'fadeInUp' : ''}`}
          src={LegendaryIcon}
          alt={t('common.project.name')}
          width="878"
          height="878"
          loading="lazy"
          onClick={() => {
            handleOnGoTo(trim === '1' ? `${routeConfig.homePage.path}?trim=1` : routeConfig.homePage.path);
          }}
        />

        <Styled.Row className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
          <Styled.Link
            onClick={() => {
              handleOnGoTo(trim === '1' ? '/home?trim=1' : '/home');
            }}
          >
            {t('common.nav.home')}
          </Styled.Link>

          <Styled.Link
            onClick={() => {
              handleOnGoTo(trim === '1' ? '/events?trim=1' : '/events');
            }}
          >
            {t('common.nav.events')}
          </Styled.Link>

          <Styled.Link
            onClick={() => {
              handleOnGoTo(trim === '1' ? '/getting-started?trim=1' : '/getting-started');
            }}
          >
            {t('common.nav.getting.started')}
          </Styled.Link>

          <Styled.Link
            onClick={() =>
              handleOnGoTo(trim === '1' ? '/home?trim=1#roadmap' : '/home#roadmap', () =>
                GTMEvents.clickBtnRoadmap({ eventContext: 'footer' }),
              )
            }
          >
            {t('common.nav.roadmap')}
          </Styled.Link>

          {/* <Styled.Link
            onClick={() => {
              navigateExternal(t('common.nav.whitepaper.link'), {
                pooleData: { pageName: 'Whitepaper', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnWhitepaper({ eventContext: 'footer' }),
              });
            }}
          >
            {t('common.nav.whitepaper')}
          </Styled.Link> */}

          <Styled.Link
            onClick={() => {
              handleOnGoTo(trim === '1' ? '/blog?trim=1' : '/blog');
            }}
          >
            {t('common.nav.blog')}
          </Styled.Link>

          <Styled.Link
            onClick={() => {
              GTMEvents.clickBtnFAQs({ eventContext: 'footer' });
              handleOnGoTo(trim === '1' ? '/home?trim=1#faqs' : '/home#faqs');
            }}
          >
            {t('common.nav.faqs')}
          </Styled.Link>
        </Styled.Row>

        <Styled.Row className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<DiscordSocialIcon />}
            height="30px"
            padding="0 .5rem"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '0.8rem' }}
            onClick={() => {
              navigateExternal(t('common.social.discord'), {
                newWindow: true,
                pooleData: { pageName: 'Discord', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnDiscord({ eventContext: 'footer', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<TwitterSocialIcon />}
            height="18px"
            padding="0 .5rem"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '0.8rem' }}
            onClick={() => {
              navigateExternal(t('common.social.twitter'), {
                newWindow: true,
                pooleData: { pageName: 'Twitter', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnTwitter({ eventContext: 'footer', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<YouTubeIcon />}
            height="30px"
            padding="0 .5rem"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '0.8rem' }}
            onClick={() => {
              navigateExternal(t('common.social.youtube'), {
                newWindow: true,
                pooleData: { pageName: 'Youtube', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnYoutube({ eventContext: 'footer', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<InstagramIcon />}
            height="25px"
            padding="0 .5rem"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '0.8rem' }}
            onClick={() => {
              navigateExternal(t('common.social.instagram'), {
                newWindow: true,
                pooleData: { pageName: 'Instagram', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnInstagram({ eventContext: 'footer', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<TikTokSocialIcon />}
            height="25px"
            padding="0 .5rem"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '0.8rem' }}
            onClick={() => {
              navigateExternal(t('common.social.tiktok'), {
                newWindow: true,
                pooleData: { pageName: 'TikTok', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnTikTok({ eventContext: 'footer', eventIsOutboundLink: true }),
              });
            }}
          />
          <BaseIcon
            cursor="pointer"
            hoverColor="#FEC300"
            icon={<FacebookIcon />}
            height="28px"
            padding="0 .5rem"
            style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '0.8rem' }}
            onClick={() => {
              navigateExternal(t('common.social.facebook'), {
                newWindow: true,
                pooleData: { pageName: 'Facebook', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnFacebook({ eventContext: 'footer', eventIsOutboundLink: true }),
              });
            }}
          />
          <img
            className="social-icon"
            src={PGGIcon}
            width="100"
            height="100"
            alt="Polkastarter Icon"
            title="PolkaStarterIcon"
            onClick={() => {
              navigateExternal(t('common.social.polkastarter'), {
                newWindow: true,
                pooleData: { pageName: 'Polkastarter', fromContext: 'footer' },
                gtmEvent: () => GTMEvents.clickBtnPolkastarter({ eventContext: 'footer', eventIsOutboundLink: true }),
              });
            }}
          />
        </Styled.Row>

        <Styled.Row className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
          <span className="material-icons">lock</span>
          <Styled.BrandingLabel>{t('common.powered.by')}</Styled.BrandingLabel>

          <BaseIcon
            hoverColor="#FEC300"
            cursor="pointer"
            icon={<ForteWhiteHeaderIcon />}
            padding="0"
            style={{ display: 'inline-block', verticalAlign: 'middle', margin: '0.8rem .7rem 0 0' }}
            onClick={() => {
              navigateExternal(t('common.forte.link'), {
                newWindow: true,
                pooleData: { pageName: 'Forte', fromContext: 'footer' },
              });
            }}
          />

          <BaseIcon
            hoverColor="#FEC300"
            cursor="pointer"
            icon={<N3tworkWhiteHeaderIcon />}
            padding="0"
            style={{ display: 'inline-block', verticalAlign: 'middle', margin: '0.8rem 0 0 0' }}
            onClick={() => {
              navigateExternal(t('common.n3twork.link'), {
                newWindow: true,
                pooleData: { pageName: 'N3twork Studios', fromContext: 'footer' },
              });
            }}
          />
        </Styled.Row>

        <Styled.Row className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
          <Styled.Copyright>
            &copy; {new Date().getFullYear()} {t('common.copyright')}
          </Styled.Copyright>
        </Styled.Row>

        <Styled.Row className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
          <Styled.LegalLink
            onClick={() => {
              navigateExternal(t('common.nav.terms.link'), {
                newWindow: true,
                pooleData: { pageName: 'Terms & Conditions', fromContext: 'footer' },
              });
            }}
          >
            {t('common.nav.terms')}
          </Styled.LegalLink>

          <span style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '.5rem' }}>|</span>

          <Styled.LegalLink
            onClick={() => {
              navigateExternal(t('common.nav.privacy.link'), {
                newWindow: true,
                pooleData: { pageName: 'Privacy Policy', fromContext: 'footer' },
              });
            }}
          >
            {t('common.nav.privacy')}
          </Styled.LegalLink>
        </Styled.Row>
      </Styled.Root>
    </Waypoint>
  );
};

export default Footer;
