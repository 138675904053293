import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import ActionsBar from 'src/components/pages/profile/components/ActionsBar';
import Username from 'src/components/pages/profile/components/Username';
import DiscordStatus from 'src/components/pages/profile/components/DiscordStatus';
import FarcasterStatus from 'src/components/pages/profile/components/FarcasterStatus';
import Tabs from 'src/components/navigation/Tabs';
import Styled from 'src/components/pages/profile/components/SectionDetails.styles';

type ProfileTabs = string[];

interface SectionDetailsProps {
  tab: number;
  setTab: (tab: number) => void;
}

const SectionDetails: FC<SectionDetailsProps> = ({ tab, setTab }) => {
  const { playerId = '' } = useParams();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t } = useTranslation(nameSpace.profile);
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const profileTabs: ProfileTabs = t(isCurrentProfile ? 'profile.details.tabs' : 'profile.details.tabs.other.user', {
    returnObjects: true,
  });

  return (
    <Styled.Root>
      <Styled.CenteredContainer>
        <Username />

        {isCurrentProfile && <DiscordStatus />}
        {isCurrentProfile && <FarcasterStatus />}

        <ActionsBar />

        <Tabs tabs={profileTabs} setTab={setTab} defaultValue={tab} style={{ marginTop: '2rem' }} />
      </Styled.CenteredContainer>
    </Styled.Root>
  );
};

export default SectionDetails;
