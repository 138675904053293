import { keyframes, styled } from '@mui/material';

const scrollBounce = keyframes`
  0% {
    bottom: 3rem;
    opacity: 0.8;
  }
  3% {
    bottom: 3.5rem;
    opacity: 0.8;
  }
  6% {
    bottom: 2.5rem;
    opacity: 0;
  }
  12% {
    bottom: 0;
    opacity: 0;
  }
  13% {
    bottom: 5rem;
    opacity: 0;
  }
  16% {
    bottom: 2.5rem;
    opacity: 0.8;
  }
  20% {
    bottom: 3rem;
    opacity: 0.8;
  }
  // Bounce
  42% {
    bottom: 3rem;
    opacity: 0.8;
  }
  46% {
    bottom: 3.5rem;
    opacity: 0.8;
  }
  50% {
    bottom: 3rem;
    opacity: 0.8;
  }
  // Bounce
  72% {
    bottom: 3rem;
    opacity: 0.8;
  }
  76% {
    bottom: 3.5rem;
    opacity: 0.8;
  }
  80% {
    bottom: 3rem;
    opacity: 0.8;
  }
  100% {
    bottom: 3rem;
    opacity: 0.8;
  }
`;

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
`;

const Hero = styled('div')`
  display: block;
  width: 100%;
  min-height: 38rem;
  height: 100vh;
  /* height: calc(100vh - 5rem); */
  /* margin-top: 5rem; */
  overflow: hidden;
  position: relative;

  /* @media (max-width: 1000px) {
    height: calc(100vh - 4.5rem);
    margin-top: 4.5rem;
  } */
`;

const BackgroundIFrame = styled('iframe')`
  display: block;
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 16 / 9;
`;

const OverlayContainer = styled('div')`
  background-color: #000000bb;
  display: block;
  box-shadow: 0 0 500px 130px #000000cc inset;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 750px) {
    background-color: #000000aa;
    box-shadow: 0 0 500px 110px #000000aa inset;
  }
`;

const Overlay = styled('div')`
  display: block;
  width: 100%;
  max-width: 70rem;
  text-align: center;
  word-wrap: break-word;
  box-sizing: border-box;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  & .play-now-button {
    margin: 2.5rem 0 0 0;
  }

  @media (max-height: 750px) {
    & .play-now-button {
      margin: 2rem 0 0 0;
    }
  }

  @media (max-width: 650px) {
    & .play-now-button {
      margin: 2rem 0 0 0;
    }
  }
`;

const Logo = styled('img')`
  background: radial-gradient(#00000099 0%, #00000000 70%);
  display: inline-block;
  width: auto;
  height: 21rem;

  @media (max-height: 750px) {
    height: 18rem;
  }

  @media (max-width: 1200px) {
    height: 20rem;
  }

  @media (max-width: 900px) {
    height: 17rem;
  }

  @media (max-height: 650px) {
    height: 15rem;
  }

  @media (max-width: 650px) {
    height: 13rem;
  }

  @media (max-height: 650px) {
    height: 13rem;
  }

  @media (max-width: 450px) {
    height: 12rem;
  }

  @media (max-width: 350px) {
    height: 8rem;
  }
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  max-width: 28rem;
  margin: 1.5rem auto 0 auto;
  padding: 0 2rem;
  box-sizing: border-box;
  text-transform: uppercase;
  text-shadow: #000000cc 1px 5px 8px;
  font-family: 'Kevlar Underwear';
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-height: 750px) {
    margin: 1.5rem auto 0 auto;
  }

  @media (max-width: 900px) {
    max-width: 26rem;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  @media (max-width: 650px) {
    margin: 1.5rem auto 0 auto;
    max-width: 24rem;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`;

export const ScrollIndicatorContainer = styled('div')`
  display: block;
  width: 100%;
  height: 10rem;
  position: absolute;
  bottom: 0;

  @media (max-height: 750px) {
    bottom: -1.5rem;
  }

  @media (max-height: 38rem) {
    display: none;
  }
`;

export const ScrollIndicator = styled('img')`
  display: block;
  height: 2rem;
  width: auto;
  margin: 0 auto;
  opacity: 0.8;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  filter: brightness(1.1);
  transition: height 0.3s ease-in-out, filter 0.3s ease-in-out;
  animation: ${scrollBounce} 10s ease-in-out infinite;

  &:hover {
    height: 2.3rem;
    filter: brightness(1.3);
  }

  @media (max-height: 750px) {
    height: 1.8rem;

    &:hover {
      height: 2.1rem;
      filter: brightness(1.3);
    }
  }
`;

export default {
  Root,
  Hero,
  BackgroundIFrame,
  OverlayContainer,
  Overlay,
  Logo,
  Heading,
  ScrollIndicatorContainer,
  ScrollIndicator,
};
