import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
  box-sizing: border-box;
`;

interface ListingsProps {
  center: boolean;
}

const Listings = styled('div')<ListingsProps>`
  background-color: #00000033;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 24rem;
  padding: 1.4rem 0.5rem;
  margin: ${({ center }) => (center ? '0 auto' : '0')};
  border: ${({ theme }) => `1px solid ${theme.custom.colors.Yellow}`};
  box-sizing: border-box;
  font-size: 0;
  pointer-events: none;
`;

interface ListingProps {
  selected: boolean;
  numListings: number;
}

const Listing = styled('div')<ListingProps>`
  display: inline-block;
  width: ${({ numListings }) => `calc(100% / ${numListings})`};
  vertical-align: top;
  text-align: center;

  & > img {
    filter: ${({ selected }) => (selected ? 'brightness(50%)' : 'brightness(100%)')};
  }
`;

const ListingImg = styled('img')`
  display: block;
  width: 55%;
  max-width: 3.4rem;
  height: auto;
  margin: 0 auto;
  box-shadow: 2px 3px 10px 2px #00000099;
  cursor: pointer;
  transition: filter 0.4s ease-in-out;
  pointer-events: all;
`;

export default {
  Root,
  Listings,
  Listing,
  ListingImg,
};
