import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentProfile = () => (_dispatch: any) => {
  const config = {
    method: 'get',
    url: '/api/web/me',
  };

  return request(config);
};
