import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
// import { PooleEvents } from 'src/modules/poole/events';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import useGameClientContext from 'src/hooks/useGameClientContext';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import {
  // CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Styled from 'src/components/modals/LeaveGameModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface LeaveGameModalProps {
  style?: React.CSSProperties;
}

const LeaveGameModal: FC<LeaveGameModalProps> = ({ style = {} }) => {
  const { leaving, setLeaving, leavingTo, setLeavingTo, leavingOutbound, setLeavingOutbound } = useGameClientContext();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tPlay } = useTranslation(nameSpace.play);
  const navigateInternal = useAppNavigateInternal();
  const navigateExternal = useAppNavigateExternal();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    // TODO: Poole Event

    setLeaving(false);
    setLeavingTo('');
    setLeavingOutbound(false);
  };

  const handleConfirm = useCallback(async () => {
    const navigate = () => {
      if (leavingTo) {
        if (leavingOutbound) {
          navigateExternal(leavingTo as string, {
            pooleData: { pageName: 'Whitepaper', fromContext: 'footer' },
            force: true,
          });
        } else {
          navigateInternal(leavingTo, { pooleData: { fromContext: 'leave_game_modal' }, force: true });
        }
      }
    };

    navigate();
  }, [leavingOutbound, leavingTo, navigateExternal, navigateInternal]);

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={leaving}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.leaveGame}` }}
    >
      <Fade in={leaving}>
        <Styled.Root style={style}>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            <Styled.Heading>{tPlay('play.popup.leave.heading')}</Styled.Heading>

            {/* <ErrorMessage msg={error} style={{ margin: '1.5rem 0 0 0' }} /> */}

            <Styled.SubheadingsContainer>
              <Styled.Subheading>{tPlay('play.popup.leave.subheading.1')}</Styled.Subheading>
            </Styled.SubheadingsContainer>

            <Styled.Note>{tPlay('play.popup.leave.subheading.2')}</Styled.Note>

            <Styled.ButtonsContainer>
              <ButtonHex
                hoverEffect
                variant={'white'}
                width={mobileView ? 175 : 150}
                height={45}
                title={tCommon('common.term.no')}
                style={{
                  display: mobileView ? 'block' : 'inline-block',
                  margin: mobileView ? '0 auto 0 auto' : '0',
                }}
                onClick={handleClose}
              />

              <ButtonHex
                hoverEffect
                variant="gold"
                width={mobileView ? 175 : 150}
                height={45}
                title={tCommon('common.term.yes')}
                style={{
                  display: mobileView ? 'block' : 'inline-block',
                  margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                }}
                onClick={handleConfirm}
              />
            </Styled.ButtonsContainer>
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default LeaveGameModal;
