/* eslint-disable no-param-reassign */
import { InternalAxiosRequestConfig } from 'axios';
import { PooleEvents } from 'src/modules/poole/events';
import { LocalStorage } from 'src/local-storage/local-storage';
import store from 'src/redux/store';
import { refreshSession } from 'src/api/auth/refresh-session';
import { logout } from 'src/api/auth/logout';
import { setCurrentProfile } from 'src/redux/slices/current-profile/current-profile';
import { logMain } from 'src/modules/logger/logger';
import { saveDevicePlayerId } from 'src/local-storage/helpers/saveDevicePlayerId';

const isLocalStorageHealthy = (): boolean => {
  const sessionToken = LocalStorage.get('session_token');
  const sessionTokenStart = LocalStorage.get('session_token_start'); // Date in milli
  const sessionTokenExp = LocalStorage.get('session_token_exp'); // Date in milli

  // If important variables not found in local state, return false
  if (!sessionToken || !sessionTokenStart || !sessionTokenExp) {
    return false;
  }

  return true;
};

const isSessionValid = (): boolean => {
  const sessionToken = LocalStorage.get('session_token');
  const sessionTokenExp = LocalStorage.get('session_token_exp'); // Date in milli

  if (sessionToken && sessionTokenExp) {
    return parseInt(sessionTokenExp, 10) > Date.now();
  }

  return false;
};

const isRefreshNeeded = (): boolean => {
  const sessionTokenStart = LocalStorage.get('session_token_start'); // Date in milli
  const sessionTokenExp = LocalStorage.get('session_token_exp'); // Date in milli
  const sessionTokenLastRefresh = LocalStorage.get('session_token_last_refresh'); // Date in milli
  // const refreshInterval = 3600000; // 3600000 = 1 hour in milliseconds
  const refreshInterval = 1800000; // 1800000 = 30 min in milliseconds

  // Parse dates in Milli for later comparison
  const parsedTokenStart = parseInt(sessionTokenStart as string, 10);
  const parsedTokenExp = parseInt(sessionTokenExp as string, 10);

  // If a refresh interval's worth of time left before expiration, refresh
  if (parsedTokenExp - Date.now() <= refreshInterval) return true;

  // If sessionTokenLastRefresh is TRUTHY, than a refresh has occured, so check current date in milli
  // versus last refresh date and see if interval has gone by
  if (sessionTokenLastRefresh) {
    const parsedTokenLastRefresh = parseInt(sessionTokenLastRefresh as string, 10);
    const elapsedTime = Date.now() - parsedTokenLastRefresh;

    if (elapsedTime >= refreshInterval) return true;
  }

  // If sessionTokenLastRefresh is FALSY, than a refresh has NOT occured, so check current date in milli
  // versus start date and see if interval has gone by
  if (!sessionTokenLastRefresh) {
    const elapsedTime = Date.now() - parsedTokenStart;

    if (elapsedTime >= refreshInterval + 60000) return true; // Add 60000 milli as buffer between login and next refresh
  }

  // If no matches above, then no refresh necessary
  return false;
};

/**
 * MAIN INTERCEPTOR FUNCTION
 */
export const customAxiosPrivateRequest = (config: InternalAxiosRequestConfig) => {
  const sessionTokenIsRefreshing = LocalStorage.get('session_token_is_refreshing');
  const isRefreshing = sessionTokenIsRefreshing && sessionTokenIsRefreshing === 'true';
  const sessionToken = LocalStorage.get('session_token');

  // If config headers object not found, create it
  config.headers = config.headers ?? {};

  // Set Bearer token in request Authorization header
  config.headers.Authorization = `Bearer ${sessionToken}`;

  /**
   * BEGIN: CHECK FOR REFRESH
   */

  // If important variables not found in local state, throw an error to cancel the request
  if (!isLocalStorageHealthy()) {
    throw new Error('Invalid session variables. Logging out.');
  }

  // If session if not valid, throw an error to cancel the request
  if (!isSessionValid) {
    throw new Error('Invalid authorization token.');
  }

  if (isRefreshNeeded() && !isRefreshing) {
    logMain.debug('Refreshing session...');

    LocalStorage.set('session_token_is_refreshing', 'true');

    // Fire Poole event for authentication renewal
    PooleEvents.AuthRenew({
      playerId: store.getState().currentProfile.currentProfile.id,
      from: window.location.pathname,
    });

    return (
      refreshSession()
        .then(data => {
          logMain.debug('Successfully refreshed session!');

          LocalStorage.set('session_token', data.sessionToken);
          LocalStorage.set('session_token_exp', data.expiration);
          LocalStorage.set('session_token_last_refresh', Date.now().toString());
          LocalStorage.set('session_token_is_refreshing', 'false');

          config.headers.Authorization = `Bearer ${data.sessionToken}`;

          // Save player id to local storage device player ids
          saveDevicePlayerId(data?.profile?.id);

          // Fire Poole event for authentication renewal success
          PooleEvents.AuthRenewSuccess({
            playerId: store.getState().currentProfile.currentProfile.id,
            from: window.location.pathname,
          });

          store.dispatch(setCurrentProfile(data.profile));

          return config;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug('Failed to refresh session!');

          // Fire Poole event for authentication renewal
          PooleEvents.AuthRenewFail({
            playerId: store.getState().currentProfile.currentProfile.id,
            from: window.location.pathname,
            reason: e instanceof Error ? e.message : String(e),
          });

          // Clear users local state variables if refresh fails
          store.dispatch(logout());

          throw new Error('Failed to refresh session. Logging Out.');
        })
    );
  }

  /**
   * END: CHECK FOR REFRESH
   */

  return config;
};
