import { FC, useState } from 'react';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getNonfungibleBySeriesAndSerialNumber } from 'src/redux/slices/nonfungibles/nonfungibles';
import { formatPrice } from 'src/tools/formatPrice';
import { useMediaQuery, useTheme } from '@mui/material';
import NFTStatus from 'src/components/data-display/NFTStatus';
import Tabs from 'src/components/navigation/Tabs';
import Subtitle from 'src/components/pages/nft/components/Subtitle';
import StarLevel from 'src/components/data-display/StarLevel';
import PurchaseButton from 'src/components/pages/nft/components/PurchaseButton';
import Metadata from 'src/components/pages/nft/components/Metadata';
import Stats from 'src/components/pages/nft/components/Stats';
import Skills from 'src/components/pages/nft/components/Skills';
import Description from 'src/components/pages/nft/components/Description';
import ListNFTModal from 'src/components/modals/ListNFTModal';
import DelistNFTModal from 'src/components/modals/DelistNFTModal';
import BaseModal from 'src/components/modals/BaseModal';
import PackOpeningModal from 'src/components/modals/PackOpeningModal';
import ATCIcon from 'src/components/icons/ATCIcon';
import { config } from 'src/config/config';
import Styled from 'src/components/pages/nft/components/NFTDetails.styles';

interface SectionTitle {
  empty: string;
}

interface NFTDetailsProps {
  seriesId: string;
  serialNumber: string;
}

const NFTDetails: FC<NFTDetailsProps> = ({ seriesId, serialNumber }) => {
  const [tab, setTab] = useState(0);
  const [inView1, setInView1] = useState(false);
  const [packOpeningConfirmation, setPackOpeningConfirmation] = useState(false);
  const [packOpeningModal, setPackOpeningModal] = useState(false);
  const [listingModalOpen, setListingModalOpen] = useState(false);
  const [delistingModalOpen, setDelistingModalOpen] = useState(false);
  const currentProfile = useAppSelector(state => state.currentProfile.currentProfile);
  const nonfungible = useAppSelector(state =>
    getNonfungibleBySeriesAndSerialNumber(state, seriesId, parseInt(serialNumber, 10)),
  );
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(768));
  const extraSmallView = useMediaQuery(theme.breakpoints.down(400));
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tNFT } = useTranslation(nameSpace.nft);
  const tabs: string[] = tNFT('nft.details.tabs', { returnObjects: true });
  const titles: SectionTitle[] = tNFT('nft.tab.results.titles', { returnObjects: true });
  const packTabs: string[] = tNFT('nft.pack.details.tabs', { returnObjects: true });
  const packTitles: SectionTitle[] = tNFT('nft.pack.tab.results.titles', { returnObjects: true });
  const isPack = nonfungible?.type === 'pack';
  const packOpenVideoUrl = nonfungible?.packOpenVideoUrl || '';
  const packLoopVideoUrl = nonfungible?.packLoopVideoUrl || '';
  const listed = nonfungible?.listed;
  const price = nonfungible?.price || 0;
  const normalizedPrice = formatPrice(price, { decimals: 0, hideSymbol: true });
  // const priceUSD = (price * config.app_variables.lc_conversion).toFixed(2);
  const normalizedPriceUSD = formatPrice(price * config.app_variables.lc_conversion);
  const isCurrentProfile = nonfungible?.playerId === currentProfile.id;
  const marketplaceEnabled = currentProfile?.features?.marketplace?.enabled;

  // NFT Pack Opening Functions
  const handleOpenPackOpenConfirmation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.OpenPackStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_preview',
      nonfungibleId: nonfungible?.id || '',
      packTier: nonfungible?.packTier || '',
    });

    setPackOpeningConfirmation(true);
  };

  const handleSubmitPackOpenConfirmation = () => {
    setPackOpeningConfirmation(false);
    setPackOpeningModal(true);
  };

  const handleClosePackOpening = () => {
    setPackOpeningConfirmation(false);
    setPackOpeningModal(false);
  };

  // NFT Listing Functions
  const handleOpenListingModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.CreateListingStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_details',
      nonfungibleId: nonfungible?.id || '',
    });

    setListingModalOpen(true);
  };

  const handleCloseListingModal = async () => {
    await setListingModalOpen(false);
  };

  // NFT Delisting Functions
  const handleOpenDelistingModal = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    PooleEvents.RemoveListingStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'nft_details',
      nonfungibleId: nonfungible?.id || '',
      listingId: nonfungible?.listingId || '',
      priceLC: (nonfungible?.price || 0).toString(),
      priceUSD: ((nonfungible?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
    });

    setDelistingModalOpen(true);
  };

  const handleCloseDelistingModal = async () => {
    await setDelistingModalOpen(false);
  };

  if (!nonfungible) return null;

  return (
    <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated delay-2 ${inView1 ? 'fadeInUp' : ''}`}>
        <ListNFTModal nonfungibleId={nonfungible?.id || ''} open={listingModalOpen} onClose={handleCloseListingModal} />

        <DelistNFTModal nft={nonfungible} open={delistingModalOpen} onClose={handleCloseDelistingModal} />

        {isPack && nonfungible?.packTier && (
          <>
            <BaseModal
              open={packOpeningConfirmation}
              onClose={handleClosePackOpening}
              heading={tCommon('common.popup.confirmation.pack.heading')}
              subheading={tCommon('common.popup.confirmation.pack.subheading')}
              secondaryButton={tCommon('common.term.no')}
              secondaryOnClick={handleClosePackOpening}
              primaryButton={tCommon('common.term.yes')}
              primaryOnClick={handleSubmitPackOpenConfirmation}
            />

            <PackOpeningModal
              id={nonfungible?.id}
              tier={nonfungible?.packTier}
              open={packOpeningModal}
              setOpen={setPackOpeningModal}
              videoOpenUrl={packOpenVideoUrl}
              videoLoopUrl={packLoopVideoUrl}
            />
          </>
        )}

        <Styled.TitleContainer>
          <Styled.Title>{nonfungible?.seriesName}</Styled.Title>

          <NFTStatus
            nft={nonfungible}
            style={{
              position: 'relative',
              top: 'auto',
              left: 'auto',
              verticalAlign: 'bottom',
              margin: mobileView ? '0.6rem 0 .2rem 0' : '0.6rem 0 .4rem 0',
            }}
          />
        </Styled.TitleContainer>

        <Subtitle
          attributes={nonfungible?.metadata?.attributes || []}
          heroRarity={nonfungible?.heroRarity || 0}
          serialNumber={nonfungible?.serialNumber}
          maxSerialNumber={nonfungible?.maxSerialNumber}
        />

        {!isPack && (
          <StarLevel
            level={nonfungible?.heroProgress?.starLevel || 0}
            maxLevel={nonfungible?.heroProgress?.maxStarLevel || 0}
            starSize={26}
            style={{ margin: '-0.2rem 0 0 -0.1rem' }}
          />
        )}

        {listed && marketplaceEnabled && (
          <Styled.PriceContainer>
            <Styled.PricePrimary>
              <ATCIcon size={18} style={{ margin: '0 0.3rem 1px 0' }} />
              <span>{`${normalizedPrice}`}</span>&nbsp;&nbsp;<span>{`${tCommon('common.project.currency')}`}</span>
            </Styled.PricePrimary>

            <Styled.PriceSecondary>
              <span>{`${normalizedPriceUSD}`}</span>&nbsp;&nbsp;<span>{`${tCommon('common.currency.usd')}`}</span>
            </Styled.PriceSecondary>
          </Styled.PriceContainer>
        )}

        {!isCurrentProfile && listed && marketplaceEnabled && (
          <Styled.FeesContainer>
            <Styled.FeeRow>
              <Styled.FeeLabel>{tCommon('common.term.fees')}</Styled.FeeLabel>

              <Styled.FeeValue>
                {config.app_variables.listing_fee
                  ? `${config.app_variables.listing_fee}%`
                  : tCommon('common.fees.none')}
              </Styled.FeeValue>
            </Styled.FeeRow>

            <Styled.FeeRow>
              <Styled.FeeLabel>{tCommon('common.term.royalties')}</Styled.FeeLabel>

              <Styled.FeeValue>
                {config.app_variables.royalty_fee
                  ? `${config.app_variables.royalty_fee * 100}%`
                  : tCommon('common.royalties.none')}
              </Styled.FeeValue>
            </Styled.FeeRow>
          </Styled.FeesContainer>
        )}

        {isCurrentProfile
          ? (isPack || marketplaceEnabled) && (
              <Styled.ActionButtonContainer>
                {isPack && (
                  <Styled.ActionButton disabled={!nonfungible.packOpenable} onClick={handleOpenPackOpenConfirmation}>
                    <span>
                      <span className="material-icons-outlined">style</span>
                      <span>{tCommon(extraSmallView ? 'common.term.open' : 'common.pack.open')}</span>
                    </span>
                  </Styled.ActionButton>
                )}

                {marketplaceEnabled &&
                  (listed ? (
                    <Styled.ActionButton
                      disabled={!marketplaceEnabled || !nonfungible?.listingId}
                      onClick={handleOpenDelistingModal}
                    >
                      <span>
                        <span className="material-icons-outlined">highlight_off</span>
                        <span>
                          {nonfungible?.listingId
                            ? tCommon(extraSmallView ? 'common.term.delist' : 'common.term.remove.listing')
                            : tCommon(extraSmallView ? 'common.term.pending' : 'common.term.listing.pending')}
                        </span>
                      </span>
                    </Styled.ActionButton>
                  ) : (
                    <Styled.ActionButton
                      disabled={!marketplaceEnabled || !nonfungible?.listable}
                      onClick={handleOpenListingModal}
                    >
                      <span>
                        <span className="material-icons-outlined">sell</span>
                        <span>{tCommon(extraSmallView ? 'common.term.list' : 'common.term.list.for.sale')}</span>
                      </span>
                    </Styled.ActionButton>
                  ))}
              </Styled.ActionButtonContainer>
            )
          : marketplaceEnabled &&
            listed && (
              <Styled.ActionButtonContainer>
                <PurchaseButton nft={nonfungible} />
              </Styled.ActionButtonContainer>
            )}

        <Tabs
          tabs={isPack ? packTabs : tabs}
          setTab={setTab}
          fullWidth={mobileView}
          defaultValue={tab}
          indicatorColor={theme.custom.colors.Yellow}
          tabStyle={{ textTransform: 'uppercase', fontWeight: 400 }}
          style={{ marginTop: '1.5rem', fontFamily: 'Kevlar Underwear' }}
        />

        {isPack ? (
          <>
            {tab === 0 && (
              <Metadata
                emptyText={packTitles[tab].empty}
                attributes={
                  nonfungible?.metadata?.attributes && nonfungible?.metadata?.attributes.length > 0
                    ? [...nonfungible.metadata.attributes].reverse()
                    : []
                }
                heroProgress={nonfungible?.heroProgress || ({} as HeroProgress)}
                username={nonfungible?.username || ''}
              />
            )}
            {tab === 1 && (
              <Description desc={nonfungible?.metadata?.description || null} emptyText={packTitles[tab].empty} />
            )}
          </>
        ) : (
          <>
            {tab === 0 && (
              <Metadata
                emptyText={titles[tab].empty}
                attributes={
                  nonfungible?.metadata?.attributes && nonfungible?.metadata?.attributes.length > 0
                    ? [...nonfungible.metadata.attributes].reverse()
                    : []
                }
                heroProgress={nonfungible?.heroProgress || ({} as HeroProgress)}
                username={nonfungible?.username || ''}
              />
            )}
            {tab === 1 && (
              <Stats
                emptyText={titles[tab].empty}
                stats={nonfungible?.heroStats && nonfungible?.heroStats.length > 0 ? [...nonfungible.heroStats] : []}
              />
            )}
            {tab === 2 && (
              <Skills
                emptyText={titles[tab].empty}
                skills={nonfungible?.skills || []}
                maxStarLevel={nonfungible?.heroProgress?.maxStarLevel || 0}
              />
            )}
            {tab === 3 && (
              <Description desc={nonfungible?.metadata?.description || null} emptyText={titles[tab].empty} />
            )}
          </>
        )}
      </Styled.Root>
    </Waypoint>
  );
};

export default NFTDetails;
