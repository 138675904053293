import { styled } from '@mui/material';
import { config } from 'src/config/config';

const Root = styled('div')`
  display: block;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translate(0, -50%);

  @media (max-width: ${config.ui_variables.mobile_breakpoints.header}px) {
    right: 1rem;
  }
`;

const AccountButton = styled('div')`
  display: inline-block;
  margin-right: -8px;
  vertical-align: middle;
  font-size: 0;

  & > button {
    & .material-icons {
      font-size: 1.8rem;
    }
  }
`;

export default { Root, AccountButton };
