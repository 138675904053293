import { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import { Waypoint } from 'react-waypoint';
import { formatInTimeZone } from 'date-fns-tz';
import useBlogContext from 'src/hooks/useBlogContext';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
// import N3tworkLogo from 'src/assets/logo/n3twork_logo.webp';
import Styled from 'src/components/pages/blog/components/FeaturedBlogCard.styles';

interface FeaturedBlogCardProps {
  id: number;
}

const FeaturedBlogCard: FC<FeaturedBlogCardProps> = ({ id }) => {
  const { blogPosts } = useBlogContext();
  const [inView, setInView] = useState(false);
  const navigateInternal = useAppNavigateInternal();
  const blogPost = blogPosts.find(post => post.id === id);
  const { title = '', pubDate = Date.now(), contentRawNoImages = '', thumbnailImage = {} } = blogPost;
  const { src = '', alt = '' } = thumbnailImage;

  const handleGoToBlogDetails = () => {
    const encodedTitle = encodeURIComponent(title);

    navigateInternal(`/blog/${encodedTitle}`, { pooleData: { fromContext: 'blog' } });
  };

  if (!blogPost) return null;

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated ${inView ? 'fadeInUp' : ''}`} onClick={handleGoToBlogDetails}>
        <Styled.BackgroundImage
          id="featured-card-background-image"
          src={src}
          width={'100'}
          height={'100'}
          title={alt}
          alt={alt}
          style={{ bottom: '0' }}
        />

        <Styled.GradientOverlay />

        {/* <Styled.Logo
        src={N3tworkLogo}
        width={'100'}
        height={'100'}
        title={'N3twork Studios Inc. Logo'}
        alt={'N3twork Studios Inc. Logo'}
      /> */}

        <Styled.ContentContainer>
          <Styled.Title dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
          <Styled.Subtitle dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contentRawNoImages) }} />
          <Styled.Date>{formatInTimeZone(new Date(pubDate), 'America/New_York', 'LLLL dd, yyyy')}</Styled.Date>
        </Styled.ContentContainer>
      </Styled.Root>
    </Waypoint>
  );
};

export default FeaturedBlogCard;
