import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 2rem;
  transform: translateY(-50%);

  @media (max-width: 900px) {
    left: 1.5rem;
    transform: translateY(-60%);
  }

  @media (max-width: 600px) {
    left: 1.5rem;
  }

  @media (max-height: 600px) {
    transform: translateY(-60%);
  }
`;

const Name = styled('span')`
  display: inline-block;
  padding: 0.4rem 1rem 0.5rem 1rem;
  /* font-family: 'Kevlar Underwear'; */
  /* text-transform: uppercase; */
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2rem;
  color: #ffffff;
  position: relative;

  @media (max-width: 900px) {
    padding: 0.4rem 0.8rem 0.5rem 0.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.1rem;
  }

  @media (max-height: 600px) {
    padding: 0.4rem 0.8rem 0.5rem 0.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.1rem;
  }
`;

export default { Root, Name };
