export const getURLParamValue = (param: string, params: string[], values: (string | null)[]): string | null => {
  const i = params.indexOf(param);

  if (i === -1) return null;

  return values[i];
};

export const urlParams: URLParams = {
  home: ['state', 'code', 'error', 'error_description', 'trim'],
  profile: ['error', 'from_purchase', 'default_tab'],
  game: ['server_env'],
  farcaster: ['token'],
};
