import { styled } from '@mui/material';
import { motion } from 'framer-motion';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  display: inline-block;
  position: relative;
`;

interface CursorImgProps {
  anchor: 'top' | 'bottom';
  offsetx: number;
  offsety: number;
}

const CursorImg = styled(motion.img)<CursorImgProps>`
  display: inline-block;
  width: auto;
  height: 4rem;
  position: absolute;
  top: ${({ anchor, offsety }) => (anchor === 'bottom' ? 'auto' : `${offsety}px`)};
  bottom: ${({ anchor, offsety }) => (anchor === 'bottom' ? `${offsety}px` : 'auto')};
  left: ${({ offsetx }) => `calc(50% + ${offsetx}px)`};
  transform: ${({ anchor }) =>
    anchor === 'bottom' ? 'translate(-50%, 100%) rotate(180deg)' : 'translate(-50%, -100%) rotate(0deg)'};
  z-index: ${customThemeOptions.custom.zIndex.tutorial.cursor};
`;

export default { Root, CursorImg };
