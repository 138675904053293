import { styled } from '@mui/material';
import { motion } from 'framer-motion';

const Root = styled('div')`
  background-color: #000000aa;
  display: block;
  width: calc(100% - 8rem);
  max-width: 32rem;
  min-height: 8rem;
  padding: 2rem;
  margin: 0 4rem;
  box-sizing: border-box;
  position: absolute;
  left: calc(50% - 4rem);
  bottom: 1rem;
  transform: translateX(-50%);
  opacity: 1;
  pointer-events: none;

  @media (max-width: 900px) {
    padding: 1.5rem;
  }

  @media (max-width: 600px) {
    min-height: 8rem;
  }

  @media (max-height: 600px) {
    min-height: 6rem;
    padding: 1.5rem;
  }
`;

const DialogContainer = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Dialog = styled('p')`
  display: block;
  font-family: 'BarlowSemiCondensed';
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5em;
  letter-spacing: 0px;
  color: #ffffff;

  & img.tutorial-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    margin: 0 0.1rem 0.1rem 0rem;
  }

  @media (max-height: 600px) {
    font-size: 1.1rem;
    line-height: 1.4rem;

    & img.tutorial-icon {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  @media (max-width: 600px) {
    font-size: 1rem;
    line-height: 1.3rem;

    & img.tutorial-icon {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  @media (max-width: 500px) {
    font-size: 0.9rem;
    line-height: 1.2rem;

    & img.tutorial-icon {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
`;

const NextArrow = styled(motion.div)`
  display: inline-block;
  position: absolute;
  right: 2rem;
  bottom: 2rem;

  & .material-icons,
  & .material-icons-outlined {
    font-size: 2rem;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-height: 600px) {
    & .material-icons,
    & .material-icons-outlined {
      font-size: 1.8rem;
    }
  }

  @media (max-width: 600px) {
    right: 1.5rem;
    bottom: 1.5rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 1.4rem;
    }
  }
`;

export default { Root, DialogContainer, Dialog, NextArrow };
