import { CSSProperties, FC, useMemo } from 'react';
import Styled from './MediaPlaceholder.styles';
import { ReactComponent as PhotoIcon } from '../icons/iconic/photo.svg';
import PlayIcon from '../icons/PlayIcon';

export interface IMediaPlaceholderProps {
  size: 'small' | 'medium' | 'default' | 'full-width' | 'full-screen';
  active: boolean;
  isVideo: boolean;
  width: CSSProperties['width'];
  height: CSSProperties['height'];
  border?: CSSProperties['border'];
  borderRadius: CSSProperties['borderRadius'];
  minWidth?: CSSProperties['minWidth'];
  minHeight?: CSSProperties['minHeight'];
  maxWidth?: CSSProperties['maxWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  aspectratio: CSSProperties['aspectRatio'];
  onClick?: VoidFunction;
}

const MediaPlaceholder: FC<IMediaPlaceholderProps> = ({
  active = false,
  isVideo = false,
  borderRadius = '8px',
  border,
  width,
  height,
  size,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  aspectratio,
  onClick,
}) => {
  const icon = useMemo(() => (isVideo ? <PlayIcon /> : <PhotoIcon />), [isVideo]);
  const iconSize = useMemo(() => {
    if (size === 'small') {
      return '32px';
    }

    if (size === 'medium') {
      return '56px';
    }

    return '72px';
  }, [size]);

  return (
    <Styled.Root
      maxheight={maxHeight}
      minheight={minHeight}
      maxwidth={maxWidth}
      minwidth={minWidth}
      height={height}
      border={border}
      width={width}
      active={+active}
      borderradius={borderRadius}
      aspectratio={aspectratio}
      onClick={onClick}
    >
      <Styled.Placeholder borderradius={borderRadius}>
        <Styled.Icon icon={icon} width={iconSize} height={iconSize} />
      </Styled.Placeholder>
    </Styled.Root>
  );
};

export default MediaPlaceholder;
