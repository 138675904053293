import { createContext } from 'react';

type LegalNoticeVariant = 'terms' | 'privacy' | 'both';

export interface ILegalNoticeContext {
  showLegalNoticeBanner: boolean;
  setShowLegalNoticeBanner: (showLegalNoticeForced: boolean) => void;
  legalNoticeBannerVariant: LegalNoticeVariant;
  setLegalNoticeBannerVariant: (legalNoticeBannerVariant: LegalNoticeVariant) => void;
  legalNoticePopupVariant: LegalNoticeVariant;
  setLegalNoticePopupVariant: (legalNoticePopupVariant: LegalNoticeVariant) => void;
  showLegalNoticePopup: boolean;
  setShowLegalNoticePopup: (showLegalNoticePopup: boolean) => void;
  submitLegalNoticeView: boolean;
  setSubmitLegalNoticeView: (submitLegalNoticeView: boolean) => void;
  submitLegalNoticeAgree: boolean;
  setSubmitLegalNoticeAgree: (submitLegalNoticeAgree: boolean) => void;
  legalSubmissionError: string;
  setLegalSubmissionError: (legalSubmissionError: string) => void;
}

export const initialContext: ILegalNoticeContext = {
  showLegalNoticeBanner: false,
  setShowLegalNoticeBanner: () => undefined,
  legalNoticeBannerVariant: 'both',
  setLegalNoticeBannerVariant: () => undefined,
  showLegalNoticePopup: false,
  setShowLegalNoticePopup: () => undefined,
  legalNoticePopupVariant: 'both',
  setLegalNoticePopupVariant: () => undefined,
  submitLegalNoticeView: false,
  setSubmitLegalNoticeView: () => undefined,
  submitLegalNoticeAgree: false,
  setSubmitLegalNoticeAgree: () => undefined,
  legalSubmissionError: '',
  setLegalSubmissionError: () => undefined,
};

const LegalNoticeContext = createContext<ILegalNoticeContext>(initialContext);

LegalNoticeContext.displayName = 'LegalNoticeContext';

export default LegalNoticeContext;
