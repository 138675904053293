import { CSSProperties } from 'react';
import { styled } from '@mui/material';

export interface ImageProps {
  width?: CSSProperties['width'];
  maxWidth?: CSSProperties['maxWidth'];
  height?: CSSProperties['height'];
  loaded?: boolean;
}

const Image = styled('img')<ImageProps>`
  display: block;
  width: ${({ width = '100%' }) => width};
  max-width: ${({ maxWidth = '100%' }) => maxWidth};
  height: ${({ height }) => height};
  object-fit: cover;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity 0.3s ease-in;
`;

export default { Image };
