import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  margin-top: 1.3rem;
`;

const StatsContainer = styled('div')`
  display: block;

  & > div:nth-of-type(2n) {
    background-color: #ffffff11;
  }
`;

const StatRow = styled('div')`
  display: block;
  padding: 0.3rem 0.5rem 0.2rem 0.5rem;
  margin-top: 0.2rem;
  text-align: center;
`;

const StatLabel = styled('div')`
  display: inline-block;
  width: 50%;
  margin-bottom: 1px;
  vertical-align: middle;
  text-transform: capitalize;
  font-family: 'Kevlar Underwear';
  text-align: right;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1rem;
  color: #ffffff99;
`;

const StatSeparator = styled('span')`
  display: inline-block;
  padding: 0 0 0.2rem 0.8rem;
  vertical-align: middle;
  text-transform: capitalize;
  font-family: 'Barlow';
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #ffffff77;
`;

const StatValue = styled('div')`
  display: inline-block;
  width: 50%;
  padding: 0 0 0.2rem 0.8rem;
  vertical-align: middle;
  text-align: left;

  & > span {
    display: inline-block;
    vertical-align: bottom;
  }

  & > span:nth-of-type(1) {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    /* color: #ffffff; */
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  & > span:nth-of-type(2) {
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 0.9rem;
    color: #ffffff77;
    /* color: ${({ theme }) => `${theme.custom.colors.Yellow}`}; */
  }
`;

const EmptyText = styled('p')`
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #ffffff77;
`;

export default {
  Root,
  StatsContainer,
  StatRow,
  StatLabel,
  StatSeparator,
  StatValue,
  EmptyText,
};
