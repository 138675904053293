import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { Waypoint } from 'react-waypoint';
import { formatInTimeZone } from 'date-fns-tz';
import usePendingPurchasesContext from 'src/hooks/usePendingPurchasesContext';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getMarketplaceStats } from 'src/api/secondary-markets/get-marketplace-stats';
import { formatPrice } from 'src/tools/formatPrice';
import ATCIcon from 'src/components/icons/ATCIcon';
import ActionsBar from 'src/components/pages/marketplace/components/ActionsBar';
import Alert from 'src/components/feedback/Alert';
import { logMain } from 'src/modules/logger/logger';
import { config } from 'src/config/config';
import Styled from 'src/components/pages/marketplace/components/SectionDetails.styles';

const SectionDetails: FC = () => {
  const { loadPendingPurchases, setLoadPendingPurchases, concurrentPurchaseLimit } = usePendingPurchasesContext();
  const [inView, setInView] = useState(false);
  const [loading, setLoading] = useState(true);
  const [creationTimestamp, setCreationTimestamp] = useState(null);
  const [items, setItems] = useState(null);
  const [totalVolume, setTotalVolume] = useState(null);
  const [floorPrice, setFloorPrice] = useState(null);
  const [listedProportion, setListedProportion] = useState(null);
  const [owners, setOwners] = useState(null);
  const [uniqueOwnersProportion, setUniqueOwnersProportion] = useState(null);
  const pendingPurchases = useAppSelector(({ pendingPurchases }) => pendingPurchases.pendingPurchases);
  const { t: tMarketplace } = useTranslation(nameSpace.marketplace);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const dispatch = useAppDispatch();
  const navigateInternal = useAppNavigateInternal();
  const creationDate = creationTimestamp
    ? formatInTimeZone(new Date(creationTimestamp), 'America/New_York', 'LLL yyyy')
    : 'N/A';
  const royaltyFee = `${config.app_variables.royalty_fee * 100}%`;
  const normalizedTotalVolume = formatPrice(totalVolume || 0, { decimals: 0, hideSymbol: true });
  const normalizedFloorPrice = formatPrice(floorPrice || 0, { decimals: 0, hideSymbol: true });
  const listed = listedProportion ? `${Math.floor(listedProportion * 100)}%` : '- -';
  const uniqueOwners = uniqueOwnersProportion ? `${Math.floor(uniqueOwnersProportion * 100)}%` : '- -';

  // Load Page Data
  useEffect(() => {
    if (loading) {
      // Get marketplace stats
      dispatch(getMarketplaceStats())
        .then(data => {
          if (data && Object.keys(data).length > 0) {
            typeof data?.creationTimestamp !== 'undefined' && setCreationTimestamp(data?.creationTimestamp);
            typeof data?.items !== 'undefined' && setItems(data?.items);
            typeof data?.totalVolume !== 'undefined' && setTotalVolume(data?.totalVolume);
            typeof data?.floorPrice !== 'undefined' && setFloorPrice(data?.floorPrice);
            typeof data?.listedProportion !== 'undefined' && setListedProportion(data?.listedProportion);
            typeof data?.owners !== 'undefined' && setOwners(data?.owners);
            typeof data?.uniqueOwnersProportion !== 'undefined' &&
              setUniqueOwnersProportion(data?.uniqueOwnersProportion);
          }
        })
        .catch(() => {
          logMain.debug('Error loading marketplace stats');
        });

      // Get pending purchases for current user
      if (!loadPendingPurchases) {
        setLoadPendingPurchases(true);
      }

      setLoading(false);
    }
  }, [dispatch, loadPendingPurchases, loading, setLoadPendingPurchases]);

  return (
    <Styled.Root>
      <Styled.CenteredContainer>
        <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
          <div className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
            <Styled.DetailsContainer>
              <Styled.Title>{tCommon('common.project.name')}</Styled.Title>

              <Styled.Subtitle>
                <span>{tMarketplace('marketplace.details.subtitle.by')}</span>
                <span>{tMarketplace('marketplace.details.subtitle.company')}</span>
                <span className="material-icons">verified</span>
              </Styled.Subtitle>

              <Styled.OverviewContainer>
                <Styled.OverviewLabel>{tMarketplace('marketplace.details.overview.label1')}</Styled.OverviewLabel>
                <Styled.OverviewValue>{`${items !== null ? items : '0'}`}</Styled.OverviewValue>

                <Styled.OverviewSeparator>-</Styled.OverviewSeparator>

                <Styled.OverviewLabel>{tMarketplace('marketplace.details.overview.label2')}</Styled.OverviewLabel>
                <Styled.OverviewValue>{`${creationDate}`}</Styled.OverviewValue>

                <Styled.OverviewSeparator>-</Styled.OverviewSeparator>

                <Styled.OverviewLabel>{tMarketplace('marketplace.details.overview.label3')}</Styled.OverviewLabel>
                <Styled.OverviewValue>{`${royaltyFee}`}</Styled.OverviewValue>
              </Styled.OverviewContainer>

              <Styled.Desc>{tMarketplace('marketplace.details.desc')}</Styled.Desc>

              <Styled.MetricsContainer>
                <Styled.Metric>
                  <Styled.MetricValue>
                    <ATCIcon size={20} style={{ margin: '0 0.3rem 2px 0' }} />
                    {`${totalVolume !== null ? normalizedTotalVolume : '- -'}`}
                  </Styled.MetricValue>
                  <Styled.MetricLabel>{tMarketplace('marketplace.details.metrics.label1')}</Styled.MetricLabel>
                </Styled.Metric>

                <Styled.Metric>
                  <Styled.MetricValue>
                    <ATCIcon size={20} style={{ margin: '0 0.3rem 2px 0' }} />
                    {`${floorPrice !== null ? normalizedFloorPrice : '- -'}`}
                  </Styled.MetricValue>
                  <Styled.MetricLabel>{tMarketplace('marketplace.details.metrics.label2')}</Styled.MetricLabel>
                </Styled.Metric>

                <Styled.Metric>
                  <Styled.MetricValue>{`${listed !== null ? listed : '- -'}`}</Styled.MetricValue>
                  <Styled.MetricLabel>{tMarketplace('marketplace.details.metrics.label4')}</Styled.MetricLabel>
                </Styled.Metric>

                <Styled.Metric>
                  <Styled.MetricValue>{`${owners !== null ? owners : '- -'}`}</Styled.MetricValue>
                  <Styled.MetricLabel>{tMarketplace('marketplace.details.metrics.label5')}</Styled.MetricLabel>
                </Styled.Metric>

                <Styled.Metric>
                  <Styled.MetricValue>{`${uniqueOwners !== null ? uniqueOwners : '- -'}`}</Styled.MetricValue>
                  <Styled.MetricLabel>{tMarketplace('marketplace.details.metrics.label6')}</Styled.MetricLabel>
                </Styled.Metric>
              </Styled.MetricsContainer>
            </Styled.DetailsContainer>
          </div>
        </Waypoint>

        <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
          <div className={`inview animated delay-2 ${inView ? 'fadeIn' : ''}`}>
            <ActionsBar />
          </div>
        </Waypoint>

        {pendingPurchases.length > 0 && (
          <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
            <div className={`inview animated delay-1 ${inView ? 'fadeIn' : ''}`}>
              {pendingPurchases.length === concurrentPurchaseLimit ? (
                <Alert
                  severity={'error'}
                  msg={tCommon('common.message.purchase.pending.max')}
                  action={tCommon('common.nav.profile')}
                  onAction={() =>
                    navigateInternal('/profile?default_tab=3', {
                      pooleData: { fromContext: 'marketplace_pending_purchases' },
                    })
                  }
                  style={{ marginTop: '2rem' }}
                />
              ) : (
                <Alert
                  severity={'warning'}
                  msg={tCommon('common.message.purchase.pending')}
                  action={tCommon('common.nav.profile')}
                  onAction={() =>
                    navigateInternal('/profile?default_tab=3', {
                      pooleData: { fromContext: 'marketplace_pending_purchases' },
                    })
                  }
                  style={{ marginTop: '2rem' }}
                />
              )}
            </div>
          </Waypoint>
        )}
      </Styled.CenteredContainer>
    </Styled.Root>
  );
};

export default SectionDetails;
