import { styled } from '@mui/material';
import BaseIcon from '../../../icons/BaseIcon';

const Root = styled('div')`
  text-align: center;
  margin: auto;
  width: 100%;
  .newsletter-wrapper {
    ${({ theme }) => theme.breakpoints.down('md')} {
      display: block;
      h2 {
        margin-top: 0;
        text-align: center;
        margin-bottom: 1rem;
        line-height: 1;
      }
    }
  }
  h2 {
    font-family: ${({ theme }) => theme.custom.typography.LabelSm.fontFamily};
    font-weight: 700;
    flex: 1 0 0%;
    font-size: 2rem;
    margin-top: 0.9rem;
    text-align: right;
  }
  button {
    margin-top: 4px;
    width: 145px;
    svg {
      width: 100%;
    }
    ${({ theme }) => theme.breakpoints.down('lg')} {
      margin-top: -1px;
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-top: 1rem;
    }
  }
  .form-wrapper {
    ${({ theme }) => theme.breakpoints.down('md')} {
      display: block;
    }
  }
  .complete-alert-message {
    ${({ theme }) => theme.breakpoints.down('md')} {
      margin: 1.3rem auto 0;
    }
  }
  .alert-message {
    ${({ theme }) => theme.breakpoints.down('md')} {
      margin: 1.3rem auto 0;
    }
  }
`;

const NewsLetter = styled('div')`
  text-align: center;
  margin: auto;
  width: 100%;
  flex: 1 0 0%;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: block;
  }
`;

const InputWrapper = styled('div')`
  position: relative;
  #email {
    position: relative;
    width: 325px;
    margin: auto;
    top: 14px;
    background-color: #ebbf2d;
    border: 0;
    margin-bottom: 40px;
    &:focus {
      background-color: #ebbf2d;
      border: 0;
    }
    ${({ theme }) => theme.breakpoints.down('lg')} {
      top: 1vw;
      width: 81%;
    }
    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 325px;
      top: 14px;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 275px;
      top: 8px;
      margin-bottom: 30px;
    }
  }
`;

const InputAgree = styled('div')`
  position: relative;
  text-align: left;
  max-width: 400px;
  margin: auto;

  & > p {
    margin-left: 24px;
    font-family: ${({ theme }) => theme.custom.typography.LabelSm.fontFamily};
    font-size: 11.5px;
    line-height: 1.1;
    color: ${({ theme }) => theme.custom.colors.Black};

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-left: 14px;
    }
  }

  label {
    color: ${({ theme }) => theme.custom.colors.Black};
    margin-top: 1rem;
    margin-right: 0;
    margin-left: 10px;
    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-left: 0;
    }
    .MuiFormControlLabel-label {
      font-size: 11.5px;
      line-height: 1.1;
      font-family: ${({ theme }) => theme.custom.typography.LabelSm.fontFamily};

      ${({ theme }) => theme.breakpoints.down('md')} {
        margin-bottom: 16px;
      }
    }
    > span {
      &:hover {
        background: transparent;
      }
    }
    .Mui-checked {
      color: ${({ theme }) => theme.custom.colors.Black};
    }
  }
`;

const InputIcon = styled(BaseIcon)`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  margin: auto;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 390px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 340px;
  }
`;

export default { Root, NewsLetter, InputWrapper, InputAgree, InputIcon };
