import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useAlertOverrideContext from 'src/hooks/useAlertOverrideContext';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { setSnackbar } from 'src/redux/slices/snackbar/snackbar';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/modals/GameErrorModal.styles';
import { config } from 'src/config/config';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface GameErrorModalProps {
  style?: React.CSSProperties;
}

const GameErrorModal: FC<GameErrorModalProps> = ({ style = {} }) => {
  const { alertOverrideMessage } = useAlertOverrideContext();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tPlay } = useTranslation(nameSpace.play);
  const navigateExternal = useAppNavigateExternal();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  //   const handleClose = () => {
  //     setAlertOverrideMessage('');
  //   };

  const handleReload = () => {
    window.location.reload();
  };

  const handleSubmit = () => {
    navigateExternal(config.base_url.zendesk, {
      newWindow: true,
      pooleData: { pageName: 'Zendesk', fromContext: 'game' },
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(alertOverrideMessage || '');
    dispatch(setSnackbar({ severity: 'success', msg: tCommon('common.copy.clipboard') }));
  };

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={Boolean(alertOverrideMessage)}
      //   onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.gameError}` }}
    >
      <Fade in={Boolean(alertOverrideMessage)}>
        <Styled.Root style={style}>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            <Styled.Heading>{tPlay('play.popup.error.title')}</Styled.Heading>

            <Styled.SubheadingsContainer>
              <Styled.Subheading>{tPlay('play.popup.error.subheading.1')}</Styled.Subheading>
              <Styled.Subheading>{tPlay('play.popup.error.subheading.2')}</Styled.Subheading>
              <Styled.Subheading>
                <i>{tPlay('play.popup.error.subheading.3')}</i>
              </Styled.Subheading>
            </Styled.SubheadingsContainer>

            <Styled.ErrorMessageContainer>
              <Styled.CopyContainer onClick={handleCopy}>
                <span className="material-icons-outlined">copy</span>
              </Styled.CopyContainer>

              <Styled.ErrorMessage>{alertOverrideMessage}</Styled.ErrorMessage>
            </Styled.ErrorMessageContainer>

            <Styled.ButtonsContainer>
              <ButtonHex
                hoverEffect
                variant={'white'}
                width={mobileView ? 175 : 150}
                height={45}
                title={tPlay('play.popup.button.secondary')}
                style={{
                  display: mobileView ? 'block' : 'inline-block',
                  margin: mobileView ? '0 auto 0 auto' : '0',
                }}
                onClick={handleReload}
                //   disabled={processing}
              />

              <ButtonHex
                hoverEffect
                variant="gold"
                width={mobileView ? 175 : 150}
                height={45}
                title={tPlay('play.popup.button.primary')}
                style={{
                  display: mobileView ? 'block' : 'inline-block',
                  margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                }}
                onClick={handleSubmit}
                //   disabled={processing}
              />
            </Styled.ButtonsContainer>
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default GameErrorModal;
