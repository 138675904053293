/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getListPriceMarketsArr } from 'src/redux/slices/list-price-markets/list-price-markets';
import { getServerGameTime } from 'src/tools/getGameTime';
import useTutorialContext from 'src/hooks/useTutorialContext';
import useGameOverlayContext from './useGameOverlayContext';
// import { logMain } from 'src/modules/logger/logger';

// Function to check if there are unread items in Packs tab
const checkUnreadPacksExist = (tutorialState: any) => {
  if (tutorialState.starterMintPack) return true;
  if (tutorialState.heroQuestExplain) return true;
  // if (tutorialState.heroQuestMid) return true;
  if (tutorialState.heroQuestClaim) return true;

  return false;
};

// Function to check if there are unread items in Packs tab
const checkUnreadMarketplaceExist = () => {
  return false;
};

// Function to check if there are unread items in Rewards
const checkUnreadRewardsExist = (listPriceMarkets: ListPriceMarket[]) => {
  // Uncomment the following line to show pip only after user completes starter tutorial and Yuna is done explaining quest
  // if (!tutorialState.starterComplete || tutorialState.heroQuestExplain) return false;

  const now = getServerGameTime();
  const dailyLPMs = listPriceMarkets.filter(lpm => lpm.type === 'daily');

  for (let i = 0; i < dailyLPMs.length; i++) {
    const lpm = dailyLPMs[i];
    const { purchaseCount, purchaseLimit, startTimestampMs } = lpm;
    const purchaseLimitReached = purchaseLimit !== null && purchaseLimit !== 0 && purchaseCount >= purchaseLimit;
    const inRedemptionPeriod = startTimestampMs && startTimestampMs <= now;

    if (inRedemptionPeriod && !purchaseLimitReached) {
      return true;
    }
  }

  return false;
};

// Function to check if there are unread items in My Collection tab
const checkUnreadCollectionExist = (packs: CollectionItem[], tutorialState: any, enablePipsUnopenedPacks: boolean) => {
  if (enablePipsUnopenedPacks && packs.length > 0) return true;
  if (tutorialState.starterOpenPack) return true;

  return false;
};

interface NavBarPips {
  packs: boolean;
  marketplace: boolean;
  rewards: boolean;
  collection: boolean;
}

interface UseGameOverlayNavBarUnreadPips {
  getNavBarPips: () => void;
  pips: NavBarPips;
}

export const useGameOverlayNavBarUnreadPips = (): UseGameOverlayNavBarUnreadPips => {
  const { tutorialState } = useTutorialContext();
  const { enablePipsUnopenedPacks } = useGameOverlayContext();
  const listPriceMarkets = useAppSelector(state => getListPriceMarketsArr(state));
  const collectionItems = useAppSelector(({ collectionItems }) => collectionItems.collectionItems);
  const packs = collectionItems.filter(item => item.type === 'pack' && !item.listed);

  const handleGetNavBarPips = useCallback(() => {
    return {
      packs: checkUnreadPacksExist(tutorialState),
      marketplace: checkUnreadMarketplaceExist(),
      rewards: checkUnreadRewardsExist(listPriceMarkets),
      collection: checkUnreadCollectionExist(packs, tutorialState, enablePipsUnopenedPacks),
    };
  }, [enablePipsUnopenedPacks, listPriceMarkets, packs, tutorialState]);

  useEffect(() => {
    handleGetNavBarPips();
  }, [handleGetNavBarPips]);

  return {
    getNavBarPips: handleGetNavBarPips,
    pips: handleGetNavBarPips(),
  };
};
