import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #ffffffcc;
`;

const ResultsContainer = styled('div')`
  display: block;
  margin: 1rem 0 0 0;
`;

const ItemContainer = styled('div')`
  display: block;
  min-width: 12rem;
  font-size: 0;
  box-sizing: border-box;
`;

const Allowlist = styled('div')`
  display: inline-block;
  margin: 0.5rem 0.5rem 0 0;
  padding: 0.3rem 0.7rem;
  border: ${({ theme }) => `${theme.custom.colors.Yellow} 1px solid`};
  border-radius: 0.2rem;
  word-break: break-word;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: ${props => props.theme.custom.colors.Yellow};
  cursor: default;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => `${theme.custom.colors.Yellow}cc`};
    color: ${({ theme }) => `${theme.custom.colors.Yellow}cc`};
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

export default {
  Root,
  Title,
  ResultsContainer,
  ItemContainer,
  Allowlist,
};
