import { FC, ReactNode, useState, useMemo, useEffect, useCallback } from 'react';
import ViewportContext, { initialContext, IViewportContext } from 'src/context/viewport/Viewport.context';
import { logMain } from 'src/modules/logger/logger';

interface ViewportProviderProps {
  children: ReactNode;
}

const ViewportProvider: FC<ViewportProviderProps> = ({ children }) => {
  const [viewportWidth, setViewportWidth] = useState<IViewportContext['viewportWidth']>(initialContext.viewportWidth);
  const [viewportHeight, setViewportHeight] = useState<IViewportContext['viewportHeight']>(
    initialContext.viewportHeight,
  );
  const [landscape, setLandscape] = useState<IViewportContext['landscape']>(initialContext.landscape);

  const getViewportWidth = useCallback(() => {
    return Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0);
  }, []);

  const getViewportHeight = useCallback(() => {
    return Math.max(document?.documentElement?.clientHeight || 0, window?.innerHeight || 0);
  }, []);

  useEffect(() => {
    // Callback function to handle load event.
    const handleLoad = () => {
      const vw = getViewportWidth();
      const vh = getViewportHeight();
      const isLandscape = vw > vh;

      setViewportWidth(vw);
      setViewportHeight(vh);
      setLandscape(isLandscape);

      logMain.info(`%c[EVENT]: WINDOW LOAD`, `color: magenta`);
    };

    // Callback function to handel resize events
    const handleResize = () => {
      const vw = getViewportWidth();
      const vh = getViewportHeight();
      const isLandscape = vw > vh;

      setViewportWidth(vw);
      setViewportHeight(vh);
      setLandscape(isLandscape);
    };

    // Callback function to handle timeout for resize call to ensure orientation change registers updated dimensions.
    const handleResizeTimeout = () => {
      setTimeout(handleResize, 50);

      logMain.info(`%c[EVENT]: ORIENTATION CHANGE`, `color: magenta`);
    };

    // Add event listeners for window load and resize events
    window.addEventListener('load', handleLoad, { once: true }); // Once true to ensure the event is only called once
    window.addEventListener('resize', handleResize);

    // Add event listeners for orientation change events
    if (window?.screen?.orientation) {
      window.screen.orientation.addEventListener('change', handleResizeTimeout);
      window.screen.orientation.onchange = handleResizeTimeout;
    }

    // Cleanup event listeners
    return () => {
      window.removeEventListener('resize', handleResize);

      if (window?.screen?.orientation) {
        window.screen.orientation.removeEventListener('change', handleResizeTimeout);
        window.screen.orientation.onchange = () => null;
      }
    };
  }, [getViewportHeight, getViewportWidth]);

  const value = useMemo(
    () => ({
      viewportWidth,
      viewportHeight,
      landscape,
    }),
    [viewportWidth, viewportHeight, landscape],
  );

  return <ViewportContext.Provider value={value}>{children}</ViewportContext.Provider>;
};

export default ViewportProvider;
