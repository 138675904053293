import { FC } from 'react';
import Styled from 'src/components/data-display/Level.styles';

const getStyles = (rank: number, variant: Variant, enableBase: boolean) => {
  const digits = rank.toString().length;
  const size = variant === 'default' ? '3.2rem' : '2.5rem';

  switch (digits) {
    case 1:
      if (enableBase) {
        return {
          size,
          fontSize: variant === 'condensed' ? '2.2rem' : '2.6rem',
          letterSpacing: variant === 'condensed' ? '-15px' : '-15px',
        };
      }

      return {
        size,
        fontSize: variant === 'condensed' ? '1.8rem' : '2.2rem',
        letterSpacing: variant === 'condensed' ? '-15px' : '-15px',
      };

    case 2:
      return {
        size,
        fontSize: variant === 'condensed' ? '1.5rem' : '1.8rem',
        letterSpacing: variant === 'condensed' ? '-11px' : '-13px',
      };
    // case 3:
    //   return {
    //     size,
    //     fontSize: variant === 'condensed' ? '1.2rem' : '1.5rem',
    //     letterSpacing: variant === 'condensed' ? '-9px' : '-11px',
    //   };
    // case 4:
    //   return {
    //     size,
    //     fontSize: variant === 'condensed' ? '1rem' : '1.3rem',
    //     letterSpacing: variant === 'condensed' ? '-7px' : '-11px',
    //   };
    // case 5:
    //   return {
    //     size,
    //     fontSize: variant === 'condensed' ? '0.8rem' : '1rem',
    //     letterSpacing: variant === 'condensed' ? '-6px' : '-8px',
    //   };
    // case 6:
    //   return {
    //     size,
    //     fontSize: variant === 'condensed' ? '0.7rem' : '0.8rem',
    //     letterSpacing: variant === 'condensed' ? '-5px' : '-6px',
    //   };
    default:
      return {
        size,
        fontSize: variant === 'condensed' ? '1.5rem' : '1.8rem',
        letterSpacing: variant === 'condensed' ? '-12px' : '-13px',
      };
  }
};

type Variant = 'condensed' | 'default';

interface LevelProps {
  level: number;
  variant?: Variant;
  enableBase?: boolean;
  borderStyle?: 'circle' | 'diamond';
  style?: React.CSSProperties;
}

const Level: FC<LevelProps> = ({
  level,
  variant = 'default',
  enableBase = false,
  borderStyle = 'circle',
  style = {},
}) => {
  return (
    <Styled.Root variantStyles={getStyles(level, variant, enableBase)} style={style}>
      {borderStyle === 'circle' ? (
        <>
          <Styled.CircleOuter />
          <Styled.CircleInner />
        </>
      ) : (
        <>
          <Styled.DiamondOuter />
          <Styled.DiamondInner />
        </>
      )}

      <span>{enableBase && level === 1 ? 'B' : level}</span>
    </Styled.Root>
  );
};

export default Level;
