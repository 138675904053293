import { combineReducers } from '@reduxjs/toolkit';
import snackbar from 'src/redux/slices/snackbar/snackbar';
import authenticated from 'src/redux/slices/authenticated/authenticated';
import currentProfile from 'src/redux/slices/current-profile/current-profile';
import loadedProfiles from 'src/redux/slices/loaded-profiles/loaded-profiles';
import dropEvents from 'src/redux/slices/drop-events/drop-events';
import listPriceMarkets from 'src/redux/slices/list-price-markets/list-price-markets';
import purchases from 'src/redux/slices/purchases/purchases';
import pendingPurchases from 'src/redux/slices/pending-purchases/pending-purchases';
import nonfungibles from 'src/redux/slices/nonfungibles/nonfungibles';
import collectionItems from 'src/redux/slices/collection-items/collection-items';
import listings from 'src/redux/slices/listings/listings';
import leaderboards from 'src/redux/slices/leaderboards/leaderboards';
import leaderboardItems from 'src/redux/slices/leaderboard-items/leaderboard-items';
import tutorialStates from 'src/redux/slices/tutorial-states/tutorial-states';
import currencyStates from 'src/redux/slices/currency-states/currency-states';

const rootReducer = combineReducers({
  snackbar,
  authenticated,
  currentProfile,
  loadedProfiles,
  dropEvents,
  listPriceMarkets,
  purchases,
  pendingPurchases,
  nonfungibles,
  collectionItems,
  listings,
  leaderboards,
  leaderboardItems,
  tutorialStates,
  currencyStates,
});

export default rootReducer;
