import { styled } from '@mui/material';

const Root = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 44rem;
  position: relative;

  @media (max-width: 768px) {
    height: 44rem;
  }
`;

const Overlay = styled('div')`
  display: block;
  width: 100%;
  padding: 0 10rem;
  word-wrap: break-word;
  box-sizing: border-box;
  position: absolute;
  top: calc(50% + 2.5rem);
  left: 0;
  transform: translate(0, -50%);

  @media (max-width: 1200px) {
    padding: 0 5rem;
  }

  @media (max-width: 768px) {
    background: linear-gradient(to top, #00000055 0%, #00000055 70%, #00000000 100%);
    width: auto;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: none;
  }
`;

const CopyContainer = styled('div')`
  display: block;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 2rem;
    position: absolute;
    top: calc(50% + 2.5rem);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  max-width: 65rem;
  margin: 0 0 0 -0.3rem;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 6rem;
  line-height: 5.3rem;
  font-weight: 700;
  color: ${({ color }) => color || '#ffffff'};

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1200px) {
    font-size: 5.6rem;
    line-height: 5rem;
  }

  @media (max-width: 1000px) {
    max-width: 30rem;
  }

  @media (max-width: 768px) {
    max-width: none;
    margin: 0 auto;
    text-align: center;
    font-size: 5rem;
    line-height: 4.8rem;
  }

  @media (max-width: 500px) {
    font-size: 4rem;
    line-height: 3.8rem;
  }

  @media (max-width: 450px) {
    font-size: 4rem;
    line-height: 3.8rem;
  }

  @media (max-width: 350px) {
    font-size: 3.4rem;
    line-height: 3.4rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  width: 100%;
  max-width: 35rem;
  margin-top: 2.5rem;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1200px) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  @media (max-width: 1000px) {
    max-width: 26rem;
  }

  @media (max-width: 768px) {
    max-width: 33rem;
    margin: 2.5rem auto 0 auto;
    text-align: center;
    /* font-size: 1.1rem;
    line-height: 1.3rem; */
  }

  /* @media (max-width: 450px) {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }

  @media (max-width: 350px) {
    font-size: 1.1rem;
    line-height: 1.3rem;
  } */
`;

const Body = styled('p')`
  display: block;
  width: 100%;
  max-width: 35rem;
  margin-top: 1rem;
  /* text-transform: uppercase; */
  font-size: 1rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #ffffff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1200px) {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @media (max-width: 1000px) {
    max-width: 26rem;
  }

  @media (max-width: 768px) {
    max-width: 33rem;
    margin: 1rem auto 0 auto;
    text-align: center;
    /* font-size: 0.9rem;
    line-height: 1.3rem; */
  }

  /* @media (max-width: 450px) {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  @media (max-width: 350px) {
    font-size: 0.8rem;
    line-height: 1.2rem;
  } */
`;

const ButtonsContainer = styled('div')`
  display: block;
  margin-top: 2.7rem;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export default {
  Root,
  Overlay,
  CopyContainer,
  Heading,
  Subheading,
  Body,
  ButtonsContainer,
};
