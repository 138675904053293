import { FC, memo, useRef } from 'react';
import PrimaryMarketplace from 'src/components/primary-marketplace/PrimaryMarketplace';
import Styled from 'src/components/game-overlay/pages/PagePrimaryMarketplace.styles';

type DropEventTag = 'pack' | 'daily' | 'hero';

const PagePrimaryMarketplace: FC = () => {
  const dropEventTags = useRef<DropEventTag[]>(['pack', 'hero']);

  return (
    <Styled.Root>
      <PrimaryMarketplace dropEventTags={dropEventTags.current} />
    </Styled.Root>
  );
};

export default memo(PagePrimaryMarketplace);
