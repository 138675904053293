import { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { GTMEvents } from 'src/modules/gtm/events';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { getRarityNameFromEnum } from 'src/tools/getRarityNameFromEnum';
import { getPackTierFromType } from 'src/tools/getPackTierFromType';
import { getPackTierColors } from 'src/tools/getPackTierColors';
import Separator from 'src/components/layout/Separator';
import BulletPoint from 'src/components/layout/BulletPoint';
import BaseIcon from 'src/components/icons/BaseIcon';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import TwitterSocialIcon from 'src/components/icons/TwitterSocialIcon';
import Styled from 'src/components/pages/event-pack/components/SectionDetails.styles';

interface RarityIcon {
  name: string;
  desc: string;
  title: string;
  width: string;
  height: string;
  path: string;
}

interface RarityIcons {
  common: RarityIcon;
  uncommon: RarityIcon;
  rare: RarityIcon;
  ultraRare: RarityIcon;
  epic: RarityIcon;
  legendary: RarityIcon;
}

interface SectionDetailsProps {
  listing: 'starter-pack' | 'alpha-pack' | 'bronze-pack' | 'silver-pack' | 'gold-pack';
  marketId: string;
}

const SectionDetails: FC<SectionDetailsProps> = ({ listing, marketId }) => {
  const { t: tEvent } = useTranslation(nameSpace.event);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const [inView2, setInView2] = useState(false);
  const [inView3, setInView3] = useState(false);
  const [inView5, setInView5] = useState(false);
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, marketId));
  const navigateExternal = useAppNavigateExternal();
  const rarityIcons: RarityIcons = tCommon('common.rarity.icons', { returnObjects: true });
  const packTier = getPackTierFromType(listing);
  const tierColors = getPackTierColors(packTier);

  return (
    <Styled.Root bgColor={tierColors.bgPrimary}>
      <Styled.ContentOuterContainer bgColor={tierColors.bgSecondary}>
        <Styled.ContentInnerContainer>
          <Waypoint onEnter={() => setInView2(true)} bottomOffset="20px">
            <div>
              <Separator className={`inview animated ${inView2 ? 'fadeIn' : ''}`} />

              <Styled.DetailSection className={`inview animated ${inView2 ? 'fadeInUp' : ''}`}>
                <Styled.SectionTitle>{tEvent('event.detail.section.1.title')}</Styled.SectionTitle>

                <Styled.SectionBullets>
                  <Styled.Bullet>
                    <BulletPoint />
                    <Styled.BulletValue>{tEvent('event.detail.section.1.bullet.1')}</Styled.BulletValue>
                  </Styled.Bullet>

                  <Styled.Bullet>
                    <BulletPoint />
                    <Styled.BulletValue>{tEvent('event.detail.section.1.bullet.2')}</Styled.BulletValue>
                  </Styled.Bullet>

                  {listPriceMarket?.rarities && listPriceMarket.rarities.length > 0 && (
                    <Styled.RaritiesContainer>
                      {listPriceMarket.rarities.map((rarityArr, i) => {
                        return (
                          <Styled.RarityContainer key={`rarities-${marketId}-${i}`}>
                            <Styled.RaritiesLabel>{`${'NFT'} #${i + 1}`}</Styled.RaritiesLabel>

                            {rarityArr.map((rarity, j) => {
                              const { id, percentage } = rarity;
                              const rarityName: keyof RarityIcons =
                                (getRarityNameFromEnum(id) as keyof RarityIcons) || 'common';

                              return (
                                <Styled.RarityContainer key={`rarities-${marketId}-${i}-${j}`}>
                                  <Styled.RarityImgContainer>
                                    <Styled.RarityImg
                                      src={`/assets/${rarityIcons[rarityName].path}`}
                                      width={rarityIcons[rarityName].width}
                                      height={rarityIcons[rarityName].height}
                                      loading="lazy"
                                      alt={rarityIcons[rarityName].title}
                                    />
                                  </Styled.RarityImgContainer>

                                  <Styled.RarityInfoContainer>
                                    <Styled.RarityName>{rarityIcons[rarityName].name}</Styled.RarityName>
                                    <Styled.RarityDesc>{rarityIcons[rarityName].desc}</Styled.RarityDesc>
                                  </Styled.RarityInfoContainer>

                                  <Styled.RarityChance>{percentage.toFixed(1)}%</Styled.RarityChance>
                                </Styled.RarityContainer>
                              );
                            })}
                          </Styled.RarityContainer>
                        );
                      })}
                    </Styled.RaritiesContainer>
                  )}
                </Styled.SectionBullets>
              </Styled.DetailSection>
            </div>
          </Waypoint>

          <Waypoint onEnter={() => setInView3(true)} bottomOffset="20px">
            <div>
              <Separator className={`inview animated ${inView3 ? 'fadeIn' : ''}`} />

              <Styled.DetailSection className={`inview animated ${inView3 ? 'fadeInUp' : ''}`}>
                <Styled.SectionTitle>{tEvent('event.detail.section.2.title')}</Styled.SectionTitle>

                <Styled.SectionDesc
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tEvent('event.detail.section.2.desc')) }}
                />

                <Styled.SectionBullets>
                  <Styled.Bullet>
                    <BulletPoint />
                    <Styled.BulletValue>{tEvent('event.detail.section.2.bullet.1')}</Styled.BulletValue>
                  </Styled.Bullet>
                </Styled.SectionBullets>
              </Styled.DetailSection>
            </div>
          </Waypoint>

          <Waypoint onEnter={() => setInView5(true)} bottomOffset="20px">
            <div>
              <Separator className={`inview animated ${inView5 ? 'fadeIn' : ''}`} />

              <Styled.SectionCommunity className={`inview animated ${inView5 ? 'fadeInUp' : ''}`}>
                <Styled.CommunityTitle>{tEvent('event.community.title')}</Styled.CommunityTitle>
                <Styled.CommunityDesc>{tEvent('event.community.desc')}</Styled.CommunityDesc>

                <Styled.CommunitySocialsContainer>
                  <Styled.SocialContainer>
                    <Styled.SocialDiamond />

                    <BaseIcon
                      cursor="pointer"
                      hoverColor="#dbba5a"
                      icon={<DiscordSocialIcon />}
                      height="25px"
                      style={{
                        display: 'block',
                        height: '25px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      onClick={() => {
                        navigateExternal(tCommon('common.social.discord'), {
                          newWindow: true,
                          pooleData: { pageName: 'Discord', fromContext: 'event_details' },
                          gtmEvent: () =>
                            GTMEvents.clickBtnDiscord({ eventContext: 'event_details', eventIsOutboundLink: true }),
                        });
                      }}
                    />
                  </Styled.SocialContainer>

                  <Styled.SocialContainer>
                    <Styled.SocialDiamond />

                    <BaseIcon
                      cursor="pointer"
                      hoverColor="#dbba5a"
                      icon={<TwitterSocialIcon />}
                      height="15px"
                      style={{
                        display: 'block',
                        height: '15px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                      onClick={() => {
                        navigateExternal(tCommon('common.social.twitter'), {
                          newWindow: true,
                          pooleData: { pageName: 'Twitter', fromContext: 'event_details' },
                          gtmEvent: () =>
                            GTMEvents.clickBtnTwitter({ eventContext: 'event_details', eventIsOutboundLink: true }),
                        });
                      }}
                    />
                  </Styled.SocialContainer>
                </Styled.CommunitySocialsContainer>
              </Styled.SectionCommunity>
            </div>
          </Waypoint>
        </Styled.ContentInnerContainer>
      </Styled.ContentOuterContainer>
    </Styled.Root>
  );
};

export default SectionDetails;
