import { styled } from '@mui/material';

interface SwiperPrevProps {
  disabled: boolean;
}

const SwiperPrev = styled('div')<SwiperPrevProps>`
  background-color: #00000077;
  display: block;
  width: 2rem;
  height: 4rem;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  position: absolute;
  top: 35rem;
  left: 0;
  transform: translate(0, -50%);
  z-index: 1;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;

  & > span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    color: ${({ disabled }) => (disabled ? '#ffffff33' : '#ffffff')};
    /* transition: font-size 0.3s ease-in-out; */
  }

  &:hover {
    /* width: 2.5rem; */
    height: 5rem;

    /* & > span {
      font-size: 1.8rem;
    } */
  }

  @media (max-width: 768px) {
    top: 33rem;
  }

  @media (max-width: 700px) {
    top: 30rem;
  }
`;

interface SwiperNextProps {
  disabled: boolean;
}

const SwiperNext = styled('div')<SwiperNextProps>`
  background-color: #00000077;
  display: block;
  width: 2rem;
  height: 4rem;
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  position: absolute;
  top: 35rem;
  right: 0;
  transform: translate(0, -50%);
  z-index: 1;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;

  & > span {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    color: ${({ disabled }) => (disabled ? '#ffffff33' : '#ffffff')};
    /* transition: font-size 0.3s ease-in-out; */
  }

  &:hover {
    /* width: 2.5rem; */
    height: 5rem;

    /* & > span {
      font-size: 1.8rem;
    } */
  }

  @media (max-width: 768px) {
    top: 33rem;
  }

  @media (max-width: 700px) {
    top: 30rem;
  }
`;

export default {
  SwiperPrev,
  SwiperNext,
};
