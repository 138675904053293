import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useDiscordAuthentication } from 'src/hooks/useDiscordAuthentication';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { useQueueItStatus } from 'src/hooks/useQueueItStatus';
import { formatPrice } from 'src/tools/formatPrice';
import { getServerGameTime } from 'src/tools/getGameTime';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { getDropEventsMap } from 'src/redux/slices/drop-events/drop-events';
import { getPackTierFromType } from 'src/tools/getPackTierFromType';
import { getPackTierColors } from 'src/tools/getPackTierColors';
import { getButtonTextFromState } from 'src/components/pages/event-pack/utils/get-button-text-from-state';
import ListingSelector from 'src/components/pages/event-pack/components/ListingSelector';
import DropEventStatusBar from 'src/components/data-display/DropEventStatusBar';
import CheckboxDiamond from 'src/components/input/CheckboxDiamond';
import Alert from 'src/components/feedback/Alert';
import ErrorMessage from 'src/components/feedback/ErrorMessage';
import ButtonHex from 'src/components/input/ButtonHex';
import BaseIcon from 'src/components/icons/BaseIcon';
import ForteWhiteHeaderIcon from 'src/components/icons/ForteWhiteHeaderIcon';
import { Tooltip } from '@mui/material';
import { config } from 'src/config/config';
import Styled from 'src/components/pages/event-pack/components/SectionHero.styles';

interface Asset {
  title: string;
  width: string;
  height: string;
  path: string;
}

type Listing = 'starter-pack' | 'alpha-pack' | 'bronze-pack' | 'silver-pack' | 'gold-pack';

interface SectionHeroProps {
  listing: Listing;
  marketId: string;
  processing: boolean;
  polling: boolean;
  error: string;
  handlePurchase: (marketId: string) => void;
  fromGameOverlay?: boolean;
}

const SectionHero: FC<SectionHeroProps> = ({
  listing,
  marketId,
  processing,
  polling,
  error,
  handlePurchase,
  fromGameOverlay = false,
}) => {
  const { eventId = '' } = useParams();
  const { processing: authProcessing, initiateAuthentication } = useAuthentication({ fromContext: 'event' });
  const { linkDiscord, processingLink } = useDiscordAuthentication({ fromContext: 'event_discord_link' });
  const [inView, setInView] = useState(false);
  const authenticated = useAppSelector(state => state.authenticated.authenticated);
  const currentProfile = useAppSelector(state => state.currentProfile.currentProfile);
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, marketId));
  const dropEvents = useAppSelector(state => getDropEventsMap(state));
  const { t: tEvent } = useTranslation(nameSpace.event);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();
  const navigateExternal = useAppNavigateExternal();
  const { queueComplete, queueItCookieExpired } = useQueueItStatus();
  const dropEvent = dropEvents[eventId];
  const packTier = getPackTierFromType(listing);
  const tierColors = getPackTierColors(packTier);
  const bkg: Asset = tEvent(`event.hero.${packTier}.assets.bg`, { returnObjects: true });
  const pack: Asset = tEvent(`event.hero.${packTier}.assets.pack`, { returnObjects: true });
  const packSoldOut: Asset = tEvent(`event.hero.${packTier}.assets.pack.sold.out`, { returnObjects: true });

  // Variables to determine event state
  const {
    type: lpmType,
    startTimestampMs: allowlistStartDate,
    closeTimestampMs: allowlistEndDate,
    publicStartTimestampMs: publicStartDate,
    publicEndTimestampMs: publicEndDate,
    recapEndTimestampMs: recapEndDate,
  } = listPriceMarket;
  const now = getServerGameTime();

  // Event Status Variables
  const allowlistSaleExists = allowlistStartDate && allowlistEndDate && allowlistStartDate < allowlistEndDate;
  const publicSaleExists = publicStartDate && publicEndDate && publicStartDate < publicEndDate;
  const allowlistStarted = allowlistSaleExists ? now >= allowlistStartDate : false;
  const allowlistEnded = allowlistSaleExists ? now >= allowlistEndDate : false;
  const allowlistActive = allowlistSaleExists && allowlistStarted && !allowlistEnded;
  const publicStarted = publicSaleExists ? now >= publicStartDate : false;
  const publicEnded = publicSaleExists ? now >= publicEndDate : false;
  const publicActive = publicSaleExists && publicStarted && !publicEnded;
  const active = allowlistActive || publicActive;
  const closed =
    (publicSaleExists && now >= publicEndDate) || (!publicSaleExists && allowlistSaleExists && now >= allowlistEndDate);
  // const publicImmediatelyFollowsAllowlist =
  //   allowlistSaleExists && publicSaleExists && publicStartDate === allowlistEndDate;
  const upcomingAllowlistEvent = allowlistSaleExists && !allowlistStarted;
  // const upcomingPublicEvent =
  //   publicSaleExists && ((allowlistSaleExists && allowlistEnded) || !allowlistSaleExists) && !publicStarted;

  // Variables to determine market state and user purchase state
  const {
    allowedViaAllowListIds,
    allowedViaAllowLists,
    price,
    strikeThroughPrice,
    availableCount,
    purchaseCount,
    purchaseLimit,
    purchaseType,
    priceCurrencyBalance,
    queueProtected,
  } = listPriceMarket;
  const strikethroughPriceUSD = formatPrice(strikeThroughPrice * config.app_variables.lc_conversion, {
    decimals: 2,
  });
  const priceUSD = price >= 0 ? formatPrice(price * config.app_variables.lc_conversion, { decimals: 2 }) : 0;
  // const priceCurrency = price >= 0 ? formatPrice(price, { decimals: 0, hideSymbol: true }) : 0;
  const discordVerified = currentProfile.discordUser !== null;
  const hasAllowlist = (allowedViaAllowListIds && allowedViaAllowListIds.length > 0) ?? false;
  const lpmAllowlistPurchaseReqsMet =
    purchaseType === 'lpm' && allowlistActive && authenticated && discordVerified && hasAllowlist;
  const lpmPublicPurchaseReqsMet = purchaseType === 'lpm' && publicActive && authenticated;
  const offerPurchaseReqsMet = purchaseType === 'offer' && authenticated;
  const userMustQueue = queueProtected && (!queueComplete || (queueComplete && queueItCookieExpired));
  const purchaseLimitReached =
    authenticated && purchaseLimit !== null && purchaseLimit !== 0 && purchaseCount >= purchaseLimit;
  const sufficientBalance = purchaseType === 'offer' && price !== null ? priceCurrencyBalance >= price : true;
  const soldOut = availableCount === 0;
  const canPurchase =
    (lpmAllowlistPurchaseReqsMet || lpmPublicPurchaseReqsMet || offerPurchaseReqsMet) &&
    !userMustQueue &&
    !soldOut &&
    !purchaseLimitReached &&
    sufficientBalance;

  const handleOnPurchase = (marketId: string) => {
    if (userMustQueue) {
      // Fire Poole event for primary market purchase queue enter
      PooleEvents.PrimaryPurchaseQueueEnter({
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'event_details',
        dropEventId: dropEvent?.id || '',
        dropEventType: dropEvent?.type || '',
        dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
        lpmId: listPriceMarket?.id || '',
        lpmType: listPriceMarket?.type || '',
        lpmAllowlistIds: listPriceMarket?.allowedViaAllowListIds || [],
        lpmPriceLC: listPriceMarket?.price.toString() || '',
        lpmPriceUSD: ((listPriceMarket?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
        lpmPurchaseCount: listPriceMarket?.purchaseCount.toString() || '',
        lpmPurchaseLimit: listPriceMarket?.purchaseLimit.toString() || '',
        lpmTotalCount: listPriceMarket?.totalCount.toString() || '',
        lpmCurrencyName: listPriceMarket?.priceCurrencyName || 'USD',
        lpmCurrencyBalance: listPriceMarket?.priceCurrencyBalance?.toString() || '',
      });
    }

    handlePurchase(marketId);
  };

  return (
    <Styled.Root
      style={{
        backgroundImage: `url(/assets/${bkg.path})`,
      }}
    >
      <DropEventStatusBar
        allowlistStartDate={allowlistStartDate}
        allowlistEndDate={allowlistEndDate}
        publicStartDate={publicStartDate}
        publicEndDate={publicEndDate}
        recapEndDate={recapEndDate}
        soldOut={soldOut}
      />

      <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
        <div>
          <Styled.CenteredContentContainer soldOut={soldOut} fromGameOverlay={fromGameOverlay}>
            <Styled.MediaContainer className={`inview animated ${inView ? 'fadeInUp' : ''}`} soldOut={soldOut}>
              <Styled.ListingImage
                src={`/assets/${soldOut ? packSoldOut.path : pack.path}`}
                width={soldOut ? packSoldOut.width : pack.width}
                height={soldOut ? packSoldOut.height : pack.height}
                loading="lazy"
                alt={soldOut ? packSoldOut.title : pack.title}
              />
            </Styled.MediaContainer>

            <Styled.DetailsContainer className={`inview animated delay-2 ${inView ? 'fadeIn' : ''}`} soldOut={soldOut}>
              <Styled.Title
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tEvent(`event.hero.${packTier}.title`)) }}
                color={tierColors.main}
                soldOut={soldOut}
              />

              {active && queueProtected && authenticated && !soldOut && !purchaseLimitReached && (
                <>
                  {(!queueComplete || (queueComplete && queueItCookieExpired)) && (
                    <div className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
                      <Alert
                        severity="info"
                        variant="filled"
                        msg={tEvent('event.queue.required')}
                        action={tEvent('event.hero.button.join.queue')}
                        onAction={() => handleOnPurchase(marketId)}
                        style={{ margin: '1.5rem auto 0.5rem auto' }}
                      />
                    </div>
                  )}

                  {queueComplete && !queueItCookieExpired && (
                    <div className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
                      <Alert
                        severity="success"
                        variant="filled"
                        msg={tEvent('event.queue.success')}
                        style={{ margin: '1.5rem auto 0.5rem auto' }}
                      />
                    </div>
                  )}
                </>
              )}

              <ErrorMessage msg={error} style={{ marginTop: '1.2rem' }} />

              <Styled.SecondaryPriceContainer>
                <Styled.SecondaryPrice>
                  <span>{strikethroughPriceUSD}</span>
                  <span>USD</span>
                </Styled.SecondaryPrice>

                <Styled.Strikethrough />
              </Styled.SecondaryPriceContainer>

              <Styled.PrimaryPriceContainer>
                <Styled.PrimaryPrice>
                  <span>{priceUSD}</span>
                  <span>USD</span>
                </Styled.PrimaryPrice>

                {(soldOut || !closed) && (
                  <Styled.QuantityContainer>
                    <span>{availableCount}</span> {tCommon('common.term.available')}
                  </Styled.QuantityContainer>
                )}
              </Styled.PrimaryPriceContainer>

              <Styled.ButtonContainer>
                <ButtonHex
                  hoverEffect
                  variant={tierColors.buttonVariant}
                  width={175}
                  height={50}
                  cornerOffset={14}
                  fontSize={12}
                  title={getButtonTextFromState({
                    t: tEvent,
                    lpmType,
                    soldOut,
                    purchaseLimitReached,
                    purchaseCount,
                    sufficientBalance,
                  })}
                  disabled={processing || polling || !canPurchase || !active}
                  processing={processing || polling}
                  onClick={() => handleOnPurchase(marketId)}
                />
              </Styled.ButtonContainer>

              <Styled.RequirementsContainer>
                <Styled.RequirementsLabel>{`${tEvent('event.queue.mint.requirements')}:`}</Styled.RequirementsLabel>

                <Styled.Requirement>
                  <CheckboxDiamond
                    defaultValue={authenticated}
                    clickable={false}
                    size={12}
                    style={{ display: 'block', float: 'left', margin: '4px 16px 0 2px' }}
                  />
                  <Styled.RequirementText>{tCommon('common.drop.event.requirements.req1')}</Styled.RequirementText>

                  {!authenticated && (
                    <Styled.RequirementHelp disabled={authProcessing} onClick={initiateAuthentication}>
                      {tCommon('common.auth.register.or.sign.in')}{' '}
                      <span className="material-icons-outlined">open_in_new</span>
                    </Styled.RequirementHelp>
                  )}
                </Styled.Requirement>

                {(upcomingAllowlistEvent || allowlistActive) && (
                  <>
                    <Styled.Requirement>
                      <CheckboxDiamond
                        defaultValue={authenticated && currentProfile.discordUser !== null}
                        clickable={false}
                        size={12}
                        style={{ display: 'block', float: 'left', margin: '4px 16px 0 2px' }}
                      />
                      <Styled.RequirementText>
                        {tCommon('common.drop.event.requirements.req2')}
                        {!currentProfile.discordUser && (
                          <Tooltip
                            arrow
                            title={
                              <Styled.TooltipContainer>
                                <Styled.TooltipHeading>
                                  {tEvent('event.tooltip.discord.notfound.title')}
                                </Styled.TooltipHeading>
                                <Styled.TooltipNotice>
                                  {tEvent('event.tooltip.discord.notfound.notice')}
                                </Styled.TooltipNotice>
                              </Styled.TooltipContainer>
                            }
                            placement="top-start"
                            componentsProps={{
                              arrow: {
                                sx: {
                                  color: '#222126',
                                },
                              },
                              tooltip: {
                                sx: {
                                  backgroundColor: '#222126',
                                  padding: '1rem',
                                  fontSize: '0.8rem',
                                },
                              },
                            }}
                          >
                            <span className="material-icons-outlined" style={{ cursor: 'default' }}>
                              info
                            </span>
                          </Tooltip>
                        )}
                      </Styled.RequirementText>

                      {authenticated && !currentProfile.discordUser && (
                        <Styled.RequirementHelp disabled={processingLink} onClick={linkDiscord}>
                          {tCommon('common.discord.login')} <span className="material-icons-outlined">open_in_new</span>
                        </Styled.RequirementHelp>
                      )}
                    </Styled.Requirement>

                    <Styled.Requirement>
                      <CheckboxDiamond
                        defaultValue={authenticated && hasAllowlist}
                        clickable={false}
                        size={12}
                        style={{ display: 'block', float: 'left', margin: '4px 16px 0 2px' }}
                      />
                      <Styled.RequirementText>
                        {tCommon('common.drop.event.requirements.req3')}
                        <Tooltip
                          arrow
                          title={
                            <Styled.TooltipContainer>
                              {allowedViaAllowLists && allowedViaAllowLists.length > 0 ? (
                                <>
                                  <Styled.TooltipHeading>
                                    {tEvent('event.tooltip.allowlist.found.title')}
                                  </Styled.TooltipHeading>

                                  <Styled.TooltipRows>
                                    {allowedViaAllowLists.map((allowList, i) => {
                                      return (
                                        <Styled.TooltipRow key={`tooltiprow-${i}`}>{allowList.name}</Styled.TooltipRow>
                                      );
                                    })}
                                  </Styled.TooltipRows>
                                </>
                              ) : (
                                <>
                                  <Styled.TooltipHeading>
                                    {tEvent('event.tooltip.allowlist.notfound.title')}
                                  </Styled.TooltipHeading>
                                  <Styled.TooltipNotice>
                                    {tEvent('event.tooltip.allowlist.notfound.notice')}
                                  </Styled.TooltipNotice>
                                </>
                              )}
                            </Styled.TooltipContainer>
                          }
                          placement="top-start"
                          componentsProps={{
                            arrow: {
                              sx: {
                                color: '#222126',
                              },
                            },
                            tooltip: {
                              sx: {
                                backgroundColor: '#222126',
                                padding: '1rem',
                                fontSize: '0.8rem',
                              },
                            },
                          }}
                        >
                          <span className="material-icons-outlined info-icon" style={{ cursor: 'default' }}>
                            info
                          </span>
                        </Tooltip>
                      </Styled.RequirementText>
                    </Styled.Requirement>
                  </>
                )}
              </Styled.RequirementsContainer>

              <ListingSelector listing={listing} />

              <Styled.Help>
                <span>{tCommon('common.need.help')}</span>
                <span onClick={() => navigateInternal('/home#faqs', { pooleData: { fromContext: 'event_details' } })}>
                  {tCommon('common.visit.faqs')}
                </span>
              </Styled.Help>

              <Styled.PoweredBy>
                <span>{tCommon('common.powered.by')}</span>
                <BaseIcon
                  hoverColor="#FEC300"
                  cursor="pointer"
                  icon={<ForteWhiteHeaderIcon />}
                  padding="0"
                  height={17}
                  style={{ display: 'inline-block', verticalAlign: 'top', marginTop: '1px' }}
                  onClick={() => {
                    navigateExternal(tCommon('common.forte.link'), {
                      newWindow: true,
                      pooleData: { pageName: 'Forte', fromContext: 'event_details' },
                    });
                  }}
                />
              </Styled.PoweredBy>
            </Styled.DetailsContainer>
          </Styled.CenteredContentContainer>
        </div>
      </Waypoint>
    </Styled.Root>
  );
};

export default SectionHero;
