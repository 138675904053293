import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useLegalNoticeContext from 'src/hooks/useLegalNoticeContext';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { Alert, Slide, Button } from '@mui/material';
import Styled from 'src/components/banners/LegalNoticeBanner.styles';
import { config } from 'src/config/config';

type LegalNoticeBannerVariant = 'terms' | 'privacy' | 'both';

const getBannerText = (legalNoticeBannerVariant: LegalNoticeBannerVariant) => {
  const bannerStatus = config.legal.change_notice_status;

  switch (legalNoticeBannerVariant) {
    case 'both':
      return `legal.notice.banner.${bannerStatus}.both.primary`;
    case 'terms':
      return `legal.notice.banner.${bannerStatus}.terms.primary`;
    case 'privacy':
      return `legal.notice.banner.${bannerStatus}.privacy.primary`;
    default:
      return `legal.notice.banner.${bannerStatus}.both.primary`;
  }
};

const LegalNoticeBanner: FC = () => {
  const { showLegalNoticeBanner, legalNoticeBannerVariant, setSubmitLegalNoticeView } = useLegalNoticeContext();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tLegal } = useTranslation(nameSpace.legal);
  const navigateExternal = useAppNavigateExternal();
  const bannerURL = config.legal.change_notice_url;
  const bannerStatus = config.legal.change_notice_status;
  const bannerText = getBannerText(legalNoticeBannerVariant);

  return (
    <Slide direction="down" in={showLegalNoticeBanner} mountOnEnter unmountOnExit>
      <Styled.Root>
        <Alert
          severity={'info'}
          variant={'outlined'}
          elevation={6}
          action={
            <>
              <Styled.ActionsContainer>
                {bannerStatus === 'changed' ? (
                  <>
                    {(legalNoticeBannerVariant === 'both' || legalNoticeBannerVariant === 'terms') && (
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          navigateExternal(tCommon('common.nav.terms.link'), {
                            newWindow: true,
                            pooleData: { pageName: 'N3twork Legal Change Summary', fromContext: 'legal_notice_banner' },
                          });
                        }}
                        sx={{
                          display: 'block',
                          padding: '0.2rem 1.2rem',
                          borderRadius: '0.2rem',
                          textTransform: 'uppercase',
                          fontFamily: 'Barlow',
                          fontWeight: '400',
                          fontSize: '0.8rem',
                          '&:hover': {
                            backgroundColor: '#52A9FF',
                            color: 'white',
                            borderColor: '#52A9FF',
                          },
                        }}
                      >
                        {tCommon('common.nav.terms.short')}
                      </Button>
                    )}

                    {(legalNoticeBannerVariant === 'both' || legalNoticeBannerVariant === 'privacy') && (
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          navigateExternal(tCommon('common.nav.privacy.link'), {
                            newWindow: true,
                            pooleData: { pageName: 'N3twork Legal Change Summary', fromContext: 'legal_notice_banner' },
                          });
                        }}
                        sx={{
                          display: 'block',
                          marginLeft: '0.5rem',
                          padding: '0.2rem 1.2rem',
                          borderRadius: '0.2rem',
                          textTransform: 'uppercase',
                          fontFamily: 'Barlow',
                          fontWeight: '400',
                          fontSize: '0.8rem',
                          '&:hover': {
                            backgroundColor: '#52A9FF',
                            color: 'white',
                            borderColor: '#52A9FF',
                          },
                        }}
                      >
                        {tCommon('common.nav.privacy.short')}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      navigateExternal(bannerURL, {
                        newWindow: true,
                        pooleData: { pageName: 'N3twork Legal Change Summary', fromContext: 'legal_notice_banner' },
                      });
                    }}
                    sx={{
                      display: 'block',
                      padding: '0.2rem 1.2rem',
                      borderRadius: '0.2rem',
                      textTransform: 'uppercase',
                      fontFamily: 'Barlow',
                      fontWeight: '400',
                      fontSize: '0.8rem',
                      '&:hover': {
                        backgroundColor: '#52A9FF',
                        color: 'white',
                        borderColor: '#52A9FF',
                      },
                      //   transform: 'translateY(-50%)',
                    }}
                  >
                    {tCommon('common.term.review')}
                  </Button>
                )}
              </Styled.ActionsContainer>

              <Styled.CloseButton onClick={() => setSubmitLegalNoticeView(true)}>
                <span className="material-icons">close</span>
              </Styled.CloseButton>
            </>
          }
          sx={{
            width: '100%',
            borderRadius: '0',
            border: 'none',
            borderBottom: '1px solid #52A9FF',
            padding: '.9rem 3.3rem .9rem 1rem',
            position: 'relative',
          }}
        >
          <Styled.BannerTextContainer>
            <Styled.BannerText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tLegal(bannerText)) }} />

            {/* <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => {
                navigateExternal(bannerURL, {
                  newWindow: true,
                  pooleData: { pageName: 'N3twork Legal Change Summary', fromContext: 'legal_notice_banner' },
                });
              }}
              sx={{
                display: 'block',
                marginTop: '0.5rem',
                padding: '0.2rem 1.2rem',
                borderRadius: '0.2rem',
                textTransform: 'uppercase',
                fontFamily: 'Barlow',
                fontWeight: '400',
                fontSize: '0.8rem',
              }}
            >
              {tCommon('common.term.review')}
            </Button> */}

            <div>
              {bannerStatus === 'changed' ? (
                <>
                  {(legalNoticeBannerVariant === 'both' || legalNoticeBannerVariant === 'terms') && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        navigateExternal(tCommon('common.nav.terms.link'), {
                          newWindow: true,
                          pooleData: { pageName: 'N3twork Legal Change Summary', fromContext: 'legal_notice_banner' },
                        });
                      }}
                      sx={{
                        display: 'inline-block',
                        padding: '0.2rem 1.2rem',
                        margin: '0.5rem 0.5rem 0 0',
                        verticalAlign: 'middle',
                        borderRadius: '0.2rem',
                        textTransform: 'uppercase',
                        fontFamily: 'Barlow',
                        fontWeight: '400',
                        fontSize: '0.8rem',
                        '&:hover': {
                          backgroundColor: '#52A9FF',
                          color: 'white',
                          borderColor: '#52A9FF',
                        },
                      }}
                    >
                      {tCommon('common.nav.terms.short')}
                    </Button>
                  )}

                  {(legalNoticeBannerVariant === 'both' || legalNoticeBannerVariant === 'privacy') && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        navigateExternal(tCommon('common.nav.privacy.link'), {
                          newWindow: true,
                          pooleData: { pageName: 'N3twork Legal Change Summary', fromContext: 'legal_notice_banner' },
                        });
                      }}
                      sx={{
                        display: 'inline-block',
                        padding: '0.2rem 1.2rem',
                        margin: '0.5rem 0 0 0',
                        verticalAlign: 'middle',
                        borderRadius: '0.2rem',
                        textTransform: 'uppercase',
                        fontFamily: 'Barlow',
                        fontWeight: '400',
                        fontSize: '0.8rem',
                        '&:hover': {
                          backgroundColor: '#52A9FF',
                          color: 'white',
                          borderColor: '#52A9FF',
                        },
                      }}
                    >
                      {tCommon('common.nav.privacy.short')}
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    navigateExternal(bannerURL, {
                      newWindow: true,
                      pooleData: { pageName: 'N3twork Legal Change Summary', fromContext: 'legal_notice_banner' },
                    });
                  }}
                  sx={{
                    display: 'inline-block',
                    marginTop: '0.5rem',
                    padding: '0.2rem 1.2rem',
                    verticalAlign: 'middle',
                    borderRadius: '0.2rem',
                    textTransform: 'uppercase',
                    fontFamily: 'Barlow',
                    fontWeight: '400',
                    fontSize: '0.8rem',
                    '&:hover': {
                      backgroundColor: '#52A9FF',
                      color: 'white',
                      borderColor: '#52A9FF',
                    },
                  }}
                >
                  {tCommon('common.term.review')}
                </Button>
              )}
            </div>
          </Styled.BannerTextContainer>
        </Alert>
      </Styled.Root>
    </Slide>
  );
};

export default LegalNoticeBanner;
