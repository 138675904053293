import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const PlayIcon: FC = () => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="63px" height="72px" viewBox="0 0 63 72">
    <path
      fillRule="evenodd"
      stroke="rgb(220, 184, 89)"
      strokeWidth="4px"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      fillOpacity="0"
      fill="rgb(220, 184, 89)"
      d="M54.924,35.250 L4.591,64.300 L4.591,6.200 L54.924,35.250 Z"
    />
  </SvgIcon>
);

export default PlayIcon;
