import { FC, memo, useRef } from 'react';
import PrimaryMarketplace from 'src/components/primary-marketplace/PrimaryMarketplace';
import Styled from 'src/components/game-overlay/pages/PagePrimaryMarketplace.styles';

type DropEventTag = 'pack' | 'daily';

const PageRewards: FC = () => {
  const dropEventTags = useRef<DropEventTag[]>(['daily']);

  return (
    <Styled.Root>
      <PrimaryMarketplace dropEventTags={dropEventTags.current} />
    </Styled.Root>
  );
};

export default memo(PageRewards);
