import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useCountdown } from 'src/hooks/useCountdown';
import Styled from 'src/components/data-display/DropEventStatusBar.styles';
import { getServerGameTime } from 'src/tools/getGameTime';

const getCountdownDate = (
  now: number,
  allowlistStartDate: number,
  allowlistEndDate: number,
  publicStartDate: number,
  publicEndDate: number,
  recapEndDate: number,
): number | null => {
  const allowlistSaleExists = allowlistStartDate && allowlistEndDate && allowlistStartDate < allowlistEndDate;
  const publicSaleExists = publicStartDate && publicEndDate && publicStartDate < publicEndDate;
  const recapExists = !!recapEndDate;

  if (allowlistSaleExists && now < allowlistStartDate) return allowlistStartDate;
  if (allowlistSaleExists && now >= allowlistStartDate && now < allowlistEndDate) return allowlistEndDate;
  if (publicSaleExists && now >= allowlistEndDate && now < publicStartDate) return publicStartDate;
  if (publicSaleExists && now >= publicStartDate && now < publicEndDate) return publicEndDate;
  if (
    recapExists &&
    ((allowlistSaleExists && !publicSaleExists && now >= allowlistEndDate) ||
      (publicSaleExists && now >= publicEndDate)) &&
    now < recapEndDate
  )
    return recapEndDate;

  return null;
};

interface GetCountdownTextProps {
  now: number;
  allowlistStartDate: number;
  allowlistEndDate: number;
  publicStartDate: number;
  publicEndDate: number;
  soldOut: boolean;
}

interface CountdownText {
  primaryText: string;
  secondaryText: string;
  tertiaryText: string;
}

const getCountdownText = ({
  now,
  allowlistStartDate,
  allowlistEndDate,
  publicStartDate,
  publicEndDate,
  soldOut,
}: GetCountdownTextProps): CountdownText => {
  // Event status variables
  const allowlistSaleExists = allowlistStartDate && allowlistEndDate && allowlistStartDate < allowlistEndDate;
  const publicSaleExists = publicStartDate && publicEndDate && publicStartDate < publicEndDate;
  const allowlistStarted = allowlistSaleExists ? now >= allowlistStartDate : false;
  const allowlistEnded = allowlistSaleExists ? now >= allowlistEndDate : false;
  const allowlistActive = allowlistSaleExists && allowlistStarted && !allowlistEnded;
  const publicStarted = publicSaleExists ? now >= publicStartDate : false;
  const publicEnded = publicSaleExists ? now >= publicEndDate : false;
  const publicActive = publicSaleExists && publicStarted && !publicEnded;
  const active = allowlistActive || publicActive;
  const closed =
    (publicSaleExists && now >= publicEndDate) || (!publicSaleExists && allowlistSaleExists && now >= allowlistEndDate);
  const publicImmediatelyFollowsAllowlist =
    allowlistSaleExists && publicSaleExists && publicStartDate === allowlistEndDate;
  const upcomingAllowlistEvent = allowlistSaleExists && !allowlistStarted;
  const upcomingPublicEvent =
    publicSaleExists && ((allowlistSaleExists && allowlistEnded) || !allowlistSaleExists) && !publicStarted;

  if (soldOut) {
    return {
      primaryText: 'common.term.event.ended',
      secondaryText: 'common.term.all.sold.out',
      tertiaryText: '',
    };
  }

  if (closed) {
    return {
      primaryText: '',
      secondaryText: 'common.term.this.event.has.ended',
      tertiaryText: '',
    };
  }

  if (upcomingAllowlistEvent || upcomingPublicEvent) {
    return {
      primaryText: 'common.term.upcoming.event',
      secondaryText: 'common.term.begins.in',
      tertiaryText: upcomingAllowlistEvent ? 'common.term.allowlist.only.period' : 'common.term.open.to.public',
    };
  }

  if (active) {
    if (allowlistActive && (!publicSaleExists || !publicImmediatelyFollowsAllowlist)) {
      return {
        primaryText: 'common.term.event.active',
        secondaryText: 'common.term.ends.in',
        tertiaryText: 'common.term.allowlist.only.period',
      };
    }

    if (allowlistActive && publicSaleExists && publicImmediatelyFollowsAllowlist) {
      return {
        primaryText: 'common.term.event.active',
        secondaryText: 'common.term.public.sale.starts',
        tertiaryText: 'common.term.allowlist.only.period',
      };
    }

    if (publicSaleExists && publicActive) {
      return {
        primaryText: 'common.term.event.active',
        secondaryText: 'common.term.ends.in',
        tertiaryText: 'common.term.open.to.public',
      };
    }
  }

  return {
    primaryText: '',
    secondaryText: '',
    tertiaryText: '',
  };
};

interface DropEventStatusBarProps {
  allowlistStartDate: number;
  allowlistEndDate: number;
  publicStartDate: number;
  publicEndDate: number;
  recapEndDate: number;
  soldOut: boolean;
  style?: React.CSSProperties;
}

const DropEventStatusBar: FC<DropEventStatusBarProps> = ({
  allowlistStartDate,
  allowlistEndDate,
  publicStartDate,
  publicEndDate,
  recapEndDate,
  soldOut,
  style = {},
}) => {
  const now = getServerGameTime();
  const countdownDate =
    getCountdownDate(now, allowlistStartDate, allowlistEndDate, publicStartDate, publicEndDate, recapEndDate) || 0;
  const { daysDD, hoursDD, minsDD, secsDD } = useCountdown(countdownDate);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const allowlistSaleExists = allowlistStartDate && allowlistEndDate && allowlistStartDate < allowlistEndDate;
  const publicSaleExists = publicStartDate && publicEndDate && publicStartDate < publicEndDate;
  const closed =
    (publicSaleExists && now >= publicEndDate) || (!publicSaleExists && allowlistSaleExists && now >= allowlistEndDate);
  const countdownText = getCountdownText({
    now,
    allowlistStartDate,
    allowlistEndDate,
    publicStartDate,
    publicEndDate,
    soldOut,
  });

  return (
    <Styled.Root style={style}>
      <Styled.TextContainer>
        {countdownText.primaryText && (
          <Styled.StatusPrimaryText>
            {tCommon(countdownText.primaryText)}!
            {countdownText.tertiaryText && (
              <Styled.StatusTertiaryText>{tCommon(countdownText.tertiaryText)}</Styled.StatusTertiaryText>
            )}
          </Styled.StatusPrimaryText>
        )}

        {countdownText.secondaryText && (
          <Styled.StatusSecondaryText>{tCommon(countdownText.secondaryText)}</Styled.StatusSecondaryText>
        )}
      </Styled.TextContainer>

      {!soldOut && !closed && (
        <Styled.CountdownContainer>
          <Styled.CountdownSegment>
            <Styled.CountdownSegmentBottom />
            <Styled.CountdownSegmentData>{daysDD}</Styled.CountdownSegmentData>
            <Styled.CountdownSegmentLabel>{tCommon('common.days')}</Styled.CountdownSegmentLabel>
          </Styled.CountdownSegment>

          <Styled.CountdownSegment>
            <Styled.CountdownSegmentBottom />
            <Styled.CountdownSegmentData>{hoursDD}</Styled.CountdownSegmentData>
            <Styled.CountdownSegmentLabel>{tCommon('common.hours')}</Styled.CountdownSegmentLabel>
          </Styled.CountdownSegment>

          <Styled.CountdownSegment>
            <Styled.CountdownSegmentBottom />
            <Styled.CountdownSegmentData>{minsDD}</Styled.CountdownSegmentData>
            <Styled.CountdownSegmentLabel>{tCommon('common.mins')}</Styled.CountdownSegmentLabel>
          </Styled.CountdownSegment>

          <Styled.CountdownSegment>
            <Styled.CountdownSegmentBottom />
            <Styled.CountdownSegmentData>{secsDD}</Styled.CountdownSegmentData>
            <Styled.CountdownSegmentLabel>{tCommon('common.secs')}</Styled.CountdownSegmentLabel>
          </Styled.CountdownSegment>
        </Styled.CountdownContainer>
      )}
    </Styled.Root>
  );
};

export default DropEventStatusBar;
