import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import LogoSrc from 'src/assets/logo/lhu_logo.webp';
import ParticlesOverlay from 'src/components/vfx/ParticlesOverlay';
import Styled from 'src/components/pages/marketplace/components/SectionHero.styles';

type Hero = {
  path: string;
};

const SectionHero: FC = () => {
  const { t } = useTranslation(nameSpace.marketplace);
  const hero: Hero = t('marketplace.hero.asset', { returnObjects: true });

  return (
    <Styled.Root style={{ backgroundImage: `url(/assets/${hero.path})` }}>
      <ParticlesOverlay id={'marketplace-red'} color={'red'} number={100} sizes={{ min: 1, max: 5 }} />
      <ParticlesOverlay id={'marketplace-purple'} color={'purple'} number={100} sizes={{ min: 1, max: 3 }} />

      <Styled.CenteredContainer>
        <Styled.LogoOuterContainer>
          <Styled.LogoInnerContainer>
            <Styled.Logo src={LogoSrc} width="100" height="100" loading="lazy" alt={t('marketplace.logo.alt')} />
          </Styled.LogoInnerContainer>
        </Styled.LogoOuterContainer>
      </Styled.CenteredContainer>
    </Styled.Root>
  );
};

export default SectionHero;
