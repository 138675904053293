import { styled } from '@mui/material';

const Button = styled('button')<{
  width: number;
  height: number;
  color: string;
  fontSize: number;
  fontWeight: number;
  gradientID: string;
  disabled?: boolean;
  hoverEffect?: boolean;
}>`
  display: block;
  background-color: transparent;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  margin: 0;
  padding: 0;
  border: none;
  position: relative;
  cursor: ${({ disabled, hoverEffect }) => (hoverEffect && !disabled ? 'pointer' : 'inherit')};
  filter: ${({ disabled }) => (disabled ? 'brightness(.8)' : 'none')};

  & > svg:nth-of-type(1) {
    display: block;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
      transition: all 0.4s ease-in;
    }

    ${({ disabled, hoverEffect, gradientID }) =>
      hoverEffect && !disabled ? `&:hover { #hex-filled { fill: url(#${gradientID}-hover); } }` : ''};
  }

  & > svg:nth-of-type(2) {
    display: block;
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    path {
      transition: all 0.4s ease-in;
    }
  }

  & > div {
    display: block;
    width: 85%;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${({ fontSize }) => `${fontSize}px`};
    font-weight: ${({ fontWeight }) => fontWeight};
    pointer-events: none;
    color: ${({ disabled, color }) => (disabled ? '#00000044' : color)};
  }
`;

export default { Button };
