import { styled } from '@mui/material';

const Root = styled('div')`
  display: none;
  width: 100%;
  margin-top: 2rem;
  padding: 0 2rem;
  box-sizing: border-box;
  text-align: center;

  @media (max-width: 500px) {
    display: block;
    padding: 0 1.5rem;
  }
`;

const Separator = styled('div')`
  background-color: #ffffff55;
  display: inline-block;
  width: calc(50% - 6rem);
  height: 2px;
  vertical-align: middle;
`;

const Title = styled('span')`
  display: inline-block;
  width: 100%;
  max-width: 12rem;
  vertical-align: middle;
  word-break: break-word;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

export default {
  Root,
  Separator,
  Title,
};
