import { FC } from 'react';
import useDebugWidgetContext from 'src/hooks/useDebugWidgetContext';
import ToggleButton from 'src/components/debug-widget/ToggleButton';
import DebugMenu from 'src/components/debug-widget/DebugMenu';
import Styled from 'src/components/debug-widget/DebugWidget.styles';

const DebugWidget: FC = () => {
  const { enableDebugWidget } = useDebugWidgetContext();

  if (!enableDebugWidget) return null;

  return (
    <Styled.Root>
      <ToggleButton />
      <DebugMenu />
    </Styled.Root>
  );
};

export default DebugWidget;
