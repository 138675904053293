import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

export default {
  Root,
};
