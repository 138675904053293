import { FC } from 'react';
import { formatPrice } from 'src/tools/formatPrice';
import Styled from 'src/components/data-display/leaderboard/Score.styles';
// import { logMain } from 'src/modules/logger/logger';

const getStyles = (variant: Variant) => {
  switch (variant) {
    case 'condensed':
      return {
        fontSize: '1.2rem',
      };
    case 'default':
    default:
      return {
        fontSize: '1.8rem',
      };
  }
};

type Variant = 'condensed' | 'default';

interface ScoreProps {
  value: number;
  variant?: Variant;
  style?: React.CSSProperties;
}

const Score: FC<ScoreProps> = ({ value, variant = 'default', style = {} }) => {
  return (
    <Styled.Root variantStyles={getStyles(variant)} style={style}>
      {formatPrice(value, { hideSymbol: true, decimals: 0 })}
    </Styled.Root>
  );
};

export default Score;
