import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  box-sizing: border-box;

  & > span:nth-of-type(1) {
    display: inline-block;
    margin-right: 0.7rem;
    vertical-align: middle;
    word-break: break-word;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 400;
    color: #fff;
  }

  @media (max-width: 768px) {
    padding-right: 2.5rem;

    & > span:nth-of-type(1) {
      font-size: 1.1rem;
      line-height: 1.3rem;
    }
  }
`;

const EditButton = styled('div')`
  display: inline-block;
  padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  vertical-align: middle;
  border: 1px solid #ffffff77;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};

    & .material-icons {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }

    & > span:nth-of-type(2) {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin-bottom: 0.1rem;
    vertical-align: middle;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    color: #fff;
  }

  & > span:nth-of-type(2) {
    display: none;
  }

  @media (max-width: 500px) {
    padding: 0.1rem 0.3rem 0.1rem 0.3rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.8rem;
      line-height: 1rem;
    }
    /* display: block;
    width: 100%;
    max-width: 9rem;
    padding: 0.3rem 0.5rem 0.2rem 0.5rem;
    margin-top: 1rem;
    text-align: center;

    & > span:nth-of-type(1) {
      margin-right: 0.4rem;
    }

    & > span:nth-of-type(2) {
      display: inline-block;
    } */
  }
`;

const EditForm = styled('form')`
  display: block;
  width: 100%;
  max-width: 20rem;
  font-size: 0;
`;

const Input = styled('input')`
  background-color: #00000033;
  display: inline-block;
  margin-right: 0.5rem;
  width: calc(100% - 0.5rem - 2.3rem - 0.5rem - 2.3rem);
  padding: 0.5rem 0.7rem;
  vertical-align: middle;
  border: 1px solid #ffffffaa;
  border-radius: 0.2rem;
  outline: none;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #ffffff;
`;

const Buttons = styled('div')`
  display: inline-block;
  vertical-align: middle;

  & > button:nth-of-type(1) {
    margin-right: 0.5rem;
  }
`;

const Button = styled('button')`
  background: transparent;
  display: inline-block;
  width: 2.3rem;
  height: 2.3rem;
  border: ${({ disabled }) => `1px solid ${disabled ? '#ffffff55' : '#ffffffaa'}`};
  border-radius: 0.2rem;
  position: relative;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  & .material-icons {
    font-size: 1rem;
    color: ${({ disabled }) => (disabled ? '#ffffff55' : '#ffffff')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'transparent' : '#ffffff')};

    & .material-icons {
      color: ${({ theme, disabled }) => (disabled ? '#ffffff55' : theme.custom.colors.BgLayer1)};
    }
  }
`;

export default {
  Root,
  EditButton,
  EditForm,
  Input,
  Buttons,
  Button,
};
