/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useHomeCTAResize } from 'src/hooks/useHomeCTAResize';
import LogoSrc from 'src/assets/logo/lhu_logo_lg.webp';
import ButtonPrimary from 'src/components/input/ButtonPrimary';
import SectionSocialPromo from 'src/components/sections/SectionSocialPromo';
import { useMediaQuery } from '@mui/material';
import Styled from 'src/components/pages/home/components/SectionVideoHero.styles';

const getIFrameSrc = (t: any, bpMaxWidth768: boolean) => {
  if (bpMaxWidth768) return t('home.video.cta.2k');

  return t('home.video.cta.4k');
};

const SectionVideoHero: FC = () => {
  const { t: tHome } = useTranslation(nameSpace.home);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();
  const bpMaxWidth768 = useMediaQuery('(max-width: 768px)');
  // const bpMaxWidth1080 = useMediaQuery('(max-width: 1080px)');
  const iframeSrc = getIFrameSrc(tHome, bpMaxWidth768);

  useHomeCTAResize();

  const handlePlay = (path: string, gtmEvent?: () => void) => {
    navigateInternal(path, { pooleData: { fromContext: 'play_now_button' }, gtmEvent: gtmEvent || null });
  };

  return (
    <Styled.Root>
      <Styled.Hero className="home-hero-container">
        <Styled.BackgroundIFrame
          className="home-hero-iframe"
          src={iframeSrc}
          frameBorder="0"
          width="100%"
          height="100%"
          allow="autoplay; encrypted-media; picture-in-picture;"
          allowFullScreen
          title="Legendary: Heroes Unchained Gameplay"
        />

        <Styled.OverlayContainer>
          <Styled.Overlay className={'inview animated slow fadeIn'}>
            <Styled.Logo src={LogoSrc} width="406" height="213" loading="lazy" alt={tCommon('common.project.name')} />
            <Styled.Heading dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tHome('home.hero.content')) }} />

            <ButtonPrimary
              className="play-now-button"
              type="button"
              title={tCommon('common.play.now')}
              width={bpMaxWidth768 ? '200px' : '250px'}
              height={bpMaxWidth768 ? '75px' : '85px'}
              enablePulseAnimation
              fontSize={bpMaxWidth768 ? 18 : 24}
              textStyle={{ paddingTop: '1px' }}
              style={{ display: 'inline-block' }}
              onClick={() => handlePlay('/play')}
            />
          </Styled.Overlay>

          <Styled.ScrollIndicatorContainer className={'inview animated slow fadeIn delay-2'}>
            <Styled.ScrollIndicator
              src={'/assets/yellow-scroll-3.webp'}
              width={'64'}
              height={'64'}
              alt={'Scroll Indicator'}
              title={'Scroll Indicator'}
              onClick={() =>
                navigateInternal('/home#section-social-promo', {
                  pooleData: { fromContext: 'home_cta_scroll_indicator' },
                })
              }
            />
          </Styled.ScrollIndicatorContainer>
        </Styled.OverlayContainer>
      </Styled.Hero>

      <SectionSocialPromo />
    </Styled.Root>
  );
};

export default SectionVideoHero;
