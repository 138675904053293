import { styled } from '@mui/material';

const Root = styled('span')`
  display: inline-block;

  & > img {
    display: inline-block;
    margin-right: 0.4rem;
    vertical-align: middle;
    height: 2.5rem;
    width: auto;
  }

  & > span {
    display: inline-block;
    vertical-align: bottom;
    text-transform: uppercase;
    color: #ffffff;

    & > span {
      font-weight: 600;
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }

  & > span:nth-of-type(1) {
    margin-right: 0.3rem;
    font-size: 3rem;
    line-height: 2.7rem;
    font-weight: 600;
  }

  & > span:nth-of-type(2) {
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: 300;
  }

  @media (max-width: 1050px) {
    & > img {
      height: 2rem;
    }

    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
  }

  @media (max-width: 900px) {
    & > img {
      height: 1.3rem;
      margin-right: 0.3rem;
    }

    & > span:nth-of-type(1) {
      font-size: 1.4rem;
      line-height: 1.5rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 700px) {
    & > img {
      height: 1.3rem;
      margin-right: 0.3rem;
      margin-bottom: 0.1rem;
    }

    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 1.4rem;
      line-height: 1.5rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 500px) {
    & > img {
      height: 1.3rem;
      margin-right: 0.3rem;
      margin-bottom: 0.1rem;
    }

    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 1.4rem;
      line-height: 1.5rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }

  @media (max-height: 450px) {
    & > img {
      height: 1rem;
      margin-right: 0.2rem;
    }

    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 1.2rem;
      line-height: 1.3rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.6rem;
      line-height: 0.8rem;
    }
  }

  @media (max-height: 350px) {
    & > img {
      height: 1rem;
      margin-right: 0.2rem;
    }

    & > span:nth-of-type(1) {
      margin-right: 0.2rem;
      font-size: 1.1rem;
      line-height: 1.2rem;
    }

    & > span:nth-of-type(2) {
      font-size: 0.6rem;
      line-height: 0.8rem;
    }
  }
`;

export default {
  Root,
};
