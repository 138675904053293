/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { getPackTierFromType } from 'src/tools/getPackTierFromType';
import Timer from 'src/components/primary-marketplace/components/Timer';
import Styled from 'src/components/primary-marketplace/components/ListPriceMarketImage.styles';

const getAssetFromDropEventType = (t: any, lpmType: string, packTier: string): Asset => {
  if (lpmType === 'pack') return t(`event.pack.${packTier}.asset`, { returnObjects: true });
  if (lpmType === 'daily') return t('event.chest.daily.asset', { returnObjects: true });

  return t(`event.pack.${packTier}.asset`, { returnObjects: true });
};

interface Asset {
  title: string;
  width: string;
  height: string;
  path: string;
}

interface ListPriceMarketImageProps {
  dropEventId: string;
  lpmId: string;
  style?: React.CSSProperties;
}

const ListPriceMarketImage: FC<ListPriceMarketImageProps> = ({ dropEventId, lpmId, style = {} }) => {
  const [inView, setInView] = useState(false);
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const { t: tEvent } = useTranslation(nameSpace.event);

  if (!listPriceMarket) return null;

  const { type: lpmType, imageUrl } = listPriceMarket;
  const packTier = getPackTierFromType(lpmType);
  const asset: Asset = getAssetFromDropEventType(tEvent, lpmType, packTier);

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated ${inView ? 'fadeInUp' : ''}`} style={style}>
        <Styled.ListingImage
          src={imageUrl || ''}
          width={asset.width}
          height={asset.height}
          loading="lazy"
          alt={asset.title}
          isDaily={lpmType === 'daily'}
        />

        <Timer dropEventId={dropEventId} lpmId={lpmId} />
      </Styled.Root>
    </Waypoint>
  );
};

export default ListPriceMarketImage;
