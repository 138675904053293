import { FC } from 'react';
// import { logMain } from 'src/modules/logger/logger';
import Styled from 'src/components/data-display/UnreadIndicator.styles';

interface UnreadIndicatorProps {
  active: boolean;
  topOffset?: number;
  rightOffset?: number;
  style?: React.CSSProperties;
}

const UnreadIndicator: FC<UnreadIndicatorProps> = ({ active, topOffset = 0, rightOffset = 0, style = {} }) => {
  if (!active) return null;

  return (
    <Styled.Root topOffset={topOffset} rightOffset={rightOffset} style={style}>
      <Styled.Dot />

      <Styled.GrowingCircle className="grow-delay-0" />
      <Styled.GrowingCircle className="grow-delay-1" />
      <Styled.GrowingCircle className="grow-delay-2" />
    </Styled.Root>
  );
};

export default UnreadIndicator;
