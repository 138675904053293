import { request } from 'src/api/request';

let astcSupport: boolean | undefined;

const determineAstcSupport = (): boolean => {
  if (astcSupport !== undefined) {
    return Boolean(astcSupport);
  }

  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl');
  const gl2 = canvas.getContext('webgl2');

  astcSupport =
    (gl && gl.getExtension('WEBGL_compressed_texture_astc')) ||
    (gl2 && gl2.getExtension('WEBGL_compressed_texutre_astc'));

  canvas.remove();

  return Boolean(astcSupport);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const playStart = () => (_dispatch: any) => {
  const config = {
    method: 'post',
    url: '/api/web/play/start',
    data: {
      astcSupport: determineAstcSupport(),
    },
  };

  return request(config);
};
