import { styled } from '@mui/material';

interface RootProps {
  variantStyles: {
    padding: string;
  };
  enableGradient: boolean;
  enableBorder: boolean;
}

const Root = styled('div')<RootProps>`
  background: ${({ enableGradient }) =>
    enableGradient ? 'linear-gradient(90deg, #ffffff00 5%, #ffffff14 40%, #ffffff00 75%)' : 'none'};
  background-color: #1c1c1c;
  display: flex;
  width: 100%;
  margin-top: 0.7rem;
  padding: ${({ variantStyles }) => variantStyles?.padding};
  border-top: ${({ enableBorder }) => (enableBorder ? '7px solid #1c1c1c' : 'none')};
  border-bottom: ${({ enableBorder }) => (enableBorder ? '7px solid #1c1c1c' : 'none')};
  /* box-shadow: 0 0 5px 5px #00000033; */
  box-sizing: border-box;
  border-radius: 0.1rem;
  overflow-y: visible;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background: ${({ enableGradient }) =>
      enableGradient ? 'linear-gradient(90deg, #ffffff00 10%, #ffffff22 35%, #ffffff00 60%)' : 'none'};
    /* background-color: #222222; */
    background-color: #272727;
  }
`;

const BorderGradientImg = styled('img')`
  width: 100%;
  height: 8px;
  position: absolute;
  left: 0;
`;

export default {
  Root,
  BorderGradientImg,
};
