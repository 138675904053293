import { FC } from 'react';
import useGameClientContext from 'src/hooks/useGameClientContext';
import Styled from 'src/components/game/TheatreModeButton.styles';
// import { logMain } from 'src/modules/logger/logger';

interface Colors {
  gradientColor1: string;
  gradientColor2: string;
}

interface TheatreModeButtonProps {
  colors: Colors;
}

const TheatreModeButton: FC<TheatreModeButtonProps> = ({ colors }) => {
  const { setTheatreMode } = useGameClientContext();
  const { unityClientLoaded } = useGameClientContext();

  if (!unityClientLoaded) return null;

  return (
    <Styled.Root colors={colors} onClick={() => setTheatreMode(true)}>
      <span className="material-icons-outlined">crop_landscape</span>
    </Styled.Root>
  );
};

export default TheatreModeButton;
