import { FC, useEffect, useRef, useState } from 'react';
import useViewportContext from 'src/hooks/useViewportContext';
import XPIcon from 'src/assets/icons/xp.webp';
import Styled from 'src/components/data-display/XP.styles';

interface XPProps {
  xp: number;
  xpInLevel: number;
  height?: number;
  cornerOffset?: number;
  style?: React.CSSProperties;
}

const XP: FC<XPProps> = ({ xp, xpInLevel, height = 16, cornerOffset = 6, style = {} }) => {
  const xpRef = useRef(null);
  const { viewportWidth, viewportHeight } = useViewportContext();
  const [width, setWidth] = useState(0);
  const halfHeight = height / 2;
  const adjustedEnd = width - cornerOffset;
  const xpWidth = (xp / xpInLevel) * adjustedEnd;
  const progress = xpWidth >= cornerOffset ? xpWidth : cornerOffset;

  const bgPoint1 = `${0} ${halfHeight}`;
  const bgPoint2 = `${cornerOffset} ${0}`;
  const bgPoint3 = `${width - cornerOffset} 0`;
  const bgPoint4 = `${width} ${halfHeight}`;
  const bgPoint5 = `${width - cornerOffset} ${height}`;
  const bgPoint6 = `${cornerOffset} ${height}`;

  const progressPoint1 = `${0} ${halfHeight}`;
  const progressPoint2 = `${cornerOffset} ${0}`;
  const progressPoint3 = `${progress} 0`;
  const progressPoint4 = `${progress + cornerOffset} ${halfHeight}`;
  const progressPoint5 = `${progress} ${height}`;
  const progressPoint6 = `${cornerOffset} ${height}`;

  useEffect(() => {
    if (xpRef.current) {
      const el = xpRef.current as HTMLDivElement;
      const { offsetWidth } = el;

      setWidth(offsetWidth);
    }
  }, [viewportWidth, viewportHeight]);

  return (
    <Styled.Root ref={xpRef} style={style}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} preserveAspectRatio="none">
        <defs>
          <linearGradient id={'gradient-bkg'} x1="0" x2="0" y1="0" y2="1">
            <stop offset="0" stopColor={'#ffffff'} />
            <stop offset="0.3" stopColor={'#cccccc'} />
            <stop offset="0.7" stopColor={'#cccccc'} />
            <stop offset="1" stopColor={'#ffffff'} />
          </linearGradient>
        </defs>

        <path
          id="hex-bg"
          data-name="hex-xp-bg"
          d={`M${bgPoint1} L${bgPoint2} L${bgPoint3} L${bgPoint4} L${bgPoint5} L${bgPoint6} L${bgPoint1} Z`}
          fill="#ffffff77"
        />

        {xp !== 0 && (
          <path
            id="hex-xp-gradient-bar"
            data-name="hex-xp-gradient-bar"
            d={`M${progressPoint1} L${progressPoint2} L${progressPoint3} L${progressPoint4} L${progressPoint5} L${progressPoint6} L${progressPoint1} Z`}
            fill="url(#gradient-bkg)"
          />
        )}
      </svg>

      <Styled.XPLabel>
        <span>{xp}</span>
        <span>/</span>
        <span>{xpInLevel}</span>
        <img src={XPIcon} alt="XP Icon" width="256" height="256" title="XP Icon" />
      </Styled.XPLabel>
    </Styled.Root>
  );
};

export default XP;
