import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import TriangleOutlineContainer from 'src/components/layout/TriangleOutlineContainer';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/pages/events/components/SectionDetails.styles';

interface Asset {
  title: string;
  path: string;
}

const SectionDetails: FC = () => {
  const { t } = useTranslation(nameSpace.events);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [inView1, setInView1] = useState(false);
  const [inView2, setInView2] = useState(false);
  const detailImg1: Asset = t('events.details.asset1', { returnObjects: true });
  const detailImg2: Asset = t('events.details.asset2', { returnObjects: true });

  return (
    <Styled.Root>
      <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
        <div className={`inview animated ${inView1 ? 'fadeInUp' : ''}`}>
          <Styled.Row>
            <Styled.RowContentContainer>
              <Styled.ContentTitle>{t('events.upcoming.content.detail4.title')}</Styled.ContentTitle>
              <Styled.ContentBody>{t('events.upcoming.content.detail4.body1')}</Styled.ContentBody>
            </Styled.RowContentContainer>

            <Styled.RowImageContainer>
              <TriangleOutlineContainer>
                <Styled.RowImage
                  src={`/assets/${detailImg1.path}`}
                  width="1200"
                  height="627"
                  loading="lazy"
                  alt={detailImg1.title}
                />
              </TriangleOutlineContainer>
            </Styled.RowImageContainer>
          </Styled.Row>
        </div>
      </Waypoint>

      <Waypoint onEnter={() => setInView2(true)} bottomOffset="20px">
        <div className={`inview animated ${inView2 ? 'fadeInUp' : ''}`}>
          <Styled.Row>
            {!mobileView && (
              <Styled.RowImageContainer>
                <TriangleOutlineContainer>
                  <Styled.RowImage
                    src={`/assets/${detailImg2.path}`}
                    width="1200"
                    height="627"
                    loading="lazy"
                    alt={detailImg2.title}
                  />
                </TriangleOutlineContainer>
              </Styled.RowImageContainer>
            )}

            <Styled.RowContentContainer>
              <Styled.ContentTitle>{t('events.upcoming.content.detail5.title')}</Styled.ContentTitle>
              <Styled.ContentBody>{t('events.upcoming.content.detail5.body1')}</Styled.ContentBody>
            </Styled.RowContentContainer>

            {mobileView && (
              <Styled.RowImageContainer>
                <TriangleOutlineContainer>
                  <Styled.RowImage
                    src={`/assets/${detailImg2.path}`}
                    width="1200"
                    height="627"
                    loading="lazy"
                    alt={detailImg2.title}
                  />
                </TriangleOutlineContainer>
              </Styled.RowImageContainer>
            )}
          </Styled.Row>
        </div>
      </Waypoint>
    </Styled.Root>
  );
};

export default SectionDetails;
