import { styled } from '@mui/material';

interface RootProps {
  dimensions: { width: string; height: string };
}

const Root = styled('div')<RootProps>`
  display: block;
  width: ${({ dimensions }) => dimensions.width};
  height: ${({ dimensions }) => dimensions.height};
  box-sizing: border-box;
  position: absolute;
  top: calc(50% + 6px);
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  pointer-events: default;
  transition: transform 0.4s ease-in;

  &.centered-container-scale-in {
    transform: translate(-50%, -50%) scale(1);
  }

  &.centered-container-scale-out {
    transform: translate(-50%, -50%) scale(0);
  }

  @media (max-width: 1000px) or (max-height: 700px) {
    top: calc(50% + 7px);
  }
`;

const InnerContainer = styled('div')`
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 12px;
  right: 23px;
  bottom: 19px;
  left: 23px;
  clip-path: url('#game-overlay-clip-path');
`;

export default { Root, InnerContainer };
