/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { To } from 'react-router-dom';
import { randomIntFromInterval } from 'src/tools/randomIntFromInterval';

export interface IGameClientContext {
  dimensions: { width: string; height: string };
  setDimensions: (dimensions: { width: string; height: string }) => void;
  iFrameBlobObjectURL: string;
  setIFrameBlobObjectURL: (iFrameBlobObjectURL: string) => void;
  loadGameConfig: boolean;
  setLoadGameConfig: (loadGameConfig: boolean) => void;
  isLoadingGameConfig: boolean;
  setIsLoadingGameConfig: (isLoadingGameConfig: boolean) => void;
  configLoaded: boolean;
  setConfigLoaded: (configLoaded: boolean) => void;
  clearUnityClient: boolean;
  setClearUnityClient: (clearUnityClient: boolean) => void;
  isClearingUnityClient: boolean;
  setIsClearingUnityClient: (isClearingUnityClient: boolean) => void;
  showGamePage: boolean;
  setShowGamePage: (showGamePage: boolean) => void;
  checkGameAvailability: boolean;
  setCheckGameAvailability: (checkGameAvailability: boolean) => void;
  gameAvailable: boolean;
  setGameAvailable: (gameAvailable: boolean) => void;
  gameClientColor: number;
  setGameClientColor: (gameClientColor: number) => void;
  devicePixelRatio: number;
  setDevicePixelRatio: (devicePixelRatio: number) => void;
  unityClientLoadPercentage: number;
  setUnityClientLoadPercentage: (unityClientLoadPercentage: number) => void;
  unityClientLoaded: boolean;
  setUnityClientLoaded: (unityClientLoaded: boolean) => void;
  theatreMode: boolean;
  setTheatreMode: (theatreMode: boolean) => void;
  fullscreen: boolean;
  setFullscreen: (fullscreen: boolean) => void;
  leaving: boolean;
  setLeaving: (leaving: boolean) => void;
  leavingTo: To | number;
  setLeavingTo: (leavingTo: To | number) => void;
  leavingOutbound: boolean;
  setLeavingOutbound: (leavingOutbound: boolean) => void;
  gameConfigError: boolean;
  setGameConfigError: (gameConfigError: boolean) => void;
  gameConfigErrorMessage: string;
  setGameConfigErrorMessage: (gameConfigErrorMessage: string) => void;
  fullscreenModal: boolean;
  setFullscreenModal: (fullscreenModal: boolean) => void;
  searchMarketsFailureModal: boolean;
  setSearchMarketsFailureModal: (searchMarketsFailureModal: boolean) => void;
  gameOverlayErrorModal: boolean;
  setGameOverlayErrorModal: (gameOverlayErrorModal: boolean) => void;
  listNFTModalNonfungibleId: string;
  setListNFTModalNonfungibleId: (listNFTModalNonfungibleId: string) => void;
  multipleInstanceModal: boolean;
  setMultipleInstanceModal: (multipleInstanceModal: boolean) => void;
  disableGameClientByDebug: boolean;
  setDisableGameClientByDebug: (disableGameClientByDebug: boolean) => void;
  isGameClientDisabledByDebug: boolean;
  setIsGameClientDisabledByDebug: (isGameClientDisabledByDebug: boolean) => void;
  disableGameClientByMultipleInstances: boolean;
  setDisableGameClientByMultipleInstances: (disableGameClientByMultipleInstances: boolean) => void;
  isGameClientDisabledByMultipleInstances: boolean;
  setIsGameClientDisabledByMultipleInstances: (isGameClientDisabledByMultipleInstances: boolean) => void;
  isDisablingGameClient: boolean;
  setIsDisablingGameClient: (isDisablingGameClient: boolean) => void;
}

export const initialContext: IGameClientContext = {
  dimensions: { width: '0', height: '0' },
  setDimensions: () => undefined,
  iFrameBlobObjectURL: '',
  setIFrameBlobObjectURL: () => undefined,
  loadGameConfig: false,
  setLoadGameConfig: () => undefined,
  isLoadingGameConfig: false,
  setIsLoadingGameConfig: () => undefined,
  configLoaded: false,
  setConfigLoaded: () => undefined,
  clearUnityClient: false,
  setClearUnityClient: () => undefined,
  isClearingUnityClient: false,
  setIsClearingUnityClient: () => undefined,
  showGamePage: false,
  setShowGamePage: () => undefined,
  checkGameAvailability: false,
  setCheckGameAvailability: () => undefined,
  gameAvailable: true,
  setGameAvailable: () => undefined,
  gameClientColor: randomIntFromInterval(0, 4),
  setGameClientColor: () => undefined,
  devicePixelRatio: window.devicePixelRatio || 1,
  setDevicePixelRatio: () => undefined,
  unityClientLoadPercentage: 0,
  setUnityClientLoadPercentage: () => undefined,
  unityClientLoaded: false,
  setUnityClientLoaded: () => undefined,
  theatreMode: false,
  setTheatreMode: () => undefined,
  fullscreen: false,
  setFullscreen: () => undefined,
  leaving: false,
  setLeaving: () => undefined,
  leavingTo: '',
  setLeavingTo: () => undefined,
  leavingOutbound: false,
  setLeavingOutbound: () => undefined,
  gameConfigError: false,
  setGameConfigError: () => undefined,
  gameConfigErrorMessage: '',
  setGameConfigErrorMessage: () => undefined,
  fullscreenModal: false,
  setFullscreenModal: () => undefined,
  searchMarketsFailureModal: false,
  setSearchMarketsFailureModal: () => undefined,
  gameOverlayErrorModal: false,
  setGameOverlayErrorModal: () => undefined,
  listNFTModalNonfungibleId: '',
  setListNFTModalNonfungibleId: () => undefined,
  multipleInstanceModal: false,
  setMultipleInstanceModal: () => undefined,
  disableGameClientByDebug: false,
  setDisableGameClientByDebug: () => undefined,
  isGameClientDisabledByDebug: false,
  setIsGameClientDisabledByDebug: () => undefined,
  disableGameClientByMultipleInstances: false,
  setDisableGameClientByMultipleInstances: () => undefined,
  isGameClientDisabledByMultipleInstances: false,
  setIsGameClientDisabledByMultipleInstances: () => undefined,
  isDisablingGameClient: false,
  setIsDisablingGameClient: () => undefined,
};

const GameClientContext = createContext<IGameClientContext>(initialContext);

GameClientContext.displayName = 'GameClientContext';

export default GameClientContext;
