import { styled } from '@mui/material';

const PromoSection = styled('div')`
  box-sizing: border-box;
  position: relative;
  padding: 7rem 0 5rem;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ${({ theme }) => theme.breakpoints.down('xl')} {
    padding: 4rem 0;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 3rem 0;
  }
  h2.main-title {
    font-size: 3.5rem;
    line-height: 1;
    margin: 0 0 2rem;
    border: 0;
    color: ${({ theme }) => theme.custom.colors.White};
    span {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
    ${({ theme }) => theme.breakpoints.down('xl')} {
      font-size: 3rem;
    }
    ${({ theme }) => theme.breakpoints.down('lg')} {
      font-size: 2.2rem;
    }
  }
  h4 {
    font-size: 1.6rem;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 1.8rem;
    font-family: ${({ theme }) => theme.custom.typography.LabelSm.fontFamily};
    svg {
      margin: 0 1rem 0 0;
      ${({ theme }) => theme.breakpoints.down('lg')} {
        margin: -3px 1rem 0 0;
      }
    }
    ${({ theme }) => theme.breakpoints.down('lg')} {
      font-size: 1.3rem;
    }
  }
  .promo-wrapper {
    position: relative;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      display: block;
    }
    button {
      margin-top: 0.5rem;
      padding: 1px 6px;
      ${({ theme }) => theme.breakpoints.down('sm')} {
        margin: 1rem auto 0;
        width: 100%;
      }
    }
    .img-wrapper {
      ${({ theme }) => theme.breakpoints.down('sm')} {
        margin: 1.5rem auto 0;
        max-width: 400px;
      }
      img {
        max-width: 100%;
        height: auto;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
      }
    }
  }
`;
export default { PromoSection };
