import { FC, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Container, Typography } from '@mui/material';
import { useQuery } from 'src/tools/useQuery';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import Styled from './SectionFaqs.styles';
import Box from '../../../basics/Box';
import SectionTitle from './SectionTitle';
import { nameSpace } from '../../../../modules/i18n';

type itemProps = {
  id: number;
  group: string;
  question: string;
  answer: string;
};

const Faq: FC = () => {
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const { t } = useTranslation(trim === '1' ? nameSpace.homeTrim : nameSpace.home);
  const faqItems = t<string, itemProps[]>('home.faqs', { returnObjects: true });
  const [expanded, setExpanded] = useState<string | false>();

  const handleChange = useCallback(
    (panel: string) => (_: SyntheticEvent<Element, Event>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    [],
  );

  return (
    <Styled.FaqsSection>
      <Container>
        <SectionTitle
          alignItems="center"
          header={t('home.faq.title')}
          isTitle
          maxWidth="665px"
          padding="0"
          textAlign="center"
        />
        <SectionTitle padding="0" delay={2} header={t('home.faq.subtitle')} body={t('home.faq.content')} />
        <Box className="animated delay-3" margin="0 0 0">
          {faqItems.map(({ id, group, question, answer }) => (
            <Styled.Accordion key={id} expanded={expanded === group} onChange={handleChange(group)}>
              <Styled.AccordionSummary aria-controls={`${group}-content`} id={`${group}-header`}>
                <Typography variant="h6">{question}</Typography>
              </Styled.AccordionSummary>
              <Styled.AccordionDetails>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: typeof answer === 'string' ? DOMPurify.sanitize(answer) : '' }}
                />
              </Styled.AccordionDetails>
            </Styled.Accordion>
          ))}
        </Box>
      </Container>
    </Styled.FaqsSection>
  );
};

export default Faq;
