import { useEffect } from 'react';
import useViewportContext from 'src/hooks/useViewportContext';
// import { logMain } from 'src/modules/logger/logger';

export const useHomeCTAResize = () => {
  const { viewportWidth, viewportHeight } = useViewportContext();

  // Listen for window resize  and adjust home hero and iframe respectively
  useEffect(() => {
    const resize = () => {
      const homeHeroContainer = document.querySelector('.home-hero-container') as HTMLDivElement | null;
      const homeHeroIFrame = document.querySelector('.home-hero-iframe') as HTMLDivElement | null;

      if (homeHeroContainer && homeHeroIFrame) {
        const { width: heroWidth, height: heroHeight } = homeHeroContainer.getBoundingClientRect();
        // The ratio of the iframe video
        const ratio = 16 / 9;

        // The estimated full width of the iframe, assuming the iframe would be set to full height of hero container
        let newWidth = heroHeight * ratio;

        // If the width of the iframe is too big, adjust the size
        if (newWidth < heroWidth) {
          newWidth = heroWidth;
        }

        // Adjust the game container size
        homeHeroIFrame.style.width = `${newWidth}px`;
        homeHeroIFrame.style.height = `${newWidth / ratio}px`;
      }
    };

    resize();
  }, [viewportWidth, viewportHeight]);
};
