import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLeaderboard = (options: RequestOptions) => (_dispatch: any) => {
  const { urlParams, queryParams } = options;
  let url = `/api/web/leaderboard/${urlParams?.leaderboardId}/page`;

  if (queryParams && Object.keys(queryParams).length > 0) {
    let started = false;

    Object.keys(queryParams).forEach(key => {
      if (typeof queryParams[key] !== 'undefined') {
        if (!started) {
          url += '?';
          started = true;
        } else {
          url += '&';
        }

        url += `${key}=${queryParams[key]}`;
      }
    });
  }

  const config = {
    method: 'get',
    url,
  };

  return request(config);
};
