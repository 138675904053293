import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import Styled from 'src/components/pages/leaderboards/components/MobileSectionLabel.styles';

const MobileSectionLabel: FC = () => {
  const [inView, setInView] = useState(false);
  const { t } = useTranslation(nameSpace.leaderboards);

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated delay-1s ${inView ? 'fadeInUp' : ''}`}>
        <Styled.Separator />
        <Styled.Title>{`${t('leaderboards.section.title')}`}</Styled.Title>
        <Styled.Separator />
      </Styled.Root>
    </Waypoint>
  );
};

export default MobileSectionLabel;
