/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useLocation, useNavigate, NavigateFunction, To, RelativeRoutingType } from 'react-router-dom';
import { PooleEvents } from 'src/modules/poole/events';
import { useAppSelector } from 'src/hooks/useAppSelector';
import useGameClientContext from 'src/hooks/useGameClientContext';
// import { logMain } from 'src/modules/logger/logger';

interface PooleData {
  playerId?: string;
  fromContext: string;
  priceFrom?: string;
  priceTo?: string;
}

interface Options {
  navigateOpts?: {
    replace?: boolean;
    state?: any;
    relative?: RelativeRoutingType;
  };
  pooleData: PooleData;
  gtmEvent?: (() => void) | null;
  force?: boolean;
}

export const useAppNavigateInternal = () => {
  const { unityClientLoaded, setLeaving, setLeavingTo, setLeavingOutbound } = useGameClientContext();
  const routerLocation = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const playerId = useAppSelector(({ currentProfile }) => currentProfile.currentProfile.id) || '';

  const handleNavigateInternal = useMemo(
    () => (to: To | number, options: Options | null) => {
      const { navigateOpts = {}, pooleData, gtmEvent = () => null, force = false } = options || {};
      const { fromContext = '' } = pooleData || {};

      // IMPORTANT: if user is on '/play' and they are trying to leave, we must activate popup
      // that asks them if they are sure they want to leave
      if (routerLocation.pathname.indexOf('play') !== -1 && !force && unityClientLoaded) {
        setLeaving(true);
        setLeavingTo(to);
        setLeavingOutbound(false);

        return;
      }

      // IMPORTANT: If the force param is true, deactivate the any popup caused by the user trying to leave
      if (force) {
        setLeaving(false);
        setLeavingTo('');
        setLeavingOutbound(false);
      }

      // If theres no anchor tag in the url, scroll to the top of the page
      if (typeof to === 'string') {
        if (to.indexOf('#') === -1) {
          window.scrollTo({ top: 0, left: 0 });
        }
      }

      // If a google tag manager event is passed in, fire it
      if (gtmEvent) {
        gtmEvent();
      }

      // If the to param is a number, it's a history stack number, so don't fire the poole event
      if (typeof to !== 'number') {
        PooleEvents.NavigateInternal({
          playerId: playerId || pooleData?.playerId || '',
          from: routerLocation.pathname,
          to: typeof to === 'string' ? to : to.pathname || '',
          fromContext,
        });
      }

      // If the to param is a number, it's a history stack number, so don't append options
      // If there are options, use the navigate function with the options
      // Otherwise, just use the navigate function
      if (typeof to === 'number') {
        navigate(to);
      } else if (navigateOpts && Object.keys(navigateOpts).length > 0) {
        navigate(to, navigateOpts);
      } else {
        navigate(to);
      }
    },
    [routerLocation.pathname, unityClientLoaded, setLeaving, setLeavingTo, setLeavingOutbound, playerId, navigate],
  );

  return handleNavigateInternal;
};
