import { FC, ReactNode, useState, useMemo, useCallback } from 'react';
import BroadcastChannelContext, {
  initialContext,
  IBroadcastChannelContext,
  BroadcastChannelMessageParams,
} from 'src/context/broadcast-channel/BroadcastChannel.context';
import { config } from 'src/config/config';
import { logMain } from 'src/modules/logger/logger';

interface BroadcastChannelProviderProps {
  children: ReactNode;
}

const BroadcastChannelProvider: FC<BroadcastChannelProviderProps> = ({ children }) => {
  const [broadcastChannel, setBroadcastChannel] = useState<IBroadcastChannelContext['broadcastChannel']>(
    initialContext.broadcastChannel,
  );

  const sendBroadcastMessage = useCallback(
    (params?: BroadcastChannelMessageParams) => {
      logMain.debug('[BROADCAST_CHANNEL_WEBSITE]: Sending message to broadcast channel...');

      broadcastChannel.postMessage({
        from: process.env.PUBLIC_URL,
        uuid: config.uuid,
        env: config.env,
        ...params,
      });
    },
    [broadcastChannel],
  );

  const value = useMemo(
    () => ({ sendBroadcastMessage, broadcastChannel, setBroadcastChannel }),
    [sendBroadcastMessage, broadcastChannel, setBroadcastChannel],
  );

  return <BroadcastChannelContext.Provider value={value}>{children}</BroadcastChannelContext.Provider>;
};

export default BroadcastChannelProvider;
