import { styled } from '@mui/material';

interface RootProps {
  numSlides?: number;
  hasReqs?: boolean;
}

const Root = styled('div')<RootProps>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  position: relative;

  & .swiper {
    height: 100%;
    position: static;
    /* overflow-y: auto; */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -webkit-mask-image: linear-gradient(
      to right,
      transparent 0%,
      transparent 1.5%,
      #000 4%,
      #000 96%,
      transparent 98.5%,
      transparent 100%
    );
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      transparent 1.5%,
      #000 4%,
      #000 96%,
      transparent 98.5%,
      transparent 100%
    );

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .swiper {
    width: ${({ numSlides }) => (numSlides && numSlides > 1 ? `auto` : '100%')};
    padding: 0 3rem;

    @media (max-width: 1100px) {
      padding: 0 1.5rem;
    }

    @media (max-width: 900px) {
      padding: 0 1.5rem;
    }

    @media (max-height: 700px) {
      padding: 0 2.5rem;
    }

    @media (max-height: 500px) {
      padding: 0 1.5rem;
    }
  }

  & .swiper-slide {
    display: flex;
    width: fit-content;
    min-width: ${({ numSlides }) => (numSlides && numSlides > 1 ? `auto` : '100%')};
    justify-content: flex-start;
    align-items: center;
  }
`;

interface BottomGradientProps {
  active: boolean;
  color: string;
}

const BottomGradient = styled('div')<BottomGradientProps>`
  background: ${({ color }) => `linear-gradient(3deg, ${color}88 -20%, ${color}00 70%)`};
  display: block;
  height: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export default {
  Root,
  BottomGradient,
};
