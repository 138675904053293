/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useCallback, useEffect, useRef } from 'react';
// import { PooleEvents } from 'src/modules/poole/events';
import PaymentPopupContext, {
  PopupCallbacks,
  initialContext,
  IPaymentPopupContext,
} from 'src/context/payment-popup/PaymentPopup.context';
import { logMain } from 'src/modules/logger/logger';

const DOMAINS_FOR_DETECTION = ['localhost', 'legendaryheroesunchained.com'];
const INTERVAL_DURATION = 1000;

interface PaymentPopupProviderProps {
  children: ReactNode;
}

const PaymentPopupProvider: FC<PaymentPopupProviderProps> = ({ children }) => {
  const interval = useRef<NodeJS.Timer | null>(null);
  const handleOnReturn = useRef<(paymentSucceeded: boolean) => void>(() => undefined);
  const handleOnClose = useRef<() => void>(() => undefined);
  const [popup, setPopup] = useState<IPaymentPopupContext['popup']>(initialContext.popup);

  const handleClearInterval = () => {
    // Clear interval if exists
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  };

  // Function to open a popup to a passed in URL
  const handleOpenPopup = useCallback(
    (url: string, callbacks: PopupCallbacks) => {
      // Check if popup is already open, if so close existing one and close current active interval
      if (popup && !popup.closed) {
        logMain.debug('%c[PAYMENT POPUP]: Popup already open', 'color: orange;');
        logMain.debug('%c[PAYMENT POPUP]: Closing existing popup', 'color: orange;');

        handleClearInterval();
        popup.close();
      }

      const width = window.innerWidth;
      const height = window.innerHeight;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      // Set popup options
      const options = `width=${width},height=${height},top=${top},left=${left}`;

      logMain.debug('%c[PAYMENT POPUP]: Opening popup', 'color: orange;');

      // Create new popup
      const newPopup = window.open(url, 'paymentPopup', options);

      // Set popup states
      setPopup(newPopup);

      // Set callback state functions
      handleOnReturn.current = callbacks.onReturn;
      handleOnClose.current = callbacks.onClose;
    },
    [popup],
  );

  // Listen for changes in the popup state and clear Interval when closing popup
  useEffect(() => {
    // Function to clear the popup states
    const clearPopupState = (options: { paymentSucceeded: boolean }) => {
      const { paymentSucceeded } = options;

      logMain.debug('%c[PAYMENT POPUP]: Clearing popup state', 'color: orange;');

      // Clear interval if exists
      handleClearInterval();

      // Close the popup if exists and is open
      if (popup && !popup.closed) {
        popup.close();
        setPopup(null);
      }

      // Call onReturn callback
      if (paymentSucceeded) {
        handleOnReturn.current(true);
      } else {
        handleOnReturn.current(false);
      }

      // Call onClose callback
      handleOnClose.current();

      // Clear popup handler functions
      handleOnReturn.current = () => undefined;
      handleOnClose.current = () => undefined;
    };

    // Interval to monitor the popup
    const monitorPopup = () => {
      // Listen for changes in popup state every interval of (intervalDuration - 1000ms)
      interval.current = setInterval(() => {
        let popupUrl = '';
        let popupUrlParams: URLSearchParams | null = null;

        // Try to read the popup location href and search params
        try {
          popupUrl = popup?.location?.href || '';

          if (popupUrl) {
            popupUrlParams = new URLSearchParams(new URL(popupUrl).search);
          }
        } catch (e: any) {
          logMain.debug(
            "%c[PAYMENT POPUP]: Error reading 'location.href' from 'popup' (Due to CORS)",
            'color: orange;',
            e,
          );
        }

        // Check the popup url and close if detecting LHU domain
        if (popupUrl) {
          if (DOMAINS_FOR_DETECTION.some(domain => popupUrl.indexOf(domain) !== -1)) {
            logMain.debug('%c[PAYMENT POPUP]: LHU Domain detected', 'color: orange;');

            const paymentSucceeded = !!(
              popupUrlParams &&
              popupUrlParams.has('from_purchase') &&
              popupUrlParams.get('from_purchase') === 'true'
            );

            clearPopupState({ paymentSucceeded });
          }
        }

        // If popup is closed, clear interval and clear popup states
        if (!popup || popup.closed) {
          logMain.debug('%c[PAYMENT POPUP]: Closed popup detected', 'color: orange;', {
            popup,
            popupClosed: popup?.closed || null,
          });

          clearPopupState({ paymentSucceeded: false });
        }
      }, INTERVAL_DURATION);
    };

    // Start monitoring the popup if popup is open
    if (popup && interval.current === null) {
      logMain.debug('%c[PAYMENT POPUP]: Popup detected - Popup monitoring has begun', 'color: orange;');

      monitorPopup();
    }
  }, [popup]); // This effect depends on isPopupOpen and popup

  const value = useMemo(
    () => ({
      popup,
      handleOpenPopup,
    }),
    [popup, handleOpenPopup],
  );

  return <PaymentPopupContext.Provider value={value}>{children}</PaymentPopupContext.Provider>;
};

export default PaymentPopupProvider;
