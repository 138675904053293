import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getLeaderboardById } from 'src/redux/slices/leaderboards/leaderboards';
import { getNFTImage } from 'src/tools/getNFTImage';
import Styled from 'src/components/pages/leaderboard/components/Metadata.styles';
import { formatPrice } from 'src/tools/formatPrice';

const Metadata: FC = () => {
  const { leaderboardId = '' } = useParams();
  const selectedLeaderboard = useAppSelector(state => getLeaderboardById(state, leaderboardId));
  const { t: tLeaderboard } = useTranslation(nameSpace.leaderboard);
  const { totalPoints = null, displayName, imageUrl } = selectedLeaderboard || {};

  return (
    <Styled.Root>
      <Styled.LeftContainer>
        <Styled.InlineLogo
          // color={'#fff'}
          style={{ backgroundImage: `url(${getNFTImage(imageUrl || '')})` }}
        />

        <Styled.InlineText color={'#fff'}>{displayName || 'N/A'}</Styled.InlineText>
      </Styled.LeftContainer>

      <Styled.RightContainer>
        <Styled.MetadataLabel>{`${tLeaderboard('leaderboard.label.total')}:`}</Styled.MetadataLabel>
        <Styled.MetadataValue>
          {totalPoints !== null ? formatPrice(totalPoints, { hideSymbol: true, decimals: 0 }) : 'N/A'}
        </Styled.MetadataValue>
      </Styled.RightContainer>
    </Styled.Root>
  );
};

export default Metadata;
