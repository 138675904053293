import { createContext } from 'react';

export interface IAlertOverrideContext {
  enableAlertOverride: boolean;
  setEnableAlertOverride: (enableAlertOverride: boolean) => void;
  alertOverrideMessage: string;
  setAlertOverrideMessage: (alertOverrideMessage: string) => void;
}

export const initialContext: IAlertOverrideContext = {
  enableAlertOverride: false,
  setEnableAlertOverride: () => undefined,
  alertOverrideMessage: '',
  setAlertOverrideMessage: () => undefined,
};

const AlertOverrideContext = createContext<IAlertOverrideContext>(initialContext);

AlertOverrideContext.displayName = 'AlertOverrideContext';

export default AlertOverrideContext;
