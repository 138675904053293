import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  border: 7px solid;
  border-image: url('/assets/nine_slice_primary_modal.webp') round;
  border-image-slice: 14 fill;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default { Root };
