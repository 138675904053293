import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useSwiper } from 'swiper/react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getDropEventListPriceMarkets } from 'src/redux/slices/list-price-markets/list-price-markets';
import nestedSort from 'src/tools/nestedSort';
import { useMediaQuery, useTheme } from '@mui/material';
import { getPackTierFromType } from 'src/tools/getPackTierFromType';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/event-pack/components/ListingSelector.styles';

interface Asset {
  title: string;
  width: string;
  height: string;
  path: string;
}

type Listing = 'starter-pack' | 'alpha-pack' | 'bronze-pack' | 'silver-pack' | 'gold-pack';

interface ListingSelectorProps {
  listing: Listing;
  center?: boolean;
}

const ListingSelector: FC<ListingSelectorProps> = ({ listing, center = false }) => {
  const { eventId = '' } = useParams();
  const eventListPriceMarkets = useAppSelector(state => getDropEventListPriceMarkets(state, eventId));
  const sortedListPriceMarkets = nestedSort(eventListPriceMarkets, -1, 'displayOrder');
  const swiper = useSwiper();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { t: tEvent } = useTranslation(nameSpace.event);
  const { t: tCommon } = useTranslation(nameSpace.common);

  const handleOnChangeSlider = (i: number) => {
    swiper.slideTo(i);
  };

  if (eventListPriceMarkets.length <= 1) return null;

  return (
    <Styled.Root>
      <Styled.Listings center={center}>
        {sortedListPriceMarkets.map((listPriceMarket, i) => {
          const packTier = getPackTierFromType(listPriceMarket.type);
          const packAsset: Asset = tEvent(`event.hero.${packTier}.assets.pack`, { returnObjects: true });

          return (
            <Styled.Listing
              key={`listing-selection-${listPriceMarket.id}`}
              selected={listing === listPriceMarket.type}
              numListings={sortedListPriceMarkets.length}
            >
              <Styled.ListingImg
                src={`/assets/${packAsset.path}`}
                width={packAsset.width}
                height={packAsset.height}
                loading="lazy"
                alt={packAsset.title}
                onClick={() => swiper.slideTo(i)}
              />

              <ButtonHex
                variant="gold"
                width={mobileView ? 70 : 80}
                height={35}
                cornerOffset={10}
                fontSize={10}
                enableDropShadow
                hoverEffect
                title={tCommon('common.term.details')}
                textStyle={{ paddingTop: '1px' }}
                style={{ display: 'inline-block', margin: '.8rem 0 0 .1rem', pointerEvents: 'all' }}
                disabled={listing === listPriceMarket.type}
                onClick={() => handleOnChangeSlider(i)}
              />
            </Styled.Listing>
          );
        })}
      </Styled.Listings>
    </Styled.Root>
  );
};

export default ListingSelector;
