import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { formatPrice } from 'src/tools/formatPrice';
import ATCIcon from 'src/components/icons/ATCIcon';
import Styled from 'src/components/pages/profile/components/Balance.styles';
import PentarcShardsIcon from 'src/components/icons/PentarcShardsIcon';
import PentarcDustIcon from 'src/components/icons/PentarcDustIcon';

interface Title {
  default: string;
  empty: string;
}

interface BalanceProps {
  tab: number;
}

const Balance: FC<BalanceProps> = ({ tab }) => {
  const { playerId = '' } = useParams();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const currencyStates = useAppSelector(({ currencyStates }) => currencyStates.currencyStates);
  // const collectionItems = useAppSelector(({ collectionItems }) => collectionItems.collectionItems);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tProfile } = useTranslation(nameSpace.profile);
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const titles: Title[] = tProfile(
    isCurrentProfile ? 'profile.tab.results.titles' : 'profile.tab.results.titles.other.user',
    { returnObjects: true },
  );
  const balance = formatPrice(currentProfile?.atcBalance, { decimals: 0, hideSymbol: true });
  const pentarcShardsBalance = currencyStates['pentarc-shards']
    ? formatPrice(currencyStates['pentarc-shards'].amount, { decimals: 0, hideSymbol: true })
    : 0;
  const pentarcDustBalance = currencyStates['pentarc-dust']
    ? formatPrice(currencyStates['pentarc-dust'].amount, { decimals: 0, hideSymbol: true })
    : 0;

  return (
    <Styled.Root>
      <Styled.Title>{titles[tab].default}</Styled.Title>

      <Styled.Separator />

      <Styled.BalanceContainer>
        <ATCIcon resolution={'large'} size={40} style={{ margin: '2px 1rem 0 0' }} />

        <Styled.Balance>{balance}</Styled.Balance>
        <Styled.BalanceLabel>{tCommon('common.project.currency')}</Styled.BalanceLabel>
      </Styled.BalanceContainer>

      <Styled.Button disabled>{`${tCommon('common.term.sell')} ${tCommon('common.project.currency')}`}</Styled.Button>

      <Styled.BalanceContainer>
        <PentarcShardsIcon resolution={'large'} size={40} style={{ margin: '2px 1rem 0 0' }} />

        <Styled.Balance>{pentarcShardsBalance || 0}</Styled.Balance>
        <Styled.BalanceLabel>{tCommon('common.project.currency.pentarc.shards')}</Styled.BalanceLabel>
      </Styled.BalanceContainer>

      <Styled.BalanceContainer>
        <PentarcDustIcon resolution={'large'} size={40} style={{ margin: '2px 1rem 0 0' }} />

        <Styled.Balance>{pentarcDustBalance || 0}</Styled.Balance>
        <Styled.BalanceLabel>{tCommon('common.project.currency.pentarc.dust')}</Styled.BalanceLabel>
      </Styled.BalanceContainer>
    </Styled.Root>
  );
};

export default Balance;
