import { styled } from '@mui/material';
import { motion } from 'framer-motion';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled(motion.div)`
  background-color: #070707ec;
  display: block;
  width: 96%;
  max-width: 85rem;
  height: 93%;
  max-height: 52rem;
  box-sizing: border-box;
  border: 2px solid ${`${customThemeOptions.custom.colors.BgLayer3Accent}77`};
  border-radius: 0.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
`;

const HeaderContainer = styled(motion.div)`
  display: block;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-height: 500px) {
    width: 48%;
  }

  @media (max-height: 350px) {
    width: 45%;
  }
`;

const HeaderFrame = styled('div')`
  display: block;
  width: 100%;
  border: 55px solid;
  border-image: url('/assets/rewards_header_bkg.webp');
  border-image-slice: fill 45%;
  opacity: 0.5;

  @media (max-height: 600px) {
    border: 45px solid;
    border-image: url('/assets/rewards_header_bkg.webp');
    border-image-slice: fill 45%;
  }

  @media (max-height: 500px) {
    border: 30px solid;
    border-image: url('/assets/rewards_header_bkg.webp');
    border-image-slice: fill 45%;
  }

  @media (max-height: 350px) {
    border: 24px solid;
    border-image: url('/assets/rewards_header_bkg.webp');
    border-image-slice: fill 45%;
  }
`;

const Header = styled('div')`
  display: inline-block;
  padding-bottom: 3px;
  text-transform: uppercase;
  font-family: 'Barlow';
  font-size: 3rem;
  font-weight: 400;
  line-height: 3rem;
  color: #ffffffcc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-height: 500px) {
    padding-bottom: 2px;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2rem;
  }

  @media (max-height: 350px) {
    padding-bottom: 1px;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

interface RewardsContainerProps {
  modalWidth: number;
  numRewards: number;
}

const RewardsContainer = styled('div')<RewardsContainerProps>`
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  gap: 3%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & > div {
    width: 9rem;
    max-width: ${({ modalWidth, numRewards }) => `${Math.floor(modalWidth / numRewards)}px`};
  }

  @media (max-height: 500px) {
    width: 55%;
  }

  @media (max-height: 400px) {
    width: 50%;
    gap: 4%;
  }

  @media (max-height: 350px) {
    width: 40%;
  }

  @media (max-height: 300px) {
    width: 30%;
    gap: 8%;
  }
`;

const RewardContainer = styled(motion.div)`
  background-color: ${`${customThemeOptions.custom.colors.BgLayer3}99`};
  display: inline-block;
  aspect-ratio: 1;
  border: 2px solid ${`${customThemeOptions.custom.colors.BgLayer3Accent}77`};
  border-radius: 0.3rem;
  box-shadow: 0 4px 7px -3px #000000;
  position: relative;
  transform: scale(0);

  @media (max-height: 600px) {
    border-width: 2px;
  }

  @media (max-height: 350px) {
    border-width: 2px;
  }
`;

const RewardImg = styled('img')`
  display: block;
  width: auto;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translate(-50%, -50%) scale(1.2);
  }
`;

const RewardCount = styled('span')`
  display: inline-block;
  font-family: 'Barlow';
  color: #ffffff;
  position: absolute;
  bottom: 10%;
  right: 10%;

  & > span {
    text-shadow: 1px 1px 3px #333333;
  }

  & > span:first-of-type {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
  }

  & > span:last-of-type {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.8rem;
  }

  @media (max-height: 500px) {
    & > span:first-of-type {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }

    & > span:last-of-type {
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
  }

  @media (max-height: 400px) {
    bottom: 4%;
    right: 5%;

    & > span:first-of-type {
      font-size: 0.8rem;
      line-height: 0.8rem;
    }

    & > span:last-of-type {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }

  @media (max-height: 350px) {
    & > span:first-of-type {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }

    & > span:last-of-type {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`;

const ButtonContainer = styled(motion.div)`
  display: block;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;

  @media (max-width: 1000px) {
    bottom: 1.5rem;
  }

  @media (max-width: 768px) {
    bottom: 1rem;
  }

  @media (max-height: 350px) {
    bottom: 1rem;
  }
`;

// const CloseButton = styled('div')`
//   display: block;
//   width: 2.5rem;
//   height: 2.5rem;
//   border-radius: 50%;
//   cursor: pointer;
//   transition: background-color 0.2s ease-in-out;
//   position: absolute;
//   top: 1rem;
//   right: 1rem;
//   opacity: 0;
//   pointer-events: none;

//   & .material-icons {
//     display: block;
//     font-size: 1.4rem;
//     color: white;
//     position: absolute;
//     top: 52%;
//     left: 52%;
//     transform: translate(-50%, -50%);

//     @media (max-width: 768px) {
//       font-size: 1rem;
//       top: 51%;
//       left: 51%;
//     }
//   }

//   &:hover {
//     background-color: #ffffff22;
//   }

//   @media (max-width: 768px) {
//     width: 2rem;
//     height: 2rem;
//     top: 0.5rem;
//     right: 0.5rem;
//   }
// `;

const TooltipRoot = styled('div')`
  display: block;
`;

const TooltipPrimary = styled('p')`
  display: block;
  font-family: 'Barlow';
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 400;
  /* color: #ffffff; */
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

// const TooltipSecondary = styled('p')`
//   display: block;
//   margin-top: 1rem;
//   font-family: 'Barlow';
//   font-style: italic;
//   font-size: 0.8rem;
//   line-height: 1.2rem;
//   font-weight: 400;
//   color: #ffffff77;
//   /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
// `;

export default {
  Root,
  HeaderContainer,
  Header,
  HeaderFrame,
  RewardsContainer,
  RewardContainer,
  RewardImg,
  RewardCount,
  ButtonContainer,
  // CloseButton,
  TooltipRoot,
  TooltipPrimary,
  // TooltipSecondary,
};
