import { FC } from 'react';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useBlogContext from 'src/hooks/useBlogContext';
import FeaturedBlogCard from 'src/components/pages/blog/components/FeaturedBlogCard';
import BlogCard from 'src/components/pages/blog/components/BlogCard';
import Styled from 'src/components/pages/blog/components/SectionDetails.styles';
import { useMediaQuery, useTheme } from '@mui/material';

interface BG {
  path: string;
}

const SectionDetails: FC = () => {
  const { blogPosts } = useBlogContext();
  const { t } = useTranslation(nameSpace.blog);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(901));
  const bgBottom: BG = t('blog.details.bkg.bottom', { returnObjects: true });

  if (blogPosts.length === 0) return null;

  return (
    <Styled.Root>
      <Styled.BackgroundImage style={{ backgroundImage: `url(/assets/${bgBottom.path})` }}>
        <Styled.BackgroundImageGradient />
      </Styled.BackgroundImage>

      <Styled.CenteredContainer>
        {blogPosts.map((blogPost, i) => {
          if (i === 0) {
            return (
              <div key={`blog-featured-${i}`}>
                {mobileView ? (
                  <BlogCard id={blogPost.id} />
                ) : (
                  <>
                    <FeaturedBlogCard id={blogPost.id} />

                    {blogPosts.length > 1 && (
                      <Styled.SeparatorContainer>
                        <Styled.SeparatorText
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('blog.title.latest.news')) }}
                        />
                        <Styled.SeparatorRight />
                      </Styled.SeparatorContainer>
                    )}
                  </>
                )}
              </div>
            );
          }

          return <BlogCard key={`blog-unfeatured-${i}`} id={blogPost.id} />;
        })}
      </Styled.CenteredContainer>
    </Styled.Root>
  );
};

export default SectionDetails;
