import { FC } from 'react';
import Styled from 'src/components/tutorial-overlay/NameBackground.styles';

export interface NameBackgroundProps {
  width: number;
  height: number;
  style?: React.CSSProperties;
}

const NameBackground: FC<NameBackgroundProps> = ({ width, height, style = {} }) => {
  const color = '#ff2727';
  const cutCornerOffset = 8;
  const point1 = `${cutCornerOffset} 0`;
  const point2 = `${width} 0`;
  const point3 = `${width} ${height - cutCornerOffset}`;
  const point4 = `${width - cutCornerOffset} ${height}`;
  const point5 = `0 ${height}`;
  const point6 = `0 ${cutCornerOffset}`;

  return (
    <Styled.Root style={{ ...style }}>
      <svg width={'100%'} height={'100%'} viewBox={`0 0 ${width} ${height}`}>
        <path
          id="polygon-container-outline"
          data-name="polygon-container-outline"
          d={`M${point1} L${point2} L${point3} L${point4} L${point5} L${point6} L${point1} Z`}
          // strokeWidth={0}
          // stroke={color}
          strokeLinejoin="miter"
          fill={color}
        />
      </svg>
    </Styled.Root>
  );
};

export default NameBackground;
