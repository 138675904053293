import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Styled from './Media.styles';
import PlayIcon from '../icons/PlayIcon';
import MediaPlaceholder from './MediaPlaceholder';

export interface IMediaProps {
  size: 'small' | 'medium' | 'default' | 'full-width' | 'full-screen';
  source?: string;
  alt?: string;
  active?: boolean;
  isVideo?: boolean;
  aspectRatio?: '1:1' | '2:1' | '4:3' | '3:2' | '16:9' | 'initial';
  portrait?: boolean;
  borderRadius?: CSSProperties['borderRadius'];
  width?: CSSProperties['width'];
  border?: CSSProperties['border'];
  height?: CSSProperties['height'];
  minWidth?: CSSProperties['minWidth'];
  minHeight?: CSSProperties['minHeight'];
  maxWidth?: CSSProperties['maxWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  onClick?: VoidFunction;
}

const Media: FC<IMediaProps> = ({
  size,
  source,
  alt,
  active = false,
  isVideo = false,
  aspectRatio = 'initial',
  borderRadius = '8px',
  border,
  portrait,
  height,
  width,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  onClick,
}) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const imageReference = useRef<HTMLImageElement | null>(null);
  const imageSource = typeof source === 'string' || source === undefined ? source : URL.createObjectURL(source);

  const handleOnLoad = useCallback(() => setLoaded(true), []);
  const iconSize = useMemo(() => {
    if (size === 'small') {
      return '32px';
    }

    if (size === 'medium') {
      return '56px';
    }

    return '72px';
  }, [size]);

  const imageAspectRatio = useMemo(() => {
    switch (aspectRatio) {
      case '1:1':
        return 1;
      case '2:1':
        return portrait ? 1 / 2 : 2;
      case '3:2':
        return portrait ? 2 / 3 : 3 / 2;
      case '4:3':
        return portrait ? 3 / 4 : 4 / 3;
      case '16:9':
        return portrait ? 9 / 16 : 16 / 9;
      default:
        return 'initial';
    }
  }, [portrait, aspectRatio]);

  useEffect(() => {
    if (imageReference.current && imageReference.current?.complete && source !== undefined && source !== '') {
      setLoaded(true);
      return;
    }
    setLoaded(false);
  }, [source]);

  if (!source) {
    return (
      <MediaPlaceholder
        onClick={onClick}
        size={size}
        height={height}
        width={width}
        active={active}
        border={border}
        borderRadius={borderRadius}
        isVideo={isVideo}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        minWidth={minWidth}
        minHeight={minHeight}
        aspectratio={imageAspectRatio.toString()}
      />
    );
  }

  return (
    <Styled.Root active={+active} borderradius={borderRadius} onClick={onClick}>
      <Styled.Image
        maxheight={maxHeight}
        minheight={minHeight}
        maxwidth={maxWidth}
        minwidth={minWidth}
        height={height}
        width={width}
        alt={alt}
        loading="lazy"
        onLoad={handleOnLoad}
        ref={imageReference}
        src={imageSource}
        loaded={+loaded}
        aspectratio={imageAspectRatio.toString()}
      />
      {isVideo && loaded && <Styled.Icon icon={<PlayIcon />} width={iconSize} height={iconSize} />}
    </Styled.Root>
  );
};

export default Media;
