import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  display: inline-flex;
  width: auto;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1.4rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: ${customThemeOptions.custom.zIndex.gameOverlay.swiperNavigation};

  & svg {
    display: inline-flex;
    width: 1.5rem;
    height: 1.5rem;
    margin: 1px 0.5rem 0 0.5rem;
    transition: opacity 0.3s ease-in-out;
  }

  & .swiper-nav-disabled {
    opacity: 0.3;
  }

  @media (max-width: 1000px) {
    bottom: 0.8rem;

    & svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  @media (max-height: 700px) {
    bottom: 0.8rem;

    & svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
`;

const SlidePosition = styled('span')`
  display: inline-block;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 0.9rem;
  color: #ffffff;

  @media (max-width: 1000px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
`;

export default {
  Root,
  SlidePosition,
};
