import DOMPurify from 'dompurify';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from 'src/components/pages/auth/Auth.styles';
import { nameSpace } from 'src/modules/i18n';

const Auth: FC = () => {
  const { t } = useTranslation(nameSpace.auth);

  return (
    <Styled.Root>
      <Styled.Copy dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('auth.copy')) }} />
    </Styled.Root>
  );
};

export default Auth;
