import { styled } from '@mui/material';
import { isMobile } from 'react-device-detect';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  background-color: #ffffff22;
  display: block;
  width: ${isMobile ? '2rem' : '2.2rem'};
  height: ${isMobile ? '2rem' : '2.2rem'};
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  z-index: ${customThemeOptions.custom.zIndex.game.exitTheatreModeButton};
  border-radius: 0.3rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  & .material-icons-outlined {
    font-size: ${isMobile ? '1.3rem' : '1.4rem'};
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    background-color: #ffffff;

    & .material-icons-outlined {
      color: #000000;
    }
  }
`;

export default {
  Root,
};
