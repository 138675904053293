import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { formatPrice } from 'src/tools/formatPrice';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { config } from 'src/config/config';
import Styled from 'src/components/primary-marketplace/components/SecondaryPrice.styles';

interface SecondaryPriceProps {
  lpmId: string;
}

const SecondaryPrice: FC<SecondaryPriceProps> = ({ lpmId }) => {
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));

  // Variables to determine market state and user purchase state
  const { strikeThroughPrice } = listPriceMarket;
  const strikethroughPriceUSD = formatPrice(strikeThroughPrice * config.app_variables.lc_conversion, {
    decimals: 2,
  });

  if (!strikeThroughPrice && strikeThroughPrice !== 0) return null;

  return (
    <Styled.Root>
      <Styled.SecondaryPriceContainer>
        <Styled.SecondaryPrice>
          <span>{strikethroughPriceUSD}</span>
          <span>USD</span>
        </Styled.SecondaryPrice>

        <Styled.Strikethrough />
      </Styled.SecondaryPriceContainer>
    </Styled.Root>
  );
};

export default SecondaryPrice;
