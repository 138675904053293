import { copyCurrencyState } from 'src/redux/slices/currency-states/helpers/copy-currency-state';

export const copyCurrencyStates = (currencyStates: CurrencyState[]): CurrencyStatesKC => {
  const returnedCurrencyStates: CurrencyStatesKC = {};

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < currencyStates.length; i += 1) {
    const currencyState: CurrencyState = currencyStates[i];
    const id = currencyState.id as string;

    if (currencyState) {
      returnedCurrencyStates[id] = copyCurrencyState(currencyState);
    }
  }

  return returnedCurrencyStates;
};
