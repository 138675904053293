import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-flex;
  /* width: 100%; */
  position: absolute;
  /* bottom: 13px; */
  top: calc(50% + 2px);
  left: 0;
  transform: translateY(-50%);
  justify-content: flex-start;

  @media (max-height: 350px) {
    top: 50%;
  }
`;

interface ButtonProps {
  color: string;
}

const Button = styled('div')<ButtonProps>`
  display: block;
  width: 2rem;
  height: 2rem;
  border: 1px solid ${({ color }) => `${color}ff`};
  border-radius: 0rem;
  transition: background-color 0.3s ease-in-out;
  position: relative;

  & .material-icons,
  & .material-icons-outlined {
    font-size: 1.3rem;
    font-weight: 200;
    color: ${({ color }) => `${color}ff`};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    background-color: ${({ color }) => `${color}77`};

    & .material-icons,
    & .material-icons-outlined {
      color: ${({ color }) => `${color}ff`};
    }
  }

  &:active {
    background-color: ${({ color }) => `${color}99`};

    & .material-icons,
    & .material-icons-outlined {
      color: ${({ color }) => `${color}ff`};
    }
  }

  @media (max-width: 900px) or (max-height: 700px) {
    width: 1.8rem;
    height: 1.8rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  @media (max-height: 400px) {
    width: 1.3rem;
    height: 1.3rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.9rem;
    }
  }

  @media (max-height: 350px) {
    width: 1.3rem;
    height: 1.3rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.9rem;
    }
  }
`;

export default {
  Root,
  Button,
};
