import { FC } from 'react';
import Styled from 'src/components/pages/nft/components/Stats.styles';

interface StatsProps {
  stats: HeroStat[];
  emptyText: string;
}

const Stats: FC<StatsProps> = ({ emptyText, stats }) => {
  if (!stats || stats.length === 0) return <Styled.EmptyText>{emptyText}</Styled.EmptyText>;

  return (
    <Styled.Root>
      {stats && stats.length > 0 && (
        <Styled.StatsContainer>
          {stats.map((stat, i) => {
            const splitValue = stat?.value?.toString().split('.');

            if (splitValue.length === 0) return null;

            return (
              <Styled.StatRow key={`${stat.type}-${i}`}>
                <Styled.StatLabel>
                  {stat.type}
                  <Styled.StatSeparator>|</Styled.StatSeparator>
                </Styled.StatLabel>

                <Styled.StatValue>
                  <span>{splitValue[0]}</span>
                  {/* {splitValue.length > 1 ? <span>{`.${splitValue[1]}`}</span> : <span>{'.0'}</span>} */}
                </Styled.StatValue>
              </Styled.StatRow>
            );
          })}
        </Styled.StatsContainer>
      )}
    </Styled.Root>
  );
};

export default Stats;
