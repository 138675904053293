import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { isMobile } from 'react-device-detect';
import { PooleEvents } from 'src/modules/poole/events';
import Player from '@vimeo/player';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useTutorialContext from 'src/hooks/useTutorialContext';
import { openPackNonfungible } from 'src/redux/slices/nonfungibles/nonfungibles';
import { addCollectionItems, removeCollectionItems } from 'src/redux/slices/collection-items/collection-items';
import { openPack } from 'src/api/profile/open-pack';
import nestedSort from 'src/tools/nestedSort';
import { getNFTImage } from 'src/tools/getNFTImage';
import BaseModal from 'src/components/modals/BaseModal';
import ButtonHex from 'src/components/input/ButtonHex';
import { Modal, Backdrop, Fade, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import { logMain } from 'src/modules/logger/logger';
import Styled from 'src/components/modals/PackOpeningModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

type Tier = 'starter' | 'alpha' | 'bronze' | 'silver' | 'gold';
type ButtonVariant = 'bronze' | 'silver' | 'gold' | 'white';

const getButtonVariantFromTier = (tier: Tier): ButtonVariant => {
  switch (tier) {
    case 'starter':
      return 'white';
    case 'alpha':
      return 'white';
    case 'bronze':
      return 'bronze';
    case 'silver':
      return 'silver';
    case 'gold':
      return 'gold';
    default:
      return 'bronze';
  }
};

const cardAnimationClasses1 = [''];
const cardAnimationClasses3 = ['slideLeft', 'slideRight', ''];
const cardAnimationClasses5 = ['slideOuterLeft', 'slideOuterRight', 'slideInnerLeft', 'slideInnerRight', ''];

interface PackOpeningModalProps {
  id: string;
  tier: Tier;
  open: boolean;
  setOpen: (open: boolean) => void;
  videoOpenUrl: string;
  videoLoopUrl: string;
}

const PackOpeningModal: FC<PackOpeningModalProps> = ({ id, tier, open, setOpen, videoOpenUrl, videoLoopUrl }) => {
  const packIdRef = useRef<string>(id);
  const openInitiated = useRef<boolean>(false);
  const oldOpenPackTutorialState = useRef<boolean | null>(null);
  const cards = useRef<CollectionItem[]>([]);
  const { closeGameOverlay, enableGameOverlay } = useGameOverlayContext();
  const { tutorialState } = useTutorialContext();
  const [isLoading, setIsLoading] = useState(true);
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [error, setError] = useState('');
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(nameSpace.common);
  const dispatch = useAppDispatch();
  // const vimeoSources = getVimeoSourcesFromTier(tier);

  useEffect(() => {
    // Set the tutorial state tracking
    if (open && tutorialState.starterOpenPack && !oldOpenPackTutorialState.current) {
      oldOpenPackTutorialState.current = tutorialState.starterOpenPack;
    }
  }, [open, tutorialState.starterOpenPack]);

  const initializeAnimations = useCallback(() => {
    const iframeLoop: HTMLIFrameElement | null = document.querySelector(`#iframe-loop-${packIdRef.current}`);
    const iframeOpen: HTMLIFrameElement | null = document.querySelector(`#iframe-open-${packIdRef.current}`);

    if (iframeLoop && iframeOpen) {
      const playerLoop = new Player(iframeLoop);
      const playerOpen = new Player(iframeOpen);

      // Function to play video 1 (opening pack)
      const handlePlay = () => {
        iframeOpen.style.opacity = '1';
        playerOpen.play();
      };

      // Function to show cards
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handleShowCards = (data: any) => {
        if (data.seconds >= 5.5) {
          const cardsContainerEl: HTMLDivElement | null = document.querySelector(
            `#cards-container-${packIdRef.current}`,
          );

          if (cardsContainerEl) {
            const cardElArr = cardsContainerEl.querySelectorAll(`:scope > img`);

            if (cardElArr && cardElArr.length > 0) {
              const reversedCardElArr = Array.from(cardElArr).reverse();

              if (reversedCardElArr.length === 5) {
                reversedCardElArr.forEach((card, i) => {
                  if (cardAnimationClasses5[i]) {
                    card.classList.add(cardAnimationClasses5[i]);
                  }

                  if (i === 4) {
                    cardsContainerEl.style.opacity = '1';
                  }
                });
              } else if (reversedCardElArr.length === 3) {
                reversedCardElArr.forEach((card, i) => {
                  if (cardAnimationClasses3[i]) {
                    card.classList.add(cardAnimationClasses3[i]);
                  }

                  if (i === 2) {
                    cardsContainerEl.style.opacity = '1';
                  }
                });
              } else if (reversedCardElArr.length === 1) {
                reversedCardElArr.forEach((card, i) => {
                  if (cardAnimationClasses1[i]) {
                    card.classList.add(cardAnimationClasses1[i]);
                  }

                  if (i === 0) {
                    cardsContainerEl.style.opacity = '1';
                  }
                });
              }
            }

            playerOpen.off('timeupdate');
          }
        }
      };

      // Function to play video 2 (loop) & remove event listener
      const handleEnded = () => {
        // Hide video 1 (opening pack) & show video 2 (loop)
        iframeOpen.style.opacity = '0';
        iframeLoop.style.opacity = '1';

        // Play video 2 (loop) & remove event listener
        playerLoop.play();
        playerOpen.off('ended');
      };

      // Add event listeners to video 1 (opening pack) to listen for loading & ending
      playerOpen.on('loaded', handlePlay);
      playerOpen.on('timeupdate', handleShowCards);
      playerOpen.on('ended', handleEnded);
    }
  }, []);

  useEffect(() => {
    if (open && !openInitiated.current) {
      openInitiated.current = true;

      // Fire Poole event for open pack confirm
      PooleEvents.OpenPackConfirm({
        dataset: enableGameOverlay ? 'unity' : 'website',
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'open_pack_modal',
        nonfungibleId: id || '',
        packTier: tier || '',
      });

      dispatch(openPack({ data: { id } }))
        .then(data => {
          if (data?.items && data.items.length > 0) {
            // Fire Poole event for open pack confirm success
            PooleEvents.OpenPackConfirmSuccess({
              dataset: enableGameOverlay ? 'unity' : 'website',
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'open_pack_modal',
              nonfungibleId: id || '',
              packTier: tier || '',
            });

            const sortedCards = nestedSort(data.items, -1, 'heroRarity');

            cards.current = sortedCards;

            setIsLoading(false);
            initializeAnimations();

            setTimeout(() => {
              setShowClaimButton(true);
              setShowCloseButton(true);
            }, 9500);
          } else {
            throw new Error('Failed to open pack.');
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug(e);

          // Fire Poole event for open pack confirm fail
          PooleEvents.OpenPackConfirmFail({
            dataset: enableGameOverlay ? 'unity' : 'website',
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'open_pack_modal',
            nonfungibleId: id || '',
            packTier: tier || '',
            reason: e instanceof Error ? e.message : String(e),
          });

          setIsLoading(false);
          setError(t('common.page.error.description'));
        });
    }
  }, [
    currentProfile.id,
    dispatch,
    enableGameOverlay,
    id,
    initializeAnimations,
    open,
    t,
    tier,
    tutorialState.starterOpenPack,
  ]);

  const handleClose = useCallback(() => {
    if (showClaimButton) {
      PooleEvents.OpenPackClaim({
        dataset: enableGameOverlay ? 'unity' : 'website',
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'open_pack_modal',
        nonfungibleId: id || '',
        packTier: tier || '',
      });
    }

    PooleEvents.OpenPackClose({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'open_pack_modal',
      nonfungibleId: id || '',
      packTier: tier || '',
    });

    setIsLoading(true);
    setShowClaimButton(false);
    setShowCloseButton(false);
    setOpen(false);
    setError('');

    dispatch(addCollectionItems(cards.current));
    dispatch(openPackNonfungible(id));
    dispatch(removeCollectionItems([id]));

    if (enableGameOverlay && oldOpenPackTutorialState.current) {
      closeGameOverlay();
    }

    // Reset the tutorial state tracking
    oldOpenPackTutorialState.current = null;
  }, [closeGameOverlay, currentProfile.id, dispatch, enableGameOverlay, id, setOpen, showClaimButton, tier]);

  if (!id) return null;

  return (
    <Modal
      aria-labelledby={`pack-opening-modal-${id}`}
      aria-describedby={`pack-opening-modal-${id}-desc`}
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.packOpening}` }}
    >
      {error ? (
        <Fade in={open}>
          <BaseModal
            open={error !== ''}
            onClose={handleClose}
            heading={t('common.page.error.title')}
            subheading={t('common.page.error.description')}
            secondaryButton={t('common.term.close')}
            secondaryOnClick={handleClose}
          />
        </Fade>
      ) : (
        <Fade in={open}>
          <Styled.Root>
            <Styled.AnimationContainer>
              {isLoading && (
                <Styled.ProgressContainer>
                  <CircularProgress
                    color="inherit"
                    size={'1.5rem'}
                    sx={{
                      display: 'block',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </Styled.ProgressContainer>
              )}

              <Styled.AnimationIFrame
                id={`iframe-open-${id}`}
                src={isMobile && videoOpenUrl ? videoOpenUrl.replace('muted=0', 'muted=1') : videoOpenUrl}
                frameBorder="0"
                width="100%"
                height="100%"
                allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
                allowFullScreen
                title="Pack opening animation"
              />

              <Styled.AnimationIFrame
                id={`iframe-loop-${id}`}
                src={isMobile && videoLoopUrl ? videoLoopUrl.replace('muted=0', 'muted=1') : videoLoopUrl}
                frameBorder="0"
                width="100%"
                height="100%"
                allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
                allowFullScreen
                title="Pack opened loop animation"
              />

              <Styled.CardsContainer id={`cards-container-${id}`}>
                {cards.current.map((card: CollectionItem, i) => {
                  if (!card) return null;

                  return (
                    <Styled.Card
                      key={`card-${id}-${i}`}
                      // className={i === 0 ? 'blur' : ''}
                      src={getNFTImage(`${card?.imageUrl as string}`)}
                      width="800"
                      height="1200"
                      alt={t('common.card.alt')}
                    />
                  );
                })}
              </Styled.CardsContainer>

              <Styled.ButtonContainer className={showClaimButton ? 'fadeIn' : ''}>
                <ButtonHex
                  hoverEffect
                  variant={getButtonVariantFromTier(tier)}
                  width={mobileBreakpoint ? 140 : 200}
                  height={mobileBreakpoint ? 36 : 50}
                  cornerOffset={14}
                  title={t('common.pack.open.modal.button')}
                  textStyle={{ fontSize: mobileBreakpoint ? '0.7rem' : '.8rem' }}
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  onClick={handleClose}
                />
              </Styled.ButtonContainer>

              <Styled.CloseButton onClick={handleClose} className={showCloseButton ? 'fadeIn' : ''}>
                <span className="material-icons">close</span>
              </Styled.CloseButton>
            </Styled.AnimationContainer>
          </Styled.Root>
        </Fade>
      )}
    </Modal>
  );
};

export default PackOpeningModal;
