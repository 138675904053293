import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface RootProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
  show: boolean;
  theatreMode: boolean;
}

const Root = styled('div')<RootProps>`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theatreMode }) =>
    theatreMode
      ? customThemeOptions.custom.zIndex.game.theatreMode
      : customThemeOptions.custom.zIndex.game.notTheatreMode};
`;

interface UnderHeaderContainerProps {
  theatreMode: boolean;
}

const UnderHeaderContainer = styled('div')<UnderHeaderContainerProps>`
  /* background-color: #00000011; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  top: ${({ theatreMode }) => (theatreMode ? '0' : '5rem')};
  right: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 1000px) {
    top: ${({ theatreMode }) => (theatreMode ? '0' : '4.5rem')};
  }
`;

interface GameGradientContainerProps {
  colors: {
    gradientColor1: string;
    gradientColor2: string;
  };
  theatreMode: boolean;
  dimensions: { width: string; height: string };
}

const GameGradientContainer = styled('div')<GameGradientContainerProps>`
  background: ${({ colors, theatreMode }) =>
    theatreMode ? 'none' : `linear-gradient(180deg, ${colors.gradientColor1} 0%, ${colors.gradientColor2} 100%)`};
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  width: ${({ theatreMode, dimensions }) => (theatreMode ? '100%' : dimensions.width)};
  max-width: ${({ theatreMode }) => (theatreMode ? 'none' : '85rem')};
  height: ${({ theatreMode }) => (theatreMode ? '100%' : 'auto')};
  padding: ${({ theatreMode }) => (theatreMode ? '0' : '4px')};
  margin: 0 auto;
  aspect-ratio: ${({ theatreMode }) => (theatreMode ? 'auto' : 'calc(16 / 9)')};
  box-sizing: border-box;
  box-shadow: ${({ theatreMode }) => (theatreMode ? 'none' : '0 0 48px 48px #000000cc')};
  transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
  position: relative;
`;

interface GameContainerProps {
  theatreMode?: boolean;
  dimensions: { width: string; height: string };
}

const GameContainer = styled('div')<GameContainerProps>`
  background-color: #000000;
  display: block;
  width: ${({ theatreMode, dimensions }) => (theatreMode ? dimensions.width : '100%')};
  height: auto;
  aspect-ratio: calc(16 / 9);
  margin-top: ${({ theatreMode }) => (theatreMode ? '0' : '-0.5px')};
  overflow: visible;
  position: relative;
`;

const NoGameOverlayContainer = styled('div')`
  background-color: #000000;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NoGameContainer = styled('div')`
  display: block;
  width: 80%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const NoGameTitle = styled('div')`
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.9rem;
  color: #ffffff;

  & > span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 500px) {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4rem;
  }

  @media (max-height: 500px) {
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4rem;
  }
`;

const NoGameSubtitle = styled('div')`
  display: block;
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: #ffffff;

  @media (max-width: 500px) {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.1rem;
  }

  @media (max-height: 500px) {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.1rem;
  }
`;

export default {
  Root,
  UnderHeaderContainer,
  GameGradientContainer,
  GameContainer,
  NoGameOverlayContainer,
  NoGameContainer,
  NoGameTitle,
  NoGameSubtitle,
};
