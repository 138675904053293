import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyCollectionItems } from 'src/redux/slices/collection-items/helpers/copy-collection-items';

// Define the initial state using that type
const initialState: CollectionItemsState = {
  collectionItems: [],
};

const collectionItemsSlice = createSlice({
  name: 'collectionItems',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCollectionItems: {
      reducer(state, action: PayloadAction<CollectionItemsPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.collectionItems = copyCollectionItems(action.payload.collectionItems);
      },
      prepare(collectionItems: CollectionItem[]) {
        return {
          payload: {
            collectionItems,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    addCollectionItems: {
      reducer(state, action: PayloadAction<CollectionItemsPayload>) {
        const existingItems = state.collectionItems;
        const newItems = action.payload.collectionItems || [];
        const allItems = [...existingItems, ...newItems];

        // eslint-disable-next-line no-param-reassign
        state.collectionItems = copyCollectionItems(allItems);
      },
      prepare(collectionItems: CollectionItem[]) {
        return {
          payload: {
            collectionItems,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    removeCollectionItems: {
      reducer(state, action: PayloadAction<{ collectionItemIds: string[] }>) {
        const existingItems = state.collectionItems;
        const filteredItems = existingItems.filter(item => !action.payload.collectionItemIds.includes(item.id));

        // eslint-disable-next-line no-param-reassign
        state.collectionItems = copyCollectionItems(filteredItems);
      },
      prepare(collectionItemIds: string[]) {
        return {
          payload: {
            collectionItemIds,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    listCollectionItem: {
      reducer(state, action: PayloadAction<{ id: string; price: number }>) {
        const existingItems = copyCollectionItems(state.collectionItems);
        const listedItemIndex = existingItems.findIndex(item => action.payload.id === item.id);

        if (listedItemIndex !== -1) {
          existingItems[listedItemIndex].listingId = null;
          existingItems[listedItemIndex].listable = false;
          existingItems[listedItemIndex].listed = true;
          existingItems[listedItemIndex].price = action.payload.price;

          if (existingItems[listedItemIndex].type === 'pack') {
            existingItems[listedItemIndex].packOpenable = false;
          }
        }

        // eslint-disable-next-line no-param-reassign
        state.collectionItems = existingItems;
      },
      prepare(id: string, price: number) {
        return {
          payload: {
            id,
            price,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    removeListedCollectionItem: {
      reducer(state, action: PayloadAction<{ id: string }>) {
        const existingItems = copyCollectionItems(state.collectionItems);
        const listedItemIndex = existingItems.findIndex(item => action.payload.id === item.id);

        if (listedItemIndex !== -1) {
          existingItems[listedItemIndex].listingId = null;
          existingItems[listedItemIndex].listable = true;
          existingItems[listedItemIndex].listed = false;
          existingItems[listedItemIndex].price = null;

          if (existingItems[listedItemIndex].type === 'pack') {
            existingItems[listedItemIndex].packOpenable = true;
          }
        }

        // eslint-disable-next-line no-param-reassign
        state.collectionItems = existingItems;
      },
      prepare(id: string) {
        return {
          payload: {
            id,
          },
        };
      },
    },
    clearCollectionItems: state => {
      // eslint-disable-next-line no-param-reassign
      state.collectionItems = initialState.collectionItems;
    },
  },
});

export const {
  setCollectionItems,
  addCollectionItems,
  removeCollectionItems,
  listCollectionItem,
  removeListedCollectionItem,
  clearCollectionItems,
} = collectionItemsSlice.actions;

// Other Selectors
export const getCollectionItemsByPlayerId = (state: RootState, _playerId: string): CollectionItem[] =>
  state.collectionItems.collectionItems.filter((item: CollectionItem) => item.playerId === _playerId);
export const getCollectionItemById = (state: RootState, id: string): CollectionItem | null =>
  state.collectionItems.collectionItems.find((item: CollectionItem) => item.id === id) || null;

export default collectionItemsSlice.reducer;
