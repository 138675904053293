import { FC, useEffect, useState } from 'react';
import useBlogContext from 'src/hooks/useBlogContext';
import PageError from 'src/components/feedback/PageError';
import SectionHero from 'src/components/pages/blog-details/components/SectionHero';
import SectionDetails from 'src/components/pages/blog-details/components/SectionDetails';
import Styled from 'src/components/pages/blog-details/BlogDetails.styles';

const BlogDetails: FC = () => {
  const { setLoadBlogPosts, isLoadingBlogPosts, blogPosts } = useBlogContext();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loading) {
      setLoadBlogPosts(true);
      setLoading(false);
    }
  }, [loading, setLoadBlogPosts]);

  // If data is not loaded return blank page
  if (loading || isLoadingBlogPosts) return <Styled.Root />;
  // If data loaded incorrectly return page error
  if (!loading && blogPosts.length === 0) return <PageError />;

  return (
    <Styled.Root>
      <SectionHero />
      <SectionDetails />
    </Styled.Root>
  );
};

export default BlogDetails;
