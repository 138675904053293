import { styled, keyframes } from '@mui/material';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: auto;
  }
`;

const slideLeft = keyframes`
  0% {
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  100% {
    left: 12.5%;
    right: auto;
    transform: translate(0, -50%);
  }
`;

const slideRight = keyframes`
  0% {
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  1% {
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  3% {
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  100% {
    left: auto;
    right: 12.5%;
    transform: translate(0, -50%);
  }
`;

const slideInnerLeft = keyframes`
  0% {
    height: 62%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  100% {
    height: 35%;
    left: 22%;
    right: auto;
    transform: translate(0, -50%);
  }
`;

const slideInnerRight = keyframes`
  0% {
    height: 62%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  1% {
    height: 62%;
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  3% {
    height: 62%;
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  100% {
    height: 35%;
    left: auto;
    right: 22%;
    transform: translate(0, -50%);
  }
`;

const slideOuterLeft = keyframes`
  0% {
    height: 62%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  100% {
    height: 35%;
    left: 6%;
    right: auto;
    transform: translate(0, -50%);
  }
`;

const slideOuterRight = keyframes`
  0% {
    height: 62%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  1% {
    height: 62%;
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  3% {
    height: 62%;
    left: auto;
    right: 50%;
    transform: translate(50%, -50%);
  }
  100% {
    height: 35%;
    left: auto;
    right: 6%;
    transform: translate(0, -50%);
  }
`;

const Root = styled('div')`
  display: block;
  width: 95%;
  max-width: 85rem;
  height: auto;
  box-sizing: border-box;
  border-radius: 0.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ProgressContainer = styled('div')`
  display: block;
  margin-top: 1.5rem;
  text-align: center;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const AnimationContainer = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
  position: relative;
`;

const AnimationIFrame = styled('iframe')`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: visible;

  &:first-of-type {
    transition: opacity 0.3s ease-in-out;
  }
`;

const CardsContainer = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
`;

const Card = styled('img')`
  background-color: #000000;
  display: block;
  height: 62%;
  width: auto;
  box-shadow: 0 0 9px 4px #00000055;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* &.blur {
    filter: blur(13px);
  } */

  &.slideLeft {
    animation: ${slideLeft} 0.6s ease-in-out 1.5s 1 forwards;
  }

  &.slideRight {
    animation: ${slideRight} 0.6s ease-in-out 2.1s 1 forwards;
  }

  &.slideOuterLeft {
    animation: ${slideOuterLeft} 0.6s ease-in-out 1.5s 1 forwards;
  }

  &.slideOuterRight {
    animation: ${slideOuterRight} 0.6s ease-in-out 2s 1 forwards;
  }

  &.slideInnerLeft {
    animation: ${slideInnerLeft} 0.6s ease-in-out 2.5s 1 forwards;
  }

  &.slideInnerRight {
    animation: ${slideInnerRight} 0.6s ease-in-out 3s 1 forwards;
  }
`;

const ButtonContainer = styled('div')`
  display: block;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  pointer-events: none;

  &.fadeIn {
    animation: ${fadeIn} 0.6s ease-in-out 0s 1 forwards;
  }

  @media (max-width: 1000px) {
    bottom: 1.5rem;
  }

  @media (max-width: 768px) {
    bottom: 1rem;
  }
`;

const CloseButton = styled('div')`
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 1rem;
  right: 1rem;
  opacity: 0;
  pointer-events: none;

  &.fadeIn {
    animation: ${fadeIn} 0.6s ease-in-out 0s 1 forwards;
  }

  & .material-icons {
    display: block;
    font-size: 1.4rem;
    color: white;
    position: absolute;
    top: 52%;
    left: 52%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
      font-size: 1rem;
      top: 51%;
      left: 51%;
    }
  }

  &:hover {
    background-color: #ffffff22;
  }

  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

export default {
  Root,
  ProgressContainer,
  AnimationContainer,
  AnimationIFrame,
  CardsContainer,
  Card,
  ButtonContainer,
  CloseButton,
};
