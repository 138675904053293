import { FC } from 'react';
import useBroadcastChannelContext from 'src/hooks/useBroadcastChannelContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import Styled from 'src/components/game/ScreenshotButton.styles';
// import { logMain } from 'src/modules/logger/logger';

interface Colors {
  gradientColor1: string;
  gradientColor2: string;
}

interface ScreenshotButtonProps {
  colors: Colors;
}

const ScreenshotButton: FC<ScreenshotButtonProps> = ({ colors }) => {
  const { sendBroadcastMessage } = useBroadcastChannelContext();
  const { unityClientLoaded } = useGameClientContext();

  const handleTakeScreenshot = () => {
    sendBroadcastMessage({ captureScreenshot: true });
  };

  if (!unityClientLoaded) return null;

  return (
    <Styled.Root colors={colors} onClick={handleTakeScreenshot}>
      <span className="material-icons-outlined">camera_alt</span>
    </Styled.Root>
  );
};

export default ScreenshotButton;
