import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { copyListPriceMarkets } from './helpers/copy-list-price-markets';
import { copyListPriceMarket } from './helpers/copy-list-price-market';

// Define the initial state using that type
const initialState: ListPriceMarketsState = {
  listPriceMarkets: {},
};

const listPriceMarketsSlice = createSlice({
  name: 'listPriceMarkets',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setListPriceMarkets: {
      reducer(state, action: PayloadAction<ListPriceMarketsPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.listPriceMarkets = copyListPriceMarkets(action.payload.listPriceMarkets);
      },
      prepare(listPriceMarkets: ListPriceMarket[]) {
        return {
          payload: {
            listPriceMarkets,
          },
        };
      },
    },
    addListPriceMarkets: {
      reducer(state, action: PayloadAction<ListPriceMarketsPayload>) {
        const existingListPriceMarkets: ListPriceMarketsKC = state.listPriceMarkets;
        const existingItemsArr = Object.keys(existingListPriceMarkets).map((listPriceMarketId: ListPriceMarketId) => {
          return existingListPriceMarkets[listPriceMarketId];
        });

        // eslint-disable-next-line no-param-reassign
        state.listPriceMarkets = copyListPriceMarkets(existingItemsArr.concat(action.payload.listPriceMarkets));
      },
      prepare(listPriceMarkets: ListPriceMarket[]) {
        return {
          payload: {
            listPriceMarkets,
          },
        };
      },
    },
    updateListPriceMarketFromPurchase: {
      reducer(state, action: PayloadAction<UpdateListPriceMarketFromPurchasePayload>) {
        const existingListPriceMarkets: ListPriceMarketsKC = state.listPriceMarkets;
        const existingItemsArr = Object.keys(existingListPriceMarkets).map((listPriceMarketId: ListPriceMarketId) => {
          const mappedListPriceMarket = copyListPriceMarket(existingListPriceMarkets[listPriceMarketId]);
          const { purchaseCount, availableCount, availableAndPendingCount, priceCurrencyBalance } =
            mappedListPriceMarket;

          if (mappedListPriceMarket.id === action.payload.listPriceMarketId) {
            // Update purchaseCount
            mappedListPriceMarket.purchaseCount = purchaseCount + 1;

            // Update availableAndPendingCount
            mappedListPriceMarket.availableAndPendingCount =
              availableAndPendingCount !== null && availableAndPendingCount > 0
                ? availableAndPendingCount - 1
                : availableAndPendingCount;

            // Update availableCount
            mappedListPriceMarket.availableCount =
              availableCount !== null && availableCount > 0 ? availableCount - 1 : availableCount;

            // Update priceCurrencyBalance
            mappedListPriceMarket.priceCurrencyBalance =
              priceCurrencyBalance !== null && priceCurrencyBalance > 0
                ? priceCurrencyBalance - 1
                : priceCurrencyBalance;
          }

          return mappedListPriceMarket;
        });

        const newListPriceMarkets = copyListPriceMarkets(existingItemsArr);

        // eslint-disable-next-line no-param-reassign
        state.listPriceMarkets = newListPriceMarkets;
      },
      prepare(listPriceMarketId: ListPriceMarketId) {
        return {
          payload: {
            listPriceMarketId,
          },
        };
      },
    },
    clearListPriceMarkets: state => {
      // eslint-disable-next-line no-param-reassign
      state.listPriceMarkets = {};
    },
  },
});

export const { setListPriceMarkets, addListPriceMarkets, updateListPriceMarketFromPurchase, clearListPriceMarkets } =
  listPriceMarketsSlice.actions;

// Other Selectors
export const getListPriceMarketsMap = (state: RootState): ListPriceMarketsKC => state.listPriceMarkets.listPriceMarkets;
export const getListPriceMarketsArr = (state: RootState): ListPriceMarket[] => {
  const { listPriceMarkets }: { listPriceMarkets: ListPriceMarketsKC } = state.listPriceMarkets;
  return Object.keys(listPriceMarkets).map((key: ListPriceMarketId) => listPriceMarkets[key]);
};
export const getListPriceMarketByID = (state: RootState, id: string): ListPriceMarket => {
  return state.listPriceMarkets.listPriceMarkets[id];
};
export const getDropEventListPriceMarkets = (state: RootState, dropEventId: DropEventId): ListPriceMarket[] => {
  const listPriceMarkets: ListPriceMarket[] = getListPriceMarketsArr(state);
  return listPriceMarkets.filter(market => market.dropEventId === dropEventId);
};

export default listPriceMarketsSlice.reducer;
