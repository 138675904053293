import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { useQuery } from 'src/hooks/useQuery';
import usePendingPurchasesContext from 'src/hooks/usePendingPurchasesContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import Alert from 'src/components/feedback/Alert';
// import TutorialCursor from 'src/components/tutorial-cursor/TutorialCursor';
import Styled from 'src/components/pages/profile/components/Collection.styles';
import Collectibles from 'src/components/collectibles/Collectibles';

interface Title {
  default: string;
  empty: string;
}

interface CollectionProps {
  tab: number;
}

const Collection: FC<CollectionProps> = ({ tab }) => {
  const { playerId = '' } = useParams();
  const params: (string | null)[] = useQuery(urlParams.profile);
  const fromPurchase = getURLParamValue('from_purchase', urlParams.profile, params);
  const { loadPendingPurchases, setLoadPendingPurchases, concurrentPurchaseLimit } = usePendingPurchasesContext();
  const [loading, setLoading] = useState(true);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const pendingPurchases = useAppSelector(({ pendingPurchases }) => pendingPurchases.pendingPurchases);
  const collectionItems = useAppSelector(({ collectionItems }) => collectionItems.collectionItems);
  const navigateInternal = useAppNavigateInternal();
  const { t: tProfile } = useTranslation(nameSpace.profile);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const titles: Title[] = tProfile(
    isCurrentProfile ? 'profile.tab.results.titles' : 'profile.tab.results.titles.other.user',
    { returnObjects: true },
  );
  const packs = collectionItems.filter(item => item.type === 'pack' && !item.listed);
  const heroes = collectionItems.filter(item => item.type === 'hero' && !item.listed);
  const listedPacks = collectionItems.filter(item => item.type === 'pack' && item.listed);
  const listedHeroes = collectionItems.filter(item => item.type === 'hero' && item.listed);

  // Load Page Data
  useEffect(() => {
    if (loading) {
      setLoading(false);

      // Get pending purchases for current user
      if (!loadPendingPurchases) {
        setLoadPendingPurchases(true);
      }
    }
  }, [loadPendingPurchases, loading, setLoadPendingPurchases]);

  return (
    <Styled.Root>
      {tab === 0 && (
        <>
          {fromPurchase === 'true' && (
            <Alert
              severity={'info'}
              msg={tCommon('common.message.purchase.delay')}
              style={{ marginBottom: '1.5rem' }}
            />
          )}

          <Styled.Title>
            {packs.length === 0 && heroes.length === 0 ? titles[tab].empty : titles[tab].default}
          </Styled.Title>

          {packs.length > 0 && (
            <Styled.ResultsContainer>
              <Styled.CollectionTitle>{tCommon('common.term.packs')}</Styled.CollectionTitle>

              <Collectibles collectibles={packs} style={{ marginTop: '2rem' }} />
            </Styled.ResultsContainer>
          )}

          {heroes.length > 0 && (
            <Styled.ResultsContainer>
              <Styled.CollectionTitle>{tCommon('common.term.heroes')}</Styled.CollectionTitle>

              <Collectibles collectibles={heroes} style={{ marginTop: '2rem' }} />
            </Styled.ResultsContainer>
          )}
        </>
      )}

      {tab === 1 && (
        <>
          {!isCurrentProfile &&
            pendingPurchases.length > 0 &&
            (pendingPurchases.length === concurrentPurchaseLimit ? (
              <Alert
                severity={'error'}
                msg={tCommon('common.message.purchase.pending.max')}
                action={tCommon('common.nav.profile')}
                onAction={() =>
                  navigateInternal('/profile?default_tab=3', {
                    pooleData: { fromContext: 'marketplace_pending_purchases' },
                  })
                }
                style={{ marginBottom: '1.5rem' }}
              />
            ) : (
              <Alert
                severity={'warning'}
                msg={tCommon('common.message.purchase.pending')}
                action={tCommon('common.nav.profile')}
                onAction={() =>
                  navigateInternal('/profile?default_tab=3', {
                    pooleData: { fromContext: 'marketplace_pending_purchases' },
                  })
                }
                style={{ marginBottom: '1.5rem' }}
              />
            ))}

          <Styled.Title>
            {listedPacks.length === 0 && listedHeroes.length === 0 ? titles[tab].empty : titles[tab].default}
          </Styled.Title>

          {isCurrentProfile && listedPacks.length > 0 && (
            <Styled.ResultsContainer>
              <Styled.CollectionTitle>{tCommon('common.term.packs')}</Styled.CollectionTitle>

              <Collectibles collectibles={listedPacks} style={{ marginTop: '2rem' }} />
            </Styled.ResultsContainer>
          )}

          {listedHeroes.length > 0 && (
            <Styled.ResultsContainer>
              <Styled.CollectionTitle>{tCommon('common.term.heroes')}</Styled.CollectionTitle>

              <Collectibles collectibles={listedHeroes} style={{ marginTop: '2rem' }} />
            </Styled.ResultsContainer>
          )}
        </>
      )}

      <Styled.BuyContainer>
        <Styled.BuyLabel>{tProfile('profile.purchases.add.nfts')}</Styled.BuyLabel>

        <Styled.BuyButton onClick={() => navigateInternal('/marketplace', { pooleData: { fromContext: 'profile' } })}>
          <span className="material-icons">add</span>
          <span>{tProfile('profile.purchases.button.buy')}</span>
        </Styled.BuyButton>
      </Styled.BuyContainer>
    </Styled.Root>
  );
};

export default Collection;
