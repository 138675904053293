import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import Styled, { IImageStyledProps } from './Image.styles';
import { ReactComponent as PhotoIcon } from '../icons/iconic/photo.svg';

interface IImageProps extends IImageStyledProps {
  alt?: string;
  source?: string | File;
  showPlaceholder?: boolean;
  hoverIcon?: ReactNode;
}

type TImageHandleOnLoad = () => void;

const Image: FC<IImageProps> = props => {
  const {
    alt = '',
    hoverIcon = null,
    source,
    height = '100%',
    width = '100%',
    showPlaceholder = false,
    borderRadius,
    ...leftProps
  } = props;
  const [loaded, setLoaded] = useState<boolean>(false);
  const imageReference = useRef<HTMLImageElement | null>(null);
  const imageSource =
    typeof source === 'string' || source === undefined || source === null ? source : URL.createObjectURL(source);

  const handleOnLoad: TImageHandleOnLoad = () => setLoaded(true);

  useEffect(() => {
    if (
      imageReference.current &&
      imageReference.current?.complete &&
      source !== undefined &&
      source !== '' &&
      source !== null
    ) {
      setLoaded(true);

      return;
    }

    setLoaded(false);
  }, [source]);

  if (!showPlaceholder) {
    return (
      <Styled.Root
        alt={alt}
        borderRadius={borderRadius}
        height={height}
        width={width}
        src={imageSource}
        loaded
        {...leftProps}
      />
    );
  }

  return (
    <Styled.Wrapper height={height} width={width}>
      {hoverIcon && (
        <Styled.HoverIconWrapper height={height} width={width} borderRadius={borderRadius}>
          {hoverIcon}
        </Styled.HoverIconWrapper>
      )}
      <Styled.Root
        alt={alt}
        borderRadius={borderRadius}
        height={height}
        loaded={loaded}
        loading="lazy"
        onLoad={handleOnLoad}
        ref={imageReference}
        src={imageSource}
        width={width}
        {...leftProps}
      />
      <Styled.ImagePlaceholder height={height} width={width} loaded={loaded} borderRadius={borderRadius}>
        <Styled.Icon icon={<PhotoIcon />} width="100%" height="100%" />
      </Styled.ImagePlaceholder>
    </Styled.Wrapper>
  );
};

export default Image;
