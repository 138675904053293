import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  padding: 1px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  position: relative;
  font-size: 0;
`;

const Line = styled('div')`
  /* background-color: ${({ theme }) => theme.custom.colors.Yellow}; */
  background-color: #dbba5a;
  display: inline-block;
  width: calc(50% - 6px - 2px);
  vertical-align: middle;
  height: 2px;
  position: relative;
`;

const Box = styled('div')`
  /* background-color: ${({ theme }) => theme.custom.colors.Yellow}; */
  background-color: #dbba5a;
  display: inline-block;
  margin: 0 5px 0 5px;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  transform: rotate(45deg);
`;

export default {
  Root,
  Line,
  Box,
};
