import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
import { useInterval } from 'src/hooks/useInterval';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import usePaymentPopupContext from 'src/hooks/usePaymentPopupContext';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { useQueueItStatus } from 'src/hooks/useQueueItStatus';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useTutorialContext from 'src/hooks/useTutorialContext';
import { purchaseNextListing } from 'src/api/markets/purchase-next-listing';
import { getAsyncOperation } from 'src/api/async-operations/getAsyncOperation';
import { setSnackbar } from 'src/redux/slices/snackbar/snackbar';
import { getDropEventsMap } from 'src/redux/slices/drop-events/drop-events';
import {
  getDropEventListPriceMarkets,
  updateListPriceMarketFromPurchase,
} from 'src/redux/slices/list-price-markets/list-price-markets';
import RewardSequenceModal from 'src/components/modals/RewardSequenceModal';
import ErrorMessage from 'src/components/feedback/ErrorMessage';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import Alert from 'src/components/feedback/Alert';
import ButtonHex from 'src/components/input/ButtonHex';
import ButtonPrimary from 'src/components/input/ButtonPrimary';
import BaseIcon from 'src/components/icons/BaseIcon';
import ForteWhiteHeaderIcon from 'src/components/icons/ForteWhiteHeaderIcon';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { logMain } from 'src/modules/logger/logger';
import { config } from 'src/config/config';
import Styled from 'src/components/modals/PrimaryPurchaseModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';
import loadCollection from 'src/tools/loadCollection';

interface PrimaryPurchaseModalProps {
  dropEventId: string;
  lpmId: string;
  open: boolean;
  onClose: () => Promise<void>;
  disableConfirmation?: boolean;
  style?: React.CSSProperties;
}

const PrimaryPurchaseModal: FC<PrimaryPurchaseModalProps> = ({
  dropEventId,
  lpmId,
  open,
  onClose,
  disableConfirmation = false,
  style = {},
}) => {
  const { playerId = '' } = useParams();
  const lpmRef = useRef<ListPriceMarket | null>(null);
  const { navigateGameOverlay, setBlurbs, enableGameOverlay } = useGameOverlayContext();
  const { tutorialState } = useTutorialContext();
  const { handleOpenPopup } = usePaymentPopupContext();
  const [step, setStep] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [polling, setPolling] = useState(false);
  const [asyncOperationId, setAsyncOperationId] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [error, setError] = useState('');
  const [rewards, setRewards] = useState([]);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const dropEvents = useAppSelector(state => getDropEventsMap(state));
  const eventListPriceMarkets = useAppSelector(state => getDropEventListPriceMarkets(state, dropEventId));
  const dispatch = useAppDispatch();
  const navigateExternal = useAppNavigateExternal();
  const { queueComplete, queueItCookieExpired } = useQueueItStatus();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tEvent } = useTranslation(nameSpace.event);
  const { t: tPlay } = useTranslation(nameSpace.play);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const dropEvent = dropEvents[dropEventId];
  const lpm = eventListPriceMarkets.find(lpm => lpm.id === lpmId);
  const { queueProtected } = lpm || {};
  const userMustQueue = queueProtected && (!queueComplete || (queueComplete && queueItCookieExpired));

  // Open Up popup window for payment flow and set onReturn and onClose callbacks
  const openPopup = (url: string) => {
    handleOpenPopup(url, {
      onReturn: paymentSuccessful => {
        if (paymentSuccessful) {
          dispatch(updateListPriceMarketFromPurchase(lpmId));
          dispatch(setSnackbar({ severity: 'success', msg: tCommon('common.mint.success') }));
          navigateGameOverlay({ page: 'collection', params: {} });
        } else {
          dispatch(setSnackbar({ severity: 'warning', msg: tCommon('common.mint.fail') }));
        }
      },
      onClose: () => {
        handleClose(true);
      },
    });
  };

  const handleClose = (disableAbortEvent?: boolean) => {
    // Fire Poole event for primary market purchase
    // PooleEvents.PrimaryPurchaseClose({
    //   playerId: currentProfile.id,
    //   from: window.location.pathname,
    //   fromContext: 'primary_purchase_modal',
    //   dropEventId: dropEventId || '',
    //   asyncOperationId,
    // });

    if (polling && !disableAbortEvent) {
      // Fire Poole event for primary market purchase
      PooleEvents.PrimaryPurchasePollingAbort({
        dataset: enableGameOverlay ? 'unity' : 'website',
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'primary_purchase_modal',
        dropEventId: dropEventId || '',
        asyncOperationId,
      });
    }

    onClose().then(() => {
      setPolling(false);
      setAsyncOperationId('');
      setProcessing(false);
      setStep(0);
      setRedirectUrl('');
      setError('');
      setRewards([]);
    });
  };

  const handleCloseRewardsModal = () => {
    handleClose();
  };

  const handleSubmit = useCallback(() => {
    setProcessing(true);

    if (userMustQueue) {
      // Fire Poole event for primary market purchase queue enter
      PooleEvents.PrimaryPurchaseQueueEnter({
        dataset: enableGameOverlay ? 'unity' : 'website',
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'event_details',
        dropEventId: dropEvent?.id || '',
        dropEventType: dropEvent?.type || '',
        dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
        lpmId: lpmRef.current?.id || '',
        lpmType: lpmRef.current?.type || '',
        lpmAllowlistIds: lpmRef.current?.allowedViaAllowListIds || [],
        lpmPriceLC: lpmRef.current?.price?.toString() || '',
        lpmPriceUSD: ((lpmRef.current?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
        lpmPurchaseCount: lpmRef.current?.purchaseCount?.toString() || '',
        lpmPurchaseLimit: lpmRef.current?.purchaseLimit?.toString() || '',
        lpmTotalCount: lpmRef.current?.totalCount?.toString() || '',
        lpmCurrencyName: lpmRef.current?.priceCurrencyName || 'USD',
        lpmCurrencyBalance: lpmRef.current?.priceCurrencyBalance?.toString() || '',
      });
    }

    // Fire Poole event for primary market purchase start
    PooleEvents.PrimaryPurchaseStart({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'event_details',
      dropEventId: dropEvent?.id || '',
      dropEventType: dropEvent?.type || '',
      dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
      lpmId: lpmRef.current?.id || '',
      lpmType: lpmRef.current?.type || '',
      lpmAllowlistIds: lpmRef.current?.allowedViaAllowListIds || [],
      lpmPriceLC: lpmRef.current?.price?.toString() || '',
      lpmPriceUSD: ((lpmRef.current?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
      lpmPurchaseCount: lpmRef.current?.purchaseCount?.toString() || '',
      lpmPurchaseLimit: lpmRef.current?.purchaseLimit?.toString() || '',
      lpmTotalCount: lpmRef.current?.totalCount?.toString() || '',
      lpmCurrencyName: lpmRef.current?.priceCurrencyName || 'USD',
      lpmCurrencyBalance: lpmRef.current?.priceCurrencyBalance?.toString() || '',
    });

    dispatch(purchaseNextListing({ urlParams: { marketId: lpmId } }))
      .then(data => {
        if (data?.id) {
          // Fire Poole event for primary market purchase start success
          PooleEvents.PrimaryPurchaseStartSuccess({
            dataset: enableGameOverlay ? 'unity' : 'website',
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'event_details',
            dropEventId: dropEvent?.id || '',
            dropEventType: dropEvent?.type || '',
            dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
            lpmId: lpmRef.current?.id || '',
            lpmType: lpmRef.current?.type || '',
            lpmAllowlistIds: lpmRef.current?.allowedViaAllowListIds || [],
            lpmPriceLC: lpmRef.current?.price?.toString() || '',
            lpmPriceUSD: ((lpmRef.current?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            lpmPurchaseCount: lpmRef.current?.purchaseCount?.toString() || '',
            lpmPurchaseLimit: lpmRef.current?.purchaseLimit?.toString() || '',
            lpmTotalCount: lpmRef.current?.totalCount?.toString() || '',
            lpmCurrencyName: lpmRef.current?.priceCurrencyName || 'USD',
            lpmCurrencyBalance: lpmRef.current?.priceCurrencyBalance?.toString() || '',
            asyncOperationId: data?.id || '',
          });

          setAsyncOperationId(data?.id);
          setPolling(true);
          setStep(1);
          setRedirectUrl('');
        } else if (userMustQueue) {
          // Fire Poole event for primary market purchase queue enter success
          PooleEvents.PrimaryPurchaseQueueEnterSuccess({
            dataset: enableGameOverlay ? 'unity' : 'website',
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'event_details',
            dropEventId: dropEvent?.id || '',
            dropEventType: dropEvent?.type || '',
            dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
            lpmId: lpmRef.current?.id || '',
            lpmType: lpmRef.current?.type || '',
            lpmAllowlistIds: lpmRef.current?.allowedViaAllowListIds || [],
            lpmPriceLC: lpmRef.current?.price?.toString() || '',
            lpmPriceUSD: ((lpmRef.current?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            lpmPurchaseCount: lpmRef.current?.purchaseCount?.toString() || '',
            lpmPurchaseLimit: lpmRef.current?.purchaseLimit?.toString() || '',
            lpmTotalCount: lpmRef.current?.totalCount?.toString() || '',
            lpmCurrencyName: lpmRef.current?.priceCurrencyName || 'USD',
            lpmCurrencyBalance: lpmRef.current?.priceCurrencyBalance?.toString() || '',
          });
        } else {
          dispatch(setSnackbar({ severity: 'error', msg: tCommon('common.error.message.1') }));
          setError(tCommon('common.error.message.1'));
          setStep(0);
          setRedirectUrl('');
        }

        setProcessing(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        logMain.debug(e);

        // Fire Poole event for primary market purchase start success
        PooleEvents.PrimaryPurchaseStartFail({
          dataset: enableGameOverlay ? 'unity' : 'website',
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'event_details',
          dropEventId: dropEvent?.id || '',
          dropEventType: dropEvent?.type || '',
          dropEventAllowlistIds: dropEvent?.allowedViaAllowListIds || [],
          lpmId: lpmRef.current?.id || '',
          lpmType: lpmRef.current?.type || '',
          lpmAllowlistIds: lpmRef.current?.allowedViaAllowListIds || [],
          lpmPriceLC: lpmRef.current?.price?.toString() || '',
          lpmPriceUSD: ((lpmRef.current?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
          lpmPurchaseCount: lpmRef.current?.purchaseCount?.toString() || '',
          lpmPurchaseLimit: lpmRef.current?.purchaseLimit?.toString() || '',
          lpmTotalCount: lpmRef.current?.totalCount?.toString() || '',
          lpmCurrencyName: lpmRef.current?.priceCurrencyName || 'USD',
          lpmCurrencyBalance: lpmRef.current?.priceCurrencyBalance?.toString() || '',
          reason: e instanceof Error ? e.message : String(e),
        });

        dispatch(setSnackbar({ severity: 'error', msg: tCommon('common.error.message.1') }));
        setError(tCommon('common.error.message.1'));
        setProcessing(false);
        setPolling(false);
        setRedirectUrl('');
      });
  }, [
    currentProfile.id,
    dispatch,
    dropEvent?.allowedViaAllowListIds,
    dropEvent?.id,
    dropEvent?.type,
    enableGameOverlay,
    lpmId,
    tCommon,
    userMustQueue,
  ]);

  // If user initiated a payment, poll the async op endpoint to recieve redirect URL
  useInterval(() => {
    if (polling && asyncOperationId) {
      // Fire Poole event for primary market polling start
      PooleEvents.PrimaryPurchasePollingStart({
        dataset: enableGameOverlay ? 'unity' : 'website',
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'primary_purchase_modal',
        dropEventId: dropEventId || '',
        asyncOperationId,
      });

      dispatch(getAsyncOperation({ urlParams: { asyncOperationId } }))
        .then(data => {
          if (data?.status === 'pending') {
            logMain.debug('[DEBUG]: Operation pending');
            logMain.debug({ data });

            // Fire Poole event for primary market polling pending
            PooleEvents.PrimaryPurchasePollingPending({
              dataset: enableGameOverlay ? 'unity' : 'website',
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'primary_purchase_modal',
              dropEventId: dropEventId || '',
              asyncOperationId,
            });
          } else if (data?.status === 'success') {
            logMain.debug('[DEBUG]: Operation succeeded');
            logMain.debug({ data, uri: data.result.redirectUri });

            // Fire Poole event for primary market polling success
            PooleEvents.PrimaryPurchasePollingSuccess({
              dataset: enableGameOverlay ? 'unity' : 'website',
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'primary_purchase_modal',
              dropEventId: dropEventId || '',
              asyncOperationId,
            });

            // Redirect User if succcessful
            if (data?.result?.redirectUri) {
              // Open Up popup window for payment flow
              openPopup(data.result.redirectUri);

              // After 1 second, set redirect URL to trigger button to appear
              setTimeout(() => {
                setRedirectUrl(data.result.redirectUri);
              }, 1000);

              // navigateExternal(data.result.redirectUri, {
              //   force: true,
              //   pooleData: { pageName: 'Forte Pay', fromContext: 'secondary_market_purchase' },
              // });
            } else if (lpmRef.current?.purchaseType === 'offer') {
              dispatch(setSnackbar({ severity: 'success', msg: tCommon('common.mint.success') }));
              dispatch(updateListPriceMarketFromPurchase(lpmId));

              // Refresh collection after successful purchase
              loadCollection({
                dispatch,
                currentProfileId: currentProfile.id,
                currentProfileUsername: currentProfile.username,
                playerId,
              });

              // If items returned, show reward sequence modal
              if (lpm?.showRewardSequence && data?.result?.items && data.result.items.length > 0) {
                setRewards(data.result.items);
              } else {
                handleClose(true);
              }

              // If part of tutorial, automatically navigate user to collection page for next part of tutorial.
              if (enableGameOverlay && tutorialState.starterOpenPack) {
                // navigateGameOverlay({ page: 'collection', params: {} });
                setBlurbs([tPlay('play.overlay.blurb.marketplace.minting.packs')]);
              }
            } else if (lpmRef.current?.purchaseType === 'lpm') {
              dispatch(setSnackbar({ severity: 'error', msg: tCommon('common.error.message.1') }));
              setError(tCommon('common.error.message.1'));
              setStep(0);
              setRedirectUrl('');
            }

            setPolling(false);
            setAsyncOperationId('');
          } else if (data?.status === 'failure' || data?.error) {
            logMain.debug('[DEBUG]: Operation failed', data?.error);
            logMain.debug({ data });

            // Fire Poole event for primary market polling fail
            PooleEvents.PrimaryPurchasePollingFail({
              dataset: enableGameOverlay ? 'unity' : 'website',
              playerId: currentProfile.id,
              from: window.location.pathname,
              fromContext: 'primary_purchase_modal',
              dropEventId: dropEventId || '',
              asyncOperationId,
              reason: data?.error?.toString() || 'Error during primary purchase polling.',
            });

            if (data?.error?.metadata?.kyc_redirect_url) {
              navigateExternal(data?.error?.metadata?.kyc_redirect_url, {
                pooleData: { pageName: 'Forte Pay', fromContext: 'primary_purchase_modal' },
              });
            } else {
              setPolling(false);
              setAsyncOperationId('');
              setStep(0);
              setRedirectUrl('');
              setError(tCommon('common.error.message.1'));
            }
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug(e);

          // Fire Poole event for primary market polling fail
          PooleEvents.PrimaryPurchasePollingFail({
            dataset: enableGameOverlay ? 'unity' : 'website',
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'primary_purchase_modal',
            dropEventId: dropEventId || '',
            asyncOperationId,
            reason: e instanceof Error ? e.message : String(e),
          });

          setPolling(false);
          setAsyncOperationId('');
          setStep(0);
          setRedirectUrl('');
          setError(tCommon('common.error.message.1'));
        });
    }
  }, config.poll_interval.nft_purchase);

  // If disableConfirmation is true, call purchaseNextListing and skip to step 1
  // Immediately process transaction
  useEffect(() => {
    if (open && disableConfirmation) {
      setStep(1);
      handleSubmit();
    }
  }, [disableConfirmation, handleSubmit, open]);

  useEffect(() => {
    if (lpm) {
      lpmRef.current = lpm;
    }
  }, [lpm]);

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={open}
      onClose={() => handleClose()}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.purchaseNFT}` }}
    >
      <Fade in={open}>
        <Styled.Root style={style}>
          <RewardSequenceModal
            showRewardSequence={lpm?.showRewardSequence || false}
            rewards={rewards}
            onClose={handleCloseRewardsModal}
          />

          <PrimaryModalBackground />

          <Styled.ContentContainer>
            <Styled.Heading>
              {lpmRef.current?.purchaseType === 'lpm'
                ? tCommon('common.popup.purchase.primary.lpm.heading')
                : tCommon('common.popup.purchase.primary.offer.heading')}
            </Styled.Heading>

            <ErrorMessage msg={error} style={{ margin: '1rem 0 0 0' }} />

            {step === 0 && !disableConfirmation && (
              <>
                <Styled.SubheadingsContainer>
                  <Styled.Subheading>{tCommon('common.popup.purchase.primary.step.0.subheading.1')}</Styled.Subheading>
                </Styled.SubheadingsContainer>

                {queueProtected && (!queueComplete || (queueComplete && queueItCookieExpired)) && (
                  <div>
                    <Alert
                      severity="info"
                      variant="filled"
                      msg={tEvent('event.queue.required')}
                      action={tEvent('event.hero.button.join.queue')}
                      // onAction={handleSubmit}
                      hideClose
                      style={{ margin: '1.5rem auto 0.5rem auto' }}
                    />
                  </div>
                )}

                {queueProtected && queueComplete && !queueItCookieExpired && (
                  <div>
                    <Alert
                      severity="success"
                      variant="filled"
                      msg={tEvent('event.queue.success')}
                      hideClose
                      style={{ margin: '1.5rem auto 0.5rem auto' }}
                    />
                  </div>
                )}

                <Styled.FeesContainer>
                  <Styled.FeeRow>
                    <Styled.FeeLabel>{tCommon('common.term.fees')}</Styled.FeeLabel>

                    <Styled.FeeValue>
                      {config.app_variables.listing_fee
                        ? `${config.app_variables.listing_fee}%`
                        : tCommon('common.fees.none')}
                    </Styled.FeeValue>
                  </Styled.FeeRow>

                  <Styled.FeeRow>
                    <Styled.FeeLabel>{tCommon('common.term.royalties')}</Styled.FeeLabel>

                    <Styled.FeeValue>
                      {config.app_variables.royalty_fee
                        ? `${config.app_variables.royalty_fee * 100}%`
                        : tCommon('common.royalties.none')}
                    </Styled.FeeValue>
                  </Styled.FeeRow>
                </Styled.FeesContainer>
              </>
            )}

            {step === 1 && (
              <>
                {polling && (
                  <Styled.ProgressContainer>
                    <CircularProgress color="inherit" size={'1.5rem'} />
                  </Styled.ProgressContainer>
                )}

                {lpmRef.current?.purchaseType === 'lpm' && (
                  <Styled.ConfirmationTitle>
                    {tCommon('common.popup.purchase.primary.lpm.step.1.title')}
                  </Styled.ConfirmationTitle>
                )}

                {lpmRef.current?.purchaseType === 'offer' && (
                  <Styled.ConfirmationTitle>
                    {tCommon('common.popup.purchase.primary.offer.step.1.title')}
                  </Styled.ConfirmationTitle>
                )}

                {redirectUrl && (
                  <Styled.ConfirmationTitle>
                    <span>{tCommon('common.popup.purchase.primary.lpm.step.1.title.redirect')}</span>
                  </Styled.ConfirmationTitle>
                )}
              </>
            )}

            {step === 1 && redirectUrl && (
              <Styled.ButtonsContainer>
                <ButtonPrimary
                  className="pay-securely-button"
                  type="button"
                  title={tCommon('common.term.pay.securely')}
                  width={mobileView ? '135px' : '175px'}
                  height={mobileView ? '45px' : '55px'}
                  hideBackgroundImg
                  fontSize={mobileView ? 14 : 16}
                  textStyle={{ paddingTop: '1px' }}
                  style={{
                    display: 'inline-block',
                  }}
                  onClick={() => openPopup(redirectUrl)}
                />
              </Styled.ButtonsContainer>
            )}

            {lpmRef.current?.purchaseType === 'lpm' && (
              <Styled.ForteContainer>
                <span className="material-icons">lock</span>
                <Styled.ForteBrandingLabel>{tCommon('common.powered.by')}</Styled.ForteBrandingLabel>

                <BaseIcon
                  hoverColor="#FEC300"
                  cursor="pointer"
                  icon={<ForteWhiteHeaderIcon />}
                  padding="0"
                  style={{ display: 'inline-block', verticalAlign: 'middle', margin: '1.1rem 0 0 0' }}
                  onClick={() => {
                    navigateExternal(tCommon('common.forte.link'), {
                      newWindow: true,
                      pooleData: { pageName: 'Forte', fromContext: 'purchase_listing_modal' },
                    });
                  }}
                />
              </Styled.ForteContainer>
            )}

            {step === 0 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant={'white'}
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={tCommon('common.term.cancel')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '0 auto 0 auto' : '0',
                  }}
                  onClick={() => handleClose()}
                  disabled={processing}
                />

                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={userMustQueue ? tEvent('event.hero.button.join.queue') : tCommon('common.term.purchase')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  onClick={handleSubmit}
                  disabled={processing}
                />
              </Styled.ButtonsContainer>
            )}

            {/* {step === 1 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={tCommon('common.term.close')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  onClick={() => handleClose()}
                />
              </Styled.ButtonsContainer>
            )} */}
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default PrimaryPurchaseModal;
