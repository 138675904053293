import { useContext } from 'react';
import WebMetricsContext from 'src/context/web-metrics/WebMetrics.context';

const useWebMetricsContext = () => {
  const webMetricsContext = useContext(WebMetricsContext);

  return webMetricsContext;
};

export default useWebMetricsContext;
