import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createListing = (options: RequestOptions) => (_dispatch: any) => {
  const { data } = options;

  const config = {
    method: 'post',
    url: `/api/web/secondary-market/create-listing`,
    data: {
      nonfungibleId: data?.nonfungibleId,
      price: data?.price,
      priceFungibleId: data?.priceFungibleId || undefined,
    },
  };

  return request(config);
};
