import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
`;

interface TitleProps {
  color: string;
}

const Title = styled('p')<TitleProps>`
  display: block;
  margin: 0;
  background: ${({ color }) => `-webkit-linear-gradient(${color}, #ffffff)`};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 3.7rem;
  font-weight: 500;
  line-height: 3.5rem;
  color: #ffffff;

  @media (max-width: 1150px) {
    font-size: 2.9rem;
    line-height: 2.9rem;
  }

  @media (max-width: 1050px) {
    font-size: 2.3rem;
    line-height: 2.4rem;
  }

  @media (max-width: 900px) {
    font-size: 1.8rem;
    line-height: 1.9rem;
  }

  @media (max-height: 700px) {
    font-size: 2.3rem;
    line-height: 2.4rem;
  }

  @media (max-height: 450px) {
    font-size: 1.7rem;
    line-height: 1.8rem;
  }

  @media (max-height: 375px) {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
`;

export default {
  Root,
  Title,
};
