import { styled } from '@mui/material';

const Root = styled('div')`
  display: inline-block;
  vertical-align: middle;
`;

const AccountButton = styled('div')`
  display: inline-block;
  margin-right: -8px;
  vertical-align: middle;
  font-size: 0;

  & > button {
    & .material-icons-outlined {
      font-size: 1.8rem;
    }
  }
`;

const MenuContainer = styled('div')`
  background-color: #202425;
  display: block;
  width: 100%;
  padding: 1.5rem 0 0.9rem 0;
`;

const OverviewContainer = styled('div')`
  display: block;
  padding: 0 1.5rem;
`;

const ProfilePicture = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #083e59;
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 1rem;
  vertical-align: middle;
  border-radius: 50%;
`;

const ProfileDetails = styled('div')`
  display: inline-block;
  margin-top: 0.1rem;
  width: calc(100% - 4.5rem);
  vertical-align: middle;
`;

const ProfilePrimary = styled('p')`
  display: block;
  margin-top: -0.1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 700;
  color: #fff;
`;

const ProfileSecondaryContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.1rem;
`;

const ProfileSecondary = styled('div')`
  display: block;
  /* margin-top: 0.1rem; */
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
  color: #ffffff99;
`;

const BalanceContainer = styled('div')`
  display: block;
  width: 100%;
  padding: 0.7rem 1.5rem 0.7rem 1.5rem;
  box-sizing: border-box;
  position: relative;
`;

const Balance = styled('span')`
  display: inline-block;
  margin-bottom: 0.1rem;
  margin-right: 0.3rem;
  vertical-align: bottom;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #fff;
`;

const BalanceLabel = styled('span')`
  display: inline-block;
  margin-bottom: 0.1rem;
  vertical-align: bottom;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 300;
  color: #ffffff;
`;

interface BalanceButtonProps {
  disabled?: boolean;
}

const BalanceButton = styled('div')<BalanceButtonProps>`
  display: inline-block;
  padding: 0.5rem 1.8rem;
  border: ${({ disabled }) => `1px solid ${disabled ? '#ffffff77' : '#fff'}`};
  border-radius: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  text-transform: capitalize;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ disabled }) => (disabled ? '#ffffff77' : '#fff')};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);

  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }
`;

const DiscordButton = styled('div')`
  background-color: #4c6acd;
  display: block;
  margin: 1.5rem 1.5rem 0.7rem 1.5rem;
  padding: 0.5rem 0.4rem 0.5rem 0.4rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  text-align: center;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #6882d5;
  }

  & > span {
    display: inline-block;
    margin: 0 0 0.1rem 1.6rem;
    vertical-align: middle;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #fff;
  }
`;

const MenuItems = styled('div')`
  display: block;
  margin-top: 0.7rem;
`;

const MenuItem = styled('div')`
  display: block;
  padding: 0.7rem 1.5rem;
  word-break: break-word;
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: 600;
  color: #fff;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffffff33;
  }
`;

const Separator = styled('div')`
  display: block;
  margin: 1rem 1.5rem;
  border-bottom: 1px solid #ffffff55;
`;

export default {
  Root,
  AccountButton,
  MenuContainer,
  OverviewContainer,
  ProfilePicture,
  ProfileDetails,
  ProfilePrimary,
  ProfileSecondaryContainer,
  ProfileSecondary,
  BalanceContainer,
  Balance,
  BalanceLabel,
  BalanceButton,
  DiscordButton,
  MenuItems,
  MenuItem,
  Separator,
};
