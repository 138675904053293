import { styled } from '@mui/material';
import { isMobile } from 'react-device-detect';

const getRootMainContainerHeight = (
  isPack: boolean,
  mobileBreakpoint: boolean,
  hoverable: boolean,
  tutorialEnabled: boolean,
  isHovering: boolean,
) => {
  if (isPack) {
    if (hoverable && (isMobile || tutorialEnabled || isHovering)) {
      return mobileBreakpoint ? '6rem' : '6.2rem';
    }

    return mobileBreakpoint ? '3.8rem' : '3.8rem';
  }

  if (hoverable && (isMobile || tutorialEnabled || isHovering)) {
    return mobileBreakpoint ? '8.1rem' : '8.3rem';
  }

  return mobileBreakpoint ? '5.8rem' : '5.9rem';
};

const Root = styled('div')`
  display: block;
  width: 100%;
  padding-top: 3.2rem;
  overflow: hidden;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  position: absolute;
  bottom: 0;
  transition: height 0.3s ease-in-out;
  pointer-events: none;
`;

interface MainContainerProps {
  isPack: boolean;
  mobileBreakpoint: boolean;
  hoverable: boolean;
  tutorialEnabled: boolean;
}

const MainContainer = styled('div')<MainContainerProps>`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  display: block;
  width: 100%;
  height: ${({ isPack, mobileBreakpoint, hoverable, tutorialEnabled }) =>
    getRootMainContainerHeight(isPack, mobileBreakpoint, hoverable, tutorialEnabled, false)};
  padding: 0.5rem 0.5rem;
  overflow: visible;
  border: 1px solid ${({ theme }) => theme.custom.colors.BgLayer2};
  box-sizing: border-box;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  transition: height 0.3s ease-in-out;
  pointer-events: all;

  &:hover {
    height: ${({ isPack, mobileBreakpoint, hoverable, tutorialEnabled }) =>
      getRootMainContainerHeight(isPack, mobileBreakpoint, hoverable, tutorialEnabled, true)};
  }
`;

interface NameProps {
  isPack: boolean;
}

const Name = styled('div')<NameProps>`
  display: inline-block;
  width: ${({ isPack }) => (isPack ? '100%' : 'calc(100% - 1.4rem)')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

const RarityIcon = styled('img')`
  display: inline-block;
  width: 1.4rem;
  height: auto;
  margin: 0 0.6rem 0 0;
  vertical-align: middle;
  position: absolute;
  top: 3.8rem;
  right: 0;

  @media (max-width: 900px) {
    width: 1.4rem;
  }
`;

interface MetaDetailsContainerProps {
  isPack: boolean;
}

const MetaDetailsContainer = styled('div')<MetaDetailsContainerProps>`
  display: block;
  width: 100%;
  margin-top: ${({ isPack }) => (isPack ? '0.5rem' : '0.7rem')};
`;

interface SerialNumberProps {
  isPack: boolean;
}

const SerialNumber = styled('div')<SerialNumberProps>`
  display: inline-block;
  width: ${({ isPack }) => (isPack ? '100%' : '40%')};
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: #777;

  @media (max-width: 900px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
`;

interface RarityNameProps {
  rarityColor?: string;
}

const RarityName = styled('span')<RarityNameProps>`
  display: inline-block;
  width: calc(60% - 0.5rem);
  margin-left: 0.5rem;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-align: right;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ rarityColor }) => `${rarityColor}` || '#fff'};

  @media (max-width: 900px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
`;

const ActionsContainer = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 0.7rem;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

interface PrimaryButtonProps {
  disabled?: boolean;
}

const PrimaryButton = styled('div')<PrimaryButtonProps>`
  flex-grow: 1;
  padding: 0.2rem 0.4rem 0.3rem 0.4rem;
  border: ${({ disabled }) => `1px solid ${disabled ? '#ffffff77' : '#fff'}`};
  border-radius: 0.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ disabled }) => (disabled ? '#ffffff77' : '#fff')};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & > span {
    display: inline-block;
    vertical-align: middle;
  }

  & .material-icons,
  & .material-icons-outlined {
    margin-top: 1px;
    margin-right: 0.3rem;
    font-size: 0.9rem;
    color: ${({ disabled }) => (disabled ? '#ffffff77' : '#fff')};
  }

  &:hover {
    & .material-icons,
    & .material-icons-outlined {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }

    background-color: #fff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }

  @media (max-width: 900px) {
    font-size: 0.7rem;
    line-height: 0.9rem;

    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.8rem;
    }
  }
`;

const PriceContainer = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer2};
  display: block;
  min-width: 3rem;
  padding: 0.3rem 0.4rem 0.2rem 0.4rem;
  border-radius: 0.2rem;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0.5rem;
`;

const PricePrimary = styled('div')`
  display: block;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 600;
  color: #ffffff;
`;

const PriceSecondary = styled('div')`
  display: block;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  line-height: 0.9rem;
  font-weight: 600;
  color: #ffffff77;
`;

export default {
  Root,
  MainContainer,
  Name,
  RarityIcon,
  MetaDetailsContainer,
  SerialNumber,
  RarityName,
  ActionsContainer,
  PrimaryButton,
  PriceContainer,
  PricePrimary,
  PriceSecondary,
};
