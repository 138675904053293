import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 75rem;
  padding: 2rem 2rem;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
`;

export default {
  Root,
  CenteredContainer,
};
