import { ICustomTheme } from './types';
import { Colors, BorderRadius, BoxShadow, FontFamilies, Spacing, Typography } from './tokens';

const customThemeOptions: ICustomTheme = {
  custom: {
    colors: {
      BgDefault: Colors.BgDefault,
      BgLayer1: Colors.BgLayer1,
      BgLayer2: Colors.BgLayer2,
      BgLayer3: Colors.BgLayer3,
      BgLayer3Accent: Colors.BgLayer3Accent,
      BgLayer3Font: Colors.BgLayer3Font,
      BgLayer3Heading: Colors.BgLayer3Heading,
      BgAside: Colors.BgAside,
      BgAccent: Colors.BgAccent,
      BgAccentWeak: Colors.BgAccentWeak,
      BgCritical: Colors.BgCritical,
      BgWarning: Colors.BgWarning,
      BgSuccess: Colors.BgSuccess,
      BgHighlight: Colors.BgHighlight,
      BgHighlightWeak: Colors.BgHighlightWeak,

      ActionAccent: Colors.ActionAccent,
      ActionAccentHover: Colors.ActionAccentHover,
      ActionCritical: Colors.ActionCritical,
      ActionCriticalHover: Colors.ActionCriticalHover,
      ActionSecondary: Colors.ActionSecondary,
      ActionSecondaryHover: Colors.ActionSecondaryHover,
      ActionTertiaryHover: Colors.ActionTertiaryHover,
      ActionDisabled: Colors.ActionDisabled,

      BorderDefault: Colors.BorderDefault,
      BorderSeparator: Colors.BorderSeparator,
      BorderField: Colors.BorderField,
      BorderTertiaryHover: Colors.BorderTertiaryHover,
      BorderAccent: Colors.BorderAccent,
      BorderCritical: Colors.BorderCritical,
      BorderHighlight: Colors.BorderHighlight,

      FieldDefault: Colors.FieldDefault,
      FieldSearch: Colors.FieldSearch,
      FieldDisabled: Colors.FieldDisabled,
      FieldCritical: Colors.FieldCritical,
      FieldWarning: Colors.FieldWarning,
      FieldSuccess: Colors.FieldSuccess,
      FieldHighlight: Colors.FieldHighlight,

      TextDefault: Colors.TextDefault,
      TextWeak: Colors.TextWeak,
      TextHint: Colors.TextHint,
      TextDisabled: Colors.TextDisabled,
      TextOnAccent: Colors.TextOnAccent,
      TextOnAccentWeak: Colors.TextOnAccentWeak,
      TextOnCritical: Colors.TextOnCritical,
      TextOnWarning: Colors.TextOnWarning,
      TextOnSuccess: Colors.TextOnSuccess,
      TextOnHighlight: Colors.TextOnHighlight,
      TextCritical: Colors.TextCritical,
      TextWarning: Colors.TextWarning,
      TextSuccess: Colors.TextSuccess,
      TextHighlight: Colors.TextHighlight,

      IconWeak: Colors.IconWeak,
      IconDisabled: Colors.IconDisabled,
      IconOnAccent: Colors.IconOnAccent,
      IconOnAccentWeak: Colors.IconOnAccentWeak,
      IconOnCritical: Colors.IconOnCritical,
      IconOnWarning: Colors.IconOnWarning,
      IconOnSuccess: Colors.IconOnSuccess,
      IconOnHighlight: Colors.IconOnHighlight,
      IconCritical: Colors.IconCritical,
      IconWarning: Colors.IconWarning,
      IconSuccess: Colors.IconSuccess,
      IconHighlight: Colors.IconHighlight,

      White: Colors.White,
      Black: Colors.Black,
      Yellow: Colors.Yellow,
      Purple: Colors.Purple,

      // others
      SlateDarkAlpha6: Colors.SlateDarkAlpha6,
    },
    borderRadius: {
      BorderRadiusRadiusScale: BorderRadius.BorderRadiusRadiusScale,
      BorderRadiusRadiusNone: BorderRadius.BorderRadiusRadiusNone,
      BorderRadiusRadiusSm: BorderRadius.BorderRadiusRadiusSm,
      BorderRadiusRadiusBase: BorderRadius.BorderRadiusRadiusBase,
      BorderRadiusRadiusMd: BorderRadius.BorderRadiusRadiusMd,
      BorderRadiusRadiusLg: BorderRadius.BorderRadiusRadiusLg,
      BorderRadiusRadiusXl: BorderRadius.BorderRadiusRadiusXl,
      BorderRadiusRadius2Xl: BorderRadius.BorderRadiusRadius2Xl,
      BorderRadiusRadius3Xl: BorderRadius.BorderRadiusRadius3Xl,
      BorderRadiusRadiusFull: BorderRadius.BorderRadiusRadiusFull,
    },
    shadows: {
      ShadowShadowXl: BoxShadow.ShadowShadowXl,
      ShadowShadowLg: BoxShadow.ShadowShadowLg,
      ShadowShadowMd: BoxShadow.ShadowShadowMd,
      ShadowShadowBase: BoxShadow.ShadowShadowBase,
      ShadowShadowInner: BoxShadow.ShadowShadowInner,
    },
    fontFamily: {
      FontFamiliesBody: FontFamilies.FontFamiliesHeadline,
      FontFamiliesHeadline: FontFamilies.FontFamiliesHeadline,
      FontFamiliesMono: FontFamilies.FontFamiliesMono,
    },
    spacing: {
      SpacingXs: Spacing.SpacingXs,
      SpacingSm: Spacing.SpacingSm,
      SpacingBase: Spacing.SpacingBase,
      SpacingMd: Spacing.SpacingMd,
      SpacingLg: Spacing.SpacingLg,
      SpacingXl: Spacing.SpacingXl,
      Spacing2Xl: Spacing.Spacing2Xl,
      Spacing3Xl: Spacing.Spacing3Xl,
      Spacing4Xl: Spacing.Spacing4Xl,
      Spacing5Xl: Spacing.Spacing5Xl,
      Spacing6Xl: Spacing.Spacing6Xl,
    },
    typography: {
      HeadlineMobileLg: Typography.HeadlineMobileLg,
      HeadlineMobileMd: Typography.HeadlineMobileMd,
      HeadlineMobileSm: Typography.HeadlineMobileSm,
      HeadlineDesktopLg: Typography.HeadlineDesktopLg,
      HeadlineDesktopMd: Typography.HeadlineDesktopMd,
      HeadlineDesktopSm: Typography.HeadlineDesktopSm,
      TitleLg: Typography.TitleLg,
      TitleLgStrong: Typography.TitleLgStrong,
      BodyLg: Typography.BodyLg,
      BodyLgStrong: Typography.BodyLgStrong,
      BodyMd: Typography.BodyMd,
      BodyMdStrong: Typography.BodyMdStrong,
      LabelLg: Typography.LabelLg,
      LabelLgStrong: Typography.LabelLgStrong,
      LabelMd: Typography.LabelMd,
      LabelMdStrong: Typography.LabelMdStrong,
      LabelSm: Typography.LabelSm,
      CodeLg: Typography.CodeLg,
      CodeMd: Typography.CodeMd,
      CodeSm: Typography.CodeSm,
    },
    // Z-Index Codes:
    // 0 - 997: Reserved for regularly placed content (anything layered below the header)
    // 998: reserved for the game when not in fullscreen (Above all regularly placed content but below banners)
    // 999: reserved for any announcements that need to be above regularly placed content (banners, cookie notice)
    // 1000 - 1999: Reserved for front content (headers, dropdowns, side menus, etc..)
    // 2000 - 2999: Reserved for fullscreen content (game or higher priority)
    // 3000 - 3999: Reserved for in-game overlays
    // 4000 - 4999: Reserved for modals and tooltips
    // 100000: Reserved for snackbar (always on top)
    zIndex: {
      header: {
        root: 1000,
        accountDropdown: 1000,
        mobileSideMenu: 1000,
      },
      legalNoticeBanner: 999,
      // cookiePrivacy: 999,
      snackbar: 100000, // Should be on top always (1)
      game: {
        exitTheatreModeButton: 2001,
        theatreMode: 2000,
        notTheatreMode: 998,
      },
      gameOverlay: {
        root: 3000,
        basePageUnfocused: 3000,
        basePageFocused: 3001,
        navbar: 3005,
        balances: 3005,
        closeButton: 3005,
        blurb: 3004,
        swiperNavigation: 3002,
      },
      tutorial: {
        overlay: 3004,
        cursor: 3003,
      },
      modals: {
        primary: 4000,
        packOpening: 4000,
        purchaseNFT: 4000,
        listNFT: 4000,
        delistNFT: 4000,
        leaveGame: 4000,
        gameError: 4000,
        legalNotice: 4001,
        nftPreview: 4000,
        openOdds: 4000,
      },
      tooltips: 4500,
      rotateScreenOverlay: 5000,
      debugWidget: {
        root: 3000000000,
        toggleButton: 3000000001,
        menu: 3000000002,
      },
      webMetrics: 3000000002,
    },
  },
};

export default customThemeOptions;
