import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNonfungible = (options: RequestOptions) => (_dispatch: any) => {
  const { urlParams } = options;

  const config = {
    method: 'get',
    url: `/api/web/nft/${urlParams?.seriesId}/${urlParams?.serialNumber}/details`,
  };

  return request(config);
};
