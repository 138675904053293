import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const openPack = (options: RequestOptions) => (_dispatch: any) => {
  const { data } = options;

  const config = {
    method: 'post',
    url: `/api/web/me/collection/open-pack`,
    data: {
      id: data?.id,
    },
  };

  return request(config);
};
