import { styled } from '@mui/material';

const Root = styled('div')`
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: block;
  width: 100%;
  position: relative;
`;

interface CenteredContentContainerProps {
  soldOut: boolean;
  fromGameOverlay: boolean;
}

const CenteredContentContainer = styled('div')<CenteredContentContainerProps>`
  display: block;
  width: 100%;
  max-width: 64rem;
  /* padding: ${({ soldOut }) => (soldOut ? '12rem 2rem 11rem 2rem' : '10rem 2rem 11rem 2rem')}; */
  padding: ${({ fromGameOverlay }) => (fromGameOverlay ? '4rem 2rem' : '14rem 2rem 13rem 2rem')};
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 1000px) {
    /* padding: ${({ soldOut }) => (soldOut ? '12rem 2rem 11rem 2rem' : '9rem 2rem 11rem 2rem')}; */
    padding: ${({ fromGameOverlay }) => (fromGameOverlay ? '4rem 2rem' : '14rem 2rem 13rem 2rem')};
  }

  @media (max-width: 768px) {
    /* padding: ${({ soldOut }) => (soldOut ? '11.5rem 2rem 11rem 2rem' : '8.5rem 2rem 11rem 2rem')}; */
    padding: ${({ fromGameOverlay }) => (fromGameOverlay ? '4rem 2rem' : '18.5rem 2rem 12rem 2rem')};
  }

  @media (max-width: 700px) {
    /* padding: ${({ soldOut }) => (soldOut ? '11.5rem 2rem 7.5rem 2rem' : '8.5rem 2rem 7.5rem 2rem')}; */
    padding: ${({ fromGameOverlay }) => (fromGameOverlay ? '4rem 2rem' : '17rem 2rem 7.5rem 2rem')};
  }
`;

interface MediaContainerProps {
  soldOut: boolean;
}

const MediaContainer = styled('div')<MediaContainerProps>`
  display: inline-block;
  width: 49%;
  padding: ${({ soldOut }) => (soldOut ? '0 .5rem' : '0 1.5rem')};
  vertical-align: top;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 700px) {
    display: block;
    width: 100%;
    max-width: 20rem;
    padding: ${({ soldOut }) => (soldOut ? '0 1.5rem' : '0 2rem')};
    margin: 0 auto;
  }
`;

const ListingImage = styled('img')`
  display: block;
  width: 100%;
  height: auto;
`;

const SoldOutOverlay = styled('div')`
  display: block;
  width: calc(100% - 3rem);
  height: 100%;
  border: 8px solid yellow;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface DetailsContainerProps {
  soldOut: boolean;
}

const DetailsContainer = styled('div')<DetailsContainerProps>`
  display: inline-block;
  width: 51%;
  padding: 0 1.5rem 1rem 2.5rem;
  vertical-align: top;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 700px) {
    display: block;
    width: 100%;
    padding: ${({ soldOut }) => (soldOut ? '2rem 2rem 0 2rem;' : '3.5rem 2rem 0 2rem')};
  }

  @media (max-width: 500px) {
    padding: ${({ soldOut }) => (soldOut ? '2rem 1rem 0 1rem;' : '3.5rem 1rem 0 1rem')};
  }
`;

interface TitleProps {
  color: string;
  soldOut: boolean;
}

const Title = styled('p')<TitleProps>`
  display: block;
  margin: ${({ soldOut }) => `${soldOut ? '-0.1rem' : '-0.7rem'} 0 0 -0.3rem`};
  word-break: break-word;
  text-transform: uppercase;
  font-size: 5.6rem;
  line-height: 4.8rem;
  font-weight: 700;
  color: #ffffff;

  & span {
    color: ${({ color }) => color || '#ffffff'};
  }

  @media (max-width: 1000px) {
    font-size: 4.8rem;
    line-height: 4.2rem;
  }

  @media (max-width: 768px) {
    margin: ${({ soldOut }) => `${soldOut ? '-0.1rem' : '-0.7rem'} 0 0 -0.1rem`};
    font-size: 4rem;
    line-height: 3.5rem;
  }

  @media (max-width: 700px) {
    width: 100%;
    /* max-width: 25rem; */
    font-size: 4.8rem;
    line-height: 4.2rem;
  }

  @media (max-width: 500px) {
    width: 100%;
    font-size: 4rem;
    line-height: 3.5rem;
  }

  @media (max-width: 350px) {
    width: 100%;
    font-size: 3.2rem;
    line-height: 2.8rem;
  }
`;

const SecondaryPriceContainer = styled('div')`
  display: inline-block;
  /* width: 100%; */
  margin-top: 1rem;
  position: relative;

  @media (max-width: 700px) {
    margin-top: 0.8rem;
  }
`;

const SecondaryPrice = styled('span')`
  display: inline-block;
  /* vertical-align: bottom; */

  & > span {
    display: inline-block;
    vertical-align: bottom;
    text-transform: uppercase;
    /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
    color: #ffffff;
    text-decoration: line;

    & > span {
      font-weight: 600;
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }

  & > span:nth-of-type(1) {
    margin-right: 0.3rem;
    font-size: 1.2rem;
    line-height: 1.3rem;
    font-weight: 600;

    @media (max-width: 1000px) {
      font-size: 1.1rem;
      line-height: 1.2rem;
    }

    @media (max-width: 500px) {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  & > span:nth-of-type(2) {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;

    @media (max-width: 1000px) {
      font-size: 0.7rem;
      line-height: 0.9rem;
    }
  }
`;

const Strikethrough = styled('div')`
  background-color: #ffffff;
  /* background-color: ${({ theme }) => theme.custom.colors.Yellow}; */
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
`;

const PrimaryPriceContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 0.5rem;

  @media (max-width: 700px) {
    margin-top: 0.4rem;
  }
`;

const PrimaryPrice = styled('span')`
  display: inline-block;
  vertical-align: bottom;

  & > span {
    display: inline-block;
    vertical-align: bottom;
    text-transform: uppercase;
    color: #ffffff;

    & > span {
      font-weight: 600;
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }

  & > span:nth-of-type(1) {
    margin-right: 0.3rem;
    font-size: 3rem;
    line-height: 2.7rem;
    font-weight: 600;

    @media (max-width: 1000px) {
      font-size: 2.2rem;
      line-height: 2rem;
    }

    @media (max-width: 500px) {
      font-size: 2rem;
      line-height: 1.9rem;
    }
  }

  & > span:nth-of-type(2) {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 400;

    @media (max-width: 1000px) {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }
`;

const QuantityContainer = styled('div')`
  display: inline-block;
  /* margin-top: 0.5rem; */
  margin-top: 0.4rem;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 600;
  color: #ffffff;

  & > span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 1000px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const ButtonContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
`;

const RequirementsContainer = styled('div')`
  display: block;
  margin-top: 1.3rem;

  & > div:nth-of-type(n + 2) {
    margin-top: 0.6rem;
  }
`;

const RequirementsLabel = styled('p')`
  display: block;
  margin-bottom: 0.6rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
  color: #ffffff;
`;

const Requirement = styled('div')`
  display: block;
  width: 100%;
`;

const RequirementText = styled('p')`
  display: block;
  margin-left: 2rem;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin: 0 0 0 0.3rem;
    vertical-align: middle;
    font-size: 1.2rem;
    line-height: 1.1rem;
    font-weight: 400;
  }
`;

interface RequirementHelpProps {
  disabled: boolean;
}

const RequirementHelp = styled('p')<RequirementHelpProps>`
  display: inline-block;
  margin-top: 0.5rem;
  margin-left: 2rem;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 0.7rem;
  line-height: 0.9rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.Yellow};
  /* color: cyan; */
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    text-decoration: underline;
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin: 0 0 0.1rem 0.1rem;
    vertical-align: middle;
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const TooltipContainer = styled('div')`
  display: block;
  width: 100%;
`;

const TooltipHeading = styled('p')`
  display: block;
  font-family: 'Barlow';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #ffffff;
`;

const TooltipRows = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;
`;

const TooltipRow = styled('p')`
  display: block;
  /* padding-left: 1rem; */
  font-family: 'Barlow';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const TooltipNotice = styled('p')`
  display: block;
  /* padding-left: 1rem; */
  margin-top: 1rem;
  font-family: 'Barlow';
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 400;
  font-style: italic;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const Help = styled('div')`
  display: block;
  width: 100%;
  padding-left: 0rem;
  margin-top: 1.5rem;

  & > span {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: 400;

    @media (max-width: 1000px) {
      font-size: 0.9rem;
      line-height: 1.1rem;
    }
  }

  & > span:nth-of-type(1) {
    margin-right: 0.3rem;
  }

  & > span:nth-of-type(2) {
    color: ${({ theme }) => theme.custom.colors.Yellow};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const PoweredBy = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.2rem;

  & > span:nth-of-type(1) {
    display: inline-block;
    vertical-align: top;
    margin-right: 0.3rem;
    text-transform: uppercase;
    font-size: 0.6rem;
    line-height: 0.6rem;
    font-weight: 400;
  }
`;

export default {
  Root,
  CenteredContentContainer,
  MediaContainer,
  ListingImage,
  SoldOutOverlay,
  DetailsContainer,
  Title,
  SecondaryPriceContainer,
  SecondaryPrice,
  Strikethrough,
  PrimaryPriceContainer,
  PrimaryPrice,
  QuantityContainer,
  ButtonContainer,
  RequirementsLabel,
  RequirementsContainer,
  Requirement,
  RequirementText,
  RequirementHelp,
  TooltipContainer,
  TooltipHeading,
  TooltipRows,
  TooltipRow,
  TooltipNotice,
  Help,
  PoweredBy,
};
