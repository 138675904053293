import { createContext } from 'react';

interface Snapshot {
  memoryUsed: string;
  memoryTotal: string;
  memoryLimit: string;
}

export interface IWebMetricsContext {
  clearSnapshots: () => void;
  enableWebMetrics: boolean;
  setEnableWebMetrics: (enableWebMetrics: boolean) => void;
  memoryUsed: string;
  setMemoryUsed: (memoryUsed: string) => void;
  memoryTotal: string;
  setMemoryTotal: (memoryTotal: string) => void;
  memoryLimit: string;
  setMemoryLimit: (memoryTotal: string) => void;
  snapshots: Snapshot[];
  setSnapshots: (snapshots: Snapshot[]) => void;
}

export const initialContext: IWebMetricsContext = {
  clearSnapshots: () => undefined,
  enableWebMetrics: false,
  setEnableWebMetrics: () => undefined,
  memoryUsed: '',
  setMemoryUsed: () => undefined,
  memoryTotal: '',
  setMemoryTotal: () => undefined,
  memoryLimit: '',
  setMemoryLimit: () => undefined,
  snapshots: [],
  setSnapshots: () => undefined,
};

const WebMetricsContext = createContext<IWebMetricsContext>(initialContext);

WebMetricsContext.displayName = 'WebMetricsContext';

export default WebMetricsContext;
