import { FC } from 'react';
import { SvgIcon } from '@mui/material';

export interface ISVGProps {
  title: string;
}

const CollectibleTitle: FC<ISVGProps> = ({ title }) => {
  return (
    <SvgIcon
      width="1265.505"
      height="183.022"
      viewBox="0 0 1265.505 183.022"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="1265.505" height="183.022" viewBox="0 0 1265.505 183.022">
        <text
          id={title}
          data-name={title}
          transform="translate(155.786 8.773)"
          fontSize="99"
          fontFamily="Barlow-Bold, Barlow"
          fontWeight="700"
          letterSpacing="-0.02em"
        >
          <tspan x="0" y="120">
            {title}
          </tspan>
        </text>
        <g id="Group_13" data-name="Group 13">
          <g id="Group_10" data-name="Group 10" transform="translate(42.916)">
            <path
              id="Path_30"
              data-name="Path 30"
              d="M-928.953,461H-2006.987l-52.833,91.511,52.833,91.511H-928.953l52.833-91.511Zm-9.607,166.382H-1997.38l-43.226-74.872,43.226-74.872H-938.559l43.227,74.872Z"
              transform="translate(2059.819 -461.004)"
              fill="#fec300"
            />
          </g>
          <g id="Group_11" data-name="Group 11" transform="translate(1203.376 20.654)">
            <path
              id="Path_31"
              data-name="Path 31"
              d="M-602.827,556.687l-40.909-70.856h-21.22l40.909,70.856-40.909,70.856h21.22Z"
              transform="translate(664.955 -485.83)"
              fill="#fec300"
            />
          </g>
          <g id="Group_12" data-name="Group 12" transform="translate(0 20.654)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M-2111.4,556.687l40.909,70.856h21.22l-40.909-70.856,40.909-70.856h-21.22Z"
              transform="translate(2111.403 -485.83)"
              fill="#fec300"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default CollectibleTitle;
