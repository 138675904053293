import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import ListPriceMarketImage from 'src/components/primary-marketplace/components/ListPriceMarketImage';
import ListPriceMarketDetails from 'src/components/primary-marketplace/components/ListPriceMarketDetails';
import Requirements from 'src/components/primary-marketplace/components//Requirements';
import Styled from 'src/components/primary-marketplace/components/ListPriceMarket.styles';

interface ListPriceMarketProps {
  lpmId: string;
  singleMarketExists: boolean;
  style?: React.CSSProperties;
}

const ListPriceMarket: FC<ListPriceMarketProps> = ({ lpmId, singleMarketExists, style = {} }) => {
  const lpm = useAppSelector(state => getListPriceMarketByID(state, lpmId));

  if (!lpm) return null;

  return (
    <Styled.Root style={style} singleMarketExists={singleMarketExists}>
      <Styled.FullWidthContainer>
        <ListPriceMarketImage dropEventId={lpm.dropEventId} lpmId={lpmId} />
        <ListPriceMarketDetails dropEventId={lpm.dropEventId} lpmId={lpmId} singleMarketExists={singleMarketExists} />
      </Styled.FullWidthContainer>

      <Requirements lpmId={lpmId} />
    </Styled.Root>
  );
};

export default ListPriceMarket;
