import { request } from 'src/api/request';
import store from 'src/redux/store';
import { LocalStorage } from 'src/local-storage/local-storage';
import { clearAuthenticated } from 'src/redux/slices/authenticated/authenticated';
import { clearCollectionItems } from 'src/redux/slices/collection-items/collection-items';
import { clearCurrentProfile } from 'src/redux/slices/current-profile/current-profile';
import { clearLoadedProfiles } from 'src/redux/slices/loaded-profiles/loaded-profiles';
import { clearNonfungibles } from 'src/redux/slices/nonfungibles/nonfungibles';
import { clearPurchases } from 'src/redux/slices/purchases/purchases';
import { clearPendingPurchases } from 'src/redux/slices/pending-purchases/pending-purchases';
import { clearListings } from 'src/redux/slices/listings/listings';
import { clearLeaderboards } from 'src/redux/slices/leaderboards/leaderboards';
import { clearLeaderboardItems } from 'src/redux/slices/leaderboard-items/leaderboard-items';
// import { config as LHUConfig } from 'src/config/config';
import { logMain } from 'src/modules/logger/logger';
import { PooleEvents } from 'src/modules/poole/events';

interface ClearBrowserSessionState {
  isLocalLogout?: boolean;
}

export const clearBrowserSessionState = async ({ isLocalLogout = false }: ClearBrowserSessionState) => {
  const { dispatch } = store;

  // Clear necessary reducers
  if (isLocalLogout) {
    await dispatch(clearAuthenticated());
    await dispatch(clearCollectionItems());
    await dispatch(clearCurrentProfile());
    await dispatch(clearLoadedProfiles());
    await dispatch(clearNonfungibles());
    await dispatch(clearPurchases());
    await dispatch(clearPendingPurchases());
    await dispatch(clearListings());
    await dispatch(clearLeaderboards());
    await dispatch(clearLeaderboardItems());
  }

  // Clear necessary local storage state
  LocalStorage.clear();

  return Promise.resolve();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logout = () => (_dispatch: any) => {
  const config = {
    method: 'post',
    url: '/api/web/auth/oauth/logout',
  };
  let logoutUrl = '';
  const playerId = store.getState().currentProfile.currentProfile.id || '';

  // Fire the poole events for logout
  PooleEvents.Logout({
    playerId,
    from: window.location.pathname,
    fromContext: 'logout',
  });

  return (
    request(config)
      .then(data => {
        if (data.logoutUrl) {
          logoutUrl = data.logoutUrl;
        }

        // Fire the poole events for logout success
        PooleEvents.LogoutSuccess({
          playerId,
          from: window.location.pathname,
          fromContext: 'logout',
        });

        // Clear necessary reducers
        return clearBrowserSessionState({ isLocalLogout: !logoutUrl });
      })
      .then(() => {
        if (logoutUrl) {
          // Fire the poole event for logout
          PooleEvents.NavigateExternal({
            playerId,
            pageName: 'Codsworth Logout',
            from: window.location.pathname,
            to: logoutUrl,
            fromContext: 'logout',
          });

          window.location.href = logoutUrl;
        } else {
          // logMain.debug('Manually redirecting to logout page');
          // window.location.href = `${LHUConfig.base_url.codsworth}/sign-out`;
        }

        return Promise.resolve();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        logMain.debug('Not authorized to logout');

        // Fire the poole events for logout fail
        PooleEvents.LogoutFail({
          playerId,
          from: window.location.pathname,
          fromContext: 'logout',
          reason: e instanceof Error ? e.message : String(e),
        });

        return clearBrowserSessionState({ isLocalLogout: true });
      })
  );
};
