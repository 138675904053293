import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  margin-top: -12rem;
  padding: 0 2rem 4rem 2rem;
  box-sizing: border-box;
`;

const InnerContainer = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  max-width: 75rem;
  padding: 4rem 2rem;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 0.2rem;
  position: relative;
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.8rem;
  color: #ffffff;
`;

const ButtonContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 2rem;
  box-sizing: border-box;
  text-align: center;
`;

export default {
  Root,
  InnerContainer,
  Title,
  ButtonContainer,
};
