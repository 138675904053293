import { AxiosResponse } from 'axios';
import { config } from 'src/config/config';
import store from 'src/redux/store';
import { setTutorialStates } from 'src/redux/slices/tutorial-states/tutorial-states';
import { setCurrencyStates } from 'src/redux/slices/currency-states/currency-states';
import { logMain } from 'src/modules/logger/logger';

export const customAxiosResponse = (response: AxiosResponse) => {
  const gameProfile = response?.data?.gameProfile;

  if (gameProfile) {
    if (gameProfile?.tutorialStates) {
      logMain.debug('Game Profile Tutorial States Found: ', response?.data?.gameProfile?.tutorialStates);

      store.dispatch(setTutorialStates(response?.data?.gameProfile?.tutorialStates || []));
    }

    if (gameProfile?.currencyStates) {
      logMain.debug('Game Profile Currency States Found: ', response?.data?.gameProfile?.tutorialStates);

      const currencyStateKeys = Object.keys(gameProfile?.currencyStates || {});

      if (currencyStateKeys.length > 0) {
        store.dispatch(
          setCurrencyStates(
            currencyStateKeys.map(key => {
              return { ...gameProfile?.currencyStates[key], id: key };
            }),
          ),
        );
      }
    }
  }

  if (!config.use_server_game_time) {
    return response;
  }

  if (response.headers['x-leg-current-time']) {
    sessionStorage.setItem('game_time_offset', String(Number(response.headers['x-leg-current-time']) - Date.now()));
  }

  return response;
};
