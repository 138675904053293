import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { formatPrice } from 'src/tools/formatPrice';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { config } from 'src/config/config';
import Styled from 'src/components/primary-marketplace/components/PrimaryPrice.styles';

interface PrimaryPriceProps {
  lpmId: string;
}

const PrimaryPrice: FC<PrimaryPriceProps> = ({ lpmId }) => {
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const { t: tCommon } = useTranslation(nameSpace.common);

  if (!listPriceMarket) return null;

  // Variables to determine market state and user purchase state
  const { price, priceCurrencyImageUrl, priceCurrencyName, purchaseType } = listPriceMarket;
  const priceUSD = price >= 0 ? formatPrice(price * config.app_variables.lc_conversion, { decimals: 2 }) : 0;
  const priceCurrency = price >= 0 ? formatPrice(price, { decimals: 0, hideSymbol: true }) : 0;

  return (
    <>
      {purchaseType === 'lpm' && (
        <Styled.Root>
          <span>{priceUSD}</span>
          <span>USD</span>
        </Styled.Root>
      )}

      {purchaseType === 'offer' && price !== null && (
        <Styled.Root>
          <img
            src={priceCurrencyImageUrl}
            width="100"
            height="100"
            alt={`${priceCurrencyName} Icon`}
            title={`${priceCurrencyName} Icon`}
          />
          <span>{priceCurrency}</span>
          <span>{priceCurrencyName}</span>
        </Styled.Root>
      )}

      {purchaseType === 'offer' && price === null && (
        <Styled.Root>
          <span>{tCommon('common.term.free')}</span>
        </Styled.Root>
      )}
    </>
  );
};

export default PrimaryPrice;
