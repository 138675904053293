import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { getRarityNameFromEnum } from 'src/tools/getRarityNameFromEnum';
import { getRarityColor } from 'src/tools/getRarityColor';
import { getAffinityColor } from 'src/tools/getAffinityColor';
import Styled from 'src/components/pages/nft/components/Subtitle.styles';

interface RarityIcon {
  name: string;
  desc: string;
  title: string;
  width: string;
  height: string;
  path: string;
}

interface RarityIcons {
  common: RarityIcon;
  uncommon: RarityIcon;
  rare: RarityIcon;
  ultraRare: RarityIcon;
  epic: RarityIcon;
  legendary: RarityIcon;
}

interface SubtitleProps {
  attributes: NonfungibleAttribute[];
  heroRarity: number | null;
  serialNumber: number | null;
  maxSerialNumber: number | null;
  style?: React.CSSProperties;
}

const Subtitle: FC<SubtitleProps> = ({ attributes, heroRarity, serialNumber, maxSerialNumber, style = {} }) => {
  const { t } = useTranslation(nameSpace.common);
  const affinityData =
    attributes && attributes.length > 0 ? attributes.find(attr => attr.traitType === 'Affinity') : null;
  const classData =
    attributes && attributes.length > 0 ? attributes.find(attr => attr.traitType === 'Class Type') : null;

  // Parse Rarity Color and Name from Data
  const rarityName = getRarityNameFromEnum(heroRarity);
  const rarityIcons: RarityIcons = t(`common.rarity.icons`, { returnObjects: true });
  const rarityIcon: RarityIcon = rarityIcons[rarityName as keyof RarityIcons];
  const validRarityData = rarityIcon && rarityName;

  // Parse Serial Number & Max Serial Number from Data
  const validSerialData = typeof serialNumber === 'number' && typeof maxSerialNumber === 'number';

  // Parse Affinity Color from Data
  const affinity = affinityData?.value as string;
  const validAffinityData = affinityData?.value && typeof affinityData?.value === 'string';

  // Parse Class Data
  const validClassData = classData?.value && typeof classData?.value === 'string';

  if (!validRarityData && !validSerialData && !validAffinityData && !validClassData) return null;

  return (
    <Styled.Root style={style}>
      {/* <Styled.RarityImg
        src={`/assets/${rarityIcon.path}`}
        width={rarityIcon.width}
        height={rarityIcon.height}
        loading="lazy"
        alt={rarityIcon.title}
        title={rarityIcon.title}
      /> */}

      {validRarityData && <Styled.Rarity rarityColor={getRarityColor(rarityName)}>{rarityIcon.name}</Styled.Rarity>}

      {validSerialData && <Styled.SerialNumber>{`${serialNumber} of ${maxSerialNumber}`}</Styled.SerialNumber>}

      {validRarityData && validSerialData && <Styled.Separator>|</Styled.Separator>}

      {validAffinityData && (
        <Styled.Affinity affinityColor={getAffinityColor(affinity.toLowerCase())}>{affinityData.value}</Styled.Affinity>
      )}

      {validClassData && <Styled.Class>{classData?.value}</Styled.Class>}
    </Styled.Root>
  );
};

export default Subtitle;
