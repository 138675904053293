import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentProfileCollection = () => (_dispatch: any) => {
  const config = {
    method: 'get',
    url: '/api/web/me/collection',
  };

  return request(config);
};
