import { useContext } from 'react';
import GameOverlayContext from 'src/context/game-overlay/GameOverlay.context';

const useGameOverlayContext = () => {
  const gameOverlayContext = useContext(GameOverlayContext);

  return gameOverlayContext;
};

export default useGameOverlayContext;
