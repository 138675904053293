/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, memo } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import Collection from 'src/components/collection/Collection';
import CustomScroll from 'src/components/custom-scroll/CustomScroll';
import Styled from 'src/components/game-overlay/pages/PageCollection.styles';

const PageCollection: FC = () => {
  const collectionItems = useAppSelector(({ collectionItems }) => collectionItems.collectionItems);
  const dependencies = [collectionItems];

  return (
    <Styled.Root>
      <CustomScroll dependencies={dependencies}>
        <Collection style={{ paddingBottom: '3rem' }} />
      </CustomScroll>
    </Styled.Root>
  );
};

export default memo(PageCollection);
