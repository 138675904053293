export const getAffinityColor = (rarity: string | null | undefined): string => {
  switch (rarity) {
    case 'dark':
      return '#B730E9';
    case 'water':
      return '#00C8FC';
    case 'fire':
      return '#F60000';
    case 'earth':
      return '#8EF600';
    case 'light':
      return '#F88A00';
    default:
      return '#ffffff';
  }
};
