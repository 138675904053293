import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => `${theme.custom.colors.Yellow}55`};
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  border: 1px solid ${({ theme }) => theme.custom.colors.Yellow};
  /* border-radius: 50%; */
  overflow: visible;
  position: absolute;
  top: 0.5rem;
  right: 0.3rem;
  cursor: pointer;

  & svg {
    display: inline-block;
    color: ${({ theme }) => theme.custom.colors.Yellow};
    fill: ${({ theme }) => theme.custom.colors.Yellow};
  }

  & svg:hover {
    display: inline-block;
    color: ${({ theme }) => theme.custom.colors.Yellow};
    fill: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const TooltipRoot = styled('div')`
  display: block;
`;

const TooltipPrimary = styled('p')`
  display: block;
  font-family: 'Barlow';
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 400;
  /* color: #ffffff; */
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const TooltipSecondary = styled('p')`
  display: block;
  margin-top: 1rem;
  font-family: 'Barlow';
  font-style: italic;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #ffffff77;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
`;

export default { Root, TooltipRoot, TooltipPrimary, TooltipSecondary };
