import { styled } from '@mui/material';

interface RootProps {
  color: string;
  size: number;
  checked: boolean;
  clickable: boolean;
}

const Root = styled('div')<RootProps>`
  display: inline-block;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  vertical-align: middle;
  border: ${({ checked }) => (checked ? '2px solid #04ae81' : 'none')};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  position: relative;
  transform: rotate(45deg);

  #checked {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    position: absolute;
    top: -15%;
    left: 42%;
    transform: translate(-50%, -50%) rotate(-45deg) scale(1, 1);
  }

  #checked {
    font-size: ${({ size }) => `${size * 2}px`};
    color: #04ae81;
  }

  #unchecked {
    display: ${({ checked }) => (checked ? 'none' : 'block')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scale(1, 1);
  }

  #unchecked {
    font-size: ${({ size }) => `${size * 1.7}px`};
    font-weight: 900;
    color: #ff0000;
  }
`;

export default { Root };
