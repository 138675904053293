import { styled, css, keyframes } from '@mui/material';
import { isMobile } from 'react-device-detect';

const getRootPaddingBottom = (isPack: boolean, mobileBreakpoint: boolean) => {
  if (isPack) {
    if (isMobile) {
      return mobileBreakpoint ? '6rem' : '6.2rem';
    }

    return mobileBreakpoint ? '3.8rem' : '3.8rem';
  }

  if (isMobile) {
    return mobileBreakpoint ? '8.1rem' : '8.3rem';
  }

  return mobileBreakpoint ? '5.8rem' : '5.9rem';
};

const fadeIn = keyframes`
    0% {
        opacity: 0;
        visibility: hidden;
    }
    1% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
        visibility: visible;
    }
    99% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
`;

interface RootProps {
  isPack: boolean;
  mobileBreakpoint: boolean;
}

const Root = styled('div')<RootProps>`
  display: block;
  width: 100%;
  padding-bottom: ${({ isPack, mobileBreakpoint }) => getRootPaddingBottom(isPack, mobileBreakpoint)};
  position: relative;
  border-radius: 0.2rem;
  overflow: visible;
  cursor: pointer;
`;

interface AssetContainerProps {
  hoverable?: boolean;
}

const AssetContainer = styled('div')<AssetContainerProps>`
  background: linear-gradient(0deg, #ffffff1a 0%, #000000 80%);
  display: block;
  width: 100%;
  aspect-ratio: 1;
  box-sizing: border-box;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  position: relative;

  /* ${({ hoverable }) =>
    hoverable &&
    css`
      &:hover {
        & > div {
          opacity: 1;
        }

        & > img {
          animation: ${hoverable ? `${fadeOut} 0.5s ease-in-out forwards` : 'none'};
        }

        & > video {
          animation: ${hoverable ? `${fadeIn} 0.5s ease-in-out forwards` : 'none'};
        }
      }
    `} */

  &:hover {
    & > div {
      opacity: 1;
    }

    & > img {
      animation: ${fadeOut} 0.5s ease-in-out forwards;
    }

    & > video {
      animation: ${fadeIn} 0.5s ease-in-out forwards;
    }
  }
`;

const Img = styled('img')`
  display: block;
  height: calc(100% - 1rem);
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  animation: ${fadeIn} 0.2s ease-in-out forwards;
`;

const Video = styled('video')`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: fit; // use cover to remove distortion
  opacity: 1;
  animation: ${fadeOut} 0.2s ease-in-out forwards;
`;

export default { Root, AssetContainer, Img, Video };
