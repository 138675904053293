import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const linkFarcaster = (options: RequestOptions) => (_dispatch: any) => {
  const { data } = options;

  const config = {
    method: 'post',
    url: '/api/web/farcaster/link',
    data: {
      token: data?.token || '',
    },
  };

  return request(config);
};
