import { styled } from '@mui/material';

interface RootProps {
  singleMarketExists: boolean;
}

const Root = styled('div')<RootProps>`
  display: flex;
  min-width: 100%;
  height: 100%;
  padding: ${({ singleMarketExists }) => (singleMarketExists ? '3.5rem 0 3.5rem 0' : '3.5rem 3.5rem 3.5rem 0')};
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 1100px) {
    padding: ${({ singleMarketExists }) => (singleMarketExists ? '3.5rem 0 3.5rem 0' : '3.5rem 2.5rem 3.5rem 0')};
  }

  @media (max-width: 900px) {
    padding: ${({ singleMarketExists }) => (singleMarketExists ? '3.5rem 0 3.5rem 0' : '3.5rem 2.5rem 3.5rem 0')};
  }

  @media (max-height: 700px) {
    padding: ${({ singleMarketExists }) => (singleMarketExists ? '2.5rem 0 2.5rem 0' : '2.5rem 2.5rem 2.5rem 0')};
  }

  @media (max-height: 600px) {
    padding: ${({ singleMarketExists }) => (singleMarketExists ? '2.5rem 0 2.5rem 0' : '2.5rem 2rem 2.5rem 0')};
  }

  @media (max-height: 500px) {
    padding: ${({ singleMarketExists }) => (singleMarketExists ? '2.2rem 0 2.2rem 0' : '2.2rem 2.5rem 2.2rem 0')};
  }
`;

const FullWidthContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  /* align-content: stretch; */
  position: relative;
`;

export default {
  Root,
  FullWidthContainer,
};
