import { styled } from '@mui/material';

const Root = styled('svg')`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
`;

export default { Root };
