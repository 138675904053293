import { useContext } from 'react';
import AlertOverrideContext from 'src/context/alert-override/AlertOverride.context';

const useAlertOverrideContext = () => {
  const alertOverrideContext = useContext(AlertOverrideContext);

  return alertOverrideContext;
};

export default useAlertOverrideContext;
