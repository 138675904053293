import { useContext } from 'react';
import ProgressiveWebAppContext from 'src/context/progressive-web-app/ProgressiveWebApp.context';

const useProgressiveWebAppContext = () => {
  const progressiveWebAppContext = useContext(ProgressiveWebAppContext);

  return progressiveWebAppContext;
};

export default useProgressiveWebAppContext;
