import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  color: #ffffff;

  & > span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
    font-weight: 400;
  }

  @media (max-width: 1050px) {
    font-size: 1rem;
    line-height: 1rem;
  }

  @media (max-width: 900px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-height: 700px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-height: 500px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-height: 450px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
    font-weight: 300;
  }

  @media (max-height: 350px) {
    font-size: 0.6rem;
    line-height: 0.8rem;
    font-weight: 300;
  }

  /* @media (max-height: 350px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
    font-weight: 300;
  } */
`;

export default {
  Root,
};
