import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { useDiscordAuthentication } from 'src/hooks/useDiscordAuthentication';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useQuery } from 'src/hooks/useQuery';
import { getLoadedProfileByUsernameOrPlayerId } from 'src/redux/slices/loaded-profiles/loaded-profiles';
import { setSnackbar } from 'src/redux/slices/snackbar/snackbar';
import ButtonDiscordLogin from 'src/components/input/ButtonDiscordLogin';
import StatusIndicator from 'src/components/data-display/StatusIndicator';
import BaseIcon from 'src/components/icons/BaseIcon';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import { Tooltip } from '@mui/material';
import Styled from 'src/components/pages/profile/components/DiscordStatus.styles';

const DiscordStatus: FC = () => {
  const { playerId = '' } = useParams();
  const { unlinkDiscord } = useDiscordAuthentication({ fromContext: 'profile_discord_unlink' });
  const [inView1, setInView1] = useState(false);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const loadedProfile = useAppSelector(state => getLoadedProfileByUsernameOrPlayerId(state, playerId));
  const dispatch = useAppDispatch();
  const params: (string | null)[] = useQuery(urlParams.profile);
  const discordError = getURLParamValue('error', urlParams.profile, params);
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const profile = isCurrentProfile ? currentProfile : loadedProfile;
  const discordLinked = !!profile?.discordUser;

  useEffect(() => {
    if (discordError) {
      dispatch(setSnackbar({ severity: 'error', msg: discordError }));
    }
  }, [discordError, dispatch]);

  if (!profile || Object.keys(profile).length === 0) return null;

  return (
    <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
      <Styled.Root linked={discordLinked} className={`inview animated ${inView1 ? 'fadeInUp' : ''}`}>
        {isCurrentProfile && (
          <ButtonDiscordLogin
            fullWidth={false}
            style={{
              marginRight: '0.6rem',
              padding: '0.4rem 0.8rem 0.3rem 0.8rem',
            }}
            textStyle={{
              fontSize: '0.8rem',
              lineHeight: '1rem',
            }}
          />
        )}

        {discordLinked && (
          <Styled.UsernameContainer id="discord-name">
            <BaseIcon
              icon={<DiscordSocialIcon />}
              fill="#ffffff99"
              height="18px"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                margin: '0 0.3rem 0 0',
              }}
            />

            <Styled.Username>{`Discord - ${profile?.discordUser?.username || 'UnknownUser'}`}</Styled.Username>
          </Styled.UsernameContainer>
        )}

        {isCurrentProfile && (
          <Styled.StatusContainer id="discord-status">
            <StatusIndicator active={discordLinked} style={{ margin: '0 0.4rem 0 0' }} />

            <Styled.StatusText linked={discordLinked}>
              {discordLinked ? 'Connected' : 'Not Connected'}
            </Styled.StatusText>
          </Styled.StatusContainer>
        )}

        {discordLinked && (
          <Tooltip title="Unlink Discord">
            <Styled.UnlinkButton onClick={unlinkDiscord}>
              <span className="material-icons-outlined">link_off</span>
            </Styled.UnlinkButton>
          </Tooltip>
        )}
      </Styled.Root>
    </Waypoint>
  );
};

export default DiscordStatus;
