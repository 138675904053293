import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface CustomScrollContentProps {
  enableScrollbar: boolean;
  scrollbarWidth: number;
  scrollbarGutterWidth: number;
}

const CustomScrollContent = styled('div')<CustomScrollContentProps>`
  display: block;
  width: 100%;
  height: 100%;
  padding: ${({ enableScrollbar, scrollbarWidth, scrollbarGutterWidth }) =>
    enableScrollbar
      ? `2.5rem ${14 + scrollbarWidth + scrollbarGutterWidth * 2}px 2.5rem 2.5rem`
      : `2.5rem 2.5rem 2.5rem 2.5rem`};
  /* scroll-padding-bottom: 3rem; */
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-mask-image: linear-gradient(
    to top,
    transparent 0%,
    transparent 2.5rem,
    #000 3.5rem,
    #000 calc(100% - 2.5rem),
    transparent calc(100% - 1.7rem),
    transparent 100%
  );
  mask-image: linear-gradient(
    to top,
    transparent 0%,
    transparent 2.5rem,
    #000 3.5rem,
    #000 calc(100% - 2.5rem),
    transparent calc(100% - 1.7rem),
    transparent 100%
  );

  @media (max-width: 900px) or (max-height: 500px) {
    padding: ${({ enableScrollbar, scrollbarWidth, scrollbarGutterWidth }) =>
      enableScrollbar
        ? `1.7rem ${14 + scrollbarWidth + scrollbarGutterWidth * 2}px 1.5rem 1.5rem`
        : `1.7rem 1.5rem 1.5rem 1.5rem`};
    -webkit-mask-image: linear-gradient(
      to top,
      transparent 0%,
      transparent 1.5rem,
      #000 2.5rem,
      #000 calc(100% - 1.5rem),
      transparent calc(100% - 0.7rem),
      transparent 100%
    );
    mask-image: linear-gradient(
      to top,
      transparent 0%,
      transparent 1.5rem,
      #000 2.5rem,
      #000 calc(100% - 2rem),
      transparent calc(100% - 1.5rem),
      transparent 100%
    );
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface CustomScrollScrollbarProps {
  enableScrollbar: boolean;
  scrollbarWidth: number;
  scrollbarGutterWidth: number;
}

const CustomScrollScrollbar = styled('div')<CustomScrollScrollbarProps>`
  display: block;
  width: ${({ scrollbarWidth, scrollbarGutterWidth }) => `${scrollbarWidth + scrollbarGutterWidth * 2}px`};
  box-sizing: border-box;
  position: absolute;
  top: 2.5rem;
  right: ${({ enableScrollbar }) => (enableScrollbar ? '0.8rem' : '-100%')};
  bottom: 2.7rem;
  opacity: ${({ enableScrollbar }) => (enableScrollbar ? '1' : '0')};
  pointer-events: ${({ enableScrollbar }) => (enableScrollbar ? 'auto' : 'none')};
  transition: right 0.8s ease-in-out;

  @media (max-width: 900px) or (max-height: 500px) {
    top: 1.7rem;
    bottom: 1.7rem;
  }
`;

interface CustomScrollTrackAndThumbContainerProps {
  trackButtonOffset: number;
  buttonHeight: number;
  scrollbarWidth: number;
}

const CustomScrollTrackAndThumbContainer = styled('div')<CustomScrollTrackAndThumbContainerProps>`
  display: block;
  height: ${({ trackButtonOffset, buttonHeight }) => `calc(100% - ${buttonHeight * 2 + trackButtonOffset * 2}px)`};
  width: ${({ scrollbarWidth }) => `${scrollbarWidth}px`}; /* must have some width */
  position: absolute;
  top: ${({ trackButtonOffset, buttonHeight }) => `${buttonHeight + trackButtonOffset}px`};
  bottom: ${({ trackButtonOffset, buttonHeight }) => `${buttonHeight + trackButtonOffset}px`};
  left: 50%;
  transform: translateX(-50%);
`;

interface CustomScrollTrackProps {
  trackWidth: number;
}

const CustomScrollTrack = styled('div')<CustomScrollTrackProps>`
  background-color: ${({ theme }) => `${theme.custom.colors.BgLayer3Accent}22`};
  display: block;
  width: ${({ trackWidth }) => `${trackWidth}px`}; /* must have some width */
  /* border: 1px solid ${({ theme }) => `${theme.custom.colors.BgLayer3Accent}ff`}; */
  /* border-radius: 12px; */
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

interface CustomScrollThumbProps {
  thumbWidth: number;
  thumbHeight: number;
}

const CustomScrollThumb = styled('div')<CustomScrollThumbProps>`
  background-color: ${({ theme }) => `${theme.custom.colors.BgLayer3Accent}ff`};
  display: block;
  height: ${({ thumbHeight }) => `${thumbHeight}px`};
  width: ${({ thumbWidth }) => `${thumbWidth}px`}; /* must have some width */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export default {
  Root,
  CustomScrollContent,
  CustomScrollScrollbar,
  CustomScrollTrackAndThumbContainer,
  CustomScrollTrack,
  CustomScrollThumb,
};
