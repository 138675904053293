// We only use 'prod' Poole env because we use production Scale Server for our dev environments.
export const getPooleEnv = (e: string): TEnvironmentsKeys => {
  switch (e) {
    case 'local':
      return 'prod';
    case 'dev1':
      return 'prod';
    case 'dev2':
      return 'prod';
    case 'dev3':
      return 'prod';
    case 'dev4':
      return 'prod';
    case 'dev5':
      return 'prod';
    case 'dev6':
      return 'prod';
    case 'staging1':
      return 'prod';
    case 'staging2':
      return 'prod';
    case 'staging3':
      return 'prod';
    case 'staging4':
      return 'prod';
    case 'staging5':
      return 'prod';
    case 'qa':
      return 'prod';
    case 'prod':
      return 'prod';
    default:
      return 'prod';
  }
};
