import { styled } from '@mui/material';

const Root = styled('div')`
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: flex-start;
  flex-wrap: wrap;
`;

interface ItemProps {
  width: string;
  margin: string;
  noNthHorizMargin: number;
}

const Item = styled('div')<ItemProps>`
  display: inline-block;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  position: relative;

  &:nth-of-type(${({ noNthHorizMargin }) => noNthHorizMargin}n) {
    margin-right: 0;
    margin-left: 0;
  }
`;

export default {
  Root,
  Item,
};
