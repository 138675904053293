import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAuthentication } from 'src/hooks/useAuthentication';
import ButtonHex from 'src/components/input/ButtonHex';
import { useMediaQuery, useTheme, IconButton, CircularProgress } from '@mui/material';
import Styled from 'src/components/input/ButtonLogin.styles';
import { config } from 'src/config/config';

export interface IButtonSVGProps {
  variant?: 'bronze' | 'silver' | 'gold' | 'white';
  width?: number;
  height?: number;
  title?: string;
  useMobileIcon?: boolean;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const ButtonLogin: FC<IButtonSVGProps> = ({
  variant = 'white',
  width = 155,
  height = 61,
  title = '',
  useMobileIcon = true,
  textStyle = {},
  style = {},
}) => {
  const { processing, initiateAuthentication } = useAuthentication({ fromContext: 'header' });
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(config.ui_variables.mobile_breakpoints.header));

  return mobileView && useMobileIcon ? (
    <Styled.AccountButton>
      <IconButton
        id="account-dropdown-button"
        aria-controls={'account-dropdown-menu'}
        aria-haspopup="true"
        aria-expanded={'true'}
        disabled={processing}
        onClick={initiateAuthentication}
      >
        {processing ? (
          <CircularProgress
            size="1.1rem"
            sx={{
              color: theme.custom.colors.Yellow,
            }}
          />
        ) : (
          <span className="material-icons">login</span>
        )}
      </IconButton>
    </Styled.AccountButton>
  ) : (
    <ButtonHex
      hoverEffect
      variant={variant}
      type="button"
      width={width}
      height={height}
      cornerOffset={14}
      title={title || t('common.auth.register.sign.in')}
      processing={processing}
      disabled={processing}
      textStyle={textStyle}
      style={style}
      onClick={initiateAuthentication}
    />
  );
};

export default ButtonLogin;
