import { styled } from '@mui/material';

const CardsWrapper = styled('div')`
  box-sizing: border-box;
  position: relative;
  top: 9rem;
  margin-top: -7rem;
  width: 100%;
  .swiper {
    overflow: visible;

    .swiper-slide {
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 85%;
        max-width: 100%;
        height: auto;
        opacity: 0.6;
        transition: opacity 0.5s ease-in-out;
      }
      .zoom {
        position: relative;
        overflow: hidden;
        width: 90%;
        left: 4%;
        top: 1.4vw;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.1);
          height: 200%;
          width: 160%;
          transform: skew(15deg);
          transition: all 0.5s ease-in-out;
        }

        img {
          transition: transform 2s ease-in-out;
          vertical-align: middle;
          backface-visibility: hidden;
          transform: scale(1, 1) translateZ(0);
        }
      }
      &:hover {
        svg {
          opacity: 1;
        }
        .zoom {
          &:after {
            width: 0;
          }
          img {
            transform: scale(1.03, 1.03) translateZ(0);
          }
        }
      }
    }
  }
`;

export default { CardsWrapper };
