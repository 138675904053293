import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  /* max-width: 75rem; */
  padding: 3rem 3rem 0 3rem;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    padding: 3rem 2rem 0 2rem;
  }
`;

const DetailsContainer = styled('div')`
  display: block;
  width: 100%;
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 700;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
`;

const Subtitle = styled('p')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.2rem;
  color: #fff;

  & > span:nth-of-type(1) {
    margin-right: 0.3rem;
    font-weight: 400;
  }

  & > span:nth-of-type(2) {
    margin-right: 0.3rem;
    font-weight: 700;
  }

  & .material-icons {
    display: inline-block;
    margin-bottom: 0.1rem;
    vertical-align: middle;
    font-size: 0.9rem;
    color: #2677ec;
  }

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const OverviewContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;

  & > span {
    margin-top: 0.5rem;

    @media (max-width: 768px) {
      margin-top: 0.3rem;
    }
  }
`;

const OverviewLabel = styled('span')`
  display: inline-block;
  margin-right: 0.3rem;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #aaa;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const OverviewValue = styled('span')`
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  color: #aaa;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const OverviewSeparator = styled('span')`
  display: inline-block;
  margin: 0 0.5rem;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  color: #aaa;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const Desc = styled('p')`
  display: block;
  width: 100%;
  max-width: 55rem;
  margin-top: 0.9rem;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

const MetricsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;

  & > div {
    margin: 0.5rem 0 0 0;
  }

  & > div:not(:last-child) {
    margin: 0.5rem 2.5rem 0 0;
  }
`;

const Metric = styled('div')`
  display: inline-block;
  vertical-align: middle;
`;

const MetricValue = styled('p')`
  display: block;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 700;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.2rem;
  }
`;

const MetricLabel = styled('p')`
  display: block;
  margin-right: 0.3rem;
  text-transform: lowercase;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #aaa;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }
`;

export default {
  Root,
  CenteredContainer,
  DetailsContainer,
  Title,
  Subtitle,
  Desc,
  OverviewContainer,
  OverviewLabel,
  OverviewValue,
  OverviewSeparator,
  MetricsContainer,
  Metric,
  MetricValue,
  MetricLabel,
};
