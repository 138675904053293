import { FC, useEffect, useState } from 'react';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useAppSelector } from 'src/hooks/useAppSelector';
import SectionHero from 'src/components/pages/marketplace/components/SectionHero';
import SectionDetails from 'src/components/pages/marketplace/components/SectionDetails';
import SectionResults from 'src/components/pages/marketplace/components/SectionResults';
import Styled from 'src/components/pages/marketplace/Marketplace.styles';

const Marketplace: FC = () => {
  const [loaded, setLoaded] = useState(false);
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const navigateInternal = useAppNavigateInternal();

  useEffect(() => {
    if (!loaded) {
      // const marketplaceEnabled = currentProfile?.features?.marketplace?.enabled;

      if (!authenticated) {
        navigateInternal('/page-not-found', { pooleData: { fromContext: 'marketplace' } });
      } else {
        setLoaded(true);
      }
    }
  }, [authenticated, loaded, navigateInternal]);

  if (!loaded) return <Styled.Root />;

  return (
    <Styled.Root>
      <SectionHero />
      <SectionDetails />
      <SectionResults />
    </Styled.Root>
  );
};

export default Marketplace;
