import { createContext } from 'react';

const getViewportWidth = () => {
  return Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0);
};

const getViewportHeight = () => {
  return Math.max(document?.documentElement?.clientHeight || 0, window?.innerHeight || 0);
};

export interface IViewportContext {
  viewportWidth: number;
  setViewportWidth?: (width: number) => void;
  viewportHeight: number;
  setViewportHeight?: (height: number) => void;
  landscape: boolean;
  setLandscape?: (landscape: boolean) => void;
}

export const initialContext: IViewportContext = {
  viewportWidth: getViewportWidth(),
  setViewportWidth: () => undefined,
  viewportHeight: getViewportHeight(),
  setViewportHeight: () => undefined,
  landscape: getViewportWidth() > getViewportHeight(),
  setLandscape: () => undefined,
};

const ViewportContext = createContext<IViewportContext>(initialContext);

ViewportContext.displayName = 'ViewportContext';

export default ViewportContext;
