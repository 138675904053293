import { FC, ReactNode } from 'react';
import Styled from './Backdrop.styles';

interface IBackdrop {
  children: ReactNode;
  show: boolean;
}

const Backdrop: FC<IBackdrop> = ({ children, show }) => <Styled.Root show={show ? 1 : 0}>{children}</Styled.Root>;

export default Backdrop;
