import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  /* max-width: 75rem; */
  padding: 2rem 3rem;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    padding: 2rem 2rem;
  }
`;

export default {
  Root,
};
