import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import usePendingPurchasesContext from 'src/hooks/usePendingPurchasesContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getNonfungibleBySeriesAndSerialNumber } from 'src/redux/slices/nonfungibles/nonfungibles';
import PageError from 'src/components/feedback/PageError';
import Alert from 'src/components/feedback/Alert';
import BackButton from 'src/components/pages/nft/components/BackButton';
import NFTPreview from 'src/components/pages/nft/components/NFTPreview';
import NFTDetails from 'src/components/pages/nft/components/NFTDetails';
import Styled from 'src/components/pages/nft/NFT.styles';
import { useLoadNonfungible } from 'src/hooks/useLoadNonfungible';

interface NFTProps {
  disablePageError?: boolean;
}

const NFT: FC<NFTProps> = ({ disablePageError = false }) => {
  const { seriesId = '', serialNumber = '' } = useParams();
  const { loading, error } = useLoadNonfungible({
    seriesId,
    serialNumber,
    redirectOnError: true,
    fromContext: 'nft_details',
  });
  const { concurrentPurchaseLimit } = usePendingPurchasesContext();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const pendingPurchases = useAppSelector(({ pendingPurchases }) => pendingPurchases.pendingPurchases);
  const nonfungible = useAppSelector(state =>
    getNonfungibleBySeriesAndSerialNumber(state, seriesId, parseInt(serialNumber, 10)),
  );
  const navigateInternal = useAppNavigateInternal();
  const { t } = useTranslation(nameSpace.common);
  const isCurrentProfile = currentProfile.id === nonfungible?.playerId;
  const listed = nonfungible?.listed;

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;
  // If data loaded incorrectly return page error
  if (!disablePageError && (error || !nonfungible)) return <PageError />;

  return (
    <Styled.Root>
      <Styled.CenteredContainer>
        <BackButton />

        {!isCurrentProfile &&
          listed &&
          pendingPurchases.length > 0 &&
          (pendingPurchases.length === concurrentPurchaseLimit ? (
            <Alert
              severity={'error'}
              msg={t('common.message.purchase.pending.max')}
              action={t('common.nav.profile')}
              onAction={() =>
                navigateInternal('/profile?default_tab=3', {
                  pooleData: { fromContext: 'nft_details' },
                })
              }
              style={{ marginTop: '1.5rem' }}
            />
          ) : (
            <Alert
              severity={'warning'}
              msg={t('common.message.purchase.pending')}
              action={t('common.nav.profile')}
              onAction={() =>
                navigateInternal('/profile?default_tab=3', {
                  pooleData: { fromContext: 'nft_details' },
                })
              }
              style={{ marginTop: '1.5rem' }}
            />
          ))}

        <Styled.ContentContainer>
          <NFTPreview seriesId={seriesId} serialNumber={serialNumber} />
          <NFTDetails seriesId={seriesId} serialNumber={serialNumber} />
        </Styled.ContentContainer>
      </Styled.CenteredContainer>
    </Styled.Root>
  );
};

export default NFT;
