import { createContext } from 'react';

export interface IAccount {
  id: string;
  email: string;
  name: string;
}

export interface ISessionContext {
  loading: boolean;
  account: IAccount | null;
  showProfileMenu: boolean;
  showNavigationMenu: boolean;
  onUpdateProfileMenuVisibility: (visibility: boolean) => void;
  onUpdateNavigationMenuVisibility: (visibility: boolean) => void;
  onUpdateAccount: (account: IAccount | null) => void;
}

export const initialContext: ISessionContext = {
  loading: false,
  account: null,
  showProfileMenu: false,
  showNavigationMenu: false,
  onUpdateNavigationMenuVisibility: () => undefined,
  onUpdateAccount: () => undefined,
  onUpdateProfileMenuVisibility: () => undefined,
};

const SessionContext = createContext<ISessionContext>(initialContext);

SessionContext.displayName = 'SessionContext';

export default SessionContext;
