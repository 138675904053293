// Define allowed keys for prop value
const props = {
  first_visit_logged: '',
  device_id: '',
  device_player_ids: '',
  session_token: '',
  session_token_start: '',
  session_token_exp: '',
  session_token_last_refresh: '',
  session_token_is_refreshing: '',
  enable_redirect: '',
  redirect_path: '',
  legal_notice_acknowledged: '',
  opted_out_of_pwa: '', // Clear this on Logout (in case another user logs in on same device)
  gameplay_enable_pips_unopened_packs: '',
  audio_enable_click_sounds: '',
  discord_widget_enable_welcome_message: '',
  discord_widget_enable_notifications: '',
  discord_widget_enable_indicators: '',
  discord_widget_show_widget: '',
  navigated_to_play_page: '',
};
const disabledGroupClearProps = [
  'first_visit_logged',
  'device_id',
  'device_player_ids',
  'legal_notice_acknowledged',
  'gameplay_enable_pips_unopened_packs',
  'audio_enable_click_sounds',
  // 'discord_widget_enable_notifications',
  // 'discord_widget_enable_indicators',
  // 'discord_widget_show_widget',
  'navigated_to_play_page',
];

type LocalStorageValue = string | undefined | null;
type Prop = keyof typeof props;

// Function to get property value in localStorage
const get = (prop: Prop): LocalStorageValue => {
  return localStorage.getItem(prop);
};

// Function to get all property values in localStorage
const getAll = (): Record<Prop, LocalStorageValue> => {
  const keys = Object.keys(props) as Prop[];
  const result: Record<Prop, LocalStorageValue> = { ...props };

  keys.forEach((key: Prop) => {
    result[key] = get(key);
  });

  return result;
};

// Function to set property value in localStorage
const set = (prop: Prop, val: string): void => {
  return localStorage.setItem(prop, val);
};

// Function to remove property value in localStorage
const remove = (prop: Prop): void => {
  return localStorage.removeItem(prop);
};

// Function to clear all app-related property values in localStorage
const clear = (): void => {
  const keys = Object.keys(props) as Prop[];

  keys.forEach((key: Prop) => {
    if (disabledGroupClearProps.indexOf(key) === -1) set(key, '');
  });
};

export const LocalStorage = {
  get,
  getAll,
  set,
  remove,
  clear,
};
