import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useLeaderboardsContext from 'src/hooks/useLeaderboardsContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getLeaderboardById } from 'src/redux/slices/leaderboards/leaderboards';
import ButtonHex from 'src/components/input/ButtonHex';
import Styled from 'src/components/pages/leaderboard/components/SectionHero.styles';

type Hero = {
  path: string;
};

const SectionHero: FC = () => {
  const { leaderboardId = '' } = useParams();
  const { selectedCategory } = useLeaderboardsContext();
  const leaderboard = useAppSelector(state => getLeaderboardById(state, leaderboardId));
  const { t } = useTranslation(nameSpace.leaderboard);
  const hero: Hero = t('leaderboard.hero.asset', { returnObjects: true });
  const { displayName = '' } = leaderboard || {};

  return (
    <Styled.Root style={{ backgroundImage: `url(/assets/${hero.path})` }}>
      <Styled.HeroBottomBorder />

      <Styled.Overlay className={'inview animated fadeIn'}>
        <Styled.CopyContainer>
          <Styled.Title>{t('leaderboard.hero.title')}</Styled.Title>

          <ButtonHex
            variant="gold"
            hoverEffect={false}
            width={200}
            height={50}
            fontSize={14}
            enableDropShadow
            title={selectedCategory || displayName}
            textStyle={{ paddingTop: '1px' }}
            style={{ display: 'block', margin: '1.5rem auto 0 auto' }}
            onClick={() => null}
          />
        </Styled.CopyContainer>
      </Styled.Overlay>
    </Styled.Root>
  );
};

export default SectionHero;
