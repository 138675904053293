import { config } from 'src/config/config';
import { isMobile } from 'react-device-detect';
import { LocalStorage } from 'src/local-storage/local-storage';
import {
  pooleWebsite,
  // pooleUnity
} from 'src/modules/poole/poole';
import metadata from 'src/metadata/metadata.json';
import packageJson from '../../../package.json';

/**
 * Useable event keys:
 *
 * aid?: string; // App identifier (HANDLED)
 * anm?: string; // App name
 * av?: string; // App version AKA "{app_version}-{build-version}-{environment}" (HANDLED)
 * cts: string; // Current timestamp (DEFAULTED)
 * details?: string; // Any relevant details
 * did?: string; // Device identifier (DEFAULTED)
 * dma?: string; // Device manufacturer
 * dmo?: string; // Device model
 * dov?: string; // Device os version
 * dtp?: string; // Device type
 * ec?: string; // Event category
 * email?: string; // Any relevant email address
 * en?: string; // Event name
 * id?: string; // Any relevant identifier
 * name?: string; // Any relevant name
 * orgid?: string; // Org identifier
 * orgnm?: string; // Org name
 * reason?: string; // Any relevant reason
 * scrnsz?: string; // Screen size
 * seq?: number | string; // Event sequence
 * sv: string; // SDK version (DEFAULTED)
 * src?: string; // Event source
 * taid?: string; // Any relevant target app identifier
 * temail?: string; // Any relevant target email address
 * tuid?: string; // Target user identifier
 * ua?: string; // User agent
 * uid?: string; // User identifier (DEFAULTED)                     *** IMPORTANT ***
 * unm?: string; // User name
 * url?: string; // Any relevant url
 * v?: string; // Protocol version. By default is 1.0
 */

type PooleDataset = 'website' | 'unity' | null | undefined;
interface WindowSize {
  winWidth: number;
  winHeight: number;
}

const getWindowSize = (): WindowSize => {
  return {
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  };
};

const getBaseDetails = (dataset: PooleDataset): string => {
  const deviceId = LocalStorage.get('device_id') || '';
  const devicePlayerIds = LocalStorage.get('device_player_ids') || '';
  const splitDevicePlayerIds = devicePlayerIds.length === 0 ? [] : devicePlayerIds.split(',');
  const { winWidth, winHeight } = getWindowSize();
  const gameClientActive = dataset === 'unity';
  const buildNumber = metadata.build;

  return `env~${config.env}|bn~${buildNumber}|serverUrl~${config.base_url.lhu}|browserSession~${
    config.uuid
  }|deviceId~${deviceId}|devicePlayerIds~${devicePlayerIds}|deviceAccountsDetected~${splitDevicePlayerIds.length.toString()}|mobile~${isMobile}|winWidth~${winWidth}|winHeight~${winHeight}|gameClientActive~${gameClientActive}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logPooleEvent = (dataset: PooleDataset, event: Record<string, any>): void => {
  switch (dataset) {
    case 'website':
      pooleWebsite.logEvent(event);
      break;
    case 'unity':
      // pooleUnity.logEvent(event);
      pooleWebsite.logEvent(event);
      break;
    default:
      pooleWebsite.logEvent(event);
      break;
  }
};

// GLOBAL POOLE PARAMS
const eventGlobals = {
  ec: 'WEBSITE',
  anm: packageJson.name,
  src: `${window.location.protocol}//${window.location.host}`,
  uid: '',
};

// App Initialization Event
const InitializeApp = ({ dataset, referrer }: InitializeAppEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const firstVisitLogged = LocalStorage.get('first_visit_logged') === 'true';
  const details = `${baseDetails}|referrer~${referrer}|firstVisit~${!firstVisitLogged}`;
  const event = {
    ...eventGlobals,
    en: 'InitializeApp',
    // url: window.location.pathname,
    details,
  };
  logPooleEvent(dataset, event);
};

// Start Authentication Event
const AuthStart = ({ dataset, from, fromContext }: AuthStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    en: 'AuthStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Start Authentication Fail Event
const AuthStartFail = ({ dataset, from, fromContext, reason }: AuthStartFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    en: 'AuthStartFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Start Authentication Success Event
const AuthStartSuccess = ({ dataset, from, fromContext }: AuthStartSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    en: 'AuthStartSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Finish Authentication Event
const AuthFinish = ({ dataset, from, fromContext }: AuthFinishEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    en: 'AuthFinish',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Finish Authentication Fail Event
const AuthFinishFail = ({ dataset, from, fromContext, reason }: AuthFinishFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    en: 'AuthFinishFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Finish Authentication Success Event
const AuthFinishSuccess = ({ dataset, playerId, authType, from, fromContext }: AuthFinishSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|authType~${authType}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'AuthFinishSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Renew Authentication Event
const AuthRenew = ({ dataset, playerId, from }: AuthRenewEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'AuthRenew',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Renew Authentication Fail Event
const AuthRenewFail = ({ dataset, playerId, from, reason }: AuthRenewFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'AuthRenewFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Renew Authentication SuccessEvent
const AuthRenewSuccess = ({ dataset, playerId, from }: AuthRenewSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'AuthRenewSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Logout Event
const Logout = ({ dataset, playerId, from, fromContext }: LogoutEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'Logout',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Logout Fail Event
const LogoutFail = ({ dataset, playerId, from, fromContext, reason }: LogoutFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'LogoutFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Logout Success Event
const LogoutSuccess = ({ dataset, playerId, from, fromContext }: LogoutSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'LogoutSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Page Load Event
const PageLoad = ({ dataset, playerId, pageName, url }: PageLoadEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|to~${url}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PageLoad',
    name: pageName,
    // url,
    details,
  };
  logPooleEvent(dataset, event);
};

// Internal Navigation Event
const NavigateInternal = ({ dataset, playerId, from, to, fromContext }: NavigateInternalEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|to~${to}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'NavigateInternal',
    // url: to,
    details,
  };
  logPooleEvent(dataset, event);
};

// External Navigation Event
const NavigateExternal = ({ dataset, playerId, pageName, from, to, fromContext }: NavigateExternalEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|to~${to}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'NavigateExternal',
    name: pageName,
    // url: to,
    details,
  };
  logPooleEvent(dataset, event);
};

// Create Listing Start Event
const CreateListingStart = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
}: CreateListingStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'CreateListingStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Create Listing Price Event
const CreateListingPrice = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  priceLC,
  priceUSD,
}: CreateListingPriceEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'CreateListingPrice',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Create Listing Confirm Event
const CreateListingConfirm = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  priceLC,
  priceUSD,
}: CreateListingConfirmEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'CreateListingConfirm',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Create Listing Confirm Fail Event
const CreateListingConfirmFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  priceLC,
  priceUSD,
  reason,
}: CreateListingConfirmFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'CreateListingFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Create Listing Confirm Success Event
const CreateListingConfirmSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  priceLC,
  priceUSD,
}: CreateListingConfirmSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'CreateListingSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Create Listing Close Event
const CreateListingClose = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  priceLC,
  priceUSD,
}: CreateListingCloseEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'CreateListingClose',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Remove Listing Start Event
const RemoveListingStart = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
}: RemoveListingStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'RemoveListingStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Remove Listing Confirm Event
const RemoveListingConfirm = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
}: RemoveListingConfirmEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'RemoveListingConfirm',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Remove Listing Confirm Fail Event
const RemoveListingConfirmFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  reason,
}: RemoveListingConfirmFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'RemoveListingConfirmFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Remove Listing Confirm Success Event
const RemoveListingConfirmSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
}: RemoveListingConfirmSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'RemoveListingConfirmSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Remove Listing Close Event
const RemoveListingClose = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
}: RemoveListingCloseEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'RemoveListingClose',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Open Pack Start Event
const OpenPackStart = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  packTier,
}: OpenPackStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|packTier~${packTier}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'OpenPackStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Open Pack Confirm Event
const OpenPackConfirm = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  packTier,
}: OpenPackConfirmEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|packTier~${packTier}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'OpenPackConfirm',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Open Pack Confirm Fail Event
const OpenPackConfirmFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  packTier,
  reason,
}: OpenPackConfirmFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|packTier~${packTier}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'OpenPackConfirmFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Open Pack Confirm Success Event
const OpenPackConfirmSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  packTier,
}: OpenPackConfirmSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|packTier~${packTier}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'OpenPackConfirmSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Open Pack Close Event
const OpenPackClose = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  packTier,
}: OpenPackCloseEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|packTier~${packTier}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'OpenPackClose',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Open Pack Claim Event
const OpenPackClaim = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  packTier,
}: OpenPackClaimEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|packTier~${packTier}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'OpenPackClaim',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Edit Username Start Event
const EditUsernameStart = ({ dataset, playerId, from, fromContext }: EditUsernameStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'EditUsernameStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Edit Username Confirm Event
const EditUsernameConfirm = ({ dataset, playerId, from, fromContext }: EditUsernameConfirmEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'EditUsernameConfirm',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Edit Username Confirm Event
const EditUsernameConfirmFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  reason,
}: EditUsernameConfirmFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'EditUsernameConfirmFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Edit Username Confirm Success Event
const EditUsernameConfirmSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
}: EditUsernameConfirmSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'EditUsernameConfirmSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Edit Username Cancel Event
const EditUsernameCancel = ({ dataset, playerId, from, fromContext }: EditUsernameCancelEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'EditUsernameCancel',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Market Get Listings Event
const SecondaryMarketGetListings = ({
  dataset,
  playerId,
  from,
  fromContext,
  filtering,
  viewAllFilters,
  priceMin,
  priceMax,
  levelMin,
  levelMax,
  // evoMin,
  // evoMax,
  types,
  rarities,
  affinities,
  classes,
  variants,
  specialties,
  pageSize,
  pageNumber,
  pageCount,
  search,
}: SecondaryMarketGetListingsEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|filtering~${filtering}|viewAllFilters~${viewAllFilters}|priceMin~${priceMin}|priceMax~${priceMax}|levelMin~${levelMin}|levelMax~${levelMax}|types~${types}|rarities~${rarities}|affinities~${affinities}|classes~${classes}|variants~${variants}|specialties~${specialties}|search~${search}|pageSize~${pageSize}|pageNumber~${pageNumber}|pageCount~${pageCount}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryMarketGetListings',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Market Get Listings Fail Event
const SecondaryMarketGetListingsFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  filtering,
  viewAllFilters,
  priceMin,
  priceMax,
  levelMin,
  levelMax,
  // evoMin,
  // evoMax,
  types,
  rarities,
  affinities,
  classes,
  variants,
  specialties,
  search,
  pageSize,
  pageNumber,
  pageCount,
  reason,
}: SecondaryMarketGetListingsFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|filtering~${filtering}|viewAllFilters~${viewAllFilters}|priceMin~${priceMin}|priceMax~${priceMax}|levelMin~${levelMin}|levelMax~${levelMax}|types~${types}|rarities~${rarities}|affinities~${affinities}|classes~${classes}|variants~${variants}|specialties~${specialties}|search~${search}|pageSize~${pageSize}|pageNumber~${pageNumber}|pageCount~${pageCount}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryMarketGetListingsFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Secondary Market Get Listings Success Event
const SecondaryMarketGetListingsSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  filtering,
  viewAllFilters,
  priceMin,
  priceMax,
  levelMin,
  levelMax,
  // evoMin,
  // evoMax,
  types,
  rarities,
  affinities,
  classes,
  variants,
  specialties,
  search,
  pageSize,
  pageNumber,
  pageCount,
}: SecondaryMarketGetListingsSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|filtering~${filtering}|viewAllFilters~${viewAllFilters}|priceMin~${priceMin}|priceMax~${priceMax}|levelMin~${levelMin}|levelMax~${levelMax}|types~${types}|rarities~${rarities}|affinities~${affinities}|classes~${classes}|variants~${variants}|specialties~${specialties}|search~${search}|pageSize~${pageSize}|pageNumber~${pageNumber}|pageCount~${pageCount}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryMarketGetListingsSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Market Filter Apply Event
const SecondaryMarketFilterApply = ({
  dataset,
  playerId,
  from,
  fromContext,
  filtering,
  viewAllFilters,
  priceMin,
  priceMax,
  levelMin,
  levelMax,
  // evoMin,
  // evoMax,
  types,
  rarities,
  affinities,
  classes,
  variants,
  specialties,
  search,
  pageSize,
  pageNumber,
  pageCount,
}: SecondaryMarketFilterApplyEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|filtering~${filtering}|viewAllFilters~${viewAllFilters}|priceMin~${priceMin}|priceMax~${priceMax}|levelMin~${levelMin}|levelMax~${levelMax}|types~${types}|rarities~${rarities}|affinities~${affinities}|classes~${classes}|variants~${variants}|specialties~${specialties}|search~${search}|pageSize~${pageSize}|pageNumber~${pageNumber}|pageCount~${pageCount}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryMarketFilterApply',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Market Filter Clear Event
const SecondaryMarketFilterClear = ({
  dataset,
  playerId,
  from,
  fromContext,
  filtering,
  viewAllFilters,
  priceMin,
  priceMax,
  levelMin,
  levelMax,
  // evoMin,
  // evoMax,
  types,
  rarities,
  affinities,
  classes,
  variants,
  specialties,
  search,
  pageSize,
  pageNumber,
  pageCount,
}: SecondaryMarketFilterClearEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|filtering~${filtering}|viewAllFilters~${viewAllFilters}|priceMin~${priceMin}|priceMax~${priceMax}|levelMin~${levelMin}|levelMax~${levelMax}|types~${types}|rarities~${rarities}|affinities~${affinities}|classes~${classes}|variants~${variants}|specialties~${specialties}|search~${search}|pageSize~${pageSize}|pageNumber~${pageNumber}|pageCount~${pageCount}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryMarketFilterClear',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Market Search Apply Event
const SecondaryMarketSearchApply = ({
  dataset,
  playerId,
  from,
  fromContext,
  filtering,
  viewAllFilters,
  priceMin,
  priceMax,
  levelMin,
  levelMax,
  // evoMin,
  // evoMax,
  types,
  rarities,
  affinities,
  classes,
  variants,
  specialties,
  search,
  pageSize,
  pageNumber,
  pageCount,
}: SecondaryMarketSearchApplyEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|filtering~${filtering}|viewAllFilters~${viewAllFilters}|priceMin~${priceMin}|priceMax~${priceMax}|levelMin~${levelMin}|levelMax~${levelMax}|types~${types}|rarities~${rarities}|affinities~${affinities}|classes~${classes}|variants~${variants}|specialties~${specialties}|search~${search}|pageSize~${pageSize}|pageNumber~${pageNumber}|pageCount~${pageCount}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryMarketSearchApply',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Market Search Clear Event
const SecondaryMarketSearchClear = ({
  dataset,
  playerId,
  from,
  fromContext,
  filtering,
  viewAllFilters,
  priceMin,
  priceMax,
  levelMin,
  levelMax,
  // evoMin,
  // evoMax,
  types,
  rarities,
  affinities,
  classes,
  variants,
  specialties,
  search,
  pageSize,
  pageNumber,
  pageCount,
}: SecondaryMarketSearchClearEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|filtering~${filtering}|viewAllFilters~${viewAllFilters}|priceMin~${priceMin}|priceMax~${priceMax}|levelMin~${levelMin}|levelMax~${levelMax}|types~${types}|rarities~${rarities}|affinities~${affinities}|classes~${classes}|variants~${variants}|specialties~${specialties}|search~${search}|pageSize~${pageSize}|pageNumber~${pageNumber}|pageCount~${pageCount}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryMarketSearchClear',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Listing Start Event
const SecondaryPurchaseStart = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
}: SecondaryPurchaseStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchaseStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Confirm Event
const SecondaryPurchaseConfirm = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
}: SecondaryPurchaseConfirmEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchaseConfirm',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Confirm Fail Event
const SecondaryPurchaseConfirmFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  reason,
}: SecondaryPurchaseConfirmFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchaseConfirmFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Confirm Success Event
const SecondaryPurchaseConfirmSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  asyncOperationId,
}: SecondaryPurchaseConfirmSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchaseConfirmSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Close Event
const SecondaryPurchaseClose = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
}: SecondaryPurchaseCloseEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchaseClose',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Polling Start Event
const SecondaryPurchasePollingStart = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  asyncOperationId,
}: SecondaryPurchasePollingStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchasePollingStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Polling Pending Event
const SecondaryPurchasePollingPending = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  asyncOperationId,
}: SecondaryPurchasePollingPendingEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchasePollingPending',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Polling Fail Event
const SecondaryPurchasePollingFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  asyncOperationId,
  reason,
}: SecondaryPurchasePollingFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchasePollingFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Polling Success Event
const SecondaryPurchasePollingSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  asyncOperationId,
}: SecondaryPurchasePollingSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchasePollingSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Secondary Purchase Polling Abort Event
const SecondaryPurchasePollingAbort = ({
  dataset,
  playerId,
  from,
  fromContext,
  nonfungibleId,
  listingId,
  priceLC,
  priceUSD,
  asyncOperationId,
}: SecondaryPurchasePollingAbortEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|nonfungibleId~${nonfungibleId}|listingId~${listingId}|priceLC~${priceLC}|priceUSD~${priceUSD}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'SecondaryPurchasePollingAbort',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Queue Enter Event
const PrimaryPurchaseQueueEnter = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  dropEventType,
  dropEventAllowlistIds,
  lpmId,
  lpmType,
  lpmAllowlistIds,
  lpmPriceLC,
  lpmPriceUSD,
  lpmPurchaseCount,
  lpmPurchaseLimit,
  lpmTotalCount,
  lpmCurrencyName,
  lpmCurrencyBalance,
}: PrimaryPurchaseQueueEnterEventProps): void => {
  const normalizedDropEventAllowlistIds = dropEventAllowlistIds.length > 0 ? dropEventAllowlistIds.join(',') : '';
  const normalizedLPMAllowlistIds = lpmAllowlistIds.length > 0 ? lpmAllowlistIds.join(',') : '';
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|dropEventType~${dropEventType}|dropEventAllowlistIds~${normalizedDropEventAllowlistIds}|lpmId~${lpmId}|lpmType~${lpmType}|lpmAllowlistIds~${normalizedLPMAllowlistIds}|lpmPriceLC~${lpmPriceLC}|lpmPriceUSD~${lpmPriceUSD}|lpmPurchaseCount~${lpmPurchaseCount}|lpmPurchaseLimit~${lpmPurchaseLimit}|lpmTotalCount~${lpmTotalCount}|lpmCurrencyName~${lpmCurrencyName}|lpmCurrencyBalance~${lpmCurrencyBalance}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchaseQueueEnter',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Queue Enter Success Event
const PrimaryPurchaseQueueEnterSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  dropEventType,
  dropEventAllowlistIds,
  lpmId,
  lpmType,
  lpmAllowlistIds,
  lpmPriceLC,
  lpmPriceUSD,
  lpmPurchaseCount,
  lpmPurchaseLimit,
  lpmTotalCount,
  lpmCurrencyName,
  lpmCurrencyBalance,
}: PrimaryPurchaseQueueEnterSuccessEventProps): void => {
  const normalizedDropEventAllowlistIds = dropEventAllowlistIds.length > 0 ? dropEventAllowlistIds.join(',') : '';
  const normalizedLPMAllowlistIds = lpmAllowlistIds.length > 0 ? lpmAllowlistIds.join(',') : '';
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|dropEventType~${dropEventType}|dropEventAllowlistIds~${normalizedDropEventAllowlistIds}|lpmId~${lpmId}|lpmType~${lpmType}|lpmAllowlistIds~${normalizedLPMAllowlistIds}|lpmPriceLC~${lpmPriceLC}|lpmPriceUSD~${lpmPriceUSD}|lpmPurchaseCount~${lpmPurchaseCount}|lpmPurchaseLimit~${lpmPurchaseLimit}|lpmTotalCount~${lpmTotalCount}|lpmCurrencyName~${lpmCurrencyName}|lpmCurrencyBalance~${lpmCurrencyBalance}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchaseQueueEnterSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Start Event
const PrimaryPurchaseStart = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  dropEventType,
  dropEventAllowlistIds,
  lpmId,
  lpmType,
  lpmAllowlistIds,
  lpmPriceLC,
  lpmPriceUSD,
  lpmPurchaseCount,
  lpmPurchaseLimit,
  lpmTotalCount,
  lpmCurrencyName,
  lpmCurrencyBalance,
}: PrimaryPurchaseStartEventProps): void => {
  const normalizedDropEventAllowlistIds = dropEventAllowlistIds.length > 0 ? dropEventAllowlistIds.join(',') : '';
  const normalizedLPMAllowlistIds = lpmAllowlistIds.length > 0 ? lpmAllowlistIds.join(',') : '';
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|dropEventType~${dropEventType}|dropEventAllowlistIds~${normalizedDropEventAllowlistIds}|lpmId~${lpmId}|lpmType~${lpmType}|lpmAllowlistIds~${normalizedLPMAllowlistIds}|lpmPriceLC~${lpmPriceLC}|lpmPriceUSD~${lpmPriceUSD}|lpmPurchaseCount~${lpmPurchaseCount}|lpmPurchaseLimit~${lpmPurchaseLimit}|lpmTotalCount~${lpmTotalCount}|lpmCurrencyName~${lpmCurrencyName}|lpmCurrencyBalance~${lpmCurrencyBalance}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchaseStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Start Fail Event
const PrimaryPurchaseStartFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  dropEventType,
  dropEventAllowlistIds,
  lpmId,
  lpmType,
  lpmAllowlistIds,
  lpmPriceLC,
  lpmPriceUSD,
  lpmPurchaseCount,
  lpmPurchaseLimit,
  lpmTotalCount,
  lpmCurrencyName,
  lpmCurrencyBalance,
  reason,
}: PrimaryPurchaseStartFailEventProps): void => {
  const normalizedDropEventAllowlistIds = dropEventAllowlistIds.length > 0 ? dropEventAllowlistIds.join(',') : '';
  const normalizedLPMAllowlistIds = lpmAllowlistIds.length > 0 ? lpmAllowlistIds.join(',') : '';
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|dropEventType~${dropEventType}|dropEventAllowlistIds~${normalizedDropEventAllowlistIds}|lpmId~${lpmId}|lpmType~${lpmType}|lpmAllowlistIds~${normalizedLPMAllowlistIds}|lpmPriceLC~${lpmPriceLC}|lpmPriceUSD~${lpmPriceUSD}|lpmPurchaseCount~${lpmPurchaseCount}|lpmPurchaseLimit~${lpmPurchaseLimit}|lpmTotalCount~${lpmTotalCount}|lpmCurrencyName~${lpmCurrencyName}|lpmCurrencyBalance~${lpmCurrencyBalance}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchaseStartFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Start Success Event
const PrimaryPurchaseStartSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  dropEventType,
  dropEventAllowlistIds,
  lpmId,
  lpmType,
  lpmAllowlistIds,
  lpmPriceLC,
  lpmPriceUSD,
  lpmPurchaseCount,
  lpmPurchaseLimit,
  lpmTotalCount,
  lpmCurrencyName,
  lpmCurrencyBalance,
  asyncOperationId,
}: PrimaryPurchaseStartSuccessEventProps): void => {
  const normalizedDropEventAllowlistIds = dropEventAllowlistIds.length > 0 ? dropEventAllowlistIds.join(',') : '';
  const normalizedLPMAllowlistIds = lpmAllowlistIds.length > 0 ? lpmAllowlistIds.join(',') : '';
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|dropEventType~${dropEventType}|dropEventAllowlistIds~${normalizedDropEventAllowlistIds}|lpmId~${lpmId}|lpmType~${lpmType}|lpmAllowlistIds~${normalizedLPMAllowlistIds}|lpmPriceLC~${lpmPriceLC}|lpmPriceUSD~${lpmPriceUSD}|lpmPurchaseCount~${lpmPurchaseCount}|lpmPurchaseLimit~${lpmPurchaseLimit}|lpmTotalCount~${lpmTotalCount}|lpmCurrencyName~${lpmCurrencyName}|lpmCurrencyBalance~${lpmCurrencyBalance}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchaseStartSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Polling Start Event
const PrimaryPurchasePollingStart = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  asyncOperationId,
}: PrimaryPurchasePollingStartEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchasePollingStart',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Polling Pending Event
const PrimaryPurchasePollingPending = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  asyncOperationId,
}: PrimaryPurchasePollingPendingEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchasePollingPending',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Polling Fail Event
const PrimaryPurchasePollingFail = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  asyncOperationId,
  reason,
}: PrimaryPurchasePollingFailEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchasePollingFail',
    // url: from,
    details,
    reason,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Polling Success Event
const PrimaryPurchasePollingSuccess = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  asyncOperationId,
}: PrimaryPurchasePollingSuccessEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchasePollingSuccess',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Primary Purchase Polling Abort Event
const PrimaryPurchasePollingAbort = ({
  dataset,
  playerId,
  from,
  fromContext,
  dropEventId,
  asyncOperationId,
}: PrimaryPurchasePollingAbortEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|dropEventId~${dropEventId}|asyncOperationId~${asyncOperationId}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'PrimaryPurchasePollingAbort',
    // url: from,
    details,
  };
  logPooleEvent(dataset, event);
};

// Open Game Overlay Event
const GameOverlayOpen = ({
  dataset,
  playerId,
  from,
  fromContext,
  gameOverlayPageTo,
  gameOverlayOpenListPriceMarketId,
  gameOverlayOpenNonfungibleId,
  gameOverlayOpenConfigId,
  gameOverlayOpenGameClientContext,
}: GameOverlayOpenEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|gameOverlayPageTo~${gameOverlayPageTo}|gameOverlayOpenListPriceMarketId~${gameOverlayOpenListPriceMarketId}|gameOverlayOpenNonfungibleId~${gameOverlayOpenNonfungibleId}|gameOverlayOpenConfigId~${gameOverlayOpenConfigId}|gameOverlayOpenGameClientContext~${gameOverlayOpenGameClientContext}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'GameOverlayOpen',
    // url: to,
    details,
  };
  logPooleEvent(dataset, event);
};

// Close Game Overlay Event
const GameOverlayClose = ({
  dataset,
  playerId,
  from,
  fromContext,
  gameOverlayPageFrom,
}: GameOverlayCloseEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|gameOverlayPageFrom~${gameOverlayPageFrom}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'GameOverlayClose',
    // url: to,
    details,
  };
  logPooleEvent(dataset, event);
};

// Navigate Game Overlay Event
const GameOverlayNavigate = ({
  dataset,
  playerId,
  from,
  fromContext,
  gameOverlayPageFrom,
  gameOverlayPageTo,
}: GameOverlayNavigateEventProps): void => {
  const baseDetails = getBaseDetails(dataset);
  const details = `${baseDetails}|from~${from}|fromContext~${fromContext}|gameOverlayPageFrom~${gameOverlayPageFrom}|gameOverlayPageTo~${gameOverlayPageTo}`;
  const event = {
    ...eventGlobals,
    uid: playerId,
    en: 'GameOverlayNavigate',
    // url: to,
    details,
  };
  logPooleEvent(dataset, event);
};

export const PooleEvents = {
  InitializeApp,
  AuthStart,
  AuthStartFail,
  AuthStartSuccess,
  AuthFinish,
  AuthFinishFail,
  AuthFinishSuccess,
  AuthRenew,
  AuthRenewFail,
  AuthRenewSuccess,
  Logout,
  LogoutFail,
  LogoutSuccess,
  PageLoad,
  NavigateInternal,
  NavigateExternal,
  CreateListingStart,
  CreateListingPrice,
  CreateListingConfirm,
  CreateListingConfirmFail,
  CreateListingConfirmSuccess,
  CreateListingClose,
  RemoveListingStart,
  RemoveListingConfirm,
  RemoveListingConfirmFail,
  RemoveListingConfirmSuccess,
  RemoveListingClose,
  OpenPackStart,
  OpenPackConfirm,
  OpenPackConfirmFail,
  OpenPackConfirmSuccess,
  OpenPackClose,
  OpenPackClaim,
  EditUsernameStart,
  EditUsernameConfirm,
  EditUsernameConfirmFail,
  EditUsernameConfirmSuccess,
  EditUsernameCancel,
  SecondaryMarketGetListings,
  SecondaryMarketGetListingsFail,
  SecondaryMarketGetListingsSuccess,
  SecondaryMarketFilterApply,
  SecondaryMarketFilterClear,
  SecondaryMarketSearchApply,
  SecondaryMarketSearchClear,
  SecondaryPurchaseStart,
  SecondaryPurchaseConfirm,
  SecondaryPurchaseConfirmFail,
  SecondaryPurchaseConfirmSuccess,
  SecondaryPurchaseClose,
  SecondaryPurchasePollingStart,
  SecondaryPurchasePollingPending,
  SecondaryPurchasePollingFail,
  SecondaryPurchasePollingSuccess,
  SecondaryPurchasePollingAbort,
  PrimaryPurchaseQueueEnter,
  PrimaryPurchaseQueueEnterSuccess,
  PrimaryPurchaseStart,
  PrimaryPurchaseStartFail,
  PrimaryPurchaseStartSuccess,
  PrimaryPurchasePollingStart,
  PrimaryPurchasePollingPending,
  PrimaryPurchasePollingFail,
  PrimaryPurchasePollingSuccess,
  PrimaryPurchasePollingAbort,
  GameOverlayOpen,
  GameOverlayClose,
  GameOverlayNavigate,
};
