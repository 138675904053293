import { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import Styled from 'src/components/tutorial-cursor/TutorialCursor.styles';

interface TutorialOverlayProps {
  enableTutorial: boolean;
  anchor?: 'top' | 'bottom';
  offsetX?: number;
  offsetY?: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const TutorialCursor: FC<TutorialOverlayProps> = ({
  enableTutorial,
  anchor = 'top',
  offsetX = 0,
  offsetY = 0,
  children,
  style = {},
}) => {
  return (
    <AnimatePresence>
      <Styled.Root style={style}>
        {children}

        {enableTutorial && (
          <Styled.CursorImg
            // ref={cursorRef}
            anchor={anchor}
            offsetx={offsetX}
            offsety={offsetY}
            src={'/assets/cursor_ftue.webp'}
            width={'100'}
            height={'100'}
            alt={'Cursor Image'}
            title={'Cursor Image'}
            initial={anchor === 'bottom' ? { bottom: `${offsetY}px` } : { top: `${offsetY}px` }}
            animate={anchor === 'bottom' ? { bottom: `${offsetY - 24}px` } : { top: `${offsetY - 24}px` }}
            transition={{ duration: 0.6, repeat: Infinity, repeatType: 'reverse' }}
            exit={{ opacity: 0, transition: { type: 'tween', duration: 0.3, delay: 0 } }}
          />
        )}
      </Styled.Root>
    </AnimatePresence>
  );
};

export default TutorialCursor;
