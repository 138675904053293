import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
`;

const XPLabel = styled('p')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4rem;
  color: #ffffff77;

  & > span,
  & > img {
    display: inline-block;
    vertical-align: middle;
  }

  & > img {
    width: 2rem;
    height: auto;
    margin-left: 0.5rem;
  }

  & > span:nth-of-type(1) {
    font-weight: 300;
  }

  & > span:nth-of-type(2) {
    margin: 0 0.2rem;
  }

  & > span:nth-of-type(3) {
    font-weight: 600;
  }
`;

export default {
  Root,
  XPLabel,
};
