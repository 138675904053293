export const copyNonfungible = (nonfungible: Nonfungible) => {
  return {
    ...nonfungible,
    metadata: {
      ...nonfungible.metadata,
      attributes: nonfungible?.metadata?.attributes ? [...nonfungible.metadata.attributes] : [],
    },
    skills: nonfungible?.skills ? [...nonfungible.skills] : [],
  };
};
