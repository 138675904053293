import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

const CustomTable = styled('div')`
  display: flex;
  width: 100%;
  overflow: visible;
  min-width: 40rem;
  flex-direction: column;

  @media (max-width: 768px) {
    min-width: 30rem;
  }
`;

const CustomTableHead = styled('div')`
  background-color: #1c1c1c;
  display: flex;
  width: 100%;
  padding: 0.4rem 2rem;
  box-sizing: border-box;
  border-radius: 0.1rem;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
`;

const NoResults = styled('div')`
  background-color: #000000aa;
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 12rem 1.5rem;
  box-sizing: border-box;
  border-radius: 0.2rem;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  /* color: #ffffffcc; */
  color: ${({ theme }) => theme.custom.colors.Yellow};

  /* @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1rem;
  } */
`;

export default {
  Root,
  CustomTable,
  CustomTableHead,
  NoResults,
};
