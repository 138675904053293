import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
// import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import Row from 'src/components/data-display/leaderboard/Row';
import Col from 'src/components/data-display/leaderboard/Col';
import Rank from 'src/components/data-display/leaderboard/Rank';
import Guild from 'src/components/data-display/leaderboard/Guild';
import Score from 'src/components/data-display/leaderboard/Score';
import Styled from 'src/components/data-display/leaderboard/GuildLeaderboardTable.styles';
import { useMediaQuery, useTheme } from '@mui/material';
// import { logMain } from 'src/modules/logger/logger';

type Variant = 'condensed' | 'default';

interface GuildLeaderboardTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  variant?: Variant;
  style?: React.CSSProperties;
}

const GuildLeaderboardTable: FC<GuildLeaderboardTableProps> = ({ data = [], variant = 'default', style = {} }) => {
  const navigateInternal = useAppNavigateInternal();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tLeaderboards } = useTranslation(nameSpace.leaderboards);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(768));

  const handleGoToLeaderboard = (leaderboardId: string) => {
    navigateInternal(`/leaderboards/${leaderboardId}`, { pooleData: { fromContext: 'leaderboards' } });
  };

  return (
    <Styled.Root style={style}>
      <Styled.CustomTable>
        <Styled.CustomTableHead>
          <Col flexGrow={1}>
            <span>{tCommon('common.term.rank')}</span>
          </Col>

          <Col flexGrow={3}>
            <span>{tCommon('common.term.guild')}</span>
          </Col>

          <Col flexGrow={1}>
            <span>{tCommon('common.term.score')}</span>
          </Col>
        </Styled.CustomTableHead>

        {data.map((row, i) => {
          const { configId, category, displayName, imageUrl, totalPoints } = row;
          const { leaderboardWebCategoryId } = category || {};

          return (
            <Row
              key={`leaderboard-${leaderboardWebCategoryId}-row-${i}`}
              variant={variant}
              enableHighlight={i === 0}
              enableBorder
              enableGradient
              onClick={() => handleGoToLeaderboard(configId)}
            >
              <Col flexGrow={1}>
                <Rank rank={i + 1} variant={'default'} />
              </Col>

              <Col flexGrow={3}>
                <Guild displayName={displayName} imageUrl={imageUrl} variant={mobile ? 'condensed' : 'default'} />
              </Col>

              <Col flexGrow={1}>
                <Score value={totalPoints} variant={'default'} />
              </Col>
            </Row>
          );
        })}
      </Styled.CustomTable>

      {data.length === 0 && <Styled.NoResults>{tLeaderboards('leaderboards.table.no.results')}</Styled.NoResults>}
    </Styled.Root>
  );
};

export default GuildLeaderboardTable;
