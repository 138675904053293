import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import { useGameOverlayResize } from 'src/hooks/useGameOverlayResize';
import useElementsOnClick from 'src/hooks/useElementsOnClick';
import CenteredFrame from 'src/components/game-overlay/CenteredFrame';
import Styled from 'src/components/game-overlay/GameOverlay.styles';

const GameOverlay: FC = () => {
  const { closeGameOverlay, enableGameOverlay } = useGameOverlayContext();
  const { fullscreen } = useGameClientContext();

  // Listen for window resize to update game overlay aspe3ct ration
  useGameOverlayResize();

  // Close Game Overlay on Click Away of Page Container
  useElementsOnClick({ ids: ['game-overlay'], onClick: closeGameOverlay });

  return (
    <CSSTransition in={enableGameOverlay} timeout={500} classNames="game-overlay-root" unmountOnExit>
      <Styled.Root key="game-overlay" id="game-overlay" isfullscreen={fullscreen ? 'true' : 'false'}>
        <CenteredFrame />
      </Styled.Root>
    </CSSTransition>
  );
};

export default GameOverlay;
