import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;
`;

const Separator = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  border-bottom: 1px solid #ffffff33;
`;

const SettingCategory = styled('div')`
  display: block;
  width: 100%;
  font-family: 'Kevlar Underwear';
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};

  &:not(:first-of-type) {
    margin-top: 3rem;
  }
`;

const SettingGrouping = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;

  & > div:not(:first-of-type) {
    margin-top: 0.2rem;
  }
`;

const SettingContainer = styled('div')`
  display: block;
  width: 100%;
  margin-left: -12px;
`;

const CheckboxGroup = styled('span')`
  display: block;
`;

const CheckboxLabel = styled('span')`
  display: inline-block;
  width: calc(100% - 48px);
  /* margin-top: 1px; */
  vertical-align: top;
  margin-top: 0.7rem;
  font-family: 'Kevlar Underwear';
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #cccccc;

  @media (max-width: 400px) {
  }

  & > div {
    font-family: 'Barlow';
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #999999;
  }
`;

export default {
  Root,
  Title,
  Separator,
  SettingCategory,
  SettingGrouping,
  SettingContainer,
  CheckboxGroup,
  CheckboxLabel,
};
