import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const FusionStarEmptyIcon: FC = () => (
  <SvgIcon width="128" height="129" viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7" filter="url(#filter0_d_184_352)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.0157 42.4757L64 5.99146L43.9843 42.4757L7.5 62.4915L43.9843 82.5072L64 118.991L84.0157 82.5072L120.5 62.4915L84.0157 42.4757ZM100.749 62.4915L77.0187 49.4728L64 25.7426L50.9813 49.4728L27.2512 62.4915L50.9813 75.5101L64 99.2403L77.0187 75.5101L100.749 62.4915Z"
        fill="url(#paint0_linear_184_352)"
      />
    </g>
    <g opacity="0.7" filter="url(#filter1_d_184_352)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.9277 44.5638L64 11.8856L46.0723 44.5638L13.3942 62.4915L46.0723 80.4191L64 113.097L81.9277 80.4191L114.606 62.4915L81.9277 44.5638ZM120.5 62.4915L84.0157 82.5072L64 118.991L43.9843 82.5072L7.5 62.4915L43.9843 42.4757L64 5.99146L84.0157 42.4757L120.5 62.4915ZM106.643 62.4915L79.1067 77.5982L64 105.134L48.8933 77.5982L21.357 62.4915L48.8933 47.3847L64 19.8485L79.1067 47.3847L106.643 62.4915ZM77.0187 49.4728L64 25.7426L50.9813 49.4728L27.2512 62.4915L50.9813 75.5101L64 99.2403L77.0187 75.5101L100.749 62.4915L77.0187 49.4728Z"
        fill="white"
        style={{ mixBlendMode: 'overlay' }}
        shapeRendering="crispEdges"
      />
    </g>
    <g opacity="0.7" filter="url(#filter2_d_184_352)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.8751 79.3666L111.635 62.4915L80.8751 45.6163L64 14.8566L47.1248 45.6163L16.365 62.4915L47.1248 79.3666L63.9999 110.126L80.8751 79.3666ZM63.9999 116.021L45.0367 81.4547L10.4709 62.4915L45.0367 43.5282L64 8.9624L82.9632 43.5282L117.529 62.4915L82.9632 81.4547L63.9999 116.021Z"
        fill="white"
        style={{ mixBlendMode: 'overlay' }}
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_184_352"
        x="5.5"
        y="3.99146"
        width="117"
        height="117"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.84 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_352" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_352" result="shape" />
      </filter>
      <filter
        id="filter1_d_184_352"
        x="5.5"
        y="3.99146"
        width="117"
        height="117"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.84 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_352" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_352" result="shape" />
      </filter>
      <filter
        id="filter2_d_184_352"
        x="8.47083"
        y="6.9624"
        width="111.058"
        height="111.058"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0.84 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_184_352" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_184_352" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_184_352"
        x1="64"
        y1="5.99146"
        x2="64"
        y2="118.991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF9900" />
        <stop offset="0.484375" stopColor="#E43DFF" />
        <stop offset="1" stopColor="#FF5C00" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default FusionStarEmptyIcon;
