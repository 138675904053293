import { FC, useEffect, useState } from 'react';
// import PageError from 'src/components/feedback/PageError';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import useLeaderboardsContext from 'src/hooks/useLeaderboardsContext';
import { getSortedLeaderboardCategories } from 'src/redux/slices/leaderboards/leaderboards';
import loadLeaderboards from 'src/components/pages/leaderboards/utils/load-leaderboards';
import SectionHero from 'src/components/pages/leaderboards/components/SectionHero';
import SelectBar from 'src/components/pages/leaderboards/components/SelectBar';
import MobileSectionLabel from 'src/components/pages/leaderboards/components/MobileSectionLabel';
import SectionDetails from 'src/components/pages/leaderboards/components/SectionDetails';
import Styled from 'src/components/pages/leaderboards/Leaderboards.styles';
// import { logMain } from 'src/modules/logger/logger';

const Leaderboards: FC = () => {
  const { setSelectedCategory } = useLeaderboardsContext();
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const categories = useAppSelector(state => getSortedLeaderboardCategories(state));
  const [loading, setLoading] = useState<boolean>(true);
  const [loadedDefaultCategory, setLoadedDefaultCategory] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigateInternal = useAppNavigateInternal();

  useEffect(() => {
    const handleLoad = async () => {
      const leaderboardsEnabled = currentProfile?.features?.leaderboards?.enabled;

      // Prevent access to page if not authenticated or leaderboards are not enabled
      if (!authenticated || !leaderboardsEnabled) {
        navigateInternal('/page-not-found', { pooleData: { fromContext: 'leaderboards' } });
      } else {
        await setLoading(false);
      }

      loadLeaderboards(dispatch);
    };

    if (loading) {
      handleLoad();
    }
  }, [authenticated, currentProfile?.features?.leaderboards?.enabled, dispatch, loading, navigateInternal]);

  useEffect(() => {
    if (!loadedDefaultCategory && categories.length > 0) {
      const defaultCategory = categories[0];
      setSelectedCategory(defaultCategory);
      setLoadedDefaultCategory(true);
    }
  }, [categories, loadedDefaultCategory, setSelectedCategory]);

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;

  return (
    <Styled.Root>
      <SectionHero />
      <SelectBar />
      <MobileSectionLabel />
      <SectionDetails />
    </Styled.Root>
  );
};

export default Leaderboards;
