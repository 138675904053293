import { FC, useEffect, useRef, useState } from 'react';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useElementDimensions } from 'src/hooks/useElementDimensions';
import GameModalBackground from 'src/components/backgrounds/GameModalBackground';
import BorderMask from 'src/components/game-overlay/BorderMask';
import Blurb from 'src/components/game-overlay/Blurb';
import BaseOverlayPage from 'src/components/game-overlay/BaseOverlayPage';
import PagePrimaryMarketplace from 'src/components/game-overlay/pages/PagePrimaryMarketplace';
import PageSecondaryMarketplace from 'src/components/game-overlay/pages/PageSecondaryMarketplace';
import PageRewards from 'src/components/game-overlay/pages/PageRewards';
import PageCollection from 'src/components/game-overlay/pages/PageCollection';
import PageNFTDetails from 'src/components/game-overlay/pages/PageNFTDetails';
import NavBar from 'src/components/game-overlay/NavBar';
import Balances from 'src/components/game-overlay/Balances';
import CloseButton from 'src/components/game-overlay/CloseButton';
import TutorialOverlay from 'src/components/tutorial-overlay/TutorialOverlay';
import Styled from 'src/components/game-overlay/CenteredFrame.styles';

const CenteredFrame: FC = () => {
  const pageContainerRef = useRef(null);
  const { gameOverlayLocation, enableGameOverlayContents, dimensions } = useGameOverlayContext();
  const [scaleIn, setScaleIn] = useState(false);
  const [showData, setShowData] = useState(false);
  const { width: pageContainerWidth, height: pageContainerHeight } = useElementDimensions({
    element: pageContainerRef,
  });
  const { page } = gameOverlayLocation;

  // Scale in centered container on mount
  useEffect(() => {
    setScaleIn(true);
  }, []);

  // Show Inner container contents after scale in animation
  useEffect(() => {
    if (scaleIn) {
      setTimeout(() => {
        setShowData(true);
      }, 400);
    }
  }, [scaleIn]);

  return (
    <Styled.Root
      key="game-overlay-page-container"
      className={`${scaleIn ? 'centered-container-scale-in' : 'centered-container-scale-out'}`}
      dimensions={dimensions}
    >
      <GameModalBackground />
      <NavBar key={'game-overlay-navbar'} />
      <Balances />
      <CloseButton key={'game-overlay-close-button'} />

      {enableGameOverlayContents && (
        <Styled.InnerContainer ref={pageContainerRef}>
          <BorderMask width={pageContainerWidth} height={pageContainerHeight} />
          <TutorialOverlay />
          <Blurb />

          {showData && (
            <>
              {/* Primary Marketplace Page */}
              {page === 'primary-marketplace' && (
                <BaseOverlayPage>
                  <PagePrimaryMarketplace />
                </BaseOverlayPage>
              )}

              {/* Secondary Marketplace Page */}
              {page === 'secondary-marketplace' && (
                <BaseOverlayPage>
                  <PageSecondaryMarketplace />
                </BaseOverlayPage>
              )}

              {/* Collection Page */}
              {page === 'rewards' && (
                <BaseOverlayPage>
                  <PageRewards />
                </BaseOverlayPage>
              )}

              {/* Collection Page */}
              {page === 'collection' && (
                <BaseOverlayPage>
                  <PageCollection />
                </BaseOverlayPage>
              )}

              {/* NFT Details Page */}
              {page === 'nft' && (
                <BaseOverlayPage key={'page-nft'}>
                  <PageNFTDetails />
                </BaseOverlayPage>
              )}
            </>
          )}
        </Styled.InnerContainer>
      )}
    </Styled.Root>
  );
};

export default CenteredFrame;
