import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state using that type
const initialState: AuthenticatedState = {
  authenticated: false,
};

const authenticatedSlice = createSlice({
  name: 'authenticated',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAuthenticated: {
      reducer(state, action: PayloadAction<AuthenticatedPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.authenticated = action.payload.authenticated;
      },
      prepare(authenticated: Authenticated) {
        return {
          payload: {
            authenticated,
          },
        };
      },
    },
    clearAuthenticated: state => {
      // eslint-disable-next-line no-param-reassign
      state.authenticated = false;
    },
  },
});

export const { setAuthenticated, clearAuthenticated } = authenticatedSlice.actions;

export default authenticatedSlice.reducer;
