import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import Styled from 'src/components/primary-marketplace/components/Description.styles';

interface DescriptionProps {
  dropEventId: string;
  lpmId: string;
  style?: React.CSSProperties;
}

const Description: FC<DescriptionProps> = ({ dropEventId, lpmId, style = {} }) => {
  const dropEvent = useAppSelector(state => state.dropEvents.dropEvents)[dropEventId];
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));

  if (!dropEvent || !listPriceMarket || !listPriceMarket?.description) return null;

  return (
    <Styled.Root isDaily={dropEvent?.type === 'daily'} style={style}>
      {listPriceMarket?.description || ''}
    </Styled.Root>
  );
};

export default Description;
