export const copyListPriceMarket = (listPriceMarket: ListPriceMarket) => {
  const { allowListPurchaseLimits, allowedViaAllowListIds, allowedViaAllowLists, rarities } = listPriceMarket;

  return {
    ...listPriceMarket,
    allowListPurchaseLimits: allowListPurchaseLimits ? [...allowListPurchaseLimits] : [],
    allowedViaAllowListIds: allowedViaAllowListIds ? [...allowedViaAllowListIds] : null,
    allowedViaAllowLists: allowedViaAllowLists ? [...allowedViaAllowLists] : null,
    rarities: rarities ? [...rarities] : [],
  };
};
