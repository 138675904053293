import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  min-height: 100vh;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 75rem;
  padding: 5rem 2rem 2rem 2rem;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding-top: 4.5rem;
  }

  @media (max-width: 768px) {
    padding: 5rem 2rem 0 2rem;
  }
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export default {
  Root,
  CenteredContainer,
  ContentContainer,
};
