import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  margin-top: 1.3rem;
  font-style: italic;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3rem;
  color: #ffffff;
`;

const EmptyText = styled('p')`
  display: block;
  margin-top: 1.3rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: #ffffff77;
`;

export default {
  Root,
  EmptyText,
};
