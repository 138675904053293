import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { PooleEvents } from 'src/modules/poole/events';
import MarketplaceContext, { initialContext, IMarketplaceContext } from 'src/context/marketplace/Marketplace.context';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { addListings, clearListings, setListings } from 'src/redux/slices/listings/listings';
import { searchListings } from 'src/api/secondary-markets/search-listings';
import { logMain } from 'src/modules/logger/logger';

interface MarketplaceProviderProps {
  children: ReactNode;
}

const MarketplaceProvider: FC<MarketplaceProviderProps> = ({ children }) => {
  const [loadListings, setLoadListings] = useState<IMarketplaceContext['loadListings']>(initialContext.loadListings);
  const [loadMoreListings, setLoadMoreListings] = useState<IMarketplaceContext['loadMoreListings']>(
    initialContext.loadMoreListings,
  );
  const [filtering, setFiltering] = useState<IMarketplaceContext['filtering']>(initialContext.filtering);
  const [viewAllFilters, setViewAllFilters] = useState<IMarketplaceContext['viewAllFilters']>(
    initialContext.viewAllFilters,
  );
  const [applySearch, setApplySearch] = useState<IMarketplaceContext['applySearch']>(initialContext.applySearch);
  const [applyFilters, setApplyFilters] = useState<IMarketplaceContext['applyFilters']>(initialContext.applyFilters);
  const [search, setSearch] = useState<IMarketplaceContext['search']>(initialContext.search);
  const [priceMin, setPriceMin] = useState<IMarketplaceContext['priceMin']>(initialContext.priceMin);
  const [priceMax, setPriceMax] = useState<IMarketplaceContext['priceMax']>(initialContext.priceMax);
  const [levelMin, setLevelMin] = useState<IMarketplaceContext['levelMin']>(initialContext.levelMin);
  const [levelMax, setLevelMax] = useState<IMarketplaceContext['levelMax']>(initialContext.levelMax);
  // const [evoMin, setEvoMin] = useState<IMarketplaceContext['evoMin']>(initialContext.evoMin);
  // const [evoMax, setEvoMax] = useState<IMarketplaceContext['evoMax']>(initialContext.evoMax);
  const [types, setTypes] = useState<IMarketplaceContext['types']>(initialContext.types);
  const [rarities, setRarities] = useState<IMarketplaceContext['rarities']>(initialContext.rarities);
  const [affinities, setAffinities] = useState<IMarketplaceContext['affinities']>(initialContext.affinities);
  const [classes, setClasses] = useState<IMarketplaceContext['classes']>(initialContext.classes);
  const [variants, setVariants] = useState<IMarketplaceContext['variants']>(initialContext.variants);
  const [specialties, setSpecialties] = useState<IMarketplaceContext['specialties']>(initialContext.specialties);
  const [clearFilters, setClearFilters] = useState<IMarketplaceContext['clearFilters']>(initialContext.clearFilters);
  const [clearSearch, setClearSearch] = useState<IMarketplaceContext['clearSearch']>(initialContext.clearSearch);
  const [lastSearch, setLastSearch] = useState<IMarketplaceContext['lastSearch']>(initialContext.lastSearch);
  const [pageSize, setPageSize] = useState<IMarketplaceContext['pageSize']>(initialContext.pageSize);
  const [pageNumber, setPageNumber] = useState<IMarketplaceContext['pageNumber']>(initialContext.pageNumber);
  const [pageCount, setPageCount] = useState<IMarketplaceContext['pageCount']>(initialContext.pageCount);
  const [nextPageToken, setNextPageToken] = useState<IMarketplaceContext['nextPageToken']>(
    initialContext.nextPageToken,
  );
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loadListings || loadMoreListings) {
      // Fire Poole event for secondary market get listings
      PooleEvents.SecondaryMarketGetListings({
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'marketplace',
        filtering,
        viewAllFilters,
        priceMin,
        priceMax,
        levelMin,
        levelMax,
        // evoMin,
        // evoMax,
        types,
        rarities,
        affinities,
        classes,
        variants,
        specialties,
        search,
        pageSize,
        pageNumber,
        pageCount,
      });

      dispatch(
        searchListings({
          queryParams: {
            series_name: search || undefined,
            min_price: priceMin,
            max_price: priceMax,
            min_level: levelMin,
            max_level: levelMax,
            // min_evo: evoMin,
            // max_evo: evoMax,
            types: types || undefined,
            rarities: rarities || undefined,
            affinities: affinities || undefined,
            classes: classes || undefined,
            variants: variants || undefined,
            specialties: specialties || undefined,
            page_size: pageSize || undefined,
            page_token: loadMoreListings ? nextPageToken || undefined : undefined,
          },
        }),
      )
        .then(data => {
          // Fire Poole event for secondary market get listings success
          PooleEvents.SecondaryMarketGetListingsSuccess({
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'marketplace',
            filtering,
            viewAllFilters,
            priceMin,
            priceMax,
            levelMin,
            levelMax,
            // evoMin,
            // evoMax,
            types,
            rarities,
            affinities,
            classes,
            variants,
            specialties,
            search,
            pageSize,
            pageNumber,
            pageCount,
          });

          setPageNumber(data?.pagination?.pageNumber || initialContext.pageNumber);
          setPageCount(data?.pagination?.pageCount || initialContext.pageCount);
          setNextPageToken(data?.pagination?.nextPageToken || initialContext.nextPageToken);

          if (data?.listings && data?.listings?.length > 0) {
            if (loadMoreListings) {
              dispatch(addListings(data.listings));
            } else {
              dispatch(setListings(data.listings));
            }
          } else if (!loadMoreListings) {
            dispatch(clearListings());
          }

          if (search) setLastSearch(search);
          if (loadListings) setLoadListings(false);
          if (loadMoreListings) setLoadMoreListings(false);
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug(e);

          // Fire Poole event for secondary market get listings fail
          PooleEvents.SecondaryMarketGetListingsFail({
            playerId: currentProfile.id,
            from: window.location.pathname,
            fromContext: 'marketplace',
            filtering,
            viewAllFilters,
            priceMin,
            priceMax,
            levelMin,
            levelMax,
            // evoMin,
            // evoMax,
            types,
            rarities,
            affinities,
            classes,
            variants,
            specialties,
            search,
            pageSize,
            pageNumber,
            pageCount,
            reason: e instanceof Error ? e.message : String(e),
          });

          setLoadListings(false);
        });
    }
  }, [
    dispatch,
    loadListings,
    loadMoreListings,
    search,
    priceMax,
    priceMin,
    types,
    rarities,
    affinities,
    classes,
    variants,
    specialties,
    levelMin,
    levelMax,
    // evoMin,
    // evoMax,
    currentProfile.id,
    filtering,
    viewAllFilters,
    pageSize,
    pageNumber,
    pageCount,
    nextPageToken,
  ]);

  useEffect(() => {
    if (applySearch) {
      // Fire Poole event for secondary market search apply
      PooleEvents.SecondaryMarketSearchApply({
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'marketplace',
        filtering,
        viewAllFilters,
        priceMin,
        priceMax,
        levelMin,
        levelMax,
        // evoMin,
        // evoMax,
        types,
        rarities,
        affinities,
        classes,
        variants,
        specialties,
        search,
        pageSize,
        pageNumber,
        pageCount,
      });

      setApplySearch(false);
      setLoadListings(true);
    }
  }, [
    affinities,
    applySearch,
    classes,
    currentProfile.id,
    // evoMax,
    // evoMin,
    filtering,
    levelMax,
    levelMin,
    pageCount,
    pageNumber,
    pageSize,
    priceMax,
    priceMin,
    rarities,
    search,
    specialties,
    types,
    variants,
    viewAllFilters,
  ]);

  useEffect(() => {
    if (clearSearch) {
      // Fire Poole event for secondary market search clear
      PooleEvents.SecondaryMarketSearchClear({
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'marketplace',
        filtering,
        viewAllFilters,
        priceMin,
        priceMax,
        levelMin,
        levelMax,
        // evoMin,
        // evoMax,
        types,
        rarities,
        affinities,
        classes,
        variants,
        specialties,
        search,
        pageSize,
        pageNumber,
        pageCount,
      });

      setClearSearch(false);
      setSearch('');
      setLastSearch('');
      setLoadListings(true);
    }
  }, [
    affinities,
    classes,
    clearSearch,
    currentProfile.id,
    // evoMax,
    // evoMin,
    filtering,
    levelMax,
    levelMin,
    pageCount,
    pageNumber,
    pageSize,
    priceMax,
    priceMin,
    rarities,
    search,
    specialties,
    types,
    variants,
    viewAllFilters,
  ]);

  useEffect(() => {
    if (applyFilters) {
      // Fire Poole event for secondary market search apply
      PooleEvents.SecondaryMarketSearchApply({
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'marketplace',
        filtering,
        viewAllFilters,
        priceMin,
        priceMax,
        levelMin,
        levelMax,
        // evoMin,
        // evoMax,
        types,
        rarities,
        affinities,
        classes,
        variants,
        specialties,
        search,
        pageSize,
        pageNumber,
        pageCount,
      });

      setApplyFilters(false);
      setLoadListings(true);
    }
  }, [
    affinities,
    applyFilters,
    classes,
    currentProfile.id,
    // evoMax,
    // evoMin,
    filtering,
    levelMax,
    levelMin,
    pageCount,
    pageNumber,
    pageSize,
    priceMax,
    priceMin,
    rarities,
    search,
    specialties,
    types,
    variants,
    viewAllFilters,
  ]);

  useEffect(() => {
    if (clearFilters) {
      // Fire Poole event for secondary market filter clear
      PooleEvents.SecondaryMarketFilterClear({
        playerId: currentProfile.id,
        from: window.location.pathname,
        fromContext: 'marketplace',
        filtering,
        viewAllFilters,
        priceMin,
        priceMax,
        levelMin,
        levelMax,
        // evoMin,
        // evoMax,
        types,
        rarities,
        affinities,
        classes,
        variants,
        specialties,
        search,
        pageSize,
        pageNumber,
        pageCount,
      });

      setClearFilters(false);
      setPriceMin(initialContext.priceMin);
      setPriceMax(initialContext.priceMax);
      setLevelMin(initialContext.levelMin);
      setLevelMax(initialContext.levelMax);
      // setEvoMin(initialContext.evoMin);
      // setEvoMax(initialContext.evoMax);
      setTypes(initialContext.types);
      setRarities(initialContext.rarities);
      setAffinities(initialContext.affinities);
      setClasses(initialContext.classes);
      setVariants(initialContext.variants);
      setSpecialties(initialContext.specialties);
      setLoadListings(true);
    }
  }, [
    affinities,
    classes,
    clearFilters,
    currentProfile.id,
    // evoMax,
    // evoMin,
    filtering,
    levelMax,
    levelMin,
    pageCount,
    pageNumber,
    pageSize,
    priceMax,
    priceMin,
    rarities,
    search,
    specialties,
    types,
    variants,
    viewAllFilters,
  ]);

  const value = useMemo(
    () => ({
      loadListings,
      setLoadListings,
      loadMoreListings,
      setLoadMoreListings,
      filtering,
      setFiltering,
      viewAllFilters,
      setViewAllFilters,
      applySearch,
      setApplySearch,
      applyFilters,
      setApplyFilters,
      priceMin,
      setPriceMin,
      priceMax,
      setPriceMax,
      levelMin,
      setLevelMin,
      levelMax,
      setLevelMax,
      // evoMin,
      // setEvoMin,
      // evoMax,
      // setEvoMax,
      types,
      setTypes,
      rarities,
      setRarities,
      affinities,
      setAffinities,
      classes,
      setClasses,
      variants,
      setVariants,
      specialties,
      setSpecialties,
      search,
      setSearch,
      clearFilters,
      setClearFilters,
      clearSearch,
      setClearSearch,
      lastSearch,
      setLastSearch,
      pageSize,
      setPageSize,
      pageNumber,
      setPageNumber,
      pageCount,
      setPageCount,
      nextPageToken,
      setNextPageToken,
    }),
    [
      loadListings,
      setLoadListings,
      loadMoreListings,
      setLoadMoreListings,
      filtering,
      setFiltering,
      viewAllFilters,
      setViewAllFilters,
      applySearch,
      setApplySearch,
      applyFilters,
      setApplyFilters,
      priceMin,
      setPriceMin,
      priceMax,
      setPriceMax,
      levelMin,
      setLevelMin,
      levelMax,
      setLevelMax,
      // evoMin,
      // setEvoMin,
      // evoMax,
      // setEvoMax,
      types,
      setTypes,
      rarities,
      setRarities,
      affinities,
      setAffinities,
      classes,
      setClasses,
      variants,
      setVariants,
      specialties,
      setSpecialties,
      search,
      setSearch,
      clearFilters,
      setClearFilters,
      clearSearch,
      setClearSearch,
      lastSearch,
      setLastSearch,
      pageSize,
      setPageSize,
      pageNumber,
      setPageNumber,
      pageCount,
      setPageCount,
      nextPageToken,
      setNextPageToken,
    ],
  );

  return <MarketplaceContext.Provider value={value}>{children}</MarketplaceContext.Provider>;
};

export default MarketplaceProvider;
