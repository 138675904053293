import { FC } from 'react';
import useGameClientContext from 'src/hooks/useGameClientContext';
import Styled from 'src/components/game/ExitTheatreModeButton.styles';

const ExitTheatreModeButton: FC = () => {
  const { setTheatreMode } = useGameClientContext();

  return (
    <Styled.Root onClick={() => setTheatreMode(false)}>
      <span className="material-icons-outlined">fullscreen_exit</span>
    </Styled.Root>
  );
};

export default ExitTheatreModeButton;
