import { useContext } from 'react';
import LegalNoticeContext from 'src/context/legal-notice/LegalNotice.context';

const useLegalNoticeContext = () => {
  const legalNoticeContext = useContext(LegalNoticeContext);

  return legalNoticeContext;
};

export default useLegalNoticeContext;
