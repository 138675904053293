import { FC, memo } from 'react';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getNonfungibleBySeriesAndSerialNumber } from 'src/redux/slices/nonfungibles/nonfungibles';
import CustomScroll from 'src/components/custom-scroll/CustomScroll';
import NFTDetails from 'src/components/nft-details/NFTDetails';
import Styled from 'src/components/game-overlay/pages/PageNFTDetails.styles';

const PageNFTDetails: FC = () => {
  const { gameOverlayLocation } = useGameOverlayContext();
  const { seriesId = '', serialNumber = '' } = (gameOverlayLocation.params as Record<string, string>) || {};
  const nonfungible = useAppSelector(state =>
    getNonfungibleBySeriesAndSerialNumber(state, seriesId, parseInt(serialNumber, 10)),
  );
  const dependencies = [[nonfungible]];

  return (
    <Styled.Root>
      <CustomScroll dependencies={dependencies}>
        <NFTDetails style={{ paddingBottom: '3rem' }} />
      </CustomScroll>
    </Styled.Root>
  );
};

export default memo(PageNFTDetails);
