import { FC } from 'react';
import FusionStarIcon from 'src/components/icons/FusionStarIcon';
import FusionStarEmptyIcon from 'src/components/icons/FusionStarEmptyIcon';
import Styled from 'src/components/data-display/StarLevel.styles';

interface StarLevelProps {
  level: number;
  maxLevel: number;
  starSize?: number;
  hideEmptyLevels?: boolean;
  style?: React.CSSProperties;
}

const StarLevel: FC<StarLevelProps> = ({ level, maxLevel, starSize = 18, hideEmptyLevels = false, style = {} }) => {
  if (level !== 0 && !level) return null;
  if (maxLevel !== 0 && !maxLevel) return null;

  return (
    <Styled.Root starSize={starSize} style={style}>
      {new Array(maxLevel).fill('').map((v, i) => {
        const key = `fusion-star=${v || ''}${i}`;
        const value = parseInt((level || 0).toString(), 10);

        if (value > i) return <FusionStarIcon key={key} />;

        return !hideEmptyLevels ? <FusionStarEmptyIcon key={key} /> : null;
      })}
    </Styled.Root>
  );
};

export default StarLevel;
