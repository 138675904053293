import { createContext } from 'react';

export interface TutorialState {
  visible: boolean;
  starterMintPack: boolean;
  starterOpenPack: boolean;
  starterComplete: boolean;
  heroQuestExplain: boolean;
  heroQuestMid: boolean;
  heroQuestClaim: boolean;
  heroQuestComplete: boolean;
}

export interface ITutorialContext {
  progressTutorial: () => void;
  tutorialProgress: number;
  setTutorialProgress: (progress: number) => void;
  tutorialState: TutorialState;
  setTutorialState: (tutorialState: TutorialState) => void;
}

export const initialContext: ITutorialContext = {
  progressTutorial: () => undefined,
  tutorialProgress: -1,
  setTutorialProgress: () => undefined,
  tutorialState: {
    visible: false,
    starterMintPack: false,
    starterOpenPack: false,
    starterComplete: false,
    heroQuestExplain: false,
    heroQuestMid: false,
    heroQuestClaim: false,
    heroQuestComplete: false,
  },
  setTutorialState: () => undefined,
};

const TutorialContext = createContext<ITutorialContext>(initialContext);

TutorialContext.displayName = 'TutorialContext';

export default TutorialContext;
