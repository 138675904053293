import { PooleClient } from '@n3twork/poole-js';
import { config } from 'src/config/config';
import { getPooleEnv } from 'src/modules/poole/get-poole-env';

export const pooleWebsite = new PooleClient({
  aid: config.poole.website_aid,
  apiKey: process.env.REACT_APP_POOLE_WEBSITE_API_KEY || '',
  env: getPooleEnv(config.env),
  av: `${config.version}-${getPooleEnv(config.env)}`,
  did: 'browser',
});

// export const pooleUnity = new PooleClient({
//   aid: config.poole.unity_aid,
//   apiKey: process.env.REACT_APP_POOLE_UNITY_API_KEY || '',
//   env: getPooleEnv(config.env),
//   av: `${config.version}-${getPooleEnv(config.env)}`,
//   did: 'browser',
// });
