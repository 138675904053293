import { styled } from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const FaqsSection = styled('div')`
  box-sizing: border-box;
  position: relative;
  max-width: 840px;
  padding: 4rem 0 5.2rem;
  h3.headline {
    color: #fec300;
  }
  h2.main-title {
    font-size: 6rem;
    line-height: 1;
    margin: 0 0 3rem;
    border: 0;
    color: ${({ theme }) => theme.custom.colors.Yellow};
    ${({ theme }) => theme.breakpoints.down('xl')} {
      font-size: 4rem;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: 3rem;
      margin: 0 0 2rem;
    }
  }
  h3.headline {
    text-transform: uppercase;
    font-size: 1.8rem;
    margin: 0 0 0.5rem;
    color: #bbbbbb;
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.2rem;
    }
  }
  .content {
    text-transform: uppercase;
    color: #bbbbbb;
    padding: 0 0 2rem;
    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 12px;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      padding: 0 0 1rem;
    }
  }
`;
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    maxWidth: '100%',
    border: `0 solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: '#262626',
  flexDirection: 'row-reverse',
  borderBottom: '1px solid #404040',
  transition: 'color 0.3s ease, padding 0.3s ease',
  textTransform: 'uppercase',
  '& h6': {
    fontFamily: `${theme.custom.typography.LabelSm.fontFamily}`,
    lineHeight: '1.1',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '15px !important',
  },
  '&:hover': {
    color: `${theme.custom.colors.Yellow}`,
    paddingLeft: '10px !important',
    path: {
      fill: `${theme.custom.colors.Yellow}`,
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: '#1c1c1c',
  fontFamily: `${theme.custom.typography.LabelSm.fontFamily}`,
  padding: '25px',
  fontSize: '0.9rem',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  borderLeft: `2px solid ${theme.custom.colors.Yellow}`,
}));
export default { FaqsSection, Accordion, AccordionSummary, AccordionDetails };
