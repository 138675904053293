import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Styled from 'src/components/input/ButtonHex.styles';

export type Variant = 'white' | 'bronze' | 'silver' | 'gold';
export interface ButtonColorFields {
  text: string;
  outlineOuter: string;
  outlineInner: string;
  gradientID: string;
  progress: string;
}
export interface ButtonColors {
  white: ButtonColorFields;
  bronze: ButtonColorFields;
  silver: ButtonColorFields;
  gold: ButtonColorFields;
}
export interface IButtonHexProps {
  className?: string;
  width?: number;
  height?: number;
  cornerOffset?: number;
  strokeWidth?: number;
  variant?: Variant;
  fontSize?: number;
  fontWeight?: number;
  dropShadow?: string;
  enableDropShadow?: boolean;
  type?: 'submit' | 'button' | 'reset';
  // offsetX?: string;
  icon?: JSX.Element;
  title: string;
  processing?: boolean;
  disabled?: boolean;
  hoverEffect?: boolean;
  onClick?: VoidFunction;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const buttonColors: ButtonColors = {
  white: {
    text: '#222222',
    outlineOuter: '#aaaaaa',
    outlineInner: '#ffffff',
    gradientID: 'linear-gradient-white',
    progress: '#222222',
  },
  bronze: {
    text: '#ffffff',
    outlineOuter: '#814918',
    outlineInner: '#b26725',
    gradientID: 'linear-gradient-bronze',
    progress: '#ffffff',
  },
  silver: {
    text: '#ffffff',
    outlineOuter: '#666666',
    outlineInner: '#acacac',
    gradientID: 'linear-gradient-silver',
    progress: '#ffffff',
  },
  gold: {
    text: '#ffffff',
    outlineOuter: '#b9932d',
    outlineInner: '#f8c53e',
    gradientID: 'linear-gradient-gold',
    progress: '#ffffff',
  },
};

const ButtonHex: FC<IButtonHexProps> = ({
  className = '',
  variant = 'white',
  height = 61,
  width = 140,
  cornerOffset = 16,
  strokeWidth = 1.5,
  fontSize = 13,
  fontWeight = 700,
  dropShadow = '2px 3px 10px #000000cc',
  enableDropShadow = false,
  type = 'button',
  icon = null,
  title,
  processing = false,
  disabled = false,
  hoverEffect = false,
  onClick = () => undefined,
  textStyle = {},
  style = {},
}) => {
  const colors: ButtonColorFields = buttonColors[variant as Variant];
  const fullWidth = width + strokeWidth;
  const fullHeight = height + strokeWidth;
  const halfHeight = fullHeight / 2;
  const borderOffset = strokeWidth / 2;
  const spacing = 5; // Spacing Between Outline and Filled Hex

  // Filled Hex Variables
  // In order to obtain equal spacing between the outline Hex and the filled hex,
  // we need to do some trig math. This Math can be hard to follow. We need to find 2 lengths:
  // 1) We need to find the x-coordinate for the leftmost point of the filled hex
  // (this will represent a distance we can use for other calculations)
  // 2) We need to find the x-coordinate for the top-leftmost point of the filled hex
  // (this will represent a distance we can use for other calculations)
  const cornerAngleRad = Math.atan2(halfHeight, cornerOffset - borderOffset);
  const fillLeftCoordX = spacing / Math.sin(cornerAngleRad);
  const fillTopLeftDist = (halfHeight - spacing) / Math.tan(cornerAngleRad);
  const fillTopLeftCoordX = fillLeftCoordX + fillTopLeftDist;
  const strokeWidthInner = '1';

  // Filled Hex Points
  const fillPoint1 = `${fillLeftCoordX} ${halfHeight}`;
  const fillPoint2 = `${fillTopLeftCoordX} ${spacing}`;
  const fillPoint3 = `${fullWidth - fillTopLeftCoordX} ${spacing}`;
  const fillPoint4 = `${fullWidth - fillLeftCoordX} ${halfHeight}`;
  const fillPoint5 = `${fullWidth - fillTopLeftCoordX} ${fullHeight - spacing}`;
  const fillPoint6 = `${fillTopLeftCoordX} ${fullHeight - spacing}`;

  // Outlined Hex Points
  const outlinePoint1 = `${borderOffset} ${halfHeight}`;
  const outlinePoint2 = `${cornerOffset} ${borderOffset}`;
  const outlinePoint3 = `${fullWidth - cornerOffset} ${borderOffset}`;
  const outlinePoint4 = `${fullWidth - borderOffset} ${halfHeight}`;
  const outlinePoint5 = `${fullWidth - cornerOffset} ${fullHeight - borderOffset}`;
  const outlinePoint6 = `${cornerOffset} ${fullHeight - borderOffset}`;

  return (
    <Styled.Button
      className={className}
      type={type}
      width={fullWidth}
      height={fullHeight}
      color={colors.text}
      fontSize={fontSize}
      fontWeight={fontWeight}
      gradientID={colors.gradientID}
      hoverEffect={hoverEffect}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      <svg
        width={fullWidth}
        height={fullHeight}
        viewBox={`0 0 ${fullWidth} ${fullHeight}`}
        style={enableDropShadow ? { filter: `drop-shadow(${dropShadow})` } : {}}
      >
        <defs>
          <linearGradient id={colors.gradientID} x1="0" x2="0" y1="0" y2="1">
            <stop offset="0" stopColor={colors.outlineOuter} />
            <stop offset="1" stopColor={colors.outlineInner} />
          </linearGradient>

          <linearGradient id={`${colors.gradientID}-hover`} x1="0" x2="0" y1="0" y2="1">
            <stop offset="0" stopColor={colors.outlineInner} />
            <stop offset="1" stopColor={colors.outlineOuter} />
          </linearGradient>
        </defs>

        <path
          id="hex-filled"
          data-name="hex-filled"
          d={`M${fillPoint1} L${fillPoint2} L${fillPoint3} L${fillPoint4} L${fillPoint5} L${fillPoint6} L${fillPoint1} Z`}
          strokeWidth={strokeWidthInner}
          stroke={colors.outlineInner}
          strokeLinejoin="miter"
          fill={`url(#${colors.gradientID})`}
        />
      </svg>

      <svg width={fullWidth} height={fullHeight} viewBox={`0 0 ${fullWidth} ${fullHeight}`}>
        <path
          id="hex-outline"
          data-name="hex-outline"
          d={`M${outlinePoint1} L${outlinePoint2} L${outlinePoint3} L${outlinePoint4} L${outlinePoint5} L${outlinePoint6} L${outlinePoint1} Z`}
          strokeWidth={strokeWidth}
          stroke={colors.outlineOuter}
          fill="none"
          strokeLinejoin="miter"
        />
      </svg>

      {processing ? (
        <Box sx={{ display: 'flex', paddingTop: '.2rem' }}>
          <CircularProgress color="inherit" size={'1.1rem'} sx={{ color: colors.progress }} />
        </Box>
      ) : (
        <div style={{ ...textStyle, marginTop: icon ? '2px' : '0' }}>
          {icon}
          {title}
        </div>
      )}
    </Styled.Button>
  );
};

export default ButtonHex;
