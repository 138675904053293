import { FC, ReactNode } from 'react';
import Styled from 'src/components/layout/PolygonContainer.styles';

export interface PolygonContainerProps {
  children: ReactNode;
  strokeWidth?: number;
  fill?: string;
  glowRadius?: number;
  scaleX?: number;
  scaleY?: number;
  style?: React.CSSProperties;
}

const PolygonContainer: FC<PolygonContainerProps> = ({
  children = null,
  strokeWidth = 3,
  fill = 'none',
  glowRadius = 3,
  scaleX = 1,
  scaleY = 1,
  style = {},
}) => {
  const color = '#FEC300';
  const strokeCompensation = strokeWidth / 2;
  const paddingV = 20;
  const paddingH = 100;
  const offsetH = 10;
  const pixelDensity = 400;
  const pixelsX = 400 * scaleX;
  const pixelsY = 400 * scaleY;
  const point1 = `${strokeCompensation} ${paddingV + strokeCompensation}`;
  const point2 = `${paddingH + strokeCompensation} ${paddingV + strokeCompensation}`;
  const point3 = `${paddingH + offsetH + strokeCompensation} ${strokeCompensation}`;
  const point4 = `${pixelsX - strokeCompensation} ${strokeCompensation}`;
  const point5 = `${pixelsX - strokeCompensation} ${pixelsY - paddingV - strokeCompensation}`;
  const point6 = `${pixelsX - paddingH - strokeCompensation} ${pixelsY - paddingV - strokeCompensation}`;
  const point7 = `${pixelsX - paddingH - offsetH - strokeCompensation} ${pixelsY - strokeCompensation}`;
  const point8 = `${strokeCompensation} ${pixelsY - strokeCompensation}`;

  return (
    <Styled.Root color={color} glowRadius={glowRadius} style={{ ...style }}>
      <svg
        width={'100%'}
        height={'100%'}
        viewBox={`0 0 ${pixelDensity * scaleX} ${pixelDensity * scaleY}`}
        preserveAspectRatio="none"
      >
        <path
          id="polygon-container-outline"
          data-name="polygon-container-outline"
          d={`M${point1} L${point2} L${point3} L${point4} L${point5} L${point6} L${point7}  L${point8}  L${point1} Z`}
          strokeWidth={strokeWidth}
          stroke={color}
          strokeLinejoin="miter"
          fill={fill}
        />
      </svg>

      {/* <svg width={'8px'} height={'8px'} viewBox={`0 0 100 100`} preserveAspectRatio="none">
        <path
          id="polygon-container-square"
          data-name="polygon-container-square"
          d={`M0 50 L50 0 L100 50 L50 100 L0 50 Z`}
          strokeWidth={13}
          stroke={color}
          strokeLinejoin="miter"
          fill="none"
        />
      </svg> */}

      <Styled.InnerContainer padding={`${paddingV + strokeCompensation}px ${strokeWidth}px`}>
        {children}
      </Styled.InnerContainer>
    </Styled.Root>
  );
};

export default PolygonContainer;
