import { styled } from '@mui/material';

const ScrollWrapper = styled('div')`
  box-sizing: border-box;
  position: relative;
  left: -8vw;
  width: 100%;
  padding-left: 4rem;
  max-width: 500px;
  direction: rtl;
  height: 55rem;
  margin-bottom: 20vw;
  overflow-y: scroll;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    left: 0;
    margin-left: 20px;
    padding-right: 20px;
    padding-left: 2rem;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f9c43c;
    border-radius: 10px;
    border: 0 solid transparent;
    background-clip: content-box;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
`;

export default { ScrollWrapper };
