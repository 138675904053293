import { styled } from '@mui/material';

const Root = styled('div')`
  position: relative;
  padding: 3rem 2rem;
`;

const LogoRarity = styled('img')`
  display: block;
  width: 3.5rem;
  height: auto;
  margin: 0 auto;
  cursor: pointer;
`;

const Row = styled('div')`
  display: block;
  width: 100%;
  margin: 1rem 0 0 0;
  text-align: center;

  & > .material-icons {
    display: inline-block;
    margin-top: 0.5rem;
    vertical-align: middle;
    font-size: 1.3rem;
    color: #999;
  }

  & > img {
    display: inline-block;
    margin-top: 0.5rem;
    vertical-align: middle;
    font-size: 1.3rem;
    color: #999;
  }

  & > img.social-icon {
    display: inline-block;
    width: 2.5rem;
    height: auto;
    padding: 0 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }
`;

const Link = styled('span')`
  display: inline-block;
  margin: 0.5rem 1.2rem 0 1.2rem;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #fff;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 500px) {
    display: block;
  }
`;

const BrandingLabel = styled('span')`
  display: inline-block;
  margin: 0.5rem 0.9rem 0 0.6rem;
  vertical-align: middle;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #999;
`;

const Copyright = styled('p')`
  display: inline-block;
  width: 100%;
  max-width: 30rem;
  margin: 0.5rem 0 0 0;
  vertical-align: middle;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #999;
`;

const LegalLink = styled('span')`
  display: inline-block;
  margin: 0.5rem 0.5rem 0 0.5rem;
  vertical-align: middle;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: #fff;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

export default { Root, LogoRarity, Row, Link, BrandingLabel, Copyright, LegalLink };
