import { styled } from '@mui/material';

interface RootProps {
  soldOut: boolean;
  singleMarketExists: boolean;
}

const Root = styled('div')<RootProps>`
  display: inline-block;
  height: ${({ singleMarketExists }) => (singleMarketExists ? '97%' : '90%')};
  flex-grow: 1;
  box-sizing: border-box;
  position: relative;
  z-index: 0;

  @media (max-height: 500px) {
    height: ${({ singleMarketExists }) => (singleMarketExists ? '100%' : '96%')};
  }
`;

interface DetailsContainerProps {
  isDaily: boolean;
  singleMarketExists: boolean;
}

const DetailsContainer = styled('div')<DetailsContainerProps>`
  display: block;
  height: 100%;
  width: ${({ isDaily }) => (!isDaily ? 'calc(100% + 0.7rem)' : 'calc(100% + 6rem)')};
  min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '40rem')};
  max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '40rem')};
  padding: ${({ isDaily }) => (!isDaily ? '3rem' : '3rem 3rem 3rem 8.3rem')};
  left: ${({ isDaily }) => (!isDaily ? '-0.7rem' : '-6rem')};
  position: relative;

  @media (max-width: 1200px) {
    min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '35rem')};
    max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '35rem')};
  }

  @media (max-width: 1100px) {
    min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '30rem')};
    max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '30rem')};
  }

  @media (max-width: 1050px) {
    padding: ${({ isDaily }) => (!isDaily ? '2.5rem' : '2.5rem 2.5rem 2.5rem 7.8rem')};
  }

  @media (max-width: 1000px) {
    min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '25rem')};
    max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '25rem')};
  }

  @media (max-width: 900px) {
    min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '20rem')};
    max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '20rem')};
  }

  @media (max-height: 600px) {
    min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '30rem')};
    max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '30rem')};
  }

  @media (max-height: 500px) {
    min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '30rem')};
    max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '30rem')};
    padding: ${({ isDaily }) => (!isDaily ? '2rem 2.5rem' : '2rem 2.5rem 2rem 7.8rem')};
  }

  @media (max-height: 400px) {
    width: ${({ isDaily }) => (!isDaily ? 'calc(100% + 0.7rem)' : 'calc(100% + 4rem)')};
    min-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '25rem')};
    max-width: ${({ singleMarketExists }) => (singleMarketExists ? 'auto' : '25rem')};
    padding: ${({ isDaily }) => (!isDaily ? '1.5rem 2rem 1.5rem 2rem' : '1.5rem 2rem 1.5rem 5.3rem')};
    left: ${({ isDaily }) => (!isDaily ? '-0.7rem' : '-4rem')};
  }

  @media (max-height: 350px) {
    padding: ${({ isDaily }) => (!isDaily ? '1.5rem 2rem 1.2rem 2rem' : '1.5rem 2rem 1.2rem 5.3rem')};
  }
`;

const DetailsInnerContainer = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  overflow: visible;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const DetailRow = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 0.3rem 0.5rem;

  @media (max-width: 900px) {
    margin-top: 0.5rem;
    gap: 0.1rem 0.5rem;
  }

  @media (max-height: 500px) {
    margin-top: 0.5rem;
    gap: 0.1rem 0.5rem;
  }

  @media (max-height: 350px) {
    margin-top: 0.3rem;
    gap: 0rem 0.5rem;
  }
`;

const DescriptionRow = styled('div')`
  position: absolute;
  display: block;
  max-width: 100%;
  margin-top: 1.5rem;
  font-size: 1.2rem;

  @media (max-width: 1050px) {
    font-size: 1rem;
  }

  @media (max-width: 900px) {
    font-size: 0.8rem;
  }

  @media (min-aspect-ratio: 1.8) and (max-height: 600px) {
    display: none;
  }
`;

const BottomContainer = styled('div')`
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  & .primary-marketplace-mint-btn {
    width: 100%;
    max-width: 13rem;
    margin-top: 0.5rem;
  }

  @media (max-width: 900px) {
    & .primary-marketplace-mint-btn {
      height: 55px;
      max-width: 11rem;
      margin-top: 0.4rem;

      & .button-primary-text {
        font-size: 16px;
        font-weight: 600;
        line-height: 17px;
      }
    }
  }

  @media (max-height: 700px) {
    & .primary-marketplace-mint-btn {
      height: 60px;
      max-width: 11rem;
      margin-top: 0.4rem;

      & .button-primary-text {
        font-size: 17px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }

  @media (max-height: 600px) {
    & .primary-marketplace-mint-btn {
      height: 55px;
      max-width: 11rem;
      margin-top: 0.4rem;

      & .button-primary-text {
        font-size: 16px;
        font-weight: 600;
        line-height: 17px;
      }
    }
  }

  @media (max-height: 550px) {
    & .primary-marketplace-mint-btn {
      height: 36px;
      max-width: 8rem;
      margin-top: 0.4rem;

      & .button-primary-text {
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
      }
    }
  }

  @media (max-height: 350px) {
    & .primary-marketplace-mint-btn {
      height: 30px;
      max-width: 8rem;
      margin-top: 0.3rem;
      /* bottom: -10px; */

      & .button-primary-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
      }
    }
  }
`;

const QuantityContainer = styled('div')`
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  color: #ffffff;

  & > span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
    font-weight: 400;
  }

  @media (max-width: 1050px) {
    font-size: 1rem;
    line-height: 1rem;
  }

  @media (max-width: 900px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-height: 500px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  @media (max-height: 350px) {
    font-size: 0.7rem;
    line-height: 0.9rem;
    font-weight: 300;
  }
`;

const ButtonsContainer = styled('div')`
  display: block;
  position: relative;
`;

const PoweredBy = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  text-align: center;

  & svg > path {
    fill: #ffffff99;
  }

  & > span:nth-of-type(1) {
    display: inline-block;
    vertical-align: top;
    margin-right: 0.3rem;
    text-transform: uppercase;
    font-size: 0.6rem;
    line-height: 0.6rem;
    font-weight: 400;
    color: #ffffff99;
  }

  @media (max-width: 900px) {
    margin-top: 0.5rem;

    & > span:nth-of-type(1) {
      font-size: 0.5rem;
      line-height: 0.5rem;
    }
  }

  @media (max-height: 500px) {
    margin-top: 0.5rem;

    & > span:nth-of-type(1) {
      font-size: 0.5rem;
      line-height: 0.5rem;
    }
  }
`;

export default {
  Root,
  DetailsContainer,
  DetailsInnerContainer,
  DetailRow,
  DescriptionRow,
  QuantityContainer,
  // PriceCurrencyContainer,
  BottomContainer,
  ButtonsContainer,
  PoweredBy,
};
