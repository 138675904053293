/* eslint-disable no-param-reassign */
// import { logMain } from 'src/modules/logger/logger';

// Function to remove all figures, images, and iframes from a document
const normalizeAllIFrames = (doc: Document) => {
  // Grab all iframes
  const iframes = doc.querySelectorAll('iframe');

  // logMain.debug(iframes);

  iframes.forEach((iframe, i) => {
    const iFrameParent = iframe.parentElement;
    const iFrameContainerEl = document.createElement('div');
    const iFrameEl = document.createElement('iframe');

    // Set up the iframe container that contains the replacement iframe and will replace the old iframe.
    iFrameContainerEl.setAttribute('class', `iframe-container-${i}`);
    iFrameContainerEl.style.display = 'block';
    iFrameContainerEl.style.width = '100%';
    iFrameContainerEl.style.margin = '2rem auto 0 auto';
    iFrameContainerEl.style.aspectRatio = '16 / 9';

    // Set up the replacement iframe
    iFrameEl.setAttribute('class', `iframe-${i}`);
    iFrameEl.setAttribute('src', iframe.getAttribute('src') || '');
    iFrameEl.setAttribute('title', 'Embedded content');
    iFrameEl.setAttribute('width', '100%');
    iFrameEl.setAttribute('height', '100%');
    iFrameEl.setAttribute('frameborder', '0');
    iFrameEl.setAttribute('scrolling', 'no');

    // Append the new iframe as a child of iframe container
    iFrameContainerEl.appendChild(iFrameEl);

    // Replace old iframe with new iframe container
    iFrameParent?.replaceChild(iFrameContainerEl, iframe);
  });
};

// Function to remove all figures, images, and iframes from a document
const removeImagesFromDocument = (doc: Document) => {
  // Grab all figures and remove them
  const figures = doc.querySelectorAll('figure');
  figures.forEach(figure => {
    figure.remove();
  });
  // Grab all images and remove them
  const images = doc.querySelectorAll('img');
  images.forEach(image => {
    image.remove();
  });
  // Grab all iframes and remove them
  const iframes = doc.querySelectorAll('iframe');
  iframes.forEach(iframe => {
    iframe.remove();
  });
};

// Function to get an array of images from a document
const getImagesFromDocument = (doc: Document) => {
  const imageElements = doc.querySelectorAll('img');
  const imageElementsArray = Array.from(imageElements);

  return imageElementsArray;
};

export const getItemsFromRSS = (xml: string) => {
  // If the request is successful, parse the XML data using DOMParser
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, 'application/xml');

  // Extract relevant data from the parsed XML and convert it to JSON
  const items = xmlDoc.getElementsByTagName('item');

  // Map over items and parse them into readable object
  const jsonItems = Array.from(items).map(item => {
    // Get the content for later parsing
    const content = item.getElementsByTagName('content:encoded')[0]?.textContent || '';

    // DOCUMENT WITHOUT MEDIA: Parse the content using DOMParser
    const contentDocNoImages = parser.parseFromString(content, 'text/html');
    // DOCUMENT WITHOUT MEDIA: Obtain document with all images and figures removed
    removeImagesFromDocument(contentDocNoImages);

    // DOCUMENT WITH MEDIA: Parse the content using DOMParser
    const contentDoc = parser.parseFromString(content, 'text/html');
    // DOCUMENT WITH MEDIA: Obtain document with all iframes normalized
    normalizeAllIFrames(contentDoc);
    // DOCUMENT WITH MEDIA: Get image elements from the parsed content
    const contentImageElements = getImagesFromDocument(contentDoc);
    // DOCUMENT WITH MEDIA: Parse the image elements into an array of objects
    const contentImages = contentImageElements.map(el => {
      return {
        src: el.getAttribute('src') || '',
        alt: el.getAttribute('alt') || '',
      };
    });

    return {
      categories: Array.from(item.getElementsByTagName('category') || []).map(el => el.textContent),
      link: item.getElementsByTagName('link')[0]?.textContent,
      title: item.getElementsByTagName('title')[0]?.textContent,
      pubDate: item.getElementsByTagName('pubDate')[0]?.textContent,
      images: contentImages || [],
      thumbnailImage: contentImages.find(image => image.alt.indexOf('thumbnail') !== -1) ||
        contentImages[0] || { src: '', alt: '' },
      contentRaw: contentDoc.body.innerHTML,
      contentRawNoImages: contentDocNoImages.body.innerHTML,
    };
  });

  // logMain.debug(jsonItems);

  return jsonItems;
};
