/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import LineTextureSVG from 'src/components/svg/LineTexture.svg';
import Styled from 'src/components/backgrounds/LineTextureBackground.styles';

interface LineTextureBackgroundProps {
  size?: number;
  stroke?: string;
  strokeWidth?: number;
  downScale?: number;
  animationDelay?: number;
}

const LineTextureBackground: FC<LineTextureBackgroundProps> = ({
  size = window.innerWidth,
  stroke = '#6FB6E718',
  strokeWidth = 0.5,
  downScale = 1,
  animationDelay = 0,
}) => {
  return (
    <Styled.Root>
      <Styled.LineTextureBackground size={size} downScale={downScale} animationDelay={animationDelay}>
        <LineTextureSVG fill={'#ffffff00'} stroke={stroke} strokeWidth={strokeWidth} />
      </Styled.LineTextureBackground>
    </Styled.Root>
  );
};

export default LineTextureBackground;
