type PackTier = 'starter' | 'alpha' | 'bronze' | 'silver' | 'gold';
interface PackTierColors {
  main: string;
  bgPrimary: string;
  bgSecondary: string;
  buttonVariant: 'white' | 'bronze' | 'silver' | 'gold';
}

export const getPackTierColors = (tier: PackTier): PackTierColors => {
  switch (tier) {
    case 'starter':
      return {
        main: '#86FEEE',
        bgPrimary: '#1a2920',
        bgSecondary: '#09110d',
        buttonVariant: 'white',
      };
    case 'alpha':
      return {
        main: '#fbb783',
        bgPrimary: '#1a2920',
        bgSecondary: '#09110d',
        buttonVariant: 'white',
      };
    case 'bronze':
      return {
        main: '#b66826',
        bgPrimary: '#071137',
        bgSecondary: '#090a11',
        buttonVariant: 'bronze',
      };
    case 'silver':
      return {
        main: '#9da0af',
        bgPrimary: '#1e0036',
        bgSecondary: '#0b0016',
        buttonVariant: 'white',
      };
    case 'gold':
      return {
        main: '#dea423',
        bgPrimary: '#21060b',
        bgSecondary: '#0d0303',
        buttonVariant: 'gold',
      };
    default:
      return {
        main: '#dea423',
        bgPrimary: '#21060b',
        bgSecondary: '#0d0303',
        buttonVariant: 'gold',
      };
  }
};
