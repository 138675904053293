import { FC } from 'react';
// import { logMain } from 'src/modules/logger/logger';
import Styled from 'src/components/data-display/StatusIndicator.styles';

interface StatusIndicatorProps {
  active: boolean;
  style?: React.CSSProperties;
}

const StatusIndicator: FC<StatusIndicatorProps> = ({ active, style = {} }) => {
  return (
    <Styled.Root linked={active} style={style}>
      <Styled.Indicator linked={active} />
    </Styled.Root>
  );
};

export default StatusIndicator;
