import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCurrentProfilePurchaseHistory = () => (_dispatch: any) => {
  const config = {
    method: 'get',
    url: '/api/web/me/purchase-history',
  };

  return request(config);
};
