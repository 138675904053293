import { FC } from 'react';
import Styled from 'src/components/pages/nft/components/Description.styles';

interface DescriptionProps {
  desc: string | null;
  emptyText: string;
}

const Description: FC<DescriptionProps> = ({ desc, emptyText }) => {
  if (!desc) return <Styled.EmptyText>{emptyText}</Styled.EmptyText>;

  return <Styled.Root>{desc}</Styled.Root>;
};

export default Description;
