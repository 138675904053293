import { FC } from 'react';
import { usePentarcShardsIcon } from 'src/hooks/usePentarcShardsIcon';
import Styled from 'src/components/icons/ATCIcon.styles';

interface PentarcShardsIconProps {
  resolution?: 'small' | 'large' | 'full';
  size?: number;
  style?: React.CSSProperties;
}

const PentarcShardsIcon: FC<PentarcShardsIconProps> = ({ resolution = 'small', size = 16, style = {} }) => {
  const { title, width, height, path } = usePentarcShardsIcon(resolution);

  return (
    <Styled.Root
      size={size}
      src={`/assets/${path}`}
      width={width}
      height={height}
      alt={title}
      title={title}
      style={style}
    />
  );
};

export default PentarcShardsIcon;
