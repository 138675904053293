export const getPurchaseStatusMeta = (status: string): { color: string; txt: string; pending: boolean } => {
  const meta = {
    color: '#ffffff77',
    txt: 'Status Unavailable',
    pending: false,
  };

  switch (status) {
    case 'intent_received':
      meta.color = '#118ab2';
      meta.txt = 'Purchase Initiated';
      meta.pending = true;
      break;
    case 'redirect_requested':
      meta.color = '#118ab2';
      meta.txt = 'Pending';
      meta.pending = true;
      break;
    case 'redirect_returned':
      meta.color = '#118ab2';
      meta.txt = 'Pending';
      meta.pending = true;
      break;
    case 'compliance_failed':
      meta.color = '#ef476f';
      meta.txt = 'Compliance Failed';
      break;
    case 'in_process':
      meta.color = '#118ab2';
      meta.txt = 'Pending';
      meta.pending = true;
      break;
    case 'payment_completed':
      meta.color = '#118ab2';
      meta.txt = 'Payment Complete';
      break;
    case 'payment_failed':
      meta.color = '#ef476f';
      meta.txt = 'Payment Failed';
      break;
    case 'atc_minted':
      meta.color = '#118ab2';
      meta.txt = 'Complete';
      break;
    case 'asset_transferred':
      meta.color = '#118ab2';
      meta.txt = 'Complete';
      break;
    case 'purchase_succeeded':
      meta.color = '#04ae81';
      meta.txt = 'Purchase Complete';
      break;
    default:
      break;
  }

  return meta;
};
