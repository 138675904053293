import { FC } from 'react';
import { SvgIcon } from '@mui/material';

const ForteWhiteHeaderIcon: FC = () => (
  <SvgIcon width="57" height="22" viewBox="0 0 57 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.2269 9.26708L30.6362 6.20717H29.3954V9.26708H26.9722V0.156738H31.6781C33.7646 0.156738 34.9497 1.49529 34.9497 3.18838C34.9497 4.77357 33.9608 5.61883 33.0753 5.91943L34.9895 9.25937L32.2269 9.26708ZM32.492 3.17553C32.492 2.54608 31.9618 2.20695 31.3228 2.20695H29.406V4.15953H31.3228C31.9724 4.15953 32.4947 3.81783 32.4947 3.17553H32.492Z"
      fill="#EDEDED"
    />
    <path d="M41.0926 9.26684V2.20672H38.4414V0.151367H46.13V2.20672H43.5079V9.26684H41.0926Z" fill="#EDEDED" />
    <path
      d="M50.1069 9.26707V0.156738H57.0001V2.21209H52.5222V3.63028H56.8914V5.68563H52.5222V7.22714H57.0001V9.28249L50.1069 9.26707Z"
      fill="#EDEDED"
    />
    <path
      d="M18.2921 1.75188e-05C17.3299 -0.00252466 16.3885 0.271661 15.5871 0.787862C14.7858 1.30406 14.1605 2.03907 13.7904 2.89983C13.4204 3.76058 13.3222 4.70839 13.5083 5.62325C13.6944 6.53811 14.1565 7.37889 14.836 8.03914C15.5154 8.6994 16.3818 9.14944 17.3254 9.33229C18.2689 9.51515 19.2473 9.42259 20.1365 9.06634C21.0258 8.71009 21.7859 8.10616 22.3208 7.33102C22.8557 6.55588 23.1412 5.64438 23.1412 4.71191C23.1412 3.46446 22.6307 2.26791 21.7217 1.38463C20.8127 0.501349 19.5794 0.00341841 18.2921 1.75188e-05ZM18.2921 7.15006C17.7937 7.15057 17.3064 7.00779 16.8918 6.7398C16.4772 6.4718 16.154 6.09064 15.963 5.64455C15.7721 5.19846 15.722 4.7075 15.8191 4.2338C15.9162 3.76011 16.1561 3.32498 16.5085 2.98348C16.8609 2.64199 17.3099 2.40948 17.7987 2.31538C18.2876 2.22129 18.7942 2.26984 19.2545 2.45489C19.7149 2.63994 20.1082 2.95318 20.3848 3.35494C20.6613 3.75671 20.8086 4.22896 20.8081 4.71191C20.8081 5.35855 20.543 5.9787 20.0712 6.43594C19.5994 6.89319 18.9594 7.15006 18.2921 7.15006Z"
      fill="#EDEDED"
    />
    <path
      d="M9.97259 2.20451V0.154297H3.07941V13.8506C2.08924 14.1333 1.23735 14.7523 0.679863 15.5941C0.122371 16.436 -0.103384 17.4443 0.0439708 18.4344C0.191325 19.4244 0.701919 20.3299 1.48218 20.9848C2.26244 21.6397 3.26011 22.0001 4.29234 22.0001C5.32458 22.0001 6.32224 21.6397 7.1025 20.9848C7.88276 20.3299 8.39336 19.4244 8.54071 18.4344C8.68807 17.4443 8.46231 16.436 7.90482 15.5941C7.34733 14.7523 6.49544 14.1333 5.50528 13.8506V5.67291H9.87184V3.61756H5.50528V2.20451H9.97259ZM6.32716 17.838C6.32768 18.2289 6.20855 18.6111 5.98485 18.9363C5.76114 19.2616 5.44291 19.5152 5.07042 19.6651C4.69792 19.8151 4.28791 19.8546 3.89224 19.7786C3.49658 19.7027 3.13306 19.5147 2.84766 19.2385C2.56227 18.9623 2.36783 18.6103 2.28894 18.227C2.21006 17.8436 2.25028 17.4463 2.40451 17.0851C2.55875 16.7239 2.82006 16.4152 3.1554 16.198C3.49073 15.9808 3.88502 15.8649 4.28836 15.8649C4.82863 15.8649 5.3468 16.0727 5.72907 16.4426C6.11134 16.8126 6.32645 17.3145 6.32716 17.838Z"
      fill="#EDEDED"
    />
  </SvgIcon>
);

export default ForteWhiteHeaderIcon;
