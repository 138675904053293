import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDropEvents = (options?: RequestOptions) => (_dispatch: any) => {
  const { queryParams } = options || {};
  let url = '/api/web/dropevent';

  if (queryParams && Object.keys(queryParams).length > 0) {
    let started = false;

    Object.keys(queryParams).forEach(key => {
      const value = queryParams[key];

      if (typeof value !== 'undefined') {
        if (Array.isArray(value)) {
          value.forEach((item: string | number) => {
            if (!started) {
              url += '?';
              started = true;
            } else {
              url += '&';
            }

            url += `${key}=${item}`;
          });
        } else {
          if (!started) {
            url += '?';
            started = true;
          } else {
            url += '&';
          }

          url += `${key}=${value}`;
        }
      }
    });
  }

  const config = {
    method: 'get',
    url,
  };

  return request(config);
};
