import { FC } from 'react';
import Auth from 'src/components/pages/auth/Auth';
import HomePage from 'src/components/pages/home/HomePage';
import Play from 'src/components/pages/play/Play';
import Profile from 'src/components/pages/profile/Profile';
import Events from 'src/components/pages/events/Events';
import EventPack from 'src/components/pages/event-pack/EventPack';
import Marketplace from 'src/components/pages/marketplace/Marketplace';
import Leaderboards from 'src/components/pages/leaderboards/Leaderboards';
import Leaderboard from 'src/components/pages/leaderboard/Leaderboard';
import NFT from 'src/components/pages/nft/NFT';
import GettingStarted from 'src/components/pages/getting-started/GettingStarted';
import Blog from 'src/components/pages/blog/Blog';
import BlogDetails from 'src/components/pages/blog-details/BlogDetails';
import Farcaster from 'src/components/pages/farcaster/Farcaster';
import NotFound from 'src/components/pages/not-found/NotFound';

export interface RouteConfig {
  path: string;
  root?: boolean;
  component?: FC;
  pageType:
    | 'Auth'
    | 'Home'
    | 'Play'
    | 'Profile'
    | 'Events'
    | 'Event Pack'
    | 'Marketplace'
    | 'Leaderboards'
    | 'Leaderboard'
    | 'NFT'
    | 'Getting Started'
    | 'Blog'
    | 'Blog Details'
    | 'Farcaster'
    | 'Page Not Found';
  nestedRoutes?: Record<string, RouteConfig>;
}

const routesConfig: Record<string, RouteConfig> = {
  root: {
    path: '/',
    root: true,
    pageType: 'Home',
  },
  auth: {
    path: '/auth',
    component: Auth,
    pageType: 'Auth',
  },
  homePage: {
    path: '/home',
    component: HomePage,
    pageType: 'Home',
  },
  // homePage alias
  homeAlias0Page: {
    path: '/index',
    component: HomePage,
    pageType: 'Home',
  },
  homeAlias1Page: {
    path: '/index.html',
    component: HomePage,
    pageType: 'Home',
  },
  play: {
    path: '/play',
    component: Play,
    pageType: 'Play',
  },
  profile: {
    path: '/profile',
    component: Profile,
    pageType: 'Profile',
    nestedRoutes: {
      playerId: {
        path: ':playerId',
        component: Profile,
        pageType: 'Profile',
      },
      noPlayerId: {
        path: '',
        component: Profile,
        pageType: 'Profile',
      },
    },
  },
  events: {
    path: '/events',
    component: Events,
    pageType: 'Events',
  },
  eventPack: {
    path: '/event/pack/:eventId',
    component: EventPack,
    pageType: 'Event Pack',
  },
  marketplace: {
    path: '/marketplace',
    component: Marketplace,
    pageType: 'Marketplace',
  },
  leaderboards: {
    path: '/leaderboards',
    component: Leaderboards,
    pageType: 'Leaderboards',
  },
  leaderboard: {
    path: '/leaderboards/:leaderboardId',
    component: Leaderboard,
    pageType: 'Leaderboard',
  },
  nft: {
    path: '/nft/:seriesId/:serialNumber',
    component: NFT,
    pageType: 'NFT',
  },
  gettingStarted: {
    path: '/getting-started',
    component: GettingStarted,
    pageType: 'Getting Started',
  },
  blog: {
    path: '/blog',
    component: Blog,
    pageType: 'Blog',
  },
  blogDetails: {
    path: '/blog/:blogTitle',
    component: BlogDetails,
    pageType: 'Blog Details',
  },
  farcaster: {
    path: '/link-farcaster',
    component: Farcaster,
    pageType: 'Farcaster',
  },
  notFound: {
    path: '/page-not-found',
    component: NotFound,
    pageType: 'Page Not Found',
  },
  catchAll: {
    path: '*',
    component: NotFound,
    pageType: 'Page Not Found',
  },
};

export const RootRedirect = {
  from: '/',
  to: routesConfig.homePage.path,
};

export default routesConfig;
