/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PooleEvents } from 'src/modules/poole/events';
import { LocalStorage } from 'src/local-storage/local-storage';
import GameOverlayContext, {
  initialContext,
  IGameOverlayContext,
  GameOverlayLocation,
} from 'src/context/game-overlay/GameOverlay.context';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getGameProfile } from 'src/api/game-profile/get-game-profile';
import { tutorialStateExists } from 'src/tools/tutorialStateExists';
import { config } from 'src/config/config';
import { logMain } from 'src/modules/logger/logger';

const copyGameOverlayLocation = (gameOverlayLocation: GameOverlayLocation): GameOverlayLocation => {
  return {
    ...gameOverlayLocation,
    params: {
      ...gameOverlayLocation.params,
    },
  };
};

const copyGameOverlayHistory = (gameOverlayHistory: GameOverlayLocation[]): GameOverlayLocation[] => {
  return gameOverlayHistory.map(gameOverlayLocation => copyGameOverlayLocation(gameOverlayLocation));
};

interface GameOverlayProviderProps {
  children: ReactNode;
}

const GameOverlayProvider: FC<GameOverlayProviderProps> = ({ children }) => {
  const location = useLocation();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const tutorialStates = useAppSelector(({ tutorialStates }) => tutorialStates.tutorialStates);
  const [dimensions, setDimensions] = useState<IGameOverlayContext['dimensions']>(initialContext.dimensions);
  const [enableGameOverlay, setEnableGameOverlay] = useState<IGameOverlayContext['enableGameOverlay']>(
    initialContext.enableGameOverlay,
  );
  const [gameOverlayLocation, setGameOverlayLocation] = useState<IGameOverlayContext['gameOverlayLocation']>(
    initialContext.gameOverlayLocation,
  );
  const [gameOverlayHistory, setGameOverlayHistory] = useState<IGameOverlayContext['gameOverlayHistory']>(
    initialContext.gameOverlayHistory,
  );
  const [blurbs, setBlurbs] = useState<IGameOverlayContext['blurbs']>(initialContext.blurbs);
  // Debug States for testing
  const [enableGameOverlayContents, setEnableGameOverlayContents] = useState<
    IGameOverlayContext['enableGameOverlayContents']
  >(initialContext.enableGameOverlayContents);
  const [enablePipsUnopenedPacks, setEnablePipsUnopenedPacks] = useState<
    IGameOverlayContext['enablePipsUnopenedPacks']
  >(initialContext.enablePipsUnopenedPacks);
  const dispatch = useAppDispatch();
  // const marketplaceEnabled = currentProfile?.features?.marketplace?.enabled;

  const handleGetGameProfile = useCallback(() => {
    dispatch(getGameProfile()).catch((e: any) => {
      logMain.debug('Failed to load game profile', e);
    });
  }, [dispatch]);

  const progressBlurbs = useCallback(() => {
    setBlurbs(prevBlurbs => {
      const newBlurbs = [...prevBlurbs];
      newBlurbs.shift();
      return newBlurbs;
    });
  }, []);

  const addBlurbs = useCallback((blurbs: string[]) => {
    setBlurbs(prevBlurbs => [...prevBlurbs, ...blurbs]);
  }, []);

  // Function to open the game overlay
  const openGameOverlay = useCallback(
    async (location: GameOverlayLocation) => {
      const newLocation = copyGameOverlayLocation(location);
      const starterMintPackTutorial = tutorialStateExists(tutorialStates, 'starter_1_mint');
      const starterOpenPackTutorial = tutorialStateExists(tutorialStates, 'starter_2_open');
      const heroQuestExplainTutorial = tutorialStateExists(tutorialStates, 'heroQuest_02_explain');
      const heroQuestClaimTutorial = tutorialStateExists(tutorialStates, 'heroQuest_06_claim');

      // Wait for tutorial and currencies to laod before opening the game overlay and subcomponents
      await handleGetGameProfile();

      // Check for applicatable tutorial states / hero quests / etc. to take user to correct market or page of overlay.
      if (starterMintPackTutorial && newLocation.page !== 'primary-marketplace') {
        // If user is starting mint pack tutorial, take user to primary marketplace.
        newLocation.page = 'primary-marketplace';
      } else if (starterOpenPackTutorial && newLocation.page !== 'collection') {
        // If user is starting open pack tutorial, take user to collection.
        newLocation.page = 'collection';
      } else if (heroQuestExplainTutorial) {
        // If user is starting Yuna quest, take user to Yuna market.
        newLocation.page = 'primary-marketplace';
        newLocation.params = { ...(newLocation?.params || {}), listPriceMarketId: 'yuna' };
      } else if (heroQuestClaimTutorial && newLocation.page === 'primary-marketplace') {
        // If user needs to claim Yuna, take user to Yuna market.
        newLocation.page = 'primary-marketplace';
        newLocation.params = { ...(newLocation?.params || {}), listPriceMarketId: 'yuna' };
      }

      PooleEvents.GameOverlayOpen({
        playerId: currentProfile?.id,
        from: window.location.pathname,
        fromContext: 'game_client',
        gameOverlayPageTo: newLocation.page,
        gameOverlayOpenListPriceMarketId: (newLocation?.params?.listPriceMarketId as string | undefined) || '',
        gameOverlayOpenNonfungibleId: (newLocation?.params?.nonfungibleId as string | undefined) || '',
        gameOverlayOpenConfigId: (newLocation?.params?.configId as string | undefined) || '',
        gameOverlayOpenGameClientContext: (newLocation?.params?.from as string | undefined) || '',
      });

      logMain.debug('[GAME_OVERLAY]: Opening Game Overlay', newLocation);

      setGameOverlayHistory(initialContext.gameOverlayHistory);
      setGameOverlayLocation(newLocation);
      setEnableGameOverlay(true);
    },
    [currentProfile?.id, handleGetGameProfile, tutorialStates],
  );

  // Function to close the game overlay
  const closeGameOverlay = useCallback(() => {
    if (enableGameOverlay === false) return;

    PooleEvents.GameOverlayClose({
      playerId: currentProfile?.id,
      from: window.location.pathname,
      fromContext: 'game_client',
      gameOverlayPageFrom: gameOverlayLocation.page,
    });

    setBlurbs([]);
    setEnableGameOverlay(false);
    setGameOverlayLocation(initialContext.gameOverlayLocation);
    setGameOverlayHistory(initialContext.gameOverlayHistory);
  }, [currentProfile?.id, enableGameOverlay, gameOverlayLocation.page]);

  // Function to navigate the game overlay
  const navigateGameOverlay = useCallback(
    (location: GameOverlayLocation) => {
      const newLocation = copyGameOverlayLocation(location);
      const oldLocation = copyGameOverlayLocation(gameOverlayLocation);
      const newHistory = copyGameOverlayHistory(gameOverlayHistory);
      const heroQuestClaimTutorial = tutorialStateExists(tutorialStates, 'heroQuest_06_claim');

      // Check for applicatable tutorial states / hero quests / etc. to take user to correct market.
      // If user needs to claim Yuna, take user to Yuna market.
      if (
        heroQuestClaimTutorial &&
        newLocation.page === 'primary-marketplace' &&
        newLocation?.params?.listPriceMarketId !== 'yuna'
      ) {
        newLocation.params = { ...(newLocation?.params || {}), listPriceMarketId: 'yuna' };
      }

      PooleEvents.GameOverlayNavigate({
        playerId: currentProfile?.id,
        from: window.location.pathname,
        fromContext: 'game_client',
        gameOverlayPageTo: newLocation.page,
        gameOverlayPageFrom: oldLocation.page,
      });

      logMain.debug('[GAME_OVERLAY]: Navigating Game Overlay', newLocation);

      newHistory.push(oldLocation);

      setBlurbs([]);
      setGameOverlayLocation(newLocation);
      setGameOverlayHistory(newHistory);
    },
    [gameOverlayLocation, gameOverlayHistory, tutorialStates, currentProfile?.id],
  );

  // Function to navigate the game overlay back (back button functionality)
  const navigateBackGameOverlay = useCallback(() => {
    const newHistory = copyGameOverlayHistory(gameOverlayHistory);

    if (newHistory.length > 0) {
      const newLocation = copyGameOverlayLocation(newHistory.pop() as GameOverlayLocation);

      PooleEvents.GameOverlayNavigate({
        playerId: currentProfile?.id,
        from: window.location.pathname,
        fromContext: 'game_client',
        gameOverlayPageTo: newLocation.page,
        gameOverlayPageFrom: gameOverlayLocation.page,
      });

      logMain.debug('[GAME_OVERLAY]: Navigating Game Overlay (BACK)', newLocation);

      setBlurbs([]);
      setGameOverlayLocation(newLocation);
      setGameOverlayHistory(newHistory);
    }
  }, [currentProfile?.id, gameOverlayHistory, gameOverlayLocation.page]);

  // Listen to changes in overlay opens status and make the marketOverlayEnabled boolean accessible to the
  // game client by exposing it as a global variable
  useEffect(() => {
    if (location.pathname.indexOf('play') === -1) {
      closeGameOverlay();
    }
  }, [closeGameOverlay, location, location.pathname]);

  // Listen to changes in overlay opens status and make the marketOverlayEnabled boolean accessible to the
  // game client by exposing it as a global variable
  useEffect(() => {
    if (typeof window?.lhuGlobals === 'undefined') {
      window.lhuGlobals = {
        serverEnv: config.server_env,
        marketOverlayEnabled: enableGameOverlay,
      };
    } else {
      window.lhuGlobals = { ...window.lhuGlobals, marketOverlayEnabled: enableGameOverlay };
    }
  }, [enableGameOverlay]);

  // Update LocalStorage when widget settings change
  useEffect(() => {
    if (enablePipsUnopenedPacks) {
      logMain.debug(`%c[DISCORD BOT]: Enabling pips for unopened packs`, 'color: magenta;');
      LocalStorage.set('gameplay_enable_pips_unopened_packs', 'true');
    } else {
      logMain.debug(`%c[DISCORD BOT]: Disabling pips for unopened packs`, 'color: magenta;');
      LocalStorage.set('gameplay_enable_pips_unopened_packs', 'false');
    }
  }, [enablePipsUnopenedPacks]);

  const value = useMemo(
    () => ({
      openGameOverlay,
      closeGameOverlay,
      navigateGameOverlay,
      navigateBackGameOverlay,
      progressBlurbs,
      addBlurbs,
      enableGameOverlay,
      gameOverlayLocation,
      gameOverlayHistory,
      blurbs,
      setBlurbs,
      dimensions,
      setDimensions,
      enableGameOverlayContents,
      setEnableGameOverlayContents,
      enablePipsUnopenedPacks,
      setEnablePipsUnopenedPacks,
    }),
    [
      openGameOverlay,
      closeGameOverlay,
      navigateGameOverlay,
      navigateBackGameOverlay,
      progressBlurbs,
      addBlurbs,
      enableGameOverlay,
      gameOverlayLocation,
      gameOverlayHistory,
      blurbs,
      setBlurbs,
      dimensions,
      setDimensions,
      enableGameOverlayContents,
      setEnableGameOverlayContents,
      enablePipsUnopenedPacks,
      setEnablePipsUnopenedPacks,
    ],
  );

  return <GameOverlayContext.Provider value={value}>{children}</GameOverlayContext.Provider>;
};

export default GameOverlayProvider;
