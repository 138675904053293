import { styled, keyframes } from '@mui/material';

const grow = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(0.2);
        opacity: 1;
    }
    50% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
`;

interface RootProps {
  topOffset: number;
  rightOffset: number;
}

const Root = styled('div')<RootProps>`
  display: inline-block;
  width: 34px;
  height: 34px;
  overflow: visible;
  position: absolute;
  top: ${({ topOffset }) => (topOffset ? `${topOffset}px` : '0')};
  right: ${({ rightOffset }) => (rightOffset ? `${rightOffset}px` : '0')};
  transform: translate(50%, -50%);
`;

const Dot = styled('div')`
  background: radial-gradient(#ff7700, #ff0000aa);
  display: inline-block;
  width: 9px;
  height: 9px;
  vertical-align: middle;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const GrowingCircle = styled('div')`
  display: inline-block;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border: 2px solid red;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 1;

  &.grow-delay-0 {
    animation: ${grow} 2.2s linear 0s infinite;
  }

  &.grow-delay-1 {
    animation: ${grow} 2.2s linear 0.3s infinite;
  }

  &.grow-delay-2 {
    animation: ${grow} 2.2s linear 0.6s infinite;
  }
`;

export default {
  Root,
  Dot,
  GrowingCircle,
};
