import { styled } from '@mui/material';

const Root = styled('div')`
  background: url('/assets/lhu_texture_bkg_001.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: initial;
  display: block;
  width: 100%;
  box-sizing: border-box;
`;

const HeaderContainer = styled('div')`
  display: block;
  padding: 6rem 2rem 0 2rem;
`;

const Heading = styled('h2')`
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 5.2rem;
  line-height: 5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  @media (max-width: 768px) {
    font-size: 4rem;
    line-height: 4rem;
  }

  @media (max-width: 400px) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  width: 100%;
  max-width: 42rem;
  margin: 0.5rem auto 0 auto;
  text-align: center;
  word-break: break-word;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: #ffffff;

  @media (max-width: 768px) {
    margin-top: 1rem;
    line-height: 1.4rem;
  }
`;

const EmailContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 33rem;
  margin: 2rem auto 0 auto;
`;

const EmailHeading = styled('p')`
  display: block;
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
  text-align: left;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  @media (max-width: 768px) {
    margin-top: 1rem;
    line-height: 1.4rem;
  }
`;

const EmailSubheading = styled('p')`
  display: block;
  width: 100%;
  max-width: 25rem;
  margin: 0.5rem auto 0 auto;
  text-align: left;
  word-break: break-word;
  font-size: 1rem;
  line-height: 1.3rem;
  font-weight: 600;
  color: #ffffff;
`;

const ContentOuterContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 4rem 2rem 0 2rem;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media (max-width: 800px) {
    padding: 2rem 2rem 0 2rem;
  }
`;

const ContentInnerContainer = styled('div')`
  background-color: #00000077;
  display: block;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  padding: 3rem 5rem;

  @media (max-width: 768px) {
    padding: 3rem 3rem;
  }

  @media (max-width: 575px) {
    padding: 1.5rem 1.5rem 3rem 1.5rem;
  }
`;

const DetailsContainer = styled('div')`
  display: block;
  margin: 6rem 5rem 6rem 5rem;

  & > div:nth-of-type(n + 2) {
    margin-top: 4rem;
  }

  @media (max-width: 768px) {
    margin: 3rem 3rem 3rem 3rem;
  }

  @media (max-width: 575px) {
    margin: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
`;

const Detail = styled('div')`
  display: block;
  width: 100%;
  max-width: 20rem;
  box-sizing: border-box;

  @media (max-width: 800px) {
    max-width: none;
  }
`;

const DetailTitle = styled('p')`
  display: block;
  width: 100%;
  text-align: left;
  /* text-transform: uppercase; */
  word-break: break-word;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const DetailBody = styled('p')`
  display: block;
  width: 100%;
  margin-top: 0.7rem;
  text-align: left;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
  color: #ffffff;
`;

const RequirementsContainer = styled('div')`
  display: block;
  margin-top: 1.5rem;

  & > div:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const Requirement = styled('div')`
  display: block;
  width: 100%;
`;

const RequirementText = styled('p')`
  display: block;
  margin-left: 2rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const KeyDatesContainer = styled('div')`
  display: block;
  margin-top: 1.5rem;

  & > div:not(:first-of-type) {
    margin-top: 0.7rem;
  }
`;

const KeyDate = styled('div')`
  display: block;
  width: 100%;
`;

const KeyDateLabel = styled('div')`
  display: inline-block;
  width: calc(50% - 0.5rem);
  margin-right: 0.5rem;
  text-align: left;
  /* text-transform: uppercase; */
  vertical-align: top;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 700;
  color: #ffffff;
  /* color: ${({ theme }) => theme.custom.colors.Yellow}; */
`;

const KeyDateValue = styled('div')`
  display: inline-block;
  width: 50%;
  /* margin-top: 0.3rem; */
  text-align: right;
  vertical-align: top;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  /* color: #ffffff; */
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const HeadlineContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 6rem;
`;

const HeadlineTitle = styled('p')`
  display: block;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  word-break: break-word;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 700;
  color: #ffffff;

  @media (max-width: 575px) {
    font-size: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 400px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`;

const HeadlineSubtitle = styled('p')`
  display: block;
  width: 100%;
  max-width: 32rem;
  margin-top: 0.5rem;
  text-align: left;
  word-break: break-word;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #ffffff;

  @media (max-width: 575px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

  & > a {
    color: ${({ theme }) => theme.custom.colors.Yellow};
    outline: none;
    text-decoration: none;
  }

  & > a:hover,
  & > a:active,
  & > a:link,
  & > a:focus {
    color: ${({ theme }) => theme.custom.colors.Yellow};
    text-decoration: none;
  }
`;

const HeadlineImageContainer = styled('div')`
  background-color: #000000aa;
  display: block;
  width: 100%;
  max-width: 40rem;
  margin-top: 2rem;
  padding: 1.5rem;
  box-sizing: border-box;
  position: relative;

  @media (max-width: 768px) {
    padding: 1.2rem;
  }
`;

const HeadlineImage = styled('img')`
  display: block;
  width: 100%;
  height: auto;
  border: ${({ theme }) => `1px solid ${theme.custom.colors.Yellow}`};
`;

const HeroImageOverlay = styled('img')`
  display: block;
  width: 80rem;
  height: auto;
  position: absolute;
  top: 0;
  left: 46%;

  @media (max-width: 1000px) {
    left: 55%;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export default {
  Root,
  HeaderContainer,
  Heading,
  Subheading,
  EmailContainer,
  EmailHeading,
  EmailSubheading,
  ContentOuterContainer,
  ContentInnerContainer,
  DetailsContainer,
  Detail,
  DetailTitle,
  DetailBody,
  RequirementsContainer,
  Requirement,
  RequirementText,
  KeyDatesContainer,
  KeyDate,
  KeyDateLabel,
  KeyDateValue,
  HeadlineContainer,
  HeadlineTitle,
  HeadlineSubtitle,
  HeadlineImageContainer,
  HeadlineImage,
  HeroImageOverlay,
};
