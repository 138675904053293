import { styled } from '@mui/material';

const Root = styled('div')`
  &.cursor-default {
    cursor: url('/assets/cursor_default.svg'), auto;

    /* Override cursor for child elements to ensure consistency */
    & * {
      cursor: inherit !important;
    }
  }

  &.cursor-pointer {
    cursor: url('/assets/cursor_default.svg'), pointer;

    /* Override cursor for child elements to ensure consistency */
    & * {
      cursor: inherit !important;
    }
  }

  &.cursor-loading {
    cursor: url('/assets/cursor_default.svg'), wait;

    /* Override cursor for child elements to ensure consistency */
    & * {
      cursor: inherit !important;
    }
  }
`;

export default {
  Root,
};
