import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { formatInTimeZone } from 'date-fns-tz';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import Level from 'src/components/data-display/Level';
import XP from 'src/components/data-display/XP';
import Styled from 'src/components/pages/nft/components/Metadata.styles';

interface MetadataProps {
  attributes: NonfungibleAttribute[];
  heroProgress: HeroProgress;
  username: string;
  emptyText: string;
}

const Metadata: FC<MetadataProps> = ({ emptyText, attributes, heroProgress, username }) => {
  const { t } = useTranslation(nameSpace.common);
  const navigateInternal = useAppNavigateInternal();
  const { xp = 0, xpInLevel = 1 } = heroProgress as HeroProgress;
  const levelAttr = attributes.find(attr => attr.traitType === 'Level');
  const evoAttr = attributes.find(attr => attr.traitType === 'Evolution');

  // Minted Date
  // const mintedDate = nonfungible?.creationTimestamp
  //   ? formatInTimeZone(new Date(nonfungible?.creationTimestamp), 'America/New_York', 'dd LLL yyyy')
  //   : 'N/A';

  if (!heroProgress || !attributes || attributes.length === 0)
    return (
      <Styled.Root>
        <Styled.EmptyText>{emptyText}</Styled.EmptyText>
      </Styled.Root>
    );

  return (
    <Styled.Root>
      {/* <Styled.DetailsContainer>
            <Styled.DetailContainer>
              <Styled.DetailLabel>Serial Number</Styled.DetailLabel>
              <Styled.DetailValue>{`#${nonfungible?.serialNumber || '-'}/${
                nonfungible?.maxSerialNumber || '-'
              }`}</Styled.DetailValue>
            </Styled.DetailContainer>

            <Styled.DetailContainer>
              <Styled.DetailLabel>Minted</Styled.DetailLabel>
              <Styled.DetailValue>{mintedDate}</Styled.DetailValue>
            </Styled.DetailContainer>
          </Styled.DetailsContainer> */}

      {levelAttr && evoAttr && (
        <Styled.HeroProgressContainer>
          {levelAttr && (
            <Styled.HeroProgressSegment>
              <Styled.HeroProgressTitle>{t('common.term.level')}</Styled.HeroProgressTitle>

              <Styled.HeroProgressLevelContainer>
                <Level
                  level={(levelAttr.value as number) || 1}
                  variant={'condensed'}
                  enableBase={false}
                  borderStyle={'circle'}
                />
              </Styled.HeroProgressLevelContainer>

              <Styled.HeroProgressXPContainer>
                <XP xp={xp} xpInLevel={xpInLevel} />
              </Styled.HeroProgressXPContainer>
            </Styled.HeroProgressSegment>
          )}

          {evoAttr && (
            <Styled.HeroProgressSegment>
              <Styled.HeroProgressTitle>{t('common.term.evolution')}</Styled.HeroProgressTitle>

              <Styled.HeroProgressEvoContainer>
                <Level
                  level={(evoAttr.value as number) || 1}
                  variant={'condensed'}
                  enableBase
                  borderStyle={'diamond'}
                />
              </Styled.HeroProgressEvoContainer>
            </Styled.HeroProgressSegment>
          )}
        </Styled.HeroProgressContainer>
      )}

      {attributes && attributes.length > 0 && (
        <Styled.AttributesContainer>
          {username && (
            <Styled.AttributeRow key={`nft-attribute-username`}>
              <Styled.AttributeLabel>{t('common.term.owned.by')}</Styled.AttributeLabel>

              <Styled.Username
                onClick={() =>
                  navigateInternal(`/profile/${username}`, {
                    pooleData: { fromContext: 'nft_details' },
                  })
                }
              >
                {username}
              </Styled.Username>
            </Styled.AttributeRow>
          )}

          {attributes.map((attribute, i) => {
            const skippedAttributes = [
              'Class Type',
              'Affinity',
              'Rarity',
              'Serial Number',
              'Series',
              'Burn Date',
              'Print Number',
              'Star Level',
              'Level',
              'Evolution',
              'Specialty Type',
            ];

            if (skippedAttributes.indexOf(attribute.traitType) === -1) {
              if (attribute.traitType === 'Birthday' && typeof attribute.value === 'number') {
                return (
                  <Styled.AttributeRow key={`${attribute.traitType}-${i}`}>
                    <Styled.AttributeLabel>{attribute.traitType}</Styled.AttributeLabel>

                    <Styled.AttributeValue>
                      {attribute.value
                        ? formatInTimeZone(new Date(attribute.value * 1000), 'America/New_York', 'dd LLL yyyy')
                        : 'N/A'}
                    </Styled.AttributeValue>
                  </Styled.AttributeRow>
                );
              }

              // if (attribute.traitType === 'Star Level' && typeof attribute.value === 'number') {
              //   return (
              //     <Styled.AttributeRow key={`${attribute.traitType}-${i}`}>
              //       <Styled.AttributeLabel>{attribute.traitType}</Styled.AttributeLabel>

              //       <Styled.AttributeValue>
              //         {new Array(attribute.maxValue || 7).fill('').map((v, i) => {
              //           const key = `fusion-star=${v || ''}${i}`;
              //           const value = parseInt((attribute.value || 0).toString(), 10);

              //           if (value > i) return <FusionStarIcon key={key} />;

              //           return <FusionStarEmptyIcon key={key} />;
              //         })}
              //       </Styled.AttributeValue>
              //     </Styled.AttributeRow>
              //   );
              // }

              return (
                <Styled.AttributeRow key={`${attribute.traitType}-${i}`}>
                  <Styled.AttributeLabel>{attribute.traitType}</Styled.AttributeLabel>

                  <Styled.AttributeValue>{attribute.value}</Styled.AttributeValue>
                </Styled.AttributeRow>
              );
            }

            return null;
          })}

          {/* <Styled.AttributeRow>
                <Styled.AttributeLabel>{t('common.term.minted')}</Styled.AttributeLabel>
                <Styled.AttributeValue>{mintedDate}</Styled.AttributeValue>
              </Styled.AttributeRow> */}
        </Styled.AttributesContainer>
      )}
    </Styled.Root>
  );
};

export default Metadata;
