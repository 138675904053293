import { FC } from 'react';
import { getNFTImage } from 'src/tools/getNFTImage';
import Styled from 'src/components/data-display/leaderboard/Guild.styles';

const getStyles = (variant: Variant) => {
  switch (variant) {
    case 'condensed':
      return {
        size: '2.1rem',
        margin: '0.2rem 0.8rem 0 0',
        fontSize: '1.4rem',
      };
    case 'default':
    default:
      return {
        size: '2.5rem',
        margin: '0.2rem 0.8rem 0 0',
        fontSize: '1.8rem',
      };
  }
};

type Variant = 'condensed' | 'default';

interface GuildProps {
  displayName: string;
  imageUrl: string;
  variant?: Variant;
  style?: React.CSSProperties;
}

const Affinity: FC<GuildProps> = ({ imageUrl, displayName, variant = 'default', style = {} }) => {
  return (
    <Styled.Root style={style}>
      <Styled.InlineLogo
        // color={'#fff'}
        color={'#fff'}
        variantStyles={getStyles(variant)}
        style={{ backgroundImage: `url(${getNFTImage(imageUrl || '')})` }}
      />

      <Styled.InlineText color={'#fff'} variantStyles={getStyles(variant)}>
        {displayName}
      </Styled.InlineText>
    </Styled.Root>
  );
};

export default Affinity;
