import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 30rem;
  padding: 0 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 700;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 600px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  @media (max-height: 500px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  margin-top: 1.3rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 700;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-width: 600px) {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  @media (max-height: 500px) {
    margin-top: 0.8rem;
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
`;

export default { Root, ContentContainer, Heading, Subheading };
