/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
// import { PooleEvents } from 'src/modules/poole/events';
import SoundContext, { initialContext, ISoundContext } from 'src/context/sound/Sound.context';
import { LocalStorage } from 'src/local-storage/local-storage';
import { logMain } from 'src/modules/logger/logger';

const UNCLICKABLE_TARGET_IDS: string[] = [];
const UNCLICKABLE_TARGET_CLASS_NAMES: string[] = ['unity-game-client'];

interface SoundProviderProps {
  children: ReactNode;
}

const SoundProvider: FC<SoundProviderProps> = ({ children }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [enableClickSounds, setEnableClickSounds] = useState<ISoundContext['enableClickSounds']>(
    initialContext.enableClickSounds,
  );

  const playSoundClick = useCallback(
    (e: any) => {
      // logMain.debug('Processing click event...', e);

      if (e) {
        if (
          UNCLICKABLE_TARGET_IDS.indexOf(e.target?.id) > -1 ||
          UNCLICKABLE_TARGET_CLASS_NAMES.indexOf(e.target?.className) > -1
        ) {
          logMain.debug('Click Event - NOT Playing Sound.');

          return;
        }
      }

      // Only play sound on non-mobile devices
      if (enableClickSounds && !isMobile && audioRef.current) {
        if (typeof audioRef.current.play === 'function') {
          // logMain.debug('Playing Sound - Click');

          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          audioRef.current.play();
        }
      }
    },
    [enableClickSounds],
  );

  useEffect(() => {
    window.addEventListener('click', playSoundClick);

    return () => {
      window.removeEventListener('click', playSoundClick);
    };
  }, [playSoundClick]);

  // Update LocalStorage when widget settings change
  useEffect(() => {
    if (enableClickSounds) {
      logMain.debug(`%c[DISCORD BOT]: Enabling cursor clicks sounds`, 'color: magenta;');
      LocalStorage.set('audio_enable_click_sounds', 'true');
    } else {
      logMain.debug(`%c[DISCORD BOT]: Disabling cursor clicks sounds`, 'color: magenta;');
      LocalStorage.set('audio_enable_click_sounds', 'false');
    }
  }, [enableClickSounds]);

  const value = useMemo(
    () => ({
      playSoundClick,
      enableClickSounds,
      setEnableClickSounds,
    }),
    [playSoundClick, enableClickSounds, setEnableClickSounds],
  );

  return (
    <SoundContext.Provider value={value}>
      {/* Audio element */}
      <audio ref={audioRef} src="/assets/sound_click.mp3" preload="auto" />

      {children}
    </SoundContext.Provider>
  );
};

export default SoundProvider;
