import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ATCIcon from 'src/components/icons/ATCIcon';
import PentarcShardsIcon from 'src/components/icons/PentarcShardsIcon';
import PentarcDustIcon from 'src/components/icons/PentarcDustIcon';
import { useMediaQuery } from '@mui/material';
import Styled from 'src/components/game-overlay/Balances.styles';
import { formatPrice } from 'src/tools/formatPrice';

const Balances: FC = () => {
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const currencyStates = useAppSelector(({ currencyStates }) => currencyStates.currencyStates);
  // const bpMaxHeight700 = useMediaQuery('(max-height:700px)');
  // const bpMaxWidth1000 = useMediaQuery('(max-width:1000px)');
  const bp = useMediaQuery('(max-width:1000px) or (max-height:700px)');
  const atcBalance = formatPrice(currentProfile?.atcBalance, { decimals: 0, hideSymbol: true });
  const pentarcShardsBalance = currencyStates['pentarc-shards']
    ? formatPrice(currencyStates['pentarc-shards'].amount, { decimals: 0, hideSymbol: true })
    : 0;
  const pentarcDustBalance = currencyStates['pentarc-dust']
    ? formatPrice(currencyStates['pentarc-dust'].amount, { decimals: 0, hideSymbol: true })
    : 0;

  return (
    <Styled.Root>
      <PrimaryModalBackground disableLineTexture />

      <Styled.BalancesContainer>
        <Styled.Balance>
          <ATCIcon resolution={'small'} size={bp ? 16 : 19} style={{ margin: '2px 0.4rem 0 0' }} />

          <Styled.BalanceValue>{atcBalance}</Styled.BalanceValue>
          {/* <Styled.BalanceLabel>{t('common.project.currency.shorthand')}</Styled.BalanceLabel> */}
        </Styled.Balance>

        <Styled.Separator />

        <Styled.Balance>
          <PentarcShardsIcon resolution={'small'} size={bp ? 18 : 21} style={{ margin: '2px 0.4rem 0 0' }} />

          <Styled.BalanceValue style={{ marginBottom: '0px' }}>{pentarcShardsBalance || 0}</Styled.BalanceValue>
          {/* <Styled.BalanceLabel>{t('common.project.currency')}</Styled.BalanceLabel> */}
        </Styled.Balance>

        <Styled.Separator />

        <Styled.Balance>
          <PentarcDustIcon resolution={'small'} size={bp ? 19 : 22} style={{ margin: '2px 0.4rem 0 0' }} />

          <Styled.BalanceValue style={{ marginBottom: '0px' }}>{pentarcDustBalance || 0}</Styled.BalanceValue>
          {/* <Styled.BalanceLabel>{t('common.project.currency')}</Styled.BalanceLabel> */}
        </Styled.Balance>
      </Styled.BalancesContainer>
    </Styled.Root>
  );
};

export default Balances;
