import { CSSProperties } from 'react';
import { styled } from '@mui/material';
import BaseIcon from '../icons/BaseIcon';
import { IMediaProps } from './Media';

const Root = styled('div')<{
  active: number;
  borderradius: IMediaProps['borderRadius'];
}>`
  background-color: #000;
  height: fit-content;
  position: relative;
  width: 100%;
  border-radius: ${({ borderradius }) => borderradius};
  border: ${({ active, theme }) => (active ? `1px solid ${theme.custom.colors.White}` : 'initial')};
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  img {
    transition: transform 8s ease, opacity 0.8s ease;
    border-radius: ${({ borderradius }) => borderradius};
  }
  &:hover {
    img {
      opacity: 0.7;
      transform: scale(1.04);
    }
    svg {
      filter: drop-shadow(0 0 4px rgb(213 170 30 / 0.9));
    }
  }
`;

const Image = styled('img')<{
  loaded: number;
  aspectratio: CSSProperties['aspectRatio'];
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  minwidth?: CSSProperties['minWidth'];
  minheight?: CSSProperties['minHeight'];
  maxwidth?: CSSProperties['maxWidth'];
  maxheight?: CSSProperties['maxHeight'];
}>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  object-fit: cover;
  transition: opacity 0.3s ease-in;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  aspect-ratio: ${({ aspectratio }) => aspectratio};
  border: 1px solid ${({ theme }) => theme.custom.colors.BorderDefault};
  max-width: ${({ maxwidth }) => maxwidth};
  min-width: ${({ minwidth }) => minwidth};
  max-height: ${({ maxheight }) => maxheight};
  min-height: ${({ minheight }) => minheight};
`;

const Icon = styled(BaseIcon)`
  position: absolute;
  cursor: pointer;
`;

export default { Image, Root, Icon };
