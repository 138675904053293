import { copyListPriceMarket } from './copy-list-price-market';

export const copyListPriceMarkets = (listPriceMarkets: ListPriceMarket[]): ListPriceMarketsKC => {
  const returnedListPriceMarkets: ListPriceMarketsKC = {};

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < listPriceMarkets.length; i += 1) {
    const listPriceMarket: ListPriceMarket = listPriceMarkets[i];
    const id = listPriceMarket.id as ListPriceMarketId;

    if (listPriceMarket) {
      returnedListPriceMarkets[id] = copyListPriceMarket(listPriceMarket);
    }
  }

  return returnedListPriceMarkets;
};
