import { FC } from 'react';
import Styled from 'src/components/data-display/leaderboard/Row.styles';
// import { logMain } from 'src/modules/logger/logger';

const getStyles = (variant: Variant) => {
  switch (variant) {
    case 'condensed':
      return {
        padding: '0.5rem 2rem',
      };
    case 'default':
    default:
      return {
        padding: '0.7rem 2rem',
      };
  }
};

type Variant = 'condensed' | 'default';

interface RowProps {
  children: React.ReactNode;
  variant?: Variant;
  enableHighlight?: boolean;
  enableBorder?: boolean;
  enableGradient?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Row: FC<RowProps> = ({
  children,
  variant = 'default',
  enableHighlight = true,
  enableBorder = true,
  enableGradient = true,
  onClick = () => null,
  style = {},
}) => {
  return (
    <Styled.Root
      variantStyles={getStyles(variant)}
      enableGradient={enableGradient}
      enableBorder={enableBorder}
      onClick={onClick}
      style={style}
    >
      {enableHighlight && (
        <>
          <Styled.BorderGradientImg
            src={`/assets/gold_radial_gradient.webp`}
            width="1200"
            height="18"
            alt="Gold Border Gradient"
            title="Gold Border Gradient"
            style={{ top: '-11px' }}
          />
          <Styled.BorderGradientImg
            src={`/assets/gold_radial_gradient.webp`}
            width="1200"
            height="18"
            alt="Gold Border Gradient"
            title="Gold Border Gradient"
            style={{ bottom: '-11px' }}
          />
        </>
      )}
      {children}
    </Styled.Root>
  );
};

export default Row;
