import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { PooleEvents } from 'src/modules/poole/events';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { useInterval } from 'src/hooks/useInterval';
import { createListing } from 'src/api/secondary-markets/create-listing';
import { getAsyncOperation } from 'src/api/async-operations/getAsyncOperation';
import { setSnackbar } from 'src/redux/slices/snackbar/snackbar';
import { listCollectionItem } from 'src/redux/slices/collection-items/collection-items';
import { listNonfungible } from 'src/redux/slices/nonfungibles/nonfungibles';
import { formatPrice } from 'src/tools/formatPrice';
import ErrorMessage from 'src/components/feedback/ErrorMessage';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import ATCIcon from 'src/components/icons/ATCIcon';
// import Alert from 'src/components/feedback/Alert';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import {
  Tooltip,
  // CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { logMain } from 'src/modules/logger/logger';
import { config } from 'src/config/config';
import Styled from 'src/components/modals/ListNFTModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';
import { ServerError } from 'src/api/request';

interface ListNFTModalProps {
  nonfungibleId: string;
  open: boolean;
  onClose: () => Promise<void>;
  style?: React.CSSProperties;
}

const ListNFTModal: FC<ListNFTModalProps> = ({ nonfungibleId, open, onClose, style = {} }) => {
  const { enableGameOverlay } = useGameOverlayContext();
  const [step, setStep] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [price, setPrice] = useState(0);
  const [polling, setPolling] = useState(false);
  const [asyncOperationId, setAsyncOperationId] = useState('');
  const [error, setError] = useState('');
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const dispatch = useAppDispatch();
  const navigateExternal = useAppNavigateExternal();
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const normalizedPrice = formatPrice(price, { decimals: 0, hideSymbol: true });
  const priceUSD = (price * config.app_variables.lc_conversion).toFixed(2);
  const normalizedPriceUSD = formatPrice(price * config.app_variables.lc_conversion);
  const royaltyFee = Math.round(price * config.app_variables.royalty_fee);
  const listingFee = Math.round(price * config.app_variables.listing_fee);
  const partnerFee = Math.round(price * config.app_variables.partner_fee);
  const normalizedRoyaltyFee = formatPrice(Math.round(price * config.app_variables.royalty_fee), {
    decimals: 0,
    hideSymbol: true,
  });
  // const normalizedListingFee = formatPrice(Math.round(price * config.app_variables.listing_fee), {
  //   decimals: 0,
  //   hideSymbol: true,
  // });
  const normalizedPartnerFee = formatPrice(Math.round(price * config.app_variables.partner_fee), {
    decimals: 0,
    hideSymbol: true,
  });
  const estPayout = price - royaltyFee - listingFee - partnerFee;
  const normalizedEstPayout = formatPrice(estPayout, { decimals: 0, hideSymbol: true });
  const royaltyFeePercent = `${config.app_variables.royalty_fee * 100}%`;
  // const listingFeePercent = `${config.app_variables.listing_fee * 100}%`;
  const partnerFeePercent = `${config.app_variables.partner_fee * 100}%`;
  const meetsMinListPrice = parseFloat(priceUSD) >= config.app_variables.min_list_price_usd;
  const meetsMaxListPrice = parseFloat(priceUSD) <= config.app_variables.max_list_price_usd;

  const handleClose = () => {
    // onClose();

    if (step === 2) {
      dispatch(listCollectionItem(nonfungibleId, price));
      dispatch(listNonfungible(nonfungibleId, price));
    }

    PooleEvents.CreateListingClose({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'create_listing_modal',
      nonfungibleId,
      priceLC: price ? price.toString() : '0',
      priceUSD: price ? priceUSD : '0',
    });

    if (polling) {
      // Fire Poole event for secondary purchase polling start
      // PooleEvents.CreateListingPollingAbort({
      //   playerId: currentProfile.id,
      //   from: window.location.pathname,
      //   fromContext: 'secondary_purchase_modal',
      //   nonfungibleId: nft?.id || '',
      //   listingId: nft?.listingId || '',
      //   priceLC: (nft?.price || 0).toString(),
      //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
      //   asyncOperationId,
      // });
    }

    onClose().then(() => {
      setPolling(false);
      setAsyncOperationId('');
      setProcessing(false);
      setPrice(0);
      setStep(0);
      setError('');
    });
  };

  const handleSubmitPrice = () => {
    PooleEvents.CreateListingPrice({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'create_listing_modal',
      nonfungibleId,
      priceLC: price ? price.toString() : '0',
      priceUSD: price ? priceUSD : '0',
    });

    setStep(1);
  };

  const handleConfirmPrice = () => {
    setProcessing(true);

    PooleEvents.CreateListingConfirm({
      dataset: enableGameOverlay ? 'unity' : 'website',
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'create_listing_modal',
      nonfungibleId,
      priceLC: price ? price.toString() : '0',
      priceUSD: price ? priceUSD : '0',
    });

    dispatch(createListing({ data: { nonfungibleId, price: price.toString(), priceFungibleId: '' } }))
      .then(data => {
        PooleEvents.CreateListingConfirmSuccess({
          dataset: enableGameOverlay ? 'unity' : 'website',
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'create_listing_modal',
          nonfungibleId,
          priceLC: price ? price.toString() : '0',
          priceUSD: price ? priceUSD : '0',
        });

        if (data?.id) {
          setAsyncOperationId(data?.id);
          setPolling(true);
        } else {
          dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
          setError(t('common.error.message.1'));
        }

        setProcessing(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        logMain.debug(e);

        // Fire Poole event for create listing fail
        PooleEvents.CreateListingConfirmFail({
          dataset: enableGameOverlay ? 'unity' : 'website',
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'create_listing_modal',
          nonfungibleId,
          priceLC: price ? price.toString() : '0',
          priceUSD: price ? priceUSD : '0',
          reason: e instanceof Error ? e.message : String(e),
        });

        const message = e instanceof ServerError ? e.message : t('common.error.message.1');
        dispatch(setSnackbar({ severity: 'error', msg: message }));
        setError(message);
        setProcessing(false);
        setPolling(false);
      });
  };

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const valueUSD = (parseInt(value, 10) * config.app_variables.lc_conversion).toFixed(2);
    const valueMeetsMinListPrice = parseFloat(valueUSD) >= config.app_variables.min_list_price_usd;
    const valueMeetsMaxListPrice = parseFloat(valueUSD) <= config.app_variables.max_list_price_usd;

    setPrice(parseInt(value, 10));

    if (valueMeetsMinListPrice && valueMeetsMaxListPrice) {
      setError('');
    } else {
      const errorPart1 = valueMeetsMinListPrice
        ? t('common.popup.listing.error.max.price')
        : t('common.popup.listing.error.min.price');
      const minLC = config.app_variables.min_list_price_usd / config.app_variables.lc_conversion;
      const maxLC = config.app_variables.max_list_price_usd / config.app_variables.lc_conversion;

      setError(`${errorPart1} ${valueMeetsMinListPrice ? maxLC : minLC} ${t('common.project.currency')}`);
    }
  };

  // If user initiated creating a listing, poll the async op endpoint to recieve redirect URL
  useInterval(() => {
    if (polling && asyncOperationId) {
      // Fire Poole event for secondary purchase polling start
      // PooleEvents.CreateListingPollingStart({
      //   playerId: currentProfile.id,
      //   from: window.location.pathname,
      //   fromContext: 'secondary_purchase_modal',
      //   nonfungibleId: nft?.id || '',
      //   listingId: nft?.listingId || '',
      //   priceLC: (nft?.price || 0).toString(),
      //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
      //   asyncOperationId,
      // });

      dispatch(getAsyncOperation({ urlParams: { asyncOperationId } }))
        .then(data => {
          if (data?.status === 'pending') {
            logMain.debug('[DEBUG]: Operation pending');
            logMain.debug({ data });

            // Fire Poole event for secondary purchase polling pending
            // PooleEvents.CreateListingPollingPending({
            //   playerId: currentProfile.id,
            //   from: window.location.pathname,
            //   fromContext: 'secondary_purchase_modal',
            //   nonfungibleId: nft?.id || '',
            //   listingId: nft?.listingId || '',
            //   priceLC: (nft?.price || 0).toString(),
            //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            //   asyncOperationId,
            // });
          } else if (data?.status === 'success') {
            logMain.debug('[DEBUG]: Operation succeeded');
            logMain.debug({ data });

            // Fire Poole event for secondary purchase polling start
            // PooleEvents.CreateListingPollingSuccess({
            //   playerId: currentProfile.id,
            //   from: window.location.pathname,
            //   fromContext: 'secondary_purchase_modal',
            //   nonfungibleId: nft?.id || '',
            //   listingId: nft?.listingId || '',
            //   priceLC: (nft?.price || 0).toString(),
            //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            //   asyncOperationId,
            // });

            dispatch(setSnackbar({ severity: 'success', msg: t('common.popup.listing.success') }));
            setError('');
            setPolling(false);
            setAsyncOperationId('');
            setStep(2);
          } else if (data?.status === 'failure' || data?.error) {
            logMain.debug('[DEBUG]: Operation failed', data?.error);
            logMain.debug({ data, uri: data?.error?.metadata?.kyc_redirect_url });

            if (data?.error?.metadata?.kyc_redirect_url) {
              navigateExternal(data?.error?.metadata?.kyc_redirect_url, {
                pooleData: { pageName: 'Forte Pay', fromContext: 'create_listing' },
              });
            } else {
              dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
              setError(t('common.error.message.1'));
              setPolling(false);
              setAsyncOperationId('');
              setStep(0);
            }

            // Fire Poole event for secondary purchase polling start
            // PooleEvents.CreateListingPollingFail({
            //   playerId: currentProfile.id,
            //   from: window.location.pathname,
            //   fromContext: 'secondary_purchase_modal',
            //   nonfungibleId: nft?.id || '',
            //   listingId: nft?.listingId || '',
            //   priceLC: (nft?.price || 0).toString(),
            //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
            //   asyncOperationId,
            //   reason: data?.error.toString() || 'Error during secondary purchase polling.',
            // });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((e: any) => {
          logMain.debug(e);

          // Fire Poole event for secondary purchase polling start
          // PooleEvents.CreateListingPollingFail({
          //   playerId: currentProfile.id,
          //   from: window.location.pathname,
          //   fromContext: 'secondary_purchase_modal',
          //   nonfungibleId: nft?.id || '',
          //   listingId: nft?.listingId || '',
          //   priceLC: (nft?.price || 0).toString(),
          //   priceUSD: ((nft?.price || 0) * config.app_variables.lc_conversion).toFixed(2),
          //   asyncOperationId,
          //   reason: e instanceof Error ? e.message : String(e),
          // });

          dispatch(setSnackbar({ severity: 'error', msg: t('common.error.message.1') }));
          setError(t('common.error.message.1'));
          setPolling(false);
          setAsyncOperationId('');
          setStep(0);
        });
    }
  }, config.poll_interval.nft_purchase);

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.listNFT}` }}
    >
      <Fade in={open}>
        <Styled.Root style={style}>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            {step !== 2 && <Styled.Heading>{t('common.popup.listing.heading')}</Styled.Heading>}

            <ErrorMessage msg={error} style={{ margin: '1.5rem 0 0 0' }} />

            {step === 0 && (
              <>
                <Styled.SubheadingsContainer>
                  <Styled.Subheading>{t('common.popup.listing.step.0.subheading.1')}</Styled.Subheading>
                </Styled.SubheadingsContainer>

                <Styled.InputLabel>{t('common.term.amount')}</Styled.InputLabel>

                <Styled.PriceInputContainer>
                  <Styled.PriceInput
                    id="price"
                    type="number"
                    value={price}
                    placeholder={t('common.popup.listing.step.0.input.placeholder')}
                    onChange={handleOnInputChange}
                  />

                  <Styled.PriceCurrency>
                    <ATCIcon size={16} style={{ margin: '0 0.5rem 1px 0' }} />
                    {t('common.project.currency')}
                  </Styled.PriceCurrency>
                </Styled.PriceInputContainer>
              </>
            )}

            {step === 1 && (
              <Styled.SubheadingsContainer>
                <Styled.Subheading>{t('common.popup.listing.step.1.subheading.1')}</Styled.Subheading>

                <Styled.Subheading>{t('common.popup.listing.step.1.subheading.2')}</Styled.Subheading>
              </Styled.SubheadingsContainer>
            )}

            {step === 2 && (
              <>
                <Styled.ConfirmationIconContainer>
                  <span className="material-icons-outlined">check_circle</span>
                </Styled.ConfirmationIconContainer>

                <Styled.ConfirmationTitle>{t('common.popup.listing.step.2.title')}</Styled.ConfirmationTitle>

                <Styled.ConfirmationSubtitle>{t('common.popup.listing.step.2.subtitle')}</Styled.ConfirmationSubtitle>

                {/* <Alert
                    severity={'info'}
                    msg={t('common.message.listing.delay')}
                    hideClose
                    style={{ marginTop: '1.5rem' }}
                  /> */}
              </>
            )}

            {step !== 2 && (
              <>
                <Styled.SummaryContainer>
                  <Styled.SummaryTitle>{t('common.popup.listing.summary.title')}</Styled.SummaryTitle>

                  <Styled.SummaryRows>
                    <Styled.SummaryRow>
                      <Styled.SummaryLabel>{t('common.popup.listing.summary.label.1')}</Styled.SummaryLabel>

                      <Styled.SummaryValue>
                        {price !== 0 ? `${normalizedPriceUSD} ${t('common.currency.usd')}` : '- -'}
                      </Styled.SummaryValue>
                    </Styled.SummaryRow>

                    <Styled.SummaryRow>
                      <Styled.SummaryLabel>{t('common.popup.listing.summary.label.2')}</Styled.SummaryLabel>

                      <Styled.SummaryValue>
                        <ATCIcon size={16} style={{ margin: '0 0.5rem 1px 0' }} />
                        {price !== 0 ? `${normalizedPrice} ${t('common.project.currency.shorthand')}` : '- -'}
                      </Styled.SummaryValue>
                    </Styled.SummaryRow>

                    <Styled.SummaryRow>
                      <Styled.SummaryLabel>
                        <span>{`${t('common.popup.listing.summary.label.3')} (${royaltyFeePercent})`}</span>

                        <Tooltip arrow title={t('common.tooltip.fee.royalty')} placement="top-start">
                          <span className="material-icons-outlined">info</span>
                        </Tooltip>
                      </Styled.SummaryLabel>

                      <Styled.SummaryValue>
                        <ATCIcon size={16} style={{ margin: '0 0.5rem 1px 0' }} />
                        {price !== 0 ? `-${normalizedRoyaltyFee} ${t('common.project.currency.shorthand')}` : '- -'}
                      </Styled.SummaryValue>
                    </Styled.SummaryRow>

                    {/* <Styled.SummaryRow>
                        <Styled.SummaryLabel>
                          <span>{`${t('common.popup.listing.summary.label.4')} (${listingFeePercent})`}</span>

                          <Tooltip
                            arrow
                            title={t('common.tooltip.royalty')}
                            placement="top-start"
                          >
                            <span className="material-icons-outlined">info</span>
                          </Tooltip>
                        </Styled.SummaryLabel>

                        <Styled.SummaryValue>
                          <ATCIcon size={16} style={{ margin: '0 0.5rem 1px 0' }} />
                          {price !== 0 ? `-${normalizedListingFee} ${t('common.project.currency.shorthand')}` : '- -'}
                        </Styled.SummaryValue>
                      </Styled.SummaryRow> */}

                    <Styled.SummaryRow>
                      <Styled.SummaryLabel>
                        <span>{`${t('common.popup.listing.summary.label.6')} (${partnerFeePercent})`}</span>

                        <Tooltip arrow title={t('common.tooltip.fee.partner')} placement="top-start">
                          <span className="material-icons-outlined">info</span>
                        </Tooltip>
                      </Styled.SummaryLabel>

                      <Styled.SummaryValue>
                        <ATCIcon size={16} style={{ margin: '0 0.5rem 1px 0' }} />
                        {price !== 0 ? `-${normalizedPartnerFee} ${t('common.project.currency.shorthand')}` : '- -'}
                      </Styled.SummaryValue>
                    </Styled.SummaryRow>

                    <Styled.HR />

                    <Styled.SummaryRow>
                      <Styled.SummaryLabel>{`${t('common.popup.listing.summary.label.5')}`}</Styled.SummaryLabel>

                      <Styled.SummaryValue>
                        <ATCIcon size={16} style={{ margin: '0 0.5rem 1px 0' }} />
                        {price !== 0 ? `${normalizedEstPayout} ${t('common.project.currency.shorthand')}` : '- -'}
                      </Styled.SummaryValue>
                    </Styled.SummaryRow>
                  </Styled.SummaryRows>
                </Styled.SummaryContainer>

                <Styled.ReviewWarningContainer>
                  <span className="material-icons-outlined">info</span>
                  <Styled.ReviewWarning>{t('common.popup.listing.step.1.warning.1')}</Styled.ReviewWarning>
                </Styled.ReviewWarningContainer>

                {/* <Styled.ReviewWarningContainer>
                    <span className="material-icons-outlined">celebration</span>
                    <Styled.ReviewWarning>
                      <b>{t('common.popup.listing.step.1.warning.2')}</b>
                      <br />
                      {t('common.term.terms.and.conditions.apply')}
                    </Styled.ReviewWarning>
                  </Styled.ReviewWarningContainer> */}
              </>
            )}

            {/* <Styled.ProgressContainer>
                <CircularProgress color="inherit" size={"1.5rem"} />
              </Styled.ProgressContainer> */}

            {step === 0 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant={'white'}
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.close')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '0 auto 0 auto' : '0',
                  }}
                  onClick={handleClose}
                />

                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.continue')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  disabled={!price || price === 0 || !meetsMinListPrice || !meetsMaxListPrice}
                  onClick={handleSubmitPrice}
                />
              </Styled.ButtonsContainer>
            )}

            {step === 1 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant={'white'}
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.back')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '0 auto 0 auto' : '0',
                  }}
                  onClick={() => setStep(0)}
                  disabled={processing || polling}
                />

                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.list.for.sale')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  processing={processing || polling}
                  disabled={processing || polling || !price || price === 0}
                  onClick={handleConfirmPrice}
                />
              </Styled.ButtonsContainer>
            )}

            {step === 2 && (
              <Styled.ButtonsContainer>
                <ButtonHex
                  hoverEffect
                  variant="gold"
                  width={mobileView ? 175 : 150}
                  height={45}
                  title={t('common.term.confirm')}
                  style={{
                    display: mobileView ? 'block' : 'inline-block',
                    margin: mobileView ? '1rem auto 0 auto' : '0 0 0 1rem',
                  }}
                  onClick={handleClose}
                />
              </Styled.ButtonsContainer>
            )}
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default ListNFTModal;
