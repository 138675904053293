import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import GuildLeaderboardTable from 'src/components/data-display/leaderboard/GuildLeaderboardTable';
import Styled from 'src/components/pages/leaderboards/components/SectionDetails.styles';
import { getSortedLeaderboardsByCategory } from 'src/redux/slices/leaderboards/leaderboards';
import useLeaderboardsContext from 'src/hooks/useLeaderboardsContext';

const getLeaderboardTableComponent = (selectedCategory: string) => {
  switch (selectedCategory) {
    case 'guilds':
      return GuildLeaderboardTable;
    default:
      return GuildLeaderboardTable;
  }
};

interface BG {
  path: string;
}

const SectionDetails: FC = () => {
  const { selectedCategory } = useLeaderboardsContext();
  const leaderboards = useAppSelector(state => getSortedLeaderboardsByCategory(state, selectedCategory));
  const [inView, setInView] = useState(false);
  const { t } = useTranslation(nameSpace.leaderboards);
  const bgBottom: BG = t('leaderboards.details.bkg.bottom', { returnObjects: true });
  const LeaderboardTable = getLeaderboardTableComponent(selectedCategory);

  return (
    <Styled.Root>
      <Styled.BackgroundImage style={{ backgroundImage: `url(/assets/${bgBottom.path})` }}>
        <Styled.BackgroundImageGradient />
      </Styled.BackgroundImage>

      <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
        <Styled.CenteredContainer className={`inview animated delay-1s ${inView ? 'fadeInUp' : ''}`}>
          <LeaderboardTable data={leaderboards} />
        </Styled.CenteredContainer>
      </Waypoint>
    </Styled.Root>
  );
};

export default SectionDetails;
