/**
 * Do not edit directly
 * Generated on Wed, 01 Jun 2022 13:46:22 GMT
 */

export const SpacingXs = 2;
export const SpacingSm = 4;
export const SpacingBase = 8;
export const SpacingMd = 12;
export const SpacingLg = 16;
export const SpacingXl = 20;
export const Spacing2Xl = 24;
export const Spacing3Xl = 32;
export const Spacing4Xl = 40;
export const Spacing5Xl = 64;
export const Spacing6Xl = 80;
