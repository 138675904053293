import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  display: block;
  width: 100%;
  border-bottom: 1px solid #ffffff22;
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  max-width: 75rem;
  padding: 3rem 2rem 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
`;

export default {
  Root,
  CenteredContainer,
};
