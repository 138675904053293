/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getCurrentProfileCollection } from 'src/api/profile/get-current-profile-collection';
import { getProfileCollection } from 'src/api/profile/get-profile-collection';
import { getCurrentProfilePurchaseHistory } from 'src/api/profile/get-current-profile-purchase-history';
import { getProfilePurchaseHistory } from 'src/api/profile/get-profile-purchase-history';
import { getGameProfile } from 'src/api/game-profile/get-game-profile';
import { clearCollectionItems, setCollectionItems } from 'src/redux/slices/collection-items/collection-items';
import { clearPurchases, setPurchases } from 'src/redux/slices/purchases/purchases';
import Collection from 'src/components/pages/profile/components/Collection';
import Balance from 'src/components/pages/profile/components/Balance';
import Allowlists from 'src/components/pages/profile/components/Allowlists';
import PurchaseHistory from 'src/components/pages/profile/components/PurchaseHistory';
import Settings from 'src/components/pages/profile/components/Settings';
import Styled from 'src/components/pages/profile/components/SectionTabResults.styles';
import { logMain } from 'src/modules/logger/logger';

interface SectionTabResultsProps {
  tab: number;
}

const SectionTabResults: FC<SectionTabResultsProps> = ({ tab }) => {
  const { playerId = '' } = useParams();
  const [inView1, setInView1] = useState(false);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const dispatch = useAppDispatch();
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;

  useEffect(() => {
    // Load Collections
    dispatch(isCurrentProfile ? getCurrentProfileCollection() : getProfileCollection({ urlParams: { playerId } }))
      .then(data => {
        if (data && data instanceof Array && data.length > 0) {
          dispatch(setCollectionItems(data));
        } else if (data && data.items && data.items.length > 0) {
          dispatch(setCollectionItems(data.items));
        } else if (data && data.items && data.items.length === 0) {
          dispatch(clearCollectionItems());
        }
      })
      .catch(() => {
        dispatch(clearCollectionItems());
      });

    // Load Purchase History
    dispatch(
      isCurrentProfile ? getCurrentProfilePurchaseHistory() : getProfilePurchaseHistory({ urlParams: { playerId } }),
    )
      .then(data => {
        if (data.purchases && data.purchases.length > 0) {
          dispatch(setPurchases(data.purchases));
        } else if (data.purchases && data.purchases.length === 0) {
          dispatch(clearPurchases());
        }
      })
      .catch(() => {
        dispatch(clearPurchases());
      });

    // Load Player State
    if (isCurrentProfile) {
      dispatch(getGameProfile()).catch((e: any) => {
        logMain.debug('Failed to load game profile', e);
      });
    }
  }, [currentProfile.id, dispatch, isCurrentProfile, playerId]);

  return (
    <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated delay-2 ${inView1 ? 'fadeInUp' : ''}`}>
        <Styled.CenteredContainer>
          {isCurrentProfile ? (
            <>
              {tab === 0 && <Collection tab={tab} />}
              {tab === 1 && <Collection tab={tab} />}
              {tab === 2 && <Balance tab={tab} />}
              {tab === 3 && <Allowlists tab={tab} />}
              {tab === 4 && <PurchaseHistory tab={tab} />}
              {tab === 5 && <Settings />}
            </>
          ) : (
            <>
              {tab === 0 && <Collection tab={tab} />}
              {tab === 1 && <Collection tab={tab} />}
              {tab === 2 && <PurchaseHistory tab={tab} />}
            </>
          )}
        </Styled.CenteredContainer>
      </Styled.Root>
    </Waypoint>
  );
};

export default SectionTabResults;
