import { request } from 'src/api/request';
import { v4 as uuidv4 } from 'uuid';
import { LocalStorage } from 'src/local-storage/local-storage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const finish = (options: RequestOptions) => (_dispatch: any) => {
  // Check for local device ID (UUID)
  if (!LocalStorage.get('device_id')) {
    LocalStorage.set('device_id', uuidv4());
  }

  const deviceId = LocalStorage.get('device_id');

  const config = {
    method: 'post',
    url: '/api/web/auth/oauth/finish',
    data: {
      provider: 'n3twork',
      state: options?.data?.state,
      code: options?.data?.code,
      deviceId,
    },
  };

  return request(config, { isPublic: true });
};
