import { useContext } from 'react';
import ViewportContext from 'src/context/viewport/Viewport.context';

const useViewportContext = () => {
  const viewportContext = useContext(ViewportContext);

  return viewportContext;
};

export default useViewportContext;
