import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyLoadedProfiles } from 'src/redux/slices/loaded-profiles/helpers/copy-loaded-profiles';

// Define the initial state using that type
const initialState: LoadedProfilesState = {
  loadedProfiles: {},
};

const loadedProfilesSlice = createSlice({
  name: 'loadedProfiles',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLoadedProfiles: {
      reducer(state, action: PayloadAction<LoadedProfilesPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.loadedProfiles = copyLoadedProfiles(action.payload.profiles);
      },
      prepare(profiles: LoadedProfile[]) {
        return {
          payload: {
            profiles,
          },
        };
      },
    },
    clearLoadedProfiles: state => {
      // eslint-disable-next-line no-param-reassign
      state.loadedProfiles = initialState.loadedProfiles;
    },
  },
});

export const { setLoadedProfiles, clearLoadedProfiles } = loadedProfilesSlice.actions;

// Other Selectors
export const getLoadedProfilesMap = (state: RootState): LoadedProfilesKC => state.loadedProfiles.loadedProfiles;
export const getLoadedProfilesArr = (state: RootState): LoadedProfile[] => {
  const { loadedProfiles }: { loadedProfiles: LoadedProfilesKC } = state.loadedProfiles;
  return Object.keys(loadedProfiles).map((key: DropEventId) => loadedProfiles[key]);
};
export const getLoadedProfileByUsernameOrPlayerId = (state: RootState, id: string): LoadedProfile | null => {
  const loadedProfilesArr = getLoadedProfilesArr(state);
  return (
    loadedProfilesArr.find(
      (loadedProfile: LoadedProfile) => loadedProfile.username === id || loadedProfile.id === id,
    ) || null
  );
};

export default loadedProfilesSlice.reducer;
