import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { GTMEvents } from 'src/modules/gtm/events';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { useQuery } from 'src/tools/useQuery';
import { getURLParamValue, urlParams } from 'src/modules/url-params/url-params';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import Box from 'src/components/basics/Box';
import BaseIcon from 'src/components/icons/BaseIcon';
import Image from 'src/components/media/Image';
import SectionTitle from 'src/components/pages/home/components/SectionTitle';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import Styled from 'src/components/pages/home/components/SectionPromo.styles';
import ButtonDiscordJoin from 'src/components/input/ButtonDiscordJoin';

const Promo: FC = () => {
  const params: (string | null)[] = useQuery(urlParams.home);
  const trim = getURLParamValue('trim', urlParams.home, params);
  const { t } = useTranslation(trim === '1' ? nameSpace.homeTrim : nameSpace.home);
  const theme = useTheme();
  const navigateExternal = useAppNavigateExternal();

  return (
    <Styled.PromoSection>
      <Container>
        <Grid className="promo-wrapper" display="flex" flexWrap="wrap">
          <Grid item sm={8} padding="1rem">
            <SectionTitle header={t('home.promo.title')} isTitle padding="0" />

            <Box className="animated delay-2" margin="0">
              <Typography display="flex" variant="h4" color={theme.custom.colors.Yellow}>
                <BaseIcon fill={theme.custom.colors.Yellow} icon={<DiscordSocialIcon />} height="30px" />
                {t('home.promo.content')}
              </Typography>

              <Typography display="flex" variant="h4" color={theme.custom.colors.Yellow}>
                <BaseIcon fill={theme.custom.colors.Yellow} icon={<DiscordSocialIcon />} height="30px" />
                {t('home.promo.content2')}
              </Typography>

              <ButtonDiscordJoin
                variant={'white'}
                onClick={() => {
                  navigateExternal(t('home.hero.discord'), {
                    newWindow: true,
                    pooleData: { pageName: 'Discord', fromContext: 'home_discord_promo' },
                    gtmEvent: () =>
                      GTMEvents.clickBtnDiscord({ eventContext: 'home-discord-promo', eventIsOutboundLink: true }),
                  });
                }}
              />
            </Box>
          </Grid>

          <Grid className="animated delay-3 img-wrapper" item sm={4} padding="0 1rem">
            <Image source={`/assets/${t('home.promo.asset')}`} alt={t('home.promo.title')} />
          </Grid>
        </Grid>
      </Container>
    </Styled.PromoSection>
  );
};

export default Promo;
