import { useContext } from 'react';
import DiscordWidgetContext from 'src/context/discord-widget/DiscordWidget.context';

const useDiscordWidgetContext = () => {
  const discordWidgetContext = useContext(DiscordWidgetContext);

  return discordWidgetContext;
};

export default useDiscordWidgetContext;
