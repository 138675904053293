import { FC, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useGameClientContext from 'src/hooks/useGameClientContext';
import { useDocumentVisibilityChange } from 'src/hooks/useDocumentVisibilityChange';
import { useGameClientListener } from 'src/hooks/useGameClientListener';
import { getGameColors } from 'src/tools/getGameColors';
import FullscreenModal from 'src/components/modals/FullscreenModal';
import GameErrorModal from 'src/components/modals/GameErrorModal';
import LeaveGameModal from 'src/components/modals/LeaveGameModal';
import ListNFTModal from 'src/components/modals/ListNFTModal';
import GameOverlayErrorModal from 'src/components/modals/GameOverlayErrorModal';
import SearchMarketsFailureModal from 'src/components/modals/SearchMarketsFailureModal';
// import FullscreenButton from 'src/components/game/FullscreenButton';
import TheatreModeButton from 'src/components/game/TheatreModeButton';
import ScreenshotButton from 'src/components/game/ScreenshotButton';
// import DPRInput from 'src/components/game/DPRInput';
import Styled from 'src/components/game/GameClient.styles';
import { logMain } from 'src/modules/logger/logger';

const GameClient: FC = () => {
  const {
    iFrameBlobObjectURL,
    gameClientColor,
    unityClientLoadPercentage,
    unityClientLoaded,
    theatreMode,
    setTheatreMode,
    fullscreen,
    setFullscreenModal,
    listNFTModalNonfungibleId,
    setListNFTModalNonfungibleId,
  } = useGameClientContext();

  const randomColors = getGameColors(gameClientColor);

  // Hook to listen for document visibility changes so we can reload browser after 5 mins of inactivity
  useDocumentVisibilityChange();

  // Hook to listen for game client overlay events
  useGameClientListener();

  // On Unity client load, attempt auto fullscreen on mobile devices.
  useEffect(() => {
    logMain.info('Checking for mobile device before attempting auto fullscreen...');

    if (!isMobile && unityClientLoaded) {
      logMain.info('Mobile device - DETECTED');

      // Show Fullscreen Modal to prompt user to enter fullscreen
      // setFullscreenModal(true);
    } else if (isMobile) {
      logMain.info('Mobile device - NOT DETECTED');

      setTheatreMode(true);
    }
  }, [setFullscreenModal, setTheatreMode, unityClientLoaded]);

  return (
    <Styled.Root>
      {/* UTIL BUTTONS */}
      {!theatreMode && !fullscreen && (
        <>
          {/* <DPRInput colors={randomColors} /> */}
          {/* <FullscreenButton colors={randomColors} /> */}
          <TheatreModeButton colors={randomColors} />
          <ScreenshotButton colors={randomColors} />
        </>
      )}

      {/* MODALS */}
      <FullscreenModal />
      <GameErrorModal />
      <LeaveGameModal />
      <ListNFTModal
        nonfungibleId={listNFTModalNonfungibleId}
        open={!!listNFTModalNonfungibleId}
        onClose={async () => setListNFTModalNonfungibleId('')}
      />
      <SearchMarketsFailureModal />
      <GameOverlayErrorModal />

      {!unityClientLoaded && (
        <>
          <Styled.LoadingSplash />

          <Styled.LoadingBarContainer progress={unityClientLoadPercentage} colors={randomColors}>
            <div id="loading-shrink" />
            <div id="loading-grow" />
          </Styled.LoadingBarContainer>
        </>
      )}

      <iframe
        src={iFrameBlobObjectURL}
        width={'100%'}
        height={'100%'}
        // frameBorder={'0'}
        allow="fullscreen;"
        allowFullScreen
        title={'Legendary: Heroes Unchained'}
      />
    </Styled.Root>
  );
};

export default GameClient;
