import { config } from 'src/config/config';

interface ICreateGameIFrameBlob {
  codeUrl: string;
  dataUrl: string;
  frameworkUrl: string;
  loaderUrl: string;
  version: string;
  devicePixelRatio: number;
  marketOverlayEnabled: boolean;
  listNFTModalNonfungibleId: string;
}

const createGameIFrameBlob = ({
  codeUrl,
  dataUrl,
  frameworkUrl,
  loaderUrl,
  version,
  devicePixelRatio,
  marketOverlayEnabled,
  listNFTModalNonfungibleId,
}: ICreateGameIFrameBlob): string => {
  const configIsProd = config.is_prod;
  const configUUID = config.uuid;
  const configEnv = config.env;
  const serverEnvOverride = new URLSearchParams(window.location.search).get('server_env');
  const serverEnv = serverEnvOverride ?? config.server_env;
  const publicUrl =
    process.env.PUBLIC_URL === '/' || !process.env.PUBLIC_URL
      ? `http://localhost:${process.env.PORT || 3000}`
      : process.env.PUBLIC_URL; // Default value for development

  if (!codeUrl || !dataUrl || !frameworkUrl || !loaderUrl || !version) return '';

  const blob = new Blob(
    [
      `
    <!DOCTYPE html>
    <html lang="en-us">
      <head>
        <meta charset="utf-8" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <link rel="apple-touch-icon" href="${publicUrl}/assets/game/AppleIcon.png" />
        <meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1" />
        <title>Legendary: Heroes Unchained</title>
        <link rel="shortcut icon" href="${publicUrl}/favicon.ico" />
        <link rel="stylesheet" href="${publicUrl}/css/game.css" />
        <script>
          const broadcastChannel = new BroadcastChannel("lhu-broadcast-channel-${configEnv}");
          
          window.dispatchReactUnityEvent = (eventName, eventData) => {
            broadcastChannel.postMessage({
              from: "${publicUrl}",
              uuid: "${configUUID}",
              env: "${configEnv}",
              gameEventName: eventName,
              gameEventData: eventData,
            });
          };
          window.captureScreenshot = () => {
            const canvas = document.querySelector("#unity-canvas");
            const now = Date.now();
          
            console.log("[GAME_IFRAME]: Capturing screenshot...");
          
            if (canvas) {
              const dataUrl = canvas.toDataURL("image/png");
              const link = document.createElement("a");
              link.download = "lhu-screenshot-" + now + ".png"; // Set the name of the download file here
              link.href = dataUrl;
              document.body.appendChild(link); // Append link to body to make it work on Firefox
              link.click();
              document.body.removeChild(link);
            }
          };
          window.lhuGlobals = {
            configIsProd: ${configIsProd},
            configUUID: "${configUUID}",
            configEnv: "${configEnv}",
            serverEnv: "${serverEnv}",
            publicUrl: "${publicUrl}",
            devicePixelRatio: ${devicePixelRatio},
            marketOverlayEnabled: ${marketOverlayEnabled},
            listingInProgress: ${!!listNFTModalNonfungibleId},
          };
          window.gameConfig = {
            codeUrl: "${codeUrl}",
            dataUrl: "${dataUrl}",
            frameworkUrl: "${frameworkUrl}",
            loaderUrl: "${loaderUrl}",
            version: "${version}",
          };
          window.webgl = {};
        </script>
        <script src="${loaderUrl}"></script>
        <script src="${publicUrl}/js/game.js"></script>
      </head>
  
      <body>
        <div id="unity-container" class="unity-desktop">
          <canvas id="unity-canvas" width="100%" height="100%"></canvas>

          <div id="unity-warning"></div>
          <div id="login-button"></div>
        </div>
      </body>
    </html>
    `,
    ],
    { type: 'text/html' },
  );

  return URL.createObjectURL(blob);
};
export default createGameIFrameBlob;
