interface Options {
  t: (value: string) => string;
  lpmType: string;
  soldOut: boolean;
  purchaseLimitReached: boolean;
  purchaseCount: number;
  sufficientBalance: boolean;
}

export const getButtonTextFromState = (options: Options) => {
  const { t, lpmType, soldOut, purchaseLimitReached, purchaseCount, sufficientBalance } = options;

  if (soldOut) {
    return t('event.button.soldOut');
  }

  if (purchaseLimitReached) {
    if (purchaseCount > 0) {
      return t('event.button.purchase.limit');
    }

    if (lpmType === 'pack') {
      return t('event.button.mint.now');
    }

    if (lpmType === 'daily') {
      return t('event.button.open');
    }

    return t('event.button.mint.now');
  }

  if (!sufficientBalance) {
    if (lpmType === 'pack') {
      // Uncomment this if you want to use anything other than 'Mint Now' for disabled button text
      // return t('event.hero.button.low.funds');
      return t('event.button.mint.now');
    }

    if (lpmType === 'daily') {
      // Uncomment this if you want to use anything other than 'Mint Now' for disabled button text
      // return t('event.hero.button.low.funds');
      return t('event.button.open');
    }

    // Uncomment this if you want to use anything other than 'Mint Now' for disabled button text
    // return t('event.hero.button.low.funds');
    return t('event.button.mint.now');
  }

  if (lpmType === 'pack') {
    // Uncomment this if you want to use anything other than 'Mint Now' for disabled button text
    // return t('event.hero.button.low.funds');
    return t('event.button.mint.now');
  }

  if (lpmType === 'daily') {
    // Uncomment this if you want to use anything other than 'Mint Now' for disabled button text
    // return t('event.hero.button.low.funds');
    return t('event.button.open');
  }

  return t('event.button.mint.now');
};
