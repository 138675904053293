import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useDiscordAuthentication } from 'src/hooks/useDiscordAuthentication';
import { useAppSelector } from 'src/hooks/useAppSelector';
import BaseIcon from 'src/components/icons/BaseIcon';
import DiscordSocialIcon from 'src/components/icons/DiscordSocialIcon';
import Styled from 'src/components/input/ButtonDiscordLogin.styles';

export interface ButtonDiscordLoginProps {
  fullWidth?: boolean;
  iconHeight?: string;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const ButtonDiscordLogin: FC<ButtonDiscordLoginProps> = ({
  fullWidth = false,
  iconHeight = '20px',
  textStyle = {},
  style = {},
}) => {
  const { linkDiscord } = useDiscordAuthentication({ fromContext: 'header_dropdown_discord_link' });
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const { t } = useTranslation(nameSpace.common);
  const discordLinked = !!currentProfile?.discordUser;

  if (discordLinked) return null;

  return (
    <Styled.Root id="discord-button" fullWidth={fullWidth} style={style} onClick={linkDiscord}>
      <BaseIcon
        cursor="pointer"
        fill="#fff"
        icon={<DiscordSocialIcon />}
        height={iconHeight}
        style={{
          display: 'inline-block',
          height: '20px',
          position: 'absolute',
          top: '50%',
          transform: 'translate(0, -50%)',
        }}
      />

      <span style={textStyle}>{t('common.discord.login')}</span>
    </Styled.Root>
  );
};

export default ButtonDiscordLogin;
