import { copyLoadedProfile } from 'src/redux/slices/loaded-profiles/helpers/copy-loaded-profile';

export const copyLoadedProfiles = (loadedProfiles: LoadedProfile[]): LoadedProfilesKC => {
  const returnedLoadedProfiles: LoadedProfilesKC = {};

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < loadedProfiles.length; i += 1) {
    const loadedProfile: LoadedProfile = loadedProfiles[i];
    const id = loadedProfile.id as LoadedProfileID;

    if (loadedProfile) {
      returnedLoadedProfiles[id] = copyLoadedProfile(loadedProfile);
    }
  }

  return returnedLoadedProfiles;
};
