import { createContext } from 'react';
import WidgetBot from '@widgetbot/react-embed';
import { LocalStorage } from 'src/local-storage/local-storage';

export interface IDiscordWidgetContext {
  onDiscordAPI: (api: WidgetBot['api']) => void;
  discordAPI: WidgetBot['api'] | null;
  welcomeNotificationSent: boolean;
  setWelcomeNotificationSent: (welcomeNotificationSent: boolean) => void;
  hasSetUpListeners: boolean;
  setHasSetUpListeners: (hasSetUpListeners: boolean) => void;
  discordWidgetOpen: boolean;
  setDiscordWidgetOpen: (discordWidgetOpen: boolean) => void;
  discordWidgetAuthenticated: boolean;
  setDiscordWidgetAuthenticated: (discordWidgetAuthenticated: boolean) => void;
  enableWelcomeMessage: boolean;
  setEnableWelcomeMessage: (enableWelcomeMessage: boolean) => void;
  enableNotifications: boolean;
  setEnableNotifications: (enableNotifications: boolean) => void;
  enableIndicators: boolean;
  setEnableIndicators: (enableIndicators: boolean) => void;
  showDiscordWidget: boolean;
  setShowDiscordWidget: (showDiscordWidget: boolean) => void;
}

export const initialContext: IDiscordWidgetContext = {
  onDiscordAPI: () => undefined,
  discordAPI: null,
  welcomeNotificationSent: false,
  setWelcomeNotificationSent: () => undefined,
  hasSetUpListeners: false,
  setHasSetUpListeners: () => undefined,
  discordWidgetOpen: false,
  setDiscordWidgetOpen: () => undefined,
  discordWidgetAuthenticated: false,
  setDiscordWidgetAuthenticated: () => undefined,
  enableWelcomeMessage: LocalStorage.get('discord_widget_enable_welcome_message') !== 'false',
  setEnableWelcomeMessage: () => undefined,
  enableNotifications: LocalStorage.get('discord_widget_enable_notifications') !== 'false',
  setEnableNotifications: () => undefined,
  enableIndicators: LocalStorage.get('discord_widget_enable_indicators') !== 'false',
  setEnableIndicators: () => undefined,
  showDiscordWidget: LocalStorage.get('discord_widget_show_widget') !== 'false',
  setShowDiscordWidget: () => undefined,
};

const DiscordWidgetContext = createContext<IDiscordWidgetContext>(initialContext);

DiscordWidgetContext.displayName = 'DiscordWidgetContext';

export default DiscordWidgetContext;
