/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateExternal } from 'src/hooks/useAppNavigateExternal';
import { getPurchaseStatusMeta } from 'src/components/pages/profile/utils/get-purchase-status-meta';
import { getTransactionImage } from 'src/tools/getTransactionImage';
import { formatPrice } from 'src/tools/formatPrice';
import ATCIcon from 'src/components/icons/ATCIcon';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { config } from 'src/config/config';
import { FontFamiliesBody } from 'src/styles/theme/tokens/gen/fontFamilies';
import Styled from 'src/components/pages/profile/components/PurchaseHistory.styles';

const StyledTableCell = styled(TableCell)(() => ({
  fontFamily: FontFamiliesBody,
  verticalAlign: 'top',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    // textTransform: 'uppercase',
    fontSize: '0.9rem',
    lineHeight: '1.1rem',
    color: `#ffffffcc`,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#000',
  borderColor: '#ffffff77',
  // hide last border
  '& td, th': {
    padding: '1rem 0.8rem',
    boxSizing: 'border-box',
  },
}));

interface Title {
  default: string;
  empty: string;
}

interface PurchaseHistoryProps {
  tab: number;
}

const PurchaseHistory: FC<PurchaseHistoryProps> = ({ tab }) => {
  const { playerId = '' } = useParams();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const purchases = useAppSelector(({ purchases }) => purchases.purchases);
  const navigateExternal = useAppNavigateExternal();
  const { t: tProfile } = useTranslation(nameSpace.profile);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const titles: Title[] = tProfile(
    isCurrentProfile ? 'profile.tab.results.titles' : 'profile.tab.results.titles.other.user',
    { returnObjects: true },
  );
  const cols: string[] = tProfile('profile.purchase.history.columns', { returnObjects: true });

  return (
    <Styled.Root>
      <Styled.Title>{purchases.length > 0 ? titles[tab].default : titles[tab].empty}</Styled.Title>

      {purchases.length > 0 && (
        <Styled.ResultsContainer>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  {cols.map((col, i) => (
                    <StyledTableCell key={`profile-purchases-col-header-${i}}`} align={i <= 1 ? 'left' : 'right'}>
                      {col}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {purchases.map(purchase => {
                  const { key, status, price, timestampMs, nonfungible, sellerPlayerId, redirectUri } = purchase;
                  const { serialNumber, maxSerialNumber, seriesName, imageUrl } = nonfungible || {};
                  const royaltyFee = Math.round(price * config.app_variables.royalty_fee);
                  const listingFee = Math.round(price * config.app_variables.listing_fee);
                  const partnerFee = Math.round(price * config.app_variables.partner_fee);
                  const estPayout = formatPrice(price - royaltyFee - listingFee - partnerFee, {
                    decimals: 0,
                    hideSymbol: true,
                  });
                  const normalizedPrice = formatPrice(price, { decimals: 0, hideSymbol: true });
                  const priceUSD = formatPrice(price * config.app_variables.lc_conversion);
                  const date = formatInTimeZone(new Date(timestampMs), 'America/New_York', 'dd LLL yyyy');

                  return (
                    <StyledTableRow key={`profile-purchases-row-${key}`}>
                      <StyledTableCell component="th" scope="row">
                        <Styled.ItemContainer>
                          <Styled.ItemImg
                            src={getTransactionImage(imageUrl || '')}
                            width={100}
                            height={100}
                            loading="lazy"
                            alt={tCommon('common.transaction.image.alt')}
                          />

                          <Styled.ItemDetailsContainer>
                            <Styled.Primary style={{ textTransform: 'uppercase', fontWeight: '700' }}>
                              {seriesName}
                            </Styled.Primary>
                            <Styled.Secondary>{`#${serialNumber} of ${maxSerialNumber}`}</Styled.Secondary>
                          </Styled.ItemDetailsContainer>
                        </Styled.ItemContainer>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <Styled.Status color={getPurchaseStatusMeta(status).color}>
                          {`${getPurchaseStatusMeta(status).txt}`}
                        </Styled.Status>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <Styled.Type color={sellerPlayerId === currentProfile.id ? '#04ae81' : '#ef476f'}>
                          {sellerPlayerId === currentProfile.id
                            ? tCommon('common.term.sale')
                            : tCommon('common.term.purchase')}
                        </Styled.Type>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <Styled.Primary>
                          <ATCIcon size={16} style={{ margin: '0 .4rem 2px 0' }} />
                          {`${normalizedPrice} ${tCommon('common.project.currency.shorthand')}`}
                        </Styled.Primary>
                        <Styled.Secondary>{`${priceUSD} ${tCommon('common.currency.usd')}`}</Styled.Secondary>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <Styled.Primary>
                          {sellerPlayerId && <ATCIcon size={16} style={{ margin: '0 .4rem 2px 0' }} />}
                          {sellerPlayerId ? `${estPayout} ${tCommon('common.project.currency.shorthand')}` : '- -'}
                        </Styled.Primary>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        <Styled.Date>{date}</Styled.Date>
                      </StyledTableCell>

                      <StyledTableCell align="right">
                        {redirectUri && getPurchaseStatusMeta(status).pending ? (
                          <Styled.Action
                            onClick={() => {
                              navigateExternal(redirectUri || '', {
                                newWindow: false,
                                pooleData: { pageName: 'Forte Pay', fromContext: 'profile_purchase_history' },
                              });
                            }}
                          >
                            <span className="material-icons">link</span>
                            <span>{tCommon('common.term.complete.purchase')}</span>
                          </Styled.Action>
                        ) : (
                          <Styled.NoAction>{'- -'}</Styled.NoAction>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Styled.ResultsContainer>
      )}
    </Styled.Root>
  );
};

export default PurchaseHistory;
