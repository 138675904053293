import { createContext } from 'react';

export interface IDebugWidgetContext {
  enableDebugWidget: boolean;
  setEnableDebugWidget: (enable: boolean) => void;
  debugWidgetOpen: boolean;
  setDebugWidgetOpen: (open: boolean) => void;
}

export const initialContext: IDebugWidgetContext = {
  enableDebugWidget: false,
  setEnableDebugWidget: () => undefined,
  debugWidgetOpen: false,
  setDebugWidgetOpen: () => undefined,
};

const DebugWidgetContext = createContext<IDebugWidgetContext>(initialContext);

DebugWidgetContext.displayName = 'DebugWidgetContext';

export default DebugWidgetContext;
