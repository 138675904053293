import { useContext } from 'react';
import LeaderboardsContext from 'src/context/leaderboards/Leaderboards.context';

const useLeaderboardsContext = () => {
  const leaderboardsContext = useContext(LeaderboardsContext);

  return leaderboardsContext;
};

export default useLeaderboardsContext;
