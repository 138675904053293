import { FC, useRef } from 'react';
import { useElementDimensions } from 'src/hooks/useElementDimensions';
import NFTPreview from 'src/components/collectibles/NFTPreview';
import Styled from 'src/components/collectibles/Collectibles.styles';

interface CollectibleStyles {
  width: string;
  margin: string;
  noNthHorizMargin: number;
}

const getCollectibleStyles = (width: number, condensed: boolean): CollectibleStyles => {
  if (condensed) {
    if (width <= 300) return { width: '100%', margin: '0 0 1rem 0', noNthHorizMargin: 0 };
    if (width <= 450) return { width: '48%', margin: '0 4% 1rem 0', noNthHorizMargin: 2 };
    if (width <= 600) return { width: '31%', margin: '0 3.5% 1rem 0', noNthHorizMargin: 3 };
    if (width <= 850) return { width: '22.9%', margin: '0 2.8% 1rem 0', noNthHorizMargin: 4 };
    if (width <= 1200) return { width: '18.4%', margin: '0 2% 1rem 0', noNthHorizMargin: 5 };

    return { width: '15.8%', margin: '0 1.04% 1.5rem 0', noNthHorizMargin: 6 };
  }

  if (width <= 300) return { width: '100%', margin: '0 0 1rem 0', noNthHorizMargin: 0 };
  if (width <= 600) return { width: '48%', margin: '0 4% 1rem 0', noNthHorizMargin: 2 };
  if (width <= 768) return { width: '30%', margin: '0 5% 1rem 0', noNthHorizMargin: 3 };
  if (width <= 1100) return { width: '22%', margin: '0 4% 1.5rem 0', noNthHorizMargin: 4 };
  if (width <= 1500) return { width: '18%', margin: '0 2.5% 1.5rem 0', noNthHorizMargin: 5 };

  return { width: '15%', margin: '0 2% 1.5rem 0', noNthHorizMargin: 6 };
};

interface CollectiblesProps {
  collectibles: CollectionItem[] | Listing[];
  condensed?: boolean;
  style?: React.CSSProperties;
}

const Collectibles: FC<CollectiblesProps> = ({ collectibles, condensed = false, style = {} }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const { width } = useElementDimensions({ element: rootRef });
  const collectibleStyles = getCollectibleStyles(width, condensed);

  const renderCollectibles = (collectibles: CollectionItem[] | Listing[]) => {
    let starterPackFound = false;

    return collectibles.map(collectible => {
      const firstStarterPack = collectible?.packTier === 'starter' && !starterPackFound;

      if (firstStarterPack) {
        starterPackFound = true;
      }

      return (
        <Styled.Item
          key={`collectible-${collectible.id}`}
          width={collectibleStyles.width}
          margin={collectibleStyles.margin}
          noNthHorizMargin={collectibleStyles.noNthHorizMargin}
        >
          <NFTPreview
            nft={collectible}
            // position={i}
            firstStarterPack={firstStarterPack}
          />
        </Styled.Item>
      );
    });
  };

  if (collectibles.length === 0) return null;

  return (
    <Styled.Root ref={rootRef} style={style}>
      {renderCollectibles(collectibles)}
    </Styled.Root>
  );
};

export default Collectibles;
