import TagManager from 'react-gtm-module';
import { config } from 'src/config/config';

const GLOBALS = {
  browserSessionId: config.uuid,
};

const clickBtnRoadmap = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'roadmap',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnWhitepaper = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'whitepaper',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnFAQs = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'faqs',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnDiscord = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'discord',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnTwitter = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'twitter',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnYoutube = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'youtube',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnInstagram = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'instagram',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};
const clickBtnTikTok = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'tiktok',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnFacebook = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'facebook',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnPolkastarter = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'polkastarter',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnEmail = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'email',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const clickBtnPlayTrailer = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'click-btn',
      eventSubject: 'play-trailer',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const formSubmitEmailReg = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'form-submit',
      eventSubject: 'email-registration',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const authStart = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'auth-start',
      eventSubject: 'auth-start',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const authStartFail = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'auth-start',
      eventSubject: 'auth-start-fail',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const authStartSuccess = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'auth-start',
      eventSubject: 'auth-start-success',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const authFinish = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'auth-finish',
      eventSubject: 'auth-finish',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const authFinishFail = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'auth-finish',
      eventSubject: 'auth-finish-fail',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const authFinishSuccessSignup = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'auth-finish',
      eventSubject: 'auth-finish-success-signup',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const authFinishSuccessLogin = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'auth-finish',
      eventSubject: 'auth-finish-success-login',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const loadGameConfig = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'load-game-config',
      eventSubject: 'load-game-config',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const loadGameConfigFail = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'load-game-config',
      eventSubject: 'load-game-config-fail',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const loadGameConfigSuccess = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'load-game-config',
      eventSubject: 'load-game-config-success',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

const loadGameClient = (options?: EventOptions) => {
  TagManager.dataLayer({
    dataLayer: {
      ...GLOBALS,
      event: 'load-game-client',
      eventSubject: 'load-game-client',
      userId: options?.userId || null,
      eventContext: options?.eventContext || null,
      eventIsOutboundLink: options?.eventIsOutboundLink ?? false,
    },
  });
};

export const GTMEvents = {
  clickBtnRoadmap,
  clickBtnWhitepaper,
  clickBtnFAQs,
  clickBtnDiscord,
  clickBtnTwitter,
  clickBtnYoutube,
  clickBtnInstagram,
  clickBtnTikTok,
  clickBtnFacebook,
  clickBtnPolkastarter,
  clickBtnEmail,
  clickBtnPlayTrailer,
  formSubmitEmailReg,
  authStart,
  authStartFail,
  authStartSuccess,
  authFinish,
  authFinishFail,
  authFinishSuccessSignup,
  authFinishSuccessLogin,
  loadGameConfig,
  loadGameConfigFail,
  loadGameConfigSuccess,
  loadGameClient,
};
