import { styled } from '@mui/material';
import BaseIcon from 'src/components/icons/BaseIcon';

const SocialSection = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.White};
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  box-sizing: border-box;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 1rem 1rem;
  }
`;

const SocialSectionInner = styled('div')`
  display: block;
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  font-size: 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    /* padding: 0 2.5rem; */
  }
`;

const ArrowIcon = styled(BaseIcon)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  &:nth-of-type(1) {
    left: 0;
  }

  &:nth-of-type(2) {
    left: 14px;
  }

  &:nth-of-type(3) {
    transform: translate(0, -50%) scale(-1);
    right: 14px;
  }

  &:nth-of-type(4) {
    transform: translate(0, -50%) scale(-1);
    right: 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 40px;
    top: 3px;

    &:nth-of-type(1) {
      transform: translate(0, 0);
    }

    &:nth-of-type(2) {
      left: 11px;
      transform: translate(0, 0);
    }

    &:nth-of-type(3) {
      right: 11px;
      transform: translate(0, 0) scale(-1);
    }

    &:nth-of-type(4) {
      transform: translate(0, 0) scale(-1);
    }
  }
`;

const SocialText = styled('p')`
  display: inline-block;
  width: 50%;
  padding-left: 4rem;
  vertical-align: middle;
  text-align: left;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.custom.colors.TextDefault};
  font-weight: ${({ theme }) => theme.custom.typography.BodyMdStrong.fontWeight};

  & > span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: block;
    width: 100%;
    padding: 0 4rem;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 0 2.5rem;
  }
`;

const SocialIcons = styled('div')`
  display: inline-block;
  width: 50%;
  padding-right: 3rem;
  vertical-align: middle;
  text-align: center;

  & > div {
    display: inline-block;
    padding: 0 0.5rem;
    vertical-align: middle;
  }

  & > img {
    display: inline-block;
    width: 2.4rem;
    height: auto;
    padding: 0 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: block;
    width: 100%;
    padding-right: 0;
    max-width: 19rem;
    margin: 0.5rem auto 0 auto;
  }
`;

export default {
  SocialSection,
  SocialSectionInner,
  ArrowIcon,
  SocialText,
  SocialIcons,
};
