import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPendingPurchases = () => (_dispatch: any) => {
  const config = {
    method: 'get',
    url: `/api/web/secondary-market/purchases`,
  };

  return request(config);
};
