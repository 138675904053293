import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { useMediaQuery, useTheme } from '@mui/material';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Styled from 'src/components/pages/leaderboard/components/Select.styles';
// import { logMain } from 'src/modules/logger/logger';

const Select: FC = () => {
  const { leaderboardId = '' } = useParams();
  const anchorEl = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const leaderboards = useAppSelector(({ leaderboards }) => leaderboards.leaderboards);
  const selectedLeaderboard = leaderboards.find(leaderboard => leaderboard.configId === leaderboardId);
  const leaderboardCategory = selectedLeaderboard?.category.displayName;
  const leaderboardsInCategory = leaderboards.filter(
    leaderboard => leaderboard.category.displayName === leaderboardCategory,
  );
  const navigateInternal = useAppNavigateInternal();
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down(501));

  const handleToggle = () => {
    if (anchorEl.current) {
      setOpen(prev => !prev);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (leaderboardId: string) => {
    navigateInternal(`/leaderboards/${leaderboardId}`, { pooleData: { fromContext: 'leaderboard' } });
    setOpen(false);
  };

  return (
    <Styled.Root>
      <Styled.DropdownButton ref={anchorEl} onClick={handleToggle}>
        <Styled.Value>{selectedLeaderboard?.displayName}</Styled.Value>

        {open ? (
          <span className="material-icons-outlined">expand_less</span>
        ) : (
          <span className="material-icons-outlined">expand_more</span>
        )}
      </Styled.DropdownButton>

      <Popper
        open={open}
        anchorEl={anchorEl.current}
        placement="bottom-end"
        transition
        sx={mobileBreakpoint ? { minWidth: 'calc(100% - 3rem)' } : {}}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} timeout={350}>
              <Styled.MenuContainer>
                {leaderboardsInCategory.map((leaderboard, i) => {
                  const { configId, displayName } = leaderboard;

                  return (
                    <Styled.MenuItem
                      key={`leaderboard-${i}`}
                      selected={configId === leaderboardId}
                      onClick={() => handleSelect(configId)}
                    >
                      {displayName}
                    </Styled.MenuItem>
                  );
                })}
              </Styled.MenuContainer>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Styled.Root>
  );
};

export default Select;
