import { FC } from 'react';
import GameOverlay from 'src/components/game-overlay/GameOverlay';
import Styled from 'src/components/pages/play/Play.styles';
import DeviceOrientationOverlay from 'src/components/device-orientation-overlay/DeviceOrientationOverlay';

const Play: FC = () => {
  return (
    <Styled.Root>
      <GameOverlay />

      <DeviceOrientationOverlay />
    </Styled.Root>
  );
};

export default Play;
