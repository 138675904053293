import { styled } from '@mui/material';

const AccountButton = styled('div')`
  display: inline-block;
  margin-right: 0;
  vertical-align: middle;

  & > button {
    & .material-icons {
      font-size: 1.8rem;
      transition: color 0.4s ease-in-out;
    }
  }

  & > button:hover {
    & .material-icons {
      color: ${({ theme }) => theme.custom.colors.Yellow};
    }
  }
`;

export default {
  AccountButton,
};
