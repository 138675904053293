interface Colors {
  gradientColor1: string;
  gradientColor2: string;
}

export const getGameColors = (color: number): Colors => {
  const colors = {
    gradientColor1: '#000000',
    gradientColor2: '#333333',
  };

  switch (color) {
    // Purple
    case 0:
      colors.gradientColor1 = '#375195';
      colors.gradientColor2 = '#412E6B';
      break;
    // Green
    case 1:
      colors.gradientColor1 = '#399537';
      colors.gradientColor2 = '#336B2E';
      break;
    // Orange
    case 2:
      colors.gradientColor1 = '#C98622';
      colors.gradientColor2 = '#6B352E';
      break;
    // Red
    case 3:
      colors.gradientColor1 = '#953737';
      colors.gradientColor2 = '#6B442E';
      break;
    // Blue
    case 4:
      colors.gradientColor1 = '#22ABC9';
      colors.gradientColor2 = '#2E3B6B';
      break;
    default:
      break;
  }

  return colors;
};
