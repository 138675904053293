import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  display: inline-flex;
  height: 2.3rem;
  width: 2.3rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: ${customThemeOptions.custom.zIndex.gameOverlay.closeButton};
  /* transform: translate(0, -50%); */

  @media (max-width: 1000px) {
    height: 2rem;
    width: 2rem;
  }
`;

interface ButtonProps {
  active: boolean;
}

const Button = styled('div')<ButtonProps>`
  background: ${({ active }) =>
    active ? 'linear-gradient(0deg, #3E5B94, #263E6B)' : 'linear-gradient(0deg, #2F4776, #1A2A49)'};
  display: inline-flex;
  height: calc(100% - 0.8rem);
  width: calc(100% - 0.8rem);
  padding: 0 0.5rem;
  justify-content: center;
  align-items: center;
  border: ${({ active }) => (active ? ' 2px solid #437cbe' : ' 2px solid #2f4776')};
  box-sizing: border-box;
  text-align: center;
  position: relative;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, #3e5b94, #263e6b);
    border: 2px solid #437cbe;
  }

  &:active {
    background: linear-gradient(0deg, #2f4776, #1a2a49);
    border: 2px solid #1d4676;
  }

  & .material-icons,
  & .material-icons-outlined {
    display: inline-block;
    margin-top: 1px;
    /* margin-right: 0.2rem; */
    font-size: 1.1rem;
    font-weight: 700;
    color: #ffffff;
  }

  @media (max-width: 1000px) {
    & .material-icons,
    & .material-icons-outlined {
      font-size: 0.9rem;
    }
  }
`;

const ButtonText = styled('div')`
  display: inline-block;
  margin-top: 3px;
  vertical-align: middle;
  background: -webkit-linear-gradient(#cccccc, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 0.9rem;
  color: #ffffff;

  @media (max-width: 1000px) {
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
`;

export default { Root, Button, ButtonText };
