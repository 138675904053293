import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const purchaseListing = (options: RequestOptions) => (_dispatch: any) => {
  const { data } = options;

  const config = {
    method: 'post',
    url: `/api/web/secondary-market/purchase-listing`,
    data: {
      listingId: data?.listingId,
    },
  };

  return request(config);
};
