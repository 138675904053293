import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
`;

interface InlineLogoProps {
  color?: string;
  variantStyles: {
    size: string;
    margin: string;
  };
}

const InlineLogo = styled('div')<InlineLogoProps>`
  background-color: #ffffff11;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  height: ${({ variantStyles }) => variantStyles.size};
  width: ${({ variantStyles }) => variantStyles.size};
  margin: ${({ variantStyles }) => variantStyles.margin};
  vertical-align: middle;
  border-radius: 50%;
  /* filter: ${({ color }) => `drop-shadow(0 0 40px ${color}55)`}; */
`;

interface InlineTextProps {
  color?: string;
  variantStyles: {
    size: string;
    margin: string;
    fontSize: string;
  };
}

const InlineText = styled('span')<InlineTextProps>`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-transform: uppercase;
  font-style: italic;
  font-size: ${({ variantStyles }) => variantStyles.fontSize};
  font-weight: 700;
  line-height: ${({ variantStyles }) => variantStyles.fontSize};
  color: ${({ color }) => color || '#ffffff'};
  /* filter: ${({ color }) => `drop-shadow(0 0 40px ${color}55) brightness(1)`}; */
  /* filter: brightness(1.5); */
`;

export default {
  Root,
  InlineLogo,
  InlineText,
};
