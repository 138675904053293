import { FC } from 'react';
import useWebMetricsContext from 'src/hooks/useWebMetricsContext';
import useBroadcastChannelContext from 'src/hooks/useBroadcastChannelContext';
import useDebugWidgetContext from 'src/hooks/useDebugWidgetContext';
import useDiscordWidgetContext from 'src/hooks/useDiscordWidgetContext';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { clearBrowserSessionState } from 'src/api/auth/logout';
import Styled from 'src/components/debug-widget/DebugMenu.styles';

const discordNotifications = [
  'Why did the computer break up with the internet? Too many bad connections. 😂',
  "Just dropped my new laptop and now it's doing the robot dance better than I ever could. 💻🕺",
  "If I was an HTTP status code, I'd be 404 because I have no idea what's going on.",
  "I'd tell you a UDP joke but you might not get it... and I wouldn't get your response. 🤷‍♂️",
  "Remember, if you can't laugh at yourself, I'm available to do it for you. 😌",
  "I'm not saying I'm a pro at procrastination, but I am thinking about writing a book on it someday.",
  'Life update: Currently holding a meeting with my pets to discuss why the food bowl is only filled twice a day. 🐾',
  'Trying to be mysterious by typing in Braille. .. . . . .. .. . . . .. ... nobody gets me.',
  'Why do programmers prefer dark mode? Because light attracts bugs. 🐛💡',
  "I just cleaned my keyboard and now I'm a faster typer by 0.00001%. Look at me go! ⌨️💨",
  "Decided to become a vegetarian because I heard it's a great way to save money on my Discord Nitro subscription.",
];

const DebugMenu: FC = () => {
  const { enableWebMetrics, setEnableWebMetrics } = useWebMetricsContext();
  const { sendBroadcastMessage } = useBroadcastChannelContext();
  const { showDiscordWidget } = useDiscordWidgetContext();
  const { debugWidgetOpen } = useDebugWidgetContext();
  const {
    enableGameOverlay,
    openGameOverlay,
    closeGameOverlay,
    enableGameOverlayContents,
    setEnableGameOverlayContents,
  } = useGameOverlayContext();
  const {
    showGamePage,
    isClearingUnityClient,
    disableGameClientByDebug,
    setDisableGameClientByDebug,
    isDisablingGameClient,
    isGameClientDisabledByDebug,
    setIsGameClientDisabledByDebug,
  } = useGameClientContext();
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const discordCrate = window?.discordWidgetGlobals?.crate || null;
  const navigateInternal = useAppNavigateInternal();

  const resetAuthenticationState = () => {
    clearBrowserSessionState({ isLocalLogout: true });
    navigateInternal('/home', { pooleData: { fromContext: 'debug_clear_auth' } });
  };

  if (!debugWidgetOpen) return null;

  return (
    <Styled.Root showDiscordWidget={showDiscordWidget}>
      <Styled.DebugTitle>Debug Menu</Styled.DebugTitle>

      <Styled.DebugActionButton
        type="button"
        disabled={!showGamePage}
        onClick={() => {
          if (enableGameOverlay) {
            closeGameOverlay();
          } else {
            openGameOverlay({
              page: 'primary-marketplace',
              params: {
                dropEventId: null,
                listPriceMarketId: null,
              },
            });
          }
        }}
      >
        <span className="material-icons">label</span>
        <span>{`${enableGameOverlay ? 'Close' : 'Open'} Game Overlay`}</span>
      </Styled.DebugActionButton>

      <Styled.DebugActionButton
        type="button"
        disabled={isClearingUnityClient || isDisablingGameClient}
        onClick={() => {
          if (isGameClientDisabledByDebug) {
            setIsGameClientDisabledByDebug(false);
          } else {
            setDisableGameClientByDebug(!disableGameClientByDebug);
          }
        }}
      >
        <span className="material-icons">label</span>
        <span>{`${isGameClientDisabledByDebug ? 'Enable' : 'Disable'} Game Client`}</span>
      </Styled.DebugActionButton>

      <Styled.DebugActionButton
        type="button"
        disabled={false}
        onClick={() =>
          discordCrate.notify({
            content: `${currentProfile?.discordUser?.username || 'Legendary: Heroes Unchained'}\n${
              discordNotifications[Math.floor(Math.random() * discordNotifications.length)]
            }`,
            timeout: 5000,
            avatar: currentProfile?.discordUser?.avatarUrl || '/apple-touch-icon-192.png',
          })
        }
      >
        <span className="material-icons">label</span>
        <span>Send Discord Notification (Local)</span>
      </Styled.DebugActionButton>

      <Styled.DebugActionButton
        type="button"
        disabled={false}
        onClick={() => sendBroadcastMessage({ unityClientInitializing: true })}
      >
        <span className="material-icons">label</span>
        <span>Broadcast - Game Initialized (Same-Origin)</span>
      </Styled.DebugActionButton>

      <Styled.DebugActionButton type="button" disabled={false} onClick={() => setEnableWebMetrics(!enableWebMetrics)}>
        <span className="material-icons">label</span>
        <span>{`${!enableWebMetrics ? 'Enable' : 'Disable'} Web Metrics`}</span>
      </Styled.DebugActionButton>

      <Styled.DebugActionButton
        type="button"
        disabled={false}
        onClick={() => setEnableGameOverlayContents(!enableGameOverlayContents)}
      >
        <span className="material-icons">label</span>
        <span>{`${!enableGameOverlayContents ? 'Enable' : 'Disable'} Game Overlay Contents`}</span>
      </Styled.DebugActionButton>

      <Styled.DebugActionButton type="button" disabled={false} onClick={resetAuthenticationState}>
        <span className="material-icons">label</span>
        <span>Clear Authentication State</span>
      </Styled.DebugActionButton>
    </Styled.Root>
  );
};

export default DebugMenu;
