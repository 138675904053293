import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useGameClientContext from 'src/hooks/useGameClientContext';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/modals/SearchMarketsFailureModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface SearchMarketsFailureModalProps {
  style?: React.CSSProperties;
}

const SearchMarketsFailureModal: FC<SearchMarketsFailureModalProps> = ({ style = {} }) => {
  const { searchMarketsFailureModal, setSearchMarketsFailureModal } = useGameClientContext();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tPlay } = useTranslation(nameSpace.play);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    setSearchMarketsFailureModal(false);
  };

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={searchMarketsFailureModal}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.gameError}` }}
    >
      <Fade in={searchMarketsFailureModal}>
        <Styled.Root style={style}>
          <PrimaryModalBackground />

          <Styled.ContentContainer>
            <Styled.Heading>{tPlay('play.popup.search.market.failure.title')}</Styled.Heading>

            <Styled.SubheadingsContainer>
              <Styled.Subheading>{tPlay('play.popup.search.market.failure.subheading')}</Styled.Subheading>
            </Styled.SubheadingsContainer>

            <Styled.ButtonsContainer>
              <ButtonHex
                hoverEffect
                variant={'white'}
                width={mobileView ? 175 : 150}
                height={45}
                title={tCommon('common.term.close')}
                style={{
                  display: mobileView ? 'block' : 'inline-block',
                  margin: mobileView ? '0 auto 0 auto' : '0',
                }}
                onClick={handleClose}
              />
            </Styled.ButtonsContainer>
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default SearchMarketsFailureModal;
