import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getRewardCount } from 'src/tools/getRewardCount';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { getPackTierFromType } from 'src/tools/getPackTierFromType';
import Styled from 'src/components/primary-marketplace/components/RewardCount.styles';

interface RewardCountProps {
  dropEventId: string;
  lpmId: string;
}

const RewardCount: FC<RewardCountProps> = ({ dropEventId, lpmId }) => {
  const dropEvent = useAppSelector(state => state.dropEvents.dropEvents)[dropEventId];
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const { t: tEvent } = useTranslation(nameSpace.event);

  if (!dropEvent || !listPriceMarket) return null;

  const { type: dropEventType } = dropEvent;
  const { type: lpmType, rarities } = listPriceMarket;
  const packTier = getPackTierFromType(lpmType);
  const rewardCount = getRewardCount(rarities, packTier);

  if (!rewardCount) return null;
  if (dropEventType === 'hero' || dropEventType === 'daily') return null;

  return (
    <Styled.Root>
      <span>{rewardCount}</span>

      {dropEventType === 'pack' && (
        <span>{rewardCount > 1 ? tEvent('event.pack.reward.quantities') : tEvent('event.pack.reward.quantity')}</span>
      )}

      {/* {dropEventType === 'hero' && (
        <span>{rewardCount > 1 ? tEvent('"event.hero.reward.quantities"') : tEvent('"event.hero.reward.quantity')}</span>
      )} */}

      {/* {dropEventType === 'daily' && (
        <span>{rewardCount > 1 ? tEvent('event.chest.reward.quantities') : tEvent('event.chest.reward.quantity')}</span>
      )} */}
    </Styled.Root>
  );
};

export default RewardCount;
