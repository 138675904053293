import { copyDropEvent } from 'src/redux/slices/drop-events/helpers/copy-drop-event';

export const copyDropEvents = (dropEvents: DropEvent[]): DropEventsKC => {
  const returnedDropEvents: DropEventsKC = {};

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < dropEvents.length; i += 1) {
    const dropEvent: DropEvent = dropEvents[i];
    const id = dropEvent.id as DropEventId;

    if (dropEvent) {
      returnedDropEvents[id] = copyDropEvent(dropEvent);
    }
  }

  return returnedDropEvents;
};
