import { createContext } from 'react';

type CursorType = 'default' | 'pointer' | 'loading';

export interface ICursorContext {
  cursorType: CursorType;
  setCursorType: (cursorType: CursorType) => void;
}

export const initialContext: ICursorContext = {
  cursorType: 'default',
  setCursorType: () => undefined,
};

const CursorContext = createContext<ICursorContext>(initialContext);

CursorContext.displayName = 'CursorContext';

export default CursorContext;
