/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { config } from 'src/config/config';
import DebugWidgetContext, { initialContext, IDebugWidgetContext } from 'src/context/debug-widget/DebugWidget.context';
import { logMain } from 'src/modules/logger/logger';

interface DebugWidgetProviderProps {
  children: ReactNode;
}

const DebugWidgetProvider: FC<DebugWidgetProviderProps> = ({ children }) => {
  const [enableDebugWidget, setEnableDebugWidget] = useState<IDebugWidgetContext['enableDebugWidget']>(
    initialContext.enableDebugWidget,
  );
  const [debugWidgetOpen, setDebugWidgetOpen] = useState<IDebugWidgetContext['debugWidgetOpen']>(
    initialContext.debugWidgetOpen,
  );

  useEffect(() => {
    const handleTilde = (event: KeyboardEvent) => {
      if (event.key === '~') {
        if (enableDebugWidget) {
          logMain.debug(`%c[DEBUG WIDGET]: Debug widget closed`, 'color: magenta;');

          setEnableDebugWidget(false);
          setDebugWidgetOpen(false);
        } else {
          logMain.debug(`%c[DEBUG WIDGET]: Debug widget opened`, 'color: magenta;');

          setDebugWidgetOpen(false);
          setEnableDebugWidget(true);
        }
      }
    };

    if (!config.is_prod) document.addEventListener('keydown', handleTilde, false);

    return () => {
      if (!config.is_prod) document.removeEventListener('keydown', handleTilde, false);
    };
  }, [debugWidgetOpen, enableDebugWidget]);

  const value = useMemo(
    () => ({
      enableDebugWidget,
      setEnableDebugWidget,
      debugWidgetOpen,
      setDebugWidgetOpen,
    }),
    [enableDebugWidget, setEnableDebugWidget, debugWidgetOpen, setDebugWidgetOpen],
  );

  return <DebugWidgetContext.Provider value={value}>{children}</DebugWidgetContext.Provider>;
};

export default DebugWidgetProvider;
