import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  margin-top: 0;
  text-align: left;

  @media (max-height: 450px) {
    display: none;
  }
`;

const EventType = styled('div')`
  display: inline-block;
  padding: 0.2rem 0.3rem;
  border: 1px solid #ffffff55;
  border-radius: 0.2rem;
  text-transform: uppercase;
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-weight: 400;
  color: #ffffff55;
  /* width: 100%; */
  margin-top: 0.2rem;
  position: relative;

  @media (max-width: 1150px) {
    padding: 0.15rem 0.2rem;
    font-size: 0.6rem;
    line-height: 0.6rem;
  }

  @media (max-height: 700px) {
    padding: 0.15rem 0.2rem;
    font-size: 0.6rem;
    line-height: 0.6rem;
  }
`;

export default {
  Root,
  EventType,
};
