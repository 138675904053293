import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface RootProps {
  isfullscreen: string;
}

const Root = styled('div')<RootProps>`
  background-color: ${({ isfullscreen }) => (isfullscreen === 'true' ? '#000000cc' : '#00000077')};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: ${customThemeOptions.custom.zIndex.gameOverlay.root};

  & * {
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
    user-select: none;
  }

  &.game-overlay-root-enter {
    opacity: 0;
  }

  &.game-overlay-root-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  &.game-overlay-root-exit {
    opacity: 1;
  }

  &.game-overlay-root-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
`;

export default { Root };
