import { styled } from '@mui/material';
import { config } from 'src/config/config';

const Root = styled('div')`
  background-color: #00000099;
  display: block;
  width: 100%;
  height: 5rem;
  position: relative;

  @media (max-width: 1000px) {
    height: 4.5rem;
  }
`;

const Logo = styled('img')`
  display: block;
  width: auto;
  height: 3.2rem;
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translate(0, -50%);
  cursor: pointer;

  @media (max-width: 1000px) {
    height: 2.8rem;
  }

  @media (max-width: ${config.ui_variables.mobile_breakpoints.header}px) {
    left: 4.3rem;
  }
`;

export default { Root, Logo };
