import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const start = () => (_dispatch: any) => {
  const config = {
    method: 'post',
    url: '/api/web/auth/oauth/start',
    data: {
      provider: 'n3twork',
    },
  };

  return request(config, { isPublic: true });
};
