import { FC } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import SectionHero from 'src/components/pages/getting-started/components/SectionHero';
import Styled from 'src/components/pages/getting-started/GettingStarted.styles';
import Banner from 'src/components/pages/getting-started/components/Banner';
import SectionDetails from 'src/components/pages/getting-started/components/SectionDetails';
import SectionAuth from 'src/components/pages/getting-started/components/SectionAuth';

const GettingStarted: FC = () => {
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);

  return (
    <Styled.Root>
      <SectionHero />
      <Banner />
      <SectionDetails />
      {!authenticated && <SectionAuth />}
    </Styled.Root>
  );
};

export default GettingStarted;
