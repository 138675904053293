import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
`;

const Title = styled('p')`
  display: block;
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;
`;

const ResultsContainer = styled('div')`
  display: block;
  margin: 2rem 0 0 0;
`;

const CollectionTitle = styled('p')`
  display: block;
  width: 100%;
  font-family: 'Kevlar Underwear';
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
`;

const BuyContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 2rem;
  text-align: center;
`;

const BuyLabel = styled('p')`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #ffffff77;
`;

const BuyButton = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 0.6rem 0.4rem 0.6rem;
  border: 1px solid #ffffff77;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #fff;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};

    & .material-icons {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }

    & > span:nth-of-type(2) {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }
  }

  & > .material-icons {
    display: inline-block;
    margin: 0 0.4rem 0.1rem 0;
    vertical-align: middle;
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 400;
    color: #fff;
  }

  & > span:nth-of-type(2) {
    display: inline-block;
  }
`;

export default {
  Root,
  Title,
  ResultsContainer,
  CollectionTitle,
  BuyContainer,
  BuyLabel,
  BuyButton,
};
