import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUsername = (options: RequestOptions) => (_dispatch: any) => {
  const { data } = options;

  const config = {
    method: 'post',
    url: `/api/web/me/change-username`,
    data: {
      username: data?.username,
    },
  };

  return request(config);
};
