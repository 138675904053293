import { CSSProperties, FC, useMemo } from 'react';
import { Typography, useTheme } from '@mui/material';
import DOMPurify from 'dompurify';
import { ReactComponent as CheckIcon } from '../icons/iconic/check-circle.svg';
import { ReactComponent as CloseIcon } from '../icons/iconic/close-circle.svg';
import { ReactComponent as WarningIcon } from '../icons/iconic/warning-triangle.svg';
import { ReactComponent as InformationIcon } from '../icons/iconic/information.svg';
import BaseIcon from '../icons/BaseIcon';
import Box from '../basics/Box';

interface IFormFeedbackProps {
  className?: string;
  margin?: string;
  message: string;
  showIcon?: boolean;
  variant: 'info' | 'warning' | 'critical' | 'success';
  maxWidth?: CSSProperties['maxWidth'];
  innerHtml?: boolean;
}

const FormFeedback: FC<IFormFeedbackProps> = ({
  margin = '0',
  maxWidth = 'initial',
  className = '',
  message,
  showIcon = false,
  variant,
  innerHtml = false,
}) => {
  const { custom } = useTheme();

  const props = useMemo(() => {
    switch (variant) {
      case 'success':
        return {
          color: custom.colors.TextSuccess,
          background: custom.colors.FieldSuccess,
          icon: <CheckIcon />,
        };
      case 'critical':
        return {
          color: custom.colors.TextCritical,
          background: custom.colors.FieldCritical,
          icon: <CloseIcon />,
        };
      case 'warning':
        return {
          color: custom.colors.TextWarning,
          background: custom.colors.FieldWarning,
          icon: <WarningIcon />,
        };
      case 'info':
        return {
          color: custom.colors.TextHighlight,
          background: custom.colors.FieldHighlight,
          icon: <InformationIcon />,
        };
      default:
        return {};
    }
  }, [
    custom.colors.FieldCritical,
    custom.colors.FieldHighlight,
    custom.colors.FieldSuccess,
    custom.colors.FieldWarning,
    custom.colors.TextCritical,
    custom.colors.TextHighlight,
    custom.colors.TextSuccess,
    custom.colors.TextWarning,
    variant,
  ]);

  return (
    <Box
      className={className}
      margin={margin}
      padding="8px 16px"
      display="flex"
      alignItems="center"
      flexDirection="row"
      gap="12px"
      minHeight="38px"
      maxWidth={maxWidth}
      borderRadius="4px"
      sx={{ backgroundColor: props.background }}
    >
      {(showIcon && <BaseIcon icon={props.icon} color={props.color} />) || null}
      <Typography variant="body.lg" color={props?.color}>
        {innerHtml ? (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: typeof message === 'string' ? DOMPurify.sanitize(message) : '' }}
          />
        ) : (
          message
        )}
      </Typography>
    </Box>
  );
};

export default FormFeedback;
