import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  margin-top: 1.2rem;

  & > div:not(:first-of-type) {
    margin-top: 1.3rem;
  }
`;

const SkillRow = styled('div')`
  display: block;
  width: 100%;
`;

const SkillCol = styled('div')`
  display: inline-block;
  width: 50%;
  padding: 0 1rem;
  vertical-align: top;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SkillCategory = styled('span')`
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff;
  color: ${({ theme }) => theme.custom.colors.Yellow};
`;

const SkillContainer = styled('div')`
  display: block;
  margin-top: 1rem;
  text-align: left;
`;

const SkillIcon = styled('img')`
  display: inline-block;
  width: 2.2rem;
  height: auto;
  padding: 0.3rem;
  margin: 0.2rem 1rem 0 0;
  vertical-align: top;
  border: 1px solid #ffffff77;
  border-radius: 50%;
`;

const SkillTextContainer = styled('div')`
  display: inline-block;
  width: calc(100% - 3.2rem);
  vertical-align: top;
`;

const SkillLabel = styled('div')`
  display: block;
  text-transform: capitalize;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffffff;

  & > div:first-of-type {
    display: inline-block;
    vertical-align: middle;
    margin-top: 3px;
    margin-right: 0.5rem;
  }

  & > div:last-of-type {
    display: inline-flex;
    width: auto;
    vertical-align: middle;
    margin-top: -0.6rem;
  }

  @media (max-width: 450px) {
    & > div:last-of-type {
      display: flex;
      width: 100%;
      margin-top: 0rem;

      & > svg {
        margin-top: 0.5rem;
      }
    }
  }
`;

const SkillValue = styled('p')`
  display: block;
  margin-top: 0.2rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff77;

  & b {
    color: ${({ theme }) => `${theme.custom.colors.Yellow}aa`};
  }

  @media (max-width: 450px) {
    margin-top: 0.3rem;
  }
`;

const EmptyText = styled('p')`
  display: block;
  margin-top: 1.3rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff77;
`;

export default {
  Root,
  SkillRow,
  SkillCol,
  SkillCategory,
  SkillContainer,
  SkillIcon,
  SkillTextContainer,
  SkillLabel,
  SkillValue,
  EmptyText,
};
