import { useContext } from 'react';
import PendingPurchasesContext from 'src/context/pending-purchases/PendingPurchases.context';

const usePendingPurchasesContext = () => {
  const pendingPurchasesContext = useContext(PendingPurchasesContext);

  return pendingPurchasesContext;
};

export default usePendingPurchasesContext;
