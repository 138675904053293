import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: #222126;
  display: block;
  width: 100%;
  padding: 0.7rem 2rem;
  border-bottom: ${({ theme }) => `2px solid ${theme.custom.colors.Yellow}`};
  box-sizing: border-box;
  text-align: right;
  position: absolute;
  top: 5rem;

  @media (max-width: 1000px) {
    top: 4.5rem;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const TextContainer = styled('div')`
  display: inline-block;
  vertical-align: middle;
`;

const StatusPrimaryText = styled('span')`
  display: inline-block;
  margin: 0 0.2rem;
  vertical-align: top;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 700;
  color: #ffffff;

  @media (max-width: 768px) {
    display: block;
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
`;

const StatusSecondaryText = styled('div')`
  display: inline-block;
  margin: 0 0.2rem;
  vertical-align: top;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: 700;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  @media (max-width: 768px) {
    display: block;
    margin: 0.4rem 0 0 0;
    font-size: 1.3rem;
    line-height: 1.5rem;
  }
`;

const StatusTertiaryText = styled('p')`
  display: block;
  width: 100%;
  margin: 0.1rem 0 0 0;
  text-align: left;
  text-transform: uppercase;
  font-size: 0.7rem;
  line-height: 0.9rem;
  font-weight: 700;
  color: #ffffff;
  /* color: #55ff55; */

  @media (max-width: 768px) {
    text-align: center;
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
`;

const CountdownContainer = styled('div')`
  display: inline-block;
  margin-left: 2rem;
  margin-top: -0.2rem;
  padding-bottom: 0.9rem;
  vertical-align: middle;

  & > div:not(:last-child) {
    margin-right: 0.7rem;
  }

  @media (max-width: 768px) {
    display: block;
    margin-left: 0;
    margin-top: 0.6rem;
    padding-bottom: 1rem;
  }
`;

const CountdownSegment = styled('div')`
  background-color: #00000030;
  display: inline-block;
  width: 2.3rem;
  height: 1.6rem;
  vertical-align: middle;
  border-radius: 0.3rem;
  position: relative;
`;

const CountdownSegmentBottom = styled('div')`
  background-color: #ffffff11;
  display: block;
  width: 100%;
  height: 50%;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const CountdownSegmentData = styled('span')`
  display: inline-block;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CountdownSegmentLabel = styled('span')`
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.7rem;
  line-height: 0.9rem;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  top: 1.7rem;
  left: 50%;
  transform: translate(-50%, 0);
`;

export default {
  Root,
  TextContainer,
  StatusPrimaryText,
  StatusSecondaryText,
  StatusTertiaryText,
  CountdownContainer,
  CountdownSegment,
  CountdownSegmentBottom,
  CountdownSegmentData,
  CountdownSegmentLabel,
};
