import { FC } from 'react';
import Styled from 'src/components/data-display/leaderboard/Col.styles';
// import { logMain } from 'src/modules/logger/logger';

interface ColProps {
  flexGrow: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Col: FC<ColProps> = ({ flexGrow = 1, children, style = {} }) => {
  return (
    <Styled.Root flexGrow={flexGrow} style={style}>
      {children}
    </Styled.Root>
  );
};

export default Col;
