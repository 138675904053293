import { useContext } from 'react';
import TutorialContext from 'src/context/tutorial/Tutorial.context';

const useTutorialContext = () => {
  const tutorialContext = useContext(TutorialContext);

  return tutorialContext;
};

export default useTutorialContext;
