import { FC } from 'react';
import Styled from 'src/components/game-overlay/BaseOverlayPage.styles';

interface BaseOverlayPageProps {
  children: React.ReactNode;
}

const BaseOverlayPage: FC<BaseOverlayPageProps> = ({ children }) => {
  return <Styled.Root>{children}</Styled.Root>;
};

export default BaseOverlayPage;
