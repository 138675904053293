export const getRarityColor = (rarity: string | null | undefined): string => {
  switch (rarity) {
    case 'starter':
      return '#ffffff';
    case 'promo':
      return '#ffffff';
    case 'common':
      return '#ffffff';
    case 'uncommon':
      return '#41f04b';
    case 'rare':
      return '#42e9fd';
    case 'ultraRare':
      return '#9447ec';
    // ultra rare is same as ultraRare
    case 'ultra rare':
      return '#9447ec';
    case 'epic':
      return '#f77435';
    // Mythic is same as Epic
    case 'mythic':
      return '#f77435';
    case 'legendary':
      return '#ee3635';
    default:
      return '#ffffff';
  }
};
