export const copyCurrentProfile = (profile: CurrentProfile) => {
  const legalStates = profile?.features?.legal?.states || [];

  return {
    ...profile,
    allowLists: profile.allowLists ? [...profile.allowLists] : [],
    discordUser: profile.discordUser ? { ...profile.discordUser } : null,
    features: {
      ...profile.features,
      gameAccess: { ...profile.features.gameAccess },
      marketplace: { ...profile.features.marketplace },
      legal: { ...profile.features.legal, states: legalStates.map(state => ({ ...state })) },
      leaderboards: { ...profile.features.leaderboards },
    },
  };
};
