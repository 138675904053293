import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux/store';
import { copyCurrencyStates } from 'src/redux/slices/currency-states/helpers/copy-currency-states';

// Define the initial state using that type
const initialState: CurrencyStatesState = {
  currencyStates: {},
};

const currencyStatesSlice = createSlice({
  name: 'currencyStates',
  initialState,
  reducers: {
    setCurrencyStates: {
      reducer(state, action: PayloadAction<CurrencyStatesPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.currencyStates = copyCurrencyStates(action.payload.currencyStates);
      },
      prepare(currencyStates: CurrencyState[]) {
        return {
          payload: {
            currencyStates,
          },
        };
      },
    },
    clearCurrencyStates: state => {
      // eslint-disable-next-line no-param-reassign
      state.currencyStates = {};
    },
  },
});

export const { setCurrencyStates, clearCurrencyStates } = currencyStatesSlice.actions;

// Other Selectors
export const getCurrencyStatesMap = (state: RootState): CurrencyStatesKC => state.currencyStates.currencyStates;

export default currencyStatesSlice.reducer;
