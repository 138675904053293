import { styled } from '@mui/material';

const Root = styled('div')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 22rem;
  position: relative;

  @media (max-width: 768px) {
    height: 17rem;
  }
`;

const CenteredContainer = styled('div')`
  display: block;
  width: 100%;
  /* max-width: 75rem; */
  height: 100%;
  margin: 0 auto;
  position: relative;
`;

const LogoOuterContainer = styled('div')`
  background: linear-gradient(-45deg, red -10%, black, red 110%);
  display: block;
  width: 9rem;
  height: 9rem;
  border-radius: 0.5rem;
  position: absolute;
  bottom: -1rem;
  left: 3rem;

  @media (max-width: 768px) {
    width: 7rem;
    height: 7rem;
    left: 2rem;
  }
`;

const LogoInnerContainer = styled('div')`
  background-color: #171a19;
  display: block;
  width: 8.8rem;
  height: 8.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    width: 6.9rem;
    height: 6.9rem;
  }
`;

const Logo = styled('img')`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  width: 90%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default {
  Root,
  CenteredContainer,
  LogoOuterContainer,
  LogoInnerContainer,
  Logo,
};
