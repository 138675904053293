import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import Step from 'src/components/pages/getting-started/components/Step';
import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/pages/getting-started/components/SectionDetails.styles';

interface BG {
  title: string;
  width: string;
  height: string;
  path: string;
}

interface Asset {
  title: string;
  width: string;
  height: string;
  path: string;
}

interface StepData {
  title: string;
  desc: string[];
  stepAsset: Asset;
  mediaAsset: Asset;
}

const SectionDetails: FC = () => {
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const { t: tGettingStarted } = useTranslation(nameSpace.gettingStarted);
  // const { t: tCommon } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const bgTop: BG = tGettingStarted('getting.started.details.bkg.top', { returnObjects: true });
  const bgBottom: BG = tGettingStarted('getting.started.details.bkg.bottom', { returnObjects: true });
  const bgBottomMobile: BG = tGettingStarted('getting.started.details.bkg.bottom.mobile', { returnObjects: true });
  const steps: StepData[] = tGettingStarted('getting.started.details.steps', { returnObjects: true });

  return (
    <Styled.Root>
      <Styled.BackgroundImage
        src={`/assets/${bgTop.path}`}
        width={bgTop.width}
        height={bgTop.height}
        title={bgTop.title}
        alt={bgTop.title}
        style={{ top: '0' }}
      />

      {mobileView ? (
        <Styled.BackgroundImage
          src={`/assets/${bgBottomMobile.path}`}
          width={bgBottomMobile.width}
          height={bgBottomMobile.height}
          title={bgBottomMobile.title}
          alt={bgBottomMobile.title}
          style={{ bottom: '0' }}
        />
      ) : (
        <Styled.BackgroundImage
          src={`/assets/${bgBottom.path}`}
          width={bgBottom.width}
          height={bgBottom.height}
          title={bgBottom.title}
          alt={bgBottom.title}
          style={{ bottom: '0' }}
        />
      )}

      <Styled.InnerContainer extraPadding={!authenticated}>
        <Styled.CenteredContainer>
          {steps.map((_step, i) => {
            return <Step key={`getting-started-step-${i}`} stepIndex={i} />;
          })}
        </Styled.CenteredContainer>
      </Styled.InnerContainer>
    </Styled.Root>
  );
};

export default SectionDetails;
