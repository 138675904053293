import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAuthentication } from 'src/hooks/useAuthentication';
import { useDiscordAuthentication } from 'src/hooks/useDiscordAuthentication';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { getServerGameTime } from 'src/tools/getGameTime';
import CheckboxDiamond from 'src/components/input/CheckboxDiamond';
import { Tooltip, useMediaQuery } from '@mui/material';
import Styled from 'src/components/primary-marketplace/components/Requirements.styles';
// import { logMain } from 'src/modules/logger/logger';

interface RequirementsProps {
  lpmId: string;
  style?: React.CSSProperties;
}

const Requirements: FC<RequirementsProps> = ({ lpmId, style = {} }) => {
  const { processing: authProcessing, initiateAuthentication } = useAuthentication({ fromContext: 'event' });
  const { linkDiscord, processingLink } = useDiscordAuthentication({ fromContext: 'event_discord_link' });
  const [inView, setInView] = useState(false);
  const authenticated = useAppSelector(state => state.authenticated.authenticated);
  const currentProfile = useAppSelector(state => state.currentProfile.currentProfile);
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const smallerIcons = useMediaQuery('(max-height:500px)');
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tPlay } = useTranslation(nameSpace.play);

  // Variables to determine event state
  const {
    startTimestampMs,
    closeTimestampMs,
    publicStartTimestampMs: publicStartDate,
    publicEndTimestampMs: publicEndDate,
    recapEndTimestampMs: recapEndDate,
  } = listPriceMarket;
  const now = getServerGameTime();

  // Event Status Variables
  const isTimedEvent = startTimestampMs || closeTimestampMs || publicStartDate || publicEndDate || recapEndDate;
  const allowlistOnlySaleExists = startTimestampMs && closeTimestampMs && startTimestampMs < closeTimestampMs;
  const publicSaleExists = publicStartDate && publicEndDate && publicStartDate < publicEndDate;
  const allowlistOnlySaleStarted = allowlistOnlySaleExists ? now >= startTimestampMs : false;
  const allowlistOnlySaleEnded = allowlistOnlySaleExists ? now >= closeTimestampMs : false;
  const allowlistOnlySaleActive = allowlistOnlySaleExists && allowlistOnlySaleStarted && !allowlistOnlySaleEnded;
  //   const publicStarted = publicSaleExists ? now >= publicStartDate : false;
  const publicEnded = publicSaleExists ? now >= publicEndDate : false;
  //   const publicActive = publicSaleExists && publicStarted && !publicEnded;
  const closed =
    (publicSaleExists && now >= publicEndDate) ||
    (!publicSaleExists && allowlistOnlySaleExists && now >= closeTimestampMs);
  const upcomingAllowlistEvent = allowlistOnlySaleExists && !allowlistOnlySaleStarted;

  // Variables to determine market state and user purchase state
  const {
    type,
    allowListPurchaseLimits,
    allowedViaAllowListIds,
    allowedViaAllowLists,
    questCompleted,
    questDescription,
  } = listPriceMarket;
  const questRequired = questCompleted !== null;
  const isGatedByAllowList =
    allowedViaAllowLists !== null && allowedViaAllowListIds !== null && allowListPurchaseLimits !== null;
  const hasAllowlist = (allowedViaAllowListIds && allowedViaAllowListIds.length > 0) ?? false;

  if (!questRequired && ((isTimedEvent && closed) || (!isTimedEvent && !isGatedByAllowList) || type === 'daily'))
    return null;

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated ${inView ? 'fadeInUp' : ''}`} style={style}>
        <Styled.RequirementsLabel>
          {questRequired
            ? tPlay('play.overlay.events.quest.requirements')
            : tPlay('play.overlay.events.requirements.to.mint')}
        </Styled.RequirementsLabel>

        <Styled.Requirements>
          {questRequired && (
            <Styled.Requirement>
              <CheckboxDiamond
                defaultValue={Boolean(questCompleted)}
                clickable={false}
                size={smallerIcons ? 10 : 14}
                style={{ display: 'block', float: 'left', margin: smallerIcons ? '5px 12px 0 2px' : '5px 16px 0 2px' }}
              />

              <Styled.RequirementText>
                {questDescription || tPlay('play.overlay.events.quest.description.default')}
              </Styled.RequirementText>
            </Styled.Requirement>
          )}

          {!questRequired &&
            !upcomingAllowlistEvent &&
            !allowlistOnlySaleActive &&
            publicSaleExists &&
            !publicEnded && (
              <Styled.Requirement>
                <CheckboxDiamond
                  defaultValue={authenticated}
                  clickable={false}
                  size={smallerIcons ? 11 : 14}
                  style={{
                    display: 'block',
                    float: 'left',
                    margin: smallerIcons ? '4px 12px 0 2px' : '5px 16px 0 2px',
                  }}
                />
                <Styled.RequirementText>{tCommon('common.drop.event.requirements.req1')}</Styled.RequirementText>

                {!authenticated && (
                  <Styled.RequirementHelp disabled={authProcessing} onClick={initiateAuthentication}>
                    {tCommon('common.auth.register.or.sign.in')}{' '}
                    <span className="material-icons-outlined">open_in_new</span>
                  </Styled.RequirementHelp>
                )}
              </Styled.Requirement>
            )}

          {!questRequired &&
            ((isTimedEvent && (upcomingAllowlistEvent || allowlistOnlySaleActive)) ||
              (!isTimedEvent && isGatedByAllowList)) && (
              <>
                <Styled.Requirement>
                  <CheckboxDiamond
                    defaultValue={authenticated && currentProfile.discordUser !== null}
                    clickable={false}
                    size={smallerIcons ? 11 : 14}
                    style={{
                      display: 'block',
                      float: 'left',
                      margin: smallerIcons ? '4px 12px 0 2px' : '5px 16px 0 2px',
                    }}
                  />

                  <Styled.RequirementText>
                    {tCommon('common.drop.event.requirements.req2')}
                    {!currentProfile.discordUser && (
                      <Tooltip
                        arrow
                        title={
                          <Styled.TooltipContainer>
                            <Styled.TooltipHeading>
                              {tPlay('play.overlay.events.tooltip.discord.notfound.title')}
                            </Styled.TooltipHeading>

                            <Styled.TooltipNotice>
                              {tPlay('play.overlay.events.tooltip.discord.notfound.notice')}
                            </Styled.TooltipNotice>
                          </Styled.TooltipContainer>
                        }
                        placement="top-start"
                        componentsProps={{
                          arrow: {
                            sx: {
                              color: '#222126',
                            },
                          },
                          tooltip: {
                            sx: {
                              backgroundColor: '#222126',
                              padding: '1rem',
                              fontSize: '0.8rem',
                            },
                          },
                        }}
                      >
                        <span className="material-icons-outlined" style={{ cursor: 'default' }}>
                          info
                        </span>
                      </Tooltip>
                    )}
                  </Styled.RequirementText>

                  {authenticated && !currentProfile.discordUser && (
                    <Styled.RequirementHelp disabled={processingLink} onClick={linkDiscord}>
                      {tCommon('common.discord.login')} <span className="material-icons-outlined">open_in_new</span>
                    </Styled.RequirementHelp>
                  )}
                </Styled.Requirement>

                <Styled.Requirement>
                  <CheckboxDiamond
                    defaultValue={authenticated && hasAllowlist}
                    clickable={false}
                    size={smallerIcons ? 11 : 14}
                    style={{
                      display: 'block',
                      float: 'left',
                      margin: smallerIcons ? '4px 12px 0 2px' : '5px 16px 0 2px',
                    }}
                  />
                  <Styled.RequirementText>
                    {tCommon('common.drop.event.requirements.req3')}
                    <Tooltip
                      arrow
                      title={
                        <Styled.TooltipContainer>
                          {allowedViaAllowLists && allowedViaAllowLists.length > 0 ? (
                            <>
                              <Styled.TooltipHeading>
                                {tPlay('play.overlay.events.tooltip.allowlist.found.title')}
                              </Styled.TooltipHeading>

                              <Styled.TooltipRows>
                                {allowedViaAllowLists.map((allowList, i) => {
                                  return (
                                    <Styled.TooltipRow key={`tooltiprow-${i}`}>{allowList.name}</Styled.TooltipRow>
                                  );
                                })}
                              </Styled.TooltipRows>
                            </>
                          ) : (
                            <>
                              <Styled.TooltipHeading>
                                {tPlay('play.overlay.events.tooltip.allowlist.notfound.title')}
                              </Styled.TooltipHeading>
                              <Styled.TooltipNotice>
                                {tPlay('play.overlay.events.tooltip.allowlist.notfound.notice')}
                              </Styled.TooltipNotice>
                            </>
                          )}
                        </Styled.TooltipContainer>
                      }
                      placement="top-start"
                      componentsProps={{
                        arrow: {
                          sx: {
                            color: '#222126',
                          },
                        },
                        tooltip: {
                          sx: {
                            backgroundColor: '#222126',
                            padding: '1rem',
                            fontSize: '0.8rem',
                          },
                        },
                      }}
                    >
                      <span className="material-icons-outlined info-icon" style={{ cursor: 'default' }}>
                        info
                      </span>
                    </Tooltip>
                  </Styled.RequirementText>
                </Styled.Requirement>
              </>
            )}
        </Styled.Requirements>
      </Styled.Root>
    </Waypoint>
  );
};

export default Requirements;
