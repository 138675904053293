import { styled } from '@mui/material';

const Wrapper = styled('div')`
  position: relative;
  text-align: left;
  direction: ltr;
  .bullet {
    svg {
      fill: #fec300;
      stroke: #fec300;
    }
  }
  .time-entry {
    position: relative;
    padding-left: 26px;
    padding-bottom: 2rem;
    &:before {
      content: '';
      position: absolute;
      top: 31px;
      left: -8px;
      height: 100%;
      width: 1px;
      background-color: #707070;
    }
    p,
    h4 {
      margin: 0 0 0.5rem 0;
    }
    .date {
      font-size: 1rem;
      line-height: 1.1;
    }
    .content {
      font-size: 0.8rem;
      line-height: 1.2;
      max-width: 300px;
    }
    h4 {
      line-height: 1;
      font-size: 1.8rem;
    }
  }
`;

export default { Wrapper };
