import { styled } from '@mui/material';

const Root = styled('div')`
  width: 100%;
  padding-top: 5rem;

  & > div {
    display: inline-block;
    cursor: pointer;
  }

  & .material-icons-outlined {
    margin-right: 0.8rem;
    font-size: 1.2rem;
    color: #ffffff;
  }

  & > div > span {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #ffffff;
  }

  & #back-button {
    padding-bottom: 0.1rem;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    max-width: 30rem;
    margin: 0 auto;
    padding-top: 3rem;
  }
`;

export default {
  Root,
};
