import { FC } from 'react';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import Styled from 'src/components/game-overlay/CloseButton.styles';

const CloseButton: FC = () => {
  const { closeGameOverlay } = useGameOverlayContext();

  return (
    <Styled.Root disabled={false}>
      <PrimaryModalBackground disableLineTexture />

      <Styled.Button disabled={false} onClick={() => closeGameOverlay()}>
        <span className={'material-icons'}>close</span>
      </Styled.Button>
    </Styled.Root>
  );
};

export default CloseButton;
