import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const Root = styled('div')`
  display: block;
  overflow: visible;
  position: relative;
  z-index: ${customThemeOptions.custom.zIndex.debugWidget.root};
`;

export default { Root };
