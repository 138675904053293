import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useCountdown } from 'src/hooks/useCountdown';
import Styled from 'src/components/game/GameAccessCountdown.styles';
import { getServerGameTime } from 'src/tools/getGameTime';

const getCountdownDate = (startDate: number | null, endDate: number | null, now: number): number | null => {
  if (!startDate || !endDate) return null;
  if (now < startDate) return startDate;
  if (now >= startDate && now < endDate) return endDate;

  return null;
};

interface Colors {
  gradientColor1: string;
  gradientColor2: string;
}

interface GameAccessCountdownProps {
  colors: Colors;
}

const GameAccessCountdown: FC<GameAccessCountdownProps> = ({ colors }) => {
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const gameIsGated = currentProfile?.features?.gameAccess?.eventGated;
  const gatedStartTimestampMs = currentProfile?.features?.gameAccess?.startTimestampMs;
  const gatedEndTimestampMs = currentProfile?.features?.gameAccess?.endTimestampMs;
  const gameTime = getServerGameTime();
  const countdownDate = getCountdownDate(gatedStartTimestampMs, gatedEndTimestampMs, gameTime) || 0;
  const { daysDD, hoursDD, minsDD, secsDD } = useCountdown(countdownDate);
  const { t: tCommon } = useTranslation(nameSpace.common);

  if (!gameIsGated || !gatedStartTimestampMs || !gatedEndTimestampMs) {
    return null;
  }

  return (
    <Styled.Root colors={colors}>
      <Styled.ContentContainer colors={colors}>
        <span className="material-icons-outlined">hourglass_top</span>

        <Styled.CountdownTitle colors={colors}>
          {tCommon('common.term.alpha')} {gameTime < gatedStartTimestampMs && tCommon('common.term.begins.in')}
          {gameTime >= gatedStartTimestampMs && gameTime < gatedEndTimestampMs && tCommon('common.term.ends.in')}
          {gameTime > gatedEndTimestampMs && tCommon('common.term.has.ended')}
        </Styled.CountdownTitle>

        {gameTime < gatedEndTimestampMs && (
          <Styled.CountdownContainer colors={colors}>
            <Styled.CountdownSegment>
              <Styled.CountdownSegmentData>{daysDD}</Styled.CountdownSegmentData>
              <Styled.CountdownSegmentLabel>{tCommon('common.days')}</Styled.CountdownSegmentLabel>
            </Styled.CountdownSegment>

            <Styled.CountdownSegment>
              <Styled.CountdownSegmentData>{hoursDD}</Styled.CountdownSegmentData>
              <Styled.CountdownSegmentLabel>{tCommon('common.hours')}</Styled.CountdownSegmentLabel>
            </Styled.CountdownSegment>

            <Styled.CountdownSegment>
              <Styled.CountdownSegmentData>{minsDD}</Styled.CountdownSegmentData>
              <Styled.CountdownSegmentLabel>{tCommon('common.mins')}</Styled.CountdownSegmentLabel>
            </Styled.CountdownSegment>

            <Styled.CountdownSegment>
              <Styled.CountdownSegmentData>{secsDD}</Styled.CountdownSegmentData>
              <Styled.CountdownSegmentLabel>{tCommon('common.secs')}</Styled.CountdownSegmentLabel>
            </Styled.CountdownSegment>
          </Styled.CountdownContainer>
        )}
      </Styled.ContentContainer>
    </Styled.Root>
  );
};

export default GameAccessCountdown;
