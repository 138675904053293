import { useState, useEffect } from 'react';
import { getCookieByPartialKey } from 'src/tools/getCookieByPartialKey';

interface QueueItStatus {
  queueComplete: boolean;
  queueItCookieExpired: boolean;
}

export const useQueueItStatus = (): QueueItStatus => {
  const [queueComplete, setQueueComplete] = useState<boolean>(false);
  const [queueItCookieExpired, setQueueItCookieExpired] = useState<boolean>(true);

  useEffect(() => {
    const handleQueueItStatus = (now: number) => {
      const queueItCookie = getCookieByPartialKey('QueueIT') || '';

      const cookieParams = queueItCookie.split('&');
      const cookieParamsKeyValues = cookieParams.map(param => {
        const [key, value] = param.split('=');
        return { key, value };
      });
      const issueTime = cookieParamsKeyValues.find(param => param.key === 'IssueTime')?.value || '0';
      const parsedIssueTime = isNaN(parseInt(issueTime, 10)) ? 0 : parseInt(issueTime, 10) * 1000;

      const queueComplete = !!queueItCookie;
      const queueItCookieExpired = !issueTime || !parsedIssueTime || now >= parsedIssueTime + 1200000; // 1,200,000 milliseconds = 20 minutes

      if (!queueItCookie) {
        setQueueComplete(false);
        setQueueItCookieExpired(true);
      } else {
        setQueueComplete(queueComplete);
        setQueueItCookieExpired(queueItCookieExpired);
      }
    };

    const interval = setInterval(() => handleQueueItStatus(Date.now()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    queueComplete,
    queueItCookieExpired, // 1,200,000 milliseconds = 20 minutes
  };
};
