import { styled } from '@mui/material';

interface RootProps {
  flexGrow?: number;
}

const Root = styled('div')<RootProps>`
  display: inline-block;
  flex-grow: ${({ flexGrow }) => flexGrow || 1};
  flex-basis: 0;

  & > span {
    display: inline-block;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1rem;
    color: #ffffff77;
  }
`;

export default {
  Root,
};
