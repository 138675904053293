import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
// import { useFarcasterAuthentication } from 'src/hooks/useFarcasterAuthentication';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getLoadedProfileByUsernameOrPlayerId } from 'src/redux/slices/loaded-profiles/loaded-profiles';
// import ButtonFarcasterLogin from 'src/components/input/ButtonFarcasterLogin';
import StatusIndicator from 'src/components/data-display/StatusIndicator';
import BaseIcon from 'src/components/icons/BaseIcon';
import FarcasterSocialIcon from 'src/components/icons/FarcasterSocialIcon';
// import { Tooltip } from '@mui/material';
import Styled from 'src/components/pages/profile/components/FarcasterStatus.styles';

const FarcasterStatus: FC = () => {
  const { playerId = '' } = useParams();
  //   const { unlinkDiscord } = useFarcasterAuthentication({
  //     fromContext: 'profile_farcaster_unlink',
  //   });
  const [inView1, setInView1] = useState(false);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const loadedProfile = useAppSelector(state => getLoadedProfileByUsernameOrPlayerId(state, playerId));
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const profile = isCurrentProfile ? currentProfile : loadedProfile;
  const discordLinked = !!profile?.discordUser;
  const farcasterLinked = !!profile?.farcasterUser;

  if (!farcasterLinked) return null;

  return (
    <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
      <Styled.Root
        discordLinked={discordLinked}
        farcasterLinked={farcasterLinked}
        className={`inview animated ${inView1 ? 'fadeInUp' : ''}`}
      >
        {/* {isCurrentProfile && (
          <ButtonFarcasterLogin
            fullWidth={false}
            style={{
              marginRight: '0.6rem',
              padding: '0.4rem 0.8rem 0.3rem 0.8rem',
            }}
            textStyle={{
              fontSize: '0.8rem',
              lineHeight: '1rem',
            }}
          />
        )} */}

        {farcasterLinked && (
          <Styled.UsernameContainer id="farcaster-name">
            <BaseIcon
              icon={<FarcasterSocialIcon />}
              fill="#ffffff99"
              height="18px"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                margin: '0 0.3rem 0 0',
              }}
            />

            <Styled.Username>{`Farcaster - ${profile?.farcasterUser?.username || 'UnknownUser'}`}</Styled.Username>
          </Styled.UsernameContainer>
        )}

        {isCurrentProfile && (
          <Styled.StatusContainer id="farcaster-status">
            <StatusIndicator active={farcasterLinked} style={{ margin: '0 0.4rem 0 0' }} />

            <Styled.StatusText linked={farcasterLinked}>
              {farcasterLinked ? 'Connected' : 'Not Connected'}
            </Styled.StatusText>
          </Styled.StatusContainer>
        )}

        {/* {farcasterLinked && (
          <Tooltip title="Unlink Farcaster">
            <Styled.UnlinkButton onClick={unlinkFarcaster}>
              <span className="material-icons-outlined">link_off</span>
            </Styled.UnlinkButton>
          </Tooltip>
        )} */}
      </Styled.Root>
    </Waypoint>
  );
};

export default FarcasterStatus;
