import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { copyCurrentProfile } from 'src/redux/slices/current-profile/helpers/copy-current-profile';

// Define the initial state using that type
const initialState: CurrentProfileState = {
  currentProfile: {},
};

const currentProfileSlice = createSlice({
  name: 'currentProfile',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCurrentProfile: {
      reducer(state, action: PayloadAction<CurrentProfilePayload>) {
        // eslint-disable-next-line no-param-reassign
        state.currentProfile = copyCurrentProfile(action.payload.profile);
      },
      prepare(currentProfile: CurrentProfile) {
        return {
          payload: {
            profile: currentProfile,
          },
        };
      },
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateCurrentProfileUsername: {
      reducer(state, action: PayloadAction<UsernamePayload>) {
        // eslint-disable-next-line no-param-reassign
        state.currentProfile.username = action.payload.username;
      },
      prepare(username: string) {
        return {
          payload: {
            username,
          },
        };
      },
    },
    clearCurrentProfile: state => {
      // eslint-disable-next-line no-param-reassign
      state.currentProfile = initialState.currentProfile;
    },
    updateGameAccess: {
      reducer(state, action: PayloadAction<GameAccessPayload>) {
        // eslint-disable-next-line no-param-reassign
        state.currentProfile.features.gameAccess = action.payload.gameAccess;
      },
      prepare(gameAccess: GameAccessFeature) {
        return {
          payload: {
            gameAccess,
          },
        };
      },
    },
  },
});

export const { setCurrentProfile, updateCurrentProfileUsername, clearCurrentProfile, updateGameAccess } =
  currentProfileSlice.actions;

export default currentProfileSlice.reducer;
