import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createRoot } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import TagManager from 'react-gtm-module';
import { logMain } from 'src/modules/logger/logger';
import { PooleEvents } from 'src/modules/poole/events';
import { LocalStorage } from 'src/local-storage/local-storage';
import { config } from 'src/config/config';
import store from 'src/redux/store';
import ErrorBoundary from 'src/components/error-boundary/ErrorBoundary';
import WebMetricsProvider from 'src/context/web-metrics/WebMetrics.provider';
import BroadcastChannelProvider from 'src/context/broadcast-channel/BroadcastChannel.provider';
import ViewportProvider from 'src/context/viewport/Viewport.provider';
import ProgressiveWebAppProvider from 'src/context/progressive-web-app/ProgressiveWebApp.provider';
import CursorProvider from 'src/context/cursor/Cursor.provider';
import AlertOverrideProvider from 'src/context/alert-override/AlertOverride.provider';
import ThemeProvider from 'src/styles/theme/ThemeProvider';
import TutorialProvider from 'src/context/tutorial/Tutorial.provider';
import DiscordWidgetProvider from 'src/context/discord-widget/DiscordWidget.provider';
import DebugWidgetProvider from 'src/context/debug-widget/DebugWidget.provider';
import SessionProvider from 'src/context/session/Session.provider';
import ReduxProvider from 'src/redux/provider';
import AuthProvider from 'src/context/auth/Auth.provider';
import LegalNoticeProvider from 'src/context/legal-notice/LegalNotice.provider';
import GameOverlayProvider from 'src/context/game-overlay/GameOverlay.provider';
import GameClientProvider from 'src/context/game-client/GameClient.provider';
import MarketplaceProvider from 'src/context/marketplace/Marketplace.provider';
import PendingPurchasesProvider from 'src/context/pending-purchases/PendingPurchases.provider';
import PaymentPopupProvider from 'src/context/payment-popup/PaymentPopup.provider';
import LeaderboardsProvider from 'src/context/leaderboards/Leaderboards.provider';
import BlogProvider from 'src/context/blog/Blog.provider';
import SoundProvider from 'src/context/sound/Sound.provider';
import App from 'src/App';
import 'src/styles/global/App.sanitize.scss';
import packageJson from '../package.json';

const container = document.getElementById('__root') as Element;
const root = createRoot(container);
const basename = document.querySelector('base')?.getAttribute('href') ?? '/';
const logColors = { info: 'orange', debug: 'cyan', notice: 'magenta' };

logMain.info(`%c===${packageJson.name} v${packageJson.version}===`, `color: ${logColors.info};`);
logMain.debug(`%c[PUBLIC_URL]: ${process.env.PUBLIC_URL}`, `color: ${logColors.debug};`);
logMain.debug(`%c[NODE_ENV]: ${process.env.NODE_ENV}`, `color: ${logColors.debug};`);
logMain.debug(`%c[REACT_APP_ENV]: ${config.env}`, `color: ${logColors.debug};`);
logMain.debug('%c[REDUX_STATE]: ', 'color: cyan;', store.getState());
logMain.debug(`%c[UUID]: ${config.uuid}`, `color: ${logColors.debug};`);
logMain.debug(`%c[BASENAME]: ${basename}`, `color: ${logColors.debug};`);

// Load GTM (Google Tag Manager)
TagManager.initialize(config.tag_manager_config);

// Fire off Poole Event (App Initialized)
PooleEvents.InitializeApp({ referrer: document.referrer });

// Log first visit to site if this has not visited site yet
if (!LocalStorage.get('first_visit_logged') || LocalStorage.get('first_visit_logged') === 'false') {
  logMain.info(`%c[FIRST_VISIT]: First visit to LHU!`, `color: ${logColors.notice}`);

  LocalStorage.set('first_visit_logged', 'true');
}

root.render(
  <ErrorBoundary>
    <WebMetricsProvider>
      <BroadcastChannelProvider>
        <ViewportProvider>
          <ProgressiveWebAppProvider>
            <CursorProvider>
              <AlertOverrideProvider>
                <BrowserRouter basename={basename}>
                  <ThemeProvider>
                    <SessionProvider>
                      <ReduxProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <AuthProvider>
                            <LegalNoticeProvider>
                              <GameOverlayProvider>
                                <GameClientProvider>
                                  <TutorialProvider>
                                    <DiscordWidgetProvider>
                                      <DebugWidgetProvider>
                                        <MarketplaceProvider>
                                          <PendingPurchasesProvider>
                                            <PaymentPopupProvider>
                                              <LeaderboardsProvider>
                                                <BlogProvider>
                                                  <SoundProvider>
                                                    <App />
                                                  </SoundProvider>
                                                </BlogProvider>
                                              </LeaderboardsProvider>
                                            </PaymentPopupProvider>
                                          </PendingPurchasesProvider>
                                        </MarketplaceProvider>
                                      </DebugWidgetProvider>
                                    </DiscordWidgetProvider>
                                  </TutorialProvider>
                                </GameClientProvider>
                              </GameOverlayProvider>
                            </LegalNoticeProvider>
                          </AuthProvider>
                        </LocalizationProvider>
                      </ReduxProvider>
                    </SessionProvider>
                  </ThemeProvider>
                </BrowserRouter>
              </AlertOverrideProvider>
            </CursorProvider>
          </ProgressiveWebAppProvider>
        </ViewportProvider>
      </BroadcastChannelProvider>
    </WebMetricsProvider>
  </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
