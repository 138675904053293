/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import TutorialContext, {
  TutorialState,
  initialContext,
  ITutorialContext,
} from 'src/context/tutorial/Tutorial.context';
import { useAppSelector } from 'src/hooks/useAppSelector';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { tutorialStateExists } from 'src/tools/tutorialStateExists';
// import { logMain } from 'src/modules/logger/logger';

interface TutorialProviderProps {
  children: ReactNode;
}

const TUTORIAL_START_INDICES = {
  inactive: -1,
  starterMintPack: 0,
  starterOpenPack: 3,
  heroQuestExplain: 4,
  heroQuestMid: 7,
  heroQuestClaim: 8,
  allEnd: 10,
};

const TutorialProvider: FC<TutorialProviderProps> = ({ children }) => {
  const { enableGameOverlay, gameOverlayLocation } = useGameOverlayContext();
  const [tutorialProgress, setTutorialProgress] = useState<ITutorialContext['tutorialProgress']>(
    initialContext.tutorialProgress,
  );
  const [tutorialState, setTutorialState] = useState<ITutorialContext['tutorialState']>(initialContext.tutorialState);
  const tutorialStates = useAppSelector(({ tutorialStates }) => tutorialStates.tutorialStates);
  const starterMintPack = tutorialStateExists(tutorialStates, 'starter_1_mint');
  const starterOpenPack = tutorialStateExists(tutorialStates, 'starter_2_open');
  const starterComplete = tutorialStateExists(tutorialStates, 'starter_end');
  const heroQuestExplain = tutorialStateExists(tutorialStates, 'heroQuest_02_explain');
  const heroQuestMid = tutorialStateExists(tutorialStates, 'heroQuest_04_mid');
  const heroQuestClaim = tutorialStateExists(tutorialStates, 'heroQuest_06_claim');
  const heroQuestComplete = tutorialStateExists(tutorialStates, 'heroQuest_end');
  const { page } = gameOverlayLocation;

  const progressTutorial = useCallback(() => {
    setTutorialProgress(prevCount => prevCount + 1);
  }, []);

  // Listen for tutorialStates changes and update the tutorial dialog progress
  useEffect(() => {
    if (starterComplete && heroQuestComplete) {
      setTutorialProgress(-1);
    } else if (starterMintPack) {
      setTutorialProgress(TUTORIAL_START_INDICES.starterMintPack);
    } else if (starterOpenPack) {
      setTutorialProgress(TUTORIAL_START_INDICES.starterOpenPack);
    } else if (heroQuestExplain) {
      setTutorialProgress(TUTORIAL_START_INDICES.heroQuestExplain);
    } else if (heroQuestMid) {
      setTutorialProgress(TUTORIAL_START_INDICES.heroQuestMid);
    } else if (heroQuestClaim) {
      setTutorialProgress(TUTORIAL_START_INDICES.heroQuestClaim);
    }
  }, [
    tutorialStates,
    enableGameOverlay,
    starterMintPack,
    starterOpenPack,
    starterComplete,
    heroQuestExplain,
    heroQuestMid,
    heroQuestClaim,
    heroQuestComplete,
  ]);

  // Listen for tutorialStates changes and update the tutorial state accordingly
  useEffect(() => {
    // Logic to determine if tutorial should be visible
    if (enableGameOverlay) {
      const newTutorialState: TutorialState = {
        ...initialContext.tutorialState,
        starterMintPack,
        starterOpenPack,
        starterComplete,
        heroQuestExplain,
        heroQuestMid,
        heroQuestClaim,
        heroQuestComplete,
      };

      if (starterComplete && heroQuestComplete) {
        newTutorialState.visible = false;
      } else if (
        starterMintPack &&
        page === 'primary-marketplace' &&
        tutorialProgress >= TUTORIAL_START_INDICES.starterMintPack &&
        tutorialProgress <= TUTORIAL_START_INDICES.starterOpenPack - 1
      ) {
        newTutorialState.visible = true;
      } else if (
        starterOpenPack &&
        page === 'collection' &&
        tutorialProgress >= TUTORIAL_START_INDICES.starterOpenPack &&
        tutorialProgress <= TUTORIAL_START_INDICES.heroQuestExplain - 1
      ) {
        newTutorialState.visible = true;
      } else if (
        heroQuestExplain &&
        page === 'primary-marketplace' &&
        tutorialProgress >= TUTORIAL_START_INDICES.heroQuestExplain &&
        tutorialProgress <= TUTORIAL_START_INDICES.heroQuestMid - 1
      ) {
        newTutorialState.visible = true;
      } else if (
        heroQuestMid &&
        // page === 'primary-marketplace' &&
        tutorialProgress >= TUTORIAL_START_INDICES.heroQuestMid &&
        tutorialProgress <= TUTORIAL_START_INDICES.heroQuestClaim - 1
      ) {
        newTutorialState.visible = true;
      } else if (
        heroQuestClaim &&
        page === 'primary-marketplace' &&
        tutorialProgress >= TUTORIAL_START_INDICES.heroQuestClaim &&
        tutorialProgress <= TUTORIAL_START_INDICES.allEnd - 1
      ) {
        newTutorialState.visible = true;
      } else {
        newTutorialState.visible = false;
      }

      setTutorialState(newTutorialState);
    } else {
      setTutorialProgress(initialContext.tutorialProgress);
      setTutorialState({ ...initialContext.tutorialState });
    }
  }, [
    starterComplete,
    enableGameOverlay,
    starterMintPack,
    starterOpenPack,
    page,
    tutorialProgress,
    tutorialStates,
    heroQuestExplain,
    heroQuestMid,
    heroQuestClaim,
    heroQuestComplete,
  ]);

  const value = useMemo(
    () => ({
      progressTutorial,
      tutorialProgress,
      setTutorialProgress,
      tutorialState,
      setTutorialState,
    }),
    [progressTutorial, tutorialProgress, setTutorialProgress, tutorialState, setTutorialState],
  );

  return (
    <TutorialContext.Provider value={value}>
      {children}
      <div id="tutorial-cursor-container" />
    </TutorialContext.Provider>
  );
};

export default TutorialProvider;
