type PackTier = 'starter' | 'alpha' | 'bronze' | 'silver' | 'gold';

export const getRewardCount = (rarities: ListPriceMarketRarity[][], packTier: PackTier): number => {
  if (rarities && rarities.length >= 0) {
    return rarities.length;
  }

  // This is backup in case we dont pass rarities array to website
  switch (packTier) {
    case 'starter':
      return 5;
    case 'alpha':
      return 1;
    case 'bronze':
      return 3;
    case 'silver':
      return 3;
    case 'gold':
      return 3;
    default:
      return 0;
  }
};
