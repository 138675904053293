import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getLoadedProfileByUsernameOrPlayerId } from 'src/redux/slices/loaded-profiles/loaded-profiles';
import { updateCurrentProfileUsername } from 'src/redux/slices/current-profile/current-profile';
import { updateUsername } from 'src/api/profile/update-username';
import ErrorMessage from 'src/components/feedback/ErrorMessage';
import { logMain } from 'src/modules/logger/logger';
import Styled from 'src/components/pages/profile/components/Username.styles';
import { PooleEvents } from 'src/modules/poole/events';

const Username: FC = () => {
  const { playerId = '' } = useParams();
  const [editing, setEditing] = useState(false);
  const [username, setUsername] = useState('');
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [inView1, setInView1] = useState(false);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const loadedProfile = useAppSelector(state => getLoadedProfileByUsernameOrPlayerId(state, playerId));
  const dispatch = useAppDispatch();
  const isCurrentProfile = !playerId || playerId === currentProfile.id || playerId === currentProfile?.username;
  const profile = isCurrentProfile ? currentProfile : loadedProfile;

  const handleEdit = () => {
    PooleEvents.EditUsernameStart({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'profile',
    });

    setEditing(true);
    setUsername(profile?.username ? profile?.username.trim() || '' : '');
  };

  const handleCancelEdit = () => {
    PooleEvents.EditUsernameCancel({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'profile',
    });

    setEditing(false);
    setUsername('');
  };

  const handleUpdateUsername = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setProcessing(true);

    PooleEvents.EditUsernameConfirm({
      playerId: currentProfile.id,
      from: window.location.pathname,
      fromContext: 'profile',
    });

    dispatch(updateUsername({ data: { username } }))
      .then(data => {
        logMain.debug({ data });

        PooleEvents.EditUsernameConfirmSuccess({
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'profile',
        });

        dispatch(updateCurrentProfileUsername(username));
        setEditing(false);
        setUsername('');
        setProcessing(false);
        setError('');
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => {
        logMain.debug({ e });

        PooleEvents.EditUsernameConfirmFail({
          playerId: currentProfile.id,
          from: window.location.pathname,
          fromContext: 'profile',
          reason: e instanceof Error ? e.message : String(e),
        });

        setError('Invalid username. please try again.');
        setProcessing(false);
      });
  };

  return (
    <Waypoint onEnter={() => setInView1(true)} bottomOffset="20px">
      <Styled.Root className={`inview animated ${inView1 ? 'fadeInUp' : ''}`}>
        <ErrorMessage msg={error} style={{ maxWidth: '27rem', margin: '0 2.5rem 1rem 0' }} />

        {!editing && <span>{`@${profile?.username ? profile?.username.trim() : 'UnkownUser'}`}</span>}

        {isCurrentProfile && (
          <>
            {editing && (
              <Styled.EditForm onSubmit={handleUpdateUsername}>
                <Styled.Input
                  type="text"
                  name="username"
                  placeholder="Username"
                  value={username}
                  onChange={e => setUsername(e.target.value.trim())}
                />

                <Styled.Buttons>
                  <Styled.Button type="button" onClick={handleCancelEdit}>
                    <span className="material-icons">close</span>
                  </Styled.Button>

                  <Styled.Button
                    type="submit"
                    disabled={processing || username === '' || username === currentProfile.username}
                  >
                    <span className="material-icons">check</span>
                  </Styled.Button>
                </Styled.Buttons>
              </Styled.EditForm>
            )}

            {!editing && (
              <Styled.EditButton onClick={handleEdit}>
                <span className="material-icons">edit</span>
                {/* <span>{t('profile.edit.username')}</span> */}
              </Styled.EditButton>
            )}
          </>
        )}
      </Styled.Root>
    </Waypoint>
  );
};

export default Username;
