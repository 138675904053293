import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 90%;
  max-width: 40rem;
  height: auto;
  max-height: 90vh;
  padding: 2rem 2rem 2rem 2rem;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(90vh - 4rem);
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  margin: 0 auto;
  background: -webkit-linear-gradient(#cccccc, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const SubheadingsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;

  & > p:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  text-align: left;
  /* text-transform: uppercase; */
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #ffffff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const ProgressContainer = styled('div')`
  display: block;
  margin-top: 1.5rem;
  text-align: center;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const ButtonsContainer = styled('div')`
  display: block;
  margin-top: 2rem;
  text-align: center;
  overflow: hidden;
`;

export default {
  Root,
  ContentContainer,
  Heading,
  SubheadingsContainer,
  Subheading,
  ProgressContainer,
  ButtonsContainer,
};
