interface Options {
  decimals?: number;
  hideSymbol?: boolean;
}

export const formatPrice = (price: number, options?: Options): string => {
  const { decimals = 2, hideSymbol = false } = options || {};
  const normalizedPrice = typeof price !== 'number' ? 0 : price;

  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: hideSymbol ? 'decimal' : 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

  return formatter.format(normalizedPrice);
};
