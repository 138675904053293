/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

export interface IBlogContext {
  loadBlogPosts: boolean;
  setLoadBlogPosts: (loadBlogPosts: boolean) => void;
  isLoadingBlogPosts: boolean;
  setIsLoadingBlogPosts: (isLoadingBlogPosts: boolean) => void;
  blogPosts: any[];
  setBlogPosts: (blogPosts: any[]) => void;
}

export const initialContext: IBlogContext = {
  loadBlogPosts: false,
  setLoadBlogPosts: () => undefined,
  isLoadingBlogPosts: false,
  setIsLoadingBlogPosts: () => undefined,
  blogPosts: [],
  setBlogPosts: () => undefined,
};

const BlogContext = createContext<IBlogContext>(initialContext);

BlogContext.displayName = 'BlogContext';

export default BlogContext;
