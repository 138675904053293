/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { LocalStorage } from 'src/local-storage/local-storage';

export interface IProgressiveWebAppContext {
  handleInstall: () => void;
  handleClose: () => void;
  showSaveToPhoneModal: boolean;
  setShowSaveToPhoneModal: (saveToPhoneAcknowledged: boolean) => void;
  optOut: boolean;
  setOptOut: (optOut: boolean) => void;
  userOptedOut: boolean;
  setUserOptedOut: (userOptedOut: boolean) => void;
  saveToPhoneAcknowledged: boolean;
  setSaveToPhoneAcknowledged: (saveToPhoneAcknowledged: boolean) => void;
  promptEvent: any;
  setPromptEvent: (promptEvent: any) => void;
}

export const initialContext: IProgressiveWebAppContext = {
  handleInstall: () => undefined,
  handleClose: () => undefined,
  showSaveToPhoneModal: false,
  setShowSaveToPhoneModal: () => undefined,
  optOut: false,
  setOptOut: () => undefined,
  userOptedOut: LocalStorage.get('opted_out_of_pwa') === 'true',
  setUserOptedOut: () => undefined,
  saveToPhoneAcknowledged: false,
  setSaveToPhoneAcknowledged: () => undefined,
  promptEvent: null,
  setPromptEvent: () => undefined,
};

const ProgressiveWebAppContext = createContext<IProgressiveWebAppContext>(initialContext);

ProgressiveWebAppContext.displayName = 'ProgressiveWebAppContext';

export default ProgressiveWebAppContext;
