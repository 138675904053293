// Go to Google Tag Manager -> ADMIN -> Environments -> Actions -> Get Snippet
// The values for fields 'auth' and 'preview' can be found within the script block
// provided by GTM. The param keys to look for are:
// 1) gtm_auth
// 2) htm_preview
const args: TagManagerArgs = { gtmId: 'GTM-N2GM97V' };
const argsDev = (env: string, uuid: string): TagManagerArgs => {
  return {
    ...args,
    auth: '8h4y4n9MDX39VKyQVrku5A',
    preview: 'env-33',
    dataLayer: { env, browserSessionId: uuid },
  };
};
const argsStaging = (env: string, uuid: string): TagManagerArgs => {
  return {
    ...args,
    auth: 'JN0V5OPP6mevmlgZD_oHsg',
    preview: 'env-32',
    dataLayer: { env, browserSessionId: uuid },
  };
};
const argsQA = (env: string, uuid: string): TagManagerArgs => {
  return {
    ...args,
    auth: '81yGc4B9IjgiPmFoUgzMrg',
    preview: 'env-40',
    dataLayer: { env, browserSessionId: uuid },
  };
};
const argsProd = (env: string, uuid: string): TagManagerArgs => {
  return {
    ...args,
    auth: 'i5WlccnhyHcPwe_Wyql-xQ',
    preview: 'env-1',
    dataLayer: { env, browserSessionId: uuid },
  };
};

export const getTagManagerConfig = (env: string | undefined, uuid: string): TagManagerArgs => {
  switch (env) {
    case 'local':
      return argsDev('dev', uuid);
    case 'dev1':
      return argsDev('dev', uuid);
    case 'dev2':
      return argsDev('dev', uuid);
    case 'dev3':
      return argsDev('dev', uuid);
    case 'dev4':
      return argsDev('dev', uuid);
    case 'dev5':
      return argsDev('dev', uuid);
    case 'dev6':
      return argsDev('dev', uuid);
    case 'staging1':
      return argsStaging('staging', uuid);
    case 'staging2':
      return argsStaging('staging', uuid);
    case 'staging3':
      return argsStaging('staging', uuid);
    case 'staging4':
      return argsStaging('staging', uuid);
    case 'staging5':
      return argsStaging('staging', uuid);
    case 'qa':
      return argsQA('qa', uuid);
    case 'prod':
      return argsProd('prod', uuid);
    default:
      return argsDev;
  }
};
