import { FC } from 'react';
import { isIOS, isAndroid, isSafari, isFirefox, isEdge, isChrome } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useProgressiveWebAppContext from 'src/hooks/useProgressiveWebAppContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import ButtonHex from 'src/components/input/ButtonHex';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { Checkbox, useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/modals/SaveToPhoneModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

interface SaveToPhoneModalProps {
  style?: React.CSSProperties;
}

const SaveToPhoneModal: FC<SaveToPhoneModalProps> = ({ style = {} }) => {
  const { handleInstall, handleClose, showSaveToPhoneModal, optOut, setOptOut, promptEvent } =
    useProgressiveWebAppContext();
  const { showGamePage } = useGameClientContext();
  const { t: tCommon } = useTranslation(nameSpace.common);
  const { t: tPlay } = useTranslation(nameSpace.play);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const isMajorBrowser = isChrome || isSafari || isFirefox || isEdge;

  return (
    <Modal
      aria-labelledby="svg-dialog"
      aria-describedby="svg-dialog-desc"
      open={showGamePage && showSaveToPhoneModal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.gameError}` }}
    >
      <Fade in={showGamePage && showSaveToPhoneModal}>
        <Styled.Root style={style}>
          <PrimaryModalBackground stroke={'#6FB6E744'} />

          <Styled.ContentContainer>
            <Styled.Heading>{tPlay('play.popup.save.to.phone.heading')}</Styled.Heading>

            {promptEvent ? (
              <>
                <Styled.SubheadingsContainer>
                  <Styled.Subheading>
                    <span>{tPlay('play.popup.save.to.phone.button')}</span>
                  </Styled.Subheading>
                </Styled.SubheadingsContainer>

                <Styled.InstallButton onClick={handleInstall}>
                  <span className="material-icons-outlined">download</span>
                  <span>Add to Home Screen</span>
                </Styled.InstallButton>
              </>
            ) : (
              <>
                {isChrome && (
                  <Styled.SubheadingsContainer>
                    <Styled.Subheading>
                      <span>1.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.chrome.step.1')}</span>
                      <span className="material-icons-outlined">ios_share</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>2.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.add.to.homescreen')}</span>
                      <span className="material-icons-outlined">add_box</span>
                    </Styled.Subheading>
                  </Styled.SubheadingsContainer>
                )}

                {isSafari && (
                  <Styled.SubheadingsContainer>
                    <Styled.Subheading>
                      <span>1.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.safari.step.1')}</span>
                      <span className="material-icons-outlined">ios_share</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>2.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.add.to.homescreen')}</span>
                      <span className="material-icons-outlined">add_box</span>
                    </Styled.Subheading>
                  </Styled.SubheadingsContainer>
                )}

                {isFirefox && (
                  <Styled.SubheadingsContainer>
                    <Styled.Subheading>
                      <span>1.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.firefox.step.1')}</span>
                      <span className="material-icons-outlined">menu</span>
                    </Styled.Subheading>

                    {isAndroid ? (
                      <Styled.Subheading>
                        <span>2.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.firefox.android.step.2')}</span>
                        <span className="material-icons-outlined">install_mobile</span>
                      </Styled.Subheading>
                    ) : (
                      <>
                        <Styled.Subheading>
                          <span>2.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.firefox.ios.step.2')}</span>
                          <span className="material-icons-outlined">ios_share</span>
                        </Styled.Subheading>
                        <Styled.Subheading>
                          <span>3.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.add.to.homescreen')}</span>
                          <span className="material-icons-outlined">add_box</span>
                        </Styled.Subheading>
                      </>
                    )}
                  </Styled.SubheadingsContainer>
                )}

                {isEdge && (
                  <Styled.SubheadingsContainer>
                    <Styled.Subheading>
                      <span>1.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.edge.step.1')}</span>
                      <span className="material-icons-outlined">menu</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>2.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.edge.step.2')}</span>
                      <span className="material-icons-outlined">ios_share</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>3.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.add.to.homescreen')}</span>
                      <span className="material-icons-outlined">add_box</span>
                    </Styled.Subheading>
                  </Styled.SubheadingsContainer>
                )}

                {!isMajorBrowser && isIOS && (
                  <Styled.SubheadingsContainer>
                    <Styled.Subheading>
                      <span>1.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.ios.step.1')}</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>2.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.ios.step.2')}</span>
                      <span className="material-icons-outlined">ios_share</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>3.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.add.to.homescreen')}</span>
                      <span className="material-icons-outlined">add_box</span>
                    </Styled.Subheading>
                  </Styled.SubheadingsContainer>
                )}

                {!isMajorBrowser && isAndroid && (
                  <Styled.SubheadingsContainer>
                    <Styled.Subheading>
                      <span>1.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.android.step.1')}</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>2.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.android.step.2')}</span>
                      <span className="material-icons-outlined">more_vert</span>
                    </Styled.Subheading>
                    <Styled.Subheading>
                      <span>3.&nbsp;&nbsp;&nbsp;{tPlay('play.popup.save.to.phone.add.to.homescreen')}</span>
                      <span className="material-icons-outlined">add_box</span>
                    </Styled.Subheading>
                  </Styled.SubheadingsContainer>
                )}
              </>
            )}

            <Styled.OptOutContainer>
              <Checkbox checked={optOut} onChange={() => setOptOut(!optOut)} />
              <Styled.OptOutCopy>{tPlay('play.popup.save.to.phone.opt.out')}</Styled.OptOutCopy>
            </Styled.OptOutContainer>

            <Styled.ButtonsContainer>
              <ButtonHex
                hoverEffect
                variant={'white'}
                width={mobileView ? 175 : 150}
                height={45}
                title={tCommon('common.term.close')}
                style={{
                  display: mobileView ? 'block' : 'inline-block',
                  margin: mobileView ? '0 auto 0 auto' : '0',
                }}
                onClick={handleClose}
                //   disabled={processing}
              />
            </Styled.ButtonsContainer>
          </Styled.ContentContainer>
        </Styled.Root>
      </Fade>
    </Modal>
  );
};

export default SaveToPhoneModal;
