import { v4 as uuidv4 } from 'uuid';
import { getTagManagerConfig } from 'src/modules/gtm/google-tag-manager';
import metadata from 'src/metadata/metadata.json';
import packageJson from '../../package.json';

// Derives a server env string that the Unity game client can understand
const getServerEnv = (lhuBaseUrl: string): string | undefined => {
  if (lhuBaseUrl.startsWith('http://localhost')) {
    return 'local';
  }

  // Extracts server env from game base URL e.g. 'https://qa1.game.legendaryheroesunchained.com' maps to 'qa1'
  const serverEnvMatch = /https:\/\/([a-zA-Z0-9]+)\..*/.exec(lhuBaseUrl);
  const serverEnv = serverEnvMatch === null ? undefined : serverEnvMatch[1];

  if (serverEnv?.startsWith('prod')) {
    // The game client expects 'production' instead of 'prod'
    return 'production';
  }

  return serverEnv;
};

const getConfig = (): Config => {
  const env: Environment = (process.env.REACT_APP_ENV as Environment) || 'local';
  const uuid = uuidv4();
  const pooleWebsiteAID: string = process.env.REACT_APP_POOLE_WEBSITE_AID || '';
  const pooleUnityAID: string = process.env.REACT_APP_POOLE_UNITY_AID || '';
  const isProd: boolean = env === 'prod';
  const lhuBaseUrl = process.env.REACT_APP_BASE_URL_LHU || '';
  const serverEnv = getServerEnv(lhuBaseUrl);
  const useServerGameTime = process.env.REACT_APP_USE_SERVER_GAME_TIME === 'true';

  /**
   * Set global config settings
   */
  const config: Config = {
    env,
    is_prod: isProd,
    version: `${packageJson.version}-${metadata.build}`,
    uuid,
    tag_manager_config: getTagManagerConfig(env, uuid),
    // Each key in 'logger_levels' represents a separate logger
    logger_levels: {
      main: isProd ? 'INFO' : 'DEBUG',
    },
    poole: {
      website_aid: pooleWebsiteAID,
      unity_aid: pooleUnityAID,
    },
    base_url: {
      lhu: lhuBaseUrl,
      codsworth: process.env.REACT_APP_BASE_URL_CODSWORTH || '',
      blog: process.env.REACT_APP_BASE_URL_BLOG || '',
      zendesk: process.env.REACT_APP_BASE_URL_ZENDESK || '',
    },
    discord: {
      server_id: process.env.REACT_APP_DISCORD_WIDGET_BOT_SERVER || '',
      default_channel_id: process.env.REACT_APP_DISCORD_WIDGET_BOT_CHANNEL || '',
    },
    server_env: serverEnv,
    use_server_game_time: useServerGameTime,
    legal: {
      change_notice_enabled: false,
      change_notice_variant: 'both',
      change_notice_url: 'https://static.n3twork.com/docs/change-notice-20231023.html',
      change_notice_status: 'changed',
    },
    poll_interval: {
      event_purchase: 2 * 1000, // Convert seconds to milliseconds
      nft_purchase: 2 * 1000, // Convert seconds to milliseconds
    },
    app_variables: {
      force_in_game_purchases_only: true,
      lc_conversion: 0.01,
      royalty_fee: 0.05,
      listing_fee: 0.0,
      partner_fee: 0.0075,
      min_list_price_usd: 1,
      max_list_price_usd: 2147483600 - 1, // 2147483647 is the max value for a 32-bit signed integer but round down to 2147483600 to avoid any decimals
      default_page_size: 25,
      levels: {
        min: 0,
        max: 29,
      },
      // evolutions: {
      //   min: 0,
      //   max: 2,
      // },
    },
    ui_variables: {
      mobile_breakpoints: {
        header: 901,
      },
    },
  };

  return config;
};

export const config: Config = getConfig();
