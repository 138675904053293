import { FC, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Styled from 'src/components/input/ButtonPrimary.styles';
import LineTextureBackground from '../backgrounds/LineTextureBackground';

export interface ButtonPrimaryProps {
  className?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  fontSize?: number;
  fontWeight?: number;
  type?: 'submit' | 'button' | 'reset';
  title: string;
  hideBackgroundImg?: boolean;
  enablePulseAnimation?: boolean;
  processing?: boolean;
  disabled?: boolean;
  onClick?: VoidFunction;
  textStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

const ButtonPrimary: FC<ButtonPrimaryProps> = ({
  className = '',
  width = 'auto',
  minWidth = 'auto',
  maxWidth = 'auto',
  height = '70px',
  minHeight = 'auto',
  maxHeight = 'auto',
  fontSize = 20,
  fontWeight = 700,
  type = 'button',
  title,
  hideBackgroundImg = false,
  enablePulseAnimation = false,
  processing = false,
  disabled = false,
  onClick = () => undefined,
  textStyle = {},
  style = {},
}) => {
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <Styled.Root
      ref={rootRef}
      className={className}
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      style={style}
    >
      {!hideBackgroundImg && (
        <Styled.HexBackgroundImg
          src={'/assets/button_primary_hex_background.webp'}
          width={'343'}
          height={'103'}
          alt="Primary Button Hex Background"
          title={'Primary Button Hex Background'}
        />
      )}

      <Styled.InnerButtonContainer
        className={`button-primary-inner-button-container ${enablePulseAnimation && !disabled ? 'pulse' : ''}`}
        hideBackgroundImg={hideBackgroundImg}
      >
        <Styled.Button type={type} fontSize={fontSize} fontWeight={fontWeight} disabled={disabled} onClick={onClick}>
          <LineTextureBackground size={400} stroke={'#C19017'} strokeWidth={0.7} />
          <Styled.HoverShadow className="button-primary-hover-shadow" />
          <Styled.FadedBorder />

          {processing ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '.2rem' }}>
              <CircularProgress color="inherit" size={'1.3rem'} sx={{ color: '#ffffff', fontWeight: '700' }} />
            </Box>
          ) : (
            <div className="button-primary-text" style={{ ...textStyle }}>
              {title}
            </div>
          )}
        </Styled.Button>
      </Styled.InnerButtonContainer>
    </Styled.Root>
  );
};

export default ButtonPrimary;
