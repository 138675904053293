import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import { getNFTImage } from 'src/tools/getNFTImage';
import { getNFTVideo } from 'src/tools/getNFTVideo';
import NFTPreviewMetadata from 'src/components/collectibles/NFTPreviewMetadata';
import NFTStatus from 'src/components/data-display/NFTStatus';
import { useMediaQuery, useTheme } from '@mui/material';
// import { useMediaQuery, useTheme } from '@mui/material';
import Styled from 'src/components/collectibles/NFTPreview.styles';

interface NFTPreviewProps {
  nft: Listing | CollectionItem;
  // position: number;
  firstStarterPack: boolean;
  style?: React.CSSProperties;
}

const NFTPreview: FC<NFTPreviewProps> = ({ nft, firstStarterPack, style = {} }) => {
  const { enableGameOverlay, navigateGameOverlay } = useGameOverlayContext();
  const [isHovered, setIsHovered] = useState(false);
  const navigateInternal = useAppNavigateInternal();
  const { t } = useTranslation(nameSpace.common);
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down(900));
  // const theme = useTheme();
  // const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Parse NFT data
  const image = getNFTImage(nft?.imageUrl);
  const animation = getNFTVideo(nft?.animationUrl);
  const isPack = nft?.type === 'pack';
  const packTier = nft?.packTier;

  const handleGoToNFTDetails = () => {
    if (enableGameOverlay) {
      navigateGameOverlay({
        page: 'nft',
        params: {
          seriesId: nft?.seriesId,
          serialNumber: nft?.serialNumber.toString(),
          seriesName: nft?.seriesName,
        },
      });
    } else {
      navigateInternal(`/nft/${nft?.seriesId}/${nft?.serialNumber}`, { pooleData: { fromContext: 'nft_preview' } });
    }
  };

  if (!nft.id) return null;
  if (!image) return null;
  if (isPack && !packTier) return null;

  return (
    <Styled.Root isPack={isPack} mobileBreakpoint={mobileBreakpoint} style={style}>
      <Styled.AssetContainer
        id="asset-container"
        onClick={handleGoToNFTDetails}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
        onTouchEnd={() => setIsHovered(false)}
      >
        <Styled.Img
          src={image}
          width={294}
          height={288}
          loading="lazy"
          alt={t(`common.${isPack ? 'pack' : 'card'}.alt`)}
        />

        {isHovered && (
          <Styled.Video width="1080" height={'1920'} preload="auto" loop autoPlay muted playsInline>
            {animation && <source src={animation} type="video/mp4" />}
            {t('common.video.support')}
          </Styled.Video>
        )}
      </Styled.AssetContainer>

      <NFTPreviewMetadata
        nft={nft}
        // position={position}
        firstStarterPack={firstStarterPack}
      />

      <NFTStatus nft={nft} />
    </Styled.Root>
  );
};

export default NFTPreview;
