import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getServerGameTime } from 'src/tools/getGameTime';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import Styled from 'src/components/primary-marketplace/components/Availability.styles';

interface AvailabilityProps {
  lpmId: string;
  lpmPurchaseReqsMet: boolean;
}

const Availability: FC<AvailabilityProps> = ({ lpmId, lpmPurchaseReqsMet }) => {
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const { t: tCommon } = useTranslation(nameSpace.common);

  if (!listPriceMarket) return null;

  // Variables to determine event state
  const {
    startTimestampMs: allowlistStartDate,
    closeTimestampMs: allowlistEndDate,
    publicStartTimestampMs: publicStartDate,
    publicEndTimestampMs: publicEndDate,
  } = listPriceMarket;
  const now = getServerGameTime();
  const allowlistSaleExists = allowlistStartDate && allowlistEndDate && allowlistStartDate < allowlistEndDate;
  const publicSaleExists = publicStartDate && publicEndDate && publicStartDate < publicEndDate;
  const closed =
    (publicSaleExists && now >= publicEndDate) || (!publicSaleExists && allowlistSaleExists && now >= allowlistEndDate);

  // Variables to determine market state and user purchase state
  const { availableCount, purchaseCount, purchaseLimit, purchaseType } = listPriceMarket;
  const soldOut = availableCount === 0;

  if (purchaseType === 'lpm' && !lpmPurchaseReqsMet && purchaseLimit === 0) {
    // In this case the purchase limit may change once the user meets the requirements
    // So don't show any value at all (or risk displaying something misleading)
    return null;
  }

  // The server sends the largest integer possible to represent 'infinite purchase limit'
  // So any number less than that is a finite one
  const finitePurchaseLimit = purchaseLimit >= 0 && purchaseLimit < 2147483647;
  const canShowPurchasesRemainingAccordingToPurchaseLimit = finitePurchaseLimit && !soldOut && !closed;

  return (
    <>
      {purchaseType === 'lpm' &&
        availableCount !== null &&
        (soldOut || !closed) &&
        !canShowPurchasesRemainingAccordingToPurchaseLimit && (
          <Styled.Root>
            <span>{availableCount}</span> {tCommon('common.term.available')}
          </Styled.Root>
        )}

      {purchaseType === 'lpm' && canShowPurchasesRemainingAccordingToPurchaseLimit && (
        <Styled.Root>
          <span>{purchaseLimit - purchaseCount}</span> {tCommon('common.term.available')}
        </Styled.Root>
      )}

      {purchaseType === 'offer' && finitePurchaseLimit && (
        <Styled.Root>
          <span>{purchaseLimit - purchaseCount}</span> {tCommon('common.term.available')}
        </Styled.Root>
      )}

      {purchaseType === 'offer' && !finitePurchaseLimit && availableCount !== null && (
        <Styled.Root>
          <span>{availableCount}</span> {tCommon('common.term.available')}
        </Styled.Root>
      )}
    </>
  );
};

export default Availability;
