import { styled } from '@mui/material';

const Root = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 650px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const LeftContainer = styled('div')`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  @media (max-width: 650px) {
    width: 100%;
    justify-content: center;
  }
`;

// interface InlineImgProps {
//   color?: string;
// }

const InlineLogo = styled('div')`
  background-color: #ffffff11;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: inline-block;
  margin-top: 0.2rem;
  height: 2.7rem;
  width: 2.7rem;
  margin-right: 0.7rem;
  vertical-align: middle;
  border-radius: 50%;
  /* filter: ${({ color }) => `drop-shadow(0 0 40px ${color}cc)`}; */

  @media (max-width: 768px) {
    width: 2.7rem;
    height: 2.7rem;
  }
`;

interface InlineTextProps {
  color?: string;
}

const InlineText = styled('span')<InlineTextProps>`
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  text-transform: uppercase;
  font-style: italic;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2rem;
  color: ${({ color }) => color || '#ffffff'};
  /* filter: ${({ color }) => `drop-shadow(0 0 40px ${color}cc) brightness(1)`}; */
  /* filter: brightness(1.5); */
`;

const RightContainer = styled('div')`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 0.4rem;

  @media (max-width: 650px) {
    width: 100%;
    margin-top: 0.5rem;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 0;
  }

  @media (max-width: 375px) {
    flex-wrap: wrap;
  }
`;

const MetadataLabel = styled('span')`
  display: inline-block;
  margin-right: 1rem;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};

  @media (max-width: 375px) {
    width: 100%;
    margin-right: 0;
  }
`;

const MetadataValue = styled('span')`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  color: #ffffff;

  @media (max-width: 375px) {
    width: 100%;
  }
`;

export default {
  Root,
  LeftContainer,
  InlineLogo,
  InlineText,
  RightContainer,
  MetadataLabel,
  MetadataValue,
};
