import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 100%;
  position: relative;
`;

const Form = styled('form')`
  display: block;
  width: 100%;
`;

interface FilterButtonProps {
  filtering: boolean;
}

const FilterButton = styled('div')<FilterButtonProps>`
  background-color: ${({ filtering }) => (filtering ? '#ffffff' : 'transparent')};
  display: inline-block;
  width: calc(2.7rem - 2px);
  height: calc(2.7rem - 2px);
  margin-right: 1rem;
  vertical-align: middle;
  border-radius: 0.2rem;
  border: 1px solid #ffffff77;
  box-sizing: border-box;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;

  & .material-icons-outlined {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.1rem;
    color: ${({ filtering }) => (filtering ? '#000000' : '#ffffff')};
  }

  &:hover {
    background-color: #ffffff;

    & .material-icons-outlined {
      color: ${({ theme }) => theme.custom.colors.BgLayer1};
    }
  }
`;

// const PageSizeSelectContainer = styled('div')`
//   display: inline-block;
//   width: 5rem;
//   height: calc(2.7rem - 2px);
//   margin-right: 1rem;
//   vertical-align: middle;
//   border-radius: 0.2rem;
//   border: 1px solid #ffffff77;
//   box-sizing: border-box;

//   & fieldset {
//     /* border: none; */
//   }
// `;

const SearchInputContainer = styled('div')`
  background-color: ${({ theme }) => `${theme.custom.colors.BgLayer2}aa`};
  display: inline-block;
  /* width: calc(100% - 2.7rem - 1rem - 5rem - 1rem - 7rem - 1rem); */
  width: calc(100% - 2.7rem - 1rem - 7rem - 1rem);
  height: 2.7rem;
  vertical-align: middle;
  border-radius: 0.2rem;
  box-sizing: border-box;
  position: relative;

  & .material-icons-outlined {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    font-size: 1.3rem;
    color: #ffffff;
  }

  @media (max-width: 500px) {
    width: calc(100% - 2.7rem - 1rem - 5rem - 1rem);
  }
`;

const SearchInput = styled('input')`
  background-color: transparent;
  display: block;
  width: 100%;
  padding: 0 1rem 0 3.2rem;
  border-radius: 0.2rem;
  border: none;
  box-sizing: border-box;
  outline: none;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &:active {
    outline: none;
  }
`;

interface SearchButtonProps {
  disabled?: boolean;
}

const SearchButton = styled('button')<SearchButtonProps>`
  background-color: transparent;
  display: inline-block;
  width: 7rem;
  height: calc(2.7rem - 2px);
  margin-left: 1rem;
  vertical-align: middle;
  border-radius: 0.2rem;
  border: 1px solid ${({ theme }) => theme.custom.colors.Yellow};
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & span#marketplace-search-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: ${({ theme }) => theme.custom.colors.Yellow};
    border-color: ${({ theme }) => theme.custom.colors.Yellow};
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }

  @media (max-width: 500px) {
    width: 5rem;
  }
`;

const FilterTilesContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;
`;

const FilterTile = styled('div')`
  /* background-color: ${({ theme }) => `${theme.custom.colors.Yellow}`}; */
  background-color: #ffffff;
  display: inline-block;
  padding: 0.7rem 1rem 0.7rem 2.5rem;
  margin-top: 0.5rem;
  border-radius: 0.2rem;
  position: relative;
  /* text-transform: uppercase; */
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #000000;
`;

const FilterTileIconContainer = styled('div')`
  /* background-color: #ffdb66aa; */
  background-color: #eeeeee;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translate(0, -50%);
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #ffdb66;
  }

  & .material-icons {
    font-size: 1rem;
    color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ProgressContainer = styled('div')`
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default {
  Root,
  Form,
  FilterButton,
  // PageSizeSelectContainer,
  SearchInputContainer,
  SearchInput,
  SearchButton,
  FilterTilesContainer,
  FilterTile,
  FilterTileIconContainer,
  ProgressContainer,
};
