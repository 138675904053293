import { FC, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useTutorialContext from 'src/hooks/useTutorialContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useQueueItStatus } from 'src/hooks/useQueueItStatus';
import { getServerGameTime } from 'src/tools/getGameTime';
import { getListPriceMarketByID } from 'src/redux/slices/list-price-markets/list-price-markets';
import { getPackTierFromType } from 'src/tools/getPackTierFromType';
import { getButtonTextFromState } from 'src/components/pages/event-pack/utils/get-button-text-from-state';
import PrimaryPurchaseModal from 'src/components/modals/PrimaryPurchaseModal';
import TutorialCursor from 'src/components/tutorial-cursor/TutorialCursor';
import Title from 'src/components/primary-marketplace/components/Title';
import EventType from 'src/components/primary-marketplace/components/EventType';
import SecondaryPrice from 'src/components/primary-marketplace/components/SecondaryPrice';
import PrimaryPrice from 'src/components/primary-marketplace/components/PrimaryPrice';
import RewardCount from 'src/components/primary-marketplace/components/RewardCount';
import Description from 'src/components/primary-marketplace/components/Description';
import Availability from 'src/components/primary-marketplace/components/Availability';
import DecorativeBackgroundPrimary from 'src/components/backgrounds/DecorativeBackgroundPrimary';
import Alert from 'src/components/feedback/Alert';
import OpenOddsButton from 'src/components/primary-marketplace/components/OpenOddsButton';
import ButtonPrimary from 'src/components/input/ButtonPrimary';
import { useMediaQuery } from '@mui/material';
import Styled from 'src/components/primary-marketplace/components/ListPriceMarketDetails.styles';

interface ListPriceMarketDetailsProps {
  dropEventId: string;
  lpmId: string;
  singleMarketExists: boolean;
}

const ListPriceMarketDetails: FC<ListPriceMarketDetailsProps> = ({ dropEventId, lpmId, singleMarketExists }) => {
  const { tutorialState } = useTutorialContext();
  const [inView, setInView] = useState(false);
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const authenticated = useAppSelector(state => state.authenticated.authenticated);
  const currentProfile = useAppSelector(state => state.currentProfile.currentProfile);
  const listPriceMarket = useAppSelector(state => getListPriceMarketByID(state, lpmId));
  const { t: tEvent } = useTranslation(nameSpace.event);
  const bp550 = useMediaQuery('(max-height:550px)');
  const bp400 = useMediaQuery('(max-height:400px)');
  const { queueComplete, queueItCookieExpired } = useQueueItStatus();

  if (!listPriceMarket) return null;

  const {
    type: lpmType,
    startTimestampMs,
    closeTimestampMs,
    publicStartTimestampMs: publicStartDate,
    publicEndTimestampMs: publicEndDate,
    recapEndTimestampMs: recapEndDate,
  } = listPriceMarket;
  const now = getServerGameTime();
  const packTier = getPackTierFromType(lpmType);
  const isTimedEvent = startTimestampMs || closeTimestampMs || publicStartDate || publicEndDate || recapEndDate;
  const allowlistOnlySaleExists = startTimestampMs && closeTimestampMs && startTimestampMs < closeTimestampMs;
  const publicSaleExists = publicStartDate && publicEndDate && publicStartDate < publicEndDate;
  const allowlistOnlySaleStarted = allowlistOnlySaleExists ? now >= startTimestampMs : false;
  const allowlistOnlySaleEnded = allowlistOnlySaleExists ? now >= closeTimestampMs : false;
  const allowlistOnlySaleActive = allowlistOnlySaleExists && allowlistOnlySaleStarted && !allowlistOnlySaleEnded;
  const publicStarted = publicSaleExists ? now >= publicStartDate : false;
  const publicEnded = publicSaleExists ? now >= publicEndDate : false;
  const publicActive = publicSaleExists && publicStarted && !publicEnded;
  const active = allowlistOnlySaleActive || publicActive;

  // Variables to determine market state and user purchase state
  const {
    id,
    questCompleted,
    allowedViaAllowLists,
    allowedViaAllowListIds,
    allowListPurchaseLimits,
    price,
    availableCount,
    purchaseCount,
    purchaseLimit,
    purchaseType,
    priceCurrencyBalance,
    queueProtected,
  } = listPriceMarket;
  const isGatedByAllowList =
    allowedViaAllowLists !== null && allowedViaAllowListIds !== null && allowListPurchaseLimits !== null;
  const discordVerified = currentProfile.discordUser !== null;
  const hasAllowlist = (allowedViaAllowListIds && allowedViaAllowListIds.length > 0) ?? false;
  const questReqsMet = questCompleted !== null ? questCompleted : true;
  const untimedLPMPurchaseReqsMet =
    !isTimedEvent &&
    purchaseType === 'lpm' &&
    (!isGatedByAllowList || (isGatedByAllowList && discordVerified && hasAllowlist));
  const timedLPMAllowlistOnlyPurchaseReqsMet =
    isTimedEvent && purchaseType === 'lpm' && allowlistOnlySaleActive && discordVerified && hasAllowlist;
  const timedLPMPublicPurchaseReqsMet = isTimedEvent && purchaseType === 'lpm' && publicActive;
  const offerPurchaseReqsMet = purchaseType === 'offer';
  const purchaseLimitReached =
    authenticated && purchaseLimit !== null && purchaseLimit !== 0 && purchaseCount >= purchaseLimit;
  const sufficientBalance = purchaseType === 'offer' && price !== null ? priceCurrencyBalance >= price : true;
  const soldOut = availableCount === 0;
  const purchaseAllowed =
    authenticated &&
    questReqsMet &&
    (untimedLPMPurchaseReqsMet ||
      timedLPMAllowlistOnlyPurchaseReqsMet ||
      timedLPMPublicPurchaseReqsMet ||
      offerPurchaseReqsMet) &&
    !soldOut &&
    !purchaseLimitReached &&
    sufficientBalance;
  const enableTutorialCursor =
    (lpmType !== 'daily' && packTier === 'starter' && tutorialState.starterMintPack) ||
    (lpmType !== 'daily' && id === 'yuna' && tutorialState.heroQuestClaim);
  const disablePrimaryButton =
    purchaseModalOpen ||
    !purchaseAllowed ||
    (lpmType !== 'daily' && packTier !== 'starter' && tutorialState.starterMintPack) ||
    (lpmType !== 'daily' && id !== 'yuna' && tutorialState.heroQuestClaim);

  // NFT Remove Listing Functions
  const handleOpenPurchaseModal = (e?: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();

    setPurchaseModalOpen(true);
  };

  const handleClosePurchaseModal = async () => {
    await setPurchaseModalOpen(false);
  };

  return (
    <Waypoint onEnter={() => setInView(true)} bottomOffset="20px">
      <Styled.Root
        className={`inview animated delay-2 ${inView ? 'fadeIn' : ''}`}
        soldOut={soldOut}
        singleMarketExists={singleMarketExists}
      >
        <PrimaryPurchaseModal
          open={purchaseModalOpen}
          dropEventId={dropEventId}
          disableConfirmation={listPriceMarket.purchaseType === 'offer'}
          lpmId={lpmId}
          onClose={handleClosePurchaseModal}
        />

        <Styled.DetailsContainer isDaily={lpmType === 'daily'} singleMarketExists={singleMarketExists}>
          <DecorativeBackgroundPrimary hideBorder={bp400} />

          <Styled.DetailsInnerContainer>
            <Title lpmId={lpmId} />
            <EventType dropEventId={dropEventId} />

            {active &&
              authenticated &&
              !soldOut &&
              !purchaseLimitReached &&
              queueProtected &&
              queueComplete &&
              !queueItCookieExpired && (
                <div className={`inview animated ${inView ? 'fadeInUp' : ''}`}>
                  <Alert
                    severity="success"
                    variant="filled"
                    msg={tEvent('event.queue.success')}
                    style={{ margin: '1.5rem auto 0.5rem auto' }}
                  />
                </div>
              )}

            <Styled.DetailRow>
              <SecondaryPrice lpmId={lpmId} />
              <PrimaryPrice lpmId={lpmId} />
              <RewardCount dropEventId={dropEventId} lpmId={lpmId} />
            </Styled.DetailRow>

            <Description dropEventId={dropEventId} lpmId={lpmId} />

            <Styled.BottomContainer>
              <Availability
                lpmId={lpmId}
                lpmPurchaseReqsMet={Boolean(
                  untimedLPMPurchaseReqsMet || timedLPMAllowlistOnlyPurchaseReqsMet || timedLPMPublicPurchaseReqsMet,
                )}
              />

              <Styled.ButtonsContainer>
                <TutorialCursor enableTutorial={enableTutorialCursor} offsetY={12} style={{ width: '100%' }}>
                  <ButtonPrimary
                    className="primary-marketplace-mint-btn"
                    hideBackgroundImg={bp550}
                    title={getButtonTextFromState({
                      t: tEvent,
                      lpmType,
                      soldOut,
                      purchaseLimitReached,
                      purchaseCount,
                      sufficientBalance,
                    })}
                    disabled={disablePrimaryButton}
                    processing={purchaseModalOpen}
                    onClick={handleOpenPurchaseModal}
                  />
                </TutorialCursor>

                <OpenOddsButton lpmId={lpmId} />
              </Styled.ButtonsContainer>
            </Styled.BottomContainer>
          </Styled.DetailsInnerContainer>
        </Styled.DetailsContainer>
      </Styled.Root>
    </Waypoint>
  );
};

export default ListPriceMarketDetails;
