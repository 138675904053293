import { styled } from '@mui/material';
import customThemeOptions from 'src/styles/theme/customThemeOptions';
import { isMobile } from 'react-device-detect';

const Root = styled('div')`
  background-color: #ffffffcc;
  display: block;
  max-height: ${isMobile ? '100%' : 'calc(100% - 40px)'};
  width: calc(100% - 40px);
  max-width: ${isMobile ? '16.5rem' : '20rem'};
  padding: ${isMobile ? '0.5rem 0.5rem 0.5rem 0.7rem' : '0.9rem 1rem 1rem 1rem'};
  border-radius: ${isMobile ? '0' : '0.5rem'};
  box-sizing: border-box;
  overflow: auto;
  position: fixed;
  left: ${isMobile ? '0' : '20px'};
  bottom: ${isMobile ? '0' : '20px'};
  z-index: ${customThemeOptions.custom.zIndex.webMetrics};
`;

const Title = styled('p')`
  display: ${isMobile ? 'none' : 'block'};
  font-family: 'Kevlar Underwear';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  text-align: center;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
`;

const Row = styled('div')`
  display: block;
  width: 100%;

  &:first-of-type {
    margin-top: ${isMobile ? '0' : '0.8rem'};
  }

  &:not(:first-of-type) {
    margin-top: ${isMobile ? '0.2rem' : '0.8rem'};
  }
`;

const Label = styled('div')`
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
  margin-top: 0.1rem;
  font-family: 'Kevlar Underwear';
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 0.8rem;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
`;

const Value = styled('div')`
  display: inline-block;
  vertical-align: middle;
  font-family: 'Barlow';
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 0.8rem;
  color: ${({ theme }) => theme.custom.colors.BgLayer3};

  & > span {
    font-weight: 400;
    color: ${({ theme }) => `${theme.custom.colors.BgLayer3}aa`};
  }
`;

const Description = styled('div')`
  display: ${isMobile ? 'none' : 'block'};
  width: 100%;
  margin-top: 0.4rem;
  font-family: 'Barlow';
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 0.8rem;
  color: ${({ theme }) => `${theme.custom.colors.BgLayer3}cc`};
`;

const Button = styled('button')`
  background-color: ${({ disabled }) => (disabled ? '#00000022' : '#ffffff77')};
  display: block;
  width: 100%;
  padding: 0.6rem 1rem 0.4rem 1rem;
  margin-top: 1rem;
  border: none;
  outline: none;
  text-align: center;
  font-family: 'Kevlar Underwear';
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: ${({ disabled }) => (disabled ? '#777777' : '#333333')};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #00000022;

    & > span:first-of-type {
      margin: ${({ disabled }) => (disabled ? '0 0.5rem 0.1rem 0' : '0 1rem 0.1rem 0')};
    }
  }

  &:active {
    background-color: ${({ disabled }) => (disabled ? '#00000022' : '#00000044')};
  }

  & > span {
    display: inline-block;
    vertical-align: middle;
  }

  & > span:first-of-type {
    margin: 0 0.5rem 0.1rem 0;
    transition: margin 0.2s ease-in-out;
  }

  & .material-icons {
    font-size: 1.2rem;
    color: ${({ disabled }) => (disabled ? '#777777' : '#333333')};
  }

  &:last-of-type {
    margin-top: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
`;

export default { Root, Title, Row, Label, Description, Value, Button };
