/* eslint-disable @typescript-eslint/no-explicit-any */
import { styled } from '@mui/material';

interface RootProps {
  variantStyles: {
    fontSize: string;
  };
}

const Root = styled('p')<RootProps>`
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  font-style: italic;
  font-size: ${({ variantStyles }) => variantStyles.fontSize};
  font-weight: 800;
  line-height: ${({ variantStyles }) => variantStyles.fontSize};
  color: #ffffff;
`;

export default {
  Root,
};
