import { createContext } from 'react';
import { config } from 'src/config/config';

export interface IMarketplaceContext {
  loadListings: boolean;
  setLoadListings: (loadListings: boolean) => void;
  loadMoreListings: boolean;
  setLoadMoreListings: (loadMoreListings: boolean) => void;
  filtering: boolean;
  setFiltering: (filtering: boolean) => void;
  viewAllFilters: boolean;
  setViewAllFilters: (viewAllFilters: boolean) => void;
  applySearch: boolean;
  setApplySearch: (applySearch: boolean) => void;
  applyFilters: boolean;
  setApplyFilters: (applyFilters: boolean) => void;
  priceMin: number;
  setPriceMin: (priceMin: number) => void;
  priceMax: number;
  setPriceMax: (priceMax: number) => void;
  levelMin: number;
  setLevelMin: (levelMin: number) => void;
  levelMax: number;
  setLevelMax: (levelMax: number) => void;
  // evoMin: number;
  // setEvoMin: (evoMin: number) => void;
  // evoMax: number;
  // setEvoMax: (evoMax: number) => void;
  types: string;
  setTypes: (types: string) => void;
  rarities: string;
  setRarities: (rarities: string) => void;
  affinities: string;
  setAffinities: (affinities: string) => void;
  classes: string;
  setClasses: (classes: string) => void;
  variants: string;
  setVariants: (variants: string) => void;
  specialties: string;
  setSpecialties: (specialties: string) => void;
  search: string;
  setSearch: (search: string) => void;
  clearFilters: boolean;
  setClearFilters: (clearFilters: boolean) => void;
  clearSearch: boolean;
  setClearSearch: (clearSearch: boolean) => void;
  lastSearch: string;
  setLastSearch: (lastSearch: string) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageCount: number;
  setPageCount: (pageCount: number) => void;
  nextPageToken: string;
  setNextPageToken: (nextPageToken: string) => void;
}

export const initialContext: IMarketplaceContext = {
  loadListings: false,
  setLoadListings: () => undefined,
  loadMoreListings: false,
  setLoadMoreListings: () => undefined,
  filtering: false,
  setFiltering: () => undefined,
  viewAllFilters: false,
  setViewAllFilters: () => undefined,
  applySearch: false,
  setApplySearch: () => undefined,
  applyFilters: false,
  setApplyFilters: () => undefined,
  priceMin: 0,
  setPriceMin: () => undefined,
  priceMax: 100000000,
  setPriceMax: () => undefined,
  levelMin: config.app_variables.levels.min,
  setLevelMin: () => undefined,
  levelMax: config.app_variables.levels.max,
  setLevelMax: () => undefined,
  // evoMin: config.app_variables.evolutions.min,
  // setEvoMin: () => undefined,
  // evoMax: config.app_variables.evolutions.max,
  // setEvoMax: () => undefined,
  types: '',
  setTypes: () => undefined,
  rarities: '',
  setRarities: () => undefined,
  affinities: '',
  setAffinities: () => undefined,
  classes: '',
  setClasses: () => undefined,
  variants: '',
  setVariants: () => undefined,
  specialties: '',
  setSpecialties: () => undefined,
  search: '',
  setSearch: () => undefined,
  clearFilters: false,
  setClearFilters: () => undefined,
  clearSearch: false,
  setClearSearch: () => undefined,
  lastSearch: '',
  setLastSearch: () => undefined,
  pageSize: config.app_variables.default_page_size,
  setPageSize: () => undefined,
  pageNumber: 1,
  setPageNumber: () => undefined,
  pageCount: 1,
  setPageCount: () => undefined,
  nextPageToken: '',
  setNextPageToken: () => undefined,
};

const MarketplaceContext = createContext<IMarketplaceContext>(initialContext);

MarketplaceContext.displayName = 'MarketplaceContext';

export default MarketplaceContext;
