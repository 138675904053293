/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useViewportContext from 'src/hooks/useViewportContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
// import { logMain } from 'src/modules/logger/logger';

export const useGameClientResize = () => {
  const { viewportWidth, viewportHeight, landscape } = useViewportContext();
  const { setDimensions, devicePixelRatio, theatreMode } = useGameClientContext();

  const resizeCanvas = useDebouncedCallback(() => {
    // Get height boundary for game containe rby accounting for website header bar height.
    // If the viewport width is greater than 1000px, subtract 5rem from the viewport height. Otherwise, subtract 4.5rem from the viewport height.
    const containerHeight = theatreMode
      ? viewportHeight
      : viewportWidth > 1000
      ? viewportHeight - 5 * 16
      : viewportHeight - 4.5 * 16;
    const containerWidth = viewportWidth;
    // The ratio of the game client canvas
    const ratio = 16 / 9;
    // The amount of gutter space to leave between the game gradient container and the edge of the screen
    const gutterX = 24;
    const gutterY = 6;
    // The height of the game utils bar (But times 2 in order to account for the centering of the game client in its container)
    const gameUtilsBarOffset = 3 * 16 * 2; // 3rem * 16px
    // The maximum width and height the game gradient container and utils bar can be together
    // const widthBoundary = !landscape ? containerWidth - gutterX * 2 : containerHeight - gutterY * 2;
    // const heightBoundary = !landscape ? containerHeight - gutterY * 2 : containerWidth - gutterX * 2;
    const widthBoundary = containerWidth - gutterX * 2;
    const heightBoundary = containerHeight - gutterY * 2;
    // The current width of the game gradient container
    const gameGradientContainerWidth = 0.95 * widthBoundary;
    // The current height of the game gradient container
    const gameGradientContainerHeight = gameGradientContainerWidth / ratio;
    // The virtual height of the game gradient container including the game utils bar offset
    const virtualGameGradientContainerHeight = gameGradientContainerHeight + gameUtilsBarOffset;

    if (theatreMode) {
      // The estimated full width of the game container, given the game would be set to full height of gradient container
      const estimatedFullHeightGameWidth = containerHeight * ratio;

      // If the width of the game container is too big, adjust the size
      if (estimatedFullHeightGameWidth > containerWidth) {
        setDimensions({ width: `${containerWidth}px`, height: 'auto' });
      } else {
        setDimensions({ width: `${estimatedFullHeightGameWidth}px`, height: 'auto' });
      }
    } else if (gameGradientContainerWidth <= widthBoundary && virtualGameGradientContainerHeight <= heightBoundary) {
      setDimensions({ width: `${gameGradientContainerWidth}px`, height: `${gameGradientContainerHeight}px` });
    } else {
      setDimensions({
        width: `${(heightBoundary - gameUtilsBarOffset) * ratio}px`,
        height: `${heightBoundary - gameUtilsBarOffset}`,
      });
    }
  }, 300);

  // Listen for window resize and adjust canvas container and canvas size accordingly
  useEffect(() => {
    resizeCanvas();
  }, [devicePixelRatio, theatreMode, viewportWidth, viewportHeight, landscape, setDimensions, resizeCanvas]);
};
