import { createContext } from 'react';
import { config } from 'src/config/config';

export interface BroadcastChannelMessageParams {
  unityClientInitializing?: boolean;
  unityClientLoadPercentage?: number;
  unityClientLoaded?: true;
  unityClientQuit?: true;
  unityClientError?: true;
  unityClientOAuthComplete?: true;
  unityClientOAuthRedirectUrl?: string;
  unityClientFullscreen?: true;
  captureScreenshot?: true;
  enableGameOverlay?: boolean;
  listingInProgress?: boolean;
  gameEventName?: string;
  gameEventData?: string;
}

export interface IBroadcastChannelContext {
  sendBroadcastMessage: (params?: BroadcastChannelMessageParams) => void;
  broadcastChannel: BroadcastChannel;
  setBroadcastChannel: (channel: BroadcastChannel) => void;
}

export const initialContext: IBroadcastChannelContext = {
  sendBroadcastMessage: () => undefined,
  broadcastChannel: new BroadcastChannel(`lhu-broadcast-channel-${config.env}`),
  setBroadcastChannel: () => undefined,
};

const BroadcastChannelContext = createContext<IBroadcastChannelContext>(initialContext);

BroadcastChannelContext.displayName = 'BroadcastChannelContext';

export default BroadcastChannelContext;
