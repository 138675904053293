import { FC, useRef, useState } from 'react';
import useLeaderboardsContext from 'src/hooks/useLeaderboardsContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { getSortedLeaderboardCategories } from 'src/redux/slices/leaderboards/leaderboards';
import { useMediaQuery, useTheme } from '@mui/material';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Styled from 'src/components/pages/leaderboards/components/Select.styles';
// import { logMain } from 'src/modules/logger/logger';

const Select: FC = () => {
  const { selectedCategory, setSelectedCategory } = useLeaderboardsContext();
  const anchorEl = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const categories = useAppSelector(state => getSortedLeaderboardCategories(state));
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down(501));

  const handleToggle = () => {
    if (anchorEl.current) {
      setOpen(prev => !prev);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: string) => {
    setSelectedCategory(value);
    setOpen(false);
  };

  return (
    <Styled.Root>
      <Styled.DropdownButton ref={anchorEl} onClick={handleToggle}>
        <Styled.Value>{selectedCategory || '- -'}</Styled.Value>

        {open ? (
          <span className="material-icons-outlined">expand_less</span>
        ) : (
          <span className="material-icons-outlined">expand_more</span>
        )}
      </Styled.DropdownButton>

      <Popper
        open={open}
        anchorEl={anchorEl.current}
        placement="bottom-end"
        transition
        sx={mobileBreakpoint ? { minWidth: 'calc(100% - 3rem)' } : {}}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} timeout={350}>
              <Styled.MenuContainer>
                {categories.length === 0 ? (
                  <Styled.MenuItem key={`leaderboard-no-categories`} selected disabled>
                    {'- -'}
                  </Styled.MenuItem>
                ) : (
                  categories.map((category, i) => {
                    return (
                      <Styled.MenuItem
                        key={`leaderboard-category-${i}`}
                        selected={category === selectedCategory}
                        onClick={() => handleSelect(category)}
                      >
                        {category}
                      </Styled.MenuItem>
                    );
                  })
                )}
              </Styled.MenuContainer>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Styled.Root>
  );
};

export default Select;
