import { FC, ReactNode, useState, useMemo } from 'react';
// import { PooleEvents } from 'src/modules/poole/events';
import LeaderboardsContext, {
  initialContext,
  ILeaderboardsContext,
} from 'src/context/leaderboards/Leaderboards.context';
// import { logMain } from 'src/modules/logger/logger';

interface LeaderboardsProviderProps {
  children: ReactNode;
}

const LeaderboardsProvider: FC<LeaderboardsProviderProps> = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState<ILeaderboardsContext['selectedCategory']>(
    initialContext.selectedCategory,
  );
  const [pageNumber, setPageNumber] = useState<ILeaderboardsContext['pageNumber']>(initialContext.pageNumber);

  const value = useMemo(
    () => ({
      selectedCategory,
      setSelectedCategory,
      pageNumber,
      setPageNumber,
    }),
    [selectedCategory, setSelectedCategory, pageNumber, setPageNumber],
  );

  return <LeaderboardsContext.Provider value={value}>{children}</LeaderboardsContext.Provider>;
};

export default LeaderboardsProvider;
