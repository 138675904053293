import { FC, useCallback, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { useElementDimensions } from 'src/hooks/useElementDimensions';
// import ModalCloseButton from 'src/components/modals/components/ModalCloseButton';
import ButtonPrimary from 'src/components/input/ButtonPrimary';
import { Modal, Backdrop, Fade, useMediaQuery, Tooltip } from '@mui/material';
import Styled from 'src/components/modals/RewardSequenceModal.styles';
import customThemeOptions from 'src/styles/theme/customThemeOptions';

const getPrimaryButtonStyles = (bpSmall: boolean, bpExtraSmall: boolean) => {
  const styles = {
    width: '200px',
    height: '50px',
    fontSize: '1.2rem',
  };

  if (bpSmall) {
    styles.width = '160px';
    styles.height = '36px';
    styles.fontSize = '1rem';
  }

  if (bpExtraSmall) {
    styles.width = '140px';
    styles.height = '32px';
    styles.fontSize = '0.8rem';
  }

  return styles;
};

interface RewardItem {
  id: string;
  name: string;
  imageUrl: string;
  amount: number;
  nft: boolean;
}

interface RewardSequenceModalProps {
  showRewardSequence: boolean;
  rewards: RewardItem[];
  onClose: () => void;
}

const RewardSequenceModal: FC<RewardSequenceModalProps> = ({ showRewardSequence, rewards = [], onClose }) => {
  const rewardsRef = useRef<HTMLDivElement>(null);
  const { width } = useElementDimensions({ element: rewardsRef });
  const mobileSmall = useMediaQuery('(max-height: 600px)');
  const mobileExtraSmall = useMediaQuery('(max-height: 350px)');
  const { t } = useTranslation(nameSpace.common);
  const primaryButtonStyles = getPrimaryButtonStyles(mobileSmall, mobileExtraSmall);
  //   const dispatch = useAppDispatch();

  // Framer Motion Animation Variants
  const animationVariants = {
    root: {
      hidden: { opacity: 0 },
      visible: { opacity: 1, transition: { duration: 0.4, delay: 0 } },
      exit: { opacity: 0, transition: { type: 'tween', duration: 0.3, delay: 0 } },
    },
    header: {
      hidden: { top: '50%', opacity: '0' },
      visible: {
        top: '5%',
        opacity: '1',
        transition: { type: 'spring', bounce: 0.4, duration: 0.5, delay: 0.6 },
      },
    },
    reward: {
      hidden: { transform: 'scale(0)' },
      visible: {
        transform: 'scale(1)',
        transition: { type: 'spring', bounce: 0.4, duration: 0.7, delay: 1.8 },
      },
    },
    button: {
      hidden: { opacity: 0 },
      visible: { opacity: 1, transition: { duration: 0.3, delay: 2.6 } },
    },
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      aria-labelledby={`rewards-sequence-modal`}
      aria-describedby={`rewards-sequence-modal-desc`}
      open={showRewardSequence && rewards.length > 0}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // disableScrollLock
      closeAfterTransition
      sx={{ zIndex: `${customThemeOptions.custom.zIndex.modals.packOpening}`, backgroundColor: '#00000033' }}
    >
      <AnimatePresence>
        {rewards.length > 0 && (
          <Fade in={showRewardSequence && rewards.length > 0}>
            <Styled.Root variants={animationVariants.root} initial={'hidden'} animate={'visible'} exit={'exit'}>
              <Styled.HeaderContainer variants={animationVariants.header} initial={'hidden'} animate={'visible'}>
                <Styled.HeaderFrame />
                <Styled.Header>{'Rewards'}</Styled.Header>
              </Styled.HeaderContainer>

              <Styled.RewardsContainer
                ref={rewardsRef}
                modalWidth={width || window.innerWidth}
                numRewards={rewards.length}
              >
                {rewards.map(reward => {
                  return (
                    <Tooltip
                      key={`reward-${reward.name}`}
                      arrow
                      title={
                        <Styled.TooltipRoot>
                          <Styled.TooltipPrimary>{reward.name}</Styled.TooltipPrimary>
                        </Styled.TooltipRoot>
                      }
                      componentsProps={{
                        arrow: {
                          sx: {
                            color: customThemeOptions.custom.colors.Yellow,
                          },
                        },
                        tooltip: {
                          sx: {
                            backgroundColor: '#222126',
                            padding: '0.7rem',
                            border: `1px solid ${customThemeOptions.custom.colors.Yellow}`,
                            borderRadius: '0',
                          },
                        },
                      }}
                      // sx={{
                      //   zIndex: '100000 !important',
                      // }}
                    >
                      <Styled.RewardContainer
                        variants={animationVariants.reward}
                        initial={'hidden'}
                        animate={'visible'}
                      >
                        <Styled.RewardImg
                          src={reward.imageUrl}
                          width={'100'}
                          height={'100'}
                          alt={reward.name}
                          title={reward.name}
                        />

                        {reward?.amount && (
                          <Styled.RewardCount>
                            <span>x</span>
                            <span>{reward.amount}</span>
                          </Styled.RewardCount>
                        )}
                      </Styled.RewardContainer>
                    </Tooltip>
                  );
                })}
              </Styled.RewardsContainer>

              <Styled.ButtonContainer variants={animationVariants.button} initial={'hidden'} animate={'visible'}>
                <ButtonPrimary
                  className="rewards-sequence-claim-btn"
                  hideBackgroundImg
                  enablePulseAnimation
                  title={t('common.term.claim')}
                  width={primaryButtonStyles.width}
                  height={primaryButtonStyles.height}
                  textStyle={{ fontSize: primaryButtonStyles.fontSize, fontWeight: '400' }}
                  onClick={handleClose}
                />
              </Styled.ButtonContainer>

              {/* <Styled.CloseButton onClick={handleClose} className={'fadeIn'}>
            <span className="material-icons">close</span>
          </Styled.CloseButton> */}

              {/* <ModalCloseButton onClose={handleClose} /> */}
            </Styled.Root>
          </Fade>
        )}
      </AnimatePresence>
    </Modal>
  );
};

export default RewardSequenceModal;
