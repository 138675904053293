/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import DOMPurify from 'dompurify';
import { AnimatePresence } from 'framer-motion';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useTutorialContext from 'src/hooks/useTutorialContext';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import { logMain } from 'src/modules/logger/logger';
import Styled from 'src/components/game-overlay/Blurb.styles';

const Blurb: FC = () => {
  const { progressBlurbs, gameOverlayLocation, blurbs } = useGameOverlayContext();
  const { tutorialState } = useTutorialContext();
  const { t: tPlay } = useTranslation(nameSpace.play);
  const { page } = gameOverlayLocation;

  // Progress through each blurb on click
  const handleProgressBlurbs = () => {
    try {
      // If user just minted starter pack and is being directed to collection page, keep blurb and force collection nav click
      if (
        tutorialState.starterOpenPack &&
        page !== 'collection' &&
        blurbs[0] === tPlay('play.overlay.blurb.marketplace.minting.packs')
      ) {
        return;
      }

      progressBlurbs();
    } catch (e: any) {
      logMain.error('[GAME_OVERLAY_BLURBS]: An Error occured when progressing a blurb', e);
    }
  };

  return (
    <AnimatePresence>
      {blurbs.length > 0 && (
        <Styled.Root
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // exit={{ opacity: 0, transition: { type: 'tween', duration: 0.3, delay: 0 } }}
          // transition={{ duration: 0.5, delay: 0 }}
          onClick={handleProgressBlurbs}
        >
          <Styled.BlurbContainer
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          // transition={{ type: 'tween', duration: 0.6, delay: 0.3 }}
          >
            <span className="material-icons-outlined">info</span>
            <Styled.BlurbCopy dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blurbs[0]) }} />
          </Styled.BlurbContainer>
        </Styled.Root>
      )}
    </AnimatePresence>
  );
};

export default Blurb;
