import { styled } from '@mui/material';

interface RootProps {
  filtering: boolean;
}

const Root = styled('div')<RootProps>`
  background-color: ${({ theme }) => `${theme.custom.colors.BgLayer1}aa`};
  display: ${({ filtering }) => (filtering ? 'inline-block' : 'none')};
  width: 20rem;
  padding: 1.5rem;
  margin: 2rem 2rem 3rem 0;
  overflow: hidden;
  vertical-align: top;
  border: 1px solid ${({ theme }) => theme.custom.colors.BgLayer2};
  box-sizing: border-box;
  border-radius: 0.2rem;
  position: relative;

  @media (max-width: 768px) {
    display: ${({ filtering }) => (filtering ? 'block' : 'none')};
    width: 100%;
    margin: 2rem 0 0 0;
  }
`;

const FiltersContainer = styled('div')`
  display: block;
  width: 100%;

  & > div:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

const FilterRow = styled('div')`
  display: block;
  width: 100%;
`;

const FilterTitle = styled('p')`
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1rem;
  color: #ffffff;
`;

const FilterInputsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;
`;

const PriceInputGroup = styled('div')`
  display: inline-block;
  width: calc(50% - 0.5rem);
  margin-top: 0.4rem;
  vertical-align: middle;
  position: relative;

  &:first-of-type {
    margin-right: 1rem;
  }

  @media (max-width: 450px) {
    display: block;
    width: 100%;
    margin-top: 0.8rem;
  }
`;

const PriceInputLabel = styled('p')`
  display: block;
  width: 100%;
  /* padding-left: 0.8rem; */
  box-sizing: border-box;
  /* text-transform: uppercase; */
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
`;

const PriceInputContainer = styled('div')`
  /* background-color: #000; */
  display: block;
  width: 100%;
  height: 2.7rem;
  margin-top: 0.5rem;
  border-radius: 0.2rem;
  box-sizing: border-box;
  position: relative;
`;

const PriceSectionLeft = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.BgLayer1};
  display: block;
  width: 2.5rem;
  height: 2.7rem;
  border: ${({ theme }) => `1px solid ${theme.custom.colors.BgLayer2}`};
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
  position: absolute;
  top: 0;
  left: 0;

  & > p {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #ffffff77;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const PriceSectionRight = styled('div')`
  background-color: #000;
  display: block;
  width: calc(100% - 2.5rem);
  height: 2.7rem;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  position: absolute;
  top: 0;
  right: 0;
`;

const PriceInput = styled('input')`
  background: transparent;
  display: block;
  width: 100%;
  padding: 0 0.8rem;
  border-radius: 0.2rem;
  border: none;
  box-sizing: border-box;
  outline: none;
  /* text-transform: uppercase; */
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%); /* Chrome, Safari, Edge, Opera */

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

const CheckboxGroup = styled('div')`
  display: inline-block;
  width: calc(50% - 0.5rem);
  vertical-align: top;

  @media (max-width: 768px) {
    width: calc(25% - 0.5rem);
  }

  @media (max-width: 650px) {
    width: calc(33.3% - 0.5rem);
  }

  @media (max-width: 550px) {
    width: calc(50% - 0.5rem);
  }

  @media (max-width: 370px) {
    width: calc(100%);
  }
`;

interface CheckboxLabelProps {
  color?: string;
}

const CheckboxLabel = styled('span')<CheckboxLabelProps>`
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.1rem;
  color: ${({ color }) => color || '#ffffff'};
`;

const MoreFiltersButton = styled('div')`
  display: block;
  width: 100%;
  margin-top: 0.5rem;
  text-align: center;

  & > span {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }

  & > span:last-of-type {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1rem;
    color: #ffffff;
  }
`;

interface ClearButtonProps {
  disabled?: boolean;
}

const ClearButton = styled('div')<ClearButtonProps>`
  display: inline-block;
  width: 100%;
  height: calc(2.7rem - 2px);
  margin-top: 1rem;
  vertical-align: middle;
  border-radius: 0.2rem;
  border: 1px solid #ffffff77;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ disabled }) => (disabled ? '#ffffff77' : '#ffffff')};
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & span#marketplace-clear-filters-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: #ffffff;
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }
`;

interface ApplyButtonProps {
  disabled?: boolean;
}

const ApplyButton = styled('div')<ApplyButtonProps>`
  background-color: transparent;
  display: inline-block;
  width: 100%;
  height: calc(2.7rem - 2px);
  margin-top: 1rem;
  vertical-align: middle;
  border-radius: 0.2rem;
  border: 1px solid ${({ theme }) => theme.custom.colors.Yellow};
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.custom.colors.Yellow};
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  & span#marketplace-apply-filters-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: ${({ theme }) => theme.custom.colors.Yellow};
    border-color: ${({ theme }) => theme.custom.colors.Yellow};
    color: ${({ theme }) => theme.custom.colors.BgLayer1};
  }
`;

const ProgressContainer = styled('div')`
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default {
  Root,
  FiltersContainer,
  FilterRow,
  FilterTitle,
  FilterInputsContainer,
  PriceInputGroup,
  PriceInputLabel,
  PriceInputContainer,
  PriceSectionLeft,
  PriceSectionRight,
  PriceInput,
  CheckboxGroup,
  CheckboxLabel,
  MoreFiltersButton,
  ClearButton,
  ApplyButton,
  ProgressContainer,
};
