/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useMarketplaceContext from 'src/hooks/useMarketplaceContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { searchListings } from 'src/api/secondary-markets/search-listings';
import {
  // clearListings,
  setListings,
} from 'src/redux/slices/listings/listings';
import { CircularProgress } from '@mui/material';
import Collectibles from 'src/components/collectibles/Collectibles';
import Styled from 'src/components/pages/marketplace/components/Listings.styles';
import { logMain } from 'src/modules/logger/logger';

const Listings: FC = () => {
  const { enableGameOverlay, gameOverlayLocation } = useGameOverlayContext();
  const {
    loadListings,
    setLoadListings,
    loadMoreListings,
    setLoadMoreListings,
    filtering,
    setPageNumber,
    setPageCount,
    nextPageToken,
    setNextPageToken,
  } = useMarketplaceContext();
  const [loading, setLoading] = useState(true);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const listings = useAppSelector(({ listings }) => listings.listings);
  const { t: tMarketplace } = useTranslation(nameSpace.marketplace);
  const { t: tCommon } = useTranslation(nameSpace.common);
  const dispatch = useAppDispatch();
  const { params } = gameOverlayLocation;
  const marketplaceEnabled = currentProfile?.features?.marketplace?.enabled;

  useEffect(() => {
    if (loading) {
      setLoading(false);

      if (marketplaceEnabled) {
        // If the game overlay is enabled and the configId is present, search for listings by configId. Otherwise load marketplace normally
        if (enableGameOverlay && params?.configId) {
          dispatch(searchListings({ queryParams: { config_id: params.configId } }))
            .then(data => {
              setPageNumber(data?.pagination?.pageNumber || 1);
              setPageCount(data?.pagination?.pageCount || 1);
              setNextPageToken(data?.pagination?.nextPageToken || '');

              if (data?.listings && data?.listings?.length > 0) {
                dispatch(setListings(data.listings));
              } else {
                setLoadListings(true);
              }
            })
            .catch((e: any) => {
              logMain.debug('[MARKETPLACE]: Error searching listings by configId.', e);
            });
        } else {
          setLoadListings(true);
        }
      }
    }
  }, [
    dispatch,
    enableGameOverlay,
    loadListings,
    loading,
    marketplaceEnabled,
    params.configId,
    setLoadListings,
    setNextPageToken,
    setPageCount,
    setPageNumber,
  ]);

  if (!marketplaceEnabled) {
    return (
      <Styled.Root filtering={filtering}>
        <Styled.ProgressContainer>
          <Styled.Disabled>{tMarketplace('marketplace.coming.soon.primary')}</Styled.Disabled>
          <Styled.Disabled>{tMarketplace('marketplace.coming.soon.secondary')}</Styled.Disabled>
        </Styled.ProgressContainer>
      </Styled.Root>
    );
  }

  if (loadListings) {
    return (
      <Styled.Root filtering={filtering}>
        <Styled.ProgressContainer>
          <CircularProgress color="inherit" size={'1.8rem'} />
        </Styled.ProgressContainer>
      </Styled.Root>
    );
  }

  if (listings.length === 0) {
    return (
      <Styled.Root filtering={filtering}>
        <Styled.EmptyResults>{tMarketplace('marketplace.no.listings')}</Styled.EmptyResults>
      </Styled.Root>
    );
  }

  return (
    <Styled.Root filtering={filtering}>
      <Collectibles collectibles={listings} />

      {nextPageToken && (
        <Styled.LoadMoreContainer>
          <Styled.LoadMoreButton disabled={loadMoreListings} onClick={() => setLoadMoreListings(true)}>
            {loadMoreListings ? (
              <CircularProgress
                color="inherit"
                size={'1rem'}
                sx={{
                  display: 'block',
                  color: '#ffffff',
                }}
              />
            ) : (
              <Styled.LoadMoreButtonText>
                <span className="material-icons">add</span>
                <span>{`${tCommon('common.term.load.more')} ${tCommon('common.term.listings')}`}</span>
              </Styled.LoadMoreButtonText>
            )}
          </Styled.LoadMoreButton>
        </Styled.LoadMoreContainer>
      )}
    </Styled.Root>
  );
};

export default Listings;
