import { FC } from 'react';
import PrimaryModalBackground from 'src/components/backgrounds/PrimaryModalBackground';
import Styled from 'src/components/modals/components/ModalCloseButton.styles';

interface ModalCloseButtonProps {
  onClose: () => void;
  style?: React.CSSProperties;
}

const ModalCloseButton: FC<ModalCloseButtonProps> = ({ onClose, style = {} }) => {
  return (
    <Styled.Root style={style}>
      <PrimaryModalBackground disableLineTexture />

      <Styled.Button active={false} onClick={onClose}>
        <span className={'material-icons'}>close</span>
      </Styled.Button>
    </Styled.Root>
  );
};

export default ModalCloseButton;
