import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const applyLegalAction = (options: RequestOptions) => (_dispatch: any) => {
  const { data } = options;

  const config = {
    method: 'post',
    url: '/api/web/legal/action',
    data: {
      actions: data?.actions,
    },
  };

  return request(config);
};
