import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useGameOverlayContext from 'src/hooks/useGameOverlayContext';
import useTutorialContext from 'src/hooks/useTutorialContext';
import TutorialCursor from 'src/components/tutorial-cursor/TutorialCursor';
import UnreadIndicator from 'src/components/data-display/UnreadIndicator';
import { ReactComponent as MarketIcon } from 'src/components/icons/MarketIcon.svg';
import { ReactComponent as PackIcon } from 'src/components/icons/PackIcon.svg';
import { useMediaQuery } from '@mui/material';
import Styled from 'src/components/game-overlay/NavBar.styles';
import { useGameOverlayNavBarUnreadPips } from 'src/hooks/useGameOverlayNavBarUnreadPips';
import { useLoadDropEvents } from 'src/hooks/useLoadDropEvents';
import { useLoadCollection } from 'src/hooks/useLoadCollection';

type DropEventTag = 'pack' | 'daily';

const NavBar: FC = () => {
  const { navigateGameOverlay, enableGameOverlay, gameOverlayLocation } = useGameOverlayContext();
  const { tutorialState } = useTutorialContext();
  const { pips } = useGameOverlayNavBarUnreadPips();
  const emptyDropEventTags = useRef<DropEventTag[]>([]);
  const { t } = useTranslation(nameSpace.play);
  const { page } = gameOverlayLocation;
  const bp = useMediaQuery('(max-width:1000px) or (max-height:700px)');
  const smallerNav = bp;
  const unreadIndicatorOffset = smallerNav ? 5 : 7;

  // Load ALL drop events to check for unread pips on nav buttons
  useLoadDropEvents({
    dropEventTags: emptyDropEventTags.current,
    loadListPriceMarkets: true,
    redirectOnError: false,
    replaceDropEvents: true,
    replaceListPriceMarkets: true,
    fromContext: 'game_overlay_drop_events',
  });

  // Load user collection to check for unread pips on nav buttons
  useLoadCollection({ redirectOnError: false, fromContext: 'game_overlay_collection' });

  // Tutorial States and disabling nav buttons
  const starterMintPack = enableGameOverlay && tutorialState.starterMintPack && page === 'primary-marketplace';
  const starterOpenPack = enableGameOverlay && tutorialState.starterOpenPack && page === 'collection';
  const heroQuestExplain = enableGameOverlay && tutorialState.heroQuestExplain && page === 'primary-marketplace';
  const collectionTutorialCursor = enableGameOverlay && tutorialState.starterOpenPack && page !== 'collection';

  // Disable Flags for each nav button
  const disablePrimary = starterOpenPack || collectionTutorialCursor;
  const disableSecondary = starterMintPack || starterOpenPack || collectionTutorialCursor || heroQuestExplain;
  const disableRewards = starterMintPack || starterOpenPack || collectionTutorialCursor || heroQuestExplain;
  const disableCollection = starterMintPack || heroQuestExplain;

  const handleNavigate = (page: 'secondary-marketplace' | 'primary-marketplace' | 'rewards' | 'collection' | 'nft') => {
    navigateGameOverlay({ page, params: {} });
  };

  return (
    <Styled.Root>
      <Styled.TitleBackgroundImg
        src={'/assets/modal_title_bkg_lg.webp'}
        width={'100'}
        height={'100'}
        alt={`Title Background`}
        title={`Title Background`}
      />

      <Styled.ScrollableNavContainer>
        <Styled.NavContainer>
          <Styled.Button
            active={page === 'primary-marketplace'}
            onClick={() => handleNavigate('primary-marketplace')}
            disabled={disablePrimary}
          >
            <PackIcon />
            <Styled.ButtonText className="game-overlay-nav-button-text" style={{ marginTop: '4px' }}>
              {t('play.overlay.nav.packs')}
            </Styled.ButtonText>

            <UnreadIndicator
              active={pips.packs}
              topOffset={unreadIndicatorOffset}
              rightOffset={unreadIndicatorOffset}
            />
          </Styled.Button>

          <Styled.Button
            active={page === 'secondary-marketplace'}
            onClick={() => handleNavigate('secondary-marketplace')}
            disabled={disableSecondary}
          >
            <MarketIcon />

            <Styled.ButtonText className="game-overlay-nav-button-text" style={{ marginTop: '4px' }}>
              {t('play.overlay.nav.marketplace')}
            </Styled.ButtonText>

            <UnreadIndicator
              active={pips.marketplace}
              topOffset={unreadIndicatorOffset}
              rightOffset={unreadIndicatorOffset}
            />
          </Styled.Button>

          <Styled.Button
            active={page === 'rewards'}
            onClick={() => handleNavigate('rewards')}
            disabled={disableRewards}
          >
            <span className={'material-icons-outlined'} style={{ marginTop: '3px' }}>
              timer
            </span>

            <Styled.ButtonText className="game-overlay-nav-button-text" style={{ marginTop: '4px' }}>
              {t('play.overlay.nav.rewards')}
            </Styled.ButtonText>

            <UnreadIndicator
              active={pips.rewards}
              topOffset={unreadIndicatorOffset}
              rightOffset={unreadIndicatorOffset}
            />
          </Styled.Button>

          {collectionTutorialCursor ? (
            <TutorialCursor
              enableTutorial={collectionTutorialCursor}
              anchor={'bottom'}
              style={{
                display: 'inline-flex',
                height: '100%',
              }}
            >
              <Styled.Button active={false} onClick={() => handleNavigate('collection')} disabled={disableCollection}>
                <span className={'material-icons'} style={{ marginTop: '3px' }}>
                  apps
                </span>

                <Styled.ButtonText className="game-overlay-nav-button-text" style={{ marginTop: '4px' }}>
                  {t('play.overlay.nav.collection')}
                </Styled.ButtonText>
              </Styled.Button>
            </TutorialCursor>
          ) : (
            <Styled.Button
              active={page === 'collection'}
              onClick={() => handleNavigate('collection')}
              disabled={disableCollection}
            >
              <span className={'material-icons'} style={{ marginTop: '3px' }}>
                apps
              </span>

              <Styled.ButtonText className="game-overlay-nav-button-text" style={{ marginTop: '4px' }}>
                {t('play.overlay.nav.collection')}
              </Styled.ButtonText>

              <UnreadIndicator
                active={pips.collection}
                topOffset={unreadIndicatorOffset}
                rightOffset={unreadIndicatorOffset}
              />
            </Styled.Button>
          )}
        </Styled.NavContainer>
      </Styled.ScrollableNavContainer>
    </Styled.Root>
  );
};

export default NavBar;
