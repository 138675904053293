import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  width: 90%;
  max-width: 40rem;
  height: auto;
  max-height: 90vh;
  padding: 2rem 2rem 2rem 2rem;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(90vh - 4rem);
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Heading = styled('p')`
  display: block;
  width: 100%;
  margin: 0 auto;
  background: -webkit-linear-gradient(#cccccc, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const SubheadingsContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;

  & > p:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const Subheading = styled('p')`
  display: block;
  text-align: left;
  /* text-transform: uppercase; */
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #ffffff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const FeesContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1.5rem;

  & > div:not(:first-of-type) {
    margin-top: 0.5rem;
  }
`;

const FeeRow = styled('div')`
  display: block;
  width: 100%;

  & > div {
    vertical-align: top;
  }
`;

const FeeLabel = styled('div')`
  display: inline-block;
  width: calc(50% - 1rem);
  margin-right: 1rem;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.1rem;
  color: #ffffff77;
`;

const FeeValue = styled('div')`
  display: inline-block;
  width: 50%;
  text-align: right;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.1rem;
  color: #ffffff;
`;

const ForteContainer = styled('div')`
  display: block;
  width: 100%;
  margin-top: 1rem;
  text-align: center;

  & > .material-icons {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.3rem;
    color: #999;
  }

  & > img {
    display: inline-block;
    margin-top: 0.5rem;
    vertical-align: middle;
    font-size: 1.3rem;
    color: #999;
  }
`;

const ForteBrandingLabel = styled('span')`
  display: inline-block;
  margin: 0 0.6rem 0 0.6rem;
  vertical-align: middle;
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #999;
`;

// const ConfirmationIconContainer = styled('div')`
//   display: block;
//   text-align: center;

//   & .material-icons-outlined {
//     display: inline-block;
//     font-size: 3rem;
//     color: ${({ theme }) => theme.custom.colors.Yellow};
//   }
// `;

const ProgressContainer = styled('div')`
  display: block;
  margin-top: 1.5rem;
  text-align: center;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const ConfirmationTitle = styled('div')`
  display: block;
  max-width: 30rem;
  margin: 1rem auto 0 auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 600;
  color: #fff;

  & > span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }
`;

const ButtonsContainer = styled('div')`
  display: block;
  margin-top: 2rem;
  text-align: center;
`;

export default {
  Root,
  ContentContainer,
  Heading,
  SubheadingsContainer,
  Subheading,
  FeesContainer,
  FeeRow,
  FeeLabel,
  FeeValue,
  ForteContainer,
  ForteBrandingLabel,
  //   ConfirmationIconContainer,
  ProgressContainer,
  ConfirmationTitle,
  ButtonsContainer,
};
