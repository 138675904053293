import { FC, useState, useEffect } from 'react';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
// import { useAppNavigateInternal } from 'src/hooks/useAppNavigateInternal';
import { getDropEvents } from 'src/api/drop-events/get-drop-events';
import { getPublicDropEvents } from 'src/api/drop-events/get-public-drop-events';
import { getDropEventsArr, setDropEvents } from 'src/redux/slices/drop-events/drop-events';
// import PageError from 'src/components/feedback/PageError';
import SectionHero from 'src/components/pages/events/components/SectionHero';
import SectionSocialPromo from 'src/components/sections/SectionSocialPromo';
import SectionUpcomingEvents from 'src/components/pages/events/components/SectionUpcomingEvent';
import SectionDetails from 'src/components/pages/events/components/SectionDetails';
import { logMain } from 'src/modules/logger/logger';
import Styled from 'src/components/pages/events/Events.styles';

const Events: FC = () => {
  const [loading, setLoading] = useState(true);
  const authenticated = useAppSelector(({ authenticated }) => authenticated.authenticated);
  const dropEvents = useAppSelector(state => getDropEventsArr(state));
  const dispatch = useAppDispatch();
  // const navigateInternal = useAppNavigateInternal();

  // Load Page Data
  useEffect(() => {
    dispatch(authenticated ? getDropEvents() : getPublicDropEvents())
      .then(data => {
        if (data.dropEvents.length > 0) {
          dispatch(setDropEvents(data.dropEvents));
        }

        setLoading(false);
      })
      .catch(e => {
        logMain.debug(e);
        // navigateInternal('/page-not-found', { pooleData: { fromContext: 'events' } });
      });
  }, [authenticated, dispatch]);

  // If data is not loaded return blank page
  if (loading) return <Styled.Root />;

  return (
    <Styled.Root>
      <SectionHero />
      <SectionSocialPromo />
      {dropEvents.length > 0 && <SectionUpcomingEvents />}
      {dropEvents.length > 0 && <SectionDetails />}
    </Styled.Root>
  );
};

export default Events;
