import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const discordUnlink = () => (_dispatch: any) => {
  const config = {
    method: 'post',
    url: '/api/web/discord/oauth/unlink',
  };

  return request(config);
};
