import { styled } from '@mui/material';
import { isMobile } from 'react-device-detect';

const Root = styled('div')`
  display: block;
  width: 90%;
  max-width: 35rem;
  height: auto;
  max-height: 90vh;
  padding: 2rem 2rem 2rem 2rem;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
`;

const ContentContainer = styled('div')`
  display: block;
  width: 100%;
  height: auto;
  max-height: calc(90vh - 4rem);
  box-sizing: border-box;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Heading = styled('p')`
  display: ${isMobile ? 'none' : 'block'};
  width: 100%;
  margin: -0.3rem auto 0 auto;
  background: -webkit-linear-gradient(#cccccc, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Kevlar Underwear';
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: #fff;

  & span {
    color: ${({ theme }) => theme.custom.colors.Yellow};
  }

  @media (max-height: 500px) {
    display: none;
  }
`;

const RewardRow = styled('div')`
  display: block;
  width: 100%;

  &:first-of-type {
    margin-top: ${isMobile ? '0' : '1rem'};
  }

  &:not(:first-of-type) {
    margin-top: 0.8rem;
  }

  @media (max-height: 500px) {
    &:first-of-type {
      margin-top: 0rem;
    }
  }
`;

const RewardTitle = styled('div')`
  display: block;
  width: auto;
  font-family: 'Kevlar Underwear';
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.custom.colors.BgLayer3Accent};
`;

const RarityRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;

  &:not(:first-of-type) {
    margin-top: 0.6rem;
  }
`;

interface LabelProps {
  color: string;
}

const RarityLabel = styled('div')<LabelProps>`
  display: inline-block;
  width: auto;
  min-width: 12rem;
  margin: 0 1.5rem 0 0;
  flex-shrink: 0;
  font-family: 'Kevlar Underwear';
  font-size: 0.8rem;
  line-height: 0.8rem;
  font-weight: 400;
  color: #ffffffcc;

  & > span {
    color: ${({ color }) => `${color}ff`};
  }

  @media (max-height: 500px) {
    font-size: 0.7rem;
    line-height: 0.7rem;
  }
`;

const RarityResults = styled('div')`
  display: flex;
  width: auto;
  margin-top: -0.3rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem 0.3rem;
  flex-wrap: wrap;
`;

const RarityResult = styled('img')`
  display: inline-block;
  height: 1.3rem;
  width: auto;

  @media (max-height: 500px) {
    height: 1.2rem;
  }
`;

const ButtonsContainer = styled('div')`
  display: block;
  margin-top: 2rem;
  text-align: center;
`;

const TooltipRoot = styled('div')`
  display: block;
  height: 100%;
`;

interface TooltipImageProps {
  color: string;
}

const TooltipImage = styled('img')<TooltipImageProps>`
  display: block;
  width: 4.4rem;
  height: 4.4rem;
  border: 2px solid ${({ color }) => `${color}aa`};
  border-radius: 0.2rem;
  position: absolute;
  top: -1rem;
  left: 1rem;
`;

const TooltipName = styled('div')`
  display: block;
  margin: 0 0 0 5.4rem;
  font-family: 'Kevlar Underwear';
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 400;
  color: #ffffffff;
`;

interface TooltipRarityProps {
  color: string;
}

const TooltipRarity = styled('div')<TooltipRarityProps>`
  display: block;
  margin: 0.2rem 0 0 5.4rem;
  text-transform: uppercase;
  font-family: 'Barlow';
  font-size: 0.7rem;
  line-height: 0.9rem;
  font-weight: 500;
  color: ${({ color }) => `${color}ff`};
`;

const TooltipSkillsContainer = styled('div')`
  display: block;
  height: calc(100% - 4rem);
  margin-top: 1.4rem;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default {
  Root,
  ContentContainer,
  Heading,
  RewardRow,
  RewardTitle,
  RarityRow,
  RarityLabel,
  RarityResults,
  RarityResult,
  ButtonsContainer,
  TooltipRoot,
  TooltipImage,
  TooltipName,
  TooltipRarity,
  TooltipSkillsContainer,
};
