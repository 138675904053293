import { FC } from 'react';
import Styled from 'src/components/layout/Separator.styles';

interface SeparatorProps {
  className?: string;
  style?: React.CSSProperties;
}

const Separator: FC<SeparatorProps> = ({ className = '', style = {} }) => {
  return (
    <Styled.Root className={className} style={style}>
      <Styled.Line />
      <Styled.Box />
      <Styled.Line />
    </Styled.Root>
  );
};

export default Separator;
