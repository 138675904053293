import { styled } from '@mui/material';

interface RootProps {
  fullWidth: boolean;
}

const Root = styled('div')<RootProps>`
  background-color: #4c6acd;
  display: ${({ fullWidth }) => (fullWidth ? 'block' : 'inline-block')};
  padding: 0.5rem 0.6rem 0.5rem 0.6rem;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 0.2rem;
  text-align: center;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #6882d5;
  }

  & > span {
    display: inline-block;
    margin: 0 0 0.1rem 1.6rem;
    vertical-align: middle;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.1rem;
    color: #fff;
  }
`;

export default {
  Root,
};
