/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useBroadcastChannelContext from 'src/hooks/useBroadcastChannelContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { randomIntFromInterval } from 'src/tools/randomIntFromInterval';
import { getServerGameTime } from 'src/tools/getGameTime';
// import { logMain } from 'src/modules/logger/logger';

export const useGameClientConfig = () => {
  const { sendBroadcastMessage } = useBroadcastChannelContext();
  const {
    setShowGamePage,
    setCheckGameAvailability,
    setGameClientColor,
    setDevicePixelRatio,
    isGameClientDisabledByDebug,
    isGameClientDisabledByMultipleInstances,
  } = useGameClientContext();
  const intervalIdRef = useRef<any>(null);
  const pathnameRef = useRef<string>('');
  const [loaded, setLoaded] = useState<boolean>(false);
  const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const location = useLocation();

  // Handle showing or hiding the game page on route changes
  const handleGamePageDisplay = useCallback(
    (pathname: string) => {
      if (pathname.indexOf('play') !== -1) {
        // Display game page
        setShowGamePage(true);
      } else {
        // Hide game page
        setShowGamePage(false);
      }
    },
    [setShowGamePage],
  );

  // Handle loading or unloading game state on route changes.
  const handleGameState = useCallback(
    (pathname: string) => {
      if (pathname.indexOf('play') !== -1 && !isGameClientDisabledByDebug && !isGameClientDisabledByMultipleInstances) {
        // Attempt to load game config if not already done so.
        setCheckGameAvailability(true);
      } else {
        // Attempt to unload and unmount the game client if it hasnt already been done.
        // setClearUnityClient(true);
        sendBroadcastMessage({ unityClientQuit: true });
      }
    },
    [
      isGameClientDisabledByDebug,
      isGameClientDisabledByMultipleInstances,
      sendBroadcastMessage,
      setCheckGameAvailability,
    ],
  );

  // Use in 1 second interval to check if game is gated and load or unload the game based on the gated timestamps.
  const handleCheckGameAvailability = useCallback(() => {
    const isGated = currentProfile?.features?.gameAccess?.eventGated;
    const gatedEndTimestampMs = currentProfile?.features?.gameAccess?.endTimestampMs;
    const isGamePage = pathnameRef.current.indexOf('play') !== -1;

    // If user is on game page, attempt to load game, otherwise attempt to unload game.
    if (isGamePage) {
      setCheckGameAvailability(true);
    } else {
      // setClearUnityClient(true);
      sendBroadcastMessage({ unityClientQuit: true });
    }

    const gameTime = getServerGameTime();

    // Clear interval if the game is not gated or if the gated end timestamp has passed.
    if ((intervalIdRef.current && !isGated) || (isGated && gatedEndTimestampMs && gameTime >= gatedEndTimestampMs)) {
      clearInterval(intervalIdRef.current);
    }
  }, [
    currentProfile?.features?.gameAccess?.endTimestampMs,
    currentProfile?.features?.gameAccess?.eventGated,
    sendBroadcastMessage,
    setCheckGameAvailability,
  ]);

  // On every page load:
  // - Set the game client color to a random color
  // - Check if the game should be load or unmounted
  // - Show or hide the game page
  useEffect(() => {
    setGameClientColor(randomIntFromInterval(0, 4));
    handleGamePageDisplay(location.pathname);
    handleGameState(location.pathname);

    // Update local pathname state
    pathnameRef.current = location.pathname;
  }, [handleGamePageDisplay, handleGameState, location.pathname, setGameClientColor]);

  // Check if the game is gated and if the current date has passed the available play period.
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      intervalIdRef.current = setInterval(handleCheckGameAvailability, 1000);
    }
  }, [handleCheckGameAvailability, loaded]);

  // Update device pixel ratio of the Unity Application when the device pixel ratio changes.
  useEffect(() => {
    // A function which will update the device pixel ratio of the Unity
    // Application to match the device pixel ratio of the browser.
    const updateDevicePixelRatio = () => {
      setDevicePixelRatio(window.devicePixelRatio);
    };

    // A media matcher which watches for changes in the device pixel ratio.
    const mediaMatcher = window.matchMedia(`screen and (resolution: ${devicePixelRatio}dppx)`);

    // Adding an event listener to the media matcher which will update the
    // device pixel ratio of the Unity Application when the device pixel
    // ratio changes.
    mediaMatcher.addEventListener('change', updateDevicePixelRatio);

    return () => {
      // Removing the event listener when the component unmounts.
      mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
    };
  }, [setDevicePixelRatio]);
};
