import React, { useState, useEffect, useRef, useCallback } from 'react';
import useViewportContext from 'src/hooks/useViewportContext';

type Element = React.RefObject<HTMLDivElement>;

interface UseElementDimensionsProps {
  element: Element;
  disableObserver?: boolean;
}

export const useElementDimensions = ({ element, disableObserver = false }: UseElementDimensionsProps) => {
  const el = useRef<HTMLDivElement | null>(null);
  const { viewportWidth, viewportHeight } = useViewportContext();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, scrollWidth: 0, scrollHeight: 0 });

  const handleGetDimensions = useCallback(() => {
    const dimensions = {
      width: el?.current?.offsetWidth || 0,
      height: el?.current?.offsetHeight || 0,
      scrollWidth: el?.current?.scrollWidth || 0,
      scrollHeight: el?.current?.scrollHeight || 0,
    };

    setDimensions(dimensions);
  }, []);

  useEffect(() => {
    el.current = element.current;
  }, [element]);

  useEffect(() => {
    // Do something when the element is resized
    // This will listen to DOM element sizing changes (a new feature)
    const observer = new ResizeObserver(() => {
      handleGetDimensions();
    });

    if (el?.current) {
      if (!disableObserver) observer.observe(el.current);

      handleGetDimensions();
    }

    return () => {
      // Cleanup the observer by unobserving all elements
      if (!disableObserver) observer.disconnect();
    };
  }, [disableObserver, handleGetDimensions]);

  useEffect(() => {
    handleGetDimensions();
  }, [viewportWidth, viewportHeight, handleGetDimensions]);

  return dimensions;
};
