import { styled } from '@mui/material';

const Root = styled('div')`
  background-color: ${({ theme }) => theme.custom.colors.Yellow};
  display: block;
  padding: 1rem 2rem;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2rem;
  color: #000000;
`;

export default {
  Root,
};
