/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode, useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { nameSpace } from 'src/modules/i18n';
import { useTranslation } from 'react-i18next';
import WidgetBot from '@widgetbot/react-embed';
// import { useAppSelector } from 'src/hooks/useAppSelector';
import useGameClientContext from 'src/hooks/useGameClientContext';
import DiscordWidgetContext, {
  initialContext,
  IDiscordWidgetContext,
} from 'src/context/discord-widget/DiscordWidget.context';
import { LocalStorage } from 'src/local-storage/local-storage';
import customThemeOptions from 'src/styles/theme/customThemeOptions';
import { logMain } from 'src/modules/logger/logger';
import { config } from 'src/config/config';

const getDiscordCrateCSS = () => {
  return [
    //
    '.notifications {',
    'width: 100%;',
    'max-width: 25rem;',
    'height: calc(100vh - 12rem);',
    'max-height: 50rem;',
    'overflow: hidden;',
    '-webkit-mask-image: linear-gradient(to bottom,transparent 0%, #000 15%,#000 100%);',
    'mask-image: linear-gradient(to bottom,transparent 0%, #000 15%,#000 100%);',
    'pointer-events: none;',
    '}',
    //
    '.notification .content {',
    'display: inline-block;',
    'width: auto !important;',
    'max-width: calc(100% - 48px) !important;',
    'padding-bottom: 12px;',
    'box-sizing: border-box;',
    '@media (max-width: 450px) {',
    'max-width: calc(100% - 100px) !important;',
    '}',
    '}',
    //
    '.notification .content p {',
    'max-height: 10.4rem;',
    'overflow: hidden;',
    // 'text-transform: uppercase;',
    'font-family: "Barlow";',
    'font-size: 15px;',
    'font-weight: 400;',
    'line-height: 20px;',
    'color: #ffffffaa;',
    '}',
    //
    '.notification .content p:first-line {',
    `color: ${customThemeOptions.custom.colors.Yellow} !important;`,
    'font-family: "Kevlar Underwear";',
    'font-size: 14px;',
    'font-weight: 500;',
    'line-height: 25px;',
    '}',
  ].join(' ');
};

interface DiscordWidgetProviderProps {
  children: ReactNode;
}

const DiscordWidgetProvider: FC<DiscordWidgetProviderProps> = ({ children }) => {
  const { showGamePage, fullscreen, theatreMode } = useGameClientContext();
  const location = useLocation();
  const { t } = useTranslation(nameSpace.common);
  const processedMessageIds = useRef<string[]>([]);
  //   const currentProfile = useAppSelector(({ currentProfile }) => currentProfile.currentProfile);
  const [discordAPI, setDiscordAPI] = useState<IDiscordWidgetContext['discordAPI']>(initialContext.discordAPI);
  const [welcomeNotificationSent, setWelcomeNotificationSent] = useState<
    IDiscordWidgetContext['welcomeNotificationSent']
  >(initialContext.welcomeNotificationSent);
  const [hasSetUpListeners, setHasSetUpListeners] = useState<IDiscordWidgetContext['hasSetUpListeners']>(
    initialContext.hasSetUpListeners,
  );
  const [discordWidgetOpen, setDiscordWidgetOpen] = useState<IDiscordWidgetContext['discordWidgetOpen']>(
    initialContext.discordWidgetOpen,
  );
  const [discordWidgetAuthenticated, setDiscordWidgetAuthenticated] = useState<
    IDiscordWidgetContext['discordWidgetAuthenticated']
  >(initialContext.discordWidgetAuthenticated);
  const [enableWelcomeMessage, setEnableWelcomeMessage] = useState<IDiscordWidgetContext['enableWelcomeMessage']>(
    initialContext.enableWelcomeMessage,
  );
  const [enableNotifications, setEnableNotifications] = useState<IDiscordWidgetContext['enableNotifications']>(
    initialContext.enableNotifications,
  );
  const [enableIndicators, setEnableIndicators] = useState<IDiscordWidgetContext['enableIndicators']>(
    initialContext.enableIndicators,
  );
  const [showDiscordWidget, setShowDiscordWidget] = useState<IDiscordWidgetContext['showDiscordWidget']>(
    initialContext.showDiscordWidget,
  );
  const discordCrate = window?.discordWidgetGlobals?.crate || null;
  const isAuthPage = location.pathname.indexOf('auth') !== -1;

  // Set Discord Crate Options
  useEffect(() => {
    if (discordCrate) {
      // Set Crate default Options
      discordCrate.setOptions({
        server: config.discord.server_id,
        channel: config.discord.default_channel_id,
        color: '#FEC300',
        notifications: false,
        indicator: enableIndicators,
        defer: true,
        css: getDiscordCrateCSS(),
      });

      if (!welcomeNotificationSent) {
        setWelcomeNotificationSent(true);

        if (enableWelcomeMessage) {
          setEnableWelcomeMessage(false);

          // Set timeout to send a welcome message
          setTimeout(() => {
            // Send a welcome message
            discordCrate.notify({
              content: t('common.discord.widget.welcome'),
              timeout: 5000,
              avatar: '/apple-touch-icon-192.png',
            });
          }, 3000);
        }
      }
    }
  }, [discordCrate, enableIndicators, enableWelcomeMessage, t, welcomeNotificationSent]);

  // Listen for auth events and set authenticated state
  useEffect(() => {
    if (discordCrate) {
      if (!hasSetUpListeners) {
        setHasSetUpListeners(true);

        // Listen for user signing in manually
        discordCrate.on('signIn', (user: any) => {
          logMain.debug(`%c[DISCORD BOT]: User signed in as ${user.username}`, 'color: magenta;');
          setDiscordWidgetAuthenticated(true);
        });

        // Listen for user already signed in
        discordCrate.on('alreadySignedIn', (user: any) => {
          logMain.debug(`%c[DISCORD BOT]: User already signed in as ${user.username}`, 'color: magenta;');
          setDiscordWidgetAuthenticated(true);
        });

        // Listen for user already signed in
        discordCrate.on('signOut', () => {
          logMain.debug(`%c[DISCORD BOT]: User signed out`, 'color: magenta;');
          setDiscordWidgetAuthenticated(false);
        });

        // Listen for discord message events
        discordCrate.on('message', ({ message, channel }: { message: any; channel: any }) => {
          logMain.debug(`[DISCORD BOT]: New message (${message.id})`, { message, channel });

          // Check if message is already processed
          if (processedMessageIds.current.indexOf(message.id) !== -1) {
            return;
          }

          // Add message to processed list
          processedMessageIds.current.push(message.id);

          if (LocalStorage.get('discord_widget_enable_notifications') !== 'false') {
            // Send a welcome message
            discordCrate.notify({
              content: `${message?.author?.name}\n${message.content}`,
              timeout: 5000,
              avatar: message?.author?.avatarUrl || '/apple-touch-icon-192.png',
            });
          }
        });
      }
    }
  }, [discordCrate, hasSetUpListeners]);

  // Detect if discord is linked and if so pass discord username to crate
  //   useEffect(() => {
  //     const discordCrate = window?.discordWidgetGlobals?.crate || null;
  //     const { discordUser } = currentProfile || {};

  //     if (discordCrate) {
  //       if (discordUser?.username) {
  //         logMain.debug(`%c[DISCORD BOT]: Setting username to ${discordUser.username}`, 'color: magenta;');
  //         discordCrate.options.username = discordUser.username;
  //       }

  //       if (discordUser?.avatarUrl) {
  //         logMain.debug(`%c[DISCORD BOT]: Setting avatar`, 'color: magenta;');
  //         discordCrate.options.avatar = discordUser.avatarUrl;
  //       }
  //     }
  //   }, [currentProfile]);

  // Hide discord widget when game is in fullscreen
  // Hide discord widget when auth page is open
  useEffect(() => {
    if (discordCrate) {
      if (showGamePage && (fullscreen || theatreMode)) {
        logMain.debug(`%c[DISCORD BOT]: Hiding Discord Crate`, 'color: magenta;');
        discordCrate.hide();
      } else if (isAuthPage) {
        logMain.debug(`%c[DISCORD BOT]: Hiding Discord Crate`, 'color: magenta;');
        discordCrate.hide();
      } else if (showDiscordWidget) {
        logMain.debug(`%c[DISCORD BOT]: Showing Discord Crate`, 'color: magenta;');
        discordCrate.show();
      }
    }
  }, [discordCrate, fullscreen, theatreMode, isAuthPage, showDiscordWidget, showGamePage]);

  // Update LocalStorage when widget settings change
  useEffect(() => {
    if (enableWelcomeMessage) {
      logMain.debug(`%c[DISCORD BOT]: Enabling welcome message`, 'color: magenta;');
      LocalStorage.set('discord_widget_enable_welcome_message', 'true');
    } else {
      logMain.debug(`%c[DISCORD BOT]: Disabling welcome message`, 'color: magenta;');
      LocalStorage.set('discord_widget_enable_welcome_message', 'false');
    }

    if (enableNotifications) {
      logMain.debug(`%c[DISCORD BOT]: Enabling notifications`, 'color: magenta;');
      LocalStorage.set('discord_widget_enable_notifications', 'true');
    } else {
      logMain.debug(`%c[DISCORD BOT]: Disabling notifications`, 'color: magenta;');
      LocalStorage.set('discord_widget_enable_notifications', 'false');
    }

    if (enableIndicators) {
      logMain.debug(`%c[DISCORD BOT]: Enabling indicators`, 'color: magenta;');
      LocalStorage.set('discord_widget_enable_indicators', 'true');

      if (discordCrate) discordCrate.options.indicator = true;
    } else {
      logMain.debug(`%c[DISCORD BOT]: Disabling indicators`, 'color: magenta;');
      LocalStorage.set('discord_widget_enable_indicators', 'false');

      if (discordCrate) discordCrate.options.indicator = false;
    }

    if (showDiscordWidget) {
      logMain.debug(`%c[DISCORD BOT]: Showing Discord crate`, 'color: magenta;');
      LocalStorage.set('discord_widget_show_widget', 'true');

      if (discordCrate && !isAuthPage) discordCrate.show();
    } else {
      logMain.debug(`%c[DISCORD BOT]: Hiding Discord crate`, 'color: magenta;');
      LocalStorage.set('discord_widget_show_widget', 'false');

      if (discordCrate) discordCrate.hide();
    }
  }, [discordCrate, enableIndicators, enableNotifications, enableWelcomeMessage, isAuthPage, showDiscordWidget]);

  // Function to pass discord API to context
  const onDiscordAPI = useCallback((api: WidgetBot['api']) => {
    setDiscordAPI(() => api);
  }, []);

  const value = useMemo(
    () => ({
      onDiscordAPI,
      discordAPI,
      welcomeNotificationSent,
      setWelcomeNotificationSent,
      hasSetUpListeners,
      setHasSetUpListeners,
      discordWidgetOpen,
      setDiscordWidgetOpen,
      discordWidgetAuthenticated,
      setDiscordWidgetAuthenticated,
      enableWelcomeMessage,
      setEnableWelcomeMessage,
      enableNotifications,
      setEnableNotifications,
      enableIndicators,
      setEnableIndicators,
      showDiscordWidget,
      setShowDiscordWidget,
    }),
    [
      onDiscordAPI,
      discordAPI,
      welcomeNotificationSent,
      setWelcomeNotificationSent,
      hasSetUpListeners,
      setHasSetUpListeners,
      discordWidgetOpen,
      setDiscordWidgetOpen,
      discordWidgetAuthenticated,
      setDiscordWidgetAuthenticated,
      enableWelcomeMessage,
      setEnableWelcomeMessage,
      enableNotifications,
      setEnableNotifications,
      enableIndicators,
      setEnableIndicators,
      showDiscordWidget,
      setShowDiscordWidget,
    ],
  );

  return <DiscordWidgetContext.Provider value={value}>{children}</DiscordWidgetContext.Provider>;
};

export default DiscordWidgetProvider;
