import { CSSProperties } from 'react';
import { css } from '@emotion/react';

let detectedFlexGapSupported = false;
let isFlexGapSupported = false;

export const checkFlexGapSupported = (): boolean => {
  if (detectedFlexGapSupported) {
    return isFlexGapSupported;
  }

  const flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  document.body.appendChild(flex);
  const isSupported = flex.scrollHeight === 1;
  flex?.parentNode?.removeChild(flex);

  detectedFlexGapSupported = true;
  isFlexGapSupported = isSupported;

  return isSupported;
};

export const flexGap = (
  gap: CSSProperties['gap'] = '0',
  orientation: CSSProperties['flexDirection'] | undefined = 'row',
) => {
  if (checkFlexGapSupported()) {
    return css`
      gap: ${gap};
    `;
  }
  return flexGapPolyfill(gap, orientation);
};

export const flexGapPolyfill = (gap: CSSProperties['gap'], orientation: CSSProperties['flexDirection']) => {
  return css`
    overflow: hidden;
    & > *:not(:last-child) {
      ${orientation === 'row' ? `margin-right: ${gap} !important` : `margin-bottom: ${gap}  !important`};
    }
  `;
};
