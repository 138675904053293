import { request } from 'src/api/request';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLeaderboardConfig = () => (_dispatch: any) => {
  const config = {
    method: 'get',
    url: `/api/web/leaderboard-config`,
  };

  return request(config);
};
