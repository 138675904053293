import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { nameSpace } from 'src/modules/i18n';
import useViewportContext from 'src/hooks/useViewportContext';
import useGameClientContext from 'src/hooks/useGameClientContext';
import LineTextureBackground from 'src/components/backgrounds/LineTextureBackground';
import Styled from 'src/components/device-orientation-overlay/DeviceOrientationOverlay.styles';

const DeviceOrientationOverlay: FC = () => {
  const { viewportWidth, viewportHeight, landscape } = useViewportContext();
  const { unityClientLoaded } = useGameClientContext();
  const { t } = useTranslation(nameSpace.play);

  return (
    <CSSTransition
      in={isMobile && !landscape && unityClientLoaded}
      timeout={500}
      classNames="device-orientation"
      unmountOnExit
    >
      <Styled.RotateScreenOuterContainer>
        <LineTextureBackground size={Math.max(viewportWidth, viewportHeight)} stroke={'#6FB6E711'} />

        <Styled.RotateScreenInnerContainer>
          <span className="material-icons-outlined">screen_lock_rotation</span>
          <Styled.RotateScreenTitle>{t('play.blocked.rotate.device.title')}</Styled.RotateScreenTitle>
        </Styled.RotateScreenInnerContainer>
      </Styled.RotateScreenOuterContainer>
    </CSSTransition>
  );
};

export default DeviceOrientationOverlay;
