import { styled } from '@mui/material';

const Root = styled('div')`
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
`;

interface LineTextureBackgroundProps {
  size: number;
  downScale: number;
  animationDelay: number;
}

const LineTextureBackground = styled('div')<LineTextureBackgroundProps>`
  display: block;
  width: ${({ size, downScale }) => `${size * downScale}px`};
  height: ${({ size, downScale }) => `${size * downScale}px`};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default { Root, LineTextureBackground };
