import { styled } from '@mui/material';
import BaseIcon from '../../../icons/BaseIcon';

const HeaderSection = styled('div')`
  box-sizing: border-box;
  position: relative;
  top: -3rem;
  width: 100%;
  margin-bottom: 20px;
  .swiper-slide {
    overflow: hidden;
    .hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
  .swiper-next-arrow {
    position: absolute;
    z-index: 10;
    right: 10vw;
    top: 50%;
    height: 40px;
    width: 40px;
    transform: translateY(-50%);
    > div {
      width: 1.8vw;
      cursor: pointer;
      transition: all 0.4s ease;
      ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 20px;
      }
    }
    &:hover div {
      margin-left: 5px;
    }
    &.water {
      div:last-child path {
        fill: #55d1f5;
      }
      &:hover {
        div:first-of-type path {
          fill: #55d1f5;
        }
        div:last-child path {
          fill: #000000;
        }
      }
    }

    &.fire {
      div:last-child path {
        fill: #ff2400;
      }
      &:hover {
        div:first-of-type path {
          fill: #ff2400;
        }
        div:last-child path {
          fill: #000000;
        }
      }
    }

    &.light {
      div:last-child path {
        fill: #f5d954;
      }
      &:hover {
        div:first-of-type path {
          fill: #f5d954;
        }
        div:last-child path {
          fill: #000000;
        }
      }
    }

    &.dark {
      div:last-child path {
        fill: #771ec9;
      }
      &:hover {
        div:first-of-type path {
          fill: #771ec9;
        }
        div:last-child path {
          fill: #000000;
        }
      }
    }

    &.earth {
      div:last-child path {
        fill: #1ec993;
      }
      &:hover {
        div:first-of-type path {
          fill: #1ec993;
        }
        div:last-child path {
          fill: #000000;
        }
      }
    }
  }
`;

const ImageWrapper = styled('div')`
  max-width: 78vw;
  position: relative;
  .parallax {
    position: absolute;
    top: 1.1rem;
    z-index: 1;
    left: -5px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: 100%;
    img {
      width: 120%;
    }
  }
`;

const Overlay = styled('div')`
  z-index: 10;
  position: absolute;
  top: 0;
  right: 15vw;
  height: 100%;
  max-width: 25rem;
  .MuiContainer-root {
    display: flex !important;
    align-items: center !important;
  }
  @media (min-width: 1925px) {
    right: 30vw;
  }
  @media (min-width: 1899px) {
    max-width: 31rem;
  }
  ${({ theme }) => theme.breakpoints.up('xl')} {
    right: 22vw;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 21rem;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: relative;
    right: inherit;
    max-width: 100%;
    margin: auto;
    top: -5vw;
  }
`;

const OverlayContent = styled('div')`
  position: relative;
  text-align: center;
  font-family: ${({ theme }) => theme.custom.typography.LabelSm.fontFamily};
  color: ${({ theme }) => theme.custom.colors.Black};
  p {
    text-align: left;
    font-size: 0.9rem;
    line-height: 1.2;
    text-shadow: 0 0 5px #fff;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px 7px rgba(255, 255, 255, 0.2);
    @media (min-width: 1899px) {
      font-size: 1.04rem;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: 0.9rem;
    }
  }
  h4 {
    font-size: 1.6rem;
    margin-bottom: 0;
    line-height: 0.9;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: 1.3rem;
    }
  }
  h3 {
    font-size: 3rem;
    line-height: 0.9;
    margin-bottom: 0.8rem;
    text-shadow: 0 0 5px #fff;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: 2rem;
    }
  }
  .img-wrapper {
    margin: auto;
    img {
      width: 40px;
      margin-bottom: 1rem;
    }
    .rarity {
      font-size: 0.8rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
      letter-spacing: 0;
      line-height: 0;
    }
  }
`;

const Icon = styled(BaseIcon)`
  position: absolute;
  top: 0;
  &:first-of-type {
    left: 0;
  }
  &:last-child {
    left: 14px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      left: 10px;
    }
  }
`;

export default { HeaderSection, ImageWrapper, Overlay, OverlayContent, Icon };
